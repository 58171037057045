import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ConfigurationActions from '../actions/configuration.action';
import { map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigurationService } from 'app/util/configuration.service';
var ConfigurationEffects = /** @class */ (function () {
    function ConfigurationEffects(actions$, configurationService) {
        var _this = this;
        this.actions$ = actions$;
        this.configurationService = configurationService;
        this.loadConfiguration = this.actions$.pipe(ofType(ConfigurationActions.ActionTypes.GeneralLoadConfiguration), concatMap(function (action) { return _this.configurationService.getConfiguration(action.payload.getConfigurationRequest).pipe(map(function (configurationState) { return new ConfigurationActions.ConfigurationEffectLoadConfigurationSuccess({ configurationState: configurationState }); }), catchError(function (error) { return of(new ConfigurationActions.ConfigurationEffectLoadConfigurationFailure({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ConfigurationEffects.prototype, "loadConfiguration", void 0);
    return ConfigurationEffects;
}());
export { ConfigurationEffects };
