import { OnInit } from '@angular/core';
import { ModalSize } from 'app/store/models/modal';
var GroupToolsSuccessModal = /** @class */ (function () {
    function GroupToolsSuccessModal() {
        this.size = 'modal-md';
    }
    GroupToolsSuccessModal.prototype.ngOnInit = function () {
    };
    return GroupToolsSuccessModal;
}());
export { GroupToolsSuccessModal };
export var GroupToolsSuccessModalConfig = {
    title: 'Success',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: GroupToolsSuccessModal
    }
};
