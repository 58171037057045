
import { tap, map} from 'rxjs/operators';
import { Injectable, NgZone, SecurityContext } from '@angular/core';

import { URLConfig, ClientAuthConfig } from '../pages/helpers/config.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as user from 'app/store/actions/user';
import * as attendeePackages from 'app/store/actions/attendeePackages';
import * as ContactsActions from 'app/store/actions/contacts.action';
import * as event from 'app/store/actions/event';
import * as ActionActions from 'app/store/actions/actions.action';
import * as PaymentActions from 'app/store/actions/payment.action';
import * as PackageDetailsActions from 'app/store/actions/packageDetails.actions';
import * as MessageActions from 'app/store/actions/message.action';
import * as SurveysActions from 'app/store/actions/surveys.action';
import { ContactService } from 'app/pages/contacts/contact.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GettingStartedService } from 'app/pages/getting-started/getting-started.service';
import { AppStateService } from 'app/pages/helpers/appState.service';
import { NgxConfigureService } from 'ngx-configure';
import { User } from 'app/store/models/user';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { DomSanitizer } from '@angular/platform-browser';

declare const gapi: any;

export class EmailVerification {
  Result: boolean;
  id: number;
  name: string;
}

export class Person {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  Password: string;
  RepeatPassword: string;
  InvitationToken: string;
  IdInvoice: number;

  constructor(
    email = '',
    firstname = '',
    lastname = '',
    phonevalue = '',
    password = '',
    repeatPassword = '',
    invitationToken = null,
    invoiceId = null,
    ) {
      this.Email = email;
      this.FirstName = firstname;
      this.LastName = lastname;
      this.PhoneNumber = phonevalue;
      this.Password = password;
      this.RepeatPassword = password;
      this.InvitationToken = invitationToken;
      this.IdInvoice = invoiceId;
  }
}
export class GooglePerson extends Person {
  public idToken: string;
  public accessToken: string;
}

export class LoginPerson {
  Email: string;
  Password: string;
  GoogleAccessToken: string;

  constructor(email = '', password = '', googleAccessToken = '') {
    this.Email = email;
    this.Password = password;
    this.GoogleAccessToken = googleAccessToken;
  }
}

export const BIStartPlanningProperties: string[] = [
  'GTOrderId',
  'GTEmail',
  'GTTransId1',
  'GTProductName1',
  'GTProductDate1',
  'GTProductTime1'
];

export interface BIStartPlanning {
  GTOrderId: string,
  GTEmail: string,
  GTTransId1: string,
  GTProductName1: string,
  GTProductDate1: string,
  GTProductTime1: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private URLs: URLConfig,
    private ClientAuth: ClientAuthConfig,
    private store: Store<fromRoot.State>,
    private _contactService: ContactService,
    private zone: NgZone,
    private router: Router,
    private _gettingStartedService: GettingStartedService,
    private _appStateService: AppStateService,
    private _route: ActivatedRoute,
    private configService: NgxConfigureService,
    private sanitizer: DomSanitizer,
  ) {}

  isLoggedIn(): void {
    const accessToken = localStorage.getItem('access_token') ? true : false;
    const loginStatus = localStorage.getItem('login_status') || 'none';

    this.store.dispatch(new user.SetLoggedIn(accessToken));
    this.store.dispatch(new user.SetLoginStatus(loginStatus));

    if (accessToken) {
      this.setUserInfo(loginStatus);
    }
    if (!accessToken) {
      this.clearUserState();
    }
  }

  setUserInfo(loginStatus?): void {
    if (!localStorage.access_token) {
      return;
    }
    this.getUserInfo().subscribe(({Obj: userInfo}) => {
      this.storeInfo({...userInfo, loginStatus});
      this.getPicture().subscribe(picture => {
        if (picture !== null) {
          this.store.dispatch(new user.SetProfilePicture(picture));
        } else {
          this.store.dispatch(new user.SetDefaultProfilePicture());
        }
      });
      this.getLeaderStatus().subscribe(({Obj: leaderRes}) => {
        this.store.dispatch(new user.SetLeaderStatus(leaderRes));
      });
      this.getPaymentMethod().subscribe(
        (paymentMethod) => {this.store.dispatch(new user.SetPaymentMethod({paymentMethod}));},
        (error) => {this.store.dispatch(new user.SetPaymentMethod({paymentMethod: ''}));}, // swallow error // TODO: Handle this error appropriately
        () => {}, // complete
      );
      this.getAlternatePaymentMethod().subscribe(
        (paymentMethod) => {this.store.dispatch(new user.SetAlternatePaymentMethod(paymentMethod));},
        (error) => {this.store.dispatch(new user.SetAlternatePaymentMethod(''));}, // swallow error // TODO: Handle this error appropriately
        () => {}, // complete
      );
    });
  }

  getLeaderStatus(): Observable<any> {
    return this.http.get<any>(this.URLs._isGroupLeader());
  }

  requestGroupLeaderRole(): Observable<any> {
    return this.http.post<any>(this.URLs._requestNewRole(), { 'role': 'GroupLeader' });
  }

  getPaymentMethod(): Observable<string> {
    return this.http.get<GTResponse<string>>(this.URLs._getPaymentMethod()).pipe(
      handleGTResponse(),
    );
  }

  getAlternatePaymentMethod(id?): Observable<string> {
    return this.http.get<GTResponse<string>>(this.URLs._paymentGetAlternatePaymentMethod(id)).pipe(
      handleGTResponse(),
      map(alternatePaymentMethod => this.sanitizer.sanitize(SecurityContext.HTML, alternatePaymentMethod)),
    );
  }

  getUserInfo(userID = ''): Observable<any> {
    return this.http.get<any>(this.URLs._userinfourl());
  }

  isRegisteredUser(email = ''): Observable<any> {
    return this.http.post<any>(this.URLs._isregistereduserurl(), {email: email});
  }

  isLoggedInObservable(): Observable<any> {
    const accessToken = localStorage.getItem('access_token') ? true : false;
    const loginStatus = localStorage.getItem('login_status') || 'none';

    this.store.dispatch(new user.SetLoggedIn(accessToken));
    this.store.dispatch(new user.SetLoginStatus(loginStatus));

    if (accessToken) {
      return this.getUserInfo();
    }
    if (!accessToken) {
      this.clearUserState();
    }
    return null;
  }

  public storeInfo(userInfo: any): void {
    this.store.dispatch(new user.SetUserID(Number(userInfo.Id)));
    this.store.dispatch(new user.SetEmail(userInfo.Email));

    if (userInfo.UserStatus === 'Unregistered') {
      this.store.dispatch(new user.SetRegisteredStatus(false));
      this.store.dispatch(new user.SetVerifiedStatus(false));
      this.store.dispatch(new user.SetFirstName(userInfo.FirstName));
      this.store.dispatch(new user.SetLastName(userInfo.LastName));
    } else {
      const userDataRegisteredData = <User>{
        isRegistered: true,
        isVerified: userInfo.UserStatus === 'RegisteredAndVerified' ? true : false,
        firstName: userInfo.FirstName,
        lastName: userInfo.LastName,
        roles: userInfo.UserRoles,
        phoneNumber: userInfo.PhoneNumber,
        addressState: userInfo.AddressState,
        about: (userInfo.SocialData && userInfo.SocialData.About) ? userInfo.SocialData.About : null
      }

      this.store.dispatch(new user.SetUserRegisteredData(userDataRegisteredData))

      if (userInfo.loginStatus !== 'partial') {
        this.store.dispatch(new ContactsActions.AuthenticationServiceLoadContacts());
      }
    }
  }

  public clearUserState () {
    this.store.dispatch(new user.SetDefault);
  }

  public forgotPassword(userEmail: string): Observable<any> {
    return this.http.post<any>(this.URLs._forgotpass(''), {userEmail: userEmail});
  }

  logErrorMsg(err): Observable<any> {
    return this.http.get<any>(this.URLs._errorLoggingUrl() + err).pipe(
      map(res => res.Obj));
  }

  public verifyUserEmailToken(token): Observable<any> {
    return this.http.get<any>(
      `${this.URLs._verifyuseremailtokenurl()}${token}/?apiKey=${this.configService.config.authVerifyToken}`
    );
  }

  public verifyEmailToken(token): Observable<any> {
    return this.http.get<any>(
      `${this.URLs._verifytokenurl()}${token}/?apiKey=${this.configService.config.authVerifyToken}`
    );
  }

  public insertPicture(picture?): Observable<any> {
    return this.http.post<any>(this.URLs._pictureuploadurl(), picture);
  }

  public getPicture(): Observable<string> {
    return this.http.get<GTResponse<string>>(this.URLs._picturegeturl()).pipe(
      handleGTResponse(),
    );
  }

  getPaymentInfo(): Observable<any> {
    return this.http.get<any>(this.URLs._paymentinfogeturl()).pipe(
      map((res) => {
        return res.Obj;
      }));

  }
  insertPaymentInfo(paymentInfo?): Observable<any> {
    return this.http.post<any>(this.URLs._paymentinfoaddurl(), paymentInfo);

  }
  logError(err): Observable<any> {
    return this.http.post<any>(this.URLs._errorLoggingUrl(), err);
  }
  getEmailVerification(email): Observable<any> {
    return this.http.post<any>(this.URLs._emailurl() + email, JSON.stringify({ email })).pipe(
      map((res) => {
        return res.Result;
      }));
  }

  facebookLogin(facebookUser) {
    let loginPerson;
    this.zone.run(() => {
      const token = encodeURIComponent(facebookUser.AccessToken);
      loginPerson = new LoginPerson(facebookUser.Email, token);
    });
    return this.login(loginPerson, 'facebook');
  }

  amazonLogin(amazonUser) {
    let loginPerson;
    this.zone.run(() => {
      const token = encodeURIComponent(amazonUser.accessToken);
      loginPerson = new LoginPerson(amazonUser.Email, token);
    });
    return this.login(loginPerson, 'amazon');
  }

  googleLogin(googleUser): Observable<any> {
    let profile;
    this.zone.run(() => {
      profile = googleUser.getBasicProfile();
    });
    const email = profile.getEmail();
    const idToken = googleUser.getAuthResponse().id_token;
    const accessToken = googleUser.getAuthResponse().access_token;
    const encodedPassword = encodeURIComponent(idToken);
    const encodedAccessToken = encodeURIComponent(accessToken);

    const loginPerson = new LoginPerson(email, encodedPassword, encodedAccessToken);

    return this.login(loginPerson, 'google');
  }
  login(person: LoginPerson, social?: string): Observable<any> {
    localStorage.clear();
    this._appStateService.setEmailPrefill('');

    let credentials: string;
    if (social && social === 'google') {
      credentials = 'grant_type=password'
      + '&userName=' + encodeURIComponent(person.Email)
      + '&password=' + person.Password
      + '&type=google_signin'
      + '&gat=' + person.GoogleAccessToken;
    } else if (social && social === 'amazon') {
      credentials = 'grant_type=password'
      + '&userName=' + encodeURIComponent(person.Email)
      + '&password=' + person.Password
      + '&type=amazon_signin';
    } else if (social && social === 'facebook') {
      credentials = 'grant_type=password'
      + '&userName=' + encodeURIComponent(person.Email)
      + '&password=' + person.Password
      + '&type=facebook_signin';
    } else {
      credentials = 'grant_type=password'
        + '&userName=' + encodeURIComponent(person.Email)
        + '&password=' + encodeURIComponent(person.Password);
    }

    return this.http.post<any>(this.URLs._tokenUrl(), credentials).pipe(
      map((res) => {
        if (res.token_type) {
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          localStorage.setItem('userID', res.userID);
          localStorage.setItem('login_status', res.login_status);
          localStorage.setItem('lastLogin', res.LastLogin);
          localStorage.removeItem('need-refresh');

          if(res.user_status && res.user_status === 'RegisteredAndVerified') {
            this.store.dispatch(new user.SetVerifiedStatus(true));
          }

          this.isLoggedIn();
        }
        return res;
      }));
  }

  adminLogin(token: string): Observable<any> {
    localStorage.clear();

    let credentials: string;
    credentials = 'grant_type=password&username=' + '_'
      + '&password=' + token
      + '&type=admin';

    return this.http.post<any>(this.URLs._tokenUrl(), credentials).pipe(
      map((res) => {
        if (res.token_type) {
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          localStorage.setItem('userID', res.userID);
          localStorage.setItem('login_status', res.login_status);
          localStorage.setItem('lastLogin', res.LastLogin);
          localStorage.removeItem('need-refresh');
          this.isLoggedIn();
        }
        return res;
      }));
  }

  public setLocalStorage(res) {
    if (res.token_type) {
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      localStorage.setItem('userID', res.userID);
      localStorage.setItem('login_status', res.login_status);
      localStorage.setItem('lastLogin', res.LastLogin);
      localStorage.removeItem('need-refresh');
      this.isLoggedIn();
    }
  }

  public refresh(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': this.ClientAuth.basicAuthHeader()
    });

    const options = { headers: headers };
    const credentials = ['grant_type=refresh_token&refresh_token=', localStorage.getItem('refresh_token')].join('');

    return this.http.post<any>(this.URLs._tokenUrl(), credentials, options).pipe(
      map((res) => {
        if (res.token_type) {
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          localStorage.setItem('userID', res.userID);
          localStorage.setItem('login_status', res.login_status);
        }

        return res;
      }));
  }

  logout(): void {
    this.logoutNoNavigation();

    this.router.navigateByUrl('/login', {
      queryParams: {
        ...this._route.snapshot.queryParams,
        returnUrl: undefined
      }
    });
  }

  public logoutNoNavigation() {
    localStorage.clear();
    sessionStorage.clear();
    this._appStateService.setEmailPrefill('');

    //If you're logged into the Google app, sign you out
    try {
      gapi.load('auth2', () => {
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2) {
          auth2.signOut();
        }
      });
    } catch(e) {
      //console.error(e);
    }
    this.store.dispatch(new attendeePackages.Logout());
    this.store.dispatch(new ContactsActions.AuthenticationServiceLogout());
    this.store.dispatch(new event.Logout());
    this.store.dispatch(new user.Logout());
    this.store.dispatch(new ActionActions.AuthenticationServiceLogout());
    this.store.dispatch(new PaymentActions.AuthenticationServiceLogout());
    this.store.dispatch(new PackageDetailsActions.AuthenticationServiceLogout());
    this.store.dispatch(new MessageActions.AuthenticationServiceLogout());
    this.store.dispatch(new ContactsActions.AuthenticationServiceLogout());
    this.store.dispatch(new SurveysActions.AuthenticationServiceLogout());
  }

  public getToken() {
    return localStorage.getItem('access_token');
  }

  loginByEmailCode(email: string, code: string): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token ? ['Bearer ', token] : this.ClientAuth.basicAuthHeader()
                              });
    const options = { headers: headers };
    const credentials = 'grant_type=password'
      + '&userName=' + email
      + '&password=' + code
      + '&type=email_invite';

    return this.http.post<any>(this.URLs._tokenUrl(), credentials, options).pipe(
      map(res => {
        if (res.token_type) {
          localStorage.clear();
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          localStorage.setItem('userID', res.userID);
          localStorage.setItem('login_status', res.login_status);
          localStorage.setItem('lastLogin', res.LastLogin);
        }
      }));
  }



  insertPerson(person): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    if (person !== undefined) {
      if (typeof person === 'string') {
        person = JSON.parse(person);
      }

      person.APIKey = this.configService.config.authPersonApiKey;
      this.setTrackingDetails(person);
    }

    return this.http.post<any>(
      this.URLs._contactRegisterUrl(), person, options);
  }

  registerViaGoogle(person): Observable<any> {
    if (person !== undefined) {
      if (typeof person === 'string') {
        person = JSON.parse(person);
      }

      person.APIKey = this.configService.config.authPersonApiKey;
      this.setTrackingDetails(person);
    }

    return this.http.post<any>(this.URLs._registerViaGoogle(), person).pipe(
      map(res => res.Obj));
  }

  registerViaAmazon(person): Observable<any> {
    if (person !== undefined) {
      if (typeof person === 'string') {
        person = JSON.parse(person);
      }

      person.APIKey = this.configService.config.authPersonApiKey;
      this.setTrackingDetails(person);
    }

    return this.http.post<any>(this.URLs._registerViaAmazon(), person).pipe(
      map(res => res.Obj));
  }

  registerViaFacebook(person): Observable<any> {
    if (person !== undefined) {
      if (typeof person === 'string') {
        person = JSON.parse(person);
      }

      person.APIKey = this.configService.config.authPersonApiKey;
      this.setTrackingDetails(person);
    }

    return this.http.post<any>(this.URLs._registerViaFacebook(), person);
  }

  updateProfile(person): Observable<any> {
    return this.http.post<any>(this.URLs._updateProfile(), person).pipe(
      map(res => res.Obj),
      tap(res => {
        if (res.FirstName) {
          this.store.dispatch(new user.SetFirstName(res.FirstName));
        }
        if (res.LastName) {
          this.store.dispatch(new user.SetLastName(res.LastName));
        }
        if (res.PhoneNumber) {
          this.store.dispatch(new user.SetPhoneNumber(res.PhoneNumber));
        }

        if (res.AddressState) {
          this.store.dispatch(new user.SetAddressState(res.AddressState));
        }
      }));
  }

  changePassword(passwords): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.post(this.URLs._passwordchangeurl(), passwords, options);
  }

  //Mutates the person object and adds tracking details.
  //The tracking details are retrieved from session storage.
  //Once retrieved, we remove them as we only want to use them for the first registration that occurs per session.
  //Otherwise, tabs that remain open could incorrectly track someone.
  setTrackingDetails(person : any) {
    if(person) {
      //Set Origin Data for Registration
      if (window.sessionStorage) {
        person.TrackingDetails = {};

        Object.keys(window.sessionStorage).forEach(key => {
          const lowercaseKey = key.toLowerCase();
          if(lowercaseKey.startsWith('utm_') || lowercaseKey === 'referrer') {
            person.TrackingDetails[lowercaseKey] = window.sessionStorage[key] || '';
            // ska 2018-10-24 just remove the keys being read here
            window.sessionStorage.removeItem(key);
          } 
        });
      }
    }
  }

    public createBIPlaceHolderEvent(data?: BIStartPlanning): void {
        const doc: any = {
            inviteOthers: true,
            inviteOthersCount: 99,
            activityPlaceholders: ['BI']
        };
        if (data) {
          doc.BIData = data;
        }

        for (const key of BIStartPlanningProperties) {
          sessionStorage.removeItem(key);
        }

        const formValue:any = {
            IdTenant: 1,
            JsonDoc: JSON.stringify(doc)
        };
        if (data) {
          formValue.PackageName = data.GTProductName1 + ' on ' + data.GTProductDate1;
        }
        this.tryCreate(formValue);
    }

    private tryCreate(eventData) {
        this._gettingStartedService.saveEvent(eventData)
        .subscribe(({IdPackage: eventId}) => {
            // navigate to package detail page when the new event is created
            this.router.navigateByUrl('/pages/package-details/' + eventId);
        },
        (err) => {
            this.requestGroupLeaderRole().subscribe(requestLeaderRes => {
                if (requestLeaderRes.Result) {
                    this.refresh().subscribe(authRes => {
                        this.setUserInfo();
                      // now user is a group leader, try to create the event again
                      this.tryCreate(eventData);
                    });
                }
            })
        });
    }
}
