import { Action } from "@ngrx/store";
import { EventItem } from "app/store/models/eventItem";
import { EimTab } from "app/store/models/eventItemState";
import { AttendeeUpsertRequest, AttendeeUpsertRequestValid } from "app/store/models/requests/attendee-upsert-request";
import { GTCollectionPayload, GTResponseCodes } from 'app/store/models/gtResponse';
import { ShowInfo } from "app/store/models/show-info";
import { TicketReadyNotificationResponse } from "app/store/models/responses/ticket-ready-notification-response";
import { FetchedTicketResult } from 'app/store/models/responses/fetched-ticket-result';
import { GTFile } from 'app/store/models/gt-file';
import { TicketFormat } from "app/store/models/requests/download-tickets-request";
import { InviteEventAttendeesRequest } from '../models/requests/invite-event-attendees-request';
import { UpsertAttendeesRequest } from "../models/requests/upsert-attendees-request";

export enum ActionTypes {
    EimPageAssignAttendeeToEventItem = '[Event Item Manager Page] Assign Attendee to Event Item',
    EimPageUnassignAttendeeFromEventItem = '[Event Item Manager Page] Unassign Attendee from Event Item',
    EimApiAutosaveAssignmentsSuccess = '[Event Item Api] Autosave Assignments Success',
    EimApiAutosaveAssignmentsFailure = '[Event Item Api] Autosave Assignments Failure',

    EimPageLoadAttendees = '[Event Item Manager Page] Load Attendees',
    EimApiLoadAttendeesSuccess = '[Event Item Api] Load Attendees Success',
    EimApiLoadAttendeesFailure = '[Event Item Api] Load Attendees Failure',

    // Parsing attendees from csv is taken care of locally
    // EIM_PAGE_PARSE_ATTENDEES_FROM_CSV = '[Event Item Manager Page] Parse Attendees From Csv',
    // EIM_PAGE_PARSE_ATTENDEES_FROM_CSV_SUCCESS = '[Event Item Manager Page] Parse Attendees From Csv Success',
    // EIM_PAGE_PARSE_ATTENDEES_FROM_CSV_FAILURE = '[Event Item Manager Page] Parse Attendees From Csv Failure',

    // EIM_PAGE_ADD_IMPORTED_ATTENDEES = '[Event Item Manager Page] Add Imported Attendees',

    EimPageLoadEventItems = '[Event Item Manager Page] Load Event Items',
    EimApiLoadEventItemsSuccess = '[Event Item Api] Load Event Items Success',
    EimApiLoadEventItemsFailure = '[Event Item Api] Load Event Items Failure',

    EimPageDeleteAttendee = '[Event Item Manager Page] Delete Attendee',
    EiEffectsDeleteExistingAttendee = '[Event Item Effects] Delete Existing Attendee',
    EiEffectsDeleteNewAttendee = '[Event Item Effects] Delete New Attendee',
    EimApiDeleteAttendeeSuccess = '[Event Item Api] Delete Attendee Success',
    EimApiDeleteAttendeeFailure = '[Event Item Api] Delete Attendee Failure',
    EimPageDeletedIdsProcessed = '[Event Item Manager Page] Deleted Ids Processed',

    EimPageAttendeesHaveBeenEdited = '[Event Item Manager Page] Attendees Have Been Edited', // This is dispatched in the component for autosaving
    EiEffectsSaveAttendees = '[Event Item Effects] Save Attendees', // Used internally in eventItem.effect.ts for autosaving flow
    EiEffectsNoAttendeesToSave = '[Event Item Effects] No Attendees To Save', // Used internally in eventItem.effect.ts for autosaving flow
    EimPageSaveAttendees = '[Event Item Manager Page] Save Attendees', // Called indirectly in eventItem.effect.ts when using autosaving. Call directly when saving synchronously (blocking UI until call is finished)
    EimApiSaveAttendeesSuccess = '[Event Item Manager Api] Save Attendees Success',
    EimApiSaveAttendeesFailure = '[Event Item Manager Api] Save Attendees Failure',

    EimPageSelectTab = '[Event Item Manager Page] Select Tab',

    EimGetShowList = '[Order Number Login Page] Get Show List',
    EimGetShowListSuccess = '[Auth Api] Get Show List Success',
    EimGetShowListFailure = '[Auth Api] Get Show List Failure',

    EimPageNotifyAttendeesTicketsReady = '[Event Item Manager Page] Notify Attendees Tickets Ready',
    EimPageNotifySelectedAttendeesTicketsReady = '[Event Item Manager Page] Notify Selected Attendees Tickets Ready',
    EimApiNotifyAttendeesTicketsReadyResponse = '[Event Item Manager Api] Notify Attendees Tickets Ready Response',
    EimApiNotifyAttendeesTicketsReadyFailure = '[Event Item Manager Api] Notify Attendees Tickets Ready Failure',
    EimApiNotifyAttendeesTicketsReadyTooSoonError = '[Event Item Manager Api] Notify Attendees Tickets Ready Too Soon Error',

    EimPageGroupLeaderDownloadTickets = '[Event Item Manager Page] Group Leader Download Tickets',
    EimPageGroupLeaderDownloadSelectedTickets = '[Event Item Manager Page] Group Leader Download Selected Tickets',
    EimPageGroupLeaderDownloadAllTickets = '[Event Item Manager Page] Group Leader Download All Tickets',
    EimPageGroupLeaderDownloadTooManyTicketsError = '[Event Item Manager Page] Group Leader Download Too Many Tickets Error',
    EimApiGroupLeaderDownloadTicketsTooSoonError = '[Event Item Manager Api] Group Leader Download Tickets Too Soon Error',
    EimApiGroupLeaderDownloadTicketsResponse = '[Event Item Manager Api] Group Leader Download Tickets Response',
    EimApiGroupLeaderDownloadTicketsFailure = '[Event Item Manager Api] Group Leader Download Tickets Failure',

    EimPageDownloadManifest = '[Event Item Manager Page] Download Manifest',
    EimApiDownloadManifestResponse = '[Event Item Manager Page] Download Manifest Response',
    EimApiDownloadManifestFailure = '[Event Item Manager Page] Download Manifest Failure',

    EimEffectsDownloadFile = '[Event Item Effects] Download File',

    EimPageSetDeliverySelectionState = '[Event Item Manager Page] Set Delivery Selection State',
    EimPageSetDeliverySelectionStateAll = '[Event Item Manager Page] Set Delivery Selection State All',

    // WARNING: The string below needs be the exact same string from the same action in authentication.action.ts to prevent warnings
    AuthEffectsClearAllData = '[Auth Effect] Clear All Data',
}

export class EimPageAssignAttendeeToEventItem implements Action {
    readonly type = ActionTypes.EimPageAssignAttendeeToEventItem;
    constructor(public payload: {eventItem: EventItem, attendee: AttendeeUpsertRequest}) {}
}
export class EimPageUnassignAttendeeFromEventItem implements Action {
    readonly type = ActionTypes.EimPageUnassignAttendeeFromEventItem;
    constructor(public payload: {eventItem: EventItem}) {}
}
export class EimApiAutosaveAssignmentsSuccess implements Action {
    readonly type = ActionTypes.EimApiAutosaveAssignmentsSuccess;
    constructor(public payload: {eventItems: EventItem[]}) {}
}
export class EimApiAutosaveAssignmentsFailure implements Action {
    readonly type = ActionTypes.EimApiAutosaveAssignmentsFailure;
    constructor(public payload: {errorMessage: string}) {}
}

export class EimPageLoadAttendees implements Action {
    readonly type = ActionTypes.EimPageLoadAttendees;
    constructor() {}
}
export class EimApiLoadAttendeesSuccess implements Action {
    readonly type = ActionTypes.EimApiLoadAttendeesSuccess;
    constructor(public payload: {attendees: AttendeeUpsertRequest[]}) {}
}
export class EimApiLoadAttendeesFailure implements Action {
    readonly type = ActionTypes.EimApiLoadAttendeesFailure;
    constructor(public payload: {errorMessage: string}) {}
}

// export class EimPageAddImportedAttendees implements Action {
//     readonly type = ActionTypes.EIM_PAGE_ADD_IMPORTED_ATTENDEES;
//     constructor(public payload: {importedAttendees: AttendeeUpsertRequest[]}) {}
// }

export class EimPageLoadEventItems implements Action {
    readonly type = ActionTypes.EimPageLoadEventItems;
    constructor() {}
}
export class EimApiLoadEventItemsSuccess implements Action {
    readonly type = ActionTypes.EimApiLoadEventItemsSuccess;
    constructor(public payload: {eventItems: EventItem[]}) {}
}
export class EimApiLoadEventItemsFailure implements Action {
    readonly type = ActionTypes.EimApiLoadEventItemsFailure;
    constructor(public payload: {errorMessage: string}) {}
}

export class EimPageDeleteAttendee implements Action {
    readonly type = ActionTypes.EimPageDeleteAttendee;
    constructor(public payload: {eventAttendeeId: number, newEventAttendeeId: number, eventId: number}) {}
}
export class EiEffectsDeleteExistingAttendee implements Action {
    readonly type = ActionTypes.EiEffectsDeleteExistingAttendee;
    constructor(public payload: {eventAttendeeId: number, eventId: number}) {}
}
export class EiEffectsDeleteNewAttendee implements Action {
    readonly type = ActionTypes.EiEffectsDeleteNewAttendee;
    constructor(public payload: {newEventAttendeeId: number}) {}
}
export class EimApiDeleteAttendeeSuccess implements Action {
    readonly type = ActionTypes.EimApiDeleteAttendeeSuccess;
    constructor(public payload: {deletedEventAttendeeIds: number[]}) {}
}
export class EimApiDeleteAttendeeFailure implements Action {
    readonly type = ActionTypes.EimApiDeleteAttendeeFailure;
    constructor(public payload: {errorMessage: string, failedEventAttendeeId: number}) {}
}
export class EimPageDeletedIdsProcessed implements Action {
    readonly type = ActionTypes.EimPageDeletedIdsProcessed;
    constructor(public payload: {
        deletedEventAttendeeIds: number[];
        deletedNewEventAttendeeIds: number[];
        failedToDeleteEventAttendeeIds: number[];
        failedToDeleteNewEventAttendeeIds: number[];
    }) {}
}

export class EimPageAttendeesHaveBeenEdited implements Action {
    readonly type = ActionTypes.EimPageAttendeesHaveBeenEdited;
    constructor(public payload: {allAttendees: AttendeeUpsertRequestValid[], eventId: number}) {}
}
export class EiEffectsSaveAttendees implements Action {
    readonly type = ActionTypes.EiEffectsSaveAttendees;
    constructor() {}
}
export class EiEffectsNoAttendeesToSave implements Action {
    readonly type = ActionTypes.EiEffectsNoAttendeesToSave;
    constructor() {}
}
export class EimPageSaveAttendees implements Action {
    readonly type = ActionTypes.EimPageSaveAttendees;
    constructor(public payload: {attendees: UpsertAttendeesRequest, eventId: number}) {}
}
export class EimApiSaveAttendeesSuccess implements Action {
    readonly type = ActionTypes.EimApiSaveAttendeesSuccess;
    constructor(public payload: {attendeeCollectionPayload: GTCollectionPayload<AttendeeUpsertRequest>}) {}
}
export class EimApiSaveAttendeesFailure implements Action {
    readonly type = ActionTypes.EimApiSaveAttendeesFailure;
    constructor(public payload: {errorMessage: string, failedAttendees: AttendeeUpsertRequest[]}) {}
}

export class EimPageSelectTab implements Action {
    readonly type = ActionTypes.EimPageSelectTab;
    constructor(public payload: {selectedTab: EimTab}) {}
}

export class EimGetShowList implements Action {
    readonly type = ActionTypes.EimGetShowList;
    constructor() {}
}
export class EimGetShowListSuccess implements Action {
    readonly type = ActionTypes.EimGetShowListSuccess;
    constructor(public payload: {showList: ShowInfo[]}) {}
}
export class EimGetShowListFailure implements Action {
    readonly type = ActionTypes.EimGetShowListFailure;
    constructor(public payload: {errorMessage: string}) {}
}

export class EimPageNotifyAttendeesTicketsReady implements Action {
    readonly type = ActionTypes.EimPageNotifyAttendeesTicketsReady;
    constructor(public payload: {eventItemIds: number[]}) {}
}
export class EimPageNotifySelectedAttendeesTicketsReady implements Action {
    readonly type = ActionTypes.EimPageNotifySelectedAttendeesTicketsReady;
    constructor() {}
}
export class EimApiNotifyAttendeesTicketsReadyResponse implements Action {
    readonly type = ActionTypes.EimApiNotifyAttendeesTicketsReadyResponse;
    constructor(public payload: {notificationCollectionPayload: GTCollectionPayload<TicketReadyNotificationResponse>}) {}
}
export class EimApiNotifyAttendeesTicketsReadyFailure implements Action {
    readonly type = ActionTypes.EimApiNotifyAttendeesTicketsReadyFailure;
    constructor(public payload: {errorMessage: string, responseCode: GTResponseCodes | null}) {}
}
export class EimApiNotifyAttendeesTicketsReadyTooSoonError implements Action {
    readonly type = ActionTypes.EimApiNotifyAttendeesTicketsReadyTooSoonError;
    constructor() {}
}

export class EimPageGroupLeaderDownloadTickets implements Action {
    readonly type = ActionTypes.EimPageGroupLeaderDownloadTickets;
    constructor(public payload: {eventItemIds: number[], ticketFormat: TicketFormat}) {}
}
export class EimPageGroupLeaderDownloadSelectedTickets implements Action {
    readonly type = ActionTypes.EimPageGroupLeaderDownloadSelectedTickets;
    constructor(public payload: {ticketFormat: TicketFormat}) {}
}
export class EimPageGroupLeaderDownloadAllTickets implements Action {
    readonly type = ActionTypes.EimPageGroupLeaderDownloadAllTickets;
    constructor(public payload: {ticketFormat: TicketFormat}) {}
}
export class EimPageGroupLeaderDownloadTooManyTicketsError implements Action {
    readonly type = ActionTypes.EimPageGroupLeaderDownloadTooManyTicketsError;
    constructor(public payload: {numberOfTicketsRequested: number, maxNumberOfTicketsAllowed: number}) {}
}
export class EimApiGroupLeaderDownloadTicketsTooSoonError implements Action {
    readonly type = ActionTypes.EimApiGroupLeaderDownloadTicketsTooSoonError;
    constructor() {}
}
export class EimApiGroupLeaderDownloadTicketsResponse implements Action {
    readonly type = ActionTypes.EimApiGroupLeaderDownloadTicketsResponse;
    constructor(public payload: {ticketsCollectionPayload: GTCollectionPayload<FetchedTicketResult>}) {}
}
export class EimApiGroupLeaderDownloadTicketsFailure implements Action {
    readonly type = ActionTypes.EimApiGroupLeaderDownloadTicketsFailure;
    constructor(public payload: {errorMessage: string, responseCode: GTResponseCodes}) {}
}

export class EimPageDownloadManifest implements Action {
    readonly type = ActionTypes.EimPageDownloadManifest;
    constructor() {}
}
export class EimApiDownloadManifestResponse implements Action {
    readonly type = ActionTypes.EimApiDownloadManifestResponse;
    constructor(public payload: {gtFile: GTFile}) {}
}
export class EimApiDownloadManifestFailure implements Action {
    readonly type = ActionTypes.EimApiDownloadManifestFailure;
    constructor(public payload: {errorMessage: string, responseCode: GTResponseCodes}) {}
}

export class EimEffectsDownloadFile implements Action {
    readonly type = ActionTypes.EimEffectsDownloadFile;
    constructor(public payload: {gtFile: GTFile}) {}
}

export class EimPageSetDeliverySelectionState implements Action {
    readonly type = ActionTypes.EimPageSetDeliverySelectionState;
    constructor(public payload: {selectionMap: Map<number, boolean>}) {}
}
export class EimPageSetDeliverySelectionStateAll implements Action {
    readonly type = ActionTypes.EimPageSetDeliverySelectionStateAll;
    constructor(public payload: {isAllSelected: boolean}) {}
}

//-----------------------------------------------------------------

// This isn't used directly, but its string matches the same action in authentication.action.ts so it prevents warnings from showing
export class AuthEffectsClearAllData implements Action {
    readonly type = ActionTypes.AuthEffectsClearAllData;
    constructor() {}
}

export type Actions
    = EimPageAssignAttendeeToEventItem
    | EimPageUnassignAttendeeFromEventItem
    | EimApiAutosaveAssignmentsSuccess
    | EimApiAutosaveAssignmentsFailure
    | EimPageLoadAttendees
    | EimApiLoadAttendeesSuccess
    | EimApiLoadAttendeesFailure
    // | EimPageAddImportedAttendees
    | EimPageLoadEventItems
    | EimApiLoadEventItemsSuccess
    | EimApiLoadEventItemsFailure

    | EimPageDeleteAttendee
    | EiEffectsDeleteExistingAttendee
    | EiEffectsDeleteNewAttendee
    | EimApiDeleteAttendeeSuccess
    | EimApiDeleteAttendeeFailure
    | EimPageDeletedIdsProcessed

    | EimPageAttendeesHaveBeenEdited
    | EiEffectsSaveAttendees
    | EiEffectsNoAttendeesToSave
    | EimPageSaveAttendees
    | EimApiSaveAttendeesSuccess
    | EimApiSaveAttendeesFailure

    | EimPageSelectTab

    | EimGetShowList
    | EimGetShowListSuccess
    | EimGetShowListFailure

    | EimPageNotifyAttendeesTicketsReady
    | EimPageNotifySelectedAttendeesTicketsReady
    | EimApiNotifyAttendeesTicketsReadyResponse
    | EimApiNotifyAttendeesTicketsReadyFailure
    | EimApiNotifyAttendeesTicketsReadyTooSoonError

    | EimPageGroupLeaderDownloadTickets
    | EimPageGroupLeaderDownloadSelectedTickets
    | EimPageGroupLeaderDownloadAllTickets
    | EimPageGroupLeaderDownloadTooManyTicketsError
    | EimApiGroupLeaderDownloadTicketsTooSoonError
    | EimApiGroupLeaderDownloadTicketsResponse
    | EimApiGroupLeaderDownloadTicketsFailure

    | EimPageDownloadManifest
    | EimApiDownloadManifestResponse 
    | EimApiDownloadManifestFailure

    | EimEffectsDownloadFile
    
    | EimPageSetDeliverySelectionState
    | EimPageSetDeliverySelectionStateAll

    | AuthEffectsClearAllData
;
