import { map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/authentication.service';
var EmailVerification = /** @class */ (function () {
    function EmailVerification() {
    }
    return EmailVerification;
}());
export { EmailVerification };
var Token = /** @class */ (function () {
    function Token() {
    }
    return Token;
}());
export { Token };
var Person = /** @class */ (function () {
    function Person(email, firstname, lastname, password, repeatpassword) {
        if (email === void 0) { email = ""; }
        if (firstname === void 0) { firstname = ""; }
        if (lastname === void 0) { lastname = ""; }
        if (password === void 0) { password = ""; }
        if (repeatpassword === void 0) { repeatpassword = ""; }
        this.Email = email;
        this.FirstName = firstname;
        this.LastName = lastname;
        this.Password = password;
        this.RepeatPassword = password;
    }
    return Person;
}());
export { Person };
var tokengetter = /** @class */ (function () {
    function tokengetter() {
    }
    return tokengetter;
}());
var RegisterService = /** @class */ (function () {
    function RegisterService(http, URLs, _authService) {
        this.http = http;
        this.URLs = URLs;
        this._authService = _authService;
    }
    RegisterService.prototype.getEmailVerification = function (email) {
        return this.http
            .post(this.URLs._emailurl() + email, JSON.stringify(email));
    };
    RegisterService.prototype.insertPerson = function (person) {
        if (person !== undefined) {
            if (typeof person === 'string') {
                person = JSON.parse(person);
            }
            person.APIKey = '515ECrdayo53r4C+VYSDGIC/Z0i8HE7O+dzU5xsKblk=';
            this._authService.setTrackingDetails(person);
        }
        return this.http.post(this.URLs._contactRegisterUrl(), person).pipe(map(function (res) {
            if (res.success) {
                localStorage.setItem('auth_token', res.auth_token);
                var loggedIn = true;
            }
            return res.Obj;
        }));
    };
    RegisterService.prototype.handleError = function (error) {
        // In a real world app, we might use a remote logging infrastructure
        var errMsg;
        if (error instanceof Response) {
            var body = error || '';
            var err = body || JSON.stringify(body);
            errMsg = error.status + " - " + (error.statusText || '') + " " + err;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        //console.error(errMsg);
        return observableThrowError(errMsg);
    };
    RegisterService.prototype.sendVerifyEmail = function () {
        return this.http.get(this.URLs._sendVerifyEmail()).pipe(map(function (res) {
            // if (res.success) {
            //   console.log(res);
            // }
            return res.Obj;
        }));
    };
    RegisterService.prototype.sendVerifyCode = function () {
        return this.http.get(this.URLs._sendVerifyCode()).pipe(map(function (res) {
            // if (res.Result) {
            //   console.log(res);
            // }
            return res;
        }));
    };
    RegisterService.prototype.ValidateVerificationCode = function (verifyCode) {
        return this.http.post(this.URLs._validateVerificationCode(), verifyCode).pipe(map(function (res) {
            return res;
        }));
    };
    RegisterService.prototype.GetVerificationCodeExpiration = function () {
        return this.http.get(this.URLs._getVerificationCodeExpiration()).pipe(map(function (res) {
            return res;
        }));
    };
    return RegisterService;
}());
export { RegisterService };
