import { Component, OnInit } from '@angular/core';
import { State as RootStoreState } from 'app/store/reducers';
import * as AttendeesActions from 'app/store/actions/attendees.action';
import * as AttendeesSelectors from 'app/store/selectors/attendees.selector';
import { select, Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { Attendee } from 'app/store/models/attendee';
import { takeUntil, map } from 'rxjs/operators';
import { Modal, ModalSize } from 'app/store/models/modal';
import { Actions, ofType } from '@ngrx/effects';
import { CloseModal } from 'app/store/actions/modal.action';

@Component({
  selector: 'change-attendee-emails',
  templateUrl: './change-attendee-emails.component.html',
  styleUrls: ['./change-attendee-emails.component.scss']
})
export class ChangeAttendeeEmailsComponent implements OnInit {

  public attendees$: Observable<Attendee[]>;
  public attendees: Attendee[];
  public unsubscribe$: Subject<void> = new Subject<void>();

  public saveInProgress: boolean = false;
  public savedEmails: number = 0;
  public savedMessage: string = '';
  constructor(
    private store$: Store<RootStoreState>,
    private actions$: Actions,
  ) { }

  ngOnInit() {
    this.attendees$ = this.store$.pipe(
      select(AttendeesSelectors.getAttendees),
      takeUntil(this.unsubscribe$),
      map(attendeeList => {
        this.attendees = attendeeList.filter(a => a.status.toLowerCase() === 'bounced');
        this.saveInProgress = false;
        return this.attendees;
      }),
    );

    // TODO: Catching an action directly in a component is not a great pattern,
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType<AttendeesActions.AttendeeEffectChangeAttendeeEmailsSuccess>(AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess),
    ).subscribe(action => {
      this.savedEmails = action.payload.attendees.length;
      if (this.savedEmails === 1) {
        this.savedMessage = 'Email has been updated';
      } else if (this.savedEmails > 1) {
        this.savedMessage = `${this.savedEmails} email addresses have been updated`;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
      this.store$.dispatch(new CloseModal());
  }

  updateEmails() {
    this.saveInProgress = true;
    const updatedAttendees = [];
    for (const a of this.attendees) {
      if (a['updatedEmail']) {
        updatedAttendees.push(a);
      }
    }
    this.store$.dispatch(new AttendeesActions.ChangeAttendeeEmailsComponentChangeAttendeeEmails({attendees: updatedAttendees}));
  }
}

export const ChangeAttendeeEmailsConfig: Modal = {
    title: 'Change Attendee Email',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: ChangeAttendeeEmailsComponent
    }
};
