import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddFeaturesComponent } from './add-features.component';
import { FeatureAddOnComponent } from './feature-add-on/feature-add-on.component';
import { FeatureTicketsComponent } from './feature-tickets/feature-tickets.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MaterialModule } from 'app/material';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        GTNgaModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonsModule,
        NgbModule,
        BsDropdownModule,
        GooglePlaceModule,
        WidgetsModule,
        MaterialModule
    ],
    declarations: [
        AddFeaturesComponent,
        FeatureAddOnComponent,
        FeatureTicketsComponent,
        AdditionalInfoComponent
    ],
    exports: [
        AddFeaturesComponent
    ]
})
export class AddFeaturesModule {

}
