<div class="gled-add-activity-lodging">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <p class="title" id="stepOne" [ngClass]="{'clickable':steps.stepOne.clickable,'title-margin':isFirstFeature}">
            {{ !!isFirstFeature ? '1. Tell us about your event' : '1. Tell us about the accommodation'}}
            <i class="fa fa-check step-check" *ngIf="steps.stepOne.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-add-on> begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="feature-add-on">
            <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
            <div class="form-group" *ngIf="!isFirstFeature">
                <grouptools-label for="isRequired" class="feature-required-popover"
                    [helperText]="'At least one activity is required. A required activity means that all attendees must opt-in. An attendee can opt in to an optional activity at any time. This cannot be changed once invites have been sent.'"
                    [isQuestion]="true">
                    <label>Is this activity required?</label>
                </grouptools-label>
                <input type="radio" formControlName="isRequired" value="Yes"><span class="required-opt">Yes</span>
                <input type="radio" formControlName="isRequired" value="No"> <span class="required-opt">No</span>
                <label for="isRequired" *ngIf="formErrors.isRequired"
                    class="error-text">{{formErrors.isRequired}}</label>
            </div>

            <div class="form-group" [class.has-error]="formErrors.ComponentName">
                <grouptools-label for="addLodgingName" [helperText]="'Name of the activity.'"><label>Name:</label>
                </grouptools-label>
                <input type="text" class="form-control" id="addLodgingName" placeholder="Ex. Westin"
                    formControlName="ComponentName">
                <label for="addLodgingName" *ngIf="formErrors.ComponentName"
                    class="error-text">{{formErrors.ComponentName}}</label>
            </div>

            <div class="form-group less-form-bottom">
                <div class="row">
                    <div class="col-sm-6 less-right" [class.has-error]="formErrors.ComponentOccurDate">
                        <label for="ComponentOccurDate">Check In Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                formControlName="ComponentOccurDate" ngbDatepicker #ComponentOccurDate="ngbDatepicker"
                                container="body">
                            <div class="input-group-addon"
                                (click)="ComponentOccurDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, ComponentOccurDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="ComponentOccurDate" *ngIf="formErrors.ComponentOccurDate"
                            class="error-text">{{formErrors.ComponentOccurDate}}</label>
                    </div>
                    <div class="col-sm-6 less-left">
                        <label for="addLodgingOccurTime">Check In Time:</label>
                        <div class="input-group">
                            <input type="time" class="form-control" id="addLodgingOccurTime"
                                formControlName="ComponentOccurTime" style="height:46px;">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group less-form-bottom">
                <div class="row">
                    <div class="col-sm-6 less-right" [class.has-error]="formErrors.ComponentEndDate">
                        <label for="ComponentEndDate">Check Out Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                formControlName="ComponentEndDate" ngbDatepicker #ComponentEndDate="ngbDatepicker"
                                container="body">
                            <div class="input-group-addon" (click)="ComponentEndDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, ComponentEndDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="ComponentEndDate" *ngIf="formErrors.ComponentEndDate"
                            class="error-text">{{formErrors.ComponentEndDate}}</label>
                    </div>
                    <div class="col-sm-6 less-left">
                        <label for="addLodgingEndTime">Check Out Time:</label>
                        <div class="input-group">
                            <input type="time" class="form-control" id="addLodgingEndTime"
                                formControlName="ComponentEndTime" style="height:46px;">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" [class.has-error]="formErrors.ComponentDesc">
                <grouptools-label for="addLodgingDesc"
                    [helperText]="'Write something enticing about the activity. This will help invitees understand what the activity is all about and what it may include.'">
                    <label>{{ !!this.isFirstFeature ? 'Add an enticing description:' : 'Description:' }}</label>
                </grouptools-label>
                <textarea class="form-control" id="addLodgingDesc"
                    placeholder="{{ !!this.isFirstFeature ? 'Be descriptive, this is what your users will see when you send an invitation. ' : 'Hotel description' }}"
                    formControlName="ComponentDesc" formControlName="ComponentDesc"></textarea>
                <label for="ComponentDesc" *ngIf="formErrors.ComponentDesc"
                    class="error-text">{{formErrors.ComponentDesc}}</label>
            </div>

            <div class="form-group" [class.has-error]="formErrors.ComponentVenueLocation">
                <label for="addLodgingVenueLocation">Address:</label>
                <!-- <input ngx-google-places-autocomplete [options]='options' class="form-control"
                    id="addLodgingVenueLocation" placeholder="Location of the hotel"
                    formControlName="ComponentVenueLocation" #placesRef="ngx-places"
                    (onAddressChange)="handleVenueAddressChange($event)" /> -->
                <!--<input type="text" class="form-control" id="ComponentVenueLocation" placeholder="What's the address?" formControlName="ComponentVenueLocation">-->
                <label for="addLodgingVenueLocation" *ngIf="formErrors.ComponentVenueLocation"
                    class="error-text">{{formErrors.ComponentVenueLocation}}</label>
            </div>

            <div class="form-group less-form-bottom" *ngIf="isFirstFeature">
                <div class="row">
                    <div class="col-sm-12 less-right" [class.has-error]="formErrors.RSVPDueDate">
                        <label for="RSVPDueDate">RSVP Due Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp" formControlName="RSVPDueDate"
                                ngbDatepicker #RSVPDueDate="ngbDatepicker" container="body">
                            <div class="input-group-addon" (click)="RSVPDueDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, RSVPDueDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="RSVPDueDate" *ngIf="formErrors.RSVPDueDate"
                            class="error-text">{{formErrors.RSVPDueDate}}</label>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="!(inModal && !isEdit)">
                <div class="form-group row">
                    <div class="col-xs-12">
                        <button [disabled]="disableSave ? true : null" type="submit"
                            class="btn btn-secondary form-control"
                            [ngClass]="{'add-feature-continue-btn': !inModal, 'add-feature-continue-btn-modal': inModal}">
                            <span>{{inModal ? 'Save & Continue' : 'Next Step'}}</span>
                        </button>
                    </div>
                </div>
            </div> -->
            <!-- </form> -->
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-add-on> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <p class="title" id="stepTwo"
            [ngClass]="{'clickable':false}">
            2. {{ !!isFirstFeature ? 'Dates, pricing and availability' : 'Pricing'}}
            <i class="fa fa-check step-check" *ngIf="steps.stepTwo.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-tickets> Begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="feature-tickets">
            <label class="ticket-title">Let's start off with some basics. You'll be able to add multiple locations once
                complete.</label>
            <!-- <form [formGroup]="featureTicketsForm" (ngSubmit)="onSubmit()" class="feature-tickets-form"> -->
            <div class="feature-tickets-form">
                <div class="form-group" *ngIf="!multiOptions" [class.has-error]="formErrors.LodgingSeatLocation">
                    <grouptools-label for="LodgingSeatLocation" [helperText]="'room type help text'">
                        <label class="label-less-margin">Room Type</label>
                    </grouptools-label>
                    <input type="text" class="form-control" id="LodgingSeatLocation"
                        formControlName="LodgingSeatLocation" placeholder="Ex. Double Occupancy King Room">
                    <label for="LodgingSeatLocation" *ngIf="formErrors.LodgingSeatLocation"
                        class="error-text">{{formErrors.LodgingSeatLocation}}</label>
                </div>


                <div class="form-group" *ngIf="!multiOptions">
                    <div class="row">
                        <div class="col-sm-3 col-sm-6" [class.has-error]="formErrors.ComponentPrice">
                            <grouptools-label for="ComponentPrice"
                                [helperText]="'Enter 0 if the event/activity is free. The amount you will charge your attendees opting into this activity.'">
                                <label class="label-less-margin">Price:</label>
                            </grouptools-label>
                            <input [readonly]="isEdit && multiOptions" min="0" type="number" class="form-control"
                                id="ComponentPrice" formControlName="ComponentPrice">
                            <label for="ComponentPrice" *ngIf="formErrors.ComponentPrice"
                                class="error-text">{{formErrors.ComponentPrice}}</label>
                        </div>
                    </div>
                </div>

                <div>
                    <p>Tell us about your attendee requirements</p>
                    <hr>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-6 less-right">
                            <label for="DepositAmount">Deposit Amount(If any):</label>
                            <div class="input-group">
                                <input type="number" class="form-control" id="DepositAmount"
                                    formControlName="DepositAmount">
                            </div>
                            <label for="DepositAmount" *ngIf="formErrors.DepositAmount"
                                class="error-text">{{formErrors.DepositAmount}}</label>
                        </div>
                        <div class="col-sm-6 less-left" [class.has-error]="formErrors.DepositDueDate">
                            <label for="DepositDueDate">Deposit Due Date(If any):</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                    formControlName="DepositDueDate" ngbDatepicker #DepositDueDate="ngbDatepicker"
                                    container="body">
                                <div class="input-group-addon"
                                    (click)="DepositDueDate.toggle();$event.stopPropagation();"
                                    (document:click)="datePickerUtility.tryClose($event, DepositDueDate)">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                            <label for="DepositDueDate" *ngIf="formErrors.DepositDueDate"
                                class="error-text">{{formErrors.DepositDueDate}}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group" [class.has-error]="formErrors.FinalPaymentDue">
                    <grouptools-label for="FinalPaymentDue"
                        [helperText]="'The last day you are willing to accept payments for your event. This date will affect attendee opt in dates.'">
                        <label class="label-less-margin">Final Payment Due:</label>
                    </grouptools-label>
                    <div class="input-group final-less-margin">
                        <input class="form-control" placeholder="mm/dd/yyyy" name="dp" formControlName="FinalPaymentDue"
                            ngbDatepicker #FinalPaymentDue="ngbDatepicker" container="body">
                        <div class="input-group-addon" (click)="FinalPaymentDue.toggle();$event.stopPropagation();"
                            (document:click)="datePickerUtility.tryClose($event, FinalPaymentDue)">
                            <i class="fa fa-calendar"></i>
                        </div>
                    </div>
                    <label for="FinalPaymentDue" *ngIf="formErrors.FinalPaymentDue"
                        class="error-text">{{formErrors.FinalPaymentDue}}</label>
                </div>

                <!-- Add ng-content so that we can show the price increase message in edit modal -->
                <ng-content></ng-content>

                <!-- <div *ngIf="!(inModal && !isEdit)">
                    <div class="form-group row">
                        <div class="col-xs-12">
                            <button type="submit" [disabled]="disableSave ? true : null"
                                class="btn btn-secondary form-control"
                                [ngClass]="{'add-feature-continue-btn': !inModal, 'add-feature-continue-btn-modal': inModal}">
                                <span>{{inModal ? 'Save & Continue' : 'Next Step'}}</span>
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- </form> -->
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-tickets> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <p class="title" id="stepThree">
            3. Additional Information
            <i class="fa fa-check step-check" *ngIf="steps.stepThree.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <additional-info> Begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="additional-info">
            <div *ngIf="!isFirstFeature">
                <grouptools-label for="featurePhoto"
                    [helperText]="'The photo is optional but useful to give your attendees a visual of the activity. This could be a photo, a logo or whatever you think makes the activity interesting.'">
                </grouptools-label>
                <div class="feature-photo" (click)="changePhoto.emit()"
                    [ngClass]="{'exist-image-height':image,'none-image-height':!image}">
                    <p *ngIf="!image">Add {{photoType}}</p>
                    <img *ngIf="image" [src]="image">
                </div>
            </div>
            <div *ngIf="isFirstFeature">
                <label class="photo-title">Photos</label>
                <div class="feature-photo" (click)="changeEventPhoto.emit()"
                    [ngClass]="{'exist-image-height':eventImage,'none-image-height':!eventImage}">
                    <p *ngIf="!eventImage">Add an event photo. This is the primary photo of your event.</p>
                    <img *ngIf="eventImage" [src]="eventImage">
                </div>
                <div class="feature-photo" (click)="changePhoto.emit()"
                    [ngClass]="{'exist-image-height':image,'none-image-height':!image}">
                    <p *ngIf="!image">Add {{photoType}}. This is the secondary photo of your event.</p>
                    <img *ngIf="image" [src]="image">
                </div>
            </div>
        </div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <additional-info> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <div class="custom-feature-btns">
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-primary form-control save-btn" type="submit">
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-secondary form-control cancel-btn" type="button" (click)="cancelModal()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>