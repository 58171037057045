import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { URLConfig } from '../helpers/config.service';
import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
import { DomSanitizer } from '@angular/platform-browser';
var AttendeePackageDetailService = /** @class */ (function () {
    function AttendeePackageDetailService(http, URLs, sanitizer) {
        this.http = http;
        this.URLs = URLs;
        this.sanitizer = sanitizer;
        this.paymentsMadeOptions = {
            hideDate: true,
            multiSelectMode: false,
            hideMenu: false,
            columns: [{
                    fieldName: 'PaymentDate',
                    label: 'Payment Date',
                    pipe: new DatePipe('en-US'),
                    type: 'date'
                },
                {
                    fieldName: 'PaymentType',
                    label: 'Payment Type',
                    mobile: {
                        showInMobile: false
                    }
                },
                {
                    fieldName: 'PayeeName',
                    label: 'To',
                    mobile: {
                        showInMobile: false
                    }
                },
                {
                    fieldName: 'Amount',
                    label: 'Amount',
                    type: 'currency'
                },
                {
                    fieldName: 'Status',
                    label: 'Status',
                    mobile: {
                        showInMobile: false
                    }
                }
            ],
            collapsed: false,
            singleActions: [],
            headerActions: ['Make Payment'],
            defaultMessage: {
                message: 'No payments have been submitted for this event.'
            }
        };
    }
    AttendeePackageDetailService.prototype.GetPackageDetails = function (IdPackage) {
        return this.http.get(this.URLs._packageDetailsForAttendee(IdPackage)).pipe(handleGTResponse(), map(function (packageDetails) {
            try {
                packageDetails.options = JSON.parse(packageDetails.JsonDoc);
            }
            catch (e) {
                packageDetails.options = {};
            }
            if (packageDetails.options.inviteOthers === undefined) {
                packageDetails.options.inviteOthers = true;
            }
            if (packageDetails.options.inviteOthersCount === undefined) {
                packageDetails.options.inviteOthersCount = 99;
            }
            return packageDetails;
        }), map(function (packageDetails) {
            if (packageDetails.OwnerPhoto) {
                packageDetails.OwnerPhoto = 'data:image/jpg;base64,' + packageDetails.OwnerPhoto;
            }
            return packageDetails;
        }));
    };
    AttendeePackageDetailService.prototype.GetComponents = function (IdPackage) {
        return this.http.get(this.URLs._packageComponentsGetByID(IdPackage)).pipe(handleGTResponse());
    };
    AttendeePackageDetailService.prototype.GetMyPayments = function (IdPackage) {
        return this.http.get(this.URLs._packagePaymentsForAttendee(IdPackage)).pipe(handleGTResponse());
    };
    AttendeePackageDetailService.prototype.GetPaymentsSentSummary = function (packageId) {
        return this.http.get(this.URLs._paymentsSentSummaryForAttendee(packageId)).pipe(map(function (res) {
            if (res.Result) {
                return res.Obj;
            }
            return null;
        }));
    };
    AttendeePackageDetailService.prototype.GetPackageInfo = function (packageId) {
        return this.http.get(this.URLs._packageGetByID(packageId)).pipe(handleGTResponse());
    };
    AttendeePackageDetailService.prototype.GetAttendeeSummary = function (packageId) {
        return this.http.get(this.URLs._packageAttendeeSummary(packageId)).pipe(handleGTResponse());
    };
    AttendeePackageDetailService.prototype.SetStatus = function (update) {
        return this.http.post(this.URLs._packageUpdateResponse(), update).pipe(handleGTResponse());
    };
    AttendeePackageDetailService.prototype.AttendeeFeature = function (packageComponent) {
        return this.http.post(this.URLs._packageAttendeeFeature(packageComponent.IdPackageComponent), packageComponent);
    };
    AttendeePackageDetailService.prototype.GetComponentStatus = function (IdPackage) {
        return this.http.get(this.URLs._packageGetComponentStatus(IdPackage)).pipe(map(function (res) {
            if (res.Result) {
                return res.Obj;
            }
            else {
                return null;
            }
        }));
    };
    AttendeePackageDetailService.prototype.HasGuests = function (IdPackage) {
        return this.http.get(this.URLs._packageAttendeeHasGuests(IdPackage));
    };
    AttendeePackageDetailService.prototype.MakePaymentAsAttendee = function (payment) {
        return this.http.post(this.URLs._makePayment(), payment);
    };
    AttendeePackageDetailService.prototype.DeletePayment = function (IdPayment) {
        return this.http.post(this.URLs._deletePayment(IdPayment), null);
    };
    AttendeePackageDetailService.prototype.UpdatePaymentStatus = function (paymentId, status) {
        var payload = {
            'IdPayment': paymentId,
            'Status': status
        };
        return this.http.post(this.URLs._paymentStatusUpdate(), payload);
    };
    AttendeePackageDetailService.prototype.CreateWePayCheckout = function (payload) {
        return this.http.post(this.URLs._createWePayCheckout(payload.IdPayment), payload);
    };
    AttendeePackageDetailService.prototype.CreateStripeCheckout = function (payload) {
        return this.http.post(this.URLs._createStripeCheckout(payload.IdPayment), payload);
    };
    AttendeePackageDetailService.prototype.GetLeaderPaymentMethod = function (leaderId) {
        return this.http.get(this.URLs._getPaymentMethod(leaderId)).pipe(handleGTResponse());
    };
    AttendeePackageDetailService.prototype.GetAlternatePaymentMethod = function (userId) {
        var _this = this;
        return this.http.get(this.URLs._paymentGetAlternatePaymentMethod(userId)).pipe(handleGTResponse(), map(function (alternatePaymentMethod) { return _this.sanitizer.sanitize(SecurityContext.HTML, alternatePaymentMethod); }));
    };
    AttendeePackageDetailService.prototype.DeletePlusOnes = function (IdPackage) {
        return this.http.get(this.URLs._packageRemovePlusOnes(IdPackage)).pipe(map(function (res) { return res.Result; }));
    };
    /********************
     * Calculations
     *******************/
    // TODO: REFACTOR: change this to use a standard model used in the store
    AttendeePackageDetailService.prototype.getComponentPrice = function (component) {
        var e_1, _a;
        var price = 0;
        if (!component.Options) {
            return 0;
        } // if somehow no options, price is 0
        // if there is only one option, price is that option's price
        if (component.Options.length === 1) {
            price = component.Options[0].AttendeePrice;
        }
        else {
            // if there are multiple options, find either the one that
            // the user is attending (Status === 'accept') OR
            // the minimum of option prices
            var min = component.Options[0].AttendeePrice;
            try {
                for (var _b = tslib_1.__values(component.Options), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var opt = _c.value;
                    if (opt.AttendeePrice < min) {
                        min = opt.AttendeePrice;
                    }
                    if (opt.Status === 'accept' || opt.Status === 'tentative') {
                        price = opt.AttendeePrice;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            // at this point, if price is not set, the user is not attending an option
            // show them the minimum price
            if (!price) {
                price = min;
            }
        }
        return price;
    };
    AttendeePackageDetailService.prototype.getAttendingPartySize = function (component) {
        var e_2, _a;
        var party = 0;
        try {
            // if they have accepted any option, the whole party is attending
            for (var _b = tslib_1.__values(component.Options), _c = _b.next(); !_c.done; _c = _b.next()) {
                var opt = _c.value;
                if (opt.Status === 'accept' || opt.Status === 'tentative') {
                    party = component.PartySize;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return party;
    };
    AttendeePackageDetailService.prototype.getTotalDue = function (components) {
        var e_3, _a;
        var sum = 0;
        try {
            for (var components_1 = tslib_1.__values(components), components_1_1 = components_1.next(); !components_1_1.done; components_1_1 = components_1.next()) {
                var comp = components_1_1.value;
                sum += this.getComponentPrice(comp) * this.getAttendingPartySize(comp);
                sum -= comp.TotalDistributedPayments;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (components_1_1 && !components_1_1.done && (_a = components_1.return)) _a.call(components_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return sum;
    };
    AttendeePackageDetailService.prototype.getTotalCost = function (components) {
        var e_4, _a;
        var sum = 0;
        try {
            for (var components_2 = tslib_1.__values(components), components_2_1 = components_2.next(); !components_2_1.done; components_2_1 = components_2.next()) {
                var comp = components_2_1.value;
                sum += this.getComponentPrice(comp) * this.getAttendingPartySize(comp);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (components_2_1 && !components_2_1.done && (_a = components_2.return)) _a.call(components_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return sum;
    };
    return AttendeePackageDetailService;
}());
export { AttendeePackageDetailService };
