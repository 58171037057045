import { PipeTransform } from "@angular/core";
var UtcDatePipe = /** @class */ (function () {
    function UtcDatePipe() {
    }
    UtcDatePipe.prototype.transform = function (value) {
        if (!value) {
            return '';
        }
        var dateValue = new Date(value);
        var dateWithNoTimezone = new Date(dateValue.getUTCFullYear(), dateValue.getUTCMonth(), dateValue.getUTCDate(), dateValue.getUTCHours(), dateValue.getUTCMinutes(), dateValue.getUTCSeconds());
        if (dateValue !== dateWithNoTimezone) {
            if (dateValue < dateWithNoTimezone) {
                return dateValue;
            }
            else {
                return dateWithNoTimezone;
            }
        }
        else {
            return dateWithNoTimezone;
        }
    };
    return UtcDatePipe;
}());
export { UtcDatePipe };
