import { ActivatedRoute, Params, Router } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EqualPasswordsValidator } from 'app/grouptools-theme/validators/equalsPasswords.validator';
import { AppStateService } from '../helpers/appState.service';
import { AuthService, EmailVerification, Person, LoginPerson, BIStartPlanning, BIStartPlanningProperties } from 'app/authentication/authentication.service';
// import { RegisterService, EmailVerification, Person } from './register.service';
import { ModalDirective } from 'ngx-bootstrap';
import { ViewChild } from '@angular/core';
import 'style-loader!./register.scss';
import { GrouptoolsModalComponent } from 'app/widgets/grouptools-modal/grouptools-modal.component';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { Observable, BehaviorSubject } from 'rxjs';
import { ButtonConfig } from 'app/widgets/grouptools-modal/grouptools-modal.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { TitleCasePipe } from '@angular/common';
import * as attendeePackages from 'app/store/actions/attendeePackages';

@Component({
  selector: 'register',
  templateUrl: './register.html',

  providers: [
    AuthService
  ]
})

export class Register implements OnInit {
  public PASSWORD_REGX: string = '^((?=.*[A-Z])(?=.*\\d)(?=.*[a-z])[A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{8,})|([A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{12,})$';
  public NAME_REGX: string = "^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ \',.-]+$";
  public EMAIL_REGX: string = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  public PHONE_REGEX: string = "^[0-9]{3}-[0-9]{3}-[0-9]{4}$";

  form: FormGroup;
  formSubmitted: boolean = false;
  emailUnique: EmailVerification;
  me: Person;

  @Input() doNotRedirect: boolean = false;
  @Input() inBaPageTop: boolean = false;
  @Input() inModal: boolean = false;
  @Input() emailToken: string;
  @Input() invoiceId: number;
  @Input() omitTitle: boolean = false;
  @Input() isNavgiate: boolean = true;
  @Input() emailPrefill: string = '';
  @Output() registerEmit: EventEmitter<any> = new EventEmitter();
  @Output() loginInfo: EventEmitter<any> = new EventEmitter();
  @Output() differentAccount: EventEmitter<any> = new EventEmitter();
  @Output() googleRegisterEmit: EventEmitter<any> = new EventEmitter();
  @Output() amazonRegisterEmit: EventEmitter<any> = new EventEmitter();
  @Output() facebookRegisterEmit: EventEmitter<any> = new EventEmitter();

  // public name:AbstractControl;
  public firstname: AbstractControl;
  public lastname: AbstractControl;
  public label: string = 'Verify?';
  public emails: FormGroup;
  public email: AbstractControl;
  public repeatEmail: AbstractControl;
  public phonevalue: AbstractControl;
  public password: AbstractControl;
  public repeatPassword: AbstractControl;
  public tosBox: AbstractControl;
  public passwords: FormGroup;
  public loginPerson;
  public title = 'Account Created';
  public events: Observable<any>;
  public _events;
  public modalState: string = 'null';
  public modalTitle: string = '';
  public googleUser;
  public amazonUser;
  public facebookUser;

  @ViewChild('staticModal') staticModal: GrouptoolsModalComponent;
  @ViewChild('emailCheck') emailCheck;
  @ViewChild('passwordCheck') passwordCheck;
  @ViewChild('ordersTable') ordersTable: AbstractControl;
  @ViewChild('ordersModal') ordersModal: ModalDirective;
  @ViewChild('existsModal') existsModal: GrouptoolsModalComponent;
  @ViewChild('accountVerification') accountVerification: AccountVerificationComponent;
  @ViewChild('modal') modal: GrouptoolsModalComponent;
  loginName: string;
  existsPassword: string;
  loginEmail: string;
  loginPhone: string;

  showChildModal(): void {
    this.staticModal.showModal();
  }
  resetPassword(): void {
    this._registerService.forgotPassword(this.email.value).subscribe(({Obj: res}) => {
      alert('Your password has been mailed to you.');
      this.existsModal.hideModal();
    });
  }
  showOrdersModal(): void {
    this.ordersModal.show();
  }

  hideChildModal(): void {
    this.staticModal.hideModal();
  }

  constructor(
    public fb: FormBuilder,
    private _registerService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public titlecasePipe:TitleCasePipe,
    private store: Store<fromRoot.State>,
    private _appService: AppStateService
  ) { }

  public ngOnInit() {
    this.form = this.fb.group(this.formFields);

    this.firstname = this.form.controls['firstname'];
    this.lastname = this.form.controls['lastname'];
    this.phonevalue = this.form.controls['phonevalue'];
    this.emails = <FormGroup>this.form.controls['emails'];
    this.email = this.emails.controls['email'];
    this.passwords = <FormGroup>this.form.controls['passwords'];
    this.password = this.passwords.controls['password'];
    this.repeatPassword = this.passwords.controls['repeatPassword'];
    this.tosBox = this.form.controls['tosBox'];
    this.route.queryParams.subscribe((params: Params) => {
      this.email.setValue(params['email']);
      this.lastname.setValue(params['lastname']);
      this.firstname.setValue(params['firstname']);
      this.emailToken = params['emailToken'];
    });

    if (sessionStorage.getItem('GTEmail')) {
      this.emailPrefill = sessionStorage.getItem('GTEmail');
    }
    this.form.valueChanges
      .subscribe(data => {
        this.onValueChanged(data);
      });

      if (this.emailPrefill) {
        this.email.setValue(this.emailPrefill);
        this.email.disable();
      }
    this.onValueChanged(); //(re)set validation messages now
  }

  public verifyEmail() {
    return this._registerService.getEmailVerification(encodeURIComponent(this.email.value));
  }

  public onSubmit(values: Object): void {
    this.formSubmitted = true;

    if (!this.form.valid) {
      this.onFormSubmit();
      return;
    }

    this.verifyEmail().subscribe(
      emailVerify => {
        this.emailUnique = emailVerify;
        // Email in use check. If false is returned from API the email is currently in use.
        if (!this.emailUnique.valueOf()) {
          // this.email.setErrors({'notUnique': true});
          // this.onFormSubmit();
          this.emailInUse();
        } else {
          this.registerUser();
        }
      },
      error => {
        //console.error(error);
      });
  }

  public emailInUse() {
    this.loginName = this.firstname.value;
    if (this.inModal) {
      this.registerEmit.emit('duplicateEmail');
    } else {
      this.existsModal.showModal();
    }
  }

  registerUser() {
    this.me = new Person(
      this.email.value,
      this.firstname.value,
      this.lastname.value,
      this.phonevalue.value,
      this.password.value,
      this.repeatPassword.value,
      this.emailToken,
      this.invoiceId
    );
    this._registerService.insertPerson(JSON.stringify(this.me))
      .subscribe(
      submitUser => {
        if(submitUser.Result) {
          this.loginName = this.firstname.value;
          this.loginEmail = this.email.value;
          if (this.inModal) {
            this.loginInfo.emit({loginName: this.loginName, loginEmail: this.loginEmail, loginPhone: this.loginPhone, password: this.password.value});
            this.login();
          } else {
            this.showChildModal();
          }
        } else{
          //console.error("" + submitUser.ResponseCode + ": " + submitUser.ResponseMessage)
          alert("" + submitUser.ResponseMessage);
        }
      },
      error => {
        //console.error(error);
      });
  }

  onlyLogin(){
    this.isNavgiate = false;
    this.login();
  }

  skipVerification(){
    this.isNavgiate = true;
    this.login();
    this.staticModal.grouptoolsModal.hide();
  }

  existsLogin() {
    this.loginPerson = new LoginPerson(
      this.email.value,
      this.existsPassword);

    this._registerService.login(this.loginPerson).subscribe(
      token => {
        if (token.token_type) {
          //console.log(token);
          localStorage.setItem('access_token', token.access_token);
          localStorage.setItem('userID', token.userID);
          localStorage.setItem('login_status', token.login_status);
          this._registerService.isLoggedIn();
          let loggedIn = true;
          let returnUrl = this.route.snapshot.queryParams['returnUrl'];

          if (returnUrl) {
            this.router.navigate([returnUrl]);
          }
          else {
            if (this.inModal) {
              this.registerEmit.emit('loggedIn')
            } else {
              this.router.navigate(['/pages/dashboard']);
            }
          }

        }
      },
      error => {
        if (error.status > 0) {
          alert(JSON.parse(error._body).error_description);
          //console.log('Server is unresponsive.');
        }
        else {
          alert('Server is unresponsive.');
          //console.log('Server is unresponsive.');
        }
      });
  }

  login() {
    this.loginPerson = new LoginPerson(
      this.email.value,
      this.password.value);

    this._registerService.login(this.loginPerson).subscribe(
      token => {
        if (token.token_type) {
          localStorage.setItem('access_token', token.access_token);
          localStorage.setItem('userID', token.userID);
          localStorage.setItem('login_status', token.login_status);

          this._registerService.isLoggedInObservable().subscribe(res => {

            if (this.inModal) {
              this.registerEmit.emit('loggedIn');
            } else {
              let loggedIn = true;
              if(this.isNavgiate){
                // TODO change the way the verification process flows so that this is unnecessary
                // FIX #10198 dashboard resource collapsed after verification - ska 2019-03-07
                // Remove the 'lastLogin' key from localStorage so that the resource shows up
                // the first time you see the dashboard whether you verified or not. The bug
                // was that verification caused a re-login which then contained the lastLogin
                // field.
                localStorage.removeItem('lastLogin');

                // check if we have stored BI order information, if so, create a
                if (sessionStorage.getItem('GTOrderId')) {
                  const data = <BIStartPlanning> {};
                  for (const key of BIStartPlanningProperties) {
                    data[key] = sessionStorage.getItem(key);
                    sessionStorage.removeItem(key); // clear out cached values for future logins
                  }

                  this._registerService.createBIPlaceHolderEvent(data);
                  return;
                }
                let returnUrl = this.route.snapshot.queryParams['returnUrl'];
                if (returnUrl) {
                  this.router.navigate([returnUrl]);
                }
                else {
                  this.router.navigate(['/pages/dashboard']);
                }
              }
              else{
                  this.accountVerification.sendVerificationCode();
              }
            }

          });

        }

      },
      error => {
        if (error.status > 0) {
          alert(JSON.parse(error._body).error_description);
          //console.log('Server is unresponsive.');
        }
        else {
          alert('Server is unresponsive.');
          //console.log('Server is unresponsive.');
        }
      });
  }

  onValueChanged(data?: any) {
    if (!this.form) { return; }

    const form = this.form;

    if (this.formSubmitted) {
      this.onFormSubmit();
      return;
    }

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      let control = form.get(field);

      if (field === "email") {
        let intCont = form.get('emails');
        control = intCont.get('email');
      }

      if (field === "password") {
        let intCont = form.get('passwords');
        control = intCont.get('password');
      }

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  onFormSubmit() {
    const form = this.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      let control = form.get(field)

      if (field === "email") {
        let intCont = form.get('emails')
        control = intCont.get('email')
      }

      if (field === "password") {
        let intCont = form.get('passwords');
        control = intCont.get('password');
      }

      if (control && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }

  }

  routeToLogin(): void {
    if (this.inModal) {
      this.registerEmit.emit('login');
    } else {
      this.router.navigate(['/login']);
    }
  }

  changeModalTitle(){
    this.title = 'Email Verification';
  }

  includeSpecialChar(){
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return format.test(this.password.value);
  }

  public handlePostLogin({token_type: tokenType}) {
    if (tokenType) {
      const returnUrl = this.route.snapshot.queryParams['returnUrl'];
      if(!this.inBaPageTop){
        // check if we have stored BI order information, if so, create a
        if (sessionStorage.getItem('GTOrderId')) {
          const data = <BIStartPlanning> {};
          for (const key of BIStartPlanningProperties) {
            data[key] = sessionStorage.getItem(key);
            sessionStorage.removeItem(key); // clear out cached values for future logins
          }
        
          this._registerService.createBIPlaceHolderEvent(data);
          return;
        }
        if (returnUrl) {
          this.router.navigateByUrl(returnUrl);
        } else {
          if (this.inModal) {
            this.registerEmit.emit('loggedIn');
            if (!this.doNotRedirect) {
              this.events = this._appService.getEvents();
              this.events.subscribe(res => {
                  this._events = res;
  
                  this._events.forEach(event => {
                    if (event.attendeeStatus === 'accept') {
                      this.store.dispatch(new attendeePackages.SetSelectedPackageId(Number(event.packageID)))
                      this.router.navigateByUrl('/pages/attendee-package-details/' + event.packageID);
                    }
                  });
              });
            }
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      }
      else{
        if (returnUrl) 
          this.router.navigateByUrl(returnUrl);
        else
          this.router.navigate(['/dashboard']);

        this.registerEmit.emit('closeModal');
      }
    }
  }

  cancelLogin() {
    this.googleUser = null;
    this.facebookUser = null;
    this.amazonUser = null;
    this.modal.grouptoolsModal.hide();
  }

  public destroyContents() {
    this.modalState = 'null';
  }

  public openDifferentAccountModal(){
    this.modalState = 'differentAccount';
    this.modalTitle = 'Warning';
    this.modal.grouptoolsModal.show();
  }

  public openGoogleRegisterModal(googleUser){
    //Show registration option
    this.modalState = 'googleRegister';
    this.modal.title = 'Google Account Registration';
    this.googleUser = googleUser;
    this.modal.grouptoolsModal.show();
  }

  public openAmazonRegisterModal(amazonUser){
    //Show registration option
    this.modalState = 'amazonRegister';
    this.modalTitle = 'Register for GroupTools with your Amazon Account';
    this.amazonUser = amazonUser;
    this.modal.grouptoolsModal.show();
  }

  public openFacebookRegisterModal(facebookUser){
    //Show registration option
    this.modalState = 'facebookRegister';
    this.modalTitle = 'Register for GroupTools with your Facebook Account';
    this.facebookUser = facebookUser;
    this.modal.grouptoolsModal.show();
  }

  formFields = {
    firstname: ['', Validators.compose([Validators.required, Validators.pattern(this.NAME_REGX)])],
    lastname: ['', Validators.compose([Validators.required, Validators.pattern(this.NAME_REGX)])],
    tosBox: [false, Validators.compose([Validators.requiredTrue])],
    passwords: this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern(this.PASSWORD_REGX)])],
      repeatPassword: ['', Validators.compose([Validators.required, Validators.pattern(this.PASSWORD_REGX)])]
    }, { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') }),
    emails: this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.EMAIL_REGX)])]
    }),
    phonevalue: ['', Validators.compose([Validators.pattern(this.PHONE_REGEX)])],
  }

  formErrors = {
    'firstname': '',
    'lastname': '',
    'phonevalue': '',
    'passwords': '',
    'emails': '',
    'password': '',
    'email': '',
    'tosBox': '',
  };

  validationMessages = {
    'firstname': {
      'required': 'First Name is required.',
      'pattern': "Only alphabetic characters and the special characters [,.'-] are allowed."
    },
    'lastname': {
      'required': 'Last Name is required.',
      'pattern': "Only alphabetic characters and the special characters [,.'-] are allowed."
    },
    'password': {
      'required': `Password is Required.`,
      'pattern': 'Password must be at least 8 characters long and contain an upper case letter, a lower case letter and 1 number.'
    },
    'passwords': {
      'passwordsEqual': 'Passwords must match.',
    },
    'email': {
      'required': 'Email is required.',
      'pattern': 'Email must be in the proper format (ex. example@example.com).',
      'notUnique': 'Email is already in use.'
    },
    'phonevalue': {
      'pattern': 'Phone number must be in the format 123-456-7890.',
    },
    'tosBox': {
      'required': 'You must accept the Terms of Service to continue.'
    }
  };

}
