import { GTNgaModule } from '../../grouptools-theme/gtnga.module';
import { ViewMessageComponent } from './view-message/view-message.component';
// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { WidgetsModule } from '../../widgets/widgets.module';
import { MessagesSummaryComponent } from './messages-summary.component';
import { MessagesComponent } from './messages.component';
import { CreateMessageComponent } from './createMessage/createMessage.component';
import { MessagesCardComponent } from './messages-card/messages-card.component';
import { MessagesListComponent } from './messages-list/messages-list.component';
import { MessagesService } from './messages.service';
import { BulkMessageComponent } from './bulk-message/bulk-message.component';
import { ExpressInviteModule } from 'app/pages/contacts/invite-contacts/express-invite/express-invite.module';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetsModule,
        GTNgaModule,
        ExpressInviteModule
    ],
    declarations: [
        MessagesComponent,
        MessagesSummaryComponent,
        CreateMessageComponent,
        ViewMessageComponent,
        MessagesCardComponent,
        MessagesListComponent,
        BulkMessageComponent
    ],
    exports: [
        MessagesComponent,
        CreateMessageComponent,
        ViewMessageComponent,
        BulkMessageComponent
    ],
    providers: [
        MessagesService
    ]
})
export class MessagesModule {
}
