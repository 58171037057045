import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import * as PackageDetailsActions from "app/store/actions/packageDetails.actions";
var CreateFromSupplierOrderDialogComponent = /** @class */ (function () {
    function CreateFromSupplierOrderDialogComponent(dialogRef, data, store$) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.store$ = store$;
    }
    CreateFromSupplierOrderDialogComponent.prototype.ngOnInit = function () {
        this.supplierOrderNumberCreateEventApiState$ = this.store$.select(PackageDetailsSelectors.getSupplierOrderNumberCreateEventApiState);
    };
    CreateFromSupplierOrderDialogComponent.prototype.showSelected = function () { };
    CreateFromSupplierOrderDialogComponent.prototype.dateSelected = function () { };
    CreateFromSupplierOrderDialogComponent.prototype.getStartedClicked = function (createEventFromSupplierOrderRequest) {
        this.store$.dispatch(new PackageDetailsActions.CreateEventFromSupplierOrderDialog({ createEventFromSupplierOrderRequest: createEventFromSupplierOrderRequest }));
    };
    return CreateFromSupplierOrderDialogComponent;
}());
export { CreateFromSupplierOrderDialogComponent };
