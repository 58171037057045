import { Action } from "@ngrx/store";
import { PackageSurveyDTO } from "../models/packages/PackageSurveyDTO";
import { Survey } from "../models/survey";

export enum ActionTypes {
    GLEDLoadEventSurveys = "[GLED Component] Load Event Surveys",
    AttendeePackageDetailsComponentLoadEventSurveys = "[Attendee Package Details Component] Load Event Surveys",
    AttendeeSurveyViewComponentLoadEventSurveys = "[Attendee Survey View Componenet] Load Event Surveys",
    PackageDetailsEffectLoadEventSurveys = "[Package Details Effect] Load Event Surveys",
    SurveyListComponentLoadEventSurveys = "[Survey List Component] Load Event Surveys",
    SurveysEffectLoadEventSurveysSuccess = "[Surveys Effect] Load Event Surveys",
    SurveysEffectLoadEventSurveysFailure = "[Surveys Effect] Load Event Failure",

    // TODO: Audit the behavior of the below two actions, I think they are triggered in an ngOnChanges when the route param eventId changes, a bit convoluted
    AttendeeSurveyViewSetSurveysEventId = "[Attendee Survey View Component] Set Surveys EventId",
    PackageDetailsEffectSetSurveysEventId = "[Package Details Effect] Set Surveys EventId",

    AttendeePackageDetailsComponentLoadSurveyDetails = "[Attendee Package Details Component] Load Survey Details",
    ApdViewComponentLoadSurveyDetails = "[APD View Component] Load Survey Details",
    AttendeeSurveyListComponentLoadSurveyDetails = "[Attendee Survey List Component] Load Survey Details",
    SurveyComponentLoadSurveyDetails = "[Survey Component] Load Survey Details",
    SurveysEffectLoadSurveyDetailsSuccess = "[Surveys Effect] Load Survey Details Success",
    SurveysEffectLoadSurveyDetailsFailure = "[Surveys Effect] Load Survey Details Failure",

    DashboardComponentLoadCreatedSurveys = "[Dashboard Component] Load Created Surveys",
    GroupLeaderDashboardComponentLoadCreatedSurveys = "[Group Leader Dashboard Component] Load Created Surveys",
    SurveyDashboardComponentLoadCreatedSurveys = "[Survey Dashboard Component] Load Created Surveys",
    SurveysEffectLoadCreatedSurveysSuccess = "[Surveys Effect] Load Created Surveys Success",
    SurveysEffectLoadCreatedSurveysFailure = "[Surveys Effect] Load Created Surveys Failure",

    DashboardComponentLoadInvitedSurveys = "[Dashboard Component] Load Invited Surveys",
    SurveyAttendeeComponentLoadInvitedSurveys = "[Survey Attendee Component] Load Invited Surveys",
    SurveysEffectLoadInvitedSurveysSuccess = "[Surveys Effect] Load Invited Surveys Success",
    SurveysEffectLoadInvitedSurveysFailure = "[Surveys Effect] Load Invited Surveys Failure",

    AuthenticationServiceLogout = "[Authentication Service] Logout",
}

//----------------------------------------------------------------
export class GLEDLoadEventSurveys implements Action {
    readonly type = ActionTypes.GLEDLoadEventSurveys;
    constructor(public payload: {eventId: number}) {}
}
export class AttendeePackageDetailsComponentLoadEventSurveys implements Action {
    readonly type = ActionTypes.AttendeePackageDetailsComponentLoadEventSurveys;
    constructor(public payload: {eventId: number}) {}
}
export class AttendeeSurveyViewComponentLoadEventSurveys implements Action {
    readonly type = ActionTypes.AttendeeSurveyViewComponentLoadEventSurveys;
    constructor(public payload: {eventId: number}) {}
}
export class PackageDetailsEffectLoadEventSurveys implements Action {
    readonly type = ActionTypes.PackageDetailsEffectLoadEventSurveys;
    constructor(public payload: {eventId: number}) {}
}
export class SurveyListComponentLoadEventSurveys implements Action {
    readonly type = ActionTypes.SurveyListComponentLoadEventSurveys;
    constructor(public payload: {eventId: number}) {}
}
export class SurveysEffectLoadEventSurveysSuccess implements Action {
    readonly type = ActionTypes.SurveysEffectLoadEventSurveysSuccess;
    constructor(public payload: {eventSurveys: Survey[]}) {}
}
export class SurveysEffectLoadEventSurveysFailure implements Action {
    readonly type = ActionTypes.SurveysEffectLoadEventSurveysFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class AttendeeSurveyViewSetSurveysEventId implements Action {
    readonly type = ActionTypes.AttendeeSurveyViewSetSurveysEventId;
    constructor(public payload: {eventId: number}) {}
}
export class PackageDetailsEffectSetSurveysEventId implements Action {
    readonly type = ActionTypes.PackageDetailsEffectSetSurveysEventId;
    constructor(public payload: {eventId: number}) {}
}
//----------------------------------------------------------------
export class AttendeePackageDetailsComponentLoadSurveyDetails implements Action {
    readonly type = ActionTypes.AttendeePackageDetailsComponentLoadSurveyDetails;
    constructor(public payload: {surveyId: number}) {}
}
export class ApdViewComponentLoadSurveyDetails implements Action {
    readonly type = ActionTypes.ApdViewComponentLoadSurveyDetails;
    constructor(public payload: {surveyId: number}) {}
}
export class AttendeeSurveyListComponentLoadSurveyDetails implements Action {
    readonly type = ActionTypes.AttendeeSurveyListComponentLoadSurveyDetails;
    constructor(public payload: {surveyId: number}) {}
}
export class SurveyComponentLoadSurveyDetails implements Action {
    readonly type = ActionTypes.SurveyComponentLoadSurveyDetails;
    constructor(public payload: {surveyId: number}) {}
}
export class SurveysEffectLoadSurveyDetailsSuccess implements Action {
    readonly type = ActionTypes.SurveysEffectLoadSurveyDetailsSuccess;
    constructor(public payload: {survey: Survey}) {}
}
export class SurveysEffectLoadSurveyDetailsFailure implements Action {
    readonly type = ActionTypes.SurveysEffectLoadSurveyDetailsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class DashboardComponentLoadCreatedSurveys implements Action {
    readonly type = ActionTypes.DashboardComponentLoadCreatedSurveys;
    constructor() {}
}
export class GroupLeaderDashboardComponentLoadCreatedSurveys implements Action {
    readonly type = ActionTypes.GroupLeaderDashboardComponentLoadCreatedSurveys;
    constructor() {}
}
export class SurveyDashboardComponentLoadCreatedSurveys implements Action {
    readonly type = ActionTypes.SurveyDashboardComponentLoadCreatedSurveys;
    constructor() {}
}
export class SurveysEffectLoadCreatedSurveysSuccess implements Action {
    readonly type = ActionTypes.SurveysEffectLoadCreatedSurveysSuccess;
    constructor(public payload: {createdSurveys: PackageSurveyDTO[]}) {}
}
export class SurveysEffectLoadCreatedSurveysFailure implements Action {
    readonly type = ActionTypes.SurveysEffectLoadCreatedSurveysFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class DashboardComponentLoadInvitedSurveys implements Action {
    readonly type = ActionTypes.DashboardComponentLoadInvitedSurveys;
    constructor() {}
}
export class SurveyAttendeeComponentLoadInvitedSurveys implements Action {
    readonly type = ActionTypes.SurveyAttendeeComponentLoadInvitedSurveys;
    constructor() {}
}
export class SurveysEffectLoadInvitedSurveysSuccess implements Action {
    readonly type = ActionTypes.SurveysEffectLoadInvitedSurveysSuccess;
    constructor(public payload: {invitedSurveys: PackageSurveyDTO[]}) {}
}
export class SurveysEffectLoadInvitedSurveysFailure implements Action {
    readonly type = ActionTypes.SurveysEffectLoadInvitedSurveysFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class AuthenticationServiceLogout implements Action {
    readonly type = ActionTypes.AuthenticationServiceLogout;
    constructor() {}
}
//----------------------------------------------------------------

export type Actions
    = GLEDLoadEventSurveys
    | AttendeePackageDetailsComponentLoadEventSurveys
    | AttendeeSurveyViewComponentLoadEventSurveys
    | PackageDetailsEffectLoadEventSurveys
    | SurveyListComponentLoadEventSurveys
    | SurveysEffectLoadEventSurveysSuccess
    | SurveysEffectLoadEventSurveysFailure

    | AttendeeSurveyViewSetSurveysEventId
    | PackageDetailsEffectSetSurveysEventId

    | AttendeePackageDetailsComponentLoadSurveyDetails
    | ApdViewComponentLoadSurveyDetails
    | AttendeeSurveyListComponentLoadSurveyDetails
    | SurveyComponentLoadSurveyDetails
    | SurveysEffectLoadSurveyDetailsSuccess
    | SurveysEffectLoadSurveyDetailsFailure

    | DashboardComponentLoadCreatedSurveys
    | GroupLeaderDashboardComponentLoadCreatedSurveys
    | SurveyDashboardComponentLoadCreatedSurveys
    | SurveysEffectLoadCreatedSurveysSuccess
    | SurveysEffectLoadCreatedSurveysFailure

    | DashboardComponentLoadInvitedSurveys
    | SurveyAttendeeComponentLoadInvitedSurveys
    | SurveysEffectLoadInvitedSurveysSuccess
    | SurveysEffectLoadInvitedSurveysFailure

    | AuthenticationServiceLogout
;
