import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as modalActions from 'app/store/actions/modal.action';
import * as eventActions from 'app/store/actions/event.action';
import { takeUntil, tap } from 'rxjs/operators';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { ModalSize } from 'app/store/models/modal';
import { Actions, ofType } from '@ngrx/effects';
import { GroupToolsSuccessModalConfig, GroupToolsSuccessModal } from '../grouptools-success-modal/grouptools-success-modal.component';
import { Router } from '@angular/router';
var AssignEventToOtherComponent = /** @class */ (function () {
    function AssignEventToOtherComponent(store, actions$, router, eventService, fb) {
        this.store = store;
        this.actions$ = actions$;
        this.router = router;
        this.eventService = eventService;
        this.fb = fb;
        this.badEmail = false;
        this.working = false;
        // tslint:disable-next-line:max-line-length
        this.templateInfoText = 'Create an event with dates not set for the given user. If you want to set the dates yourself, then create an event from the template, set the dates and assign the event.';
        this.eventInfoText = 'Assign control of this event to another user.';
        this.formSubmitted = false;
        this.unsubscribe$ = new Subject();
        this.NAME_REGX = "^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ \',.-]+$";
        this.EMAIL_REGX = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
        this.assignEventFormErrors = {
            'FirstName': '',
            'LastName': '',
            'Email': ''
        };
        this.assignEventFormValidationMessages = {
            'FirstName': {
                'pattern': 'First Name contains invalid characters.'
            },
            'LastName': {
                'pattern': 'Last Name contains invalid characters.'
            },
            'Email': {
                'required': 'Email is required.',
                'pattern': 'Invalid email.'
            }
        };
        this.assignEventFormFields = {
            FirstName: [null, [Validators.pattern(this.NAME_REGX)]],
            LastName: [null, [Validators.pattern(this.NAME_REGX)]],
            Email: [null, [Validators.required, Validators.pattern(this.EMAIL_REGX)]],
        };
    }
    AssignEventToOtherComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actions$.pipe(ofType(eventActions.ASSIGN_EVENT_ERROR), takeUntil(this.unsubscribe$)).subscribe(function (error) {
            _this.badEmail = true;
            var anyError = error;
            _this.errorMessage = anyError.payload.errorMessage;
            console.log('error', error);
            _this.working = false;
        });
        this.selectedEvent$ = this.store.select(fromRoot.getSelectedEvent).pipe(takeUntil(this.unsubscribe$), tap(function (event) { return console.log('event', event); }), tap(function (event) {
            _this.isTemplateEvent = (event.status && event.status.toLowerCase() === 'template');
        }));
        this.actions$.pipe(ofType(eventActions.ASSIGN_EVENT_OK), takeUntil(this.unsubscribe$)).subscribe(function (event) {
            var config = tslib_1.__assign({}, GroupToolsSuccessModalConfig, { title: 'Event Created', componentWithOptions: {
                    component: GroupToolsSuccessModal,
                    size: ModalSize.Small,
                    options: {
                        headerText: 'Success',
                        type: 'success',
                        bodyText: "'" + event.payload.name + "' has been successfully created and assigned to '" + event.payload.ownerName + "'",
                        buttons: {
                            primary: {
                                text: "Return to " + (_this.isTemplateEvent ? 'Template' : 'Dashboard'),
                                action: function () {
                                    _this.store.dispatch(new modalActions.CloseModal());
                                    if (!_this.isTemplateEvent) {
                                        _this.router.navigateByUrl('dashboard');
                                    }
                                }
                            }
                        }
                    }
                } });
            _this.store.dispatch(new modalActions.OpenModal(config));
            _this.working = false;
        });
        this.buildAssignEventForm();
    };
    AssignEventToOtherComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
    };
    AssignEventToOtherComponent.prototype.assignEvent = function (event) {
        if (!this.assignEventForm.valid) {
            this.onAssignEventFormValueChanged();
            return;
        }
        ;
        this.working = true;
        var firstName = this.assignEventForm.get('FirstName').value;
        var lastName = this.assignEventForm.get('LastName').value;
        var email = this.assignEventForm.get('Email').value;
        var assignEvent = tslib_1.__assign({}, event, { email: email, firstName: firstName, lastName: lastName });
        if (event.status.toLowerCase() === 'template') {
            this.store.dispatch(new eventActions.CloneAndAssignEvent(assignEvent));
        }
        else {
            this.store.dispatch(new eventActions.AssignEvent(assignEvent));
        }
    };
    AssignEventToOtherComponent.prototype.close = function () {
        this.store.dispatch(new modalActions.CloseModal());
    };
    AssignEventToOtherComponent.prototype.buildAssignEventForm = function () {
        var _this = this;
        this.assignEventForm = this.fb.group(this.assignEventFormFields);
        this.assignEventForm.valueChanges
            .subscribe(function (data) { return _this.onAssignEventFormValueChanged(data); });
        this.onAssignEventFormValueChanged(); //(re)set validation messages now
    };
    AssignEventToOtherComponent.prototype.onAssignEventFormValueChanged = function (data) {
        if (!this.assignEventForm) {
            return;
        }
        if (this.formSubmitted) {
            this.onFormSubmit();
            return;
        }
        var form = this.assignEventForm;
        for (var field in this.assignEventFormErrors) {
            if (this.assignEventFormErrors.hasOwnProperty(field)) {
                this.assignEventFormErrors[field] = '';
                var control = form.get(field);
                if (control && control.dirty && !control.valid) {
                    var messages = this.assignEventFormValidationMessages[field];
                    for (var key in control.errors) {
                        if (control.errors.hasOwnProperty(key)) {
                            this.assignEventFormErrors[field] += messages[key] + ' ';
                        }
                    }
                }
            }
        }
    };
    AssignEventToOtherComponent.prototype.onFormSubmit = function () {
        var form = this.assignEventForm;
        for (var field in this.assignEventFormErrors) {
            if (this.assignEventFormErrors.hasOwnProperty(field)) {
                this.assignEventFormErrors[field] = '';
                var control = form.get(field);
                if (control && !control.valid) {
                    var messages = this.assignEventFormValidationMessages[field];
                    for (var key in control.errors) {
                        if (control.errors.hasOwnProperty(key)) {
                            this.assignEventFormErrors[field] += messages[key] + ' ';
                        }
                    }
                }
            }
        }
    };
    return AssignEventToOtherComponent;
}());
export { AssignEventToOtherComponent };
export var AssignEventToOtherConfig = {
    title: 'Assign Event',
    size: ModalSize.Small,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: AssignEventToOtherComponent
    }
};
