import { EventEmitter, ElementRef } from '@angular/core';
import { humanizeBytes } from 'ngx-uploader';
var BaPictureUploader = /** @class */ (function () {
    function BaPictureUploader(elementRef) {
        this.elementRef = elementRef;
        this.picture = '';
        this.uploaderOptions = { type: 'uploadAll', url: '', method: 'POST' };
        this.canDelete = true;
        this.onUpload = new EventEmitter();
        this.onUploadCompleted = new EventEmitter();
        this.remove = new EventEmitter();
        this.updateSavePicDisabled = new EventEmitter();
        this.closeChangePhoto = new EventEmitter();
        this.getChangedPhoto = new EventEmitter();
        this.fileChangeEvent = new EventEmitter();
        this.uploadInProgress = false;
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter(); // input events, we use this to emit data to ngx-uploader
        this.humanizeBytes = humanizeBytes;
    }
    BaPictureUploader.prototype.onUploadOutput = function (output) {
        var _this = this;
        if (output.type === 'allAddedToQueue') { // when all files added in queue
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
            //preview image
            this.previewImagem(output.file.nativeFile).then(function (response) {
                _this.files.push(output.file);
                _this.getChangedPhoto.emit(response.toString());
                _this.updateSavePicDisabled.emit();
                _this.picture = response.toString();
            });
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            // update current data in files array for uploading file
            this.uploadInProgress = true;
            var index = this.files.findIndex(function (file) { return typeof output.file !== 'undefined' && file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
        else if (output.type === 'done') {
            this.uploadInProgress = false;
            this._onUploadCompleted(output);
            this.closeChangePhoto.emit();
        }
    };
    BaPictureUploader.prototype.previewImagem = function (file) {
        var fileReader = new FileReader();
        return new Promise(function (resolve) {
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                resolve(e.target.result);
            };
        });
    };
    BaPictureUploader.prototype.uploadPicture = function () {
        var event = this.uploaderOptions;
        event.file = this.files[this.files.length - 1];
        this.uploadInput.emit(event);
    };
    BaPictureUploader.prototype.removePicture = function () {
        this.picture = '';
        this.remove.emit();
        return false;
    };
    BaPictureUploader.prototype._onUpload = function (data) {
        if (data['done'] || data['abort'] || data['error']) {
            this._onUploadCompleted(data);
        }
        else {
            this.onUpload.emit(data);
        }
    };
    BaPictureUploader.prototype._onUploadCompleted = function (data) {
        this.onUploadCompleted.emit(data);
    };
    BaPictureUploader.prototype._canUploadOnServer = function () {
        return !!this.uploaderOptions['url'];
    };
    return BaPictureUploader;
}());
export { BaPictureUploader };
