import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { PackageDetailsService } from "app/pages/package-details/package-details.service";
import * as PDActions from "app/store/actions/packageDetails.actions";
import * as AttendeesActions from "app/store/actions/attendees.action";
import * as SurveysActions from "app/store/actions/surveys.action";
import * as MessageActions from "app/store/actions/message.action";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import * as eventActions from 'app/store/actions/event.action';
import * as userActions from 'app/store/actions/user';
import * as activityActions from 'app/store/actions/activity.action';
import * as ContactsActions from 'app/store/actions/contacts.action';
import * as PaymentActions from 'app/store/actions/payment.action';
import * as formActions from 'app/store/actions/forms';
import { of } from "rxjs";
import { PaymentsService } from "app/pages/payments/payments.service";
import { Router } from "@angular/router";
import { DialogService } from "app/dialogs/dialog.service";
var PackageDetailsEffects = /** @class */ (function () {
    function PackageDetailsEffects(actions$, packageDetailsService, paymentService, router, dialogService) {
        var _this = this;
        this.actions$ = actions$;
        this.packageDetailsService = packageDetailsService;
        this.paymentService = paymentService;
        this.router = router;
        this.dialogService = dialogService;
        this.packageDetailsPageLoad = this.actions$.pipe(ofType(PDActions.ActionTypes.PackageDetailsPageLoad), concatMap(function (action) {
            var eventId = action.payload.eventId;
            return [
                // new PackageDetailsActions.PDEffectsLoadSelectedEvent({eventId}),
                // new PackageDetailsActions.PDEffectsLoadLeaderSummary({eventId}),
                // new PackageDetailsActions.PDEffectsLoadAlternateLeaders({eventId}),
                // new PackageDetailsActions.PDEffectsLoadGroupLeaderProfile({eventId}),
                // new PackageDetailsActions.PDEffectsLoadSelectedActivities({eventId}),
                // new PackageDetailsActions.PDEffectsLoadAttendees({eventId}),
                // new PackageDetailsActions.PDEffectsLoadEventContacts({eventId}),
                new PDActions.PDEffectsLoadPackageDetails({ eventId: eventId }),
                new PDActions.PDEffectsLoadTagInfo({ eventId: eventId }),
                new PDActions.PDEffectsLoadPackageComponents({ eventId: eventId }),
                new PDActions.PDEffectsLoadPackageRecord({ eventId: eventId }),
                new PaymentActions.PackageDetailsEffectsLoadPaymentsReceivedSummary({ eventId: eventId }),
                new PaymentActions.PackageDetailsEffectsLoadPaymentsSentSummary({ eventId: eventId }),
                new AttendeesActions.GeneralLoadAttendees({ eventId: eventId }),
                new PDActions.PDEffectsLoadSuppliers(),
                new PDActions.PDEffectsLoadPackageLogs({ eventId: eventId }),
                new PaymentActions.PackageDetailsEffectsLoadEventPayments({ eventId: eventId }),
                new PDActions.PDEffectsLoadSuppliersForPackage({ eventId: eventId }),
                new eventActions.LoadSelectedEvent(eventId),
                new AttendeesActions.PackageDetailsEffectLoadLeaderSummary({ eventId: eventId }),
                new eventActions.LoadAlternateLeaders(eventId),
                new userActions.LoadGroupLeaderProfile(eventId),
                new activityActions.LoadSelectedActivities(eventId),
                new ContactsActions.PackageDetailsEffectLoadContacts(),
                new formActions.LoadEventForms(eventId),
                new SurveysActions.PackageDetailsEffectSetSurveysEventId({ eventId: eventId }),
                new SurveysActions.PackageDetailsEffectLoadEventSurveys({ eventId: eventId }),
                new MessageActions.PDEffectsLoadEventMessages({ eventId: eventId }),
                new MessageActions.PDEffectsLoadEventSentMessages({ eventId: eventId }),
            ];
        }));
        this.packageDetailsPageGetPackageDetails = this.actions$.pipe(ofType(PDActions.ActionTypes.PackageDetailsPageGetPackageDetails), concatMap(function (action) {
            var eventId = action.payload.eventId;
            return [
                // new PackageDetailsActions.PDEffectsLoadSelectedEvent({eventId}),
                // new PackageDetailsActions.PDEffectsLoadLeaderSummary({eventId}),
                // new PackageDetailsActions.PDEffectsLoadGroupLeaderProfile({eventId}),
                // new PackageDetailsActions.PDEffectsLoadAttendees({eventId}),
                new PDActions.PDEffectsLoadPackageDetails({ eventId: eventId }),
                new PDActions.PDEffectsLoadPackageComponents({ eventId: eventId }),
                new AttendeesActions.GeneralLoadAttendees({ eventId: eventId }),
                new PaymentActions.PackageDetailsEffectsLoadEventPayments({ eventId: eventId }),
                new PaymentActions.PackageDetailsEffectsLoadPaymentsReceivedSummary({ eventId: eventId }),
                new eventActions.LoadSelectedEvent(eventId),
                new AttendeesActions.PackageDetailsEffectLoadLeaderSummary({ eventId: eventId }),
                new userActions.LoadGroupLeaderProfile(eventId),
            ];
        }));
        this.attendeePackageDetailsPageLoad = this.actions$.pipe(ofType(PDActions.ActionTypes.PackageDetailsPageGetPackageDetails), concatMap(function (action) {
            var eventId = action.payload.eventId;
            return [
                new MessageActions.PDEffectsLoadEventMessages({ eventId: eventId }),
                new MessageActions.PDEffectsLoadEventSentMessages({ eventId: eventId }),
            ];
        }));
        this.inboxPageLoad = this.actions$.pipe(ofType(PDActions.ActionTypes.InboxPageLoad), concatMap(function (action) {
            var eventId = null; // null means load messages for all events
            return [
                new MessageActions.PDEffectsLoadEventMessages({ eventId: eventId }),
                new MessageActions.PDEffectsLoadEventSentMessages({ eventId: eventId }),
            ];
        }));
        this.reloadMessages = this.actions$.pipe(ofType(PDActions.ActionTypes.EventRelatedMessagesComponentLoadMessages, PDActions.ActionTypes.MessagesComponentUpdateSentMessages), concatMap(function (action) {
            var eventId = action.payload.eventId;
            return [
                new MessageActions.PDEffectsLoadEventMessages({ eventId: eventId }),
                new MessageActions.PDEffectsLoadEventSentMessages({ eventId: eventId }),
            ];
        }));
        this.forwardActionsTo_PDEffectLoadPackageRecord = this.actions$.pipe(ofType(PDActions.ActionTypes.EventReportComponentPageLoad, PDActions.ActionTypes.EventFeaturesViewComponentReloadPackageRecord), map(function (action) { return new PDActions.PDEffectsLoadPackageRecord({ eventId: action.payload.eventId }); }));
        this.loadPackageDetails = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadPackageDetails), concatMap(function (action) { return _this.packageDetailsService.GetPackage(action.payload.eventId).pipe(map(function (packageDetails) { return new PDActions.PDApiLoadPackageDetailsSuccess({ packageDetails: packageDetails }); }), catchError(function (error) { return of(new PDActions.PDApiLoadPackageDetailsFailure({ errorMessage: error.toString() })); })); }));
        this.loadTagInfo = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadTagInfo), concatMap(function (action) { return _this.packageDetailsService.GetTagsInfo(action.payload.eventId, 'Package').pipe(map(function (tagInfo) { return new PDActions.PDApiLoadTagInfoSuccess({ tagInfo: tagInfo }); }), catchError(function (error) { return of(new PDActions.PDApiLoadTagInfoFailure({ errorMessage: error.toString() })); })); }));
        this.loadPackageComponents = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadPackageComponents), concatMap(function (action) { return _this.packageDetailsService.packageComponentsGetByID(action.payload.eventId).pipe(map(function (components) { return new PDActions.PDApiLoadPackageComponentsSuccess({ components: components }); }), catchError(function (error) { return of(new PDActions.PDApiLoadPackageComponentsFailure({ errorMessage: error.toString() })); })); }));
        this.loadPackageRecord = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadPackageRecord), concatMap(function (action) { return _this.packageDetailsService.GetPackageRecord(action.payload.eventId).pipe(map(function (packageRecord) { return new PDActions.PDApiLoadPackageRecordSuccess({ packageRecord: packageRecord }); }), catchError(function (error) { return of(new PDActions.PDApiLoadPackageRecordFailure({ errorMessage: error.toString() })); })); }));
        this.loadSuppliers = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadSuppliers), concatMap(function (action) { return _this.packageDetailsService.GetActiveSuppliers().pipe(map(function (suppliers) { return new PDActions.PDApiLoadSuppliersSuccess({ suppliers: suppliers }); }), catchError(function (error) { return of(new PDActions.PDApiLoadSuppliersFailure({ errorMessage: error.toString() })); })); }));
        this.loadPackageLogs = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadPackageLogs), concatMap(function (action) { return _this.packageDetailsService.getPackageLogs(action.payload.eventId).pipe(map(function (packageLogs) { return new PDActions.PDApiLoadPackageLogsSuccess({ packageLogs: packageLogs }); }), catchError(function (error) { return of(new PDActions.PDApiLoadPackageLogsFailure({ errorMessage: error.toString() })); })); }));
        this.loadSuppliersForPackage = this.actions$.pipe(ofType(PDActions.ActionTypes.PDEffectsLoadSuppliersForPackage), concatMap(function (action) { return _this.paymentService.getSuppliersForPackage(action.payload.eventId).pipe(map(function (packageSuppliersByActivity) { return new PDActions.PDApiLoadSuppliersForPackageSuccess({ packageSuppliersByActivity: packageSuppliersByActivity }); }), catchError(function (error) { return of(new PDActions.PDApiLoadSuppliersForPackageFailure({ errorMessage: error.toString() })); })); }));
        this.createEventFromBibOrder = this.actions$.pipe(ofType(PDActions.ActionTypes.CreateEventFromBibOrderDialog), concatMap(function (action) { return _this.packageDetailsService.createEventFromBibOrder(action.payload.createEventFromBibOrderRequest).pipe(map(function (createEventFromBibOrderResponse) { return new PDActions.PDApiCreateEventFromBibOrderSuccess({ createEventFromBibOrderResponse: createEventFromBibOrderResponse }); }), catchError(function (error) { return of(new PDActions.PDApiCreateEventFromBibOrderFailure({ errorMessage: error.toString() })); })); }));
        this.navigateToPackageDetailsPageFromBibOrderEventCreation = this.actions$.pipe(ofType(PDActions.ActionTypes.PDApiCreateEventFromBibOrderSuccess), tap(function (action) {
            _this.dialogService.closeBibOrderNumberLoginDialog();
            _this.router.navigateByUrl('/pages/package-details/' + action.payload.createEventFromBibOrderResponse.eventId);
        }));
        this.createEventFromSupplierOrder = this.actions$.pipe(ofType(PDActions.ActionTypes.CreateEventFromSupplierOrderDialog), concatMap(function (action) { return _this.packageDetailsService.createEventFromSupplierOrder(action.payload.createEventFromSupplierOrderRequest).pipe(map(function (createEventFromSupplierOrderResponse) { return new PDActions.PDApiCreateEventFromSupplierOrderSuccess({ createEventFromSupplierOrderResponse: createEventFromSupplierOrderResponse }); }), catchError(function (error) { return of(new PDActions.PDApiCreateEventFromSupplierOrderFailure({ errorMessage: error.toString() })); })); }));
        this.updateEventFromSupplierOrder = this.actions$.pipe(ofType(PDActions.ActionTypes.UpdateEventFromSupplierOrder), concatMap(function (action) { return _this.packageDetailsService.updateEventFromSupplierOrder(action.payload.updateEventFromSupplierOrderRequest).pipe(map(function (updateEventFromSupplierOrderResponse) { return new PDActions.PDApiUpdateEventFromSupplierOrderSuccess({ updateEventFromSupplierOrderResponse: updateEventFromSupplierOrderResponse }); }), catchError(function (error) { return of(new PDActions.PDApiUpdateEventFromSupplierOrderFailure({ errorMessage: error.toString() })); })); }));
        this.loadTickets = this.actions$.pipe(ofType(PDActions.ActionTypes.LoadTickets), concatMap(function (action) { return _this.packageDetailsService.loadTickets(action.payload.loadTicketsRequest).pipe(map(function (loadTicketsResponse) { return new PDActions.PDApiLoadTicketsSuccess({ loadTicketsResponse: loadTicketsResponse }); }), catchError(function (error) { return of(new PDActions.PDApiLoadTicketsFailure({ errorMessage: error.toString() })); })); }));
        this.purchaseTickets = this.actions$.pipe(ofType(PDActions.ActionTypes.PurchaseTickets), concatMap(function (action) { return _this.packageDetailsService.purchaseTickets(action.payload.purchaseTicketsRequest).pipe(map(function (purchaseTicketsResponse) { return new PDActions.PDApiPurchaseTicketsSuccess({ purchaseTicketsResponse: purchaseTicketsResponse }); }), catchError(function (error) { return of(new PDActions.PDApiPurchaseTicketsFailure({ errorMessage: error.toString() })); })); }));
        this.navigateToPackageDetailsPageFromAVOrderEventCreation = this.actions$.pipe(ofType(PDActions.ActionTypes.PDApiCreateEventFromSupplierOrderSuccess), tap(function (action) {
            _this.dialogService.closeSupplierOrderNumberLoginDialog();
            _this.router.navigateByUrl('/pages/package-details/' + action.payload.createEventFromSupplierOrderResponse.eventId);
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "packageDetailsPageLoad", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "packageDetailsPageGetPackageDetails", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "attendeePackageDetailsPageLoad", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "inboxPageLoad", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "reloadMessages", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "forwardActionsTo_PDEffectLoadPackageRecord", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadPackageDetails", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadTagInfo", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadPackageComponents", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadPackageRecord", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadSuppliers", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadPackageLogs", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadSuppliersForPackage", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "createEventFromBibOrder", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "navigateToPackageDetailsPageFromBibOrderEventCreation", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "createEventFromSupplierOrder", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "updateEventFromSupplierOrder", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "loadTickets", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "purchaseTickets", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PackageDetailsEffects.prototype, "navigateToPackageDetailsPageFromAVOrderEventCreation", void 0);
    return PackageDetailsEffects;
}());
export { PackageDetailsEffects };
