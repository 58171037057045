import { Injectable } from '@angular/core';
import { SurveysService } from 'app/pages/surveys/surveys.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SurveysActions from "app/store/actions/surveys.action";
import { AppStateService } from 'app/pages/helpers/appState.service';

@Injectable()
export class SurveysEffects {

    @Effect()
    loadEventSurveys = this.actions$.pipe(
        ofType<SurveysActions.GLEDLoadEventSurveys
                | SurveysActions.AttendeePackageDetailsComponentLoadEventSurveys
                | SurveysActions.AttendeeSurveyViewComponentLoadEventSurveys
                | SurveysActions.PackageDetailsEffectLoadEventSurveys
                | SurveysActions.SurveyListComponentLoadEventSurveys>
                (SurveysActions.ActionTypes.GLEDLoadEventSurveys,
                    SurveysActions.ActionTypes.AttendeePackageDetailsComponentLoadEventSurveys,
                    SurveysActions.ActionTypes.AttendeeSurveyViewComponentLoadEventSurveys,
                    SurveysActions.ActionTypes.PackageDetailsEffectLoadEventSurveys,
                    SurveysActions.ActionTypes.SurveyListComponentLoadEventSurveys,),
        concatMap(action => this.surveyService.getSurveys(action.payload.eventId).pipe(
            map(eventSurveys => new SurveysActions.SurveysEffectLoadEventSurveysSuccess({eventSurveys})),
            catchError(error => of(new SurveysActions.SurveysEffectLoadEventSurveysFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadSurveyDetails = this.actions$.pipe(
        ofType<SurveysActions.AttendeePackageDetailsComponentLoadSurveyDetails
                | SurveysActions.ApdViewComponentLoadSurveyDetails
                | SurveysActions.AttendeeSurveyListComponentLoadSurveyDetails
                | SurveysActions.SurveyComponentLoadSurveyDetails>
                (SurveysActions.ActionTypes.AttendeePackageDetailsComponentLoadSurveyDetails,
                    SurveysActions.ActionTypes.ApdViewComponentLoadSurveyDetails,
                    SurveysActions.ActionTypes.AttendeeSurveyListComponentLoadSurveyDetails,
                    SurveysActions.ActionTypes.SurveyComponentLoadSurveyDetails,),
        concatMap(action => this.surveyService.getSurveyDetails(action.payload.surveyId).pipe(
            map(survey => new SurveysActions.SurveysEffectLoadSurveyDetailsSuccess({survey})),
            catchError(error => of(new SurveysActions.SurveysEffectLoadSurveyDetailsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadCreatedSurveys = this.actions$.pipe(
        ofType<SurveysActions.DashboardComponentLoadCreatedSurveys
                | SurveysActions.GroupLeaderDashboardComponentLoadCreatedSurveys
                | SurveysActions.SurveyDashboardComponentLoadCreatedSurveys>
                (SurveysActions.ActionTypes.DashboardComponentLoadCreatedSurveys,
                    SurveysActions.ActionTypes.GroupLeaderDashboardComponentLoadCreatedSurveys,
                    SurveysActions.ActionTypes.SurveyDashboardComponentLoadCreatedSurveys,),
        concatMap(action => this.appStateService.getSurveyEvents().pipe(
            map(createdSurveys => new SurveysActions.SurveysEffectLoadCreatedSurveysSuccess({createdSurveys})),
            catchError(error => of(new SurveysActions.SurveysEffectLoadCreatedSurveysFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadInvitedSurveys = this.actions$.pipe(
        ofType<SurveysActions.DashboardComponentLoadInvitedSurveys
                | SurveysActions.SurveyAttendeeComponentLoadInvitedSurveys>
                (SurveysActions.ActionTypes.DashboardComponentLoadInvitedSurveys,
                    SurveysActions.ActionTypes.SurveyAttendeeComponentLoadInvitedSurveys,),
        concatMap(action => this.appStateService.getSurveyAttendees().pipe(
            map(invitedSurveys => new SurveysActions.SurveysEffectLoadInvitedSurveysSuccess({invitedSurveys})),
            catchError(error => of(new SurveysActions.SurveysEffectLoadInvitedSurveysFailure({errorMessage: error.toString()}))),
        )),
    );

    constructor(
        private actions$: Actions,
        private surveyService: SurveysService,
        private appStateService: AppStateService,
    ) {}
}
