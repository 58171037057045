import { switchMap, filter, take, finalize, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'app/authentication/authentication.service';
import { ClientAuthConfig } from '../pages/helpers/config.service';
import { NgxConfigureService } from 'ngx-configure';
var AppInterceptor = /** @class */ (function () {
    function AppInterceptor(injector, configService) {
        this.injector = injector;
        this.configService = configService;
        this.isRefreshingToken = false;
        this.tokenSubject = new BehaviorSubject(null);
    }
    AppInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var authService = this.injector.get(AuthService);
        var clientAuthConfig = this.injector.get(ClientAuthConfig);
        if (this.isTokenEndpoint(req)) {
            req = this.addBasicAuth(req, clientAuthConfig);
        }
        else {
            req = this.addBearerAuth(req, authService.getToken());
        }
        return next.handle(req).pipe(catchError(function (error, caught) {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 400:
                        return _this.handle400Error(error, authService);
                    case 401:
                        return _this.handle401Error(req, next, authService);
                    case 500:
                        return observableThrowError(new Error(error.error.ExceptionMessage));
                }
            }
            return observableThrowError(error);
        }));
    };
    AppInterceptor.prototype.isTokenEndpoint = function (req) {
        return req.url === this.configService.config.apiUrl + "token";
    };
    AppInterceptor.prototype.isGetAttendeesFromCsvRequest = function (req) {
        return req.url.includes("GetAttendeesFromCsvText");
    };
    AppInterceptor.prototype.addBearerAuth = function (req, token) {
        if (this.isGetAttendeesFromCsvRequest(req)) {
            return req.clone({
                setHeaders: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'text/csv'
                }
            });
        }
        else {
            return req.clone({
                setHeaders: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json'
                }
            });
        }
    };
    AppInterceptor.prototype.addBasicAuth = function (req, clientAuthConfig) {
        return req.clone({
            setHeaders: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': clientAuthConfig.basicAuthHeader()
            }
        });
    };
    AppInterceptor.prototype.handle400Error = function (error, authService) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant' && !!error.error.error_message) {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            // We also have to check if there's an error message. if there's not, this came from the refresh token.
            // If there is, then it's most likely invalid username/password and we don't want to call logout.
            authService.logout();
        }
        return observableThrowError(error);
    };
    AppInterceptor.prototype.handle401Error = function (req, next, authService) {
        var _this = this;
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            //Hold any other requests until we get the new bearer token back
            this.tokenSubject.next(null);
            return authService.refresh().pipe(switchMap(function (newTokenResponse) {
                if (newTokenResponse) {
                    var newToken = newTokenResponse.access_token;
                    _this.tokenSubject.next(newToken);
                    return next.handle(_this.addBearerAuth(req, newToken));
                }
                // If we don't get a new token, logout.
                authService.logout();
                return observableThrowError("");
            }), catchError(function (error) {
                // If there is an exception calling 'refreshToken', logout.
                authService.logout();
                return observableThrowError(error);
            }), finalize(function () {
                _this.isRefreshingToken = false;
            }));
        }
        else {
            //Hold any other requests until we get the new bearer token back
            return this.tokenSubject.pipe(filter(function (token) { return token != null; }), take(1), switchMap(function (token) {
                return next.handle(_this.addBearerAuth(req, token));
            }));
        }
    };
    return AppInterceptor;
}());
export { AppInterceptor };
