import { PipeTransform } from '@angular/core';
var ShortTimeStringPipe = /** @class */ (function () {
    function ShortTimeStringPipe() {
    }
    ShortTimeStringPipe.prototype.transform = function (ts) {
        if (Boolean(ts) !== true) {
            return ts;
        }
        var hrColon = ts.indexOf(':');
        var minColon = ts.lastIndexOf(':');
        var hours = ts.slice(0, hrColon);
        var minutes = ts.slice(hrColon + 1, minColon);
        var seconds = ts.slice(minColon);
        var append = 'AM';
        if (hours == 12)
            append = 'PM';
        if (hours > 12) {
            hours = hours - 12;
            append = 'PM';
        }
        if (seconds >= 30) {
            minutes++;
        }
        var timeString = hours + ":" + minutes + " " + append;
        return timeString;
    };
    return ShortTimeStringPipe;
}());
export { ShortTimeStringPipe };
