import { CommonModule } from '@angular/common';

// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { InviteDetails, OrderBy } from '../invite-details/invite-details.component';
import { InviteFeatureComponent } from '../invite-feature/invite-feature.component';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { LoginModule } from 'app/pages/login/login.module';
import { RegisterModule } from 'app/pages/register-page/register-page.module';
import { ReCaptchaModule } from 'angular2-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        GTNgaModule,
        WidgetsModule,
        ModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        ReCaptchaModule,
        LoginModule,
        RegisterModule,
    ],
    declarations: [
        InviteDetails,
        OrderBy,
        InviteFeatureComponent
    ],
    exports: [
        InviteDetails
    ]
})
export class InviteDetailsModule {

}
