import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { ActionsService } from "app/actions/actions.service";
import * as ActionActions from "app/store/actions/actions.action";
import { of } from "rxjs";
import { catchError, concatMap, filter, map, withLatestFrom } from "rxjs/operators";
import { MessageRequestDTO } from "../models/requests/MessageRequestDTO";
import * as UserActions from "app/store/actions/user";
import { State as RootStoreState } from 'app/store/reducers';
import { select, Store } from "@ngrx/store";
import * as ActionSelectors from "app/store/selectors/actions.selector";
import * as AttendeesActions from "app/store/actions/attendees.action";
import * as PackageDetailsActions from "app/store/actions/packageDetails.actions";

@Injectable()
export class ActionsEffects {

    // TODO: Could redesign this so it only fires if the status is being set to true AND it was previously false
    //       This functionality isn't immediately available because reducers always fire before effects, so we can't
    //       test the previous value of the status without creating an intermediary user action (which I don't have time for atm).
    @Effect()
    reloadActionsAfterUserIsVerified = this.actions$.pipe(
        ofType<UserActions.SetVerifiedStatus>(UserActions.SET_VERIFIED_STATUS),
        filter(action => action.payload === true),
        map(action => new ActionActions.ActionsEffectUserWasVerified()),
    );

    // Grab other miscellaneous actions to update the actionsList that don't have the eventId info,
    // get the eventId from actions state, and forward to effect to send api request
    @Effect()
    getEventIdAndForwardToLoadActions = this.actions$.pipe(
        ofType<ActionActions.SurveyComponentUpdateActions |
                ActionActions.AttendeeFormResponseComponentUpdateActions |
                ActionActions.ActionsEffectUserWasVerified |
                AttendeesActions.AttendeeEffectChangeAttendeeEmailsSuccess |
                PackageDetailsActions.PackageDetailsPageGetPackageDetails |
                ActionActions.ActionsEffectMarkAsReadSuccess>
                (ActionActions.ActionTypes.SurveyComponentUpdateActions,
                ActionActions.ActionTypes.AttendeeFormResponseComponentUpdateActions,
                ActionActions.ActionTypes.ActionsEffectUserWasVerified,
                AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess,
                PackageDetailsActions.ActionTypes.PackageDetailsPageGetPackageDetails,
                ActionActions.ActionTypes.ActionsEffectMarkAsReadSuccess),
        withLatestFrom(this.store$.pipe(select(ActionSelectors.getActionsEventId)),
                        (action, actionEventId) => {return actionEventId;}),
        map(actionEventId => new ActionActions.ActionsEffectLoadActions({eventId: actionEventId})),
    );

    @Effect()
    loadActions = this.actions$.pipe(
        ofType<ActionActions.ActionsComponentLoadActions | ActionActions.ActionsEffectLoadActions>
                (ActionActions.ActionTypes.ActionsComponentLoadActions, ActionActions.ActionTypes.ActionsEffectLoadActions),
        concatMap(action => {
            const options: MessageRequestDTO = {
                IdPackage: action.payload.eventId,
                state: 'Unread',
                type: null
            };
            return this.actionsService.GetActions(options).pipe(
                map(userActions => new ActionActions.ActionsEffectLoadActionsSuccess({userActions})),
                catchError(error => of(new ActionActions.ActionsEffectLoadActionsFailure({errorMessage: error.toString()}))),
            );
        }),
    );

    @Effect()
    markActionAsRead = this.actions$.pipe(
        ofType<ActionActions.ActionsComponentMarkAsRead>(ActionActions.ActionTypes.ActionsComponentMarkAsRead),
        concatMap(action => this.actionsService.MarkAsRead(action.payload.userAction).pipe(
                map(userActionId => new ActionActions.ActionsEffectMarkAsReadSuccess({userActionId})),
                catchError(error => of(new ActionActions.ActionsEffectMarkAsReadFailure({errorMessage: error.toString()}))),
            )
        ),
    );

    @Effect()
    dismissSurveyActions = this.actions$.pipe(
        ofType<ActionActions.ActionsComponentDismissSurveyActions>(ActionActions.ActionTypes.ActionsComponentDismissSurveyActions),
        concatMap(action => this.actionsService.DismissSurveyActions(action.payload.surveyId).pipe(
                map(userActionId => new ActionActions.ActionsEffectDismissSurveyActionsSuccess({userActionId})),
                catchError(error => of(new ActionActions.ActionsEffectDismissSurveyActionsFailure({errorMessage: error.toString()}))),
            )
        ),
    );

    constructor(
        private actions$: Actions,
        private actionsService: ActionsService,
        private store$: Store<RootStoreState>,
    ) {}
}
