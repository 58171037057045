import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GroupToolsNewModalComponent } from './grouptools-new-modal.component';
import { CloseModal } from 'app/store/actions/modal.action';

@Injectable({ providedIn: 'root' })
export class GroupToolsNewModalService implements OnDestroy {
    private _activeModal: NgbModalRef;
    private _isOpen: boolean;
    private _isStatic: boolean;

    private _isOpen$: Observable<boolean>;
    private _unsubscribe$ = new Subject();

    constructor(
        private _ngbModalService: NgbModal,
        private _store: Store<fromRoot.State>
    ) {
        this._isOpen$ = this._store.select(fromRoot.getModalIsOpen);

        this._isOpen$.pipe(
            takeUntil(this._unsubscribe$),
            withLatestFrom(this._store.select(fromRoot.getModalIsStatic))
        ).subscribe(([isOpen, isStatic]) => {
            this._isOpen = isOpen;
            this._isStatic = isStatic;

            this._isOpen ? this.open() : this.close();
        });
    }

    open() {
        // If we're always using the store to control modals, we should never 
        // be in a state where two modals are open at the same time. This guarantees
        // that if we somehow try to open a second modals, other open modals will be closed first.
        if (this._ngbModalService.hasOpenModals()) {
            this._ngbModalService.dismissAll();
        }


        // TODO: Find a way to switch between static/non-static through the store.
        //      Currently, because this is an options passed to ng-bootstrap's modal
        //      modal service at the time we open the modal, there's no way to change the
        //      value without closing and re-opening. Look into the global configuration as
        //      an option.
        const ngbModalOptions: NgbModalOptions = {};
        if (this._isStatic) {
            ngbModalOptions.backdrop = 'static';
            ngbModalOptions.keyboard = false;
        }

        // Opening a modal with ng-bootstrap generates a promise.
        // We're dispatching the close modal action here to maintain the state in the store,
        // in case the user closes by clicking on the backdrop.
        this._activeModal = this._ngbModalService.open(GroupToolsNewModalComponent, ngbModalOptions);
        this._activeModal.result.then(
            () => {
                if (this._isOpen) {
                    this._activeModal = null;
                    this._store.dispatch(new CloseModal());
                }
            },
            () => {
                if (this._isOpen) {
                    this._activeModal = null;
                    this._store.dispatch(new CloseModal());
                }
            });
    }

    close() {
        if (!!this._activeModal) {
            this._activeModal.close();
            this._activeModal = null;
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }
}

export interface ButtonConfig {
    primary?: {
        text: string;
        action: any;
        disabled?: boolean;
    },
    secondary?: {
        text: string;
        action: any;
        disabled?: boolean;
    }
}
