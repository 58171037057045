import { Profile } from './profile'
import { AttendeeDTO_Old } from 'app/store/models/packages/AttendeeDTO';
import { AttendeeUpsertRequest } from './requests/attendee-upsert-request';

// TODO: Could move this to AttendeeUpsertRequest definition file
export type AttendeeSortDirection = 'databaseOrder' | 'nameAsc' | 'nameDesc' | 'emailPhoneAsc' | 'emailPhoneDesc';

export interface Attendee extends Profile {
    eventId: number,
    eventAttendeeId: number,
    contactId: number,
    personId: number | null,
    invitedBy: string,
    parentUserId: number,
    parentAttendeeId: number,
    isParentResponsible: boolean,
    status: string,
    guestCount: number,
    paymentsSent: number,
    paymentAmountDue: number,
    paymentBalance: number,
    paymentDueDate: Date,
    invitationOpens?: number,
    invitationClicks?: number,
    securityToken: string,
    createdAt?: Date,
    updatedAt?: Date,

    isSelected: boolean, // TODO: Could move into displayOptions property

    // TODO: remove the necessity for these
    // package-details > attendee-summary, filtered list variables
    displayOptions?: AttendeeDisplayOptions
}

export function updateOrCreateAttendeeWithAttendeeUpsert(attendee: Attendee | null, attendeeUpsert: AttendeeUpsertRequest): Attendee {
    if (!attendee) attendee = createNew_Attendee();
    attendee.eventAttendeeId = attendeeUpsert.eventAttendeeId;
    attendee.isParentResponsible = attendeeUpsert.isParentResponsible;
    attendee.parentUserId = attendeeUpsert.parentUserId;
    attendee.parentAttendeeId = attendeeUpsert.parentAttendeeId;
    attendee.fullName = attendeeUpsert.fullName;
    attendee.email = attendeeUpsert.email;
    attendee.phone = attendeeUpsert.phone;
    attendee.guestCount = attendeeUpsert.guestCount;
    attendee.eventId = attendeeUpsert.eventId;
    attendee.status = attendeeUpsert.status;
    attendee.id = attendeeUpsert.userId;
    return attendee;
}

export function createOldAttendeeFromAttendee(attendee: Attendee): AttendeeDTO_Old {
    const a = attendee;
    const oldAttendee: AttendeeDTO_Old = {
        IdPackage: a.eventId,
        AttendeeFirstName: a.firstName,
        AttendeeLastName: a.lastName,
        AttendeeName: a.fullName,
        Status: a.status,
        IdUser: a.id,
        IdContact: a.contactId,
        Email: a.email,
        IdParent: a.parentUserId,
        ParentIsResponsible: a.isParentResponsible,
        CreatedAt: a.createdAt.toISOString(),
        UpdatedAt: a.updatedAt.toISOString(), // TODO: I have no idea what format these dates should be in
        NumOfGuests: a.guestCount,
        AttendeeSummary: {
            Sent: a.paymentsSent,
            AmountDue: a.paymentAmountDue,
            Balance: a.paymentBalance,
            PaymentDueDate: a.paymentDueDate.toISOString(),
        },
        InvitedBy: a.invitedBy,
        SecurityTokenString: a.securityToken,
        SecurityTokenBytes: '',
        InvitationOpens: a.invitationOpens,
        InvitationClicks: a.invitationClicks,

        showMultiSelect: true,
    };
    return oldAttendee;
}

export function createAttendeeFromOldAttendee(oldAttendee: AttendeeDTO_Old): Attendee {
    return {
        eventId: oldAttendee.IdPackage,
        eventAttendeeId: 0,
        contactId: oldAttendee.IdContact,
        personId: null,
        invitedBy: oldAttendee.InvitedBy,
        parentUserId: oldAttendee.IdParent,
        isParentResponsible: oldAttendee.ParentIsResponsible,
        status: oldAttendee.Status,
        guestCount: oldAttendee.NumOfGuests,
        paymentsSent: oldAttendee.AttendeeSummary.Sent,
        paymentAmountDue: oldAttendee.AttendeeSummary.AmountDue,
        paymentBalance: oldAttendee.AttendeeSummary.Balance,
        paymentDueDate: null, // could parse this from string to Date if needed: oldAttendee.AttendeeSummary.PaymentDueDate,
        invitationOpens: oldAttendee.InvitationOpens,
        invitationClicks: oldAttendee.InvitationClicks,
        securityToken: oldAttendee.SecurityTokenString,
        createdAt: null, // could use this: oldAttendee.CreatedAt, but would need to parse, and its not used
        updatedAt: null, // same note as above for oldAttendee.UpdatedAt
        isSelected: false,
    
        displayOptions: {
            showMultiSelect: oldAttendee.showMultiSelect,
        } as AttendeeDisplayOptions,

        // Profile properties
        id: oldAttendee.IdUser,
        email: oldAttendee.Email,
        firstName: oldAttendee.AttendeeFirstName,
        lastName: oldAttendee.AttendeeLastName,
        fullName: oldAttendee.AttendeeName,
        phone: '',
        about: '',
        photoUrl: '',
        isActive: false,
        paymentMethod: '',
        alternamePaymentMethod: ''
    } as Attendee;
}

export function createNew_Attendee(): Attendee {
    return <Attendee> {
        id: 0,
        eventId: 0,
        eventAttendeeId: 0,
        contactId: 0,
        personId: null,
        invitedBy: '',
        parentUserId: 0,
        parentAttendeeId: 0,
        isParentResponsible: false,
        status: 'Unregistered', // TODO: Make enum and use here
        guestCount: 0,
        paymentsSent: 0,
        paymentAmountDue: 0,
        paymentBalance: 0,
        paymentDueDate: null,
        invitationOpens: 0,
        invitationClicks: 0,
        securityToken: '',
        createdAt: null,
        updatedAt: null,

        isSelected: false,
    
        // TODO: remove the necessity for these
        // package-details > attendee-summary, filtered list variables
        displayOptions: null,
    };
}

export interface AttendeeDisplayOptions {
    fullName?: string,
    showMultiSelect?: boolean,
    isGroupLeader?: boolean,
    isGroupLeaderPlusOne?: boolean,
    isAttendeePlusOne?: boolean
}

// TODO: Could move this to the same file where AttendeeUpsertRequest is defined
export function compareAttendees(a: AttendeeUpsertRequest, b: AttendeeUpsertRequest, sortDirection: AttendeeSortDirection) {
    switch (sortDirection) {
        default:
        case 'databaseOrder':
        {
            if (a.eventAttendeeId < b.eventAttendeeId) return -1;
            if (a.eventAttendeeId > b.eventAttendeeId) return 1;
            return 0;
        }
        case 'nameAsc':
        {
            return a.fullName.localeCompare(b.fullName);
        }
        case 'nameDesc':
        {
            return b.fullName.localeCompare(a.fullName);
        }
        case 'emailPhoneAsc':
        {
            return a.emailPhone.localeCompare(b.emailPhone);
        }
        case 'emailPhoneDesc':
        {
            return b.emailPhone.localeCompare(a.emailPhone);
        }
    }
}
