import { createSelector } from "@ngrx/store";
import { AppState } from "app/store/models/app-state";
import { cloneDeep as _cloneDeep } from "lodash";

const getActionsState = (state: AppState) => state.actionsState;

// =================================================================================
//                                      Selectors
// =================================================================================

export const getActionsList = createSelector(
    getActionsState,
    actionsState => actionsState.actions
);

export const getActionsListCopy = createSelector(
    getActionsList,
    actionsList => _cloneDeep(actionsList) // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
);

export const getActionsEventId = createSelector(
    getActionsState,
    actionsState => actionsState.eventId
);
