import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { User } from 'app/store/models/user';
import { takeUntil, tap, delay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { UserEffects } from 'app/store/effects/user.effect';
import * as user from 'app/store/actions/user';
import { AuthService } from 'app/authentication/authentication.service';
import { GrouptoolsModalComponent } from 'app/widgets/grouptools-modal/grouptools-modal.component';
import { LoginComponent } from 'app/pages/login/login.component';

@Component({
  selector: 'iframe-login',
  templateUrl: './iframe-login.component.html',
  styleUrls: ['./iframe-login.component.scss']
})
export class IframeLoginComponent implements AfterViewInit {

  public state: string = 'login';
  public modalState: string;
  public modalTitle: string;
  public modalSize: string = 'modal-md';
  public user$: Observable<User>;
  public isVerified: boolean;
  public loginName: string;
  public loginEmail: string;
  @ViewChild('modal') modal: GrouptoolsModalComponent;
  @ViewChild('loginComponent') loginComponent: LoginComponent;

  private unsubscribe$: Subject<null> = new Subject<null>();
  constructor(
    private store: Store<fromRoot.State>,
    private authService: AuthService
  ) { }

  ngAfterViewInit(): void {
    this.user$ = this.store.select(fromRoot.getUserState).pipe(
      delay(0),
      tap(u => this.isVerified = u.isVerified),
      tap(u => {
          this.loginName = `${u.firstName} ${u.lastName}`;
          this.loginEmail = u.email;
          if (u.isLoggedIn) {
            if (this.isVerified) {
              this.modal.hideModal();
              this.state = 'loggedIn';
            } else {
              this.modalState = 'accountVerification';
              this.modalTitle = 'Account Verification';
              this.modal.showModal();
            }
          } else {
            this.state = 'login';
          }
          if (window.parent) {
            window.parent.postMessage(u, 'http://grouptools.com');
          }
        }),
      takeUntil(this.unsubscribe$),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setState(state: string) {
    this.state = state;
  }

  logout() {
    this.authService.logoutNoNavigation();
  }

  public loginAction(event): void {
    switch (event) {
      case 'register':
        this.state = 'register';
        break;
      case 'passwordReset':
        this.modalState = 'passwordReset';
        this.modalTitle = 'Reset Password';
        this.modal.showModal();
        break;
      case 'closeModal':
        break;
      case 'login':
        this.state = 'login';
        break;
      case 'loggedIn':
        break;
      case 'verified':
        this.authService.refresh().subscribe(suc => {
          this.store.dispatch(new user.SetVerifiedStatus(true));
          this.state = 'loggedIn';
        });
        this.modal.hideModal();
        break;
      default:
        break;
    }
  }

}
