// Angular Imports
import { FacebookLoginModule } from '../facebook-login/facebook-login.module';
import { AmazonLoginModule } from '../amazon-login/amazon-login.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { GoogleLoginModule } from 'app/pages/google-login/google-login.module';
import { LoginWithSocialComponent } from 'app/pages/login/login-with-social/login-with-social.component';
// This Module's Components
import { LoginComponent } from './login.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        WidgetsModule,
        GoogleLoginModule,
        AmazonLoginModule,
        FacebookLoginModule
    ],
    declarations: [
        LoginComponent,
        LoginWithSocialComponent
    ],
    exports: [
        LoginComponent,
        LoginWithSocialComponent
    ]
})
export class LoginModule {

}
