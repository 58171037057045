// TODO: This action is just a placeholder until error actions are implemented properly
// Eventually this action should be phased out and replaced.
export var UNHANDLED_ERROR = '[App] Unhandled App Error!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!';
var UnhandledError = /** @class */ (function () {
    function UnhandledError(payload) {
        this.payload = payload;
        this.type = UNHANDLED_ERROR;
    }
    return UnhandledError;
}());
export { UnhandledError };
