import { Injectable } from '@angular/core';

@Injectable()
export class NbgDatePickerUtility {
    tryClose(event, datepicker) {
        if(!!event) {
            let path = event.hasOwnProperty('path') ? event.path : this.buildThePath(event.target, []);
            path = path.map(p => p.localName);

            if (!path.includes('ngb-datepicker')) {
                datepicker.close();
            }
        }
    }

    private buildThePath(target, currentPath) {
        currentPath = currentPath || [];
        
        if(!!target) {
            if(!!target.parentNode)
                currentPath = this.buildThePath(target.parentNode, currentPath);
                
            currentPath.push({
                localName: target.localName || ''
            });
        }
        
        return currentPath;
    }
}
