export var ActionTypes;
(function (ActionTypes) {
    // TODO: Create separate actions file for component/page actions?
    ActionTypes["PackageDetailsPageLoad"] = "[Package Details Page] Page Load";
    ActionTypes["PackageDetailsPageGetPackageDetails"] = "[Package Details Page] Get Package Details";
    ActionTypes["AttendeePackageDetailsPageLoad"] = "[Attendee Package Details Page] Page Load";
    ActionTypes["InboxPageLoad"] = "[Inbox Page] Page Load";
    ActionTypes["EventRelatedMessagesComponentLoadMessages"] = "[Event Related Messages Component] Load Messages";
    ActionTypes["MessagesComponentUpdateSentMessages"] = "[Messages Component] Update Sent Messages";
    ActionTypes["EventReportComponentPageLoad"] = "[Event Report Component] Page Load";
    // PDEffectsLoadSelectedEvent = '[Package Details Effect] Load Selected Event',
    // PDApiLoadSelectedEventSuccess = '[Package Details Api] Load Selected Event Success',
    // PDApiLoadSelectedEventFailure = '[Package Details Api] Load Selected Event Failure',
    // PDEffectsLoadLeaderSummary = '[Package Details Effect] Load Leader Summary',
    // PDApiLoadLeaderSummarySuccess = '[Package Details Api] Load Leader Summary Success',
    // PDApiLoadLeaderSummaryFailure = '[Package Details Api] Load Leader Summary Failure',
    // PDEffectsLoadAlternateLeaders = '[Package Details Effect] Load Alternate Leaders',
    // PDApiLoadAlternateLeadersSuccess = '[Package Details Api] Load Alternate Leaders Success',
    // PDApiLoadAlternateLeadersFailure = '[Package Details Api] Load Alternate Leaders Failure',
    // PDEffectsLoadGroupLeaderProfile = '[Package Details Effect] Load Group Leader Profile',
    // PDApiLoadGroupLeaderProfileSuccess = '[Package Details Api] Load Group Leader Profile Success',
    // PDApiLoadGroupLeaderProfileFailure = '[Package Details Api] Load Group Leader Profile Failure',
    // PDEffectsLoadSelectedActivities = '[Package Details Effect] Load Selected Activities',
    // PDApiLoadSelectedActivitiesSuccess = '[Package Details Api] Load Selected Activities Success',
    // PDApiLoadSelectedActivitiesFailure = '[Package Details Api] Load Selected Activities Failure',
    // PDEffectsLoadAttendees = '[Package Details Effect] Load Attendees',
    // PDApiLoadAttendeesSuccess = '[Package Details Api] Load Attendees Success',
    // PDApiLoadAttendeesFailure = '[Package Details Api] Load Attendees Failure',
    // PDEffectsLoadEventContacts = '[Package Details Effect] Load Event Contacts',
    // PDApiLoadEventContactsSuccess = '[Package Details Api] Load Event Contacts Success',
    // PDApiLoadEventContactsFailure = '[Package Details Api] Load Event Contacts Failure',
    ActionTypes["PDEffectsLoadPackageDetails"] = "[Package Details Effect] Load Package Details";
    ActionTypes["PDApiLoadPackageDetailsSuccess"] = "[Package Details Api] Load Package Details Success";
    ActionTypes["PDApiLoadPackageDetailsFailure"] = "[Package Details Api] Load Package Details Failure";
    ActionTypes["PDEffectsLoadTagInfo"] = "[Package Details Effect] Load Tag Info";
    ActionTypes["PDApiLoadTagInfoSuccess"] = "[Package Details Api] Load Tag Info Success";
    ActionTypes["PDApiLoadTagInfoFailure"] = "[Package Details Api] Load Tag Info Failure";
    ActionTypes["PDEffectsLoadPackageComponents"] = "[Package Details Effect] Load Package Components";
    ActionTypes["PDApiLoadPackageComponentsSuccess"] = "[Package Details Api] Load Package Components Success";
    ActionTypes["PDApiLoadPackageComponentsFailure"] = "[Package Details Api] Load Package Components Failure";
    ActionTypes["EventFeaturesViewComponentReloadPackageRecord"] = "[Event Features View Component] Reload Package Record";
    ActionTypes["PDEffectsLoadPackageRecord"] = "[Package Details Effect] Load Package Record";
    ActionTypes["PDApiLoadPackageRecordSuccess"] = "[Package Details Api] Load Package Record Success";
    ActionTypes["PDApiLoadPackageRecordFailure"] = "[Package Details Api] Load Package Record Failure";
    ActionTypes["PDEffectsLoadSuppliers"] = "[Package Details Effect] Load Suppliers";
    ActionTypes["PDApiLoadSuppliersSuccess"] = "[Package Details Api] Load Suppliers Success";
    ActionTypes["PDApiLoadSuppliersFailure"] = "[Package Details Api] Load Suppliers Failure";
    ActionTypes["PDEffectsLoadPackageLogs"] = "[Package Details Effect] Load Package Logs";
    ActionTypes["PDApiLoadPackageLogsSuccess"] = "[Package Details Api] Load Package Logs Success";
    ActionTypes["PDApiLoadPackageLogsFailure"] = "[Package Details Api] Load Package Logs Failure";
    ActionTypes["PDEffectsLoadSuppliersForPackage"] = "[Package Details Effect] Load Suppliers For Package";
    ActionTypes["PDApiLoadSuppliersForPackageSuccess"] = "[Package Details Api] Load Suppliers For Package Success";
    ActionTypes["PDApiLoadSuppliersForPackageFailure"] = "[Package Details Api] Load Suppliers For Package Failure";
    ActionTypes["PDEffectsLoadSurveys"] = "[Package Details Effect] Load Surveys";
    ActionTypes["CreateEventFromBibOrderDialog"] = "[Create Event From Bib Order Dialog] Create Event From Bib Order";
    ActionTypes["PDApiCreateEventFromBibOrderSuccess"] = "[Package Details Api] Create Event From Bib Order Success";
    ActionTypes["PDApiCreateEventFromBibOrderFailure"] = "[Package Details Api] Create Event From Bib Order Failure";
    ActionTypes["CreateEventFromSupplierOrderDialog"] = "[Create Event From AV Order Dialog] Create Event From AV Order";
    ActionTypes["PDApiCreateEventFromSupplierOrderSuccess"] = "[Package Details Api] Create Event From AV Order Success";
    ActionTypes["PDApiCreateEventFromSupplierOrderFailure"] = "[Package Details Api] Create Event From AV Order Failure";
    ActionTypes["UpdateEventFromSupplierOrder"] = "[Update Event From AV Order] Update Event From AV Order";
    ActionTypes["PDApiUpdateEventFromSupplierOrderSuccess"] = "[Package Details Api] Update Event From AV Order Success";
    ActionTypes["PDApiUpdateEventFromSupplierOrderFailure"] = "[Package Details Api] Update Event From AV Order Failure";
    ActionTypes["LoadTickets"] = "[Load Tickets] Load Tickets";
    ActionTypes["PDApiLoadTicketsSuccess"] = "[Package Details Api] Load Tickets Success";
    ActionTypes["PDApiLoadTicketsFailure"] = "[Package Details Api] Load Tickets Failure";
    ActionTypes["PurchaseTickets"] = "[Purchase Tickets] Purchase Tickets";
    ActionTypes["PDApiPurchaseTicketsSuccess"] = "[Package Details Api] Purchase Tickets Success";
    ActionTypes["PDApiPurchaseTicketsFailure"] = "[Package Details Api] Purchase Tickets Failure";
    ActionTypes["UpdateEventOk"] = "[Event] Update Event Ok";
    // PDEffectsLoadReplaceMe = '[Package Details Effect] Load ReplaceMe',
    // PDApiLoadReplaceMeSuccess = '[Package Details Api] Load ReplaceMe Success',
    // PDApiLoadReplaceMeFailure = '[Package Details Api] Load ReplaceMe Failure',
    ActionTypes["AuthenticationServiceLogout"] = "[Authentication Service] Logout";
})(ActionTypes || (ActionTypes = {}));
//-----------------------------------------------------------------
// export class PDEffectsLoadReplaceMe implements Action {
//     readonly type = ActionTypes.PDEffectsLoadReplaceMe;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadReplaceMeSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadReplaceMeSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadReplaceMeFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadReplaceMeFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
//-----------------------------------------------------------------
var PackageDetailsPageLoad = /** @class */ (function () {
    function PackageDetailsPageLoad(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsPageLoad;
    }
    return PackageDetailsPageLoad;
}());
export { PackageDetailsPageLoad };
var PackageDetailsPageGetPackageDetails = /** @class */ (function () {
    function PackageDetailsPageGetPackageDetails(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsPageGetPackageDetails;
    }
    return PackageDetailsPageGetPackageDetails;
}());
export { PackageDetailsPageGetPackageDetails };
var AttendeePackageDetailsPageLoad = /** @class */ (function () {
    function AttendeePackageDetailsPageLoad(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeePackageDetailsPageLoad;
    }
    return AttendeePackageDetailsPageLoad;
}());
export { AttendeePackageDetailsPageLoad };
var InboxPageLoad = /** @class */ (function () {
    function InboxPageLoad() {
        this.type = ActionTypes.InboxPageLoad;
    }
    return InboxPageLoad;
}());
export { InboxPageLoad };
//-----------------------------------------------------------------
var EventRelatedMessagesComponentLoadMessages = /** @class */ (function () {
    function EventRelatedMessagesComponentLoadMessages(payload) {
        this.payload = payload;
        this.type = ActionTypes.EventRelatedMessagesComponentLoadMessages;
    }
    return EventRelatedMessagesComponentLoadMessages;
}());
export { EventRelatedMessagesComponentLoadMessages };
var MessagesComponentUpdateSentMessages = /** @class */ (function () {
    function MessagesComponentUpdateSentMessages(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessagesComponentUpdateSentMessages;
    }
    return MessagesComponentUpdateSentMessages;
}());
export { MessagesComponentUpdateSentMessages };
//-----------------------------------------------------------------
var EventReportComponentPageLoad = /** @class */ (function () {
    function EventReportComponentPageLoad(payload) {
        this.payload = payload;
        this.type = ActionTypes.EventReportComponentPageLoad;
    }
    return EventReportComponentPageLoad;
}());
export { EventReportComponentPageLoad };
//-----------------------------------------------------------------
var PDEffectsLoadPackageDetails = /** @class */ (function () {
    function PDEffectsLoadPackageDetails(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadPackageDetails;
    }
    return PDEffectsLoadPackageDetails;
}());
export { PDEffectsLoadPackageDetails };
var PDApiLoadPackageDetailsSuccess = /** @class */ (function () {
    function PDApiLoadPackageDetailsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageDetailsSuccess;
    }
    return PDApiLoadPackageDetailsSuccess;
}());
export { PDApiLoadPackageDetailsSuccess };
var PDApiLoadPackageDetailsFailure = /** @class */ (function () {
    function PDApiLoadPackageDetailsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageDetailsFailure;
    }
    return PDApiLoadPackageDetailsFailure;
}());
export { PDApiLoadPackageDetailsFailure };
//-----------------------------------------------------------------
var PDEffectsLoadTagInfo = /** @class */ (function () {
    function PDEffectsLoadTagInfo(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadTagInfo;
    }
    return PDEffectsLoadTagInfo;
}());
export { PDEffectsLoadTagInfo };
var PDApiLoadTagInfoSuccess = /** @class */ (function () {
    function PDApiLoadTagInfoSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadTagInfoSuccess;
    }
    return PDApiLoadTagInfoSuccess;
}());
export { PDApiLoadTagInfoSuccess };
var PDApiLoadTagInfoFailure = /** @class */ (function () {
    function PDApiLoadTagInfoFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadTagInfoFailure;
    }
    return PDApiLoadTagInfoFailure;
}());
export { PDApiLoadTagInfoFailure };
//-----------------------------------------------------------------
var PDEffectsLoadPackageComponents = /** @class */ (function () {
    function PDEffectsLoadPackageComponents(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadPackageComponents;
    }
    return PDEffectsLoadPackageComponents;
}());
export { PDEffectsLoadPackageComponents };
var PDApiLoadPackageComponentsSuccess = /** @class */ (function () {
    function PDApiLoadPackageComponentsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageComponentsSuccess;
    }
    return PDApiLoadPackageComponentsSuccess;
}());
export { PDApiLoadPackageComponentsSuccess };
var PDApiLoadPackageComponentsFailure = /** @class */ (function () {
    function PDApiLoadPackageComponentsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageComponentsFailure;
    }
    return PDApiLoadPackageComponentsFailure;
}());
export { PDApiLoadPackageComponentsFailure };
//-----------------------------------------------------------------
var EventFeaturesViewComponentReloadPackageRecord = /** @class */ (function () {
    function EventFeaturesViewComponentReloadPackageRecord(payload) {
        this.payload = payload;
        this.type = ActionTypes.EventFeaturesViewComponentReloadPackageRecord;
    }
    return EventFeaturesViewComponentReloadPackageRecord;
}());
export { EventFeaturesViewComponentReloadPackageRecord };
var PDEffectsLoadPackageRecord = /** @class */ (function () {
    function PDEffectsLoadPackageRecord(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadPackageRecord;
    }
    return PDEffectsLoadPackageRecord;
}());
export { PDEffectsLoadPackageRecord };
var PDApiLoadPackageRecordSuccess = /** @class */ (function () {
    function PDApiLoadPackageRecordSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageRecordSuccess;
    }
    return PDApiLoadPackageRecordSuccess;
}());
export { PDApiLoadPackageRecordSuccess };
var PDApiLoadPackageRecordFailure = /** @class */ (function () {
    function PDApiLoadPackageRecordFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageRecordFailure;
    }
    return PDApiLoadPackageRecordFailure;
}());
export { PDApiLoadPackageRecordFailure };
//-----------------------------------------------------------------
var PDEffectsLoadSuppliers = /** @class */ (function () {
    function PDEffectsLoadSuppliers() {
        this.type = ActionTypes.PDEffectsLoadSuppliers;
    }
    return PDEffectsLoadSuppliers;
}());
export { PDEffectsLoadSuppliers };
var PDApiLoadSuppliersSuccess = /** @class */ (function () {
    function PDApiLoadSuppliersSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadSuppliersSuccess;
    }
    return PDApiLoadSuppliersSuccess;
}());
export { PDApiLoadSuppliersSuccess };
var PDApiLoadSuppliersFailure = /** @class */ (function () {
    function PDApiLoadSuppliersFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadSuppliersFailure;
    }
    return PDApiLoadSuppliersFailure;
}());
export { PDApiLoadSuppliersFailure };
//-----------------------------------------------------------------
var PDEffectsLoadPackageLogs = /** @class */ (function () {
    function PDEffectsLoadPackageLogs(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadPackageLogs;
    }
    return PDEffectsLoadPackageLogs;
}());
export { PDEffectsLoadPackageLogs };
var PDApiLoadPackageLogsSuccess = /** @class */ (function () {
    function PDApiLoadPackageLogsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageLogsSuccess;
    }
    return PDApiLoadPackageLogsSuccess;
}());
export { PDApiLoadPackageLogsSuccess };
var PDApiLoadPackageLogsFailure = /** @class */ (function () {
    function PDApiLoadPackageLogsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadPackageLogsFailure;
    }
    return PDApiLoadPackageLogsFailure;
}());
export { PDApiLoadPackageLogsFailure };
//-----------------------------------------------------------------
var PDEffectsLoadSuppliersForPackage = /** @class */ (function () {
    function PDEffectsLoadSuppliersForPackage(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadSuppliersForPackage;
    }
    return PDEffectsLoadSuppliersForPackage;
}());
export { PDEffectsLoadSuppliersForPackage };
var PDApiLoadSuppliersForPackageSuccess = /** @class */ (function () {
    function PDApiLoadSuppliersForPackageSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadSuppliersForPackageSuccess;
    }
    return PDApiLoadSuppliersForPackageSuccess;
}());
export { PDApiLoadSuppliersForPackageSuccess };
var PDApiLoadSuppliersForPackageFailure = /** @class */ (function () {
    function PDApiLoadSuppliersForPackageFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadSuppliersForPackageFailure;
    }
    return PDApiLoadSuppliersForPackageFailure;
}());
export { PDApiLoadSuppliersForPackageFailure };
//-----------------------------------------------------------------
var PDEffectsLoadSurveys = /** @class */ (function () {
    function PDEffectsLoadSurveys(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadSurveys;
    }
    return PDEffectsLoadSurveys;
}());
export { PDEffectsLoadSurveys };
//-----------------------------------------------------------------
var CreateEventFromBibOrderDialog = /** @class */ (function () {
    function CreateEventFromBibOrderDialog(payload) {
        this.payload = payload;
        this.type = ActionTypes.CreateEventFromBibOrderDialog;
    }
    return CreateEventFromBibOrderDialog;
}());
export { CreateEventFromBibOrderDialog };
var PDApiCreateEventFromBibOrderSuccess = /** @class */ (function () {
    function PDApiCreateEventFromBibOrderSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiCreateEventFromBibOrderSuccess;
    }
    return PDApiCreateEventFromBibOrderSuccess;
}());
export { PDApiCreateEventFromBibOrderSuccess };
var PDApiCreateEventFromBibOrderFailure = /** @class */ (function () {
    function PDApiCreateEventFromBibOrderFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiCreateEventFromBibOrderFailure;
    }
    return PDApiCreateEventFromBibOrderFailure;
}());
export { PDApiCreateEventFromBibOrderFailure };
//-----------------------------------------------------------------
var CreateEventFromSupplierOrderDialog = /** @class */ (function () {
    function CreateEventFromSupplierOrderDialog(payload) {
        this.payload = payload;
        this.type = ActionTypes.CreateEventFromSupplierOrderDialog;
    }
    return CreateEventFromSupplierOrderDialog;
}());
export { CreateEventFromSupplierOrderDialog };
var PDApiCreateEventFromSupplierOrderSuccess = /** @class */ (function () {
    function PDApiCreateEventFromSupplierOrderSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiCreateEventFromSupplierOrderSuccess;
    }
    return PDApiCreateEventFromSupplierOrderSuccess;
}());
export { PDApiCreateEventFromSupplierOrderSuccess };
var PDApiCreateEventFromSupplierOrderFailure = /** @class */ (function () {
    function PDApiCreateEventFromSupplierOrderFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiCreateEventFromSupplierOrderFailure;
    }
    return PDApiCreateEventFromSupplierOrderFailure;
}());
export { PDApiCreateEventFromSupplierOrderFailure };
//-----------------------------------------------------------------
var UpdateEventFromSupplierOrder = /** @class */ (function () {
    function UpdateEventFromSupplierOrder(payload) {
        this.payload = payload;
        this.type = ActionTypes.UpdateEventFromSupplierOrder;
    }
    return UpdateEventFromSupplierOrder;
}());
export { UpdateEventFromSupplierOrder };
var PDApiUpdateEventFromSupplierOrderSuccess = /** @class */ (function () {
    function PDApiUpdateEventFromSupplierOrderSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiUpdateEventFromSupplierOrderSuccess;
    }
    return PDApiUpdateEventFromSupplierOrderSuccess;
}());
export { PDApiUpdateEventFromSupplierOrderSuccess };
var PDApiUpdateEventFromSupplierOrderFailure = /** @class */ (function () {
    function PDApiUpdateEventFromSupplierOrderFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiUpdateEventFromSupplierOrderFailure;
    }
    return PDApiUpdateEventFromSupplierOrderFailure;
}());
export { PDApiUpdateEventFromSupplierOrderFailure };
//-----------------------------------------------------------------
var LoadTickets = /** @class */ (function () {
    function LoadTickets(payload) {
        this.payload = payload;
        this.type = ActionTypes.LoadTickets;
    }
    return LoadTickets;
}());
export { LoadTickets };
var PDApiLoadTicketsSuccess = /** @class */ (function () {
    function PDApiLoadTicketsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadTicketsSuccess;
    }
    return PDApiLoadTicketsSuccess;
}());
export { PDApiLoadTicketsSuccess };
var PDApiLoadTicketsFailure = /** @class */ (function () {
    function PDApiLoadTicketsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiLoadTicketsFailure;
    }
    return PDApiLoadTicketsFailure;
}());
export { PDApiLoadTicketsFailure };
//-----------------------------------------------------------------
var PurchaseTickets = /** @class */ (function () {
    function PurchaseTickets(payload) {
        this.payload = payload;
        this.type = ActionTypes.PurchaseTickets;
    }
    return PurchaseTickets;
}());
export { PurchaseTickets };
var PDApiPurchaseTicketsSuccess = /** @class */ (function () {
    function PDApiPurchaseTicketsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiPurchaseTicketsSuccess;
    }
    return PDApiPurchaseTicketsSuccess;
}());
export { PDApiPurchaseTicketsSuccess };
var PDApiPurchaseTicketsFailure = /** @class */ (function () {
    function PDApiPurchaseTicketsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDApiPurchaseTicketsFailure;
    }
    return PDApiPurchaseTicketsFailure;
}());
export { PDApiPurchaseTicketsFailure };
//-----------------------------------------------------------------
// export class PDEffectsLoadSelectedEvent implements Action {
//     readonly type = ActionTypes.PDEffectsLoadSelectedEvent;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedEventSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedEventSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedEventFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedEventFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadLeaderSummary implements Action {
//     readonly type = ActionTypes.PDEffectsLoadLeaderSummary;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadLeaderSummarySuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadLeaderSummarySuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadLeaderSummaryFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadLeaderSummaryFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadAlternateLeaders implements Action {
//     readonly type = ActionTypes.PDEffectsLoadAlternateLeaders;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAlternateLeadersSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadAlternateLeadersSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAlternateLeadersFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadAlternateLeadersFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadGroupLeaderProfile implements Action {
//     readonly type = ActionTypes.PDEffectsLoadGroupLeaderProfile;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadGroupLeaderProfileSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadGroupLeaderProfileSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadGroupLeaderProfileFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadGroupLeaderProfileFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadSelectedActivities implements Action {
//     readonly type = ActionTypes.PDEffectsLoadSelectedActivities;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedActivitiesSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedActivitiesSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedActivitiesFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedActivitiesFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadAttendees implements Action {
//     readonly type = ActionTypes.PDEffectsLoadAttendees;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAttendeesSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadAttendeesSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAttendeesFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadAttendeesFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadEventContacts implements Action {
//     readonly type = ActionTypes.PDEffectsLoadEventContacts;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadEventContactsSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadEventContactsSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadEventContactsFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadEventContactsFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
//-----------------------------------------------------------------
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = ActionTypes.AuthenticationServiceLogout;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
