import { Action } from '@ngrx/store';
import { ShortUrl } from 'app/store/models/shortUrl.interface';

export const SET_SHORT_URL = '[ShortUrl] Set Short Url';

export class SetShortUrl implements Action {
  readonly type = SET_SHORT_URL;

  constructor(public payload: ShortUrl) {}
}

export type Actions
  = SetShortUrl;
