import * as tslib_1 from "tslib";
import * as loaderActions from '../actions/loader.action';
var initialState = {
    show: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case loaderActions.SHOW_LOADER: {
            return tslib_1.__assign({}, state, { show: true });
        }
        case loaderActions.HIDE_LOADER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
export var getIsLoading = function (state) { return state.show; };
