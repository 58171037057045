import { EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
var GrouptoolsModalComponent = /** @class */ (function () {
    function GrouptoolsModalComponent() {
        this.styles = ['modal-md'];
        this.static = false;
        this.showHeader = true;
        this.onHidden = new EventEmitter();
        this.primaryButtonClick = new EventEmitter();
        this.secondaryButtonClick = new EventEmitter();
        this.config = {
            backdrop: true
        };
    }
    GrouptoolsModalComponent.prototype.showModal = function () {
        this.grouptoolsModal.show();
    };
    GrouptoolsModalComponent.prototype.hideModal = function () {
        this.grouptoolsModal.hide();
    };
    GrouptoolsModalComponent.prototype.ngOnChanges = function (change) {
        var staticChange = change.static;
        var sizeChange = change.size;
        if (staticChange) {
            if (this.static) {
                this.config = { backdrop: 'static' };
            }
            else {
                this.config.backdrop = true;
            }
        }
        if (sizeChange) {
            if (this.size) {
                this.styles.length = 0;
                if (this.size.indexOf('modal-sm') > -1) {
                    this.styles.push('modal-sm');
                }
                if (this.size.indexOf('modal-md') > -1) {
                    this.styles.push('modal-md');
                }
                if (this.size.indexOf('modal-lg') > -1) {
                    this.styles.push('modal-lg');
                }
                if (this.size.indexOf('auto') > -1) {
                    this.styles.push('auto');
                    this.auto = 'auto';
                }
            }
        }
    };
    return GrouptoolsModalComponent;
}());
export { GrouptoolsModalComponent };
;
