import { tap, map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
var ContactService = /** @class */ (function () {
    function ContactService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
        this.count = 0;
    }
    ContactService.prototype.insertContact = function (person) {
        return this.http.post(this.URLs._insertContactUrl(), person);
    };
    ContactService.prototype.insertContactForUser = function (person, userId) {
        return this.http.post(this.URLs._insertContactUrlForUser(userId), person);
    };
    ContactService.prototype.deleteContactTags = function (person) {
        if (person.TagsToDelete != undefined && person.TagsToDelete.length !== 0) {
            var deleteBody = {
                tagText: person.TagsToDelete,
                record: "Contact",
                recordId: person.IdContact
            };
            return this.http.post(this.URLs._deleteTagsInfo(), deleteBody).pipe(catchError(function (err) {
                var details = err;
                return observableThrowError(details);
            }));
        }
        else {
            return;
        }
    };
    ContactService.prototype.addContactTags = function (person) {
        if (person.TagsToAdd != undefined && person.TagsToAdd.length !== 0) {
            var addBody = {
                tagText: person.TagsToAdd,
                record: "Contact",
                recordId: person.IdContact
            };
            return this.http.post(this.URLs._insertTagsInfo(), addBody).pipe(catchError(function (err) {
                var details = err;
                return observableThrowError(details);
            }));
        }
        else {
            return;
        }
    };
    ContactService.prototype.insertContacts = function (people) {
        return this.http.post(this.URLs._insertContactsUrl(), people).pipe(catchError(function (err) {
            var details = err;
            return observableThrowError(details);
        }));
    };
    ContactService.prototype.insertContactsForUser = function (people, userId) {
        return this.http.post(this.URLs._insertManyContactsUrlForUser(userId), people).pipe(catchError(function (err) {
            var details = err;
            return observableThrowError(details);
        }));
    };
    ContactService.prototype.removeContact = function (person) {
        return this.http.post(this.URLs._removeContactUrl(), person).pipe(map(function (res) {
            if (res.Result) {
            }
            return res;
        }));
    };
    ContactService.prototype.removeContacts = function (person) {
        return this.http.post(this.URLs._removeContactsUrl(), person).pipe(map(function (res) {
            if (res.Result) {
            }
            return res;
        }));
    };
    ContactService.prototype.inviteContact = function (contact) {
        return this.http.post(this.URLs._inviteContactUrl(), contact).pipe(map(function (res) {
            if (res.Result) {
            }
            return res;
        }));
    };
    ContactService.prototype.inviteContacts = function (contacts) {
        return this.http.post(this.URLs._inviteContactsUrl(), contacts).pipe(map(function (res) {
            if (res.Result) {
            }
            return res;
        }));
    };
    ContactService.prototype.inviteContactsToPackage = function (requestBody) {
        return this.http.post(this.URLs._packageInvite(), requestBody);
    };
    ContactService.prototype.inviteContactsToSurvey = function (requestBody) {
        return this.http.post(this.URLs._surveyInvite(), requestBody).pipe(map(function (res) { return res.Obj; }));
    };
    ContactService.prototype.contactDashboard = function (userID) {
        if (userID === void 0) { userID = ''; }
        if (userID === '') {
            userID = localStorage.getItem('userid');
        }
        return this.http.post(this.URLs._contactDashboardUrl(), userID).pipe(map(function (res) {
            return res.Obj;
        }));
    };
    ContactService.prototype.getContactsData = function () {
        return this.http.get(this.URLs._contactsUrl()).pipe(handleGTResponse(), tap(function (contacts) {
            contacts.forEach(function (c) {
                c.Tags = (c.Tags !== undefined) ? c.Tags.replace(/ /g, ',').split(',').sort().join() : "";
                c.IsNew = false;
            });
        }));
    };
    ContactService.prototype.getEventLeaderContacts = function (eventId) {
        return this.http.get(this.URLs._getGroupLeaderContacts(eventId)).pipe(handleGTResponse());
    };
    ContactService.prototype.updateGLResponse = function (update) {
        return this.http.post(this.URLs._packageUpdateResponse(), update).pipe(handleGTResponse());
    };
    return ContactService;
}());
export { ContactService };
