export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["GeneralLoadConfiguration"] = "[General] Load Configuration";
    ActionTypes["ConfigurationEffectLoadConfigurationSuccess"] = "[Configuration Effect] Load Configuration Success";
    ActionTypes["ConfigurationEffectLoadConfigurationFailure"] = "[Configuration Effect] Load Configuration Failure";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var GeneralLoadConfiguration = /** @class */ (function () {
    function GeneralLoadConfiguration(payload) {
        this.payload = payload;
        this.type = ActionTypes.GeneralLoadConfiguration;
    }
    return GeneralLoadConfiguration;
}());
export { GeneralLoadConfiguration };
var ConfigurationEffectLoadConfigurationSuccess = /** @class */ (function () {
    function ConfigurationEffectLoadConfigurationSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ConfigurationEffectLoadConfigurationSuccess;
    }
    return ConfigurationEffectLoadConfigurationSuccess;
}());
export { ConfigurationEffectLoadConfigurationSuccess };
var ConfigurationEffectLoadConfigurationFailure = /** @class */ (function () {
    function ConfigurationEffectLoadConfigurationFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ConfigurationEffectLoadConfigurationFailure;
    }
    return ConfigurationEffectLoadConfigurationFailure;
}());
export { ConfigurationEffectLoadConfigurationFailure };
