import { Message } from "../models/message";
import * as messageActions from '../actions/message.action';
import { MessagesState } from "../models/messagesState";
import { userMessageEntityAdapter, userMessageEntitySelectors } from "../entity-adapters/message.entity";
import { cloneDeep as _cloneDeep } from "lodash";
import { UserMessageDTO } from "../models/packages/UserMessageDTO";
import { Update } from "@ngrx/entity";

export type State = MessagesState;

export const initialState: MessagesState = {

    eventMessages: userMessageEntityAdapter.getInitialState({
        // Initialize extra properties here
    }),
    eventSentMessages: userMessageEntityAdapter.getInitialState({
        // Initialize extra properties here
    }),
    messageSummary: null,

    eventMessagesNew: [],
}

export function reducer(state: State = initialState, action: messageActions.Actions): State {
    switch (action.type) {
        case messageActions.ActionTypes.MessageEffectsLoadEventMessagesSuccess:
        {
            return {
                ...state,
                eventMessages: userMessageEntityAdapter.addAll(action.payload.eventMessages, state.eventMessages),
            };
        }
        case messageActions.ActionTypes.MessageEffectsLoadEventMessagesFailure:
        {
            return { ...state }; // TODO: Implement somewhere to store error
        }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.MessageEffectsLoadEventSentMessagesSuccess:
        {
            return {
                ...state,
                eventSentMessages: userMessageEntityAdapter.addAll(action.payload.eventSentMessages, state.eventSentMessages),
            };
        }
        case messageActions.ActionTypes.MessageEffectsLoadEventSentMessagesFailure:
        {
            return { ...state }; // TODO: Implement somewhere to store error
        }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.LoadEventMessagesNewSuccess:
        {
            return {
                ...state,
                eventMessagesNew: action.payload.eventMessagesNew
            };
        }
        case messageActions.ActionTypes.LoadEventMessagesNewFailure:
        {
            return { ...state }; // TODO: Implement somewhere to store error
        }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.MessageEffectsDeleteMessageSuccess:
        {
            return {
                ...state, 
                // Removing from both because not sure if this action was for a sent or received message, but the IdUserMessage should be unique across both
                eventMessages: userMessageEntityAdapter.removeOne(action.payload.idUserMessage, state.eventMessages),
                eventSentMessages: userMessageEntityAdapter.removeOne(action.payload.idUserMessage, state.eventSentMessages),
            };
        }
        case messageActions.ActionTypes.MessageEffectsDeleteMessageFailure:
        {
            return { ...state }; // TODO: Implement somewhere to store error
        }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.MessageEffectsMarkMessageAsReadSuccess:
        {
            const clonedMessage = _cloneDeep(action.payload.message);
            clonedMessage.State = 'Read';
            clonedMessage.CombinedFromDate.State = 'Read';
            clonedMessage.CombinedFromSubject.State = 'Read';
            clonedMessage.JsonMessage.State = 'Read';

            const clonedMessageUpdate: Update<UserMessageDTO> = {
                id: action.payload.message.IdUserMessage.toString(),
                changes: clonedMessage,
            };

            return {
                ...state,
                // Updating both because not sure if this action was for a sent or received message, but the IdUserMessage should be unique across both
                eventMessages: userMessageEntityAdapter.updateOne(clonedMessageUpdate, state.eventMessages),
                eventSentMessages: userMessageEntityAdapter.updateOne(clonedMessageUpdate, state.eventSentMessages),
            };
        }
        case messageActions.ActionTypes.MessageEffectsMarkMessageAsReadFailure:
        {
            return { ...state }; // TODO: Implement somewhere to store error
        }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.AuthenticationServiceLogout:
        {
            return {
                ...initialState,
            };
        }
        default:
        {
            return state;
        }
    }
}