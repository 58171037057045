import { ActionsState } from "../models/actions.state";
import * as ActionActions from '../actions/actions.action';


export type State = ActionsState;

export const initialState: State = {
    actions: [],
    eventId: null,
};

export function reducer(state: State = initialState, action: ActionActions.Actions): State {
    switch (action.type) {
        case ActionActions.ActionTypes.ActionsComponentLoadActions:
        {
            return {
                ...state,
                eventId: action.payload.eventId,
            };
        }
        case ActionActions.ActionTypes.ActionsEffectLoadActionsSuccess:
        {
            return {
                ...state,
                actions: action.payload.userActions,
            };
        }
        case ActionActions.ActionTypes.ActionsEffectLoadActionsFailure:
        {
            return {
                ...state, // TODO: Implement somewhere to store error
            };
        }
        case ActionActions.ActionTypes.AuthenticationServiceLogout:
        {
            return {
                ...initialState,
            };
        }
        default:
        {
            return state;
        }
    }
}
