import { Action } from "@ngrx/store";
import { EventItem } from "../models/eventItem";

export const LOAD_EVENT_ITEMS = '[Event] Load Event Items';
export const LOAD_EVENT_ITEMS_OK = '[Event] Load Event Items Ok';
export const ASSIGN_ATTENDEES_TO_ITEMS_PRE_AUTOSAVE_CHANGE = '[Event] Assign Event Items Pre-Autosave Change';
export const ASSIGN_ATTENDEES_TO_ITEMS = '[Event] Assign Attendees To Items';
export const ASSIGN_ATTENDEES_TO_ITEMS_OK = '[Event] Assign Attendees To Items Ok';
export const ASSIGN_ATTENDEES_TO_ITEMS_ERROR = '[Event] Assign Attendees To Items Error';
export const ASSIGN_ATTENDEES_TO_ITEMS_AUTOSAVE_CANCELLED = '[Event] Assign Attendees To Items Autosave Cancelled';

export const ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY = '[Event] Assign Attendees To Items Automatically';
export const ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_OK = '[Event] Assign Attendees To Items Automatically Ok';
export const ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_ERROR = '[Event] Assign Attendees To Items Automatically Error';

export class LoadEventItems implements Action {
    readonly type = LOAD_EVENT_ITEMS;
    constructor(public eventId: number, public activityId?: number, public optionId?: number) {}
}

export class LoadEventItemsOk implements Action {
    readonly type = LOAD_EVENT_ITEMS_OK;
    constructor(public payload: EventItem[]) {}
}

export class AssignAttendeesToItemsPreAutosaveChange implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_PRE_AUTOSAVE_CHANGE;
    constructor() {}
}

export class AssignAttendeesToItemsAutosaveCancelled implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOSAVE_CANCELLED;
    constructor() {}
}

export class AssignAttendeesToItems implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS;
    constructor(public eventId: number, public eventItems: EventItem[]) {}
}

export class AssignAttendeesToItemsOk implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_OK;
    constructor(public payload: EventItem[]) {}
}

export class AssignAttendeesToItemsError implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_ERROR;
    constructor(public payload: {errorMessage: string}) {} // TODO: figure out what type this error should be! Maybe a string or custom Error object?
}

export class AssignAttendeesToItemsAutomatically implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY;
    constructor(public eventId: number) {}
}

export class AssignAttendeesToItemsAutomaticallyOk implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_OK;
    constructor(public payload: EventItem[]) {}
}

export class AssignAttendeesToItemsAutomaticallyError implements Action {
    readonly type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_ERROR;
    constructor(public payload: {errorMessage: string}) {} // TODO: figure out what type this error should be! Maybe a string or custom Error object?
}

export type Actions 
    = LoadEventItems
    | LoadEventItemsOk
    | AssignAttendeesToItems
    | AssignAttendeesToItemsOk
    | AssignAttendeesToItemsError
    | AssignAttendeesToItemsPreAutosaveChange
    | AssignAttendeesToItemsAutosaveCancelled
    | AssignAttendeesToItemsAutomatically
    | AssignAttendeesToItemsAutomaticallyOk
    | AssignAttendeesToItemsAutomaticallyError
;