import { Action } from '@ngrx/store';
import { Event, EventWithEmail } from '../models/event'
import { Profile } from '../models/profile';
import { TransferredEvent } from '../models/transferred-event';
import { EventOverview } from '../models/eventOverview';

export const LOAD_OWNED_EVENTS = '[Event] Load Owned Events';
export const LOAD_OWNED_EVENTS_OK = '[Event] Load Owned Events Ok';
export const LOAD_TRANSFERRED_EVENTS = '[Event] Load Transferred Events';
export const LOAD_TRANSFERRED_EVENTS_OK = '[Event] Load Transferred Events Ok';
export const LOAD_INVITED_EVENTS = '[Event] Load Invited Events';
export const LOAD_INVITED_EVENTS_OK = '[Event] Load Invited Events Ok';
export const LOAD_SELECTED_EVENT = '[Event] Load Single Event';
export const LOAD_SELECTED_EVENT_OK = '[Event] Load Single Event Ok';
export const UPDATE_EVENT = '[Event] Update Event';
export const UPDATE_EVENT_OK = '[Event] Update Event Ok';
export const LOAD_ALTERNATE_LEADERS = '[Event] Load Alternate Leaders';
export const LOAD_ALTERNATE_LEADERS_OK = '[Event] Load Alternate Leaders Ok';
export const ADD_ALTERNATE_LEADER = '[Event] Add Alternate Leader';
export const ADD_ALTERNATE_LEADER_OK = '[Event] Add Alternate Leader Ok';
export const SET_ALTERNATE_LEADER_STATUS = '[Event] Set Alternate Leader Status';
export const SET_ALTERNATE_LEADER_STATUS_OK = '[Event] Set Alternate Leader Status Ok';
export const REQUEST_SHORT_URL = '[Event] Request Short Url';
export const CONVERT_TO_TEMPLATE = '[Event] Convert to Template';
export const CONVERT_TO_TEMPLATE_OK = '[Event] Convert to Template Ok';
export const CLONE_EVENT = '[Event] Clone Event';
export const CLONE_EVENT_OK = '[Event] Clone Event Ok';
export const ASSIGN_EVENT = '[Event] Assign Event';
export const CLONE_AND_ASSIGN_EVENT = '[Event] Clone and Assign Event';
export const ASSIGN_EVENT_OK = '[Event] Assign Event Ok';
export const CLONE_AND_ASSIGN_EVENT_OK = '[Event] Clone and Assign Event Ok';
export const ASSIGN_EVENT_ERROR = '[Event] Assign Event Error';
export const CLEAR_SELECTED_EVENT = '[Event] Clear Selected Event';
export const CLEAR_SELECTED_EVENT_DATA = '[Event] Clear Selected Event Data';
export const LOAD_EVENT_OVERVIEW = '[Event] Load Event Overview';
export const LOAD_EVENT_OVERVIEW_OK = '[Event] Load Event Overview Ok';
export const AUTHENTICATION_SERVICE_LOGOUT = '[Authentication Service] Logout';

export class LoadOwnedEvents implements Action {
  readonly type = LOAD_OWNED_EVENTS;
  constructor() {}
}

export class LoadOwnedEventsOk implements Action {
  readonly type = LOAD_OWNED_EVENTS_OK;
  constructor(public payload: Event[]) {}
}

export class LoadTransferredEvents implements Action {
  readonly type = LOAD_TRANSFERRED_EVENTS;
  constructor() {}
}

export class LoadTransferredEventsOk implements Action {
  readonly type = LOAD_TRANSFERRED_EVENTS_OK;
  constructor(public payload: TransferredEvent[]) {}
}

export class LoadInvitedEvents implements Action {
  readonly type = LOAD_INVITED_EVENTS;
  constructor() {}
}

export class LoadInvitedEventsOk implements Action {
  readonly type = LOAD_INVITED_EVENTS_OK;
  constructor(public payload: Event[]) {}
}

export class LoadSelectedEvent implements Action {
  readonly type = LOAD_SELECTED_EVENT;
  constructor(public payload: number) {}
}

export class LoadSelectedEventOk implements Action {
  readonly type = LOAD_SELECTED_EVENT_OK;
  constructor(public payload: Event) {}
}
export class UpdateEvent implements Action {
  readonly type = UPDATE_EVENT;
  constructor(public payload: Event) {}
}

export class UpdateEventOk implements Action {
  readonly type = UPDATE_EVENT_OK;
  constructor(public payload: Event) {}
}

export class LoadAlternateLeaders implements Action {
  readonly type = LOAD_ALTERNATE_LEADERS;
  constructor(public payload: number) {}
}

export class LoadAlternateLeadersOk implements Action {
  readonly type = LOAD_ALTERNATE_LEADERS_OK;
  constructor(public payload: Profile[]) {}
}

export class AddAlternateLeader implements Action {
  readonly type = ADD_ALTERNATE_LEADER;
  constructor(public payload: number) {}
}

export class AddAlternateLeaderOk implements Action {
  readonly type = ADD_ALTERNATE_LEADER_OK;
  constructor(public payload: Profile[]) {}
}

export class SetAlternateLeaderStatus implements Action {
  readonly type = SET_ALTERNATE_LEADER_STATUS;
  constructor(public payload: Profile) {}
}

export class SetAlternateLeaderStatusOk implements Action {
  readonly type = SET_ALTERNATE_LEADER_STATUS_OK;
  constructor(public payload: Profile[]) {}
}

export class RequestShortUrl implements Action {
  readonly type = REQUEST_SHORT_URL;
  constructor(public payload: Event) {}
}

export class ConvertToTemplate implements Action {
  readonly type = CONVERT_TO_TEMPLATE;
  constructor(public payload: Event) {}
}

export class ConvertToTemplateOk implements Action {
  readonly type = CONVERT_TO_TEMPLATE_OK;
  constructor(public payload: Event) {}
}

export class CloneEvent implements Action {
  readonly type = CLONE_EVENT;
  constructor(public payload: Event) {}
}

export class CloneEventOk implements Action {
  readonly type = CLONE_EVENT_OK;
  constructor(public payload: Event) {}
}

export class AssignEvent implements Action {
  readonly type = ASSIGN_EVENT;
  constructor(public payload: EventWithEmail) {}
}

export class CloneAndAssignEvent implements Action {
  readonly type = CLONE_AND_ASSIGN_EVENT;
  constructor(public payload: EventWithEmail) {}
}

export class AssignEventOk implements Action {
  readonly type = ASSIGN_EVENT_OK;
  constructor(public payload: Event) {}
}

export class CloneAndAssignEventOk implements Action {
  readonly type = ASSIGN_EVENT_OK;
  constructor(public payload: Event) {}
}

export class AssignEventError implements Action {
  readonly type = ASSIGN_EVENT_ERROR;
  constructor(public payload: {errorMessage: string}) {}
}

export class ClearSelectedEvent implements Action {
  readonly type = CLEAR_SELECTED_EVENT;
  constructor() {}
}

export class ClearSelectedEventData implements Action {
  readonly type = CLEAR_SELECTED_EVENT_DATA;
  constructor() {}
}

export class LoadEventOverview implements Action {
  readonly type = LOAD_EVENT_OVERVIEW;
  constructor(public payload: number) {}
}

export class LoadEventOverviewOk implements Action {
  readonly type = LOAD_EVENT_OVERVIEW_OK;
  constructor(public payload: EventOverview) {}
}

export class AuthenticationServiceLogout implements Action {
  readonly type = AUTHENTICATION_SERVICE_LOGOUT;
  constructor() {}
}

export type Actions
  = LoadOwnedEvents
  | LoadOwnedEventsOk
  | LoadTransferredEvents
  | LoadTransferredEventsOk
  | LoadInvitedEvents
  | LoadInvitedEventsOk
  | LoadSelectedEvent
  | LoadSelectedEventOk
  | LoadAlternateLeaders
  | LoadAlternateLeadersOk
  | AddAlternateLeader
  | AddAlternateLeaderOk
  | SetAlternateLeaderStatus
  | SetAlternateLeaderStatusOk
  | UpdateEvent
  | UpdateEventOk
  | RequestShortUrl
  | ConvertToTemplate
  | ConvertToTemplateOk
  | CloneEvent
  | CloneEventOk
  | AssignEvent
  | AssignEventOk
  | AssignEventError
  | CloneAndAssignEvent
  | CloneAndAssignEventOk
  | ClearSelectedEvent
  | ClearSelectedEventData
  | LoadEventOverview
  | LoadEventOverviewOk
  | AuthenticationServiceLogout
;
