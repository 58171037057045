import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { GTFile } from 'app/store/models/gt-file';

export interface GTResponse<T> {
    Result: boolean;
    Code: number;
    Message: string;
    Payload: T;
}

export enum GTResponseCodes {
    Initial = -1,
    Success = 0,
    BadRequest = 1,
    Unauthorized = 2,
    ServerError = 3,
    TooEarlyToGenerateTickets = 4
}

export class GTResponseError<T> {
    public code: number;
    public message: string;
    private _error: Error;

    constructor(gtResponse: GTResponse<T>) {
        this.code = gtResponse.Code;
        this.message = GTResponseHelper.getErrorMessage(gtResponse);
        this._error = new Error(this.message);
    }
    

    public get name(): string              {return this._error.name;}
    public get stack(): string | undefined {return this._error.stack;}
    public toString(): string              {return this._error.toString();}
}

export interface GTCollectionResponse<T> {
    Result: boolean;
    Code: number;
    Message: string;
    Payload: GTCollectionPayload<T>;
}

export interface GTCollectionPayload<T> {
    Succeeded: T[];
    Failed: T[];
}

export class GTResponseHelper {
    static isSuccessful<T>(gtResponse: GTResponse<T>): boolean {
        return gtResponse.Result;
    }

    static hasError<T>(gtResponse: GTResponse<T>): boolean {
        return !gtResponse || !gtResponse.Result;
    }

    static getPayload<T>(gtResponse: GTResponse<T>): T {
        return gtResponse.Payload;
    }

    static getErrorMessage<T>(gtResponse: GTResponse<T>): string {
        if (!gtResponse) {
            return "Unknown Error";
        } else if (!gtResponse.Message) {
            return "Unknown Error";
        } else {
            return gtResponse.Message;
        }
    }

    static getError<T>(gtResponse: GTResponse<T>): GTResponseError<T> {
        return new GTResponseError<T>(gtResponse);
    }

    static getError2(code: number, message: string): GTResponseError<void> {
        const gtResponse: GTResponse<void> = {
            Result: false,
            Code: code,
            Message: message,
            Payload: null,
        };
        return new GTResponseError<void>(gtResponse);
    }

    // TODO: create response enum to match possible GTResponse.Code values and implement the below function
    // static getErrorType<T>(gtResponse: GTResponse<T>): string {
    //     return 'not yet implemented';
    // }
}

// This is a custom rxjs operator for handling api responses returning a GTResponse object.
// This code below could certainly be shortened, but since this operator is going to be used in so many places,
// I see no benefit to shortening the code.  Being able to maintain this is much more important than code brevity,
// so it is better to leave it in the verbose form.
// The goal of this operator is to reduce code repetition in the service layer by
// forwarding the GTResponse payload for a successful response,
// and unifying all errors so the error's toString() method can be called in the observer.
export const handleGTResponse = <T>() => (source: Observable<GTResponse<T>>) => 
{
    const observable: Observable<T> = new Observable(observer => {
        return source.subscribe({
            next(gtResponse) {
                // This is hit for any GTResponse, successful or error.
                // Identity a failed GTResponse, and send an error to the observer
                if (GTResponseHelper.hasError(gtResponse)) observer.error(GTResponseHelper.getError(gtResponse));
                // For a successful GTResponse, get the payload and send to observer
                else observer.next(GTResponseHelper.getPayload(gtResponse));
            },
            error(err) {
                // This is hit for any unexpected errors with the api
                if (err instanceof HttpErrorResponse) {
                    // HttpErrorResponse happens when there is an unexpected error connecting to the api
                    // For example, during development this error can be simulated by stopping the local api project
                    // The ticky part to HttpErrorResponse is that the error.toString() method returns: "[object Object]" 
                    // which is not very helpful, so I re-wrap it in a new error object with a generic message.
                    // NOTE: the error.name is "HttpErrorResponse" and an example of the error.message is
                    // "Http failure response for https://localhost:44316/api/eim/0/EventItems/UpdateAssignments: 0 Unknown Error"
                    // Just in case this generic error message ever needs to be changed, knowing that ^^^ will save time.
                    observer.error(new Error('The api encountered an unexpected error'));
                    // The benefit of re-wrapping the HttpErrorResponse is that the consuming effect can
                    // use the catchError operator and simply call error.toString() on the error to get the string
                    // to forward onto the appropriate api error action.
                } 
                observer.error(err); // Forward non-HttpErrorResponse errors to observer
            },
            complete() { observer.complete(); }
        });
    })
    return observable;
};

// This is a custom rxjs operator for handling api responses returning a GTCollectionResponse object.
// This code below could certainly be shortened, but since this operator is going to be used in so many places,
// I see no benefit to shortening the code.  Being able to maintain this is much more important than code brevity,
// so it is better to leave it in the verbose form.
// The goal of this operator is to reduce code repetition in the service layer by
// forwarding the GTCollectionResponse payload for a successful response,
// and unifying all errors so the error's toString() method can be called in the observer.
export const handleGTCollectionResponse = <T>() => (source: Observable<GTCollectionResponse<T>>) => 
{
    const observable: Observable<GTCollectionPayload<T>> = new Observable(observer => {
        return source.subscribe({
            next(gtResponse) {
                // This is hit for any GTCollectionResponse, successful or error.
                // Identity a failed GTCollectionResponse, and send an error to the observer
                if (GTResponseHelper.hasError(gtResponse)) observer.error(GTResponseHelper.getError(gtResponse));
                // For a successful GTCollectionResponse, get the payload and send to observer
                else observer.next(GTResponseHelper.getPayload(gtResponse));
            },
            error(err) {
                // This is hit for any unexpected errors with the api
                if (err instanceof HttpErrorResponse) {
                    // HttpErrorResponse happens when there is an unexpected error connecting to the api
                    // For example, during development this error can be simulated by stopping the local api project
                    // The ticky part to HttpErrorResponse is that the error.toString() method returns: "[object Object]" 
                    // which is not very helpful, so I re-wrap it in a new error object with a generic message.
                    // NOTE: the error.name is "HttpErrorResponse" and an example of the error.message is
                    // "Http failure response for https://localhost:44316/api/eim/0/EventItems/UpdateAssignments: 0 Unknown Error"
                    // Just in case this generic error message ever needs to be changed, knowing that ^^^ will save time.
                    observer.error(new Error('The api encountered an unexpected error'));
                    // The benefit of re-wrapping the HttpErrorResponse is that the consuming effect can
                    // use the catchError operator and simply call error.toString() on the error to get the string
                    // to forward onto the appropriate api error action.
                } 
                observer.error(err); // Forward non-HttpErrorResponse errors to observer
            },
            complete() { observer.complete(); }
        });
    })
    return observable;
};


// This is a custom rxjs operator for handling api responses returning a GTCollectionResponse object.
// This code below could certainly be shortened, but since this operator is going to be used in so many places,
// I see no benefit to shortening the code.  Being able to maintain this is much more important than code brevity,
// so it is better to leave it in the verbose form.
// The goal of this operator is to reduce code repetition in the service layer by
// forwarding the GTCollectionResponse payload for a successful response,
// and unifying all errors so the error's toString() method can be called in the observer.
export const handleHttpBlobResponse = (defaultFileName: string = null) => (source: Observable<HttpResponse<Blob>>) => 
{
    const observable: Observable<GTFile> = new Observable(observer => {
        return source.subscribe({
            next(httpResponse) {
                // TODO: detect errors here if any are possible... this is how errors are handled:
                // if (someErrorCondition) observer.error(new Error("The error message"));

                // NOTE: The defaultFileName seems to be able to handle no file extension, so it could be passed
                // either "Tickets" or "Tickets.zip" (assuming the blob is a zip file, and assuming the defaultFileName is used),
                // and both will download a file named "Tickets.zip".  This is because the blob has the information of what filetype it is.

                const gtBlobResultHeader = httpResponse.headers.get("GT-Blob-Result");
                const gtBlobResponseCodeHeader = httpResponse.headers.get("GT-Blob-Response-Code");
                const gtBlobMessageHeader = httpResponse.headers.get("GT-Blob-Message");
                const gtBlobFileNameHeader = httpResponse.headers.get("GT-Blob-File-Name");

                const gtBlobResult = gtBlobResultHeader === "true" ? true : false;
                const gtBlobResponseCode = +gtBlobResponseCodeHeader;

                if (gtBlobResult !== true) {
                    observer.error(GTResponseHelper.getError2(gtBlobResponseCode, gtBlobMessageHeader));
                    return;
                }

                let blobFileName: string;
                if (gtBlobFileNameHeader == null || gtBlobFileNameHeader.trim() === "") {
                    if (defaultFileName == null || defaultFileName.trim() === "") {
                        blobFileName = "file";
                        console.warn("Using fallback file name");
                    } else {
                        blobFileName = defaultFileName;
                        console.warn("Using default file name");
                    }
                } else {
                    blobFileName = gtBlobFileNameHeader;
                }
                const gtFile = {
                    blob: httpResponse.body,
                    fileName: blobFileName,
                } as GTFile;

                observer.next(gtFile);
            },
            error(err) {
                // This is hit for any unexpected errors with the api
                if (err instanceof HttpErrorResponse) {
                    // HttpErrorResponse happens when there is an unexpected error connecting to the api
                    // For example, during development this error can be simulated by stopping the local api project
                    // The ticky part to HttpErrorResponse is that the error.toString() method returns: "[object Object]" 
                    // which is not very helpful, so I re-wrap it in a new error object with a generic message.
                    // NOTE: the error.name is "HttpErrorResponse" and an example of the error.message is
                    // "Http failure response for https://localhost:44316/api/eim/0/EventItems/UpdateAssignments: 0 Unknown Error"
                    // Just in case this generic error message ever needs to be changed, knowing that ^^^ will save time.
                    observer.error(new Error('The api encountered an unexpected error'));
                    // The benefit of re-wrapping the HttpErrorResponse is that the consuming effect can
                    // use the catchError operator and simply call error.toString() on the error to get the string
                    // to forward onto the appropriate api error action.
                } 
                observer.error(err); // Forward non-HttpErrorResponse errors to observer
            },
            complete() { observer.complete(); }
        });
    })
    return observable;
};