import { Action } from '@ngrx/store';
import { User, UnreadMessagesPreferences } from '../models/user';
import { Profile } from '../models/profile';

const PREFIX = '[User]'

export const LOAD_USER_STATE = `${PREFIX} Load User State`;
export const LOAD_USER_STATE_OK = `${PREFIX} Load User State Ok`;
export const SET_LOGGED_IN = `${PREFIX} Set Logged In`;
export const SET_USER_ID = `${PREFIX} Set User ID`;
export const SET_LOGIN_STATUS = `${PREFIX} Set Login Status`;
export const SET_DEFAULT = `${PREFIX} Set Default`;
export const SET_REGISTERED_STATUS = `${PREFIX} Set Registered Status`;
export const SET_VERIFIED_STATUS = `${PREFIX} Set Verified Status`;
export const SET_EMAIL = `${PREFIX} Set Email`;
export const SET_FIRST_NAME = `${PREFIX} Set First Name`;
export const SET_LAST_NAME = `${PREFIX} Set Last Name`;
export const SET_PHONE_NUMBER = `${PREFIX} Set Phone Number`;
export const SET_ADDRESS_STATE = `${PREFIX} Set Address State`;
export const SET_PROFILE_PICTURE = `${PREFIX} Set Profile Picture`;
export const SET_DEFAULT_PROFILE_PICTURE = `${PREFIX} Set Default Profile Picture`;
export const SET_LEADER_STATUS = `${PREFIX} Set Leader Status`;
export const SET_PAYMENT_METHOD = `${PREFIX} Set Payment Method`;
export const SET_ROLES = `${PREFIX} Set Roles`;
export const SET_ALTERNATE_PAYMENT_METHOD = `${PREFIX} Set Alternate Payment Method`;

// refactor actions
export const LOAD_GROUP_LEADER_PROFILE = `${PREFIX} Load Group Leader Profile`;
export const LOAD_GROUP_LEADER_PROFILE_OK = `${PREFIX} Load Group Leader Profile Ok`;
export const SET_UNREAD_EMAIL_PREFERENCE = `${PREFIX} Set Unread Email Preferences`;
export const SET_UNREAD_EMAIL_PREFERENCE_OK = `${PREFIX} Set Unread Email Preferences Ok`;
export const SET_ABOUT = `${PREFIX} Set About`;
export const SAVE_ABOUT = `${PREFIX} Save About`;
export const SAVE_ABOUT_OK = `${PREFIX} Save About Ok`;

// new actions
export const SET_USER_REGISTERED_DATA = `${PREFIX} Set user registered data`

export const LOGOUT = `${PREFIX} Logout`;

export class LoadUserState implements Action {
  readonly type = LOAD_USER_STATE;
  constructor(public payload?: any) {}
}

export class LoadUserStateOk implements Action {
  readonly type = LOAD_USER_STATE_OK;
  constructor(public payload: User) {}
}

export class SetUnreadEmailPreference implements Action {
  readonly type = SET_UNREAD_EMAIL_PREFERENCE;
  constructor(public payload: UnreadMessagesPreferences) {}
}

export class SetUnreadEmailPreferenceOk implements Action {
  readonly type = SET_UNREAD_EMAIL_PREFERENCE_OK;
  constructor(public payload: User) {}
}

export class SetUserRegisteredData implements Action {
  readonly type = SET_USER_REGISTERED_DATA;
  constructor(public payload: any) {}
}

export class SetAbout implements Action {
  readonly type = SET_ABOUT;
  constructor(public payload: string) {}
}

export class SaveAbout implements Action {
  readonly type = SAVE_ABOUT;
  constructor(public payload: User) {}
}

export class SaveAboutOk implements Action {
  readonly type = SAVE_ABOUT_OK;
  constructor(public payload: User) {}
}

export class SetLoggedIn implements Action {
  readonly type = SET_LOGGED_IN;

  constructor(public payload: boolean) {}
}

export class SetUserID implements Action {
  readonly type = SET_USER_ID;

  constructor(public payload: number) {}
}

export class SetLoginStatus implements Action {
  readonly type = SET_LOGIN_STATUS;

  constructor(public payload: string) {}
}

export class SetDefault implements Action {
  readonly type = SET_DEFAULT;

  constructor(public payload?: any) {}
}

export class SetEmail implements Action {
  readonly type = SET_EMAIL;

  constructor(public payload: string) {}
}

export class SetRegisteredStatus implements Action {
  readonly type = SET_REGISTERED_STATUS;

  constructor(public payload: boolean) {}
}

export class SetVerifiedStatus implements Action {
  readonly type = SET_VERIFIED_STATUS;

  constructor(public payload: boolean) {}
}

export class SetFirstName implements Action {
  readonly type = SET_FIRST_NAME;

  constructor(public payload: string) {}
}

export class SetLastName implements Action {
  readonly type = SET_LAST_NAME;

  constructor(public payload: string) {}
}

export class SetPhoneNumber implements Action {
  readonly type = SET_PHONE_NUMBER;

  constructor(public payload: string) {}
}

export class SetAddressState implements Action {
  readonly type = SET_ADDRESS_STATE;

  constructor(public payload: string) {}
}

export class SetProfilePicture implements Action {
  readonly type = SET_PROFILE_PICTURE;

  constructor(public payload: string) {}
}

export class SetDefaultProfilePicture implements Action {
  readonly type = SET_DEFAULT_PROFILE_PICTURE;

  constructor(public payload?: any) {}
}

export class SetLeaderStatus implements Action {
  readonly type = SET_LEADER_STATUS;

  constructor(public payload: boolean) {}
}

export class SetPaymentMethod implements Action {
  readonly type = SET_PAYMENT_METHOD;
  constructor(public payload: {paymentMethod: string}) {}
}

export class SetRoles implements Action {
  readonly type = SET_ROLES;

  constructor(public payload: string[]) {}
}

export class SetAlternatePaymentMethod implements Action {
  readonly type = SET_ALTERNATE_PAYMENT_METHOD;

  constructor(public payload: string) {}
}

export class LoadGroupLeaderProfile implements Action {
  readonly type = LOAD_GROUP_LEADER_PROFILE;
  constructor(public payload: number) {}
}

export class LoadGroupLeaderProfileOk implements Action {
  readonly type = LOAD_GROUP_LEADER_PROFILE_OK;
  constructor(public payload: Profile) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;

  constructor(public payload?: any ) {}
}

export type Actions
  = LoadUserState |
  LoadUserStateOk |
  SetUserRegisteredData |
  SetLoggedIn |
  SetUserID |
  SetLoginStatus |
  SetDefault |
  SetEmail |
  SetRegisteredStatus |
  SetVerifiedStatus |
  SetFirstName |
  SetLastName |
  SetPhoneNumber |
  SetAddressState |
  SetProfilePicture |
  SetDefaultProfilePicture |
  SetLeaderStatus |
  SetPaymentMethod |
  SetRoles |
  SetAlternatePaymentMethod |
  LoadGroupLeaderProfile |
  LoadGroupLeaderProfileOk |
  SetUnreadEmailPreference |
  SetUnreadEmailPreferenceOk |
  SetAbout |
  SaveAboutOk |
  Logout;

