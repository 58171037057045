<grouptools-success
  [successHeading]="headerText"
  [type]="type"
>
  <div class="row">
    <div class="col-sm-12 centered">
      {{ bodyText }}
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 centered">

        <!-- Standard Button Area -->
        <div *ngIf="buttons" class="row button-group-row" [ngClass]="{'no-top-padding': !buttons.primary}">
          <div class="col-sm-12">

            <!-- Primary Button -->
            <div *ngIf="buttons.primary" class="row button-row">
              <!-- LARGE MODAL BUTTON -->
              <div *ngIf="size === 'modal-lg'" class="col-sm-6 offset-sm-3 button-col">
                <button class="btn btn-primary gt-modal-button"
                  [disabled]="buttons.primary.disabled"
                  [ngStyle]="{'cursor': buttons.primary.disabled ? 'progress' : 'pointer'}"
                  (click)="buttons.primary.action()">{{buttons.primary.text}}</button>
              </div>
              <!-- SMALL and MEDIUM MODAL BUTTON -->
              <div *ngIf="size !== 'modal-lg'" class="col-sm-12 button-col">
                <button class="btn btn-primary gt-modal-button" 
                  [disabled]="buttons.primary.disabled"
                  [ngStyle]="{'cursor': buttons.primary.disabled ? 'progress' : 'pointer'}"
                  (click)="buttons.primary.action()">{{buttons.primary.text}}</button>
              </div>
            </div>

            <!-- Secondary Button -->
            <div *ngIf="buttons.secondary" class="row button-row">
              <!-- LARGE MODAL BUTTON -->
              <div *ngIf="size === 'modal-lg'" class="col-sm-6 offset-sm-3 button-col">
                <button class="btn btn-secondary gt-modal-button" 
                  [disabled]="buttons.secondary.disabled"
                  [ngStyle]="{'cursor': buttons.secondary.disabled ? 'progress' : 'pointer'}"
                  (click)="buttons.secondary.action()">{{buttons.secondary.text}}</button>
              </div>
              <!-- SMALL and MEDIUM MODAL BUTTON -->
              <div *ngIf="size !== 'modal-lg'" class="col-sm-12 button-col">
                <button class="btn btn-secondary gt-modal-button" 
                  [disabled]="buttons.secondary.disabled"
                  [ngStyle]="{'cursor': buttons.secondary.disabled ? 'progress' : 'pointer'}"
                  (click)="buttons.secondary.action()">{{buttons.secondary.text}}</button>
              </div>
            </div>


          </div>
        </div>
    </div>
  </div>
</grouptools-success>