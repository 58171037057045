import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupToolsNewModalComponent } from './grouptools-new-modal.component';
import { CloseModal } from 'app/store/actions/modal.action';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@ngrx/store";
var GroupToolsNewModalService = /** @class */ (function () {
    function GroupToolsNewModalService(_ngbModalService, _store) {
        var _this = this;
        this._ngbModalService = _ngbModalService;
        this._store = _store;
        this._unsubscribe$ = new Subject();
        this._isOpen$ = this._store.select(fromRoot.getModalIsOpen);
        this._isOpen$.pipe(takeUntil(this._unsubscribe$), withLatestFrom(this._store.select(fromRoot.getModalIsStatic))).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), isOpen = _b[0], isStatic = _b[1];
            _this._isOpen = isOpen;
            _this._isStatic = isStatic;
            _this._isOpen ? _this.open() : _this.close();
        });
    }
    GroupToolsNewModalService.prototype.open = function () {
        var _this = this;
        // If we're always using the store to control modals, we should never 
        // be in a state where two modals are open at the same time. This guarantees
        // that if we somehow try to open a second modals, other open modals will be closed first.
        if (this._ngbModalService.hasOpenModals()) {
            this._ngbModalService.dismissAll();
        }
        // TODO: Find a way to switch between static/non-static through the store.
        //      Currently, because this is an options passed to ng-bootstrap's modal
        //      modal service at the time we open the modal, there's no way to change the
        //      value without closing and re-opening. Look into the global configuration as
        //      an option.
        var ngbModalOptions = {};
        if (this._isStatic) {
            ngbModalOptions.backdrop = 'static';
            ngbModalOptions.keyboard = false;
        }
        // Opening a modal with ng-bootstrap generates a promise.
        // We're dispatching the close modal action here to maintain the state in the store,
        // in case the user closes by clicking on the backdrop.
        this._activeModal = this._ngbModalService.open(GroupToolsNewModalComponent, ngbModalOptions);
        this._activeModal.result.then(function () {
            if (_this._isOpen) {
                _this._activeModal = null;
                _this._store.dispatch(new CloseModal());
            }
        }, function () {
            if (_this._isOpen) {
                _this._activeModal = null;
                _this._store.dispatch(new CloseModal());
            }
        });
    };
    GroupToolsNewModalService.prototype.close = function () {
        if (!!this._activeModal) {
            this._activeModal.close();
            this._activeModal = null;
        }
    };
    GroupToolsNewModalService.prototype.ngOnDestroy = function () {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    };
    GroupToolsNewModalService.ngInjectableDef = i0.defineInjectable({ factory: function GroupToolsNewModalService_Factory() { return new GroupToolsNewModalService(i0.inject(i1.NgbModal), i0.inject(i2.Store)); }, token: GroupToolsNewModalService, providedIn: "root" });
    return GroupToolsNewModalService;
}());
export { GroupToolsNewModalService };
