import { attendeeAdapter, AttendeesState } from "../models/attendees.state";
import * as AttendeesActions from "../actions/attendees.action";
import { Attendee, updateOrCreateAttendeeWithAttendeeUpsert } from "../models/attendee";
import { DataState } from "../models/apiData";
import { Update } from "@ngrx/entity";
import { cloneDeep as _cloneDeep } from "lodash";

export type State = AttendeesState;

export const initialState: AttendeesState = {
    attendees: attendeeAdapter.getInitialState({
        isInFlight: false,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    leaderSummary: {
        inviteCount: 0,
        attendeeCount: 0,
        tentativeCount: 0,
        declineCount: 0,
    
        attendeePaymentsReceived: 0,
        attendeePaymentsDue: 0,
        attendeePaymentsBalance: 0,
        supplierPaymentsSent: 0,
        supplierPaymentsDue: 0,
        supplierPaymentsBalance: 0,
        supplierPaymentDueDate: new Date(),
    
        date: new Date(),
        time: new Date()
    },
}

export function reducer(state: State = initialState, action: AttendeesActions.Actions): State {
    switch (action.type) {
        case AttendeesActions.ActionTypes.GeneralLoadAttendees:
        case AttendeesActions.ActionTypes.GroupMembersTabLoadAttendees:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: true,
                    // dataState: // intentionally not set (don't want to overwrite the Inital later on)
                    errorMessage: null,
                },
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectLoadAttendeesSuccess:
        {
            return {
                ...state,
                attendees:{
                    ...attendeeAdapter.addAll(action.payload.attendees, state.attendees),
                    isInFlight: false,
                    dataState: DataState.Success,
                    errorMessage: null,
                },
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectLoadAttendeesFailure:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: false,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                },
            };
        }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.GroupMemberTabSaveAllAttendees:
        case AttendeesActions.ActionTypes.GroupMemberTabAddNewAttendees:
        case AttendeesActions.ActionTypes.PublicEventAddNewAttendee:
        case AttendeesActions.ActionTypes.ImportContactsToEventSaveAttendees:
        case AttendeesActions.ActionTypes.PreviousAttendeesSaveAttendees:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: true,
                    // dataState: // intentionally not set (don't want to overwrite the Inital later on)
                    errorMessage: null,
                },
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectSaveAttendeesSuccess:
        {
            // TODO: This whole block of code is needed simply because ngrx entity v7.x does NOT support partial updates,
            //       When updating ngrx to a version that does, this block of code can be eliminated (but test extra props from AttendeeUpsertRequest don't get included in object)
            const newAttendeesToUpsert: Attendee[] = [];
            for (const attendeeUpsert of action.payload.attendeeCollectionPayload.Succeeded) {
                // try to find matching attendee
                const matchingAttendee = state.attendees.entities[attendeeUpsert.eventAttendeeId];
                const copiedMatchingAttendee = _cloneDeep(matchingAttendee); // Kinda hacky, but meh
                if (!!copiedMatchingAttendee) { // Found matching attendee, update
                    newAttendeesToUpsert.push(updateOrCreateAttendeeWithAttendeeUpsert(copiedMatchingAttendee, attendeeUpsert));
                } else { // Did not find matching attendee, create new
                    newAttendeesToUpsert.push(updateOrCreateAttendeeWithAttendeeUpsert(null, attendeeUpsert));
                }
            }

            // Calculate the new attendee totals to update the leaderSummary state
            var existingAttendees = state.attendees.entities;
            var newAttendeeIds = newAttendeesToUpsert.map(a => a.eventAttendeeId).filter(i => existingAttendees[i] == undefined);
            
            // The new totals are equal to the old totals plus any new attendees in the respective statuses
            var inviteCount = state.leaderSummary.inviteCount + newAttendeeIds.length;

            // TODO: Handle updates to these counts if statuses are ever manipulated manually
            ///var acceptedCount = state.leaderSummary.attendeeCount + newAttendeesToUpsert.filter(a => newAttendeeIds.includes(a.eventAttendeeId) && a.status == 'accept').length;
            ///var tentativeCount = state.leaderSummary.tentativeCount + newAttendeesToUpsert.filter(a => newAttendeeIds.includes(a.eventAttendeeId) && a.status == 'tentative').length;
            ///var declineCount = state.leaderSummary.declineCount + newAttendeesToUpsert.filter(a => newAttendeeIds.includes(a.eventAttendeeId) && a.status == 'decline').length;

            // TODO: Handle action.payload.attendeeCollectionPayload.Failed entries ...

            return {
                ...state,
                attendees: {
                    ...attendeeAdapter.upsertMany(newAttendeesToUpsert, state.attendees),
                    isInFlight: false,
                    dataState: DataState.Success,
                    errorMessage: null,
                },
                leaderSummary: {
                    ...state.leaderSummary,
                    inviteCount: inviteCount,
                    ///attendeeCount: acceptedCount,
                    ///tentativeCount: tentativeCount,
                    ///declineCount: declineCount 
                }
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectSaveAttendeesFailure:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: false,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage
                    // action.payload.failedAttendees // TODO: What to do about this?
                }
            };
        }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.AttendeeEffectUpdateAttendeeStatusSuccess:
        {
            // TODO: Update attendees status from response ~or~ be lazy and redirect this action to GeneralLoadAttendees in AttendeesEffect
            return {
                ...state,
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectUpdateAttendeeStatusFailure:
        {
            return {
                ...state,
                // TODO: Implement somewhere to store error
            };
        }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.ChangeAttendeeEmailsComponentChangeAttendeeEmails:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: true,
                    // dataState: // intentionally not set (don't want to overwrite the Inital later on)
                    errorMessage: null,
                },
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess:
        {
            const attendeesUpdates: Update<Attendee>[] = action.payload.attendees.map(attendee => {
                return {
                    id: attendee.eventAttendeeId,
                    changes: {
                        ...attendee,
                    },
                };
            })
            
            return {
                ...state,
                attendees: {
                    ...attendeeAdapter.updateMany(attendeesUpdates, state.attendees),
                    isInFlight: false,
                    dataState: DataState.Success,
                    errorMessage: null,
                }
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsFailure:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: false,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage
                }
            };
        }
        // ===============================================================================
        //                            Invite Attendees
        // ===============================================================================
        case AttendeesActions.ActionTypes.GroupMemberTab_InviteEventAttendees: {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: true,
                    // errorMessage: null, // do we really need to update errorMessage and dataState in request action?
                }
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffect_InviteAttendeesSuccess: {
            // For each id in succeeded, find the matching attendee in the state, change its status to 'invited' and replace them in the state
            const updates = action.payload.attendeeIds.Succeeded.map(id => {
                return { 
                    id: id,
                    changes: {
                        status: 'invited'
                    }
                } as Update<Attendee>; // } as Update<AttendeeUpsertRequest>;
            });
            
            return {
                ...state,
                attendees: {
                    ...attendeeAdapter.updateMany(updates, state.attendees),
                    isInFlight: false,
                    dataState: DataState.Success,
                    errorMessage: null
                }
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffect_InviteAttendeesFailure: {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    isInFlight: false,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage
                }
            };
        }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.AttendeeEffectLoadLeaderSummarySuccess:
        {
            return {
                ...state,
                leaderSummary: action.payload.leaderSummary,
            };
        }
        case AttendeesActions.ActionTypes.AttendeeEffectLoadLeaderSummaryFailure:
        {
            return {
                ...state,
                // TODO: Implement somewhere to store error
            };
        }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.EventEffectClearAttendees:
        {
            return {
                ...initialState,
            };
        }
        //---------------------------------------------------------------------
        default:
        {
            return state;
        }
    }
}
