
import {map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { URLConfig } from '../helpers/config.service';
import { Injectable } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/authentication.service';

export class EmailVerification {
  Result: boolean;
  id: number;
  name: string;
}
export class Token {

  id: number;
  token: string;
}


export class Person {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  RepeatPassword: string;

  constructor(email = "", firstname = "", lastname = "", password = "", repeatpassword = "") {
    this.Email = email;
    this.FirstName = firstname;
    this.LastName = lastname;
    this.Password = password;
    this.RepeatPassword = password;

  }
}

class tokengetter {

  Username: string;
  Password: string;
  grant_type: string;

}

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient,
              private URLs: URLConfig,
              private _authService: AuthService ) { }


  getEmailVerification(email): Observable<any> {
    return this.http
      .post<any>(
        this.URLs._emailurl() + email,
        JSON.stringify(email)
      )
  }

  insertPerson(person): Observable<any> {

    if (person !== undefined) {
      if (typeof person === 'string') {
        person = JSON.parse(person);
      }

      person.APIKey = '515ECrdayo53r4C+VYSDGIC/Z0i8HE7O+dzU5xsKblk=';
      this._authService.setTrackingDetails(person);
    }

    return this.http.post<any>(
      this.URLs._contactRegisterUrl(), person).pipe(
      map((res) => {
        if (res.success) {
          localStorage.setItem('auth_token', res.auth_token);
          let loggedIn = true;
        }

        return res.Obj;
      }));
  }

  public handleError(error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    //console.error(errMsg);
    return observableThrowError(errMsg);
  }

  sendVerifyEmail(): Observable<any> {
    return this.http.get<any>(
      this.URLs._sendVerifyEmail()).pipe(
      map((res) => {
        // if (res.success) {
        //   console.log(res);
        // }

        return res.Obj;
      }));
  }

  sendVerifyCode(): Observable<any> {
    return this.http.get<any>(
      this.URLs._sendVerifyCode()).pipe(
      map((res) => {
        // if (res.Result) {
        //   console.log(res);
        // }

        return res;
      }));
  }

  ValidateVerificationCode(verifyCode): Observable<any> {
    return this.http.post(this.URLs._validateVerificationCode(), verifyCode).pipe(
    map((res) => {
      return res;
    }));
  }

  GetVerificationCodeExpiration(): Observable<any> {
    return this.http.get<any>(
      this.URLs._getVerificationCodeExpiration()).pipe(
      map((res) => {
        return res;
      }));
  }
}
