export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["GLEDLoadEventSurveys"] = "[GLED Component] Load Event Surveys";
    ActionTypes["AttendeePackageDetailsComponentLoadEventSurveys"] = "[Attendee Package Details Component] Load Event Surveys";
    ActionTypes["AttendeeSurveyViewComponentLoadEventSurveys"] = "[Attendee Survey View Componenet] Load Event Surveys";
    ActionTypes["PackageDetailsEffectLoadEventSurveys"] = "[Package Details Effect] Load Event Surveys";
    ActionTypes["SurveyListComponentLoadEventSurveys"] = "[Survey List Component] Load Event Surveys";
    ActionTypes["SurveysEffectLoadEventSurveysSuccess"] = "[Surveys Effect] Load Event Surveys";
    ActionTypes["SurveysEffectLoadEventSurveysFailure"] = "[Surveys Effect] Load Event Failure";
    // TODO: Audit the behavior of the below two actions, I think they are triggered in an ngOnChanges when the route param eventId changes, a bit convoluted
    ActionTypes["AttendeeSurveyViewSetSurveysEventId"] = "[Attendee Survey View Component] Set Surveys EventId";
    ActionTypes["PackageDetailsEffectSetSurveysEventId"] = "[Package Details Effect] Set Surveys EventId";
    ActionTypes["AttendeePackageDetailsComponentLoadSurveyDetails"] = "[Attendee Package Details Component] Load Survey Details";
    ActionTypes["ApdViewComponentLoadSurveyDetails"] = "[APD View Component] Load Survey Details";
    ActionTypes["AttendeeSurveyListComponentLoadSurveyDetails"] = "[Attendee Survey List Component] Load Survey Details";
    ActionTypes["SurveyComponentLoadSurveyDetails"] = "[Survey Component] Load Survey Details";
    ActionTypes["SurveysEffectLoadSurveyDetailsSuccess"] = "[Surveys Effect] Load Survey Details Success";
    ActionTypes["SurveysEffectLoadSurveyDetailsFailure"] = "[Surveys Effect] Load Survey Details Failure";
    ActionTypes["DashboardComponentLoadCreatedSurveys"] = "[Dashboard Component] Load Created Surveys";
    ActionTypes["GroupLeaderDashboardComponentLoadCreatedSurveys"] = "[Group Leader Dashboard Component] Load Created Surveys";
    ActionTypes["SurveyDashboardComponentLoadCreatedSurveys"] = "[Survey Dashboard Component] Load Created Surveys";
    ActionTypes["SurveysEffectLoadCreatedSurveysSuccess"] = "[Surveys Effect] Load Created Surveys Success";
    ActionTypes["SurveysEffectLoadCreatedSurveysFailure"] = "[Surveys Effect] Load Created Surveys Failure";
    ActionTypes["DashboardComponentLoadInvitedSurveys"] = "[Dashboard Component] Load Invited Surveys";
    ActionTypes["SurveyAttendeeComponentLoadInvitedSurveys"] = "[Survey Attendee Component] Load Invited Surveys";
    ActionTypes["SurveysEffectLoadInvitedSurveysSuccess"] = "[Surveys Effect] Load Invited Surveys Success";
    ActionTypes["SurveysEffectLoadInvitedSurveysFailure"] = "[Surveys Effect] Load Invited Surveys Failure";
    ActionTypes["AuthenticationServiceLogout"] = "[Authentication Service] Logout";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var GLEDLoadEventSurveys = /** @class */ (function () {
    function GLEDLoadEventSurveys(payload) {
        this.payload = payload;
        this.type = ActionTypes.GLEDLoadEventSurveys;
    }
    return GLEDLoadEventSurveys;
}());
export { GLEDLoadEventSurveys };
var AttendeePackageDetailsComponentLoadEventSurveys = /** @class */ (function () {
    function AttendeePackageDetailsComponentLoadEventSurveys(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeePackageDetailsComponentLoadEventSurveys;
    }
    return AttendeePackageDetailsComponentLoadEventSurveys;
}());
export { AttendeePackageDetailsComponentLoadEventSurveys };
var AttendeeSurveyViewComponentLoadEventSurveys = /** @class */ (function () {
    function AttendeeSurveyViewComponentLoadEventSurveys(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeSurveyViewComponentLoadEventSurveys;
    }
    return AttendeeSurveyViewComponentLoadEventSurveys;
}());
export { AttendeeSurveyViewComponentLoadEventSurveys };
var PackageDetailsEffectLoadEventSurveys = /** @class */ (function () {
    function PackageDetailsEffectLoadEventSurveys(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsEffectLoadEventSurveys;
    }
    return PackageDetailsEffectLoadEventSurveys;
}());
export { PackageDetailsEffectLoadEventSurveys };
var SurveyListComponentLoadEventSurveys = /** @class */ (function () {
    function SurveyListComponentLoadEventSurveys(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveyListComponentLoadEventSurveys;
    }
    return SurveyListComponentLoadEventSurveys;
}());
export { SurveyListComponentLoadEventSurveys };
var SurveysEffectLoadEventSurveysSuccess = /** @class */ (function () {
    function SurveysEffectLoadEventSurveysSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadEventSurveysSuccess;
    }
    return SurveysEffectLoadEventSurveysSuccess;
}());
export { SurveysEffectLoadEventSurveysSuccess };
var SurveysEffectLoadEventSurveysFailure = /** @class */ (function () {
    function SurveysEffectLoadEventSurveysFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadEventSurveysFailure;
    }
    return SurveysEffectLoadEventSurveysFailure;
}());
export { SurveysEffectLoadEventSurveysFailure };
//----------------------------------------------------------------
var AttendeeSurveyViewSetSurveysEventId = /** @class */ (function () {
    function AttendeeSurveyViewSetSurveysEventId(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeSurveyViewSetSurveysEventId;
    }
    return AttendeeSurveyViewSetSurveysEventId;
}());
export { AttendeeSurveyViewSetSurveysEventId };
var PackageDetailsEffectSetSurveysEventId = /** @class */ (function () {
    function PackageDetailsEffectSetSurveysEventId(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsEffectSetSurveysEventId;
    }
    return PackageDetailsEffectSetSurveysEventId;
}());
export { PackageDetailsEffectSetSurveysEventId };
//----------------------------------------------------------------
var AttendeePackageDetailsComponentLoadSurveyDetails = /** @class */ (function () {
    function AttendeePackageDetailsComponentLoadSurveyDetails(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeePackageDetailsComponentLoadSurveyDetails;
    }
    return AttendeePackageDetailsComponentLoadSurveyDetails;
}());
export { AttendeePackageDetailsComponentLoadSurveyDetails };
var ApdViewComponentLoadSurveyDetails = /** @class */ (function () {
    function ApdViewComponentLoadSurveyDetails(payload) {
        this.payload = payload;
        this.type = ActionTypes.ApdViewComponentLoadSurveyDetails;
    }
    return ApdViewComponentLoadSurveyDetails;
}());
export { ApdViewComponentLoadSurveyDetails };
var AttendeeSurveyListComponentLoadSurveyDetails = /** @class */ (function () {
    function AttendeeSurveyListComponentLoadSurveyDetails(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeSurveyListComponentLoadSurveyDetails;
    }
    return AttendeeSurveyListComponentLoadSurveyDetails;
}());
export { AttendeeSurveyListComponentLoadSurveyDetails };
var SurveyComponentLoadSurveyDetails = /** @class */ (function () {
    function SurveyComponentLoadSurveyDetails(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveyComponentLoadSurveyDetails;
    }
    return SurveyComponentLoadSurveyDetails;
}());
export { SurveyComponentLoadSurveyDetails };
var SurveysEffectLoadSurveyDetailsSuccess = /** @class */ (function () {
    function SurveysEffectLoadSurveyDetailsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadSurveyDetailsSuccess;
    }
    return SurveysEffectLoadSurveyDetailsSuccess;
}());
export { SurveysEffectLoadSurveyDetailsSuccess };
var SurveysEffectLoadSurveyDetailsFailure = /** @class */ (function () {
    function SurveysEffectLoadSurveyDetailsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadSurveyDetailsFailure;
    }
    return SurveysEffectLoadSurveyDetailsFailure;
}());
export { SurveysEffectLoadSurveyDetailsFailure };
//----------------------------------------------------------------
var DashboardComponentLoadCreatedSurveys = /** @class */ (function () {
    function DashboardComponentLoadCreatedSurveys() {
        this.type = ActionTypes.DashboardComponentLoadCreatedSurveys;
    }
    return DashboardComponentLoadCreatedSurveys;
}());
export { DashboardComponentLoadCreatedSurveys };
var GroupLeaderDashboardComponentLoadCreatedSurveys = /** @class */ (function () {
    function GroupLeaderDashboardComponentLoadCreatedSurveys() {
        this.type = ActionTypes.GroupLeaderDashboardComponentLoadCreatedSurveys;
    }
    return GroupLeaderDashboardComponentLoadCreatedSurveys;
}());
export { GroupLeaderDashboardComponentLoadCreatedSurveys };
var SurveyDashboardComponentLoadCreatedSurveys = /** @class */ (function () {
    function SurveyDashboardComponentLoadCreatedSurveys() {
        this.type = ActionTypes.SurveyDashboardComponentLoadCreatedSurveys;
    }
    return SurveyDashboardComponentLoadCreatedSurveys;
}());
export { SurveyDashboardComponentLoadCreatedSurveys };
var SurveysEffectLoadCreatedSurveysSuccess = /** @class */ (function () {
    function SurveysEffectLoadCreatedSurveysSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadCreatedSurveysSuccess;
    }
    return SurveysEffectLoadCreatedSurveysSuccess;
}());
export { SurveysEffectLoadCreatedSurveysSuccess };
var SurveysEffectLoadCreatedSurveysFailure = /** @class */ (function () {
    function SurveysEffectLoadCreatedSurveysFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadCreatedSurveysFailure;
    }
    return SurveysEffectLoadCreatedSurveysFailure;
}());
export { SurveysEffectLoadCreatedSurveysFailure };
//----------------------------------------------------------------
var DashboardComponentLoadInvitedSurveys = /** @class */ (function () {
    function DashboardComponentLoadInvitedSurveys() {
        this.type = ActionTypes.DashboardComponentLoadInvitedSurveys;
    }
    return DashboardComponentLoadInvitedSurveys;
}());
export { DashboardComponentLoadInvitedSurveys };
var SurveyAttendeeComponentLoadInvitedSurveys = /** @class */ (function () {
    function SurveyAttendeeComponentLoadInvitedSurveys() {
        this.type = ActionTypes.SurveyAttendeeComponentLoadInvitedSurveys;
    }
    return SurveyAttendeeComponentLoadInvitedSurveys;
}());
export { SurveyAttendeeComponentLoadInvitedSurveys };
var SurveysEffectLoadInvitedSurveysSuccess = /** @class */ (function () {
    function SurveysEffectLoadInvitedSurveysSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadInvitedSurveysSuccess;
    }
    return SurveysEffectLoadInvitedSurveysSuccess;
}());
export { SurveysEffectLoadInvitedSurveysSuccess };
var SurveysEffectLoadInvitedSurveysFailure = /** @class */ (function () {
    function SurveysEffectLoadInvitedSurveysFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.SurveysEffectLoadInvitedSurveysFailure;
    }
    return SurveysEffectLoadInvitedSurveysFailure;
}());
export { SurveysEffectLoadInvitedSurveysFailure };
//----------------------------------------------------------------
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = ActionTypes.AuthenticationServiceLogout;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
