import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortTimeString' })
export class ShortTimeStringPipe implements PipeTransform {
    transform(ts) {
        if (Boolean(ts) !== true) { return ts; }
        let hrColon = ts.indexOf(':');
        let minColon = ts.lastIndexOf(':');
        let hours = ts.slice(0, hrColon);
        let minutes = ts.slice(hrColon + 1, minColon);
        let seconds = ts.slice(minColon);
        let append = 'AM';
        if (hours == 12)
            append = 'PM';
        if (hours > 12) {
            hours = hours - 12;
            append = 'PM';
        }
        if (seconds >= 30) {
            minutes++;
        }
        let timeString = `${hours}:${minutes} ${append}`;
        return timeString;
    }
}