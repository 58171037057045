import { OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Validators, FormBuilder } from '@angular/forms';
import { customDateValidator } from 'app/grouptools-theme/validators/customDate.validator';
import { minDateValidator } from 'app/grouptools-theme/validators/minDate.validator';
import { maxDateValidator } from 'app/grouptools-theme/validators/maxDate.validator';
import { NbgDatePickerUtility } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDatePickerUtility.service';
import { Subject, Observable } from 'rxjs';
import { ActivityType } from 'app/pages/package-details/package-details.service';
import { minimumValueValidator } from 'app/grouptools-theme/validators/minimumValue.validator';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as modalActions from 'app/store/actions/modal.action';
import * as activityActions from 'app/store/actions/activity.action';
import { ModalSize } from 'app/store/models/modal';
var GledAddActivityShowComponent = /** @class */ (function () {
    function GledAddActivityShowComponent(fb, datePickerUtility, store) {
        this.fb = fb;
        this.datePickerUtility = datePickerUtility;
        this.store = store;
        this.steps = {
            stepOne: {
                clickable: false,
                expand: true,
                check: false
            },
            stepTwo: {
                clickable: false,
                expand: true,
                check: false
            },
            stepThree: {
                clickable: false,
                expand: true,
                check: false
            }
        };
        this.unsubscribe$ = new Subject();
        this.formSubmitted = false;
        // saveConfirm: boolean = false;
        this.AdvancedMode = false;
        this.isEdit = false;
        this.isFirstFeature = false;
        // @Input() disableSave_FeatureAddOn: boolean = false;
        // @Input() disableSave_FeatureTickets: boolean = false;
        this.invitesSent = false;
        this.isRequiredDisabled = true;
        // @Input() attendeeCount: number = 0;
        this.multiOptions = false;
        this.initAddShowForm = {
            // --------------- <feature-add-on> -----------------------
            isRequired: ['Yes'],
            ComponentName: [null, Validators.required],
            // tslint:disable-next-line:max-line-length
            ComponentOccurDate: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            // ComponentEndDate: [null],
            ComponentOccurTime: [''],
            // ComponentEndTime: [''],
            ComponentDesc: [null, Validators.required],
            // RSVPDueDate: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            VenueName: [null, Validators.required],
            ComponentVenueLocation: [null, Validators.required],
            // --------------- <feature-tickets> ----------------------
            // GetTickets: [''],
            SeatLocation: [''],
            // LodgingSeatLocation: [''],
            // TranSeatLocation: [''],
            SupplierCapacity: ['', minimumValueValidator(1)],
            SupplyPayDate: [null, [customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            ComponentCapacity: ['', [minimumValueValidator(1)]],
            ComponentCost: ['', minimumValueValidator(0)],
            TotalCost: [{
                    value: 0,
                    disabled: true
                }],
            ComponentPrice: ['', [minimumValueValidator(0)]],
            DepositAmount: ['', [minimumValueValidator(0)]],
            DepositDueDate: [null, [customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            // FinalPaymentDue: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            FinalPaymentDue: [null, []],
        };
        // // This logic has been moved into the template
        // public getComponentNamePlaceholder(){
        // public getComponentDescLabel(){
        // public getComponentDescPlaceholder(){
        // -------------------------------------------------------------------------------------------
        // Form Validation Config
        // -------------------------------------------------------------------------------------------
        // tslint:disable-next-line:member-ordering
        this.addShowFormErrors = {
            // --------------- <feature-add-on> -----------------------
            'isRequired': '',
            'ComponentName': '',
            'ComponentOccurDate': '',
            // 'ComponentEndDate': '',
            // 'RSVPDueDate': '', // TODO: RSVPDueDate should be commented in for show, but it is not displayed with not first feature, so this validation should be disabled in that case.
            'ComponentDesc': '',
            'VenueName': '',
            'ComponentVenueLocation': '',
            // --------------- <feature-tickets> ----------------------
            // 'TicketOccurDate': '',
            'SupplyPayDate': '',
            'DepositDueDate': '',
            'FinalPaymentDue': '',
            'ComponentCapacity': '',
            'ComponentCost': '',
            'ComponentPrice': '',
            // 'LodgingSeatLocation': '',
            // 'TranSeatLocation': '',
            'SupplierCapacity': '',
            'DepositAmount': ''
        };
        // tslint:disable-next-line:member-ordering
        // public ComponentCapacityMessages = {
        //     'tooSmall': 'Quantity must be 1 or greater',
        //     'required': 'If you enter a price, you must enter a quantity',
        //     'fullRequired': 'Quantity is required.'
        // };
        // tslint:disable-next-line:member-ordering
        this.ComponentCapacitySeatsMessages = {
            'tooSmall': 'Seats must be 1 or greater',
            'required': 'If you enter a price, you must enter a seat quantity',
            'fullRequired': 'Seats is required.'
        };
        // tslint:disable-next-line:member-ordering
        // public ComponentPriceMessages = {
        //     'tooSmall': 'Price must be 0 or greater',
        //     'required': 'If you enter a quantity, you must enter a price',
        //     'fullRequired': 'Price is required.'
        // };
        // tslint:disable-next-line:member-ordering
        this.ComponentPriceSeatsMessages = {
            'tooSmall': 'Price must be 0 or greater',
            'required': 'If you enter a seat quantity, you must enter a price',
            'fullRequired': 'Price is required.'
        };
        // tslint:disable-next-line:member-ordering
        this.addShowFormValidationMessages = {
            // --------------- <feature-add-on> -----------------------
            'isRequired': {
                'required': 'Please select one.'
            },
            'ComponentName': {
                'required': 'Name is required.'
            },
            'VenueName': {
                'required': 'Location is required.'
            },
            'ComponentVenueLocation': {
                'required': 'Address is required.'
            },
            'ComponentOccurDate': {
                'required': 'Date is required.',
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            // 'ComponentEndDate': { // not used for shows
            //   'beforeToday': 'Date must be in the future, greater than today',
            //   'dateTooLate': 'Date cannot be that far in the future',
            //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            // },
            // 'RSVPDueDate': { // TODO: RSVPDueDate should be commented in for show, but it is not displayed with not first feature, so this validation should be disabled in that case.
            //   'required': 'RSVP Due Date is required.',
            //   'beforeToday': 'Date must be in the future, greater than today',
            //   'dateTooLate': 'Date cannot be that far in the future',
            //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            // },
            'ComponentDesc': {
                'required': 'Description is required.'
            },
            // --------------- <feature-tickets> ----------------------
            // 'TicketOccurDate': {
            //   'beforeToday': 'Date must be in the future, greater than today',
            //   'dateTooLate': 'Date cannot be that far in the future',
            //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            // },
            'SupplyPayDate': {
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'DepositDueDate': {
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'FinalPaymentDue': {
                'required': 'Final Payment Due Date is required',
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'ComponentCapacity': this.ComponentCapacitySeatsMessages,
            'ComponentPrice': this.ComponentPriceSeatsMessages,
            // 'LodgingSeatLocation': {
            //   'required': 'Room Type is required'
            // },
            // 'TranSeatLocation': {
            //   'required': 'Pickup / Drop Off Location is required'
            // },
            'SupplierCapacity': {
                'tooSmall': 'This field accepts positive numbers only'
            },
            'ComponentCost': {
                'tooSmall': 'Cost must be 0 or greater'
            },
            'DepositAmount': {
                'tooSmall': 'Deposit must be 0 or greater'
            }
        };
        // @Input() isFirstFeature: boolean = true;
        this.changePhoto = new EventEmitter();
        this.changeEventPhoto = new EventEmitter();
        this.photoType = 'an Activity Photo';
    }
    GledAddActivityShowComponent.prototype.generateNewComponent = function () {
        var option = {
            // identifiers
            id: 0,
            activityId: 0,
            eventId: this.event.id,
            // Not a property: OptionName: this.radioModel === 'lodging' ? featureTickets.LodgingSeatLocation.value : (this.radioModel === 'transportation' ? featureTickets.TranSeatLocation.value : featureTickets.SeatLocation.value),
            // info
            description: this.form.controls.SeatLocation.value,
            attendeeCost: this.form.controls.ComponentCost.value,
            attendeePrice: this.form.controls.ComponentPrice.value,
            quantity: this.form.controls.ComponentCapacity.value,
            optionType: 'Optional',
            optionGroup: null,
            lineItemId: '0',
            status: null,
            availability: null,
            committed: null,
        };
        var activity = {
            // identifiers
            id: 0,
            eventId: this.event.id,
            catalogItemId: null,
            // where to put this.form.controls.RSVPDueDate.value ? this.form.controls.RSVPDueDate.value : null
            // where to put this.form.controls.TotalCost.value // which is object like this-> { value: number, disabled: boolean }
            // basic info
            name: this.form.controls.ComponentName.value,
            description: null,
            shortDescription: this.form.controls.ComponentDesc.value,
            imageUrl: null,
            image: null,
            imageType: null,
            customProperties: null,
            category: ActivityType.Default,
            occurDate: this.form.controls.ComponentOccurDate.value ? this.form.controls.ComponentOccurDate.value : null,
            occurTime: this.form.controls.ComponentOccurTime.value,
            endDate: null,
            endTime: null,
            duration: null,
            durationDescription: null,
            venueName: this.form.controls.VenueName.value,
            venueAddress: this.form.controls.ComponentVenueLocation.value,
            inventoryOption: null,
            requirementOption: this.form.controls.isRequired.value === 'Yes' ? 'Required' : 'Optional',
            reservations: null,
            reservationPayments: null,
            currencyType: null,
            displayOrder: 0,
            status: null,
            // show specific
            seatDescription: null,
            seatingAreaDescription: null,
            genre: null,
            audience: null,
            // restaurant specific
            menuPdf: null,
            menuOptionsDescription: null,
            specialMenuDescription: null,
            // attendee requirements
            attendeePayByDate: this.form.controls.FinalPaymentDue.value ? this.form.controls.FinalPaymentDue.value : null,
            requiredDepositAmount: this.form.controls.DepositAmount.value,
            depositDueDate: this.form.controls.DepositDueDate.value ? this.form.controls.DepositDueDate.value : null,
            // supplier information
            supplierId: null,
            supplierName: null,
            supplierCost: null,
            supplierMinimumQuantity: this.form.controls.SupplierCapacity.value,
            detailDescriptionUrl: null,
            supplierPayByDate: this.form.controls.SupplyPayDate.value ? this.form.controls.SupplyPayDate.value : null,
            supplierInvoiceId: null,
            supplierProductCode: null,
            // options
            options: [option] // Array<Option>
        };
        return activity;
        // // Old implementation from add-features component's generateNewComponent() method
        // const featureAddOn = this.featureAddOnForm.controls;
        // const featureTickets = this.featureTicketsForm.controls;
        // const componentOption = {
        //   IdPackage: this.packageId,
        //   IdPackageComponent: this.isEdit && this.packageFeature.Options.length > 0 ? this.packageFeature.Options[0].IdPackageComponent : 0,
        //   // tslint:disable-next-line:max-line-length
        //   IdPackageComponentOption: this.isEdit && this.packageFeature.Options.length > 0 ? this.packageFeature.Options[0].IdPackageComponentOption : 0,
        //   OptionName: this.radioModel === 'lodging' ? featureTickets.LodgingSeatLocation.value : (this.radioModel === 'transportation' ? featureTickets.TranSeatLocation.value : featureTickets.SeatLocation.value) ,
        //   OptionDescription: featureTickets.SeatLocation.value,
        //   Quantity: featureTickets.ComponentCapacity.value,
        //   AttendeeCost: featureTickets.ComponentCost.value,
        //   AttendeePrice: featureTickets.ComponentPrice.value,
        //   VisibilityStatus: 'Visible',
        //   OptionType: 'Required',
        //   LineItemId: '0',
        //   PriceIncreaseMessage: ''
        // }
        // const component = {
        //   IdPackage: this.packageId,
        //   IdPackageComponent: this.isEdit ? this.packageFeature.IdPackageComponent : 0,
        //   ComponentRequirementOption: featureAddOn.isRequired.value === 'Yes' ? 'Required' : 'Optional',
        //   ComponentName: featureAddOn.ComponentName.value,
        //   ComponentShortDesc: featureAddOn.ComponentDesc.value,
        //   ComponentOccurDate: featureAddOn.ComponentOccurDate.value ? featureAddOn.ComponentOccurDate.value : null,
        //   ComponentOccurTime: featureAddOn.ComponentOccurTime.value,
        //   ComponentEndDate: featureAddOn.ComponentEndDate.value ? featureAddOn.ComponentEndDate.value : null,
        //   ComponentEndTime: featureAddOn.ComponentEndTime.value,
        //   VenueName: featureAddOn.VenueName.value,
        //   ComponentVenueLocation: featureAddOn.ComponentVenueLocation.value,
        //   ComponentSupplierName: featureTickets.GetTickets.value,
        //   ComponentSupplierMinimumQty: featureTickets.SupplierCapacity.value,
        //   ComponentSupplierPayByDate: featureTickets.SupplyPayDate.value,
        //   ComponentCustomFeatureImage: this.featureImage,
        //   ComponentCustomFeatureImageType: this.featureImageType,
        //   RequiredDepositAmount: featureTickets.DepositAmount.value,
        //   DepositDueDate: featureTickets.DepositDueDate.value,
        //   ComponentAttendeePayByDate: featureTickets.FinalPaymentDue.value,
        //   ComponentCategory: this.radioModel,
        //   DisplayOrder: this.displayOrder,
        //   MenuPDF: featureTickets.MenuPDF.value,
        //   Options: [componentOption]
        // };
        // return component;
    };
    // -------------------------------------------------------------------------------------------
    // Angular Life Cycle Methods
    // -------------------------------------------------------------------------------------------
    GledAddActivityShowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group(this.initAddShowForm);
        // this.form.controls
        // this.subscribeToFeatureAddOnForm();
        this.subscribeToFormsOnValueChanged();
        this.event$ = this.store.select(fromRoot.getSelectedEvent);
        this.event$.pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (event) { _this.event = event; });
    };
    GledAddActivityShowComponent.prototype.ngOnChanges = function (changes) {
        // --------------- <feature-add-on> -----------------------
        if (Boolean(changes.isEdit)) {
            if (changes.isEdit.currentValue === false) {
                if (Boolean(changes.invitesSent)) {
                    if (changes.invitesSent.currentValue === true) {
                        this.form.controls.isRequired.disable();
                        if (!this.form.controls.isRequired.value) {
                            this.form.controls.isRequired.setValue('No');
                        }
                    }
                    else {
                        this.form.controls.isRequired.enable();
                    }
                }
            }
            if (changes.isEdit.currentValue === true) {
                if (this.isRequiredDisabled) {
                    if (changes.isRequiredDisabled.currentValue === true) {
                        this.form.controls.isRequired.disable();
                    }
                }
            }
        }
        // --------------- <feature-tickets> ----------------------
        if (Boolean(changes.featureTicketsForm)) {
            // this.subscribeToFormsOnValueChanged(); // TODO: check if this lost features because it used to subscribe/unsubscribe each time ngOnChanges was called
            this.onValueChanged();
        }
    };
    GledAddActivityShowComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    // -------------------------------------------------------------------------------------------
    // Form Validation and Management Methods
    // -------------------------------------------------------------------------------------------
    GledAddActivityShowComponent.prototype.onValueChanged = function (data) {
        if (Boolean(this.form) !== true) {
            return;
        }
        if (this.formSubmitted === true) {
            this.clearAndSetFormErrors(this.addShowFormErrors, this.form, this.addShowFormValidationMessages, false, false);
        }
        else {
            this.clearAndSetFormErrors(this.addShowFormErrors, this.form, this.addShowFormValidationMessages, true, false);
        }
    };
    GledAddActivityShowComponent.prototype.onSubmit = function () {
        this.formSubmitted = true;
        this.clearAndSetFormErrors(this.addShowFormErrors, this.form, this.addShowFormValidationMessages, false, false);
        if (this.form.valid !== true) {
            console.log("form is not valid!");
            console.log(this.addShowFormErrors);
            console.log(this.form);
            return;
        }
        // TODO: send form to be saved (or could use saveConfirm to show confirmation modal)
        var activity = this.generateNewComponent();
        // this.store.dispatch(new activityActions.CreateActivity(activity));
        this.store.dispatch(new activityActions.CreateActivityAndOptions(activity));
        // TODO: catch OK or error actions to show appropriate modal
        this.closeModal();
    };
    // -------------------------------------------------------------------------------------------
    // Miscellaneous Methods
    // -------------------------------------------------------------------------------------------
    GledAddActivityShowComponent.prototype.subscribeToFormsOnValueChanged = function () {
        var _this = this;
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(function (data) {
            // if (Boolean(this.formSubmitted)) { this.onValueChanged(data); } // TODO: this was an alternate version used in feature-add-on form
            return _this.onValueChanged(data); // TODO: this was used in feature-tickets form
        });
        this.onValueChanged();
    };
    GledAddActivityShowComponent.prototype.cancel = function () {
        this.closeModal();
    };
    GledAddActivityShowComponent.prototype.closeModal = function () {
        this.store.dispatch(new modalActions.CloseModal());
    };
    GledAddActivityShowComponent.prototype.handleVenueNameChange = function (address) {
        if (address.name) {
            this.form.controls.VenueName.setValue(address.name);
        }
        if (address.formatted_address) {
            this.form.controls.ComponentVenueLocation.setValue(address.formatted_address);
        }
    };
    GledAddActivityShowComponent.prototype.handleVenueAddressChange = function (address) {
        // --------------- <feature-add-on> -----------------------
        var addressList = address.formatted_address.split(',');
        if (addressList[0] === address.name) {
            this.form.controls.ComponentVenueLocation.setValue(address.formatted_address);
        }
        else {
            this.form.controls.ComponentVenueLocation.setValue(address.name + ', ' + address.formatted_address);
        }
    };
    GledAddActivityShowComponent.prototype.calculateTotalCost = function (event) {
        if (event.currentTarget.attributes.formcontrolname.nodeValue === 'ComponentCost') {
            var capacity = this.form.controls.ComponentCapacity.value;
            if (capacity === '') {
                this.form.controls.TotalCost.setValue(0);
            }
            this.form.controls.TotalCost.setValue(capacity * this.form.controls.ComponentCost.value);
        }
        else {
            var cost = this.form.controls.ComponentCost.value;
            if (cost === '') {
                this.form.controls.TotalCost.setValue(0);
            }
            this.form.controls.TotalCost.setValue(cost * this.form.controls.ComponentCapacity.value);
        }
    };
    // TODO: Put clearAndSetFormErrors somewhere common to all activities
    GledAddActivityShowComponent.prototype.clearAndSetFormErrors = function (formErrors, form, validationMessages, checkIfControlIsDirty, skipFormLevelErrors) {
        // checkIfControlIsDirty is used in onValueChanges but not when formSubmitted is true
        // checkFormLevelErrors is hopefully safe to be used always
        for (var field in formErrors) {
            if (formErrors.hasOwnProperty(field) !== true) {
                continue;
            }
            formErrors[field] = '';
        }
        // check for form-level errors
        if (!form.valid && skipFormLevelErrors !== true) {
            for (var key in form.errors) {
                if (form.errors.hasOwnProperty(key) !== true) {
                    continue;
                }
                var messages = validationMessages[key];
                if (typeof form.errors[key] === 'object') {
                    for (var subKey in form.errors[key]) {
                        if (form.errors[key].hasOwnProperty(subKey) !== true) {
                            continue;
                        }
                        formErrors[key] += messages[subKey] + ' ';
                    }
                }
                else {
                    formErrors[key] += messages[form.errors[key]] + ' ';
                }
            }
        }
        if (formErrors.hasOwnProperty("isRequired") !== true) {
            console.log('fault');
            console.log(formErrors);
            console.log(formErrors.hasOwnProperty("isRequired"));
            console.log('end fault');
        }
        for (var field in formErrors) {
            if (formErrors.hasOwnProperty(field) !== true) {
                console.log("Problem with " + field);
                continue;
            }
            // this.formErrors[field] = '';
            var control = form.get(field);
            if (control && !control.valid && (checkIfControlIsDirty === false || (control.dirty))) {
                var messages = validationMessages[field];
                for (var key in control.errors) {
                    if (control.errors.hasOwnProperty(key) !== true) {
                        continue;
                    }
                    formErrors[field] += messages[key] + ' ';
                    // break; // this was here in the feature-add-on classes onValueChanges method.  I think it is to only show one error message per control at a time.
                    // TODO: add parameter setMultipleErrorsPerControl: boolean and use this to toggle break statement.
                }
            }
        }
    };
    return GledAddActivityShowComponent;
}());
export { GledAddActivityShowComponent };
export var AddActivityShowModalConfig = {
    title: 'Add Show',
    size: ModalSize.Large,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: GledAddActivityShowComponent
    }
};
