/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./grouptools-theme/components/grouptoolsLoader/grouptoolsLoader.component.ngfactory";
import * as i3 from "./grouptools-theme/components/grouptoolsLoader/grouptoolsLoader.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "./grouptools-theme/services/grouptoolsLoader/grouptoolsLoader.service";
import * as i7 from "./app.component";
import * as i8 from "./global.state";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./authentication/authentication.service";
import * as i11 from "ngx-configure";
import * as i12 from "@ng-bootstrap/ng-bootstrap";
import * as i13 from "./widgets/grouptools-modal/grouptools-new-modal.service";
var styles_App = [];
var RenderType_App = i0.ɵcrt({ encapsulation: 2, styles: styles_App, data: {} });
export { RenderType_App as RenderType_App };
export function View_App_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 12, "main", [], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "menu-collapsed": 0 }), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "additional-bg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n      "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "grouptools-loader", [], null, null, null, i2.View_GroupToolsLoaderComponent_0, i2.RenderType_GroupToolsLoaderComponent)), i0.ɵdid(9, 4243456, null, 0, i3.GroupToolsLoaderComponent, [i4.Store], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(12, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isMenuCollapsed); _ck(_v, 2, 0, currVal_0); _ck(_v, 12, 0); }, null); }
export function View_App_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "app", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).beforeUnloadHander($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_App_0, RenderType_App)), i0.ɵprd(512, null, i6.GroupToolsLoaderService, i6.GroupToolsLoaderService, [i4.Store]), i0.ɵdid(2, 4308992, null, 0, i7.App, [i8.GlobalState, i0.ViewContainerRef, i0.Renderer2, i9.DOCUMENT, i10.AuthService, i11.NgxConfigureService, i5.Router, i4.Store, i12.NgbDatepickerConfig, i5.ActivatedRoute, i13.GroupToolsNewModalService, i6.GroupToolsLoaderService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AppNgFactory = i0.ɵccf("app", i7.App, View_App_Host_0, {}, {}, []);
export { AppNgFactory as AppNgFactory };
