export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["PDEffectsLoadEventMessages"] = "[Package Details Effects] Load Event Messages";
    ActionTypes["MessageEffectsLoadEventMessagesSuccess"] = "[Message Effects] Load Event Messages Success";
    ActionTypes["MessageEffectsLoadEventMessagesFailure"] = "[Message Effects] Load Event Messages Failure";
    ActionTypes["PDEffectsLoadEventSentMessages"] = "[Package Details Effects] Load Event Sent Messages";
    ActionTypes["MessageEffectsLoadEventSentMessagesSuccess"] = "[Message Effects] Load Event Sent Messages Success";
    ActionTypes["MessageEffectsLoadEventSentMessagesFailure"] = "[Message Effects] Load Event Sent Messages Failure";
    ActionTypes["LoadEventMessagesNew"] = "[GLED] Load Event Message New";
    ActionTypes["LoadEventMessagesNewSuccess"] = "[Message Effect] Load Event Messages New Success";
    ActionTypes["LoadEventMessagesNewFailure"] = "[Message Effect] Load Event Messages New Failure";
    ActionTypes["MessagesListComponentDeleteMessage"] = "[Messages List Component] Delete Message";
    ActionTypes["MessageEffectsDeleteMessageSuccess"] = "[Message Effects] Delete Message Success";
    ActionTypes["MessageEffectsDeleteMessageFailure"] = "[Message Effects] Delete Message Failure";
    ActionTypes["MessagesListComponentMarkMessageAsRead"] = "[Messages List Component] Mark Message As Read";
    ActionTypes["MessageEffectsMarkMessageAsReadSuccess"] = "[Message Effects] Mark Message As Read Success";
    ActionTypes["MessageEffectsMarkMessageAsReadFailure"] = "[Message Effects] Mark Message As Read Failure";
    ActionTypes["AuthenticationServiceLogout"] = "[Authentication Service] Logout";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var PDEffectsLoadEventMessages = /** @class */ (function () {
    function PDEffectsLoadEventMessages(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadEventMessages;
    }
    return PDEffectsLoadEventMessages;
}());
export { PDEffectsLoadEventMessages };
var MessageEffectsLoadEventMessagesSuccess = /** @class */ (function () {
    function MessageEffectsLoadEventMessagesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsLoadEventMessagesSuccess;
    }
    return MessageEffectsLoadEventMessagesSuccess;
}());
export { MessageEffectsLoadEventMessagesSuccess };
var MessageEffectsLoadEventMessagesFailure = /** @class */ (function () {
    function MessageEffectsLoadEventMessagesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsLoadEventMessagesFailure;
    }
    return MessageEffectsLoadEventMessagesFailure;
}());
export { MessageEffectsLoadEventMessagesFailure };
//----------------------------------------------------------------
var PDEffectsLoadEventSentMessages = /** @class */ (function () {
    function PDEffectsLoadEventSentMessages(payload) {
        this.payload = payload;
        this.type = ActionTypes.PDEffectsLoadEventSentMessages;
    }
    return PDEffectsLoadEventSentMessages;
}());
export { PDEffectsLoadEventSentMessages };
var MessageEffectsLoadEventSentMessagesSuccess = /** @class */ (function () {
    function MessageEffectsLoadEventSentMessagesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsLoadEventSentMessagesSuccess;
    }
    return MessageEffectsLoadEventSentMessagesSuccess;
}());
export { MessageEffectsLoadEventSentMessagesSuccess };
var MessageEffectsLoadEventSentMessagesFailure = /** @class */ (function () {
    function MessageEffectsLoadEventSentMessagesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsLoadEventSentMessagesFailure;
    }
    return MessageEffectsLoadEventSentMessagesFailure;
}());
export { MessageEffectsLoadEventSentMessagesFailure };
//----------------------------------------------------------------
var LoadEventMessagesNew = /** @class */ (function () {
    function LoadEventMessagesNew(payload) {
        this.payload = payload;
        this.type = ActionTypes.LoadEventMessagesNew;
    }
    return LoadEventMessagesNew;
}());
export { LoadEventMessagesNew };
var LoadEventMessagesNewSuccess = /** @class */ (function () {
    function LoadEventMessagesNewSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.LoadEventMessagesNewSuccess;
    }
    return LoadEventMessagesNewSuccess;
}());
export { LoadEventMessagesNewSuccess };
var LoadEventMessagesNewFailure = /** @class */ (function () {
    function LoadEventMessagesNewFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.LoadEventMessagesNewFailure;
    }
    return LoadEventMessagesNewFailure;
}());
export { LoadEventMessagesNewFailure };
//----------------------------------------------------------------
var MessagesListComponentDeleteMessage = /** @class */ (function () {
    function MessagesListComponentDeleteMessage(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessagesListComponentDeleteMessage;
    }
    return MessagesListComponentDeleteMessage;
}());
export { MessagesListComponentDeleteMessage };
var MessageEffectsDeleteMessageSuccess = /** @class */ (function () {
    function MessageEffectsDeleteMessageSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsDeleteMessageSuccess;
    }
    return MessageEffectsDeleteMessageSuccess;
}());
export { MessageEffectsDeleteMessageSuccess };
var MessageEffectsDeleteMessageFailure = /** @class */ (function () {
    function MessageEffectsDeleteMessageFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsDeleteMessageFailure;
    }
    return MessageEffectsDeleteMessageFailure;
}());
export { MessageEffectsDeleteMessageFailure };
//----------------------------------------------------------------
var MessagesListComponentMarkMessageAsRead = /** @class */ (function () {
    function MessagesListComponentMarkMessageAsRead(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessagesListComponentMarkMessageAsRead;
    }
    return MessagesListComponentMarkMessageAsRead;
}());
export { MessagesListComponentMarkMessageAsRead };
var MessageEffectsMarkMessageAsReadSuccess = /** @class */ (function () {
    function MessageEffectsMarkMessageAsReadSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsMarkMessageAsReadSuccess;
    }
    return MessageEffectsMarkMessageAsReadSuccess;
}());
export { MessageEffectsMarkMessageAsReadSuccess };
var MessageEffectsMarkMessageAsReadFailure = /** @class */ (function () {
    function MessageEffectsMarkMessageAsReadFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.MessageEffectsMarkMessageAsReadFailure;
    }
    return MessageEffectsMarkMessageAsReadFailure;
}());
export { MessageEffectsMarkMessageAsReadFailure };
//----------------------------------------------------------------
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = ActionTypes.AuthenticationServiceLogout;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
