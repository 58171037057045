  <div #loaderElement class="container" id="preloader">
    <div class="loader">
      <div class="loader-container">
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-text"></div>
      </div>
    </div>
  </div>