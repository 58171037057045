import { LoginPageComponent } from './login-page.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from 'app/widgets/widgets.module';

import { LoginModule } from '../login/login.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LoginModule,
    WidgetsModule
  ],
  declarations: [
    LoginPageComponent,
    WelcomePageComponent
  ],
  exports: [
    LoginPageComponent,
    WelcomePageComponent
  ]
})
export class LoginPageModule { }
