import * as tslib_1 from "tslib";
import * as SurveyActions from "../actions/surveys.action";
var initialSurveyState = {
    surveyId: 0,
    packageId: 0,
    surveyName: '',
    surveyDesc: '',
    responseSent: false,
    surveyQuestions: [],
    totalInvited: 0,
    totalResponded: 0,
    // createdAt?: '',
    // createdBy?: 0,
    // ownerEmail?: '',
    surveyType: null,
};
export var initialState = {
    createdSurveys: [],
    invitedSurveys: [],
    eventSurveys: [],
    selectedSurvey: initialSurveyState,
    selectedSurveyId: 0,
    packageId: 0,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.SurveysEffectLoadEventSurveysSuccess:
            {
                return tslib_1.__assign({}, state, { eventSurveys: action.payload.eventSurveys });
            }
        case SurveyActions.ActionTypes.SurveysEffectLoadEventSurveysFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.AttendeeSurveyViewSetSurveysEventId:
        case SurveyActions.ActionTypes.PackageDetailsEffectSetSurveysEventId:
            {
                return tslib_1.__assign({}, state, { packageId: action.payload.eventId });
            }
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.AttendeePackageDetailsComponentLoadSurveyDetails:
        case SurveyActions.ActionTypes.ApdViewComponentLoadSurveyDetails:
        case SurveyActions.ActionTypes.AttendeeSurveyListComponentLoadSurveyDetails:
        case SurveyActions.ActionTypes.SurveyComponentLoadSurveyDetails:
            {
                return tslib_1.__assign({}, state, { selectedSurveyId: action.payload.surveyId, selectedSurvey: tslib_1.__assign({}, initialSurveyState) });
            }
        case SurveyActions.ActionTypes.SurveysEffectLoadSurveyDetailsSuccess:
            {
                return tslib_1.__assign({}, state, { selectedSurvey: action.payload.survey });
            }
        case SurveyActions.ActionTypes.SurveysEffectLoadSurveyDetailsFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //----------------------------------------------------------------
        // case SurveyActions.ActionTypes.DashboardComponentLoadCreatedSurveys:
        // case SurveyActions.ActionTypes.GroupLeaderDashboardComponentLoadCreatedSurveys:
        // case SurveyActions.ActionTypes.SurveyDashboardComponentLoadCreatedSurveys:
        // {
        //     return {
        //         ...state,
        //     };
        // }
        case SurveyActions.ActionTypes.SurveysEffectLoadCreatedSurveysSuccess:
            {
                return tslib_1.__assign({}, state, { createdSurveys: action.payload.createdSurveys });
            }
        case SurveyActions.ActionTypes.SurveysEffectLoadCreatedSurveysFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //----------------------------------------------------------------
        // case SurveyActions.ActionTypes.DashboardComponentLoadInvitedSurveys:
        // case SurveyActions.ActionTypes.SurveyAttendeeComponentLoadInvitedSurveys:
        // {
        //     return {
        //         ...state,
        //     };
        // }
        case SurveyActions.ActionTypes.SurveysEffectLoadInvitedSurveysSuccess:
            {
                return tslib_1.__assign({}, state, { invitedSurveys: action.payload.invitedSurveys });
            }
        case SurveyActions.ActionTypes.SurveysEffectLoadInvitedSurveysFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.AuthenticationServiceLogout:
            {
                return tslib_1.__assign({}, initialState);
            }
        //----------------------------------------------------------------
        default:
            {
                return state;
            }
    }
}
