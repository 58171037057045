import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmazonLoginComponent } from './amazon-login.component';
import { AmazonRegisterComponent } from './amazon-register.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [AmazonLoginComponent, AmazonRegisterComponent],
    exports: [AmazonLoginComponent, AmazonRegisterComponent]
})
export class AmazonLoginModule { }