import {Component, ViewChild, Input, Output, EventEmitter, ElementRef, Renderer2} from '@angular/core';
import { UploadInput, UploadOutput, humanizeBytes, UploadFile } from 'ngx-uploader';

@Component({
  selector: 'ba-picture-uploader',
  styleUrls: ['./baPictureUploader.scss'],
  templateUrl: './baPictureUploader.html',
})
export class BaPictureUploader {
  @Input() picture:string = '';
  @Input() uploaderOptions: UploadInput = { type: 'uploadAll', url: '', method: 'POST'};
  @Input() canDelete:boolean = true;
  @Output() onUpload = new EventEmitter<any>();
  @Output() onUploadCompleted = new EventEmitter<any>();
  @Output() remove = new EventEmitter<null>();
  @Output() updateSavePicDisabled = new EventEmitter<null>();
  @Output() closeChangePhoto = new EventEmitter<null>();
  @Output() getChangedPhoto = new EventEmitter<any>();
  @Output() fileChangeEvent = new EventEmitter<any>();

  @ViewChild('fileUpload') public _fileUpload:ElementRef;

  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  uploadInProgress = false;

  constructor(private elementRef: ElementRef) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }
  
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      //preview image
      this.previewImagem(output.file.nativeFile).then(response => {
        this.files.push(output.file);
        this.getChangedPhoto.emit(response.toString());
        this.updateSavePicDisabled.emit();
        this.picture = response.toString();
      });
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      this.uploadInProgress = true;
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      this.uploadInProgress = false;
      this._onUploadCompleted(output);
      this.closeChangePhoto.emit();
    }
  }

  previewImagem(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
        fileReader.readAsDataURL(file);
        fileReader.onload = function (e: any) {
            resolve(e.target.result);
        }
    });
}

  uploadPicture(){
    const event: UploadInput = this.uploaderOptions;
    event.file = this.files[this.files.length-1];
    this.uploadInput.emit(event);
  }

  removePicture():boolean {
    this.picture = '';
    this.remove.emit();
    return false;
  }

  _onUpload(data):void {
    if (data['done'] || data['abort'] || data['error']) {
      this._onUploadCompleted(data);
    } else {
      this.onUpload.emit(data);
    }
  }

  _onUploadCompleted(data):void {
    this.onUploadCompleted.emit(data);
  }

  _canUploadOnServer(): boolean {
    return !!this.uploaderOptions['url'];
  }
}
