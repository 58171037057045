
import { catchError, map } from 'rxjs/operators';

import { throwError as observableThrowError,  Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { URLConfig } from 'app/pages/helpers/config.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GettingStartedService {
    public eventForm: FormGroup;
    public enticingImg = require('assets/img/enticing_image.png');

    constructor(
        private _http: HttpClient,
        private _urls: URLConfig,
        private store: Store<fromRoot.State>,
        private _fb: FormBuilder
    ) {
        this.eventForm = this._fb.group(this.basicEventFormFields);
    }

    public basicEventFormFields = {
        IdTenant: [1],
        IdUser: [''],
        PackageName: ['', Validators.required],
        PackageDesc: ['', Validators.required]
    }

    public saveEvent(eventData): Observable<any> {
        return this._http.post<any>(this._urls._packageInsertUrl(), eventData).pipe(
            map(res => {
                return res.Obj;
            })
        );
    }

  public getShowData(): Observable<any> {
        return this._http.get<any>(this._urls._getCatalogShows()).pipe(
            map(res => res.Obj),
            map(res => res.map(show => {
                if(!show.Details.Logo) {
                    show.Details.Logo = this.enticingImg;
                }
                let newShow = show.Details;
                newShow.catalogItemId = show.catalogItemId;
                return newShow;
            })),);
    }

  public addShowComponent(eventId, catalogId): Observable<any> {
        return this._http.post<any>(this._urls._packageInsertComponent(), this.formatShow(eventId, catalogId));
    }

  public addInvoiceShowComponent(componentObj): Observable<any> {
        return this._http.post<any>(this._urls._packageInsertComponent(), componentObj);
    }

  public addComponentOption(optionObj): Observable<any> {
        return this._http.post<any>(this._urls._addComponentOption(), optionObj);
    }

    public addComponentOptionWithDefaults(optionObj) {
        return this._http.post<any>(this._urls._addComponentOptionWithDefaults(), optionObj);
    }

    public getOptionFromInvoiceLineItem(lineItem, packageId, componentId) {
        return {
            IdPackageComponent: componentId,
            IdPackage: packageId,
            OptionName: lineItem.Description,
            OptionDescription: lineItem.Description,
            AttendeeCost: lineItem.BasePrice,
            AttendeePrice: lineItem.AttendeePrice,
            Quantity: lineItem.Quantity,
            LineItemId: lineItem.IdExternalInvoice
        };
    }

    public formatShow(eventId, catalogId) {
        return {
            IdPackage: eventId,
            ComponentSupplier: 1,
            ComponentCategory:'show',
            catalogItemId: catalogId,
        }
    }

    public getInvoiceDetails(invoiceNumber = ''): Observable<any> {
        return this._http.get<any>(this._urls._packageInvoiceDetails(invoiceNumber)).pipe(
            map(res => res.Obj),
            map(res => res.InvoiceLineItems),
            map(res => {
                res.map(item => item.ProductTimeFormatted = new Date(`01-01-01 ${item.ProductTime}`))
                return res;
            }),
            map(lineItems => {
                if (lineItems.length === 1) {
                    lineItems[0].Include = true;
                }
                return lineItems;
            }),
            catchError(this.handleError),)
    }

    public getPotentialPackagesForInvoice(invoiceNumber): Observable<any> {
        return this._http.get<any>(this._urls._getPotentialPackagesForInvoice(invoiceNumber)).pipe(
            map(res => res.Obj));
    }

    public applyInvoiceToEvent(formattedData): Observable<any> {
        return this._http.post<any>(this._urls._applyInvoiceToEvent(), formattedData);
    }

    public handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        //console.error(errMsg);
        return observableThrowError(errMsg);
    }

}
