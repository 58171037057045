import { FormControl, FormGroup } from '@angular/forms';
var QuestionControlService = /** @class */ (function () {
    function QuestionControlService() {
    }
    QuestionControlService.prototype.toFormGroup = function (questions) {
        var group = {};
        questions.forEach(function (question) {
            group[question.questionId] = new FormControl(question.response || '');
        });
        return new FormGroup(group);
    };
    return QuestionControlService;
}());
export { QuestionControlService };
