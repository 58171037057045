import { OnInit } from '@angular/core';
import * as modalActions from 'app/store/actions/modal.action';
import { Store } from '@ngrx/store';
var HotelPlannerModalComponent = /** @class */ (function () {
    function HotelPlannerModalComponent(store) {
        this.store = store;
        this.hotelPlannerLogo = require("assets/img/hotel-planner-orange.png");
    }
    HotelPlannerModalComponent.prototype.ngOnInit = function () {
    };
    HotelPlannerModalComponent.prototype.bookHotelLessRoom = function () {
        this.store.dispatch(new modalActions.ModalButtonPressed('small'));
        this.store.dispatch(new modalActions.CloseModal());
    };
    HotelPlannerModalComponent.prototype.bookHotelMoreRoom = function () {
        this.store.dispatch(new modalActions.ModalButtonPressed('large'));
        this.store.dispatch(new modalActions.CloseModal());
    };
    return HotelPlannerModalComponent;
}());
export { HotelPlannerModalComponent };
