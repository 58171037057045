import { ElementRef } from '@angular/core';
var GrouptoolsPhoneDirective = /** @class */ (function () {
    function GrouptoolsPhoneDirective(element) {
        this.element = element;
        this.allowedCharacters = '0123456789';
    }
    GrouptoolsPhoneDirective.prototype.keyup = function (event) {
        if (!this.allowedCharacters.includes(event.key)
            || this.justDigits.length === 10) {
            // 10 characters is the max length for our phone numbers
            event.preventDefault();
        }
        else {
            this.processInput();
        }
    };
    GrouptoolsPhoneDirective.prototype.keypress = function (event) {
        if (!this.allowedCharacters.includes(event.key)
            || this.justDigits.length === 10) {
            event.preventDefault();
        }
        else {
            this.processInput();
        }
    };
    GrouptoolsPhoneDirective.prototype.input = function () {
        this.processInput();
    };
    GrouptoolsPhoneDirective.prototype.blur = function () {
        this.processInput();
    };
    GrouptoolsPhoneDirective.prototype.processInput = function () {
        var justDigits = '';
        // only grab characters that are in our digit list
        for (var i = 0; i < this.element.nativeElement.value.length; i++) {
            if (this.allowedCharacters.includes(this.element.nativeElement.value[i])) {
                justDigits += this.element.nativeElement.value[i];
            }
        }
        if (this.justDigits === justDigits) {
            return; // don't process if we already did this one
        }
        // add hyphens after 3 and 6 characters
        var processed;
        if (justDigits.length >= 6) {
            processed = justDigits.substring(0, 3) + "-" + justDigits.substring(3, 6) + "-" + justDigits.substring(6, justDigits.length);
        }
        else if (justDigits.length >= 3) {
            processed = justDigits.substring(0, 3) + "-" + justDigits.substring(3, justDigits.length);
        }
        else {
            processed = justDigits;
        }
        // update the field
        this.element.nativeElement.value = processed;
        this.justDigits = justDigits;
        // directive occurs after change detection, so we need to dispatch a new
        // event to re-trigger change detection for form validation
        this.element.nativeElement.dispatchEvent(new Event('input'));
    };
    return GrouptoolsPhoneDirective;
}());
export { GrouptoolsPhoneDirective };
