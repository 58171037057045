// import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';


import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
// import { OrderNumberLoginRequest } from 'app/store/models/requests/order-number-login-request';
// import { ShowInfo } from 'app/store/models/show-info';
import { ApiState } from 'app/store/models/apiData';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { LoginErrorDialogComponent, LoginErrorDialogData, LoginErrorDialogResult } from 'app/dialogs/login-error-dialog/login-error-dialog.component';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CreateEventFromBibOrderRequest } from 'app/store/models/requests/create-event-from-bib-order-request';


// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

// function showTitleValidator(showTitleMap: Map<string, ShowInfo>): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {

//     if (!control.value || typeof control.value !== 'string')
//       return {showTitle: {value: control.value}};

//     const testValue = control.value.toLowerCase().trim();
//     if (!showTitleMap.has(testValue))
//       return {showTitle: {value: testValue}};

//     return null; // null means the control is valid
//   };
// }


@Component({
  selector: 'create-from-bib-order-view',
  templateUrl: './create-from-bib-order-view.component.html',
  styleUrls: ['./create-from-bib-order-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFromBibOrderViewComponent implements OnInit {

  @Input() matFormFieldAppearance: 'standard' | 'outline';
  @Input() isInModal: boolean = true;
  public get shouldPutFormInCard() { return !this.isInModal; }

  ApiState = ApiState; // This is so we can use the enum type in the template

  // _showList: ShowInfo[] = [];
  // showInfoTitleMap: Map<string, ShowInfo>;
  // @Input() set showList(value: ShowInfo[]) {
  //   this._showList = value;

  //   this.showInfoTitleMap = new Map<string, ShowInfo>();
  //   for (const showInfo of this._showList) {
  //     this.showInfoTitleMap.set(showInfo.title.toLowerCase().trim(), showInfo);
  //   }

  //   this.buildForm();
  // }

  // @Input() showListApiState: ApiState;
  // filteredShowList$: Observable<ShowInfo[]>;

  // TODO: Could rename authApiState to bibOrderNumberCreateEventApiState or something simpler like apiState or dialogApiState
  private _authApiState: ApiState = ApiState.Initial;
  get authApiState(): ApiState { return this._authApiState; }
  @Input() set authApiState(value: ApiState) {
    if (this._authApiState === ApiState.InFlight && value === ApiState.Error) {
      // Then the error dialog should show, because an error just happened
      this.showLoginErrorDialog();
    }
    this._authApiState = value;
  }

  // @Output() showSelectedEvent = new EventEmitter<boolean>(); // TODO: solidify which events are needed, and their type
  @Output() dateSelectedEvent = new EventEmitter<boolean>();
  @Output() getStartedEvent = new EventEmitter<CreateEventFromBibOrderRequest>();

  get shouldShowLoader(): boolean {
    // return (this.showListApiState === ApiState.Initial || this.showListApiState === ApiState.InFlight)
    //     || (this.authApiState === ApiState.InFlight);
    return (this.authApiState === ApiState.InFlight);
  }

  get shouldShowLoginForm(): boolean {
    return !this.shouldShowLoader;
  }

  // public supplierImage = require(`assets/img/shubertlogo.png`);
  public supplierImage:any = null;

  form: FormGroup;


  buildForm() {
    this.form = this.fb.group({
      // autocompleteShow: ['', [Validators.required, showTitleValidator(this.showInfoTitleMap)]],
      date: [null, [Validators.required]],
      orderNumber: [null, [Validators.required]],
    });

    // const autocompleteShowControl = this.form.controls['autocompleteShow'];
    // this.filteredShowList$ = autocompleteShowControl.valueChanges.pipe(
    //   startWith(''),
    //   map (value => typeof value === 'string' ? value : value.title),
    //   map(showInfo => this._filterShowAutocomplete(showInfo))
    // );
  }

  // private _filterShowAutocomplete(value: string) {
  //   const filterValue = value.toLowerCase();
  //   return this._showList.filter(showInfo => showInfo.title.toLowerCase().includes(filterValue));
  // }

  get createEventFromBibOrderRequest(): CreateEventFromBibOrderRequest | null {
    // const autocompleteShowTitle = this.form.get('autocompleteShow').value;// as string
    // const showInfo = this.getShowInfoFromTitle(autocompleteShowTitle);
    // if (showInfo === null) return null;
    const date = new Date(this.form.get('date').value);
    const orderNumber = +this.form.get('orderNumber').value;
    return <CreateEventFromBibOrderRequest> {
      // starShowCode: showInfo.id,
      perfDate: date,
      bibOrderNumber: orderNumber,
    }
  }

  // private getShowInfoFromTitle(title: string): ShowInfo | null {
  //   const searchTitle = title.toLowerCase().trim();
  //   if (!this.showInfoTitleMap.has(searchTitle)) return null;
  //   return this.showInfoTitleMap.get(searchTitle);
  // }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  // showSelected(event: MatSelectChange) { 
  //   this.showSelectedEvent.emit(true); 
  // }
  dateSelected(event: MatDatepickerInputEvent<Date>) { 
    this.dateSelectedEvent.emit(true); 
  }

  getStarted() {
    const createEventFromBibOrderRequest = this.createEventFromBibOrderRequest;
    if (this.form.invalid || !createEventFromBibOrderRequest) {
      // this.form.controls['autocompleteShow'].markAsTouched();
      this.form.controls['date'].markAsTouched();
      this.form.controls['orderNumber'].markAsTouched();
      return;
    }
    this.getStartedEvent.emit(createEventFromBibOrderRequest);
  }

  loginFormEnterKeyup() {
    this.getStarted();
  }

  // ===============================================================================
  //                                 Popup Dialog Logic 
  // ===============================================================================

  showLoginErrorDialog(): void {
    // alert("TODO: show login error dialog");
    // const dialogRef: MatDialogRef<LoginErrorDialogComponent, LoginErrorDialogResult> = this.dialog.open(LoginErrorDialogComponent, {
    //   width: '250px',
    //   data: {} as LoginErrorDialogData,
    // });
  }

}
