import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromActivity from '../actions/activity.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { Injectable } from '@angular/core';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { map, switchMap, catchError, tap, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class ActivityEffects {
    @Effect()
    loadActivities = this.actions$
        .pipe(
            ofType<fromActivity.LoadSelectedActivities>(fromActivity.LOAD_SELECTED_ACTIVITIES),
            switchMap(action => this.eventService.getActivities(action.payload)
                        .pipe(
                            map(activities => new fromActivity.LoadSelectedActivitiesOk(activities)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    createActivity = this.actions$
        .pipe(
            ofType<fromActivity.CreateActivity>(fromActivity.CREATE_ACTIVITY),
            exhaustMap(action => this.eventService.createActivity(action.payload)
                        .pipe(
                            map(activity => new fromActivity.CreateActivityOk(activity)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    updateActivity = this.actions$
        .pipe(
            ofType<fromActivity.UpdateActivity>(fromActivity.UPDATE_ACTIVITY),
            exhaustMap(action => this.eventService.updateActivity(action.payload)
                        .pipe(
                            map(activity => new fromActivity.UpdateActivityOk(activity)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    deleteActivity = this.actions$
        .pipe(
            ofType<fromActivity.DeleteActivity>(fromActivity.DELETE_ACTIVITY),
            exhaustMap(action => this.eventService.deleteActivity(action.payload)
                        .pipe(
                            map(activity => new fromActivity.DeleteActivityOk(activity)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    createOption = this.actions$
        .pipe(
            ofType<fromActivity.CreateOption>(fromActivity.CREATE_OPTION),
            exhaustMap(action => this.eventService.createOption(action.payload)
                        .pipe(
                            map(option => new fromActivity.CreateOptionOk(option)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    updateOption = this.actions$
        .pipe(
            ofType<fromActivity.UpdateOption>(fromActivity.UPDATE_OPTION),
            exhaustMap(action => this.eventService.updateOption(action.payload)
                        .pipe(
                            map(option => new fromActivity.UpdateOptionOk(option)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    deleteOption = this.actions$
        .pipe(
            ofType<fromActivity.DeleteOption>(fromActivity.DELETE_OPTION),
            exhaustMap(action => this.eventService.deleteOption(action.payload)
                        .pipe(
                            map(option => new fromActivity.DeleteOptionOk(option)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    createActivityAndOptions = this.actions$
        .pipe(
            ofType<fromActivity.CreateActivityAndOptions>(fromActivity.CREATE_ACTIVITY_AND_OPTIONS),
            exhaustMap(action => this.eventService.createActivityAndOptions(action.payload)
                        .pipe(
                            map(activity => new fromActivity.CreateActivityAndOptionsOk(activity)),
                            // TODO: Test the below catchError gets hit correctly.  this.eventService.createActivityAndOptions
                            // doesn't call the http request directly, it has an upstream observable that does that,
                            // need to be sure the error propogates through all the observables to here.
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    constructor(
        private actions$: Actions,
        private eventService: EventDetailsService
    ) {}
}
