import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UpdateAttendeeStatusComponent } from './update-attendee-status/update-attendee-status.component';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { HotelPlannerModalComponent } from './hotel-planner-modal/hotel-planner-modal.component';
import { ChangeAttendeeEmailsComponent } from './change-attendee-emails/change-attendee-emails.component';
import { GroupToolsSuccessModal } from './grouptools-success-modal/grouptools-success-modal.component';
import { GroupToolsNewModalComponent } from 'app/widgets/grouptools-modal/grouptools-new-modal.component';
import { AddAlternateLeaderComponent } from './add-alternate-leader/add-alternate-leader.component';
import { AssignEventToOtherComponent } from './assign-event-to-other/assign-event-to-other.component';
import { GroupLeaderEventDetailsModule } from 'app/pages/group-leader-event-details/group-leader-event-details.module';
import { GledAddActivityComponent } from 'app/pages/group-leader-event-details/gled-add-activity/gled-add-activity.component';
import { GledAddActivityLodgingComponent } from 'app/pages/group-leader-event-details/gled-add-activity/gled-add-activity-lodging/gled-add-activity-lodging.component';
import { GledAddActivityOtherComponent } from 'app/pages/group-leader-event-details/gled-add-activity/gled-add-activity-other/gled-add-activity-other.component';
import { GledAddActivityRestaurantComponent } from 'app/pages/group-leader-event-details/gled-add-activity/gled-add-activity-restaurant/gled-add-activity-restaurant.component';
import { GledAddActivityShowComponent } from 'app/pages/group-leader-event-details/gled-add-activity/gled-add-activity-show/gled-add-activity-show.component';
import { GledAddActivityTransportationComponent } from 'app/pages/group-leader-event-details/gled-add-activity/gled-add-activity-transportation/gled-add-activity-transportation.component';

@NgModule({
  declarations: [
    UpdateAttendeeStatusComponent,
    HotelPlannerModalComponent,
    ChangeAttendeeEmailsComponent,
    GroupToolsSuccessModal,
    AddAlternateLeaderComponent,
    AssignEventToOtherComponent
  ],
  exports: [
    UpdateAttendeeStatusComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    WidgetsModule,
    GroupLeaderEventDetailsModule
  ],
  entryComponents: [
    UpdateAttendeeStatusComponent,
    HotelPlannerModalComponent,
    ChangeAttendeeEmailsComponent,
    AddAlternateLeaderComponent,
    AssignEventToOtherComponent,
    GroupToolsSuccessModal,
    GroupToolsNewModalComponent,
    GledAddActivityComponent,
    GledAddActivityLodgingComponent,
    GledAddActivityOtherComponent,
    GledAddActivityRestaurantComponent,
    GledAddActivityShowComponent,
    GledAddActivityTransportationComponent,
  ]
})
export class ModalsModule {
}
