import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateNativeAdapter extends NgbDateAdapter<Date> {

  fromModel(date): NgbDateStruct {
    let realDate;
    if (date && date.indexOf('T') !== -1) {
      // this is in the server's format, un-timezone it
      const year = date.substring(0, 4);
      const month = date.substring(5, 7) - 1; // months are 1-indexed...
      const day = date.substring(8, 10);
      realDate = new Date(year, month, day);
    } else {
      // this is in the datepicker's format, leave it be
      const parsedDate = new Date(Date.parse(date));
      realDate = parsedDate;
    }

    return (realDate && realDate.getFullYear) ?
      { year: realDate.getFullYear(), month: realDate.getMonth() + 1, day: realDate.getDate() } 
      : null;
  }

  toModel(date: NgbDateStruct): any {
    return date ? `${date.month}/${date.day}/${date.year}` : null;
  }
}
