import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { URLConfig } from 'app/pages/helpers/config.service';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
var ImportContactsService = /** @class */ (function () {
    function ImportContactsService(http, URLs, store) {
        this.http = http;
        this.URLs = URLs;
        this.store = store;
        this.listOptions = {
            multiSelectMode: true,
            hideHeader: false,
            hideSubHeader: false,
            hideDate: false,
            hideMenu: false,
            hidePagination: false,
            hideHead: false,
            collapsed: false,
            columns: [{
                    label: 'name',
                    fieldName: 'FullName'
                },
                {
                    label: 'Email',
                    fieldName: 'Email'
                },
                {
                    label: 'Phone',
                    fieldName: 'PhoneValue'
                }
            ],
            singleActions: ['Import']
        };
    }
    ImportContactsService.prototype.processCsv = function (data) {
        return this.http.post(this.URLs._processCsv(), data);
    };
    ImportContactsService.prototype.getGoogleContacts = function (contacts, accessToken) {
        var _this = this;
        var options;
        if (accessToken) {
            options = { params: new HttpParams().set('accessToken', accessToken) };
        }
        return this.http.get(this.URLs._getGoogleContacts(), options).pipe(map(function (res) { return _this.formatGoogleData(res, contacts); }), catchError(function (err) {
            //console.error(err);
            return observableThrowError(err);
        }));
    };
    ImportContactsService.prototype.formatGoogleData = function (googleData, contacts) {
        if (googleData.ResponseMessage === 'unavailable') {
            return 'Unavailable';
        }
        var data = googleData.Obj;
        var emailArray = contacts.map(function (contact) { return contact.Email; });
        var formattedGoogleData = data
            .reduce(function (output, connection) {
            output.push({
                FirstName: connection.names ? connection.names[0].givenName : null,
                LastName: connection.names ? connection.names[0].familyName : null,
                FullName: connection.names ? (connection.names[0].givenName ? connection.names[0].givenName : '') + " " + (connection.names[0].familyName ? connection.names[0].familyName : '') : null,
                Email: connection.emailAddresses ? connection.emailAddresses[0].value : null,
                PhoneValue: connection.phoneNumbers ? connection.phoneNumbers[0].value : null,
            });
            return output;
        }, [])
            .filter(function (connection) { return !!(connection.FullName); });
        return formattedGoogleData;
    };
    ImportContactsService.prototype.getFacebookContacts = function () {
        return this.http.get(this.URLs._getFacebookContacts());
    };
    ImportContactsService.prototype.getOutlookContacts = function () {
        return this.http.get(this.URLs._getOutlookContacts());
    };
    ImportContactsService.prototype.formatOutlookData = function (outlookData, contacts) {
        var data = outlookData.value;
        var emailArray = contacts.map(function (contact) { return contact.Email; });
        var formattedOutlookData = data
            .reduce(function (output, connection) {
            output.push({
                FirstName: connection.GivenName ? connection.GivenName : null,
                LastName: connection.Surname ? connection.Surname : null,
                FullName: connection.GivenName ? connection.GivenName + " " + connection.Surname : null,
                Email: connection.EmailAddresses ? connection.EmailAddresses[0].Address : null,
            });
            return output;
        }, [])
            .filter(function (connection) { return !!(connection.FullName); });
        return formattedOutlookData;
    };
    return ImportContactsService;
}());
export { ImportContactsService };
