import { ValidatorFn, AbstractControl } from '@angular/forms';

// tslint:disable-next-line:max-line-length
export const DATE_REGEXP: RegExp = new RegExp(`^[0-3]?[0-9]\/[0-3]?[0-9]\/(?:[0-9]{2})?[0-9]{2}$`);

export function customDateValidator(string: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const isValid = DATE_REGEXP.test(control.value);
    if (isValid) {
      return null;
    } else if (control.value === '') {
      return null;
    } else if (!control.value) {
      return null;
    } else if (control.value.length === 19 && new Date(control.value)) {
      return null;
    } else {
      return  { 'pattern': { value: control.value } };
    }
  };
}
