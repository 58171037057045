import { SurveysState } from "../models/surveys.state";
import * as SurveyActions from "../actions/surveys.action";
import { Survey } from "../models/survey";

export type State = SurveysState;

const initialSurveyState: Survey = {
    surveyId: 0,
        packageId: 0,
        surveyName: '',
        surveyDesc: '',
        responseSent: false,
        surveyQuestions: [],
        totalInvited: 0,
        totalResponded: 0,
        // createdAt?: '',
        // createdBy?: 0,
        // ownerEmail?: '',
        surveyType: null,
        // sendNewSurveyNotification?: false,
        // customActions?: [],
}

export const initialState: State = {
    createdSurveys: [],
    invitedSurveys: [],
    eventSurveys: [],
    selectedSurvey: initialSurveyState,
    selectedSurveyId: 0,
    packageId: 0,
}

export function reducer(state: State = initialState, action: SurveyActions.Actions): State {
    switch (action.type) {
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.SurveysEffectLoadEventSurveysSuccess:
        {
            return {
                ...state,
                eventSurveys: action.payload.eventSurveys,
            };
        }
        case SurveyActions.ActionTypes.SurveysEffectLoadEventSurveysFailure:
        {
            return {
                ...state,
                // TODO: mark an api request error somewhere in the store with action.payload.errorMessage
            };
        }
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.AttendeeSurveyViewSetSurveysEventId:
        case SurveyActions.ActionTypes.PackageDetailsEffectSetSurveysEventId:
        {
            return {
                ...state,
                packageId: action.payload.eventId,
            };
        }
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.AttendeePackageDetailsComponentLoadSurveyDetails:
        case SurveyActions.ActionTypes.ApdViewComponentLoadSurveyDetails:
        case SurveyActions.ActionTypes.AttendeeSurveyListComponentLoadSurveyDetails:
        case SurveyActions.ActionTypes.SurveyComponentLoadSurveyDetails:
        {
            return {
                ...state,
                selectedSurveyId: action.payload.surveyId,
                selectedSurvey: {
                    ...initialSurveyState,
                },
            };
        }
        case SurveyActions.ActionTypes.SurveysEffectLoadSurveyDetailsSuccess:
        {
            return {
                ...state,
                selectedSurvey: action.payload.survey,
            };
        }
        case SurveyActions.ActionTypes.SurveysEffectLoadSurveyDetailsFailure:
        {
            return {
                ...state,
                // TODO: mark an api request error somewhere in the store with action.payload.errorMessage
            };
        }
        //----------------------------------------------------------------
        // case SurveyActions.ActionTypes.DashboardComponentLoadCreatedSurveys:
        // case SurveyActions.ActionTypes.GroupLeaderDashboardComponentLoadCreatedSurveys:
        // case SurveyActions.ActionTypes.SurveyDashboardComponentLoadCreatedSurveys:
        // {
        //     return {
        //         ...state,
        //     };
        // }
        case SurveyActions.ActionTypes.SurveysEffectLoadCreatedSurveysSuccess:
        {
            return {
                ...state,
                createdSurveys: action.payload.createdSurveys,
            };
        }
        case SurveyActions.ActionTypes.SurveysEffectLoadCreatedSurveysFailure:
        {
            return {
                ...state,
                // TODO: mark an api request error somewhere in the store with action.payload.errorMessage
            };
        }
                //----------------------------------------------------------------
        // case SurveyActions.ActionTypes.DashboardComponentLoadInvitedSurveys:
        // case SurveyActions.ActionTypes.SurveyAttendeeComponentLoadInvitedSurveys:
        // {
        //     return {
        //         ...state,
        //     };
        // }
        case SurveyActions.ActionTypes.SurveysEffectLoadInvitedSurveysSuccess:
        {
            return {
                ...state,
                invitedSurveys: action.payload.invitedSurveys,
            };
        }
        case SurveyActions.ActionTypes.SurveysEffectLoadInvitedSurveysFailure:
        {
            return {
                ...state,
                // TODO: mark an api request error somewhere in the store with action.payload.errorMessage
            };
        }
        //----------------------------------------------------------------
        case SurveyActions.ActionTypes.AuthenticationServiceLogout:
        {
            return {
                ...initialState,
            };
        }
        //----------------------------------------------------------------
        default:
        {
            return state;
        }
    }
}