import * as tslib_1 from "tslib";
export function toInteger(value) {
    return parseInt("" + value, 10);
}
export function toString(value) {
    return (value !== undefined && value !== null) ? "" + value : '';
}
export function getValueInRange(value, max, min) {
    if (min === void 0) { min = 0; }
    return Math.max(Math.min(value, max), min);
}
export function isString(value) {
    return typeof value === 'string';
}
export function isNumber(value) {
    return !isNaN(toInteger(value));
}
export function isInteger(value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}
export function isDefined(value) {
    return value !== undefined && value !== null;
}
export function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return '';
    }
}
export function regExpEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
export function shallowCopy(obj) {
    return tslib_1.__assign({}, obj);
}
// Because this utility is used in critical functions, I have made it as error proof as possible 
// It should almost always return an array, even if its an empty array
// One feature is each array overwrites any previous array's elements that match the getId function
export function mergeArrays(getId) {
    var arrays = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        arrays[_i - 1] = arguments[_i];
    }
    var e_1, _a, e_2, _b;
    if (!Array.isArray(arrays))
        return [];
    var map = new Map();
    try {
        for (var arrays_1 = tslib_1.__values(arrays), arrays_1_1 = arrays_1.next(); !arrays_1_1.done; arrays_1_1 = arrays_1.next()) {
            var array = arrays_1_1.value;
            if (!Array.isArray(array))
                continue;
            try {
                for (var array_1 = tslib_1.__values(array), array_1_1 = array_1.next(); !array_1_1.done; array_1_1 = array_1.next()) {
                    var element = array_1_1.value;
                    if (element === null || element === undefined)
                        continue;
                    var possibleNumber = getId(element);
                    if (typeof possibleNumber !== 'number')
                        continue;
                    map.set(possibleNumber, element);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (array_1_1 && !array_1_1.done && (_b = array_1.return)) _b.call(array_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (arrays_1_1 && !arrays_1_1.done && (_a = arrays_1.return)) _a.call(arrays_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return Array.from(map.values());
}
// This returns a string of the name of the given property on the given type T
// yes you have to already pass in the string of the property, so you already have it, why not just use it directly?
// Because the benefit to using this is that it will generate a compile time error if the property string doesn't exist on the given type
// I plan on using this to add compile safe typing for the clOptions input passed to the grouptools-list component
export var nameof = function (name) { return name; };
