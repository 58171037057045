import * as tslib_1 from "tslib";
import { attendeeAdapter } from "../models/attendees.state";
import * as AttendeesActions from "../actions/attendees.action";
import { updateOrCreateAttendeeWithAttendeeUpsert } from "../models/attendee";
import { DataState } from "../models/apiData";
import { cloneDeep as _cloneDeep } from "lodash";
export var initialState = {
    attendees: attendeeAdapter.getInitialState({
        isInFlight: false,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    leaderSummary: {
        inviteCount: 0,
        attendeeCount: 0,
        tentativeCount: 0,
        declineCount: 0,
        attendeePaymentsReceived: 0,
        attendeePaymentsDue: 0,
        attendeePaymentsBalance: 0,
        supplierPaymentsSent: 0,
        supplierPaymentsDue: 0,
        supplierPaymentsBalance: 0,
        supplierPaymentDueDate: new Date(),
        date: new Date(),
        time: new Date()
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var e_1, _a;
    switch (action.type) {
        case AttendeesActions.ActionTypes.GeneralLoadAttendees:
        case AttendeesActions.ActionTypes.GroupMembersTabLoadAttendees:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: true, 
                        // dataState: // intentionally not set (don't want to overwrite the Inital later on)
                        errorMessage: null }) });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectLoadAttendeesSuccess:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, attendeeAdapter.addAll(action.payload.attendees, state.attendees), { isInFlight: false, dataState: DataState.Success, errorMessage: null }) });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectLoadAttendeesFailure:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: false, dataState: DataState.Error, errorMessage: action.payload.errorMessage }) });
            }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.GroupMemberTabSaveAllAttendees:
        case AttendeesActions.ActionTypes.GroupMemberTabAddNewAttendees:
        case AttendeesActions.ActionTypes.PublicEventAddNewAttendee:
        case AttendeesActions.ActionTypes.ImportContactsToEventSaveAttendees:
        case AttendeesActions.ActionTypes.PreviousAttendeesSaveAttendees:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: true, 
                        // dataState: // intentionally not set (don't want to overwrite the Inital later on)
                        errorMessage: null }) });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectSaveAttendeesSuccess:
            {
                // TODO: This whole block of code is needed simply because ngrx entity v7.x does NOT support partial updates,
                //       When updating ngrx to a version that does, this block of code can be eliminated (but test extra props from AttendeeUpsertRequest don't get included in object)
                var newAttendeesToUpsert = [];
                try {
                    for (var _b = tslib_1.__values(action.payload.attendeeCollectionPayload.Succeeded), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var attendeeUpsert = _c.value;
                        // try to find matching attendee
                        var matchingAttendee = state.attendees.entities[attendeeUpsert.eventAttendeeId];
                        var copiedMatchingAttendee = _cloneDeep(matchingAttendee); // Kinda hacky, but meh
                        if (!!copiedMatchingAttendee) { // Found matching attendee, update
                            newAttendeesToUpsert.push(updateOrCreateAttendeeWithAttendeeUpsert(copiedMatchingAttendee, attendeeUpsert));
                        }
                        else { // Did not find matching attendee, create new
                            newAttendeesToUpsert.push(updateOrCreateAttendeeWithAttendeeUpsert(null, attendeeUpsert));
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                // Calculate the new attendee totals to update the leaderSummary state
                var existingAttendees = state.attendees.entities;
                var newAttendeeIds = newAttendeesToUpsert.map(function (a) { return a.eventAttendeeId; }).filter(function (i) { return existingAttendees[i] == undefined; });
                // The new totals are equal to the old totals plus any new attendees in the respective statuses
                var inviteCount = state.leaderSummary.inviteCount + newAttendeeIds.length;
                // TODO: Handle updates to these counts if statuses are ever manipulated manually
                ///var acceptedCount = state.leaderSummary.attendeeCount + newAttendeesToUpsert.filter(a => newAttendeeIds.includes(a.eventAttendeeId) && a.status == 'accept').length;
                ///var tentativeCount = state.leaderSummary.tentativeCount + newAttendeesToUpsert.filter(a => newAttendeeIds.includes(a.eventAttendeeId) && a.status == 'tentative').length;
                ///var declineCount = state.leaderSummary.declineCount + newAttendeesToUpsert.filter(a => newAttendeeIds.includes(a.eventAttendeeId) && a.status == 'decline').length;
                // TODO: Handle action.payload.attendeeCollectionPayload.Failed entries ...
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, attendeeAdapter.upsertMany(newAttendeesToUpsert, state.attendees), { isInFlight: false, dataState: DataState.Success, errorMessage: null }), leaderSummary: tslib_1.__assign({}, state.leaderSummary, { inviteCount: inviteCount }) });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectSaveAttendeesFailure:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: false, dataState: DataState.Error, errorMessage: action.payload.errorMessage }) });
            }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.AttendeeEffectUpdateAttendeeStatusSuccess:
            {
                // TODO: Update attendees status from response ~or~ be lazy and redirect this action to GeneralLoadAttendees in AttendeesEffect
                return tslib_1.__assign({}, state);
            }
        case AttendeesActions.ActionTypes.AttendeeEffectUpdateAttendeeStatusFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.ChangeAttendeeEmailsComponentChangeAttendeeEmails:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: true, 
                        // dataState: // intentionally not set (don't want to overwrite the Inital later on)
                        errorMessage: null }) });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess:
            {
                var attendeesUpdates = action.payload.attendees.map(function (attendee) {
                    return {
                        id: attendee.eventAttendeeId,
                        changes: tslib_1.__assign({}, attendee),
                    };
                });
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, attendeeAdapter.updateMany(attendeesUpdates, state.attendees), { isInFlight: false, dataState: DataState.Success, errorMessage: null }) });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsFailure:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: false, dataState: DataState.Error, errorMessage: action.payload.errorMessage }) });
            }
        // ===============================================================================
        //                            Invite Attendees
        // ===============================================================================
        case AttendeesActions.ActionTypes.GroupMemberTab_InviteEventAttendees: {
            return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: true }) });
        }
        case AttendeesActions.ActionTypes.AttendeeEffect_InviteAttendeesSuccess: {
            // For each id in succeeded, find the matching attendee in the state, change its status to 'invited' and replace them in the state
            var updates = action.payload.attendeeIds.Succeeded.map(function (id) {
                return {
                    id: id,
                    changes: {
                        status: 'invited'
                    }
                }; // } as Update<AttendeeUpsertRequest>;
            });
            return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, attendeeAdapter.updateMany(updates, state.attendees), { isInFlight: false, dataState: DataState.Success, errorMessage: null }) });
        }
        case AttendeesActions.ActionTypes.AttendeeEffect_InviteAttendeesFailure: {
            return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { isInFlight: false, dataState: DataState.Error, errorMessage: action.payload.errorMessage }) });
        }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.AttendeeEffectLoadLeaderSummarySuccess:
            {
                return tslib_1.__assign({}, state, { leaderSummary: action.payload.leaderSummary });
            }
        case AttendeesActions.ActionTypes.AttendeeEffectLoadLeaderSummaryFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //---------------------------------------------------------------------
        case AttendeesActions.ActionTypes.EventEffectClearAttendees:
            {
                return tslib_1.__assign({}, initialState);
            }
        //---------------------------------------------------------------------
        default:
            {
                return state;
            }
    }
}
