export var LOAD_OWNED_FORMS = '[Forms] Load Owned Forms';
export var LOAD_OWNED_FORMS_OK = '[Forms] Load Owned Forms OK';
export var LOAD_EVENT_FORMS = '[Forms] Load Event Forms';
export var LOAD_EVENT_FORMS_OK = '[Forms] Load Event Forms OK';
var LoadOwnedForms = /** @class */ (function () {
    function LoadOwnedForms() {
        this.type = LOAD_OWNED_FORMS;
    }
    return LoadOwnedForms;
}());
export { LoadOwnedForms };
var LoadOwnedFormsOk = /** @class */ (function () {
    function LoadOwnedFormsOk(payload) {
        this.payload = payload;
        this.type = LOAD_OWNED_FORMS_OK;
    }
    return LoadOwnedFormsOk;
}());
export { LoadOwnedFormsOk };
var LoadEventForms = /** @class */ (function () {
    function LoadEventForms(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_FORMS;
    }
    return LoadEventForms;
}());
export { LoadEventForms };
var LoadEventFormsOk = /** @class */ (function () {
    function LoadEventFormsOk(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_FORMS_OK;
    }
    return LoadEventFormsOk;
}());
export { LoadEventFormsOk };
