import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { URLConfig } from '../helpers/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Payment } from 'app/store/models/payment';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { PaymentDTO, SeparatedPaymentsDTO } from 'app/store/models/packages/SeparatedPaymentsDTO';
import { SimpleSupplierDTO } from 'app/store/models/packages/SimpleSupplierDTO';
import { GrouptoolsListComponent, ListOptions } from 'app/widgets/grouptools-list/grouptools-list.component';
import { SupplierMakePaymentResponse } from 'app/store/models/responses/make-payment-response';
import { SupplierMakePaymentRequest } from 'app/store/models/requests/make-payment-request';
import { AddAndAssociateCustomerRequest } from 'app/store/models/requests/add-and-associate-customer-request';
import { AddAndAssociateCustomerResponse } from 'app/store/models/responses/add-and-associate-customer-response';

@Injectable()
export class PaymentsService {

    public attendeePaymentClOptions: ListOptions<PaymentDTO> = {
        hideDate: true,
        multiSelectMode: false,
        hideMenu: false,
        columns: [
            {
                fieldName: 'PaymentDate',
                label: 'Payment Date',
                type: 'date',
                pipe: new DatePipe('en-US'),
                action: 'Distribute'
            },
            {
                fieldName: 'PaymentType',
                label: 'Type',
                mobile:{showInMobile: false},
                action: 'Distribute'
            },
            {
                fieldName: 'PayerName',
                label: 'Attendee',
                mobile:{showInMobile: true, title:'Attendee'},
                action: 'Distribute'
            },
            {
                fieldName: 'Amount',
                label: 'Amount',
                type: 'currency',
                action: 'Distribute'
            },
            {
                fieldName: 'Status',
                label: 'Status',
                mobile:{showInMobile: false},
                action: 'Distribute'
            }
        ],
        singleActions: ['Distribute'],
        headerActions: ['Record Attendee Payment'],
        defaultMessage: {message: 'No payments have been received.'}
    };

    public supplierPaymentClOptions: ListOptions<PaymentDTO> = {
        hideDate: true,
        multiSelectMode: false,
        hideMenu: false,
        columns: [
            {
                fieldName: 'PaymentDate',
                label: 'Payment Date',
                type: 'date',
                pipe: new DatePipe('en-US'),
                action: 'Distribute'
            },
            {
                fieldName: 'PaymentType',
                label: 'Type',
                mobile:{showInMobile: false},
                action: 'Distribute'
            },
            {
                fieldName: 'PayeeName',
                label: 'Supplier',
                action: 'Distribute'
            },
            {
                fieldName: 'Amount',
                label: 'Amount',
                type: 'currency',
                action: 'Distribute'
            },
            {
                fieldName: 'Status',
                label: 'Status',
                mobile:{showInMobile: false},
                action: 'Distribute'
            }
        ],
        singleActions: ['Distribute'],
        headerActions: ['Record Supplier Payment'],
        defaultMessage: {message: 'No supplier payments have been recorded.'}
    };

  public paymentRemindersModal$: Subject<boolean> = new Subject();

    constructor(
        private http: HttpClient,
        private URLs: URLConfig,
    ) {}

    //==================================================================================
    // KC: 2020/05/22: The new pattern is in this section
    public getPaymentsNew(eventId: number): Observable<Payment[]> {
        return this.http.get<GTResponse<Payment[]>>(this.URLs._paymentsGetUrl(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public makeSupplierPayment(makePaymentRequest: SupplierMakePaymentRequest): Observable<SupplierMakePaymentResponse> {
        return this.http.post<GTResponse<SupplierMakePaymentResponse>>(this.URLs._supplierMakePaymentUrl(), makePaymentRequest).pipe(
            handleGTResponse(),
        );
    }

    public addAndAssociateCustomer(addAndAssociateCustomerRequest: AddAndAssociateCustomerRequest): Observable<AddAndAssociateCustomerResponse> {
        return this.http.post<GTResponse<AddAndAssociateCustomerResponse>>(this.URLs._addAndAssociateCustomerUrl(), addAndAssociateCustomerRequest).pipe(
            handleGTResponse(),
        );
    }

    public makeSupplierGLPayment(makePaymentRequest: SupplierMakePaymentRequest): Observable<SupplierMakePaymentResponse> {
        return this.http.post<GTResponse<SupplierMakePaymentResponse>>(this.URLs._supplierMakeGLPaymentUrl(), makePaymentRequest).pipe(
            handleGTResponse(),
        );
    }
    //==================================================================================


    public getPayments(packageId: number): Observable<SeparatedPaymentsDTO> {
        let token = '';
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.get<GTResponse<SeparatedPaymentsDTO>>(this.URLs._paymentsGetUrlOld(packageId), { headers: headers }).pipe(
            handleGTResponse(),
        );
    }

    public getPaymentTypes(): Observable<any> {
        let token = '';
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

        return this.http.get<any>(this.URLs._paymentGetTypes(), { headers: headers }).pipe(
            map(res => res.Obj))
    }

    public refundPayment(payment: any) {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.post<any>(this.URLs._paymentRefund(), payment, { headers: headers });
    }

    public saveAttendeePayment(paymentObject): Observable<any> {
        return this.http.post<any>(this.URLs._insertAttendeePayment(), paymentObject).pipe(
            map(res => {
                //console.log(res);
                return res;
            }));
    }

    public saveSupplierPayment(paymentObject): Observable<any> {
        return this.http.post<any>(this.URLs._insertSupplierPayment(), paymentObject).pipe(
            map(res => {
                //console.log(res);
                return res;
            }));
    }

    public getAttendees(packageId: Number): Observable<any> {
        return this.http.get<any>(this.URLs._getContactsForPackage() + packageId);
    }

    public getSuppliersForPackage(packageId: number): Observable<SimpleSupplierDTO[]> {
        return this.http.get<GTResponse<SimpleSupplierDTO[]>>(this.URLs._getSuppliersForPackage() + packageId).pipe(
            handleGTResponse(),
        );
    }

    public getDistribution(paymentId: number): Observable<any> {
        return this.http.get<any>(this.URLs._paymentDistributionGetUrl() + paymentId);
    }

    public getDistributionsForPackage(packageId: number): Observable<any> {
        return this.http.get<any>(this.URLs._paymentDistributionGetByPackage(packageId)).pipe(
            map(res => res.Obj));
    }

    public insertDistributions(distributions: Array<any>): Observable<any> {
        return this.http.post<any>(this.URLs._paymentDistributionInsertMany(), distributions);
    }

    public autoDistribute(payment: any): Observable<any> {
        return this.http.post<any>(this.URLs._paymentDistributionAutoUrl(), payment);
    }

    public SetAlternatePaymentMethod(paymentMethod: any): Observable<any> {
        return this.http.post<any>(this.URLs._paymentSetAlternatePaymentMethod(), paymentMethod);
    }

    public showSendPaymentReminders() {
        this.paymentRemindersModal$.next(true);
    }
}
