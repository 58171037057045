import { PipeTransform } from '@angular/core';
var BaProfilePicturePipe = /** @class */ (function () {
    function BaProfilePicturePipe() {
    }
    BaProfilePicturePipe.prototype.transform = function (input, ext) {
        if (ext === void 0) { ext = 'png'; }
        return 'assets/img/app/profile/' + input + '.' + ext;
    };
    return BaProfilePicturePipe;
}());
export { BaProfilePicturePipe };
