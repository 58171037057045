// This is from the EventDetailsService in GroupTix, which is located at: 
// import { EventDetailsService } from 'projects/gt-distribution-app/src/app/store/services/event-details.service';
import { URLConfig } from 'app/pages/helpers/config.service';
import { HttpClient } from '@angular/common/http';
import { handleGTCollectionResponse, handleGTResponse, handleHttpBlobResponse } from 'app/store/models/gtResponse';
var GroupTixService = /** @class */ (function () {
    function GroupTixService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
    }
    GroupTixService.prototype.retrieveShowList = function () {
        return this.http.get(this.URLs._getShowList()).pipe(handleGTResponse());
    };
    GroupTixService.prototype.notifyAttendeesTicketsReady = function (eventId, eventItemIds) {
        return this.http.post(this.URLs._notifyAttendeesTicketsReady(eventId), eventItemIds).pipe(handleGTCollectionResponse());
    };
    GroupTixService.prototype.groupLeaderDownloadTickets = function (eventId, eventItemIds, ticketFormat) {
        var requestBody = {
            ticketFormat: ticketFormat,
            eventItemIds: eventItemIds,
            eventId: eventId,
        };
        return this.http.post(this.URLs._downloadTickets(), requestBody, {
            // reportProgress: true,
            observe: 'response',
            responseType: 'blob',
        }).pipe(handleHttpBlobResponse("Tickets"));
    };
    GroupTixService.prototype.groupLeaderDownloadAllTickets = function (eventId, ticketFormat) {
        var requestBody = {
            ticketFormat: ticketFormat,
            eventItemIds: [],
            eventId: eventId,
        };
        return this.http.post(this.URLs._downloadAllTickets(), requestBody, {
            // reportProgress: true,
            observe: 'response',
            responseType: 'blob',
        }).pipe(handleHttpBlobResponse("Tickets"));
    };
    GroupTixService.prototype.groupLeaderDownloadManifest = function (eventId) {
        return this.http.get(this.URLs._downloadManifest(eventId), {
            // reportProgress: true,
            observe: 'response',
            responseType: 'blob',
        }).pipe(handleHttpBlobResponse("Manifest"));
    };
    GroupTixService.prototype.getDistributionAttendees = function (eventId) {
        return this.http.get(this.URLs._getAttendeesEim(eventId)).pipe(handleGTResponse());
    };
    GroupTixService.prototype.getDistributionEventAndActivity = function (eventId) {
        return this.http.get(this.URLs._getEventAndActivity(eventId)).pipe(handleGTResponse());
    };
    GroupTixService.prototype.upsertAttendees = function (attendees, eventId) {
        return this.http.post(this.URLs._upsertAttendees(eventId), attendees).pipe(handleGTCollectionResponse());
    };
    GroupTixService.prototype.publicEventAddAttendees = function (attendee, eventId) {
        return this.http.post(this.URLs._publicEventAddAttendees(eventId), attendee).pipe(handleGTResponse());
    };
    GroupTixService.prototype.deleteAttendee = function (eventId, eventAttendeeId) {
        // This used to be http.delete method, but DELETE requests are blocked by shubert
        return this.http.post(this.URLs._deleteAttendee(eventId, eventAttendeeId), {}).pipe(handleGTResponse());
    };
    GroupTixService.prototype.parseAttendeesFromCsvString = function (csvString, eventId) {
        return this.http.post(this.URLs._parseAttendeesFromCsv(eventId), csvString).pipe(handleGTResponse());
    };
    GroupTixService.prototype.parseAttendeesFromCsvStringInJson = function (csvInJson, eventId) {
        return this.http.post(this.URLs._parseAttendeesFromCsvInJson(eventId), csvInJson).pipe(handleGTResponse());
    };
    GroupTixService.prototype.inviteEventAttendees = function (inviteEventAttendeesRequest) {
        return this.http.post(this.URLs._inviteEventAttendees(), inviteEventAttendeesRequest).pipe(handleGTCollectionResponse());
    };
    return GroupTixService;
}());
export { GroupTixService };
