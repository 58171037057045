import * as modalActions from '../actions/modal.action';
import { Modal } from '../models/modal';

export type State = Modal;

const initialState: State = <Modal>{
    title: '',
    componentWithOptions: null,
    size: null,
    isFullScreenMobile: false,
    isStatic: false,
    isOpen: false,
    displayHeader: true
};

export function reducer(state: State = initialState, action: modalActions.Actions): State {
    switch(action.type) {
        case modalActions.OPEN_MODAL:
        {
            return Object.assign({}, initialState, <Modal>{
                ...action.payload,
                isOpen: true
            });
        }
        case modalActions.CLOSE_MODAL:
        {
            return initialState;
        }
        case modalActions.SET_MODAL_COMPONENT:
        {
            return Object.assign({}, state, <Modal>{
                componentWithOptions: action.payload
            });
        }
        case modalActions.SET_MODAL_SIZE:
        {
            return Object.assign({}, state, <Modal>{
                size: action.payload
            });
        }
        case modalActions.SET_MODAL_TITLE:
        {
            return Object.assign({}, state, <Modal>{
                title: action.payload
            });
        }
        case modalActions.SET_FULL_SCREEN_MOBILE:
        {
            return Object.assign({}, state, <Modal>{
                isFullScreenMobile: action.payload
            });
        }
        default:
        {
            return state;
        }
    }
}

export const getIsOpen = (state: State) => state.isOpen;
export const getModalTitle = (state: State) => state.title;
export const getModalSize = (state: State) => state.size;
export const getIsFullScreenMobile = (state: State) => state.isFullScreenMobile;
export const getModalComponent = (state: State) => state.componentWithOptions;
export const getModalIsStatic = (state: State) => state.isStatic;
export const getModalDisplayHeader = (state: State) => state.displayHeader;