import * as tslib_1 from "tslib";
import * as activityActions from '../actions/activity.action';
// initialize state to an empty array
export function reducer(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case activityActions.LOAD_SELECTED_ACTIVITIES_OK:
            {
                return action.payload;
            }
        case activityActions.CLEAR_SELECTED_ACTIVITIES:
            {
                return [];
            }
        case activityActions.CREATE_ACTIVITY_OK:
            {
                var createdActivity = action.payload;
                var newActivityArray = getNewActivityArray(state);
                newActivityArray.push(createdActivity);
                return newActivityArray;
            }
        case activityActions.UPDATE_ACTIVITY_OK:
            {
                var updatedActivity_1 = action.payload;
                var newActivityArray = getNewActivityArray(state);
                var index = newActivityArray.findIndex(function (activity) { return activity.id === updatedActivity_1.id; });
                if (index >= 0) {
                    newActivityArray[index] = updatedActivity_1;
                }
                else {
                    console.error('eventActivities.reducer: Activity not found when updating activiy', updatedActivity_1, newActivityArray);
                }
                return newActivityArray;
            }
        case activityActions.DELETE_ACTIVITY_OK:
            {
                var deletedActivity_1 = action.payload;
                var newActivityArray = getNewActivityArray(state);
                newActivityArray.filter(function (activity) { return activity.id !== deletedActivity_1.id; });
                return newActivityArray;
            }
        case activityActions.CREATE_OPTION_OK:
            {
                var createdOption_1 = action.payload;
                var newActivityArray = getNewActivityArray(state);
                var index = newActivityArray.findIndex(function (activity) { return activity.id === createdOption_1.activityId; });
                if (index >= 0) {
                    newActivityArray[index].options.push(createdOption_1);
                }
                else {
                    console.error('eventActivities.reducer: Activity not found when creating option', createdOption_1, newActivityArray);
                }
                return newActivityArray;
            }
        case activityActions.UPDATE_OPTION_OK:
            {
                var updatedOption_1 = action.payload;
                var newActivityArray = getNewActivityArray(state);
                var index = newActivityArray.findIndex(function (activity) { return activity.id === updatedOption_1.activityId; });
                if (index >= 0) {
                    var optionsIndex = newActivityArray[index].options.findIndex(function (option) { return option.id === updatedOption_1.id; });
                    if (optionsIndex >= 0) {
                        newActivityArray[index].options[optionsIndex] = updatedOption_1;
                    }
                    else {
                        console.error('eventActivities.reducer: Option not found when updating option', updatedOption_1, newActivityArray);
                    }
                }
                else {
                    console.error('eventActivities.reducer: Activity not found when updating option', updatedOption_1, newActivityArray);
                }
                return newActivityArray;
            }
        case activityActions.DELETE_OPTION_OK:
            {
                var deletedOption_1 = action.payload;
                var newActivityArray = getNewActivityArray(state);
                var index = newActivityArray.findIndex(function (activity) { return activity.id === deletedOption_1.activityId; });
                if (index >= 0) {
                    newActivityArray[index].options.filter(function (option) { return option.id !== deletedOption_1.id; });
                }
                else {
                    console.error('eventActivities.reducer: Activity not found when deleting option', deletedOption_1, newActivityArray);
                }
                return newActivityArray;
            }
        case activityActions.CREATE_ACTIVITY_AND_OPTIONS_OK:
            {
                var createdActivity = action.payload;
                var newActivityArray = getNewActivityArray(state);
                newActivityArray.push(createdActivity);
                return newActivityArray;
            }
        default:
            {
                return state;
            }
    }
}
function getNewActivityArray(state) {
    return tslib_1.__spread(state); // returns a new top level object for ngrx to compare against.  Retains same reference to 2nd level and deeper objects.
}
