
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { URLConfig } from 'app/pages/helpers/config.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EventBuilderService {
    public eventForm: FormGroup;
    public enticingImg = require('assets/img/enticing_image.png');

    constructor(
        private _http: HttpClient,
        private _urls: URLConfig,
        private store: Store<fromRoot.State>,
        private _fb: FormBuilder
    ) {
        this.eventForm = this._fb.group(this.basicEventFormFields);
    }

    public basicEventFormFields = {
        IdTenant: [1],
        IdUser: [''],
        PackageName: ['', Validators.required],
        PackageDesc: ['', Validators.required]
    }

  public saveEvent(eventData): Observable<any> {
        return this._http.post<any>(this._urls._packageInsertUrl(), eventData).pipe(
            map(res => res.Obj));
    }

  public getShowData(): Observable<any> {
        return this._http.get<any>(this._urls._getCatalogShows()).pipe(
            map(res => res.Obj),
            map(res => res.map(show => {
                if (!show.Details.Logo) {
                    show.Details.Logo = this.enticingImg;
                }
                let newShow = show.Details;
                newShow.catalogItemId = show.catalogItemId;
                return newShow;
            })),);
    }

    public getWorkshopData(): Observable<any> {
        return this._http.get<any>(this._urls._getCatalogWorkshops()).pipe(
            map(res => res.Obj),
            map(res => res.map(workshop => {
                if (!workshop.Details.Logo) {
                    workshop.Details.Logo = this.enticingImg;
                }
                return Object.assign({
                    ApiAccessId: workshop.ApiAccessId,
                    ApiItemConfigJson: workshop.ApiItemConfigJson,
                    catalogItemId: workshop.catalogItemId,
                    catalogItemName: workshop.catalogItemName,
                    catalogItemType: workshop.catalogItemType
                }, workshop.Details)
            })),);
    }

  public addShowComponent(eventId, catalogId): Observable<any> {
        return this._http.post<any>(this._urls._packageInsertComponent(), this.formatShow(eventId, catalogId));
    }

  public addInvoiceShowComponent(componentObj): Observable<any> {
        return this._http.post<any>(this._urls._packageInsertComponent(), componentObj);
    }

    public formatShow(eventId, catalogId) {
        return {
            IdPackage: eventId,
            ComponentSupplier: 1,
            ComponentCategory: 'show',
            catalogItemId: catalogId,
        }
    }

    public getInvoiceDetails(invoiceNumber = 1): Observable<any> {
        return this._http.get<any>(this._urls._packageInvoiceDetails(invoiceNumber)).pipe(
            map(res => res.Obj),
            map(res => res.Invoice),
            map(res => {
                let newRes = res;
                newRes.ProductTimeFormatted = new Date(`01-01-01 ${res.ProductTime}`);
                return newRes
            }),
            catchError(this.handleError),)
    }

    public getPotentialPackagesForInvoice(invoiceNumber): Observable<any> {
        return this._http.get<any>(this._urls._getPotentialPackagesForInvoice(invoiceNumber)).pipe(
            map(res => res.Obj));
    }

    public applyInvoiceToEvent(formattedData): Observable<any> {
        return this._http.post<any>(this._urls._applyInvoiceToEvent(), formattedData);
    }

    public handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        //console.error(errMsg);
        return observableThrowError(errMsg);
    }

}
