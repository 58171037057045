import { Store } from '@ngrx/store';
import * as loaderActions from 'app/store/actions/loader.action';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var GroupToolsLoaderService = /** @class */ (function () {
    function GroupToolsLoaderService(store) {
        this.store = store;
    }
    GroupToolsLoaderService.prototype.show = function () {
        this.store.dispatch(new loaderActions.ShowLoader);
    };
    GroupToolsLoaderService.prototype.hide = function () {
        this.store.dispatch(new loaderActions.HideLoader);
    };
    GroupToolsLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function GroupToolsLoaderService_Factory() { return new GroupToolsLoaderService(i0.inject(i1.Store)); }, token: GroupToolsLoaderService, providedIn: "root" });
    return GroupToolsLoaderService;
}());
export { GroupToolsLoaderService };
