import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from 'app/widgets/widgets.module';
// Angular Imports
import { NgModule } from '@angular/core';

import { InviteContactsComponent } from './invite-contacts.component';
import { OnlyUninvitedPipe } from 'app/grouptools-theme/pipes/onlyUnivited.pipe';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { InviteDetailsModule } from 'app/pages/package-invite/invite-details/invite-details.module';
import { SurveyInviteModule } from 'app/pages/survey-invite/survey-invite.module';
import { ExpressInviteModule } from 'app/pages/contacts/invite-contacts/express-invite/express-invite.module';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        FormsModule,
        ReactiveFormsModule,
        GTNgaModule,
        InviteDetailsModule,
        SurveyInviteModule,
        ExpressInviteModule
    ],
    declarations: [
        InviteContactsComponent
    ],
    exports: [
        InviteContactsComponent
    ],
    providers: [
        OnlyUninvitedPipe
    ]
})
export class InviteContactsModule {

}