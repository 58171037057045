import * as tslib_1 from "tslib";
import * as eventActions from '../actions/event.action';
export var initialState = {
    id: 0,
    tenantId: 0,
    name: '',
    description: '',
    plan: '',
    shortDescription: '',
    date: null,
    ownerName: '',
    ownerId: 0,
    ownerIds: '',
    tags: [],
    types: [],
    status: '',
    shortUrl: '',
    options: {},
    photoUrl: ''
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case eventActions.LOAD_SELECTED_EVENT_OK: {
            return action.payload;
        }
        case eventActions.UPDATE_EVENT_OK: {
            // make sure we don't over-write event photo
            return tslib_1.__assign({}, state, action.payload);
        }
        case eventActions.CLEAR_SELECTED_EVENT: {
            return tslib_1.__assign({}, initialState);
        }
        default: {
            return state;
        }
    }
}
