import { Injectable } from '@angular/core';
import { Event, EventWithEmail } from 'app/store/models/event'
import { URLConfig } from '../pages/helpers/config.service';
import { Observable, concat, forkJoin } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { ConfigurationDTO } from 'app/store/models/configuration/ConfigurationDTO';
import { GetConfigurationRequest } from 'app/store/models/requests/get-configuration-request';


@Injectable()
export class ConfigurationService {

    public constructor(
        private http: HttpClient,
        private URLs: URLConfig
    ) { }

    public getConfiguration(getConfigurationRequest: GetConfigurationRequest): Observable<ConfigurationDTO> {
        return this.http.post<GTResponse<ConfigurationDTO>>(this.URLs._getConfiguration(), getConfigurationRequest).pipe(
            handleGTResponse(),
        );
    }
}