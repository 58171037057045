import { Action } from "@ngrx/store";

// TODO: This action is just a placeholder until error actions are implemented properly
// Eventually this action should be phased out and replaced.

export const UNHANDLED_ERROR = '[App] Unhandled App Error!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!';

export class UnhandledError implements Action {
    readonly type = UNHANDLED_ERROR;
    constructor(public payload: {errorMessage: string}) {}
}

export type Actions
    = UnhandledError
;
