import { AutosaveState, GledEventItemState } from "../models/eventItem";
import * as GledEventItemActions from '../actions/gledEventItem.action';
import { mergeArrays, shallowCopy } from "../models/util";


export type State = GledEventItemState;

export const initialState = {
    items: [],
    assignmentsAutosaveState: AutosaveState.Initial,
    enteredOrderNumber: null,
    selectedShow: null,
    selectedPerformance: null,
    wasShowPerformanceOrderNumberCorrect: false,
}

export function reducer(state: State = initialState, action: GledEventItemActions.Actions): State {
    switch (action.type) {
        case GledEventItemActions.LOAD_EVENT_ITEMS_OK:
        case GledEventItemActions.ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_OK:
        {
            const newState = shallowCopy(state);
            newState.items = action.payload;
            newState.assignmentsAutosaveState = AutosaveState.Initial;
            return newState;
        }
        case GledEventItemActions.ASSIGN_ATTENDEES_TO_ITEMS_PRE_AUTOSAVE_CHANGE:
        {
            const newState = shallowCopy(state);
            newState.assignmentsAutosaveState = AutosaveState.Loading;
            return newState;
        }
        case GledEventItemActions.ASSIGN_ATTENDEES_TO_ITEMS_AUTOSAVE_CANCELLED:
        {
            const newState = shallowCopy(state);
            newState.assignmentsAutosaveState = AutosaveState.Success;
            return newState;
        }
        case GledEventItemActions.ASSIGN_ATTENDEES_TO_ITEMS_OK:
        {
            const newState = shallowCopy(state);
            const currentEventItems = state.items;
            const updatedEventItems = action.payload;
            newState.items = mergeArrays(i => i.id, currentEventItems, updatedEventItems);
            newState.assignmentsAutosaveState = AutosaveState.Success;
            return newState;
        }
        case GledEventItemActions.ASSIGN_ATTENDEES_TO_ITEMS_ERROR:
        {
            const newState = shallowCopy(state);
            newState.assignmentsAutosaveState = AutosaveState.Error;
            return newState;
        }
        case GledEventItemActions.ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_ERROR:
        {
            return shallowCopy(state); // TODO: need more state to indicate error here to rest of application
        }
        default:
        {
            return state;
        }
    }
}
