import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as fromRoot from 'app/store/reducers';
import * as loaderActions from 'app/store/actions/loader.action';
import { Store } from '@ngrx/store';
import { GroupToolsLoaderService } from 'app/grouptools-theme/services/grouptoolsLoader/grouptoolsLoader.service';
import { GtLoaderConfigService } from 'app/pages/helpers/gt-loader-config.service';

// borrowed from https://grensesnittet.computas.com/loading-status-in-angular-done-right/
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        private gtLoader: GroupToolsLoaderService,
        private gtLoaderConfig: GtLoaderConfigService
        ) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0) {
            this.gtLoader.show();
        } else {
            this.gtLoader.hide();
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.gtLoaderConfig.shouldShowGTLoaderForRequest(req)) { 
            return next.handle(req); // Skip showing GT Loader for this request
        }
        this.requests.push(req);
        this.gtLoader.show();
        // We create a new observable which we return instead of the original
        return Observable.create(observer => {
            // And subscribe to the original observable to ensure the HttpRequest is made
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => { this.removeRequest(req); observer.error(err); },
                    () => { this.removeRequest(req); observer.complete(); });
            // return teardown logic in case of cancelled requests
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
