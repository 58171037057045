import { Payment } from "../models/payment";
import * as PaymentActions from 'app/store/actions/payment.action';
import { PaymentState } from "../models/payment.state";

export type State = PaymentState;

export const initialState: State = {
    separatedPayments: {
        AttendeePayments: [],
        SupplierPayments: [],
    },
    paymentsReceivedSummary: {Received: 0, AmountDue: 0, Balance: 0},
    paymentsSentSummary: {Sent: 0, AmountDue: 0, Balance: 0, PaymentDueDate: null},

    paymentsNew: [],
}

export function reducer(state: State = initialState, action: PaymentActions.Actions): State {
    switch (action.type) {
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsSuccess:
        {
            return {
                ...state,
                separatedPayments: action.payload.separatedPayments
            };
        }
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
                separatedPayments: { 
                    // Make these blank for Payments Component when no payments exist for event
                    AttendeePayments: [],
                    SupplierPayments: [],
                }
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsReceivedSummarySuccess:
        {
            return {
                ...state,
                paymentsReceivedSummary: action.payload.paymentsReceivedSummary,
            };
        }
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsReceivedSummaryFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsSentSummarySuccess:
        {
            return {
                ...state,
                paymentsSentSummary: action.payload.paymentsSentSummary,
            };
        }
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsSentSummaryFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsNewSuccess:
        {
            return {
                ...state,
                paymentsNew: action.payload.payments
            };
        }
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsNewFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.ATEDComponentMakeSupplierPaymentSuccess:
        {
            return {
                ...state,
                // TODO: May have to update state here
            };
        }
        case PaymentActions.ActionTypes.ATEDComponentMakeSupplierPaymentFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.ATEDComponentAddAndAssociateCustomerSuccess:
        {
            return {
                ...state,
                // TODO: May have to update state here
            };
        }
        case PaymentActions.ActionTypes.ATEDComponentAddAndAssociateCustomerFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.GLEDComponentMakeSupplierGLPaymentSuccess:
        {
            return {
                ...state,
                // TODO: May have to update state here
            };
        }
        case PaymentActions.ActionTypes.GLEDComponentMakeSupplierGLPaymentFailure:
        {
            return {
                ...state, // TODO: record api error somewhere
            };
        }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.AuthenticationServiceLogout:
        {
            return {
                ...initialState,
            };
        }
        default:
        {
            return state;
        }
        //-------------------------------------------------------------------------------------
    }
}