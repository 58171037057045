import { Router, ActivatedRoute } from '@angular/router';
import { EqualPasswordsValidator } from '../../grouptools-theme/validators/equalsPasswords.validator';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginPerson, AuthService, GooglePerson } from '../../authentication/authentication.service';
import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import 'style-loader!./google-register.component.scss';
declare const gapi: any;

@Component({
    selector: 'google-register',
    templateUrl: './google-register.component.html'
})
export class GoogleRegisterComponent {
    public GOOGLEICON = require(`app/grouptools-theme/google_icon.svg`);
    public PASSWORD_REGX: string = '^((?=.*[A-Z])(?=.*\\d)(?=.*[a-z])[A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{8,})|([A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{12,})$';

    public auth2: any;

    @Output() registerComplete: EventEmitter<any> = new EventEmitter<any>();
    @Input() googleUser: any;
    @Input() inModal: boolean;
    public profile: any;
    @ViewChild('button') button: ElementRef;

    form: FormGroup;
    public password: AbstractControl;
    public repeatPassword: AbstractControl;
    public passwords: FormGroup;

    constructor(
        public fb: FormBuilder,
        private _service: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group(this.formFields);
        this.passwords = <FormGroup>this.form.controls['passwords'];
        this.password = this.passwords.controls['password'];
        this.repeatPassword = this.passwords.controls['repeatPassword'];

        this.form.valueChanges
            .subscribe(data => {
                this.onValueChanged(data);
            });
    }

    ngOnChanges(changes) {

        //console.log('google register init');
        // make sure we have the google user
        if (!this.profile) {
            this.profile = this.googleUser.getBasicProfile();
        }
    }

    onSubmit(formValue) {
        if (this.form.valid) {
            this._service.registerViaGoogle({
                FirstName: this.profile.getGivenName(),
                LastName: this.profile.getFamilyName(),
                Email: this.profile.getEmail(),
                Password: this.password.value,
                accessToken: this.googleUser.getAuthResponse().access_token,
                idToken: this.googleUser.getAuthResponse().id_token
            }).subscribe(res => {
                // try to login
                this.login();
            });
        }
    }

    login() {
        let loginPerson = new LoginPerson(
            this.profile.getEmail(),
            this.password.value);


        this._service.login(loginPerson).subscribe(
            token => {
                if (token.token_type) {
                    localStorage.setItem('access_token', token.access_token);
                    localStorage.setItem('userID', token.userID);
                    localStorage.setItem('login_status', token.login_status);
                    this._service.isLoggedInObservable().subscribe(res => {

                        if (!this.inModal) {
                            let loggedIn = true;
                            let returnUrl = this.route.snapshot.queryParams['returnUrl'];
                            if (returnUrl) {
                                this.router.navigate([returnUrl]);
                            }
                            else {
                                this.router.navigate(['/pages/dashboard']);
                            }
                        } else {
                            // not in modal
                            this.registerComplete.emit('google-registered');
                        }

                    });

                }

            },
            error => {
                if (error.status > 0) {
                    alert(JSON.parse(error._body).error_description);
                    //console.log('Server is unresponsive.');
                }
                else {
                    alert('Server is unresponsive.');
                    //console.log('Server is unresponsive.');
                }
            });
    }

    onValueChanged(data?: any) {
        if (!this.form) { return; }

        for (const field in this.formErrors) {
            this.formErrors[field] = '';
            let control = this.form.get(field);

            if (field === "password") {
                let intCont = this.form.get('passwords');
                control = intCont.get('password');
            }

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }

    includeSpecialChar(){
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        return format.test(this.password.value);
    }

    formFields = {
        passwords: this.fb.group({
            password: ['', Validators.pattern(this.PASSWORD_REGX)],
            repeatPassword: ['', Validators.pattern(this.PASSWORD_REGX)]
        },
            { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') })
    }

    formErrors = {
        'passwords': '',
        'password': '',
    };

    validationMessages = {
        'password': {
            'pattern': 'If you create a password, it must be at least 8 characters long and contain an upper case letter, a lower case letter and 1 number.'
        },
        'passwords': {
            'passwordsEqual': 'Passwords must match.',
        }
    };
}

