import * as eventActions from '../actions/event.action';
import { EventOverview } from '../models/eventOverview';

export type State = EventOverview;

const initialState: State = {
    minPrice: null,
    maxPrice: null,
    isFreeEvent: true,
    rsvpDueDate: null,
    inviteCount: 0,
    attendeeCount: 0,
    tentativeCount: 0,
    declineCount: 0,
    attendeePaymentsReceived: 0,
    attendeePaymentsBalance: 0,
    surveyCount: 0,
    surveyResponseCount: 0,
    formCount: 0,
}

export function reducer(state: State = initialState, action: eventActions.Actions) {
    switch (action.type) {
        case eventActions.LOAD_EVENT_OVERVIEW_OK:
        {
            const eventOverview = (<eventActions.LoadEventOverviewOk>action).payload;
            return eventOverview;
        }
        default:
        {
            return state;
        }
    }
}