import { ValidatorFn, AbstractControl } from '@angular/forms';

export function maxDateValidator(maxDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value || control.value === '') {
        return null;
      }
      const target = new Date(control.value);
      const maximumDate = new Date(maxDate);
      const tooEarly = target > maximumDate;
        return tooEarly ? { 'dateTooLate': { value: control.value } } : null;
    };
}
