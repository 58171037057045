
import {map} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLConfig } from '../pages/helpers/config.service';
import { MessageRequestDTO } from 'app/store/models/requests/MessageRequestDTO';
import { UserActionDTO } from 'app/store/models/packages/UserActionDTO';
import { GTResponse, GTResponseHelper, handleGTResponse } from 'app/store/models/gtResponse';


@Injectable()
export class ActionsService {

    public actionsOptions = {
        multiSelectMode: false,
        hideHeader: true,
        hideSubHeader: true,
        hideDate: true,
        hideMenu: false,
        hideFilter: true,
        hideHead: true,
        columns: [
            {fieldName: 'icon'},
            {fieldName: 'type'},
            {fieldName: 'content'},
        ],
        bulkActions: [],
        singleActions: ['Dismiss'],
        headerActions: []
    };

    constructor(private http: HttpClient,
        private URLs: URLConfig) { }

    public GetActions(options: MessageRequestDTO): Observable<UserActionDTO[]> {
        return this.http.post<GTResponse<UserActionDTO[]>>(this.URLs._actions(), options).pipe(
            handleGTResponse(),
            map(userActions => {
                
                userActions.map(alert => {
                    alert.JsonActionMessage = JSON.parse(alert.JsonActionMessage);
                    alert.JsonActions = JSON.parse(alert.JsonActions);

                    // add special fields with html for list display
                    if (alert.Type === 'System Alert') {
                        alert.icon = '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>';
                    } else {
                        alert.icon = '<i class="fa fa-info-circle" aria-hidden="true"></i>';
                    }
                    alert.type = '<h4>' + alert.Type + '</h4><p>'
                        + alert.RuleType + '</p>';

                    // 2018-12-14 - ska - wrap content in a p tag for consistent styling
                    alert.content = `<p>${alert.JsonActionMessage.message}</p>`;

                    // add special field for custom actions
                    alert.customActions = [];
                    alert.JsonActions.forEach(action => {
                        alert.customActions.push(action.Text);
                    });
                });
                
                return userActions;
            }));
    }

    public MarkAsRead(actionsObj: UserActionDTO): Observable<number> {
        let token = '';
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

        let transferObj: UserActionDTO = Object.assign({}, actionsObj);
        if (transferObj.JsonActions) {
            transferObj.JsonActions = JSON.stringify(transferObj.JsonActions);
        }
        if (transferObj.JsonActionMessage) {
            transferObj.JsonActionMessage = JSON.stringify(transferObj.JsonActionMessage);
        }

        return this.http.post<GTResponse<number>>(this.URLs._markActionAsRead(), transferObj).pipe(
            handleGTResponse(),
        );
    }

    public DismissSurveyActions(surveyId: number): Observable<number> {
        return this.http.post<GTResponse<number>>(this.URLs._dismissAllSurveyActions(surveyId), null).pipe(
            handleGTResponse(),
        );
    }

}
