import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { URLConfig } from '../helpers/config.service';
import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MessagesService } from '../messages/messages.service';
import { Store } from '@ngrx/store';
import { SetShortUrl } from '../../store/actions/shortUrl.action';
import { GTResponseHelper, handleGTResponse, handleGTCollectionResponse } from 'app/store/models/gtResponse';
var PackageDetailsService = /** @class */ (function () {
    function PackageDetailsService(http, URLs, sanitizer, messagesService, store) {
        this.http = http;
        this.URLs = URLs;
        this.sanitizer = sanitizer;
        this.messagesService = messagesService;
        this.store = store;
        this.enticingImage = require('assets/img/enticing_image.png');
        // tslint:disable-next-line:member-ordering
        this._initialFeatureType = ActivityType.Default;
    }
    PackageDetailsService.prototype.GetDetails = function (packageId) {
        var _this = this;
        return this.http.get(this.URLs._packageDetails(packageId)).pipe(map(function (res) { return res.Obj; }), map(function (pkg) {
            if (!pkg.hasOwnProperty('ShortPublicUrl') || !pkg.ShortPublicUrl || !pkg.ShortPublicUrl.startsWith('http')) {
                _this.http.get(_this.URLs._packageShortUrl(packageId)).pipe(map(function (res) { return res.Obj; }))
                    .subscribe(function (url) {
                    pkg.ShortPublicUrl = url;
                    _this.dispatchShortUrlState(pkg);
                    return pkg;
                });
            }
            return pkg;
        }), map(function (res) {
            //console.log("RES", res);
            if (res.PackageDesc) {
                res.PackageDesc = _this.sanitizer.sanitize(SecurityContext.HTML, res.PackageDesc);
            }
            if (res.PackageShortDesc) {
                res.PackageShortDesc = _this.sanitizer.sanitize(SecurityContext.HTML, res.PackageShortDesc);
            }
            if (res.JsonDoc === '') {
                res.JsonDoc = '{}';
            }
            res.options = JSON.parse(res.JsonDoc);
            if (res.options.inviteOthers === undefined) {
                res.options.inviteOthers = true;
            }
            if (res.options.inviteOthersCount === undefined) {
                res.options.inviteOthersCount = 99;
            }
            return res;
        }), map(function (pkg) {
            _this.dispatchShortUrlState(pkg);
            return pkg;
        }));
    };
    PackageDetailsService.prototype.dispatchShortUrlState = function (event) {
        // create a new state with the new url info, but old image
        var urlState = Object.assign({}, {
            shortUrl: event.ShortPublicUrl,
            title: event.PackageName,
            description: event.PackageDesc,
            image: event.PhotoUrl,
            site_name: 'GroupTools'
        });
        // dispatch
        this.store.dispatch(new SetShortUrl(urlState));
    };
    PackageDetailsService.prototype.GetPackage = function (packageId) {
        var _this = this;
        return this.http.get(this.URLs._packageGetByID(packageId)).pipe(handleGTResponse(), map(function (res) { return JSON.parse(res)[0]; }), map(function (res) {
            if (res.PackageDesc) {
                res.PackageDesc = _this.sanitizer.sanitize(SecurityContext.HTML, res.PackageDesc);
            }
            if (res.PackageShortDesc) {
                res.PackageShortDesc = _this.sanitizer.sanitize(SecurityContext.HTML, res.PackageShortDesc);
            }
            return res;
        }));
    };
    PackageDetailsService.prototype.PackageInfo = function (packageId) {
        var _this = this;
        return this.http.get(this.URLs._packageInfoByID(packageId)).pipe(map(function (res) {
            if (res.Result) {
                //Assume it's a URL
                var imgSrc = res.Obj.packagePic;
                if (imgSrc) {
                    if (!imgSrc.startsWith('http')) {
                        imgSrc = 'data:image/jpg;base64,' + imgSrc;
                        res.Obj.packagePic = imgSrc;
                    }
                }
                else {
                    var imgDefult = require("assets/img/default-image.jpg");
                    res.Obj.packagePic = imgDefult;
                }
            }
            res.Obj.packageDetails = JSON.parse(res.Obj.packageDetails)[0];
            return res.Obj;
        }), tap(function (res) {
            // update open graph meta data by storing the shorturl information
            _this.dispatchShortUrlState({
                ShortPublicUrl: res.packageDetails.ShortPublicUrl,
                PackageName: res.packageDetails.PackageName,
                PackageDesc: res.packageDetails.PackageDesc,
                PhotoUrl: res.packagePic
            });
        }));
    };
    // TODO: This is deprecated, instead use event-details.service.ts getAttendees method
    PackageDetailsService.prototype.GetAttendeeDetails = function (packageId) {
        return this.http.get(this.URLs._packageAttendeeDetails(packageId)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.packageComponentsGetByID = function (packageId) {
        return this.http.get(this.URLs._packageComponentsGetByID(packageId)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.getPayments = function (packageId) {
        return this.http.get(this.URLs._paymentsGetUrlOld(packageId)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.GetPaymentsReceivedSummary = function (packageId) {
        return this.http.get(this.URLs._paymentsReceivedSummary(packageId)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.GetPaymentsSentSummary = function (packageId) {
        return this.http.get(this.URLs._paymentsSentSummary(packageId)).pipe(handleGTResponse());
    };
    /**
     * getPackageFeatureDetails
     */
    PackageDetailsService.prototype.getFeaturePaymentSummary = function (packageId, featureId) {
        return this.http.get(this.URLs._paymentsFeatureSummaryUrl(packageId, featureId)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.createEventFromBibOrder = function (createEventFromBibOrderRequest) {
        return this.http.post(this.URLs._createEventFromBibOrder(), createEventFromBibOrderRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.createEventFromSupplierOrder = function (createEventFromSupplierOrderRequest) {
        return this.http.post(this.URLs._createEventFromSupplierOrder(), createEventFromSupplierOrderRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.updateEventFromSupplierOrder = function (updateEventFromSupplierOrderRequest) {
        return this.http.post(this.URLs._updateEventFromSupplierOrder(), updateEventFromSupplierOrderRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.loadTickets = function (loadTicketsRequest) {
        return this.http.post(this.URLs._loadTickets(), loadTicketsRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.purchaseTickets = function (purchaseTicketsRequest) {
        return this.http.post(this.URLs._purchaseTickets(), purchaseTicketsRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.getSeatMapImage = function (getSeatMapImageRequest) {
        return this.http.post(this.URLs._getSeatMapImage(), getSeatMapImageRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.recordSupplierAttendeePayment = function (recordSupplierAttendeePaymentRequest) {
        return this.http.post(this.URLs._recordSupplierAttendeePayment(), recordSupplierAttendeePaymentRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.getAlternateGroupLeaderInfo = function (getAlternateGroupLeaderInfoRequest) {
        return this.http.post(this.URLs._getAlternateGroupLeaderInfo(), getAlternateGroupLeaderInfoRequest).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.inviteEventAttendees = function (inviteEventAttendeesRequest) {
        return this.http.post(this.URLs._inviteEventAttendees(), inviteEventAttendeesRequest).pipe(handleGTCollectionResponse());
    };
    PackageDetailsService.prototype.MarkPublicUrlAsShared = function (eventId) {
        return this.http.post(this.URLs._markPublicUrlAsShared(eventId), null).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.updatePackageDetails = function (packageDetails) {
        return this.http.post(this.URLs._packageUpdatePackageDetails(), packageDetails).pipe(map(function (res) {
            return {
                res: res
            };
        }), catchError(function (e) {
            return observableThrowError({
                "Errors": e
            });
        }));
    };
    PackageDetailsService.prototype.updateComponentDetails = function (componentDetails) {
        return this.http.post(this.URLs._updateComponentDetails(), componentDetails).pipe(catchError(function (e) {
            return observableThrowError({
                "Errors": e
            });
        }));
    };
    PackageDetailsService.prototype.updateComponentOption = function (optionDetails) {
        return this.http.post(this.URLs._updateComponentOption(), optionDetails).pipe(catchError(function (e) {
            return observableThrowError({
                "Errors": e
            });
        }));
    };
    PackageDetailsService.prototype.getComponentOption = function (IdPackage, IdComponent) {
        return this.http.get(this.URLs._getComponentOptions(IdPackage, IdComponent)).pipe(catchError(function (e) {
            return observableThrowError({
                "Errors": e
            });
        }));
    };
    PackageDetailsService.prototype.GetTagsInfo = function (recordId, record) {
        return this.http.get(this.URLs._tagsInfo(recordId, record)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.GetContactsTagsByOwner = function () {
        return this.http.get(this.URLs._getContactTagsByOwner()).pipe(map(function (res) {
            if (res.Result) {
                return res.Obj;
            }
            else {
                return null;
            }
        }));
    };
    PackageDetailsService.prototype.insertTagsInfo = function (tagTextList, record, recordId) {
        var insertBody = {
            tagText: tagTextList,
            record: record,
            recordId: recordId
        };
        // Return the http Observable -> turned Promise
        return this.http.post(this.URLs._insertTagsInfo(), insertBody).pipe(map(function (res) {
            return {
                res: res
            };
        }), catchError(function (e) {
            //console.log(e.status);
            return observableThrowError({
                "Errors": e
            });
        }));
    };
    PackageDetailsService.prototype.deleteTagsInfo = function (tagTextList, record, recordId) {
        var deleteBody = {
            tagText: tagTextList,
            record: record,
            recordId: recordId
        };
        // Return the http Observable -> turned Promise
        return this.http.post(this.URLs._deleteTagsInfo(), deleteBody).pipe(map(function (res) {
            return {
                res: res
            };
        }), catchError(function (e) {
            //console.log(e.status);
            return observableThrowError({
                "Errors": e
            });
        }));
    };
    PackageDetailsService.prototype.getWorkshopData = function () {
        var _this = this;
        return this.http.get(this.URLs._getCatalogWorkshops()).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.map(function (workshop) {
            if (!workshop.Details.Logo) {
                workshop.Details.Logo = _this.enticingImage;
            }
            return Object.assign({
                ApiAccessId: workshop.ApiAccessId,
                ApiItemConfigJson: workshop.ApiItemConfigJson,
                catalogItemId: workshop.catalogItemId,
                catalogItemName: workshop.catalogItemName,
                catalogItemType: workshop.catalogItemType
            }, workshop.Details);
        }); }));
    };
    PackageDetailsService.prototype.getShowData = function () {
        var _this = this;
        return this.http.get(this.URLs._getCatalogShows()).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.map(function (show) {
            if (!show.Details.Logo) {
                show.Details.Logo = _this.enticingImage;
            }
            return Object.assign({
                ApiAccessId: show.ApiAccessId,
                ApiItemConfigJson: show.ApiItemConfigJson,
                catalogItemId: show.catalogItemId,
                catalogItemName: show.catalogItemName,
                catalogItemType: show.catalogItemType
            }, show.Details);
        }); }));
    };
    PackageDetailsService.prototype.insertNewOptionToComponent = function (option) {
        return this.http.post(this.URLs._addComponentOption(), option).pipe(map(function (res) {
            if (res.Result) {
                return res.Obj;
            }
            else {
                return null;
            }
        }));
    };
    PackageDetailsService.prototype.insertNewFeatureOption = function (component) {
        return this.http.post(this.URLs._packageInsertComponent(), component).pipe(map(function (res) {
            if (res.Result) {
                return res.Obj;
            }
            else {
                return null;
            }
        }));
    };
    PackageDetailsService.prototype.insertNewFeature = function (eventId, featureObj) {
        return this.http.post(this.URLs._packageInsertComponent(), this.formatFeature(eventId, featureObj));
    };
    PackageDetailsService.prototype.formatFeature = function (eventId, featureObj) {
        return {
            IdPackage: eventId,
            ComponentSupplier: 1,
            ComponentCategory: featureObj.catalogItemType,
            catalogItemId: featureObj.catalogItemId,
        };
    };
    // I'm not sure the consumer of this observable can handle thrown errors, so instead of using handleGTResponse() operator,
    // I am matching the previous implementation of returning null just to be safe.
    // TODO: Refactor to use handleGTResponse() operator?  Or just completely switch to using ngrx store.
    PackageDetailsService.prototype.getFormsForPackage = function (packageId) {
        return this.http.get(this.URLs._getFormsForPackage(packageId)).pipe(map(function (gtResponse) {
            if (GTResponseHelper.hasError(gtResponse))
                return null;
            return GTResponseHelper.getPayload(gtResponse);
        }));
    };
    PackageDetailsService.prototype.createForm = function (detail) {
        return this.http.post(this.URLs._createForm(), detail);
    };
    PackageDetailsService.prototype.getForm = function (formId) {
        return this.http.get(this.URLs._getForm(formId)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.updateForm = function (formDetail) {
        return this.http.post(this.URLs._updateForm(), formDetail).pipe(map(function (res) {
            if (res.Result) {
                return res.Obj;
            }
            else {
                return null;
            }
        }));
    };
    PackageDetailsService.prototype.getPicture = function (packageId) {
        return this.http.get(this.URLs._getPackageImage(packageId)).pipe(map(function (res) {
            if (res.Result) {
                //Assume it's a URL
                var imgSrc = res.Obj;
                //If it's not, treat it as a base64 string representation of the image
                if (!imgSrc.startsWith('http')) {
                    imgSrc = 'data:image/jpg;base64,' + imgSrc;
                }
                return imgSrc;
            }
            else {
                var imgDefult = require("assets/img/default-image.jpg");
                return imgDefult;
            }
        }));
    };
    PackageDetailsService.prototype.canCancel = function (IdPackage) {
        return this.http.get(this.URLs._canCancelEvent(IdPackage)).pipe(map(function (res) { return res.Result; }));
    };
    PackageDetailsService.prototype.cancel = function (IdPackage) {
        return this.http.post(this.URLs._cancelEvent(IdPackage), null).pipe(map(function (res) { return res.Result; }));
    };
    PackageDetailsService.prototype.getPackageLogs = function (IdPackage) {
        return this.http.get(this.URLs._getPackageLogs(IdPackage)).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.GetPackageRecord = function (packageId) {
        return this.http.get(this.URLs._packageRecord(packageId)).pipe(handleGTResponse(), tap(function (packageRecord) {
            // since switching to GTResponse pattern)
            packageRecord.Components.forEach(function (component) {
                component.Attendees.forEach(function (attendee) {
                    if (attendee.NotesJson) {
                        attendee.Notes = JSON.parse(attendee.NotesJson).Notes.Value;
                    }
                    else
                        attendee.Notes = '';
                });
            });
        }));
    };
    PackageDetailsService.prototype.RemoveComponent = function (component) {
        return this.http.post(this.URLs._packageRemoveComponent(), component).pipe(map(function (res) { return res.Result; }));
    };
    PackageDetailsService.prototype.UpdateComponentAttendeeNotesJson = function (attendees) {
        return this.http.post(this.URLs._packageUpdateComponentAttendeeNotesJson(), attendees).pipe(map(function (res) { return res.Result; }));
    };
    // TODO: This is only used in three places to see if BI is enabled
    // it should be refactored to be saved in ngrx store b/c this data won't change very often
    // and only loaded once on login, or maybe page load
    PackageDetailsService.prototype.GetActiveSuppliers = function () {
        return this.http.get(this.URLs._getSuppliers()).pipe(handleGTResponse());
    };
    PackageDetailsService.prototype.RemoveComponentOption = function (option) {
        return this.http.post(this.URLs._packageRemoveComponentOption(), option).pipe(map(function (res) {
            return res;
        }));
    };
    PackageDetailsService.prototype.UploadPackageImage = function (IdPackage, UploadPackagePicture) {
        return this.http.post(this.URLs._uploadPackageImage(IdPackage), UploadPackagePicture);
    };
    PackageDetailsService.prototype.DeliverForms = function (formId, userIds) {
        return this.http.post(this.URLs._deliverForms(formId), userIds);
    };
    PackageDetailsService.prototype.ItineraryRecord = function (IdPackage) {
        return this.http.get(this.URLs._packageItineraryRecord(IdPackage));
    };
    PackageDetailsService.prototype.resendInvitations = function (event) {
        return this.http.post(this.URLs._packageResendInvitations(), event);
    };
    // tslint:disable-next-line:member-ordering
    PackageDetailsService.prototype.SetIsActivityOrLodging = function (value) {
        this._isActivityOrLodgingEvent = value;
        return value;
    };
    PackageDetailsService.prototype.GetIsActivityOrLodging = function () {
        // one time usage, reset after it has been retrieved
        var val = this._isActivityOrLodgingEvent;
        this._isActivityOrLodgingEvent = false;
        return val;
    };
    // tslint:disable-next-line:member-ordering
    PackageDetailsService.prototype.SetInitialActivityType = function (value) {
        this._initialFeatureType = value;
        return value;
    };
    PackageDetailsService.prototype.GetInitialActivityType = function () {
        // one time usage, reset after it has been retrieved
        var val = this._initialFeatureType;
        this._initialFeatureType = ActivityType.Default;
        return val;
    };
    PackageDetailsService.prototype.showEventName = function (event, components) {
        if (components && event) {
            var feature = components.filter(function (component) {
                return component.ComponentName === event.name ||
                    component.ComponentName === event.PackageName;
            });
            if (feature.length > 0 && components.length === 1) {
                return false;
            }
        }
        return true;
    };
    PackageDetailsService.prototype.showEventDescription = function (event, components) {
        if (components && event) {
            var feature = components.filter(function (component) {
                return (component.ComponentName === event.name || component.ComponentName === event.PackageName) &&
                    (component.ComponentShortDesc === event.description || component.ComponentShortDesc === event.PackageDesc);
            });
            if (feature.length > 0 && components.length === 1) {
                return false;
            }
        }
        return true;
    };
    PackageDetailsService.prototype.showEventDate = function (components) {
        if (components) {
            var feature = components.filter(function (component) { return component.ComponentSupplierInvoiceId; });
            if (feature.length > 0) {
                return true;
            }
        }
        return false;
    };
    PackageDetailsService.prototype.sortOptions = function (options) {
        return options.sort(function (a, b) {
            if (a.Availability === 0 && b.Availability > 0) {
                return 1;
            }
            else if (a.Availability > 0 && b.Availability === 0) {
                return -1;
            }
            else {
                return 0;
            }
        });
    };
    PackageDetailsService.prototype.isOptionUnavailable = function (option, partySize) {
        if (partySize === void 0) { partySize = 0; }
        return option.Availability < partySize
            && (!option.Status || option.Status.toLowerCase() !== 'accept');
    };
    return PackageDetailsService;
}());
export { PackageDetailsService };
export var ActivityType;
(function (ActivityType) {
    ActivityType["Default"] = "show";
    ActivityType["Lodging"] = "lodging";
    ActivityType["Restaurant"] = "restaurant";
    ActivityType["Transportation"] = "transportation";
    ActivityType["Other"] = "other";
})(ActivityType || (ActivityType = {}));
