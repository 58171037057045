/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./facebook-login.component";
import * as i2 from "./facebook.service";
import * as i3 from "ngx-configure";
var styles_FacebookLoginComponent = [];
var RenderType_FacebookLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FacebookLoginComponent, data: {} });
export { RenderType_FacebookLoginComponent as RenderType_FacebookLoginComponent };
export function View_FacebookLoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["button", 1]], null, 0, "img", [["class", "clickable social-image img-fluid"], ["id", "LoginWithFacebook"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.facebookLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.facebookButtonImage; _ck(_v, 0, 0, currVal_0); }); }
export function View_FacebookLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "facebook-login", [], null, null, null, View_FacebookLoginComponent_0, RenderType_FacebookLoginComponent)), i0.ɵdid(1, 114688, null, 0, i1.FacebookLoginComponent, [i2.FacebookService, i3.NgxConfigureService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FacebookLoginComponentNgFactory = i0.ɵccf("facebook-login", i1.FacebookLoginComponent, View_FacebookLoginComponent_Host_0, {}, { loginComplete: "loginComplete" }, []);
export { FacebookLoginComponentNgFactory as FacebookLoginComponentNgFactory };
