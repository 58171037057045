/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./baCard.component";
var styles_BaCard = [".collapsed[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]::before {\n        content: \"\\F123\";\n    }\n    .not-clickable[_ngcontent-%COMP%] {\n        cursor: default;\n    }"];
var RenderType_BaCard = i0.ɵcrt({ encapsulation: 0, styles: styles_BaCard, data: {} });
export { RenderType_BaCard as RenderType_BaCard };
function View_BaCard_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.titleStyle; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleClass; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.title; _ck(_v, 2, 0, currVal_2); }); }
function View_BaCard_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "card-header clearfix"], ["style", "height: auto;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 10, "a", [["class", "card-collapse-toggle pull-left"]], [[2, "not-clickable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleShowBody() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "i", [["class", "fa"]], [[8, "hidden", 0]], null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "fa-chevron-up": 0, "fa-chevron-down": 1 }), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaCard_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n            "])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n        "])), i0.ɵncd(null, 1), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "fa"; var currVal_3 = _ck(_v, 6, 0, _co.gtCardShow, !_co.gtCardShow); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.showTitleString; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.collapsible; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.collapsible; _ck(_v, 4, 0, currVal_1); }); }
export function View_BaCard_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["zoom-in", ""]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaCard_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n    "])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "card-body"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), i0.ɵncd(null, 2), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "animated card ", _co.cardType, " ", (_co.baCardClass || ""), ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = (_co.collapsible && !_co.gtCardShow); _ck(_v, 6, 0, currVal_2); }); }
export function View_BaCard_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "ba-card", [], null, null, null, View_BaCard_0, RenderType_BaCard)), i0.ɵdid(1, 573440, null, 0, i2.BaCard, [i0.ComponentFactoryResolver, i0.ViewContainerRef, i0.Compiler], null, null)], null, null); }
var BaCardNgFactory = i0.ɵccf("ba-card", i2.BaCard, View_BaCard_Host_0, { collapsible: "collapsible", title: "title", titleStyle: "titleStyle", titleClass: "titleClass", baCardClass: "baCardClass", cardType: "cardType", IsExpand: "IsExpand", collapsed: "collapsed" }, { CardEvent: "CardEvent" }, ["[title-text]", "[card-header]", "*"]);
export { BaCardNgFactory as BaCardNgFactory };
