import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EqualPasswordsValidator } from 'app/grouptools-theme/validators/equalsPasswords.validator';
import { AppStateService } from '../helpers/appState.service';
import { AuthService, Person, LoginPerson, BIStartPlanningProperties } from 'app/authentication/authentication.service';
// import { RegisterService, EmailVerification, Person } from './register.service';
import { ModalDirective } from 'ngx-bootstrap';
import 'style-loader!./register.scss';
import { GrouptoolsModalComponent } from 'app/widgets/grouptools-modal/grouptools-modal.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { TitleCasePipe } from '@angular/common';
import * as attendeePackages from 'app/store/actions/attendeePackages';
var Register = /** @class */ (function () {
    function Register(fb, _registerService, route, router, titlecasePipe, store, _appService) {
        this.fb = fb;
        this._registerService = _registerService;
        this.route = route;
        this.router = router;
        this.titlecasePipe = titlecasePipe;
        this.store = store;
        this._appService = _appService;
        this.PASSWORD_REGX = '^((?=.*[A-Z])(?=.*\\d)(?=.*[a-z])[A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{8,})|([A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{12,})$';
        this.NAME_REGX = "^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ \',.-]+$";
        this.EMAIL_REGX = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
        this.PHONE_REGEX = "^[0-9]{3}-[0-9]{3}-[0-9]{4}$";
        this.formSubmitted = false;
        this.doNotRedirect = false;
        this.inBaPageTop = false;
        this.inModal = false;
        this.omitTitle = false;
        this.isNavgiate = true;
        this.emailPrefill = '';
        this.registerEmit = new EventEmitter();
        this.loginInfo = new EventEmitter();
        this.differentAccount = new EventEmitter();
        this.googleRegisterEmit = new EventEmitter();
        this.amazonRegisterEmit = new EventEmitter();
        this.facebookRegisterEmit = new EventEmitter();
        this.label = 'Verify?';
        this.title = 'Account Created';
        this.modalState = 'null';
        this.modalTitle = '';
        this.formFields = {
            firstname: ['', Validators.compose([Validators.required, Validators.pattern(this.NAME_REGX)])],
            lastname: ['', Validators.compose([Validators.required, Validators.pattern(this.NAME_REGX)])],
            tosBox: [false, Validators.compose([Validators.requiredTrue])],
            passwords: this.fb.group({
                password: ['', Validators.compose([Validators.required, Validators.pattern(this.PASSWORD_REGX)])],
                repeatPassword: ['', Validators.compose([Validators.required, Validators.pattern(this.PASSWORD_REGX)])]
            }, { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') }),
            emails: this.fb.group({
                email: ['', Validators.compose([Validators.required, Validators.pattern(this.EMAIL_REGX)])]
            }),
            phonevalue: ['', Validators.compose([Validators.pattern(this.PHONE_REGEX)])],
        };
        this.formErrors = {
            'firstname': '',
            'lastname': '',
            'phonevalue': '',
            'passwords': '',
            'emails': '',
            'password': '',
            'email': '',
            'tosBox': '',
        };
        this.validationMessages = {
            'firstname': {
                'required': 'First Name is required.',
                'pattern': "Only alphabetic characters and the special characters [,.'-] are allowed."
            },
            'lastname': {
                'required': 'Last Name is required.',
                'pattern': "Only alphabetic characters and the special characters [,.'-] are allowed."
            },
            'password': {
                'required': "Password is Required.",
                'pattern': 'Password must be at least 8 characters long and contain an upper case letter, a lower case letter and 1 number.'
            },
            'passwords': {
                'passwordsEqual': 'Passwords must match.',
            },
            'email': {
                'required': 'Email is required.',
                'pattern': 'Email must be in the proper format (ex. example@example.com).',
                'notUnique': 'Email is already in use.'
            },
            'phonevalue': {
                'pattern': 'Phone number must be in the format 123-456-7890.',
            },
            'tosBox': {
                'required': 'You must accept the Terms of Service to continue.'
            }
        };
    }
    Register.prototype.showChildModal = function () {
        this.staticModal.showModal();
    };
    Register.prototype.resetPassword = function () {
        var _this = this;
        this._registerService.forgotPassword(this.email.value).subscribe(function (_a) {
            var res = _a.Obj;
            alert('Your password has been mailed to you.');
            _this.existsModal.hideModal();
        });
    };
    Register.prototype.showOrdersModal = function () {
        this.ordersModal.show();
    };
    Register.prototype.hideChildModal = function () {
        this.staticModal.hideModal();
    };
    Register.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group(this.formFields);
        this.firstname = this.form.controls['firstname'];
        this.lastname = this.form.controls['lastname'];
        this.phonevalue = this.form.controls['phonevalue'];
        this.emails = this.form.controls['emails'];
        this.email = this.emails.controls['email'];
        this.passwords = this.form.controls['passwords'];
        this.password = this.passwords.controls['password'];
        this.repeatPassword = this.passwords.controls['repeatPassword'];
        this.tosBox = this.form.controls['tosBox'];
        this.route.queryParams.subscribe(function (params) {
            _this.email.setValue(params['email']);
            _this.lastname.setValue(params['lastname']);
            _this.firstname.setValue(params['firstname']);
            _this.emailToken = params['emailToken'];
        });
        if (sessionStorage.getItem('GTEmail')) {
            this.emailPrefill = sessionStorage.getItem('GTEmail');
        }
        this.form.valueChanges
            .subscribe(function (data) {
            _this.onValueChanged(data);
        });
        if (this.emailPrefill) {
            this.email.setValue(this.emailPrefill);
            this.email.disable();
        }
        this.onValueChanged(); //(re)set validation messages now
    };
    Register.prototype.verifyEmail = function () {
        return this._registerService.getEmailVerification(encodeURIComponent(this.email.value));
    };
    Register.prototype.onSubmit = function (values) {
        var _this = this;
        this.formSubmitted = true;
        if (!this.form.valid) {
            this.onFormSubmit();
            return;
        }
        this.verifyEmail().subscribe(function (emailVerify) {
            _this.emailUnique = emailVerify;
            // Email in use check. If false is returned from API the email is currently in use.
            if (!_this.emailUnique.valueOf()) {
                // this.email.setErrors({'notUnique': true});
                // this.onFormSubmit();
                _this.emailInUse();
            }
            else {
                _this.registerUser();
            }
        }, function (error) {
            //console.error(error);
        });
    };
    Register.prototype.emailInUse = function () {
        this.loginName = this.firstname.value;
        if (this.inModal) {
            this.registerEmit.emit('duplicateEmail');
        }
        else {
            this.existsModal.showModal();
        }
    };
    Register.prototype.registerUser = function () {
        var _this = this;
        this.me = new Person(this.email.value, this.firstname.value, this.lastname.value, this.phonevalue.value, this.password.value, this.repeatPassword.value, this.emailToken, this.invoiceId);
        this._registerService.insertPerson(JSON.stringify(this.me))
            .subscribe(function (submitUser) {
            if (submitUser.Result) {
                _this.loginName = _this.firstname.value;
                _this.loginEmail = _this.email.value;
                if (_this.inModal) {
                    _this.loginInfo.emit({ loginName: _this.loginName, loginEmail: _this.loginEmail, loginPhone: _this.loginPhone, password: _this.password.value });
                    _this.login();
                }
                else {
                    _this.showChildModal();
                }
            }
            else {
                //console.error("" + submitUser.ResponseCode + ": " + submitUser.ResponseMessage)
                alert("" + submitUser.ResponseMessage);
            }
        }, function (error) {
            //console.error(error);
        });
    };
    Register.prototype.onlyLogin = function () {
        this.isNavgiate = false;
        this.login();
    };
    Register.prototype.skipVerification = function () {
        this.isNavgiate = true;
        this.login();
        this.staticModal.grouptoolsModal.hide();
    };
    Register.prototype.existsLogin = function () {
        var _this = this;
        this.loginPerson = new LoginPerson(this.email.value, this.existsPassword);
        this._registerService.login(this.loginPerson).subscribe(function (token) {
            if (token.token_type) {
                //console.log(token);
                localStorage.setItem('access_token', token.access_token);
                localStorage.setItem('userID', token.userID);
                localStorage.setItem('login_status', token.login_status);
                _this._registerService.isLoggedIn();
                var loggedIn = true;
                var returnUrl = _this.route.snapshot.queryParams['returnUrl'];
                if (returnUrl) {
                    _this.router.navigate([returnUrl]);
                }
                else {
                    if (_this.inModal) {
                        _this.registerEmit.emit('loggedIn');
                    }
                    else {
                        _this.router.navigate(['/pages/dashboard']);
                    }
                }
            }
        }, function (error) {
            if (error.status > 0) {
                alert(JSON.parse(error._body).error_description);
                //console.log('Server is unresponsive.');
            }
            else {
                alert('Server is unresponsive.');
                //console.log('Server is unresponsive.');
            }
        });
    };
    Register.prototype.login = function () {
        var _this = this;
        this.loginPerson = new LoginPerson(this.email.value, this.password.value);
        this._registerService.login(this.loginPerson).subscribe(function (token) {
            if (token.token_type) {
                localStorage.setItem('access_token', token.access_token);
                localStorage.setItem('userID', token.userID);
                localStorage.setItem('login_status', token.login_status);
                _this._registerService.isLoggedInObservable().subscribe(function (res) {
                    var e_1, _a;
                    if (_this.inModal) {
                        _this.registerEmit.emit('loggedIn');
                    }
                    else {
                        var loggedIn = true;
                        if (_this.isNavgiate) {
                            // TODO change the way the verification process flows so that this is unnecessary
                            // FIX #10198 dashboard resource collapsed after verification - ska 2019-03-07
                            // Remove the 'lastLogin' key from localStorage so that the resource shows up
                            // the first time you see the dashboard whether you verified or not. The bug
                            // was that verification caused a re-login which then contained the lastLogin
                            // field.
                            localStorage.removeItem('lastLogin');
                            // check if we have stored BI order information, if so, create a
                            if (sessionStorage.getItem('GTOrderId')) {
                                var data = {};
                                try {
                                    for (var BIStartPlanningProperties_1 = tslib_1.__values(BIStartPlanningProperties), BIStartPlanningProperties_1_1 = BIStartPlanningProperties_1.next(); !BIStartPlanningProperties_1_1.done; BIStartPlanningProperties_1_1 = BIStartPlanningProperties_1.next()) {
                                        var key = BIStartPlanningProperties_1_1.value;
                                        data[key] = sessionStorage.getItem(key);
                                        sessionStorage.removeItem(key); // clear out cached values for future logins
                                    }
                                }
                                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                finally {
                                    try {
                                        if (BIStartPlanningProperties_1_1 && !BIStartPlanningProperties_1_1.done && (_a = BIStartPlanningProperties_1.return)) _a.call(BIStartPlanningProperties_1);
                                    }
                                    finally { if (e_1) throw e_1.error; }
                                }
                                _this._registerService.createBIPlaceHolderEvent(data);
                                return;
                            }
                            var returnUrl = _this.route.snapshot.queryParams['returnUrl'];
                            if (returnUrl) {
                                _this.router.navigate([returnUrl]);
                            }
                            else {
                                _this.router.navigate(['/pages/dashboard']);
                            }
                        }
                        else {
                            _this.accountVerification.sendVerificationCode();
                        }
                    }
                });
            }
        }, function (error) {
            if (error.status > 0) {
                alert(JSON.parse(error._body).error_description);
                //console.log('Server is unresponsive.');
            }
            else {
                alert('Server is unresponsive.');
                //console.log('Server is unresponsive.');
            }
        });
    };
    Register.prototype.onValueChanged = function (data) {
        if (!this.form) {
            return;
        }
        var form = this.form;
        if (this.formSubmitted) {
            this.onFormSubmit();
            return;
        }
        for (var field in this.formErrors) {
            this.formErrors[field] = '';
            var control = form.get(field);
            if (field === "email") {
                var intCont = form.get('emails');
                control = intCont.get('email');
            }
            if (field === "password") {
                var intCont = form.get('passwords');
                control = intCont.get('password');
            }
            if (control && control.dirty && !control.valid) {
                var messages = this.validationMessages[field];
                for (var key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    };
    Register.prototype.onFormSubmit = function () {
        var form = this.form;
        for (var field in this.formErrors) {
            this.formErrors[field] = '';
            var control = form.get(field);
            if (field === "email") {
                var intCont = form.get('emails');
                control = intCont.get('email');
            }
            if (field === "password") {
                var intCont = form.get('passwords');
                control = intCont.get('password');
            }
            if (control && !control.valid) {
                var messages = this.validationMessages[field];
                for (var key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    };
    Register.prototype.routeToLogin = function () {
        if (this.inModal) {
            this.registerEmit.emit('login');
        }
        else {
            this.router.navigate(['/login']);
        }
    };
    Register.prototype.changeModalTitle = function () {
        this.title = 'Email Verification';
    };
    Register.prototype.includeSpecialChar = function () {
        var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        return format.test(this.password.value);
    };
    Register.prototype.handlePostLogin = function (_a) {
        var _this = this;
        var tokenType = _a.token_type;
        var e_2, _b;
        if (tokenType) {
            var returnUrl = this.route.snapshot.queryParams['returnUrl'];
            if (!this.inBaPageTop) {
                // check if we have stored BI order information, if so, create a
                if (sessionStorage.getItem('GTOrderId')) {
                    var data = {};
                    try {
                        for (var BIStartPlanningProperties_2 = tslib_1.__values(BIStartPlanningProperties), BIStartPlanningProperties_2_1 = BIStartPlanningProperties_2.next(); !BIStartPlanningProperties_2_1.done; BIStartPlanningProperties_2_1 = BIStartPlanningProperties_2.next()) {
                            var key = BIStartPlanningProperties_2_1.value;
                            data[key] = sessionStorage.getItem(key);
                            sessionStorage.removeItem(key); // clear out cached values for future logins
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (BIStartPlanningProperties_2_1 && !BIStartPlanningProperties_2_1.done && (_b = BIStartPlanningProperties_2.return)) _b.call(BIStartPlanningProperties_2);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    this._registerService.createBIPlaceHolderEvent(data);
                    return;
                }
                if (returnUrl) {
                    this.router.navigateByUrl(returnUrl);
                }
                else {
                    if (this.inModal) {
                        this.registerEmit.emit('loggedIn');
                        if (!this.doNotRedirect) {
                            this.events = this._appService.getEvents();
                            this.events.subscribe(function (res) {
                                _this._events = res;
                                _this._events.forEach(function (event) {
                                    if (event.attendeeStatus === 'accept') {
                                        _this.store.dispatch(new attendeePackages.SetSelectedPackageId(Number(event.packageID)));
                                        _this.router.navigateByUrl('/pages/attendee-package-details/' + event.packageID);
                                    }
                                });
                            });
                        }
                    }
                    else {
                        this.router.navigate(['/dashboard']);
                    }
                }
            }
            else {
                if (returnUrl)
                    this.router.navigateByUrl(returnUrl);
                else
                    this.router.navigate(['/dashboard']);
                this.registerEmit.emit('closeModal');
            }
        }
    };
    Register.prototype.cancelLogin = function () {
        this.googleUser = null;
        this.facebookUser = null;
        this.amazonUser = null;
        this.modal.grouptoolsModal.hide();
    };
    Register.prototype.destroyContents = function () {
        this.modalState = 'null';
    };
    Register.prototype.openDifferentAccountModal = function () {
        this.modalState = 'differentAccount';
        this.modalTitle = 'Warning';
        this.modal.grouptoolsModal.show();
    };
    Register.prototype.openGoogleRegisterModal = function (googleUser) {
        //Show registration option
        this.modalState = 'googleRegister';
        this.modal.title = 'Google Account Registration';
        this.googleUser = googleUser;
        this.modal.grouptoolsModal.show();
    };
    Register.prototype.openAmazonRegisterModal = function (amazonUser) {
        //Show registration option
        this.modalState = 'amazonRegister';
        this.modalTitle = 'Register for GroupTools with your Amazon Account';
        this.amazonUser = amazonUser;
        this.modal.grouptoolsModal.show();
    };
    Register.prototype.openFacebookRegisterModal = function (facebookUser) {
        //Show registration option
        this.modalState = 'facebookRegister';
        this.modalTitle = 'Register for GroupTools with your Facebook Account';
        this.facebookUser = facebookUser;
        this.modal.grouptoolsModal.show();
    };
    return Register;
}());
export { Register };
