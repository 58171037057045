import { Action } from '@ngrx/store';
import { PaymentsReceivedSummaryDTO } from '../models/packages/PaymentsReceivedSummaryDTO';
import { PaymentsSentSummaryDTO } from '../models/packages/paymentsSentSummaryDTO';
import { SeparatedPaymentsDTO } from '../models/packages/SeparatedPaymentsDTO';
import { Payment } from '../models/payment';
import { SupplierMakePaymentRequest } from '../models/requests/make-payment-request';
import { SupplierMakePaymentResponse } from '../models/responses/make-payment-response';
import { AddAndAssociateCustomerRequest } from '../models/requests/add-and-associate-customer-request';
import { AddAndAssociateCustomerResponse } from '../models/responses/add-and-associate-customer-response';

export enum ActionTypes {
  // These three are caught for both LoadEventPayments and LoadPaymentsReceivedSummary
  PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData = "[Payments From Attendees Summary Component] Reload Event Payments Data",
  PaymentsFromAttendeesComponentReloadEventPaymentsData = "[Payments From Attendees Component] Reload Event Payments Data",
  PaymentsToSuppliersSummaryComponentReloadEventPaymentsData = "[Payments To Suppliers Component] Reload Event Payments Data",

  PaymentsComponentLoadEventPayments = "[Payments Component] Load Event Payments",
  PackageDetailsEffectsLoadEventPayments = "[Package Details Effects] Load Event Payments",
  PaymentEffectLoadEventPaymentsSuccess = "[Payment Effect] Load Event Payments Success",
  PaymentEffectLoadEventPaymentsFailure = "[Payment Effect] Load Event Payments Failure",

  PackageDetailsEffectsLoadPaymentsReceivedSummary = '[Package Details Effect] Load Payments Received Summary',
  PaymentEffectLoadPaymentsReceivedSummarySuccess = '[Payment Effect] Load Payments Received Summary Success',
  PaymentEffectLoadPaymentsReceivedSummaryFailure = '[Payment Effect] Load Payments Received Summary Failure',

  PackageDetailsEffectsLoadPaymentsSentSummary = '[Package Details Effect] Load Payments Sent Summary',
  PaymentEffectLoadPaymentsSentSummarySuccess = '[Payment Effect] Load Payments Sent Summary Success',
  PaymentEffectLoadPaymentsSentSummaryFailure = '[Payment Effect] Load Payments Sent Summary Failure',
  //-------------------------------------------------------------------------------------
  // This is the newer payment endpoint that is (so far) only used on the GLED page
  GLEDComponentLoadEventPaymentsNew = "[GLED Component] Load Event Payments New",
  PaymentEffectLoadEventPaymentsNewSuccess = "[Payment Effect] Load Event Payments New Success",
  PaymentEffectLoadEventPaymentsNewFailure = "[Payment Effect] Load Event Payments New Failure",

  //--------------------------------------------------------------------------------------
  // Supplier Payments Actions
  ATEDComponentMakeSupplierPayment = "[ATED Component] Make Supplier Payment",
  ATEDComponentMakeSupplierPaymentSuccess = "[Payment Effect] Make Supplier Payment Success",
  ATEDComponentMakeSupplierPaymentFailure = "[Payment Effect] Make Supplier Payment Failure",

  ATEDComponentAddAndAssociateCustomer = "[ATED Component] Add And Associate Customer",
  ATEDComponentAddAndAssociateCustomerSuccess = "[Payment Effect] Add And Associate Customer Success",
  ATEDComponentAddAndAssociateCustomerFailure = "[Payment Effect] Add And Associate Customer Failure",

  GLEDComponentMakeSupplierGLPayment = "[GLED Component] Make Supplier GL Payment",
  GLEDComponentMakeSupplierGLPaymentSuccess = "[Payment Effect] Make Supplier GL Payment Success",
  GLEDComponentMakeSupplierGLPaymentFailure = "[Payment Effect] Make Supplier GL Payment Failure",

  AuthenticationServiceLogout = "[Authentication Service] Logout",
}

//----------------------------------------------------------------
export class PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData implements Action {
  readonly type = ActionTypes.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData;
  constructor(public payload: {eventId: number}) {}
}
export class PaymentsFromAttendeesComponentReloadEventPaymentsData implements Action {
  readonly type = ActionTypes.PaymentsFromAttendeesComponentReloadEventPaymentsData;
  constructor(public payload: {eventId: number}) {}
}
export class PaymentsToSuppliersSummaryComponentReloadEventPaymentsData implements Action {
  readonly type = ActionTypes.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData;
  constructor(public payload: {eventId: number}) {}
}
//----------------------------------------------------------------
export class PaymentsComponentLoadEventPayments implements Action {
  readonly type = ActionTypes.PaymentsComponentLoadEventPayments;
  constructor(public payload: {eventId: number}) {}
}
export class PackageDetailsEffectsLoadEventPayments implements Action {
  readonly type = ActionTypes.PackageDetailsEffectsLoadEventPayments;
  constructor(public payload: {eventId: number}) {}
}
export class PaymentEffectLoadEventPaymentsSuccess implements Action {
  readonly type = ActionTypes.PaymentEffectLoadEventPaymentsSuccess;
  constructor(public payload: {separatedPayments: SeparatedPaymentsDTO}) {}
}
export class PaymentEffectLoadEventPaymentsFailure implements Action {
  readonly type = ActionTypes.PaymentEffectLoadEventPaymentsFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PackageDetailsEffectsLoadPaymentsReceivedSummary implements Action {
  readonly type = ActionTypes.PackageDetailsEffectsLoadPaymentsReceivedSummary;
  constructor(public payload: {eventId: number}) {}
}
export class PaymentEffectLoadPaymentsReceivedSummarySuccess implements Action {
  readonly type = ActionTypes.PaymentEffectLoadPaymentsReceivedSummarySuccess;
  constructor(public payload: {paymentsReceivedSummary: PaymentsReceivedSummaryDTO}) {}
}
export class PaymentEffectLoadPaymentsReceivedSummaryFailure implements Action {
  readonly type = ActionTypes.PaymentEffectLoadPaymentsReceivedSummaryFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PackageDetailsEffectsLoadPaymentsSentSummary implements Action {
  readonly type = ActionTypes.PackageDetailsEffectsLoadPaymentsSentSummary;
  constructor(public payload: {eventId: number}) {}
}
export class PaymentEffectLoadPaymentsSentSummarySuccess implements Action {
  readonly type = ActionTypes.PaymentEffectLoadPaymentsSentSummarySuccess;
  constructor(public payload: {paymentsSentSummary: PaymentsSentSummaryDTO}) {}
}
export class PaymentEffectLoadPaymentsSentSummaryFailure implements Action {
  readonly type = ActionTypes.PaymentEffectLoadPaymentsSentSummaryFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class GLEDComponentLoadEventPaymentsNew implements Action {
  readonly type = ActionTypes.GLEDComponentLoadEventPaymentsNew;
  constructor(public payload: {eventId: number}) {}
}
export class PaymentEffectLoadEventPaymentsNewSuccess implements Action {
  readonly type = ActionTypes.PaymentEffectLoadEventPaymentsNewSuccess;
  constructor(public payload: {payments: Payment[]}) {}
}
export class PaymentEffectLoadEventPaymentsNewFailure implements Action {
  readonly type = ActionTypes.PaymentEffectLoadEventPaymentsNewFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class ATEDComponentMakeSupplierPayment implements Action {
  readonly type = ActionTypes.ATEDComponentMakeSupplierPayment;
  constructor(public payload: {makePaymentRequest: SupplierMakePaymentRequest}) {}
}
export class ATEDComponentMakeSupplierPaymentSuccess implements Action {
  readonly type = ActionTypes.ATEDComponentMakeSupplierPaymentSuccess;
  constructor(public payload: {makePaymentResponse: SupplierMakePaymentResponse}) {}
}
export class ATEDComponentMakeSupplierPaymentFailure implements Action {
  readonly type = ActionTypes.ATEDComponentMakeSupplierPaymentFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class ATEDComponentAddAndAssociateCustomer implements Action {
  readonly type = ActionTypes.ATEDComponentAddAndAssociateCustomer;
  constructor(public payload: {addAndAssociateCustomerRequest: AddAndAssociateCustomerRequest}) {}
}
export class ATEDComponentAddAndAssociateCustomerSuccess implements Action {
  readonly type = ActionTypes.ATEDComponentAddAndAssociateCustomerSuccess;
  constructor(public payload: {addAndAssociateCustomerResponse: AddAndAssociateCustomerResponse}) {}
}
export class ATEDComponentAddAndAssociateCustomerFailure implements Action {
  readonly type = ActionTypes.ATEDComponentAddAndAssociateCustomerFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class GLEDComponentMakeSupplierGLPayment implements Action {
  readonly type = ActionTypes.GLEDComponentMakeSupplierGLPayment;
  constructor(public payload: {makePaymentRequest: SupplierMakePaymentRequest}) {}
}
export class GLEDComponentMakeSupplierGLPaymentSuccess implements Action {
  readonly type = ActionTypes.GLEDComponentMakeSupplierGLPaymentSuccess;
  constructor(public payload: {makePaymentResponse: SupplierMakePaymentResponse}) {}
}
export class GLEDComponentMakeSupplierGLPaymentFailure implements Action {
  readonly type = ActionTypes.GLEDComponentMakeSupplierGLPaymentFailure;
  constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class AuthenticationServiceLogout implements Action {
  readonly type = ActionTypes.AuthenticationServiceLogout;
  constructor() {}
}
//----------------------------------------------------------------

export type Actions
  = PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData
  | PaymentsFromAttendeesComponentReloadEventPaymentsData
  | PaymentsToSuppliersSummaryComponentReloadEventPaymentsData

  | PaymentsComponentLoadEventPayments
  | PackageDetailsEffectsLoadEventPayments
  | PaymentEffectLoadEventPaymentsSuccess
  | PaymentEffectLoadEventPaymentsFailure

  | PackageDetailsEffectsLoadPaymentsReceivedSummary
  | PaymentEffectLoadPaymentsReceivedSummarySuccess
  | PaymentEffectLoadPaymentsReceivedSummaryFailure

  | PackageDetailsEffectsLoadPaymentsSentSummary
  | PaymentEffectLoadPaymentsSentSummarySuccess
  | PaymentEffectLoadPaymentsSentSummaryFailure

  | GLEDComponentLoadEventPaymentsNew
  | PaymentEffectLoadEventPaymentsNewSuccess
  | PaymentEffectLoadEventPaymentsNewFailure

  | ATEDComponentMakeSupplierPayment
  | ATEDComponentMakeSupplierPaymentSuccess
  | ATEDComponentMakeSupplierPaymentFailure

  | ATEDComponentAddAndAssociateCustomer
  | ATEDComponentAddAndAssociateCustomerSuccess
  | ATEDComponentAddAndAssociateCustomerFailure

  | GLEDComponentMakeSupplierGLPayment
  | GLEDComponentMakeSupplierGLPaymentSuccess
  | GLEDComponentMakeSupplierGLPaymentFailure

  | AuthenticationServiceLogout
;
