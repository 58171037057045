import * as tslib_1 from "tslib";
import { AppStateService } from '../helpers/appState.service';
import { ElementRef, EventEmitter, NgZone, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'style-loader!./login.component.scss';
import { AuthService, LoginPerson, BIStartPlanning, BIStartPlanningProperties } from '../../authentication/authentication.service';
import { GrouptoolsModalComponent } from 'app/widgets/grouptools-modal/grouptools-modal.component';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as attendeePackages from 'app/store/actions/attendeePackages';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(fb, _authService, router, route, store, zone, _appService) {
        var _this = this;
        this.fb = fb;
        this._authService = _authService;
        this.router = router;
        this.route = route;
        this.store = store;
        this.zone = zone;
        this._appService = _appService;
        this.inModal = false;
        this.doNotRedirect = false;
        this.omitTitle = false;
        this.emailPrefill = '';
        this.inBaPageTop = false;
        this.formSubmitted = false;
        this.modalState = 'null';
        this.LOGOICON = require("assets/logo/GroupTools-Logo-Icon.svg");
        this.EMAIL_REGX = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
        this.modalTitle = 'Coming Soon!';
        this.loginError = true;
        this.loginEmit = new EventEmitter();
        this.differentAccount = new EventEmitter();
        this.googleRegisterEmit = new EventEmitter();
        this.amazonRegisterEmit = new EventEmitter();
        this.facebookRegisterEmit = new EventEmitter();
        this.unsubscribe$ = new Subject();
        this.formFields = {
            'email': ['', Validators.compose([Validators.required, Validators.pattern(this.EMAIL_REGX)])],
            'password': ['', [Validators.required]]
        };
        this.formErrors = {
            'email': '',
            'password': ''
        };
        this.validationMessages = {
            'email': {
                'required': 'Email is required.',
                'pattern': 'Email must be in the proper format (ex. example@example.com).'
            },
            'password': {
                'required': 'Password is required'
            }
        };
        this.form = this.fb.group(this.formFields);
        this.email = this.form.controls['email'];
        this.password = this.form.controls['password'];
        this.loggedInStatu$ = this.store.select(fromRoot.getLoginStatus);
        this.loggedInEmail$ = this.store.select(fromRoot.getUserEmail);
        this.form.valueChanges.subscribe(function (change) { return _this.onValueChanged(change); });
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        // subscribe to loggedIn and loggedInEmail; if both exist and the email is same
        // as the prefill, check if they came from a BI email to create an event
        this.loggedInStatu$.pipe(takeUntil(this.unsubscribe$)).subscribe(function (loggedIn) {
            _this.loggedInEmail$.pipe(takeUntil(_this.unsubscribe$)).subscribe(function (email) {
                if (loggedIn && _this.emailPrefill && email &&
                    sessionStorage.getItem('GTEmail') &&
                    email.toLocaleLowerCase() === _this.emailPrefill.toLocaleLowerCase()) {
                    _this.createBIEvent();
                }
            });
        });
    };
    LoginComponent.prototype.ngOnChanges = function (changes) {
        if (changes.emailPrefill) {
            if (sessionStorage.getItem('GTEmail')) {
                this.emailPrefill = sessionStorage.getItem('GTEmail');
            }
            if (this.emailPrefill) {
                this.email.setValue(this.emailPrefill);
                this.email.disable();
            }
        }
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
    };
    LoginComponent.prototype.navToRegister = function () {
        if (this.inModal) {
            this.loginEmit.emit('register');
        }
        else {
            this.router.navigate(['/register']);
        }
    };
    LoginComponent.prototype.forgotPassword = function () {
        var _this = this;
        this.formSubmitted = true;
        if (!this.email.valid) {
            this.onFormSubmit('forgotPass');
        }
        else {
            this._authService.forgotPassword(this.email.value).subscribe(function (_a) {
                var res = _a.Obj;
                if (_this.inModal) {
                    _this.loginEmit.emit('passwordReset');
                }
                else {
                    _this.modalState = 'passwordReset';
                    _this.modalTitle = 'Forgot Password';
                    _this.modal.grouptoolsModal.show();
                }
            });
        }
    };
    LoginComponent.prototype.onValueChanged = function (data) {
        if (!this.form) {
            return;
        }
        var form = this.form;
        for (var field in this.formErrors) {
            this.formErrors[field] = '';
            var control = form.get(field);
            if (control && control.dirty && !control.valid) {
                var messages = this.validationMessages[field];
                for (var key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    };
    LoginComponent.prototype.onFormSubmit = function (forgotPass) {
        this.formSubmitted = true;
        var form = this.form;
        if (forgotPass) {
            this.password.setValue('');
            for (var field in this.formErrors) {
                this.formErrors[field] = '';
            }
            var control = form.get('email');
            if (control && !control.valid) {
                var messages = this.validationMessages['email'];
                for (var key in control.errors) {
                    this.formErrors['email'] += messages[key] + ' ';
                }
            }
        }
        else {
            for (var field in this.formErrors) {
                this.formErrors[field] = '';
                var control = form.get(field);
                if (control && !control.valid) {
                    var messages = this.validationMessages[field];
                    for (var key in control.errors) {
                        this.formErrors[field] += messages[key] + ' ';
                    }
                }
            }
        }
    };
    LoginComponent.prototype.onSubmit = function (values) {
        var _this = this;
        //VALID USER ENTRY IN LOGIN
        if (this.form.valid) {
            this.loginPerson = new LoginPerson(this.email.value, this.password.value);
            this._authService.login(this.loginPerson).subscribe(function (token) {
                //console.log(token);
                _this._authService.setLocalStorage(token);
                _this.handlePostLogin(token);
                _this.loginError = true;
            }, function (error) {
                _this.loginError = false;
                if (error.status > 0) {
                    console.log(error.error.error_description);
                }
                else {
                    console.log('Server is unresponsive.');
                }
            });
            //INVALID USER ENTRY IN LOGIN
        }
        else {
            this.onFormSubmit();
        }
    };
    LoginComponent.prototype.cancelLogin = function () {
        this.googleUser = null;
        this.facebookUser = null;
        this.amazonUser = null;
        this.modal.grouptoolsModal.hide();
    };
    LoginComponent.prototype.handlePostLogin = function (_a) {
        var _this = this;
        var tokenType = _a.token_type;
        if (tokenType) {
            var returnUrl = this.route.snapshot.queryParams['returnUrl'];
            if (!this.inBaPageTop) {
                // check if we have stored BI order information, if so, create a
                if (sessionStorage.getItem('GTOrderId')) {
                    this.createBIEvent();
                }
                else if (returnUrl) {
                    this.router.navigateByUrl(returnUrl);
                }
                else {
                    if (this.inModal) {
                        this.loginEmit.emit('loggedIn');
                        if (!this.doNotRedirect) {
                            this.events = this._appService.getEvents();
                            this.events.subscribe(function (res) {
                                _this._events = res;
                                _this._events.forEach(function (event) {
                                    if (event.attendeeStatus === 'accept') {
                                        _this.store.dispatch(new attendeePackages.SetSelectedPackageId(Number(event.packageID)));
                                        _this.router.navigateByUrl('/pages/attendee-package-details/' + event.packageID);
                                    }
                                });
                            });
                        }
                    }
                    else {
                        this.navigateToLastViewedEvent();
                    }
                }
            }
            else {
                if (returnUrl)
                    this.router.navigateByUrl(returnUrl);
                else
                    this.navigateToLastViewedEvent();
                this.loginEmit.emit('closeModal');
            }
        }
    };
    LoginComponent.prototype.navigateToLastViewedEvent = function () {
        var _this = this;
        this._authService.getUserInfo().subscribe(function (_a) {
            var userInfo = _a.Obj;
            if (!userInfo || !userInfo.Preferences || !userInfo.Preferences.userStats) {
                _this.router.navigate(['/dashboard']);
                return;
            }
            var stats = userInfo.Preferences.userStats;
            if (!!stats.lastViewedOwnedEventDateTime && stats.lastViewedOwnedEventId > 0
                && !!stats.lastViewedInvitedEventDateTime && stats.lastViewedInvitedEventId > 0) {
                var ownedDateTime = new Date(Date.parse(stats.lastViewedOwnedEventDateTime));
                var invitedDateTime = new Date(Date.parse(stats.lastViewedInvitedEventDateTime));
                if (invitedDateTime > ownedDateTime) {
                    // navigate to invited event id as attendee
                    _this.router.navigateByUrl('/pages/attendee-package-details/' + stats.lastViewedInvitedEventId);
                    return;
                }
                else {
                    // navigate to owned event id as leader
                    _this.router.navigateByUrl('pages/package-details/' + stats.lastViewedOwnedEventId);
                    return;
                }
            }
            if (!!stats.lastViewedOwnedEventDateTime && stats.lastViewedOwnedEventId > 0) {
                // navigate to owned event id as leader
                _this.router.navigateByUrl('pages/package-details/' + stats.lastViewedOwnedEventId);
                return;
            }
            if (!!stats.lastViewedInvitedEventDateTime && stats.lastViewedInvitedEventId > 0) {
                // navigate to invited event id as attendee
                _this.router.navigateByUrl('/pages/attendee-package-details/' + stats.lastViewedInvitedEventId);
                return;
            }
            // There is no last viewed event as either leader or attendee, default to navigating to dashboard
            _this.router.navigate(['/pages/create-event']);
            return;
        });
    };
    LoginComponent.prototype.createBIEvent = function () {
        var e_1, _a;
        var data = {};
        try {
            for (var BIStartPlanningProperties_1 = tslib_1.__values(BIStartPlanningProperties), BIStartPlanningProperties_1_1 = BIStartPlanningProperties_1.next(); !BIStartPlanningProperties_1_1.done; BIStartPlanningProperties_1_1 = BIStartPlanningProperties_1.next()) {
                var key = BIStartPlanningProperties_1_1.value;
                data[key] = sessionStorage.getItem(key);
                sessionStorage.removeItem(key); // clear out cached values for future logins
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (BIStartPlanningProperties_1_1 && !BIStartPlanningProperties_1_1.done && (_a = BIStartPlanningProperties_1.return)) _a.call(BIStartPlanningProperties_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this._authService.createBIPlaceHolderEvent(data);
    };
    LoginComponent.prototype.renderPlaceholder = function () {
        this.modalState = 'placeholder';
        this.modal.grouptoolsModal.show();
    };
    LoginComponent.prototype.destroyContents = function () {
        this.modalState = 'null';
    };
    LoginComponent.prototype.openDifferentAccountModal = function () {
        this.modalState = 'differentAccount';
        this.modalTitle = 'Warning';
        this.modal.grouptoolsModal.show();
    };
    LoginComponent.prototype.openGoogleRegisterModal = function (googleUser) {
        //Show registration option
        this.modalState = 'googleRegister';
        this.modal.title = 'Google Account Registration';
        this.googleUser = googleUser;
        this.modal.grouptoolsModal.show();
    };
    LoginComponent.prototype.openAmazonRegisterModal = function (amazonUser) {
        //Show registration option
        this.modalState = 'amazonRegister';
        this.modal.title = 'Register for GroupTools with your Amazon Account';
        this.amazonUser = amazonUser;
        this.modal.grouptoolsModal.show();
    };
    LoginComponent.prototype.openFacebookRegisterModal = function (facebookUser) {
        //Show registration option
        this.modalState = 'facebookRegister';
        this.modal.title = 'Register for GroupTools with your Facebook Account';
        this.facebookUser = facebookUser;
        this.modal.grouptoolsModal.show();
    };
    return LoginComponent;
}());
export { LoginComponent };
