import * as tslib_1 from "tslib";
import * as userActions from '../actions/user';
var initialState = {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    photoUrl: '',
    paymentMethod: '',
    alternamePaymentMethod: ''
};
// initialize state to an empty array
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case userActions.LOAD_GROUP_LEADER_PROFILE_OK:
            {
                return action.payload;
            }
        case userActions.LOGOUT:
            {
                return tslib_1.__assign({}, initialState);
            }
        default:
            {
                return state;
            }
    }
}
