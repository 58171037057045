import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { EventManager } from '@angular/platform-browser';

declare var window: any;
@Injectable({
    providedIn: 'root'
})
export class GroupToolsSize {

    // screen width constants
    public SMALL_WIDTH: number = 576;
    public MEDIUM_WIDTH: number = 768;
    public LARGE_WIDTH: number = 1024;
    public X_LARGE_WIDTH: number = 1400;

    public get windowWidth$(): Observable<number> {
        return this.widthSubject.asObservable();
    }

    public get windowHeight$(): Observable<number> {
        return this.heightSubject.asObservable();
    }

    private widthSubject: BehaviorSubject<number>;
    private heightSubject: BehaviorSubject<number>;

    constructor(
        private eventManager: EventManager
    ) {
        this.heightSubject = new BehaviorSubject<number>(window.innerHeight);
        this.widthSubject = new BehaviorSubject<number>(window.innerWidth);
        this.eventManager.addGlobalEventListener('window', 'resize', this.resizeHandler.bind(this));
    }

    private resizeHandler(event: UIEvent) {
        const target = <Window> event.target;
        this.heightSubject.next(target.innerHeight);
        this.widthSubject.next(target.innerWidth);
    }

}
