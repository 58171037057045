export var LOAD_EVENT_ITEMS = '[Event] Load Event Items';
export var LOAD_EVENT_ITEMS_OK = '[Event] Load Event Items Ok';
export var ASSIGN_ATTENDEES_TO_ITEMS_PRE_AUTOSAVE_CHANGE = '[Event] Assign Event Items Pre-Autosave Change';
export var ASSIGN_ATTENDEES_TO_ITEMS = '[Event] Assign Attendees To Items';
export var ASSIGN_ATTENDEES_TO_ITEMS_OK = '[Event] Assign Attendees To Items Ok';
export var ASSIGN_ATTENDEES_TO_ITEMS_ERROR = '[Event] Assign Attendees To Items Error';
export var ASSIGN_ATTENDEES_TO_ITEMS_AUTOSAVE_CANCELLED = '[Event] Assign Attendees To Items Autosave Cancelled';
export var ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY = '[Event] Assign Attendees To Items Automatically';
export var ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_OK = '[Event] Assign Attendees To Items Automatically Ok';
export var ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_ERROR = '[Event] Assign Attendees To Items Automatically Error';
var LoadEventItems = /** @class */ (function () {
    function LoadEventItems(eventId, activityId, optionId) {
        this.eventId = eventId;
        this.activityId = activityId;
        this.optionId = optionId;
        this.type = LOAD_EVENT_ITEMS;
    }
    return LoadEventItems;
}());
export { LoadEventItems };
var LoadEventItemsOk = /** @class */ (function () {
    function LoadEventItemsOk(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_ITEMS_OK;
    }
    return LoadEventItemsOk;
}());
export { LoadEventItemsOk };
var AssignAttendeesToItemsPreAutosaveChange = /** @class */ (function () {
    function AssignAttendeesToItemsPreAutosaveChange() {
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_PRE_AUTOSAVE_CHANGE;
    }
    return AssignAttendeesToItemsPreAutosaveChange;
}());
export { AssignAttendeesToItemsPreAutosaveChange };
var AssignAttendeesToItemsAutosaveCancelled = /** @class */ (function () {
    function AssignAttendeesToItemsAutosaveCancelled() {
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOSAVE_CANCELLED;
    }
    return AssignAttendeesToItemsAutosaveCancelled;
}());
export { AssignAttendeesToItemsAutosaveCancelled };
var AssignAttendeesToItems = /** @class */ (function () {
    function AssignAttendeesToItems(eventId, eventItems) {
        this.eventId = eventId;
        this.eventItems = eventItems;
        this.type = ASSIGN_ATTENDEES_TO_ITEMS;
    }
    return AssignAttendeesToItems;
}());
export { AssignAttendeesToItems };
var AssignAttendeesToItemsOk = /** @class */ (function () {
    function AssignAttendeesToItemsOk(payload) {
        this.payload = payload;
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_OK;
    }
    return AssignAttendeesToItemsOk;
}());
export { AssignAttendeesToItemsOk };
var AssignAttendeesToItemsError = /** @class */ (function () {
    function AssignAttendeesToItemsError(payload) {
        this.payload = payload;
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_ERROR;
    } // TODO: figure out what type this error should be! Maybe a string or custom Error object?
    return AssignAttendeesToItemsError;
}());
export { AssignAttendeesToItemsError };
var AssignAttendeesToItemsAutomatically = /** @class */ (function () {
    function AssignAttendeesToItemsAutomatically(eventId) {
        this.eventId = eventId;
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY;
    }
    return AssignAttendeesToItemsAutomatically;
}());
export { AssignAttendeesToItemsAutomatically };
var AssignAttendeesToItemsAutomaticallyOk = /** @class */ (function () {
    function AssignAttendeesToItemsAutomaticallyOk(payload) {
        this.payload = payload;
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_OK;
    }
    return AssignAttendeesToItemsAutomaticallyOk;
}());
export { AssignAttendeesToItemsAutomaticallyOk };
var AssignAttendeesToItemsAutomaticallyError = /** @class */ (function () {
    function AssignAttendeesToItemsAutomaticallyError(payload) {
        this.payload = payload;
        this.type = ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY_ERROR;
    } // TODO: figure out what type this error should be! Maybe a string or custom Error object?
    return AssignAttendeesToItemsAutomaticallyError;
}());
export { AssignAttendeesToItemsAutomaticallyError };
