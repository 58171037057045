import { Component, OnInit } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as modalActions from 'app/store/actions/modal.action';
import * as eventActions from 'app/store/actions/event.action';
import { takeUntil, tap } from 'rxjs/operators';
import { Event, EventWithEmail } from 'app/store/models/event';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { Modal, ModalSize } from 'app/store/models/modal';
import { Actions, ofType } from '@ngrx/effects';
import { GroupToolsSuccessModalConfig, GroupToolsSuccessModal } from '../grouptools-success-modal/grouptools-success-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-event-to-other',
  templateUrl: './assign-event-to-other.component.html',
  styleUrls: ['./assign-event-to-other.component.scss']
})
export class AssignEventToOtherComponent implements OnInit {

  public leaderEmail: string;
  public leaderFirstName: string;
  public leaderLastName: string;
  public selectedEvent$: Observable<Event>;
  public badEmail: boolean = false;
  public errorMessage: string;
  public working: boolean = false;
  public assignEventForm: FormGroup;

  // tslint:disable-next-line:max-line-length
  public templateInfoText: string = 'Create an event with dates not set for the given user. If you want to set the dates yourself, then create an event from the template, set the dates and assign the event.';
  public eventInfoText: string = 'Assign control of this event to another user.';
  public isTemplateEvent: boolean;
  public formSubmitted: boolean = false;
  private unsubscribe$: Subject<Event> = new Subject<Event>();

  public NAME_REGX: string = "^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ \',.-]+$";
  public EMAIL_REGX: string = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private router: Router,
    private eventService: EventDetailsService,
    private fb: FormBuilder
  ) {
  }

  public assignEventFormErrors: any = {
    'FirstName': '',
    'LastName': '',
    'Email': ''
};

assignEventFormValidationMessages = {
    'FirstName': {
        'pattern': 'First Name contains invalid characters.'
    },
    'LastName': {
        'pattern': 'Last Name contains invalid characters.'
    },
    'Email': {
        'required': 'Email is required.',
        'pattern': 'Invalid email.'
    }
};

assignEventFormFields = {
    FirstName: [null, [Validators.pattern(this.NAME_REGX)]],
    LastName: [null, [Validators.pattern(this.NAME_REGX)]],
    Email: [null, [Validators.required, Validators.pattern(this.EMAIL_REGX)]],
};

  ngOnInit() {
    this.actions$.pipe(
      ofType(eventActions.ASSIGN_EVENT_ERROR),
      takeUntil(this.unsubscribe$)
    ).subscribe(error => {
      this.badEmail = true;
      const anyError = error as any;
      this.errorMessage = anyError.payload.errorMessage;
      console.log('error', error);
      this.working = false;
    });

    this.selectedEvent$ = this.store.select(fromRoot.getSelectedEvent).pipe(
      takeUntil(this.unsubscribe$),
      tap(event => console.log('event', event)),
      tap(event => {
        this.isTemplateEvent = (event.status && event.status.toLowerCase() === 'template');
      })
    );

    this.actions$.pipe(
      ofType(eventActions.ASSIGN_EVENT_OK),
      takeUntil(this.unsubscribe$)
    ).subscribe((event: eventActions.AssignEventOk) => {
      const config = <Modal>{
        ...GroupToolsSuccessModalConfig,
        title: 'Event Created',
        componentWithOptions: {
          component: GroupToolsSuccessModal,
          size: ModalSize.Small,
          options: {
            headerText: 'Success',
            type: 'success',
            bodyText: `'${event.payload.name}' has been successfully created and assigned to '${event.payload.ownerName}'`,
            buttons: {
              primary: {
                text: `Return to ${this.isTemplateEvent ? 'Template' : 'Dashboard'}`,
                action: () => {
                  this.store.dispatch(new modalActions.CloseModal());
                  if (!this.isTemplateEvent) {
                    this.router.navigateByUrl('dashboard');
                  }
                }
              }
            }
          }
        }
      };
      this.store.dispatch(new modalActions.OpenModal(config));
      this.working = false;
    });

    this.buildAssignEventForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  assignEvent(event: Event) {
    if (!this.assignEventForm.valid) {
      this.onAssignEventFormValueChanged();
      return;
    };
    
    this.working = true;

    const firstName = this.assignEventForm.get('FirstName').value as string;
    const lastName = this.assignEventForm.get('LastName').value as string;
    const email = this.assignEventForm.get('Email').value as string;

    const assignEvent: EventWithEmail = {
      ...event,
      email: email,
      firstName: firstName,
      lastName: lastName
    }

    if (event.status.toLowerCase() === 'template') {
      this.store.dispatch(new eventActions.CloneAndAssignEvent(assignEvent));
    } else {
      this.store.dispatch(new eventActions.AssignEvent(assignEvent));
    }
  }

  close() {
    this.store.dispatch(new modalActions.CloseModal());
  }

  buildAssignEventForm(): void {
    this.assignEventForm = this.fb.group(this.assignEventFormFields);

    this.assignEventForm.valueChanges
        .subscribe(data => this.onAssignEventFormValueChanged(data));

    this.onAssignEventFormValueChanged(); //(re)set validation messages now
  }

  onAssignEventFormValueChanged(data?: any) {
    if (!this.assignEventForm) { return; }
    if (this.formSubmitted) {
        this.onFormSubmit();
        return;
    }
    const form = this.assignEventForm;

    for (const field in this.assignEventFormErrors) {
        if (this.assignEventFormErrors.hasOwnProperty(field)) {
            this.assignEventFormErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.assignEventFormValidationMessages[field];
                for (const key in control.errors) {
                    if (control.errors.hasOwnProperty(key)) {
                        this.assignEventFormErrors[field] += messages[key] + ' ';
                    }
                }
            }
        }
    }
  }

  onFormSubmit() {
    const form = this.assignEventForm;

    for (const field in this.assignEventFormErrors) {
        if (this.assignEventFormErrors.hasOwnProperty(field)) {
            this.assignEventFormErrors[field] = '';
            const control = form.get(field);

            if (control && !control.valid) {
                const messages = this.assignEventFormValidationMessages[field];
                for (const key in control.errors) {
                    if (control.errors.hasOwnProperty(key)) {
                        this.assignEventFormErrors[field] += messages[key] + ' ';
                    }
                }
            }
        }
    }
  }
}

export const AssignEventToOtherConfig: Modal = {
  title: 'Assign Event',
  size: ModalSize.Small,
  isFullScreenMobile: true,
  componentWithOptions: {
    component: AssignEventToOtherComponent
  }
};
