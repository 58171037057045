// This is from the EventDetailsService in GroupTix, which is located at: 
// import { EventDetailsService } from 'projects/gt-distribution-app/src/app/store/services/event-details.service';

import { Injectable } from '@angular/core';
import { URLConfig } from 'app/pages/helpers/config.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GTCollectionPayload, GTCollectionResponse, GTResponse, handleGTCollectionResponse, handleGTResponse, handleHttpBlobResponse } from 'app/store/models/gtResponse';
import { EventItem } from 'app/store/models/eventItem';
import { AttendeeUpsertRequest } from 'app/store/models/requests/attendee-upsert-request';
import { EventItemAssignment } from 'app/store/models/eventItemState';
import { ShowInfo } from 'app/store/models/show-info';
import { TicketReadyNotificationResponse } from "app/store/models/responses/ticket-ready-notification-response";
import { GTFile } from 'app/store/models/gt-file';
import { EventAndActivityResponse } from 'app/store/models/responses/event-and-activity-response';
import { DownloadTicketsRequest, TicketFormat } from 'app/store/models/requests/download-tickets-request';
import { CsvInJson } from 'app/store/models/requests/csv-in-json';
import { InviteEventAttendeesRequest } from '../models/requests/invite-event-attendees-request';
import { AddPublicAttendeeResponse } from '../models/responses/add-public-attendee-response';
import { UpsertAttendeesRequest } from '../models/requests/upsert-attendees-request';


@Injectable()
export class GroupTixService {

    public constructor(
        private http: HttpClient,
        private URLs: URLConfig
    ) { }

    public retrieveShowList(): Observable<ShowInfo[]> {
        return this.http.get<GTResponse<ShowInfo[]>>(this.URLs._getShowList()).pipe(
            handleGTResponse(),
        );
    }

    public notifyAttendeesTicketsReady(eventId: number, eventItemIds: number[]): Observable<GTCollectionPayload<TicketReadyNotificationResponse>> {
        return this.http.post<GTCollectionResponse<TicketReadyNotificationResponse>>(this.URLs._notifyAttendeesTicketsReady(eventId), eventItemIds).pipe(
            handleGTCollectionResponse(),
        );
    }

    public groupLeaderDownloadTickets(eventId: number, eventItemIds: number[], ticketFormat: TicketFormat): Observable<GTFile> {

        const requestBody = {
            ticketFormat: ticketFormat,
            eventItemIds: eventItemIds,
            eventId: eventId,
        } as DownloadTicketsRequest;

        return this.http.post(this.URLs._downloadTickets(), requestBody, {
            // reportProgress: true,
            observe: 'response',
            responseType: 'blob',
        }).pipe(
            handleHttpBlobResponse("Tickets"), // The passed in string is the default file name, in case the header with the filename doesn't work
        );
    }

    public groupLeaderDownloadAllTickets(eventId: number, ticketFormat: TicketFormat): Observable<GTFile> {

        const requestBody = {
            ticketFormat: ticketFormat,
            eventItemIds: [],
            eventId: eventId,
        } as DownloadTicketsRequest;

        return this.http.post(this.URLs._downloadAllTickets(), requestBody, {
            // reportProgress: true,
            observe: 'response',
            responseType: 'blob',
        }).pipe(
            handleHttpBlobResponse("Tickets"), // The passed in string is the default file name, in case the header with the filename doesn't work
        );
    }

    public groupLeaderDownloadManifest(eventId: number): Observable<GTFile> {
        return this.http.get(this.URLs._downloadManifest(eventId), {
            // reportProgress: true,
            observe: 'response',
            responseType: 'blob',
        }).pipe(
            handleHttpBlobResponse("Manifest"),
        );
    }

    public getDistributionAttendees(eventId: number): Observable<AttendeeUpsertRequest[]> {
        return this.http.get<GTResponse<AttendeeUpsertRequest[]>>(this.URLs._getAttendeesEim(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public getDistributionEventAndActivity(eventId: number): Observable<EventAndActivityResponse> {
        return this.http.get<GTResponse<EventAndActivityResponse>>(this.URLs._getEventAndActivity(eventId)).pipe(
            handleGTResponse(),
        )
    }

    public upsertAttendees(attendees: UpsertAttendeesRequest, eventId: number): Observable<GTCollectionPayload<AttendeeUpsertRequest>> {
        return this.http.post<GTCollectionResponse<AttendeeUpsertRequest>>(this.URLs._upsertAttendees(eventId), attendees).pipe(
            handleGTCollectionResponse(),
        );
    }

    public publicEventAddAttendees(attendee: AttendeeUpsertRequest, eventId: number): Observable<AddPublicAttendeeResponse> {
        return this.http.post<GTResponse<AddPublicAttendeeResponse>>(this.URLs._publicEventAddAttendees(eventId), attendee).pipe(
            handleGTResponse(),
        );
    }

    public deleteAttendee(eventId: number, eventAttendeeId: number): Observable<number[]> {
        // This used to be http.delete method, but DELETE requests are blocked by shubert
        return this.http.post<GTResponse<number[]>>(this.URLs._deleteAttendee(eventId, eventAttendeeId), {}).pipe(
            handleGTResponse(),
        );
    }

    public parseAttendeesFromCsvString(csvString: string, eventId: number) {
        return this.http.post<GTResponse<AttendeeUpsertRequest[]>>(this.URLs._parseAttendeesFromCsv(eventId), csvString).pipe(
            handleGTResponse(),
        );
    }

    public parseAttendeesFromCsvStringInJson(csvInJson: CsvInJson, eventId: number) {
        return this.http.post<GTResponse<AttendeeUpsertRequest[]>>(this.URLs._parseAttendeesFromCsvInJson(eventId), csvInJson).pipe(
            handleGTResponse(),
        );
    }

    public inviteEventAttendees(inviteEventAttendeesRequest: InviteEventAttendeesRequest): Observable<GTCollectionPayload<number>> {
        return this.http.post<GTCollectionResponse<number>>(this.URLs._inviteEventAttendees(), inviteEventAttendeesRequest).pipe(
            handleGTCollectionResponse(),
        );
    }
}