/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotel-planner-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hotel-planner-modal.component";
import * as i3 from "@ngrx/store";
var styles_HotelPlannerModalComponent = [i0.styles];
var RenderType_HotelPlannerModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HotelPlannerModalComponent, data: {} });
export { RenderType_HotelPlannerModalComponent as RenderType_HotelPlannerModalComponent };
export function View_HotelPlannerModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-xs-12 hotel-planner-logo centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "hotel-planner-logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "order-confirm-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Listed among the top 50 travel sites in the world and ranked number one in group travel, HotelPlanner.com now receives over 5 million unique visitors each month."])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GroupTools has partnered with HotelPlanner.com to get you the best group rates for your event. Once your rooms have been booked, simply add them to your event and GroupTools will take care of the rest."])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "col-xs-12 centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-primary form-control hotel-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bookHotelLessRoom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["8 Rooms or Less, Continue Booking"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "col-xs-12 centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "btn btn-primary form-control hotel-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bookHotelMoreRoom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["9 Rooms or More, Continue Booking"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hotelPlannerLogo; _ck(_v, 4, 0, currVal_0); }); }
export function View_HotelPlannerModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hotel-planner-modal", [], null, null, null, View_HotelPlannerModalComponent_0, RenderType_HotelPlannerModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.HotelPlannerModalComponent, [i3.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HotelPlannerModalComponentNgFactory = i1.ɵccf("hotel-planner-modal", i2.HotelPlannerModalComponent, View_HotelPlannerModalComponent_Host_0, {}, {}, []);
export { HotelPlannerModalComponentNgFactory as HotelPlannerModalComponentNgFactory };
