import { Component } from "@angular/core";
import { NgxConfigureService } from "ngx-configure";
import { select, Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as PDActions from '../store/actions/packageDetails.actions';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from "rxjs";
import { SupplierDTO } from "app/store/models/packages/SupplierDTO";

@Component({
    selector: 'not-supported',
    templateUrl: 'not-supported.component.html',
    styleUrls: ['./not-supported.component.scss']
})
export class NotSupportedComponent {
    public LOGO = require(`assets/logo/GroupTools-Logo.svg`);
    public errorImage = require(`assets/img/gt-error-faces.png`);
    public currentYear: number = (new Date()).getFullYear();;
    
    suppliers$: Observable<SupplierDTO[]>;
    public unsubscribe$: Subject<void> = new Subject<void>();
    
    constructor(private configService: NgxConfigureService, private store: Store<fromRoot.State>) {}

    ngOnInit() {
        this.store.dispatch(new PDActions.PDEffectsLoadSuppliers());

        this.suppliers$ = this.store.pipe(select(PackageDetailsSelectors.getSuppliers));
        this.suppliers$.pipe(takeUntil(this.unsubscribe$)).subscribe(suppliers => {
            // Change the logo based on the hostname.
            const hostname = window.location.hostname;

            const supplier = suppliers.filter(s => s.Url == hostname);

            if (supplier.length > 0 && supplier[0].LogoImage != undefined && supplier[0].LogoImage != '') {
                let fileName = supplier[0].LogoImage;
                this.LOGO = `assets/logo/` + fileName;
            }
            else {
                this.LOGO = `assets/logo/GroupTools-Logo.svg`; 
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}