import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionBase } from 'app/pages/surveys/survey/components/survey-body/questions/question-bases/question-base';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: QuestionBase<any>[] ) {
    let group: any = {};

    questions.forEach(question => {
      group[question.questionId] = new FormControl(question.response || '');
    });
    return new FormGroup(group);
  }
}