import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { GledOverviewComponent } from './gled-overview/gled-overview.component';
import { GroupLeaderEventDetailsComponent } from './group-leader-event-details.component';
import { GledAttendeesComponent } from './gled-attendees/gled-attendees.component';
import { GledInvitationsComponent } from './gled-invitations/gled-invitations.component';
import { GledPaymentsComponent } from './gled-payments/gled-payments.component';
import { GledSurveysComponent } from './gled-surveys/gled-surveys.component';
import { GledFormsAndAttachmentsComponent } from './gled-forms-and-attachments/gled-forms-and-attachments.component';
import { WidgetsModule } from '../../widgets/widgets.module';
import { GledEventDetailsComponent } from './gled-event-details/gled-event-details.component';
import { GledAddActivityComponent } from './gled-add-activity/gled-add-activity.component';
import { GledActivityDetailsComponent } from './gled-activity-details/gled-activity-details.component';
import { GledAddActivityShowComponent } from './gled-add-activity/gled-add-activity-show/gled-add-activity-show.component';
import { GledAddActivityTransportationComponent } from './gled-add-activity/gled-add-activity-transportation/gled-add-activity-transportation.component';
import { GledAddActivityLodgingComponent } from './gled-add-activity/gled-add-activity-lodging/gled-add-activity-lodging.component';
import { GledAddActivityRestaurantComponent } from './gled-add-activity/gled-add-activity-restaurant/gled-add-activity-restaurant.component';
import { GledAddActivityOtherComponent } from './gled-add-activity/gled-add-activity-other/gled-add-activity-other.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { GledEventDetailsEditComponent } from './gled-event-details-edit/gled-event-details-edit.component';
import { GledActivityDetailsEditComponent } from './gled-activity-details-edit/gled-activity-details-edit.component';
import { GledAttendeesAndSeatLocationsComponent } from './gled-attendees-and-seat-locations/gled-attendees-and-seat-locations.component';


@NgModule({
  imports: [
    CommonModule,
    WidgetsModule,
    ReactiveFormsModule,
    NgbModule,
    GooglePlaceModule,
    GTNgaModule
  ],
  declarations: [
    GroupLeaderEventDetailsComponent,
    GledOverviewComponent,
    GledAttendeesComponent,
    GledInvitationsComponent,
    GledPaymentsComponent,
    GledSurveysComponent,
    GledFormsAndAttachmentsComponent,
    GledEventDetailsComponent,
    GledActivityDetailsComponent,
    GledAddActivityComponent,
    GledAddActivityShowComponent,
    GledAddActivityTransportationComponent,
    GledAddActivityLodgingComponent,
    GledAddActivityRestaurantComponent,
    GledAddActivityOtherComponent,
    GledEventDetailsEditComponent,
    GledActivityDetailsEditComponent,
    GledAttendeesAndSeatLocationsComponent
  ]
})
export class GroupLeaderEventDetailsModule { }
