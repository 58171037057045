import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[blacklistStrings]',
  providers: [{provide: NG_VALIDATORS, useExisting: BlacklistStringsValidator, multi: true}]
})
export class BlacklistStringsValidator implements Validator {
  @Input('blacklist') blacklist: string[];

  public validate(control: AbstractControl): {[key: string]: any} | null {
    return blacklistStringValidator(this.blacklist);
  }

  public setBlacklist(list: string[]) {
    this.blacklist = list;
  }

}

export function blacklistStringValidator(blacklist: string[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return blacklist.indexOf(control.value) === -1 ? null :
      { 'blacklistedString': { value: control.value }};
  }
}
