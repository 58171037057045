import * as tslib_1 from "tslib";
import { QuestionBase } from "app/pages/surveys/survey/components/survey-body/questions/question-bases/question-base";
var FreeFormQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(FreeFormQuestion, _super);
    function FreeFormQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.questionType = 'freeForm';
        return _this;
    }
    return FreeFormQuestion;
}(QuestionBase));
export { FreeFormQuestion };
