import { tap } from 'rxjs/operators';
import { URLConfig } from '../helpers/config.service';
import { DatePipe } from '@angular/common';
import { SecurityContext } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { handleGTResponse } from 'app/store/models/gtResponse';
var MessagesService = /** @class */ (function () {
    function MessagesService(http, URLs, sanitizer) {
        this.http = http;
        this.URLs = URLs;
        this.sanitizer = sanitizer;
        this.messageOptions = {
            hideDate: true,
            multiSelectMode: true,
            hideMenu: false,
            columns: [{
                    fieldName: 'CreatedAt',
                    label: 'Date Sent',
                    pipe: new DatePipe('en-US')
                },
                {
                    fieldName: 'SenderName',
                    label: 'Sent By'
                },
                {
                    fieldName: 'Type',
                    label: 'Type'
                },
                {
                    fieldName: 'State',
                    label: 'State'
                },
                {
                    fieldName: 'Subject',
                    label: 'Subject'
                }
            ],
            singleActions: ['View Message', 'Mark as Read', 'Delete'],
            bulkActions: ['Mark as Read', 'Delete'],
            collapsed: true
        };
    }
    // ==================================================================================
    // KC: 2020/05/26: The new pattern is in this section
    MessagesService.prototype.getMessagesNew = function (eventId) {
        var mockMessage = {
            id: 42,
            packageId: null,
            surveyId: null,
            subject: 'Mock Message Subject',
            body: 'Mock Message Body'
        };
        return of([mockMessage]);
        // TODO: implement the below and remove the mock above. Also remove 'of' import.  Also fix _getMessagesUrl.
        // return this.http.get<GTResponse<Message[]>>(this.URLs._getMessagesUrl(eventId)).pipe(
        //          handleGTResponse(),
        // );
    };
    // ==================================================================================
    MessagesService.prototype.GetMessages = function (options) {
        var _this = this;
        return this.http.post(this.URLs._messages(), options).pipe(handleGTResponse(), tap(function (userMessages) {
            userMessages.map(function (msg) {
                msg.isSent = false;
                var parsed;
                try {
                    parsed = JSON.parse(msg.JsonMessage);
                    if (parsed.message) {
                        parsed.message = _this.sanitizer.sanitize(SecurityContext.HTML, parsed.message);
                    }
                }
                catch (e) {
                    parsed = {
                        message: 'ERROR: Uh Oh! We couldn\'t read this message!'
                    };
                }
                msg.JsonMessage = {
                    JsonMessage: parsed,
                    State: msg.State,
                    isSent: msg.isSent,
                    Date: msg.CreatedAt,
                    Subject: msg.Subject
                };
                msg.CombinedFromSubject = {
                    SenderName: msg.SenderName,
                    Subject: msg.Subject,
                    State: msg.State
                };
                msg.CombinedFromDate = {
                    Date: msg.CreatedAt,
                    State: msg.State,
                    isSent: msg.isSent
                };
                // create a new property that is just a getter, this will be sortable based on our rules
                // unread 1st, then date descending
                Object.defineProperty(msg, 'sortable', {
                    get: function () {
                        // this will be 'z' for unread, 'a' for read, followed by the iso sortable date
                        // e.g. 'a2018-08-07T19:23:55', use descending sort to get newest unread
                        return '' + (msg.State.toLocaleLowerCase() === 'unread' ? 'z' : 'a')
                            + msg.CreatedAt.substring(0, 19);
                    },
                    set: function () { },
                    configurable: false,
                    enumerable: true
                });
            });
        }));
    };
    MessagesService.prototype.GetSentMessages = function (options) {
        var _this = this;
        return this.http.post(this.URLs._getSentMessages(), options).pipe(handleGTResponse(), tap(function (userMessages) {
            userMessages.map(function (msg) {
                msg.isSent = true;
                var parsed;
                try {
                    parsed = JSON.parse(msg.JsonMessage);
                    if (parsed.message) {
                        parsed.message = _this.sanitizer.sanitize(SecurityContext.HTML, parsed.message);
                    }
                }
                catch (e) {
                    parsed = {
                        message: 'ERROR: Uh Oh! We couldn\'t read this message!'
                    };
                }
                msg.JsonMessage = {
                    JsonMessage: parsed,
                    State: msg.State,
                    isSent: msg.isSent,
                    Date: msg.CreatedAt,
                    Subject: msg.Subject
                };
                msg.CombinedFromSubject = {
                    RecipientName: msg.RecipientName,
                    Subject: msg.Subject
                };
                msg.CombinedFromDate = {
                    Date: msg.CreatedAt,
                    State: msg.State,
                    isSent: msg.isSent
                };
            });
        }));
    };
    MessagesService.prototype.GetMessagesSummary = function (options) {
        return this.http.post(this.URLs._messagesSummary(), options).pipe(handleGTResponse());
    };
    MessagesService.prototype.SendMessages = function (messageObj) {
        var token = '';
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.post(this.URLs._sendMessages(), messageObj, {
            headers: headers
        });
    };
    MessagesService.prototype.SendPackageMessages = function (messageObj) {
        return this.http.post(this.URLs._sendPackageMessages(), messageObj);
    };
    MessagesService.prototype.MarkAsRead = function (message) {
        var token = '';
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.get(this.URLs._markMessageAsRead(message.IdUserMessage)).pipe(handleGTResponse());
    };
    // returns IdUserMessage of successfully deleted message
    MessagesService.prototype.DeleteMessages = function (message) {
        var token = '';
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.post(this.URLs._deleteMessages(), message.IdUserMessage, { headers: headers }).pipe(handleGTResponse());
    };
    return MessagesService;
}());
export { MessagesService };
