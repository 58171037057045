import { Component, ElementRef, HostListener, ViewEncapsulation, Input } from '@angular/core';
import { GlobalState } from '../../../global.state';

import 'style-loader!./baSidebar.scss';

@Component({
  selector: 'ba-sidebar',
  templateUrl: './baSidebar.html'
})
export class BaSidebar {
  public menuHeight: number;
  public isMenuCollapsed: boolean = true;
  public isMenuShouldCollapsed: boolean = true;

  @Input() loggedIn: boolean;
  @Input() isGroupLeader: boolean;

  private resWidthCollapseSidebar: number = 1200;

  constructor(
    private _elementRef: ElementRef,
    private _state: GlobalState
  ){
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  public ngOnInit(): void {
    if (this._shouldMenuCollapse()) {
      this.menuCollapse();
    }
  }

  public menuExpand(): void {
    this.menuCollapseStateChange(false);
  }

  public menuCollapse(): void {
    this.menuCollapseStateChange(true);
  }

  public menuCollapseStateChange(isCollapsed: boolean): void {
    this.isMenuCollapsed = isCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
  }

  public updateSidebarHeight(): void {
    this.menuHeight = this._elementRef.nativeElement.childNodes[0].clientHeight - 84;
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= this.resWidthCollapseSidebar;
  }
}
