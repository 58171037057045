<!-- ATTENDEE DETAILS -->
<div *ngIf="state === 'modal'" class="row attendee-details">
    <div class="col-lg-12">
        <label>Attendee:</label>
    </div>
    <div class="col-lg-12">
        <p>{{attendee?.AttendeeFullName}}</p>
    </div>
    <div class="col-lg-12">
        <label>Status:</label>
    </div>
    <div class="col-lg-12">
        <select [(ngModel)]="status" class="form-control" *ngIf="attendee?.paymentsSent === 0 || !isLeader">
            <option value="invited">-- Select --</option>
            <option [disabled]="isSupplierEvent" value="accept">Accept</option>
            <option value="tentative">Tentative</option>
            <option value="decline">Declined</option>
        </select>
        <p *ngIf="isLeader && attendee?.paymentsSent > 0">Current attendance status: <b>{{attendee?.Status}}</b></p>
    </div>
    <div class="col-md-12">
        <button class="btn btn-primary attendee-btn-ok" (click)="update()" [disabled]="attendee?.paymentsSent > 0 || updateAttendeeStatusDisabled">Update Status</button>
    </div>
    <div *ngIf="isLeader" class="col-md-12">
        <button class="btn btn-primary attendee-btn-email" (click)="bulkEvent({type:'Send Message',object:attendee})">Email this Attendee</button>
    </div>
    <div class="col-md-12">
        <button class="btn btn-secondary attendee-btn-cancel" (click)="close()">Return to Event</button>
    </div>
</div>

<grouptools-success *ngIf="state==='success'" [successHeading]="'Success'">
    <div class="row">
        <div class="col-sm-12">
            <div class="success-body centered">
                <span>Your status has been updated.</span>
            </div>
        </div>
    </div>
    <div class="row"><div class="col-sm-12 centered">
      <button class="btn btn-primary" (click)="close()">Return to Event</button>
    </div></div>
</grouptools-success>
