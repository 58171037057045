
<div class="container" 
  [style.width]="cssWidth" 
  [style.height]="cssHeight" 
  [style.background-color]="cssBackgroundColor"
  [style.opacity]="opacity"
  [style.maxWidth]="maxWidth"
  [style.position]="position"
  [style.z-index]="zIndex"
  [class.grid-center-center]="shouldCenterSpinnerVertically"
>
  <h2 class="mat-display-1 loading-label" 
    [class.placement-top]="shouldTopAlignSpinnerVertically"
    [style.margin-top]="cssMarginTop"
  >{{text}}</h2>
  <div class="spinner-container">
    <mat-progress-spinner mode="indeterminate" class="spinner"></mat-progress-spinner>
  </div>
</div>
