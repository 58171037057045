import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
var AmazonService = /** @class */ (function () {
    function AmazonService(_http, _Urls) {
        this._http = _http;
        this._Urls = _Urls;
    }
    AmazonService.prototype.GetAccessTokenFromCode = function (code) {
        return this._http.get(this._Urls._getAmazonTokenFromCode(code));
    };
    return AmazonService;
}());
export { AmazonService };
