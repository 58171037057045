import { Component, Input } from '@angular/core';
 
@Component({
  selector: 'grouptools-success',
  templateUrl: './grouptools-success.component.html',
  styleUrls: ['./grouptools-success.component.scss']
})
export class GrouptoolsSuccessComponent {
    @Input() successHeading: string = "Success!";
    @Input() type: string = "success";
}