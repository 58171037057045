<div *ngIf="displayHeader" class="modal-header">
    <button *ngIf="!isStatic" type="button" class="close pull-right" (click)="close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title text-center">{{title}}</h4>
</div>
<div *ngIf="displayHeader" class="gt-modal-hr"></div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            <ng-template #container></ng-template>
        </div>
    </div>
</div>
