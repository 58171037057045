import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { EventDetailsService } from "app/pages/package-details/event-details.service";
import { of } from "rxjs";
import { catchError, exhaustMap, map, switchMap } from "rxjs/operators";
import * as fromEventItem from '../actions/gledEventItem.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';

@Injectable()
export class GledEventItemEffects {

    @Effect()
    loadEventItems = this.actions$
        .pipe(
            ofType<fromEventItem.LoadEventItems>(fromEventItem.LOAD_EVENT_ITEMS),
            switchMap(action => this.eventService.getEventItems(action.eventId, action.activityId, action.optionId)
                        .pipe(
                            map(eventItems => new fromEventItem.LoadEventItemsOk(eventItems)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    // @Effect()
    // assignAttendeesToItems = this.actions$
    //     .pipe(
    //         ofType<fromEventItem.AssignAttendeesToItems>(fromEventItem.ASSIGN_ATTENDEES_TO_ITEMS),
    //         exhaustMap(action => this.eventService.assignAttendeesToItems(action.eventId, action.eventItems)
    //                     .pipe(
    //                         map(eventItems => new fromEventItem.AssignAttendeesToItemsOk(eventItems)),
    //                         catchError(error => of(new fromEventItem.AssignAttendeesToItemsError({errorMessage: error.toString()})))
    //                     )
    //         )
    //     );

    @Effect()
    assignAttendeesToItemsAutomatically = this.actions$
        .pipe(
            ofType<fromEventItem.AssignAttendeesToItemsAutomatically>(fromEventItem.ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY),
            exhaustMap(action => this.eventService.assignAttendeesToItemsAutomatically(action.eventId)
                        .pipe(
                            map(eventItems => new fromEventItem.AssignAttendeesToItemsAutomaticallyOk(eventItems)),
                            catchError(error => of(new fromEventItem.AssignAttendeesToItemsAutomaticallyError({errorMessage: error.toString()})))
                        )
            )
        );


    constructor(
        private actions$: Actions,
        private eventService: EventDetailsService
    ) {}


}
