import { CommonModule } from '@angular/common';

// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { SurveyInviteComponent } from 'app/pages/survey-invite/survey-invite.component';
import { LoginPageModule } from '../loginPage/login-page.module';
import { RegisterModule } from '../register-page/register-page.module';
import { WidgetsModule } from 'app/widgets/widgets.module';

import { SurveysService } from 'app/pages/surveys/surveys.service';
import { SurveyInviteService } from './survey-invite.service';
import { QuestionControlService } from 'app/pages/surveys/survey/components/survey-body/questions/question-control.service';
import { AmazonLoginModule } from '../amazon-login/amazon-login.module';
import { GoogleLoginModule } from '../google-login/google-login.module';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { FacebookLoginModule } from '../facebook-login/facebook-login.module';
import { MessagesModule } from '../messages/messages.module';
import { GenericQuestionModule } from 'app/pages/surveys/survey/components/survey-body/questions/generic-question/generic-question.module';

@NgModule({
    imports: [
        CommonModule,
        LoginPageModule,
        RegisterModule,
        WidgetsModule,
        GoogleLoginModule,
        AmazonLoginModule,
        FacebookLoginModule,
        GTNgaModule,
        MessagesModule,
        GenericQuestionModule
    ],
    declarations: [
        SurveyInviteComponent
    ],
    exports: [
        SurveyInviteComponent
    ],
    providers: [
        SurveysService,
        SurveyInviteService,
        QuestionControlService
    ]
})
export class SurveyInviteModule {

}