import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ApiState } from 'app/store/models/apiData';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CreateEventFromSupplierOrderRequest } from 'app/store/models/requests/create-event-from-supplier-order-request';
import { ofType, Actions } from '@ngrx/effects';
import * as PackageDetailsActions from 'app/store/actions/packageDetails.actions';
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import { SupplierDTO } from 'app/store/models/packages/SupplierDTO';
import { ActivatedRoute } from '@angular/router';
import { NgxConfigureService } from 'ngx-configure';

@Component({
  selector: 'create-from-supplier-order-view',
  templateUrl: './create-from-supplier-order-view.component.html',
  styleUrls: ['./create-from-supplier-order-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFromSupplierOrderViewComponent implements OnInit {

  @Input() matFormFieldAppearance: 'standard' | 'outline';
  @Input() isInModal: boolean = true;
  public get shouldPutFormInCard() { return !this.isInModal; }

  public unsubscribe$: Subject<void> = new Subject<void>();
  supplierErrorMessage: string = null;

  ApiState = ApiState; // This is so we can use the enum type in the template

  suppliers$: Observable<SupplierDTO[]>;
  suppliers: SupplierDTO[];
  selectedSupplier: SupplierDTO; //TODO: Have this set based on the hostname of the url

  // TODO: Could rename authApiState to bibOrderNumberCreateEventApiState or something simpler like apiState or dialogApiState
  private _authApiState: ApiState = ApiState.Initial;
  get authApiState(): ApiState { return this._authApiState; }
  @Input() set authApiState(value: ApiState) {
    if (this._authApiState === ApiState.InFlight && value === ApiState.Error) {
      // Then the error dialog should show, because an error just happened
      this.showLoginErrorDialog();
    }
    this._authApiState = value;
  }

  @Output() dateSelectedEvent = new EventEmitter<boolean>();
  @Output() getStartedEvent = new EventEmitter<CreateEventFromSupplierOrderRequest>();

  get shouldShowLoader(): boolean {
    return (this.authApiState === ApiState.InFlight);
  }

  get shouldShowLoginForm(): boolean {
    return !this.shouldShowLoader;
  }

  private eventCreationSupplierTypes: string[];
  shouldShowVenueDropdown: boolean = true;
  hostNameSupplierId: number = null;
  hostNameSupplierName: string = "";
  @Input() SupplierOrderNumber: string;
  public supplierImage:any = null;

  form: FormGroup;


  buildForm() {
    this.form = this.fb.group({
      orderNumber: [this.SupplierOrderNumber, [Validators.required]],
      venueId: [this.hostNameSupplierId, [Validators.required]],
    });  

    // If we have both pieces of information on load of the page (e.g. using the deep link), submit the form automatically.
    if (this.SupplierOrderNumber != '' && this.hostNameSupplierId > 0) {
      this.getStarted();
    }
  }
  get createEventFromAVOrderRequest(): CreateEventFromSupplierOrderRequest | null {
    const orderNumber = this.form.get('orderNumber').value;
    const venueId = +this.form.get('venueId').value;
    return <CreateEventFromSupplierOrderRequest> {
      supplierOrderNumber: orderNumber,
      venueId: venueId,
    }
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private actions$: Actions,
    private _activatedRoute: ActivatedRoute,
    private store$: Store<fromRoot.State>,
    private configService: NgxConfigureService
  ) {
    
  }

  ngOnInit() {
    this.eventCreationSupplierTypes = this.configService.config.eventCreationSupplierTypes.split(",");

    this.suppliers$ = this.store$.pipe(select(PackageDetailsSelectors.getSuppliers));

    this.suppliers$.pipe(takeUntil(this.unsubscribe$)).subscribe(suppliers => {
      this.suppliers = suppliers.filter(s => this.eventCreationSupplierTypes.includes(s.SupplierType.toString()));

      // If we know the supplier based on the hostname, hide the dropdown and preselect the correct venue.
      const hostname = window.location.hostname;

      const supplier = suppliers.filter(s => s.Url == hostname);

      if (supplier.length > 0) {
        this.shouldShowVenueDropdown = false;
        this.hostNameSupplierId = supplier[0].SupplierId;
        this.hostNameSupplierName = supplier[0].SupplierName;
      }
      else {
        this.shouldShowVenueDropdown = true;
        this.hostNameSupplierId = null;
        this.hostNameSupplierName = "";
      }

      this.buildForm();
      this.setupAVActionTriggers();
    }); 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupAVActionTriggers() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType<PackageDetailsActions.PDApiCreateEventFromSupplierOrderFailure>(PackageDetailsActions.ActionTypes.PDApiCreateEventFromSupplierOrderFailure),
      tap(action => { 
          // If AV event creation fails, set the error message
          this.supplierErrorMessage = action.payload.errorMessage;
        }),
    ).subscribe();
  }

  dateSelected(event: MatDatepickerInputEvent<Date>) { 
    this.dateSelectedEvent.emit(true); 
  }

  getStarted() {
    const createEventFromAVOrderRequest = this.createEventFromAVOrderRequest;
    if (this.form.invalid || !createEventFromAVOrderRequest) {
      this.form.controls['orderNumber'].markAsTouched();
      this.form.controls['venueId'].markAsTouched();
      return;
    }
    this.getStartedEvent.emit(createEventFromAVOrderRequest);
  }

  loginFormEnterKeyup() {
    this.getStarted();
  }

  // ===============================================================================
  //                                 Popup Dialog Logic 
  // ===============================================================================

  showLoginErrorDialog(): void {
  }

}
