import { PipeTransform } from '@angular/core';
var PhonePipe = /** @class */ (function () {
    function PhonePipe() {
    }
    PhonePipe.prototype.transform = function (phoneNumber) {
        var phoneArray = phoneNumber.split('');
        if (phoneArray.length === 10) {
            phoneArray.splice(3, 0, '.');
            phoneArray.splice(7, 0, '.');
            return phoneArray.join('');
        }
        else {
            return phoneNumber;
        }
    };
    return PhonePipe;
}());
export { PhonePipe };
