var NbgDatePickerUtility = /** @class */ (function () {
    function NbgDatePickerUtility() {
    }
    NbgDatePickerUtility.prototype.tryClose = function (event, datepicker) {
        if (!!event) {
            var path = event.hasOwnProperty('path') ? event.path : this.buildThePath(event.target, []);
            path = path.map(function (p) { return p.localName; });
            if (!path.includes('ngb-datepicker')) {
                datepicker.close();
            }
        }
    };
    NbgDatePickerUtility.prototype.buildThePath = function (target, currentPath) {
        currentPath = currentPath || [];
        if (!!target) {
            if (!!target.parentNode)
                currentPath = this.buildThePath(target.parentNode, currentPath);
            currentPath.push({
                localName: target.localName || ''
            });
        }
        return currentPath;
    };
    return NbgDatePickerUtility;
}());
export { NbgDatePickerUtility };
