import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiState } from 'app/store/models/apiData';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ofType, Actions } from '@ngrx/effects';
import * as PackageDetailsActions from 'app/store/actions/packageDetails.actions';
import { Store, select } from '@ngrx/store';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import { ActivatedRoute } from '@angular/router';
import { NgxConfigureService } from 'ngx-configure';
var CreateFromSupplierOrderViewComponent = /** @class */ (function () {
    function CreateFromSupplierOrderViewComponent(fb, dialog, actions$, _activatedRoute, store$, configService) {
        this.fb = fb;
        this.dialog = dialog;
        this.actions$ = actions$;
        this._activatedRoute = _activatedRoute;
        this.store$ = store$;
        this.configService = configService;
        this.isInModal = true;
        this.unsubscribe$ = new Subject();
        this.supplierErrorMessage = null;
        this.ApiState = ApiState; // This is so we can use the enum type in the template
        // TODO: Could rename authApiState to bibOrderNumberCreateEventApiState or something simpler like apiState or dialogApiState
        this._authApiState = ApiState.Initial;
        this.dateSelectedEvent = new EventEmitter();
        this.getStartedEvent = new EventEmitter();
        this.shouldShowVenueDropdown = true;
        this.hostNameSupplierId = null;
        this.hostNameSupplierName = "";
        this.supplierImage = null;
    }
    Object.defineProperty(CreateFromSupplierOrderViewComponent.prototype, "shouldPutFormInCard", {
        get: function () { return !this.isInModal; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFromSupplierOrderViewComponent.prototype, "authApiState", {
        get: function () { return this._authApiState; },
        set: function (value) {
            if (this._authApiState === ApiState.InFlight && value === ApiState.Error) {
                // Then the error dialog should show, because an error just happened
                this.showLoginErrorDialog();
            }
            this._authApiState = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFromSupplierOrderViewComponent.prototype, "shouldShowLoader", {
        get: function () {
            return (this.authApiState === ApiState.InFlight);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFromSupplierOrderViewComponent.prototype, "shouldShowLoginForm", {
        get: function () {
            return !this.shouldShowLoader;
        },
        enumerable: true,
        configurable: true
    });
    CreateFromSupplierOrderViewComponent.prototype.buildForm = function () {
        this.form = this.fb.group({
            orderNumber: [this.SupplierOrderNumber, [Validators.required]],
            venueId: [this.hostNameSupplierId, [Validators.required]],
        });
        // If we have both pieces of information on load of the page (e.g. using the deep link), submit the form automatically.
        if (this.SupplierOrderNumber != '' && this.hostNameSupplierId > 0) {
            this.getStarted();
        }
    };
    Object.defineProperty(CreateFromSupplierOrderViewComponent.prototype, "createEventFromAVOrderRequest", {
        get: function () {
            var orderNumber = this.form.get('orderNumber').value;
            var venueId = +this.form.get('venueId').value;
            return {
                supplierOrderNumber: orderNumber,
                venueId: venueId,
            };
        },
        enumerable: true,
        configurable: true
    });
    CreateFromSupplierOrderViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventCreationSupplierTypes = this.configService.config.eventCreationSupplierTypes.split(",");
        this.suppliers$ = this.store$.pipe(select(PackageDetailsSelectors.getSuppliers));
        this.suppliers$.pipe(takeUntil(this.unsubscribe$)).subscribe(function (suppliers) {
            _this.suppliers = suppliers.filter(function (s) { return _this.eventCreationSupplierTypes.includes(s.SupplierType.toString()); });
            // If we know the supplier based on the hostname, hide the dropdown and preselect the correct venue.
            var hostname = window.location.hostname;
            var supplier = suppliers.filter(function (s) { return s.Url == hostname; });
            if (supplier.length > 0) {
                _this.shouldShowVenueDropdown = false;
                _this.hostNameSupplierId = supplier[0].SupplierId;
                _this.hostNameSupplierName = supplier[0].SupplierName;
            }
            else {
                _this.shouldShowVenueDropdown = true;
                _this.hostNameSupplierId = null;
                _this.hostNameSupplierName = "";
            }
            _this.buildForm();
            _this.setupAVActionTriggers();
        });
    };
    CreateFromSupplierOrderViewComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    CreateFromSupplierOrderViewComponent.prototype.setupAVActionTriggers = function () {
        var _this = this;
        this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(PackageDetailsActions.ActionTypes.PDApiCreateEventFromSupplierOrderFailure), tap(function (action) {
            // If AV event creation fails, set the error message
            _this.supplierErrorMessage = action.payload.errorMessage;
        })).subscribe();
    };
    CreateFromSupplierOrderViewComponent.prototype.dateSelected = function (event) {
        this.dateSelectedEvent.emit(true);
    };
    CreateFromSupplierOrderViewComponent.prototype.getStarted = function () {
        var createEventFromAVOrderRequest = this.createEventFromAVOrderRequest;
        if (this.form.invalid || !createEventFromAVOrderRequest) {
            this.form.controls['orderNumber'].markAsTouched();
            this.form.controls['venueId'].markAsTouched();
            return;
        }
        this.getStartedEvent.emit(createEventFromAVOrderRequest);
    };
    CreateFromSupplierOrderViewComponent.prototype.loginFormEnterKeyup = function () {
        this.getStarted();
    };
    // ===============================================================================
    //                                 Popup Dialog Logic 
    // ===============================================================================
    CreateFromSupplierOrderViewComponent.prototype.showLoginErrorDialog = function () {
    };
    return CreateFromSupplierOrderViewComponent;
}());
export { CreateFromSupplierOrderViewComponent };
