export var OPEN_MODAL = '[Modal] Open';
export var CLOSE_MODAL = '[Modal] Close';
export var MODAL_BUTTON_PRESSED = '[Modal] Button Pressed';
export var SET_MODAL_COMPONENT = '[Modal] Set Component';
export var SET_MODAL_SIZE = '[Modal] Set Size';
export var SET_MODAL_TITLE = '[Modal] Set Title';
export var SET_FULL_SCREEN_MOBILE = '[Modal] Set Is Full Screen Mobile';
var OpenModal = /** @class */ (function () {
    function OpenModal(payload) {
        this.payload = payload;
        this.type = OPEN_MODAL;
    }
    return OpenModal;
}());
export { OpenModal };
var CloseModal = /** @class */ (function () {
    function CloseModal() {
        this.type = CLOSE_MODAL;
    }
    return CloseModal;
}());
export { CloseModal };
var ModalButtonPressed = /** @class */ (function () {
    function ModalButtonPressed(payload) {
        this.payload = payload;
        this.type = MODAL_BUTTON_PRESSED;
    }
    return ModalButtonPressed;
}());
export { ModalButtonPressed };
var SetModalComponent = /** @class */ (function () {
    function SetModalComponent(payload) {
        this.payload = payload;
        this.type = SET_MODAL_COMPONENT;
    }
    return SetModalComponent;
}());
export { SetModalComponent };
var SetModalSize = /** @class */ (function () {
    function SetModalSize(payload) {
        this.payload = payload;
        this.type = SET_MODAL_SIZE;
    }
    return SetModalSize;
}());
export { SetModalSize };
var SetModalTitle = /** @class */ (function () {
    function SetModalTitle(payload) {
        this.payload = payload;
        this.type = SET_MODAL_TITLE;
    }
    return SetModalTitle;
}());
export { SetModalTitle };
var SetIsFullScreenMobile = /** @class */ (function () {
    function SetIsFullScreenMobile(payload) {
        this.payload = payload;
        this.type = SET_FULL_SCREEN_MOBILE;
    }
    return SetIsFullScreenMobile;
}());
export { SetIsFullScreenMobile };
