import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { UploadFeatureImgComponent } from './upload-feature-img.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        GTNgaModule,
        NgbModule
    ],
    declarations: [
        UploadFeatureImgComponent
    ],
    exports: [
        UploadFeatureImgComponent
    ]
})
export class UploadFeatureImgModule {

}
