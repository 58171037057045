import * as shortUrl from '../actions/shortUrl.action';
import { ShortUrl } from 'app/store/models/shortUrl.interface';

// rename the model interface to State for reducer consistency
export interface State extends ShortUrl {}

export const initialState: State = {
    shortUrl: '',
    title: '',
    description: '',
    image: '',
    site_name: 'GroupTools'
};

export function reducer(state = initialState, action: shortUrl.Actions): State {
  switch (action.type) {
    case shortUrl.SET_SHORT_URL:
      {
        return Object.assign({}, state, {
            shortUrl: action.payload.shortUrl,
            title: action.payload.title,
            description: action.payload.description,
            image: action.payload.image,
            site_name: 'GroupTools'
        });
      }
    default:
      {
        return state;
      }
  }
}

export const getShortUrl = (state: State) => state;
