import { Action } from "@ngrx/store";
import { UserActionDTO } from "../models/packages/UserActionDTO";

export enum ActionTypes {
    ActionsComponentLoadActions = "[Actions Component] Load Actions",
    ActionsEffectLoadActions = "[Actions Effect] Load Actions",
    ActionsEffectLoadActionsSuccess = "[Actions Effect] Load Actions Success",
    ActionsEffectLoadActionsFailure = "[Actions Effect] Load Actions Failure",

    SurveyComponentUpdateActions = "[Survey Component] Update Actions",
    AttendeeFormResponseComponentUpdateActions = "[Attendee Form Response Component] Update Actions",
    ActionsEffectUserWasVerified = "[Actions Effect] User Was Verified",

    ActionsComponentMarkAsRead = "[Actions Component] Mark As Read",
    ActionsEffectMarkAsReadSuccess = "[Actions Effect] Mark As Read Success",
    ActionsEffectMarkAsReadFailure = "[Actions Effect] Mark As Read Failure",

    ActionsComponentDismissSurveyActions = "[Actions Component] Dismiss Survey Actions",
    ActionsEffectDismissSurveyActionsSuccess = "[Actions Effect] Dismiss Survey Actions Success",
    ActionsEffectDismissSurveyActionsFailure = "[Actions Effect] Dismiss Survey Actions Failure",

    AuthenticationServiceLogout = "[Authentication Service] Logout",
}

//----------------------------------------------------------------
export class ActionsComponentLoadActions implements Action {
    readonly type = ActionTypes.ActionsComponentLoadActions;
    constructor(public payload: {eventId: number | null}) {} // TODO: can eventId be null for actions endpoint?
}
export class ActionsEffectLoadActions implements Action {
    readonly type = ActionTypes.ActionsEffectLoadActions;
    constructor(public payload: {eventId: number | null}) {} // TODO: can eventId be null for actions endpoint?
}
export class ActionsEffectLoadActionsSuccess implements Action {
    readonly type = ActionTypes.ActionsEffectLoadActionsSuccess;
    constructor(public payload: {userActions: UserActionDTO[]}) {}
}
export class ActionsEffectLoadActionsFailure implements Action {
    readonly type = ActionTypes.ActionsEffectLoadActionsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class SurveyComponentUpdateActions implements Action {
    readonly type = ActionTypes.SurveyComponentUpdateActions;
    constructor() {}
}
export class AttendeeFormResponseComponentUpdateActions implements Action {
    readonly type = ActionTypes.AttendeeFormResponseComponentUpdateActions;
    constructor() {}
}
export class ActionsEffectUserWasVerified implements Action {
    readonly type = ActionTypes.ActionsEffectUserWasVerified;
    constructor() {}
}
//----------------------------------------------------------------
export class ActionsComponentMarkAsRead implements Action {
    readonly type = ActionTypes.ActionsComponentMarkAsRead;
    constructor(public payload: {userAction: UserActionDTO}) {} // TODO: can eventId be null for actions endpoint?
}
export class ActionsEffectMarkAsReadSuccess implements Action {
    readonly type = ActionTypes.ActionsEffectMarkAsReadSuccess;
    constructor(public payload: {userActionId: number}) {} // TODO: what is return type
}
export class ActionsEffectMarkAsReadFailure implements Action {
    readonly type = ActionTypes.ActionsEffectMarkAsReadFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class ActionsComponentDismissSurveyActions implements Action {
    readonly type = ActionTypes.ActionsComponentDismissSurveyActions;
    constructor(public payload: {surveyId: number}) {} // TODO: can eventId be null for actions endpoint?
}
export class ActionsEffectDismissSurveyActionsSuccess implements Action {
    readonly type = ActionTypes.ActionsEffectDismissSurveyActionsSuccess;
    constructor(public payload: {userActionId: number}) {} // TODO: what is return type
}
export class ActionsEffectDismissSurveyActionsFailure implements Action {
    readonly type = ActionTypes.ActionsEffectDismissSurveyActionsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class AuthenticationServiceLogout implements Action {
    readonly type = ActionTypes.AuthenticationServiceLogout;
    constructor() {}
}
//----------------------------------------------------------------

export type Actions
    = ActionsComponentLoadActions
    | ActionsEffectLoadActions
    | ActionsEffectLoadActionsSuccess
    | ActionsEffectLoadActionsFailure

    | SurveyComponentUpdateActions
    | AttendeeFormResponseComponentUpdateActions
    | ActionsEffectUserWasVerified

    | ActionsComponentMarkAsRead
    | ActionsEffectMarkAsReadSuccess
    | ActionsEffectMarkAsReadFailure

    | ActionsComponentDismissSurveyActions
    | ActionsEffectDismissSurveyActionsSuccess
    | ActionsEffectDismissSurveyActionsFailure

    | AuthenticationServiceLogout
;
