import { AppStateService } from '../helpers/appState.service';
import { Component, ElementRef, ViewChild, EventEmitter, Output, Input, ApplicationRef, ChangeDetectorRef, NgZone, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Params, ActivatedRoute, Router, RouterState } from '@angular/router';

import 'style-loader!./login.component.scss';
import { AuthService, EmailVerification, Person, LoginPerson, BIStartPlanning, BIStartPlanningProperties } from '../../authentication/authentication.service';
import { GrouptoolsModalComponent } from 'app/widgets/grouptools-modal/grouptools-modal.component';

import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as attendeePackages from 'app/store/actions/attendeePackages';
import { Observable ,  Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  providers: [
    AuthService,
  ]
})
export class LoginComponent {
  @Input() inModal: boolean = false;
  @Input() doNotRedirect: boolean = false;
  @Input() omitTitle: boolean = false;
  @Input() emailPrefill: string = '';
  @Input() inBaPageTop: boolean = false;

  public form: FormGroup;
  public formSubmitted = false;
  public email: AbstractControl;
  public password: AbstractControl;
  public modalState: string = 'null';
  public loginPerson: LoginPerson;
  public loggedInStatu$: Observable<string>;
  public loggedInEmail$: Observable<string>;
  public googleUser: any;
  public amazonUser: any;
  public facebookUser: any;
  public LOGOICON = require(`assets/logo/GroupTools-Logo-Icon.svg`);
  public EMAIL_REGX: string = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
  public events: Observable<any>;
  public _events;
  public modalTitle: string = 'Coming Soon!';
  public loginError:boolean = true;

  @ViewChild('emailCheck') emCheck: ElementRef;
  @ViewChild('modal') modal: GrouptoolsModalComponent;

  @Output() loginEmit: EventEmitter<any> = new EventEmitter();
  @Output() differentAccount: EventEmitter<any> = new EventEmitter();
  @Output() googleRegisterEmit: EventEmitter<any> = new EventEmitter();
  @Output() amazonRegisterEmit: EventEmitter<any> = new EventEmitter();
  @Output() facebookRegisterEmit: EventEmitter<any> = new EventEmitter();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private _authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private zone: NgZone,
    private _appService: AppStateService
  ) {
    this.form = this.fb.group(this.formFields);
    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
    this.loggedInStatu$ = this.store.select(fromRoot.getLoginStatus);
    this.loggedInEmail$ = this.store.select(fromRoot.getUserEmail);
    this.form.valueChanges.subscribe(change => this.onValueChanged(change));
  }

  ngOnInit(): void {
    // subscribe to loggedIn and loggedInEmail; if both exist and the email is same
    // as the prefill, check if they came from a BI email to create an event
    this.loggedInStatu$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((loggedIn) => {
      this.loggedInEmail$.pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(email => {
          if (loggedIn && this.emailPrefill && email &&
            sessionStorage.getItem('GTEmail') &&
            email.toLocaleLowerCase() === this.emailPrefill.toLocaleLowerCase()) {
            this.createBIEvent();
        }
      })
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emailPrefill) {
      if (sessionStorage.getItem('GTEmail')) {
        this.emailPrefill = sessionStorage.getItem('GTEmail');
      }
      if (this.emailPrefill) {
        this.email.setValue(this.emailPrefill);
        this.email.disable();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  }

  public navToRegister() {
    if(this.inModal) {
      this.loginEmit.emit('register');
    } else {
      this.router.navigate(['/register']);
    }
  }

  public forgotPassword() {
    this.formSubmitted = true;
    if(!this.email.valid) {
      this.onFormSubmit('forgotPass');

    } else {
      this._authService.forgotPassword(this.email.value).subscribe(({Obj: res}) => {
        if(this.inModal) {
          this.loginEmit.emit('passwordReset');
        } else {
          this.modalState = 'passwordReset';
          this.modalTitle = 'Forgot Password';
          this.modal.grouptoolsModal.show();
        }
      });
    }
  }

  onValueChanged(data?: any) {
    if (!this.form) { return; }

    const form = this.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      let control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  onFormSubmit(forgotPass?) {
    this.formSubmitted = true;
    const form = this.form;

    if(forgotPass) {
      this.password.setValue('');
      for (const field in this.formErrors) {
        this.formErrors[field] = '';
      }
      let control = form.get('email');
      if (control && !control.valid) {
        const messages = this.validationMessages['email'];
        for (const key in control.errors) {
          this.formErrors['email'] += messages[key] + ' ';
        }
      }
    } else {
      for (const field in this.formErrors) {
        this.formErrors[field] = '';
        let control = form.get(field)

        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }
  }

  public onSubmit(values: Object): void {
    //VALID USER ENTRY IN LOGIN
    if (this.form.valid) {
      this.loginPerson = new LoginPerson(
        this.email.value,
        this.password.value);

      this._authService.login(this.loginPerson).subscribe(token => {
        //console.log(token);
        this._authService.setLocalStorage(token);
        this.handlePostLogin(token);
        this.loginError = true;
      }, error => {
        this.loginError = false;
        if (error.status > 0) {
            console.log(error.error.error_description);
          } else {
            console.log('Server is unresponsive.');
          }
        });

    //INVALID USER ENTRY IN LOGIN
    } else {
      this.onFormSubmit();
    }
  }

  cancelLogin() {
    this.googleUser = null;
    this.facebookUser = null;
    this.amazonUser = null;
    this.modal.grouptoolsModal.hide();
  }

  public handlePostLogin({token_type: tokenType}) {
    if (tokenType) {
      const returnUrl = this.route.snapshot.queryParams['returnUrl'];
      if (!this.inBaPageTop) {
        // check if we have stored BI order information, if so, create a
        if (sessionStorage.getItem('GTOrderId')) {
          this.createBIEvent();
        } else if (returnUrl) {
          this.router.navigateByUrl(returnUrl);
        } else {
          if (this.inModal) {
            this.loginEmit.emit('loggedIn');
            if (!this.doNotRedirect) {
              this.events = this._appService.getEvents();
              this.events.subscribe(res => {
                  this._events = res;
  
                  this._events.forEach(event => {
                    if (event.attendeeStatus === 'accept') {
                      this.store.dispatch(new attendeePackages.SetSelectedPackageId(Number(event.packageID)))
                      this.router.navigateByUrl('/pages/attendee-package-details/' + event.packageID);
                    }
                  });
              });
            }
          } else {
            this.navigateToLastViewedEvent();
          }
        }
      }
      else{
        if (returnUrl) 
          this.router.navigateByUrl(returnUrl);
        else
          this.navigateToLastViewedEvent();

        this.loginEmit.emit('closeModal');
      }
    }
  }

  public navigateToLastViewedEvent() {
    this._authService.getUserInfo().subscribe(({Obj: userInfo}) => {

      if (!userInfo || !userInfo.Preferences || !userInfo.Preferences.userStats) {
        this.router.navigate(['/dashboard']);
        return;
      }

      const stats = userInfo.Preferences.userStats;

      if (!!stats.lastViewedOwnedEventDateTime && stats.lastViewedOwnedEventId > 0
        && !!stats.lastViewedInvitedEventDateTime && stats.lastViewedInvitedEventId > 0) {
        const ownedDateTime = new Date(Date.parse(stats.lastViewedOwnedEventDateTime));
        const invitedDateTime = new Date(Date.parse(stats.lastViewedInvitedEventDateTime));
        if (invitedDateTime > ownedDateTime) {
          // navigate to invited event id as attendee
          this.router.navigateByUrl('/pages/attendee-package-details/' + stats.lastViewedInvitedEventId);
          return;
        } else {
          // navigate to owned event id as leader
          this.router.navigateByUrl('pages/package-details/' + stats.lastViewedOwnedEventId);
          return;
        }
      }

      if (!!stats.lastViewedOwnedEventDateTime && stats.lastViewedOwnedEventId > 0) {
        // navigate to owned event id as leader
        this.router.navigateByUrl('pages/package-details/' + stats.lastViewedOwnedEventId);
        return;
      }

      if (!!stats.lastViewedInvitedEventDateTime && stats.lastViewedInvitedEventId > 0) {
        // navigate to invited event id as attendee
        this.router.navigateByUrl('/pages/attendee-package-details/' + stats.lastViewedInvitedEventId);
        return;
      }

      // There is no last viewed event as either leader or attendee, default to navigating to dashboard
      this.router.navigate(['/pages/create-event']);
      return;
    });
  }

  public createBIEvent() {
    const data = <BIStartPlanning> {};
    for (const key of BIStartPlanningProperties) {
      data[key] = sessionStorage.getItem(key);
      sessionStorage.removeItem(key); // clear out cached values for future logins
    }

    this._authService.createBIPlaceHolderEvent(data);
  }

  public renderPlaceholder() {
    this.modalState = 'placeholder';
    this.modal.grouptoolsModal.show();
  }

  public destroyContents() {
    this.modalState = 'null';
  }

  public openDifferentAccountModal(){
    this.modalState = 'differentAccount';
    this.modalTitle = 'Warning';
    this.modal.grouptoolsModal.show();
  }

  public openGoogleRegisterModal(googleUser: any) {
    //Show registration option
    this.modalState = 'googleRegister';
    this.modal.title = 'Google Account Registration';
    this.googleUser = googleUser;
    this.modal.grouptoolsModal.show();
  }

  public openAmazonRegisterModal(amazonUser: any) {
    //Show registration option
    this.modalState = 'amazonRegister';
    this.modal.title = 'Register for GroupTools with your Amazon Account';
    this.amazonUser = amazonUser;
    this.modal.grouptoolsModal.show();
  }

  public openFacebookRegisterModal(facebookUser: any) {
    //Show registration option
    this.modalState = 'facebookRegister';
    this.modal.title = 'Register for GroupTools with your Facebook Account';
    this.facebookUser = facebookUser;
    this.modal.grouptoolsModal.show();
  }

  public formFields = {
    'email': ['', Validators.compose([Validators.required, Validators.pattern(this.EMAIL_REGX)])],
    'password': ['', [Validators.required]]
  }

  public formErrors = {
    'email': '',
    'password': ''
  };

  public validationMessages = {
    'email': {
      'required': 'Email is required.',
      'pattern': 'Email must be in the proper format (ex. example@example.com).'
    },
    'password': {
      'required': 'Password is required'
    }
  }
}
