import { EventEmitter, OnChanges, SimpleChange, ComponentFactoryResolver, ViewContainerRef, Compiler } from '@angular/core';
var BaCard = /** @class */ (function () {
    function BaCard(componentFactoryResolver, viewContainerRef, compiler) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.viewContainerRef = viewContainerRef;
        this.compiler = compiler;
        this.collapsible = true;
        this.titleStyle = '';
        this.IsExpand = false;
        this.CardEvent = new EventEmitter();
        this.gtCardShow = true;
    }
    Object.defineProperty(BaCard.prototype, "collapsed", {
        get: function () {
            return this.gtCardShow === false;
        },
        set: function (value) {
            if (value && value.toString() !== 'false') {
                this.gtCardShow = false;
            }
            else {
                this.gtCardShow = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    BaCard.prototype.toggleShowBody = function () {
        this.gtCardShow = !this.gtCardShow;
        this.CardEvent.emit(this.gtCardShow);
    };
    BaCard.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            if (propName == 'IsExpand') {
                var change = changes[propName];
                if (change.currentValue) {
                    this.gtCardShow = false;
                }
            }
            if (propName == 'title') {
                this.showTitleString = typeof this.title === 'string';
            }
        }
    };
    return BaCard;
}());
export { BaCard };
