import * as tslib_1 from "tslib";
import * as user from '../actions/user';
export var initialState = {
    isLoggedIn: false,
    loginStatus: 'none',
    isRegistered: false,
    isVerified: false,
    userID: null,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    addressState: null,
    about: null,
    profilePictureSrc: '',
    isGroupLeader: false,
    paymentMethod: '',
    alternatePaymentMethod: '',
    roles: [],
    unreadMessagesPreferences: "" /* Unassigned */
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case user.LOAD_USER_STATE_OK:
            {
                return Object.assign({}, state, action.payload);
            }
        case user.SET_LOGGED_IN:
            {
                return Object.assign({}, state, {
                    isLoggedIn: action.payload
                });
            }
        case user.SET_USER_ID:
            {
                return Object.assign({}, state, {
                    userID: action.payload
                });
            }
        case user.SET_LOGIN_STATUS:
            {
                return Object.assign({}, state, {
                    loginStatus: action.payload
                });
            }
        case user.SET_DEFAULT:
            {
                return Object.assign({}, initialState);
            }
        case user.SET_EMAIL:
            {
                return Object.assign({}, state, {
                    email: action.payload
                });
            }
        case user.SET_REGISTERED_STATUS:
            {
                return Object.assign({}, state, {
                    isRegistered: action.payload
                });
            }
        case user.SET_VERIFIED_STATUS:
            {
                return Object.assign({}, state, {
                    isVerified: action.payload
                });
            }
        case user.SET_FIRST_NAME:
            {
                return Object.assign({}, state, {
                    firstName: action.payload
                });
            }
        case user.SET_LAST_NAME:
            {
                return Object.assign({}, state, {
                    lastName: action.payload
                });
            }
        case user.SET_PHONE_NUMBER:
            {
                return Object.assign({}, state, {
                    phoneNumber: action.payload
                });
            }
        case user.SET_ADDRESS_STATE:
            {
                return Object.assign({}, state, {
                    addressState: action.payload
                });
            }
        case user.SET_PROFILE_PICTURE:
            {
                return Object.assign({}, state, {
                    profilePictureSrc: action.payload
                });
            }
        case user.SET_DEFAULT_PROFILE_PICTURE:
            {
                return Object.assign({}, state, {
                    profilePictureSrc: initialState.profilePictureSrc
                });
            }
        case user.SET_LEADER_STATUS:
            {
                return Object.assign({}, state, {
                    isGroupLeader: action.payload
                });
            }
        case user.SET_PAYMENT_METHOD:
            {
                return Object.assign({}, state, {
                    paymentMethod: action.payload.paymentMethod
                });
            }
        case user.SET_ALTERNATE_PAYMENT_METHOD:
            {
                return Object.assign({}, state, {
                    alternatePaymentMethod: action.payload
                });
            }
        case user.SET_ROLES:
            {
                return Object.assign({}, state, {
                    roles: action.payload
                });
            }
        case user.SET_UNREAD_EMAIL_PREFERENCE_OK:
            {
                return Object.assign({}, state, action.payload);
            }
        case user.SET_ABOUT:
            {
                return Object.assign({}, state, {
                    about: action.payload
                });
            }
        case user.SET_USER_REGISTERED_DATA:
            {
                return Object.assign({}, state, tslib_1.__assign({}, action.payload));
            }
        case user.SAVE_ABOUT_OK:
            {
                return Object.assign({}, state, action.payload);
            }
        case user.LOGOUT:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
}
export var getLoggedIn = function (state) { return state.isLoggedIn; };
export var getVerifiedStatus = function (state) { return state.isVerified; };
export var getLoginStatus = function (state) { return state.loginStatus; };
export var getUserID = function (state) { return state.userID; };
export var getUserEmail = function (state) { return state.email; };
export var getRegisteredStatus = function (state) { return state.isRegistered; };
export var getFirstName = function (state) { return state.firstName; };
export var getLastName = function (state) { return state.lastName; };
export var getPhoneNumber = function (state) { return state.phoneNumber; };
export var getAddressState = function (state) { return state.addressState; };
export var getProfilePicture = function (state) { return state.profilePictureSrc; };
export var getLeaderStatus = function (state) { return state.isGroupLeader; };
export var getPaymentMethod = function (state) { return state.paymentMethod; };
export var getAlternatePaymentMethod = function (state) { return state.alternatePaymentMethod; };
export var getAbout = function (state) { return state.about; };
export var getUnreadMessagesPreference = function (state) { return state.unreadMessagesPreferences; };
