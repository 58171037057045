export var LOAD_OWNED_EVENTS = '[Event] Load Owned Events';
export var LOAD_OWNED_EVENTS_OK = '[Event] Load Owned Events Ok';
export var LOAD_TRANSFERRED_EVENTS = '[Event] Load Transferred Events';
export var LOAD_TRANSFERRED_EVENTS_OK = '[Event] Load Transferred Events Ok';
export var LOAD_INVITED_EVENTS = '[Event] Load Invited Events';
export var LOAD_INVITED_EVENTS_OK = '[Event] Load Invited Events Ok';
export var LOAD_SELECTED_EVENT = '[Event] Load Single Event';
export var LOAD_SELECTED_EVENT_OK = '[Event] Load Single Event Ok';
export var UPDATE_EVENT = '[Event] Update Event';
export var UPDATE_EVENT_OK = '[Event] Update Event Ok';
export var LOAD_ALTERNATE_LEADERS = '[Event] Load Alternate Leaders';
export var LOAD_ALTERNATE_LEADERS_OK = '[Event] Load Alternate Leaders Ok';
export var ADD_ALTERNATE_LEADER = '[Event] Add Alternate Leader';
export var ADD_ALTERNATE_LEADER_OK = '[Event] Add Alternate Leader Ok';
export var SET_ALTERNATE_LEADER_STATUS = '[Event] Set Alternate Leader Status';
export var SET_ALTERNATE_LEADER_STATUS_OK = '[Event] Set Alternate Leader Status Ok';
export var REQUEST_SHORT_URL = '[Event] Request Short Url';
export var CONVERT_TO_TEMPLATE = '[Event] Convert to Template';
export var CONVERT_TO_TEMPLATE_OK = '[Event] Convert to Template Ok';
export var CLONE_EVENT = '[Event] Clone Event';
export var CLONE_EVENT_OK = '[Event] Clone Event Ok';
export var ASSIGN_EVENT = '[Event] Assign Event';
export var CLONE_AND_ASSIGN_EVENT = '[Event] Clone and Assign Event';
export var ASSIGN_EVENT_OK = '[Event] Assign Event Ok';
export var CLONE_AND_ASSIGN_EVENT_OK = '[Event] Clone and Assign Event Ok';
export var ASSIGN_EVENT_ERROR = '[Event] Assign Event Error';
export var CLEAR_SELECTED_EVENT = '[Event] Clear Selected Event';
export var CLEAR_SELECTED_EVENT_DATA = '[Event] Clear Selected Event Data';
export var LOAD_EVENT_OVERVIEW = '[Event] Load Event Overview';
export var LOAD_EVENT_OVERVIEW_OK = '[Event] Load Event Overview Ok';
export var AUTHENTICATION_SERVICE_LOGOUT = '[Authentication Service] Logout';
var LoadOwnedEvents = /** @class */ (function () {
    function LoadOwnedEvents() {
        this.type = LOAD_OWNED_EVENTS;
    }
    return LoadOwnedEvents;
}());
export { LoadOwnedEvents };
var LoadOwnedEventsOk = /** @class */ (function () {
    function LoadOwnedEventsOk(payload) {
        this.payload = payload;
        this.type = LOAD_OWNED_EVENTS_OK;
    }
    return LoadOwnedEventsOk;
}());
export { LoadOwnedEventsOk };
var LoadTransferredEvents = /** @class */ (function () {
    function LoadTransferredEvents() {
        this.type = LOAD_TRANSFERRED_EVENTS;
    }
    return LoadTransferredEvents;
}());
export { LoadTransferredEvents };
var LoadTransferredEventsOk = /** @class */ (function () {
    function LoadTransferredEventsOk(payload) {
        this.payload = payload;
        this.type = LOAD_TRANSFERRED_EVENTS_OK;
    }
    return LoadTransferredEventsOk;
}());
export { LoadTransferredEventsOk };
var LoadInvitedEvents = /** @class */ (function () {
    function LoadInvitedEvents() {
        this.type = LOAD_INVITED_EVENTS;
    }
    return LoadInvitedEvents;
}());
export { LoadInvitedEvents };
var LoadInvitedEventsOk = /** @class */ (function () {
    function LoadInvitedEventsOk(payload) {
        this.payload = payload;
        this.type = LOAD_INVITED_EVENTS_OK;
    }
    return LoadInvitedEventsOk;
}());
export { LoadInvitedEventsOk };
var LoadSelectedEvent = /** @class */ (function () {
    function LoadSelectedEvent(payload) {
        this.payload = payload;
        this.type = LOAD_SELECTED_EVENT;
    }
    return LoadSelectedEvent;
}());
export { LoadSelectedEvent };
var LoadSelectedEventOk = /** @class */ (function () {
    function LoadSelectedEventOk(payload) {
        this.payload = payload;
        this.type = LOAD_SELECTED_EVENT_OK;
    }
    return LoadSelectedEventOk;
}());
export { LoadSelectedEventOk };
var UpdateEvent = /** @class */ (function () {
    function UpdateEvent(payload) {
        this.payload = payload;
        this.type = UPDATE_EVENT;
    }
    return UpdateEvent;
}());
export { UpdateEvent };
var UpdateEventOk = /** @class */ (function () {
    function UpdateEventOk(payload) {
        this.payload = payload;
        this.type = UPDATE_EVENT_OK;
    }
    return UpdateEventOk;
}());
export { UpdateEventOk };
var LoadAlternateLeaders = /** @class */ (function () {
    function LoadAlternateLeaders(payload) {
        this.payload = payload;
        this.type = LOAD_ALTERNATE_LEADERS;
    }
    return LoadAlternateLeaders;
}());
export { LoadAlternateLeaders };
var LoadAlternateLeadersOk = /** @class */ (function () {
    function LoadAlternateLeadersOk(payload) {
        this.payload = payload;
        this.type = LOAD_ALTERNATE_LEADERS_OK;
    }
    return LoadAlternateLeadersOk;
}());
export { LoadAlternateLeadersOk };
var AddAlternateLeader = /** @class */ (function () {
    function AddAlternateLeader(payload) {
        this.payload = payload;
        this.type = ADD_ALTERNATE_LEADER;
    }
    return AddAlternateLeader;
}());
export { AddAlternateLeader };
var AddAlternateLeaderOk = /** @class */ (function () {
    function AddAlternateLeaderOk(payload) {
        this.payload = payload;
        this.type = ADD_ALTERNATE_LEADER_OK;
    }
    return AddAlternateLeaderOk;
}());
export { AddAlternateLeaderOk };
var SetAlternateLeaderStatus = /** @class */ (function () {
    function SetAlternateLeaderStatus(payload) {
        this.payload = payload;
        this.type = SET_ALTERNATE_LEADER_STATUS;
    }
    return SetAlternateLeaderStatus;
}());
export { SetAlternateLeaderStatus };
var SetAlternateLeaderStatusOk = /** @class */ (function () {
    function SetAlternateLeaderStatusOk(payload) {
        this.payload = payload;
        this.type = SET_ALTERNATE_LEADER_STATUS_OK;
    }
    return SetAlternateLeaderStatusOk;
}());
export { SetAlternateLeaderStatusOk };
var RequestShortUrl = /** @class */ (function () {
    function RequestShortUrl(payload) {
        this.payload = payload;
        this.type = REQUEST_SHORT_URL;
    }
    return RequestShortUrl;
}());
export { RequestShortUrl };
var ConvertToTemplate = /** @class */ (function () {
    function ConvertToTemplate(payload) {
        this.payload = payload;
        this.type = CONVERT_TO_TEMPLATE;
    }
    return ConvertToTemplate;
}());
export { ConvertToTemplate };
var ConvertToTemplateOk = /** @class */ (function () {
    function ConvertToTemplateOk(payload) {
        this.payload = payload;
        this.type = CONVERT_TO_TEMPLATE_OK;
    }
    return ConvertToTemplateOk;
}());
export { ConvertToTemplateOk };
var CloneEvent = /** @class */ (function () {
    function CloneEvent(payload) {
        this.payload = payload;
        this.type = CLONE_EVENT;
    }
    return CloneEvent;
}());
export { CloneEvent };
var CloneEventOk = /** @class */ (function () {
    function CloneEventOk(payload) {
        this.payload = payload;
        this.type = CLONE_EVENT_OK;
    }
    return CloneEventOk;
}());
export { CloneEventOk };
var AssignEvent = /** @class */ (function () {
    function AssignEvent(payload) {
        this.payload = payload;
        this.type = ASSIGN_EVENT;
    }
    return AssignEvent;
}());
export { AssignEvent };
var CloneAndAssignEvent = /** @class */ (function () {
    function CloneAndAssignEvent(payload) {
        this.payload = payload;
        this.type = CLONE_AND_ASSIGN_EVENT;
    }
    return CloneAndAssignEvent;
}());
export { CloneAndAssignEvent };
var AssignEventOk = /** @class */ (function () {
    function AssignEventOk(payload) {
        this.payload = payload;
        this.type = ASSIGN_EVENT_OK;
    }
    return AssignEventOk;
}());
export { AssignEventOk };
var CloneAndAssignEventOk = /** @class */ (function () {
    function CloneAndAssignEventOk(payload) {
        this.payload = payload;
        this.type = ASSIGN_EVENT_OK;
    }
    return CloneAndAssignEventOk;
}());
export { CloneAndAssignEventOk };
var AssignEventError = /** @class */ (function () {
    function AssignEventError(payload) {
        this.payload = payload;
        this.type = ASSIGN_EVENT_ERROR;
    }
    return AssignEventError;
}());
export { AssignEventError };
var ClearSelectedEvent = /** @class */ (function () {
    function ClearSelectedEvent() {
        this.type = CLEAR_SELECTED_EVENT;
    }
    return ClearSelectedEvent;
}());
export { ClearSelectedEvent };
var ClearSelectedEventData = /** @class */ (function () {
    function ClearSelectedEventData() {
        this.type = CLEAR_SELECTED_EVENT_DATA;
    }
    return ClearSelectedEventData;
}());
export { ClearSelectedEventData };
var LoadEventOverview = /** @class */ (function () {
    function LoadEventOverview(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_OVERVIEW;
    }
    return LoadEventOverview;
}());
export { LoadEventOverview };
var LoadEventOverviewOk = /** @class */ (function () {
    function LoadEventOverviewOk(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_OVERVIEW_OK;
    }
    return LoadEventOverviewOk;
}());
export { LoadEventOverviewOk };
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = AUTHENTICATION_SERVICE_LOGOUT;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
