import { FacebookService } from './facebook.service';
import { EventEmitter, OnInit } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
var FacebookLoginComponent = /** @class */ (function () {
    function FacebookLoginComponent(_service, configService) {
        this._service = _service;
        this.configService = configService;
        this.loginComplete = new EventEmitter();
        this.facebookButtonImage = require("assets/buttons/btn-facebook.png");
        this.appId = this.configService.config.facebookAppId; // '143944522840009'
        this.permissions = this.configService.config.facebookPermissions; // 'public_profile,email'
        this.fields = this.configService.config.facebookFields; // 'id,first_name,last_name,name,email'
    }
    FacebookLoginComponent.prototype.ngOnInit = function () {
        FB.init({
            appId: this.appId,
            autoLogAppEvents: true,
            xfbml: true,
            cookie: true,
            version: 'v5.0'
        });
        FB.AppEvents.logPageView();
    };
    FacebookLoginComponent.prototype.facebookLogin = function () {
        var _this = this;
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                _this.onFacebookResponse(response);
            }
            else {
                FB.login(function (res) {
                    _this.onFacebookResponse(res);
                }, { scope: _this.permissions, return_scopes: true });
            }
        });
        return false;
    };
    FacebookLoginComponent.prototype.onLoginComplete = function (response, accessToken) {
        var hasEmail = false;
        if (response.email) {
            hasEmail = true;
        }
        var user = {
            Id: response.id,
            AccessToken: accessToken,
            Email: response.email,
            HasEmail: hasEmail,
            FirstName: response.first_name,
            LastName: response.last_name
        };
        //console.log(user);
        this.loginComplete.emit(user);
    };
    FacebookLoginComponent.prototype.onFacebookResponse = function (response) {
        var _this = this;
        //console.log(response);
        if (!response) {
            //console.log('Facebook callback returned null value');
            return;
        }
        // if (!response.authResponse) {
        //     // error
        //     //console.log('User cancelled login or did not fully authorize.');
        // }
        if (response.authResponse) {
            var accessToken_1 = response.authResponse.accessToken;
            FB.api('/me', 'get', function (resp) {
                _this.onLoginComplete(resp, accessToken_1);
            }, { fields: this.fields });
        }
    };
    return FacebookLoginComponent;
}());
export { FacebookLoginComponent };
