import { Attendee, AttendeeSortDirection } from 'app/store/models/attendee';

type IsValid = { isValid: boolean; }
export type AttendeeUpsertRequestValid = AttendeeUpsertRequest & IsValid;

export class AttendeeUpsertRequest {

    userId: number | null;
    eventAttendeeId: number | null;
    newEventAttendeeId: number | null;
    public get combinedEventAttendeeId(): number {
        if (this.eventAttendeeId > 0) return this.eventAttendeeId;
        if (this.newEventAttendeeId > 0) return -this.newEventAttendeeId;
        return 0;
    }

    isParentResponsible: boolean;
    parentUserId: number | null;
    parentAttendeeId: number | null;
    newEventAttendeeIdParent: number | null;
    parentFullName: string; // This is only used for visual display

    fullName: string;
    emailPhone: string;
    email: string;
    phone: string;
    guestCount: number;
    eventId: number;
    status: string;
    verdict: string;    // Used for SendGrid Email Validation result
    isDuplicate: boolean;   // Used for duplicate error modal display

    constructor(
        userId: number | null,
        eventAttendeeId: number | null,
        newEventAttendeeId: number | null,
        isParentResponsible: boolean,
        parentUserId: number | null,
        parentAttendeeId: number | null,
        newEventAttendeeIdParent: number | null,
        fullName: string,
        emailPhone: string,
        email: string,
        phone: string,
        guestCount: number,
        eventId: number,
        status: string,
    ) {
        this.userId = userId;
        this.eventAttendeeId = eventAttendeeId;
        this.newEventAttendeeId = newEventAttendeeId;
        this.isParentResponsible = isParentResponsible;
        this.parentUserId = parentUserId;
        this.parentAttendeeId = parentAttendeeId;
        this.newEventAttendeeIdParent = newEventAttendeeIdParent;
        this.fullName = fullName;
        this.emailPhone = emailPhone;
        this.email = email;
        this.phone = phone;
        this.guestCount = guestCount;
        this.eventId = eventId;

        this.parentFullName = "";
        this.status = status;
    }

    public isEqualTo(a: AttendeeUpsertRequest): boolean {
        // NOTE: null === null and 0 === 0, but for ids we need null === 0 and 0 === null,
        //       this is because sometimes the api returns 0 when send null or vice-versa
        return (
            (this.userId === a.userId || (this.userId === 0 && a.userId === null) || (this.userId === null && a.userId === 0))
        && (this.eventAttendeeId === a.eventAttendeeId || (this.eventAttendeeId === 0 && a.eventAttendeeId === null) || (this.eventAttendeeId === null && a.eventAttendeeId === 0))
        && (this.newEventAttendeeId === a.newEventAttendeeId || (this.newEventAttendeeId === 0 && a.newEventAttendeeId === null) || (this.newEventAttendeeId === null && a.newEventAttendeeId === 0))
        && this.isParentResponsible === a.isParentResponsible
        && (this.parentUserId === a.parentUserId || (this.parentUserId === 0 && a.parentUserId === null) || (this.parentUserId === null && a.parentUserId === 0))
        && (this.parentAttendeeId === a.parentAttendeeId || (this.parentAttendeeId === 0 && a.parentAttendeeId === null) || (this.parentAttendeeId === null && a.parentAttendeeId === 0))
        && (this.newEventAttendeeIdParent === a.newEventAttendeeIdParent || (this.newEventAttendeeIdParent === 0 && a.newEventAttendeeIdParent === null) || (this.newEventAttendeeIdParent === null && a.newEventAttendeeIdParent === 0))
        && this.fullName === a.fullName
        // TODO: Rethink the messy matching '==' in emailPhone, email, and phone, this could cause problems
        && this.emailPhone == a.emailPhone
        && this.email == a.email
        && this.phone == a.phone
        && this.guestCount === a.guestCount
        && (this.eventId === a.eventId || (this.eventId === 0 && a.eventId === null) || (this.eventId === null && a.eventId === 0))
        // NOTE: eventId should always exist, but adding a 0 === null and null === 0 check doesn't hurt
        );
    }

    public isSameAttendeeAs(a: AttendeeUpsertRequest): boolean {
        // both could be new
        if (this.isNewAttendee && a.isNewAttendee) {
            // Then both 'this' and 'a' are new attendees (meaning both have a valid newEventAttendeeId)
            return this.newEventAttendeeId === a.newEventAttendeeId;
        } else if (this.isNewAttendee) {
            // only 'this' is new attendee (meaning this should have a valid newEventAttendeeId, but a might not)
            if (typeof a.newEventAttendeeId !== 'number' || a.newEventAttendeeId < 1) return false;
            return this.newEventAttendeeId === a.newEventAttendeeId;
        } else if (a.isNewAttendee) {
            // only 'a' is new attendee (meaning a should have a valid newEventAttendeeId, but this might not)
            if (typeof this.newEventAttendeeId !== 'number' || this.newEventAttendeeId < 1) return false;
            return this.newEventAttendeeId === a.newEventAttendeeId;
        } else {
            // neither are new attendees (meaning both should have a valid eventAttendeeId)
            // This is the only case where eventAttendeeId can be used to compare
            return this.eventAttendeeId === a.eventAttendeeId;
        }
    }

    public get isParent(): boolean  { return !this.isParentResponsible; }
    public get isChild(): boolean   { return this.isParentResponsible; }
    // public get isChildOfNewParent() { return this.isChild && (typeof this.newEventAttendeeIdParent === 'number') && this.newEventAttendeeIdParent > 0; }
    public get isChildOfNewParent(): boolean { return this.isChild && (typeof this.parentUserId !== 'number' || this.parentUserId < 1); }
    public get isChildOfExistingParent() { return this.isChild && !this.isChildOfNewParent; }

    public get isNewAttendee(): boolean { return typeof this.eventAttendeeId !== 'number' || this.eventAttendeeId < 1; }

    public static createNewAttendee(
                    newEventAttendeeId: number, 
                    fullName: string, 
                    emailPhone: string, 
                    email: string,
                    phone: string,
                    eventId: number,
                    guestCount: number = 0,
                    status: string): AttendeeUpsertRequest {
        return new AttendeeUpsertRequest(null, null, newEventAttendeeId, false, null, null, null, fullName, emailPhone, email, phone, guestCount, eventId, status);
    }

    public static createFromAttendee(a: Attendee): AttendeeUpsertRequest {
        return new AttendeeUpsertRequest(a.id, a.eventAttendeeId, null, a.isParentResponsible, a.parentUserId, a.parentAttendeeId, null, a.fullName, '', a.email, a.phone, a.guestCount, a.eventId, a.status);
    }

    public static createNewChildAttendee(
                                newEventAttendeeId: number,
                                fullName: string,
                                parent: AttendeeUpsertRequest,
                                eventId: number): AttendeeUpsertRequest {
        let returnVal: AttendeeUpsertRequest;
        if (parent.isNewAttendee) {
            returnVal = AttendeeUpsertRequest.createNewPlusOneWithNewParent(newEventAttendeeId, fullName, '', '', '', parent.newEventAttendeeId, eventId, '');
        } else {
            returnVal = AttendeeUpsertRequest.createNewPlusOneWithExistingParent(newEventAttendeeId, fullName, '', '', '', parent.eventAttendeeId, parent.eventAttendeeId, eventId, '');
        }
        return returnVal;
    }

    public static createNewPlusOneWithExistingParent(
                                    newEventAttendeeId: number, 
                                    fullName: string,
                                    emailPhone: string,
                                    email: string,
                                    phone: string,
                                    parentUserId: number,
                                    parentAttendeeId: number,
                                    eventId: number,
                                    status: string): AttendeeUpsertRequest {
        return new AttendeeUpsertRequest(null, null, newEventAttendeeId, true, parentUserId, parentAttendeeId, null, fullName, emailPhone, email, phone, 0, eventId, status);
    }

    public static createNewPlusOneWithNewParent(
                                newEventAttendeeId: number, 
                                fullName: string,
                                emailPhone: string,
                                email: string,
                                phone: string,
                                newEventAttendeeIdParent: number,
                                eventId: number,
                                status: string): AttendeeUpsertRequest {
        return new AttendeeUpsertRequest(null, null, newEventAttendeeId, true, null, null, newEventAttendeeIdParent, fullName, emailPhone, email, phone, 0, eventId, status);
    }


    public static copy(a: AttendeeUpsertRequest): AttendeeUpsertRequest {
        return new AttendeeUpsertRequest(a.userId, a.eventAttendeeId, a.newEventAttendeeId, a.isParentResponsible, a.parentUserId, a.parentAttendeeId, a.newEventAttendeeIdParent, a.fullName, a.emailPhone, a.email, a.phone, a.guestCount, a.eventId, a.status);
    }

    public static createAttendeeFromAttendee(a: AttendeeUpsertRequest): AttendeeUpsertRequest { 
        return AttendeeUpsertRequest.copy(a); 
    }

    // ===============================================================================
    //                                Sorting Logic
    // ===============================================================================

    public static getSortedAttendees(attendees: AttendeeUpsertRequest[], sortDirection: AttendeeSortDirection): AttendeeUpsertRequest[]  {
        // get array of parents
        // sort array of parents
        // iterate through array of parents, and get children (best way is Map<parentId, Array<child>>), but need 2 parent->child maps for new and existing

        const tupleResult = this.createParentListAndChildrenMapsFromAttendees(attendees);

        const parents: AttendeeUpsertRequest[] = tupleResult[0];
        const existingParentToChildMap: Map<number, Array<AttendeeUpsertRequest>> = tupleResult[1];
        const newParentToChildMap: Map<number, Array<AttendeeUpsertRequest>> = tupleResult[2];
    
        this.sortParentAttendeesInPlace(parents, sortDirection);
        // TODO: Sort children amoungst each other?  Could iterate each array in the maps, and sort in place
        // KC 2021-04-12: This behavior has not been defined by the business yet, so I'll just leave it alone for now.
    
        // Generate final sorted attendee list using parents and children maps
        const sortedAttendees: AttendeeUpsertRequest[] = this.mergeParentsAndChildrenMapsIntoAttendeeList(parents, existingParentToChildMap, newParentToChildMap);

        return sortedAttendees;
    }

    public static compare(a: AttendeeUpsertRequest, b: AttendeeUpsertRequest, sortDirection: AttendeeSortDirection): number {
        switch (sortDirection) {
            default:
            case 'databaseOrder':
            {
                if (a.isNewAttendee && b.isNewAttendee) {
                    // If both new, sort by newEventAttendeeId DESCENDING (newest should appear higher)
                    if (a.newEventAttendeeId > b.newEventAttendeeId) return -1;
                    if (a.newEventAttendeeId < b.newEventAttendeeId) return 1;
                    return 0;
                } else if (!a.isNewAttendee && !b.isNewAttendee) {
        
                    // both existing, could be any of these cases:
                        // both plain existing (sort by eventAttendeeId)
                        // one newly saved existing, one plain existing (sort newly saved to top)
                        // both newly saved existing (sort by inverse newEventAttendeeId)
        
                    if ((a.newEventAttendeeId === null || a.newEventAttendeeId <= 0)
                    && (b.newEventAttendeeId === null || b.newEventAttendeeId <= 0)) {
                    // Then both existing attendees were existing before loading the page
                    // sort by eventAttendeeId ASCENDING (newest should appear last)
                    if (a.eventAttendeeId < b.eventAttendeeId) return -1;
                    if (a.eventAttendeeId > b.eventAttendeeId) return 1;
                    return 0;
                    } else if (a.newEventAttendeeId > 0 && b.newEventAttendeeId > 0) {
                    // Then both existing attendees are newly saved (did not exist before loading the page)
                    // sort by newEventAttendeeId DESCENDING (newest should appear higher)
                    if (a.newEventAttendeeId > b.newEventAttendeeId) return -1;
                    if (a.newEventAttendeeId < b.newEventAttendeeId) return 1;
                    return 0;
                    } else {
                    // Then one attendee is newly saved, another is not
                    // sort newly saved existing before plain existing
                    if (a.newEventAttendeeId > 0) return -1;
                    return 1;
                    }
                } else {
                    // One is new and one is existing, sort new before existing
                    if (a.isNewAttendee) return -1;
                    return 1;
                }
            }
            case 'nameAsc':
            {
                return a.fullName.localeCompare(b.fullName);
            }
            case 'nameDesc':
            {
                return b.fullName.localeCompare(a.fullName);
            }
            case 'emailPhoneAsc':
            {
                return a.emailPhone.localeCompare(b.emailPhone);
            }
            case 'emailPhoneDesc':
            {
                return b.emailPhone.localeCompare(a.emailPhone);
            }
        }
    }

    private static mergeParentsAndChildrenMapsIntoAttendeeList(parents: AttendeeUpsertRequest[], existingParentToChildMap: Map<number, Array<AttendeeUpsertRequest>>, newParentToChildMap: Map<number, Array<AttendeeUpsertRequest>>): AttendeeUpsertRequest[] {
        // Generate final sorted attendee list using parents and children maps
        const sortedAttendees: AttendeeUpsertRequest[] = [];
        for (const parent of parents) {
            sortedAttendees.push(parent);
            if (parent.newEventAttendeeId > 0) {
                const childArray = newParentToChildMap.get(parent.newEventAttendeeId);
                
                if (childArray) {
                for (const child of childArray) {
                    sortedAttendees.push(child);
                }
                }
            }
            if (parent.eventAttendeeId > 0) {
                const childArray = existingParentToChildMap.get(parent.eventAttendeeId);
                if (childArray) {
                for (const child of childArray) {
                    sortedAttendees.push(child);
                }
                }
            }
        }
        return sortedAttendees;
    }

    private static createParentListAndChildrenMapsFromAttendees(attendees: AttendeeUpsertRequest[]): [AttendeeUpsertRequest[], Map<number, Array<AttendeeUpsertRequest>>, Map<number, Array<AttendeeUpsertRequest>>] {
    
        const parents: AttendeeUpsertRequest[] = [];
        const existingParentToChildMap = new Map<number, Array<AttendeeUpsertRequest>>();
        const newParentToChildMap = new Map<number, Array<AttendeeUpsertRequest>>();

        for (const attendee of attendees) {
        if (attendee.isParentResponsible) {
            // add child to appropriate parent->child map
            if (attendee.parentUserId === null || attendee.parentUserId < 1) {
            // then this is a child of a new parent
            let childArray = newParentToChildMap.get(attendee.newEventAttendeeIdParent);
            if (!childArray) childArray = []; // Initialize array if this is the parent's first child
            childArray.push(attendee);
            newParentToChildMap.set(attendee.newEventAttendeeIdParent, childArray);
            } else {
            // then this is a child of an existing parent
            let childArray = existingParentToChildMap.get(attendee.parentUserId);
            if (!childArray) childArray = []; // Initialize array if this is the parent's first child
            childArray.push(attendee);
            existingParentToChildMap.set(attendee.parentUserId, childArray);
            }
        } else {
            parents.push(attendee);
        }
        }

        return [parents, existingParentToChildMap, newParentToChildMap];
    }

    private static sortParentAttendeesInPlace(parentAttendees: AttendeeUpsertRequest[], sortDirection: AttendeeSortDirection) {
        parentAttendees.sort((a, b) => AttendeeUpsertRequest.compare(a, b, sortDirection));
    }

    // ===============================================================================
    //                              END Sorting Logic
    // ===============================================================================
}
