import { Component, OnInit} from '@angular/core';

@Component({
    selector: 'ba-loader',
    styleUrls: ['./baLoader.component.scss'],
    templateUrl: './baLoader.component.html',
})

export class BaLoader {

}