import { BaMsgCenterService } from './baMsgCenter.service';
var BaMsgCenter = /** @class */ (function () {
    function BaMsgCenter(_baMsgCenterService) {
        this._baMsgCenterService = _baMsgCenterService;
        this.notifications = this._baMsgCenterService.getNotifications();
        this.messages = this._baMsgCenterService.getMessages();
    }
    return BaMsgCenter;
}());
export { BaMsgCenter };
