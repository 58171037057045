import { map } from 'rxjs/operators';
import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
var SurveyResponseService = /** @class */ (function () {
    function SurveyResponseService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
        this.previousUrl = "";
    }
    // TODO: The methods here should be used in ngrx flow, currently they are called directly in components
    SurveyResponseService.prototype.getSurveyDetails = function (surveyId) {
        return this.http.get(this.URLs._getSurveyResponses(surveyId)).pipe(handleGTResponse());
    };
    SurveyResponseService.prototype.updateSurvey = function (updatedSurvey) {
        return this.http.post(this.URLs._updateSurvey(), updatedSurvey).pipe(handleGTResponse());
    };
    // TODO: This is deprecated, instead use event-details.service.ts getAttendees method
    SurveyResponseService.prototype.getSurveyInviteeDetails = function (packageId) {
        return this.http.get(this.URLs._packageAttendeeDetails(packageId)).pipe(handleGTResponse());
    };
    SurveyResponseService.prototype.getSupplierURL = function (supplierName) {
        return this.http.get(this.URLs._getSupplierURL(supplierName)).pipe(map(function (res) {
            if (res.Result) {
                return res;
            }
        }));
    };
    return SurveyResponseService;
}());
export { SurveyResponseService };
