<div class="gled-add-activity-restaurant">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <p class="title" id="stepOne" [ngClass]="{'clickable':steps.stepOne.clickable,'title-margin':isFirstFeature}">
            {{ !!isFirstFeature ? '1. Tell us about your event' : '1. Tell us more about this activity'}}
            <i class="fa fa-check step-check" *ngIf="steps.stepOne.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-add-on> begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="feature-add-on">
            <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
            <div class="form-group" *ngIf="!isFirstFeature">
                <grouptools-label for="isRequired" class="feature-required-popover"
                    [helperText]="'At least one activity is required. A required activity means that all attendees must opt-in. An attendee can opt in to an optional activity at any time. This cannot be changed once invites have been sent.'"
                    [isQuestion]="true">
                    <label>Is this activity required?</label>
                </grouptools-label>
                <input type="radio" formControlName="isRequired" value="Yes"><span class="required-opt">Yes</span>
                <input type="radio" formControlName="isRequired" value="No"> <span class="required-opt">No</span>
                <label for="isRequired" *ngIf="formErrors.isRequired"
                    class="error-text">{{formErrors.isRequired}}</label>
            </div>

            <div class="form-group" [class.has-error]="formErrors.ComponentName">
                <grouptools-label for="addRestaurantName" [helperText]="'Name of the activity.'"><label>Restaurant
                        Name:</label></grouptools-label>
                <input type="text" class="form-control" id="addRestaurantName" placeholder=""
                    formControlName="ComponentName">
                <label for="addRestaurantName" *ngIf="formErrors.ComponentName"
                    class="error-text">{{formErrors.ComponentName}}</label>
            </div>

            <div class="form-group less-form-bottom">
                <div class="row">
                    <div class="col-sm-6 less-right" [class.has-error]="formErrors.ComponentOccurDate">
                        <label for="ComponentOccurDate">Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                formControlName="ComponentOccurDate" ngbDatepicker #ComponentOccurDate="ngbDatepicker"
                                container="body">
                            <div class="input-group-addon"
                                (click)="ComponentOccurDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, ComponentOccurDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="ComponentOccurDate" *ngIf="formErrors.ComponentOccurDate"
                            class="error-text">{{formErrors.ComponentOccurDate}}</label>
                    </div>
                    <div class="col-sm-6 less-left">
                        <label for="addRestaurantOccurTime">Time:</label>
                        <div class="input-group">
                            <input type="time" class="form-control" id="addRestaurantOccurTime"
                                formControlName="ComponentOccurTime" style="height:46px;">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" [class.has-error]="formErrors.ComponentDesc">
                <grouptools-label for="addRestaurantDesc"
                    [helperText]="'Write something enticing about the activity. This will help invitees understand what the activity is all about and what it may include.'">
                    <label>{{ !!this.isFirstFeature ? 'Add an enticing description:' : 'Give your activity a description:' }}</label>
                </grouptools-label>
                <textarea class="form-control" id="addRestaurantDesc"
                    placeholder="{{ !!this.isFirstFeature ? 'Be descriptive, this is what your users will see when you send an invitation. ' : 'Describe what this activity is all about...' }}"
                    formControlName="ComponentDesc"></textarea>
                <label for="addRestaurantDesc" *ngIf="formErrors.ComponentDesc"
                    class="error-text">{{formErrors.ComponentDesc}}</label>
            </div>

            <div class="form-group" [class.has-error]="formErrors.ComponentVenueLocation">
                <label for="addRestaurantVenueLocation">Location / Address:</label>
                <!-- <input ngx-google-places-autocomplete [options]='options' class="form-control"
                    id="addRestaurantVenueLocation" placeholder="What's the address?"
                    formControlName="ComponentVenueLocation" #placesRef="ngx-places"
                    (onAddressChange)="handleVenueAddressChange($event)" /> -->
                <!--<input type="text" class="form-control" id="addRestaurantVenueLocation" placeholder="What's the address?" formControlName="ComponentVenueLocation">-->
                <label for="addRestaurantVenueLocation" *ngIf="formErrors.ComponentVenueLocation"
                    class="error-text">{{formErrors.ComponentVenueLocation}}</label>
            </div>

            <div class="form-group less-form-bottom">
                <div class="row">
                    <div class="col-sm-12 less-right" [class.has-error]="formErrors.RSVPDueDate">
                        <label for="RSVPDueDate">RSVP Due Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp" formControlName="RSVPDueDate"
                                ngbDatepicker #RSVPDueDate="ngbDatepicker" container="body">
                            <div class="input-group-addon" (click)="RSVPDueDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, RSVPDueDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="RSVPDueDate" *ngIf="formErrors.RSVPDueDate"
                            class="error-text">{{formErrors.RSVPDueDate}}</label>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="!(inModal && !isEdit)">
                <div class="form-group row">
                    <div class="col-xs-12">
                        <button [disabled]="disableSave ? true : null" type="submit"
                            class="btn btn-secondary form-control"
                            [ngClass]="{'add-feature-continue-btn': !inModal, 'add-feature-continue-btn-modal': inModal}">
                            <span>{{inModal ? 'Save & Continue' : 'Next Step'}}</span>
                        </button>
                    </div>
                </div>
            </div> -->
            <!-- </form> -->
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-add-on> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <p class="title" id="stepTwo"
            [ngClass]="{'clickable':false}">
            2. {{ !!isFirstFeature ? 'Dates, pricing and availability' : 'Additional Information'}}
            <i class="fa fa-check step-check" *ngIf="steps.stepTwo.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-tickets> Begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="feature-tickets">
            <!-- <form [formGroup]="featureTicketsForm" (ngSubmit)="onSubmit()" class="feature-tickets-form"> -->
            <div class="feature-tickets-form">
                <div class="form-group" *ngIf="!multiOptions">
                    <div class="row">
                        <div class="less-right col-sm-6" [class.has-error]="formErrors.ComponentCapacity">
                            <grouptools-label for="ComponentCapacity" class="quantity-popover"
                                [helperText]="'Enter 999 for unlimited quantity. The number you enter here will the determine the maximum availability for this activity. Attendees will NOT be able to opt in to this activity once this number has been depleted. You can increase this number at any time.'">
                                <label class="label-less-margin">Quantity (If any):</label>
                            </grouptools-label>
                            <input type="number" class="form-control" min="1" id="ComponentCapacity"
                                formControlName="ComponentCapacity">
                            <label for="ComponentCapacity" *ngIf="formErrors.ComponentCapacity"
                                class="error-text">{{formErrors.ComponentCapacity}}</label>
                        </div>
                        <div class="col-sm-6 less-left" [class.has-error]="formErrors.ComponentPrice">
                            <grouptools-label for="ComponentPrice"
                                [helperText]="'Enter 0 if the event/activity is free. The amount you will charge your attendees opting into this activity.'">
                                <label class="label-less-margin">Price (If any):</label>
                            </grouptools-label>
                            <input [readonly]="isEdit && multiOptions" min="0" type="number" class="form-control"
                                id="ComponentPrice" formControlName="ComponentPrice">
                            <label for="ComponentPrice" *ngIf="formErrors.ComponentPrice"
                                class="error-text">{{formErrors.ComponentPrice}}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="MenuPDF">Description Of Menu:</label>
                    <textarea class="form-control" id="MenuPDF" formControlName="MenuPDF"></textarea>
                </div>

                <!-- Add ng-content so that we can show the price increase message in edit modal -->
                <ng-content></ng-content>

                <!-- <div *ngIf="!(inModal && !isEdit)"></div> -->
            </div>
            <!-- </form> -->
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-tickets> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <additional-info> Begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="additional-info">
            <div *ngIf="!isFirstFeature">
                <grouptools-label for="featurePhoto"
                    [helperText]="'The photo is optional but useful to give your attendees a visual of the activity. This could be a photo, a logo or whatever you think makes the activity interesting.'">
                </grouptools-label>
                <div class="feature-photo" (click)="changePhoto.emit()"
                    [ngClass]="{'exist-image-height':image,'none-image-height':!image}">
                    <p *ngIf="!image">Add {{photoType}}</p>
                    <img *ngIf="image" [src]="image">
                </div>
            </div>
            <div *ngIf="isFirstFeature">
                <label class="photo-title">Photos</label>
                <div class="feature-photo" (click)="changeEventPhoto.emit()"
                    [ngClass]="{'exist-image-height':eventImage,'none-image-height':!eventImage}">
                    <p *ngIf="!eventImage">Add an event photo. This is the primary photo of your event.</p>
                    <img *ngIf="eventImage" [src]="eventImage">
                </div>
                <div class="feature-photo" (click)="changePhoto.emit()"
                    [ngClass]="{'exist-image-height':image,'none-image-height':!image}">
                    <p *ngIf="!image">Add {{photoType}}. This is the secondary photo of your event.</p>
                    <img *ngIf="image" [src]="image">
                </div>
            </div>
        </div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <additional-info> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <div class="custom-feature-btns">
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-primary form-control save-btn" type="submit">
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-secondary form-control cancel-btn" type="button" (click)="cancelModal()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>