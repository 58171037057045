import { Action } from "@ngrx/store";
import { Attendee } from "../models/attendee";
import { GTCollectionPayload } from "../models/gtResponse";
import { LeaderSummary } from "../models/leader-summary";
import { UpdateInvitationResponse } from "../models/packages/UpdateInvitationResponse";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import { InviteEventAttendeesRequest } from "../models/requests/invite-event-attendees-request";
import { ConfigurationDTO } from '../models/configuration/ConfigurationDTO';
import { GetConfigurationRequest } from '../models/requests/get-configuration-request';

export enum ActionTypes {

    GeneralLoadConfiguration = "[General] Load Configuration",
    ConfigurationEffectLoadConfigurationSuccess = "[Configuration Effect] Load Configuration Success",
    ConfigurationEffectLoadConfigurationFailure = "[Configuration Effect] Load Configuration Failure",    
}

//----------------------------------------------------------------
export class GeneralLoadConfiguration implements Action {
    readonly type = ActionTypes.GeneralLoadConfiguration;
    constructor(public payload: {getConfigurationRequest: GetConfigurationRequest}) {}
}
export class ConfigurationEffectLoadConfigurationSuccess implements Action {
    readonly type = ActionTypes.ConfigurationEffectLoadConfigurationSuccess;
    constructor(public payload: {configurationState: ConfigurationDTO}) {}
}
export class ConfigurationEffectLoadConfigurationFailure implements Action {
    readonly type = ActionTypes.ConfigurationEffectLoadConfigurationFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------

export type Actions
    = GeneralLoadConfiguration
    | ConfigurationEffectLoadConfigurationSuccess
    | ConfigurationEffectLoadConfigurationFailure   
;