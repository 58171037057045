import { Action } from '@ngrx/store';

export const SET_SELECTED_ID = '[AttendeePackages] Set Selected ID';
export const LOGOUT = '[AttendeePackages] Logout';

export class SetSelectedPackageId implements Action {
  readonly type = SET_SELECTED_ID;

  constructor(public payload: number) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}


export type Actions
  = SetSelectedPackageId |
  Logout;

