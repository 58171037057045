import { URLConfig } from '../helpers/config.service';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FacebookService {

  constructor(
      private _http: HttpClient,
      private _Urls: URLConfig
    ) { }

    GetAccessTokenFromCode(code: string): Observable<any> {
        return this._http.get(this._Urls._getFacebookTokenFromCode(code));
    }
}
