import * as tslib_1 from "tslib";
import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
import { map } from 'rxjs/operators';
var UserService = /** @class */ (function () {
    function UserService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
    }
    UserService.prototype.getUser = function () {
        return this.http.get(this.URLs._getUser()).pipe(handleGTResponse(), map(function (user) {
            user.unreadMessagesPreferences = user.preferences.email.unreadMessages;
            return user;
        }) // map to store model
        );
    };
    UserService.prototype.updateUser = function (user) {
        // format for server
        var prefObj = {
            email: {
                unreadMessages: user.unreadMessagesPreferences
            }
        };
        var socialObj = Object.assign({}, {
            about: user.about
        });
        var formatted = tslib_1.__assign({}, user, { id: user.userID, preferences: prefObj, socialData: socialObj, preferencesJson: JSON.stringify(prefObj), socialDataJson: JSON.stringify(socialObj) });
        return this.http.put(this.URLs._updateUser(), formatted).pipe(handleGTResponse());
    };
    return UserService;
}());
export { UserService };
