<div class="horizontal-center-container">

  <div class="inner-container" *ngIf="true">

    <img *ngIf="!!supplierImage" [src]="supplierImage" alt="supplier img" class="supplier-img">
    <h4 class="mat-display-1 login-title">Create an event from your {{hostNameSupplierName == "" ? "supplier" : hostNameSupplierName}} order</h4>

    <!-- Conditionally put the form in a mat-card -->
    <ng-container *ngIf="shouldPutFormInCard then formInCardTemplate else formNotInCardTemplate"></ng-container>

    <ng-template #formInCardTemplate>
      <mat-card class="order-login-card"><ng-container *ngTemplateOutlet="formTemplate"></ng-container></mat-card>
    </ng-template>

    <ng-template #formNotInCardTemplate>
      <div class="order-login-card">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
      </div>
    </ng-template>

    <ng-template #formTemplate>
      <form [formGroup]="form">
        <loading-spinner *ngIf="shouldShowLoader"
          cssWidth="300px"
          cssHeight="150px"
        ></loading-spinner>
        <div *ngIf="shouldShowLoginForm" class="order-login-form-container">
          <div>
            <mat-form-field *ngIf="shouldShowVenueDropdown" class="form-field" [appearance]="matFormFieldAppearance">
              <mat-label>Venue</mat-label>
              <mat-select placeholder="Venue" formControlName="venueId">
                <mat-option *ngFor="let supplier of suppliers" [value]="supplier.SupplierId">{{supplier.SupplierName}}</mat-option>
              </mat-select>
              <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            </mat-form-field>
            <div *ngIf="!shouldShowVenueDropdown" class="form-field">
              <label>Venue: {{hostNameSupplierName}}</label>              
              <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            </div>
            <mat-form-field class="form-field" [appearance]="matFormFieldAppearance">
              <mat-label>Order Number</mat-label>
              <input matInput placeholder="Order Number" type="text" formControlName="orderNumber" (keyup.enter)="loginFormEnterKeyup()">
              <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            </mat-form-field>
          </div>
          <div *ngIf="authApiState === ApiState.Error" style="text-align: center;">
            <h1 *ngIf="!this.supplierErrorMessage" class="mat-subheading-2" style="color:red;">Failed to create event</h1>
            <h1 *ngIf="this.supplierErrorMessage" class="mat-subheading-2" style="color:red;">{{this.supplierErrorMessage}}</h1>
          </div>
          <div class="get-started-button-container">
            <button mat-raised-button
                    class="get-started-button"
                    type="button"
                    color="primary"
                    (click)="getStarted()">Get Started</button>
          </div>
        </div>
      </form>
    </ng-template>

  </div>
</div>
<!-- <powered-by-grouptools></powered-by-grouptools> -->

