import { ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromUser from './user';
import * as fromAttendeePackages from './attendeePackages';
import * as fromEvent from './event';
import * as fromShortUrl from './shortUrl.reducer';
import * as fromCreatedEvents from './createdEvents.reducer';
import * as fromCreatedForms from './createdForms.reducer';
import * as fromTransferredEvents from './transferredEvents.reducer';
import * as fromInvitedEvents from './invitedEvents.reducer';
import * as fromSelectedEvent from './selectedEvent.reducer';
import * as fromAlternateLeaders from './alternateLeaders.reducer';
import * as fromEventActivites from './eventActivies.reducer';
import * as fromGledEventItems from './gledEventItem.reducer';
import * as fromEventOverview from './eventOverview.reducer';
import * as fromGroupLeaderProfile from './groupLeaderProfile.reducer';
import * as fromModal from './modal.reducer';
import * as fromLoader from './loader.reducer';
import {reducer as EventItemReducer} from './eventItem.reducer';
import { reducer as PackageDetailsReducer } from './packageDetails.reducer';
import { reducer as ActionsReducer } from './actions.reducer';
import { reducer as ContactsReducer } from './contacts.reducer';
import { reducer as SurveysReducer } from './surveys.reducer';
import { reducer as AttendeesReducer } from './attendees.reducer';
import { reducer as ConfigurationReducer } from './configuration.reducer';
import * as fromGLEDState from './groupLeaderEventDetails.reducer';
import { reducer as PaymentReducer } from './payment.reducer';
import * as fromMessage from './message.reducer';
import { AppState } from '../models/app-state';
import { cloneDeep as _cloneDeep } from "lodash";


export type State = AppState;

export const reducers: ActionReducerMap<State> = {
  user: fromUser.reducer,
  createdEvents: fromCreatedEvents.reducer,
  createdForms: fromCreatedForms.reducer,
  transferredEvents: fromTransferredEvents.reducer,
  invitedEvents: fromInvitedEvents.reducer,
  eventItemState: EventItemReducer,
  gledState: fromGLEDState.reducer,
  selectedEvent: fromSelectedEvent.reducer,
  eventActivities: fromEventActivites.reducer,
  gledEventItemState: fromGledEventItems.reducer,
  eventOverview: fromEventOverview.reducer,
  groupLeaderProfile: fromGroupLeaderProfile.reducer,
  alternateGroupLeaders: fromAlternateLeaders.reducer,
  paymentState: PaymentReducer,
  messagesState: fromMessage.reducer,
  attendeePackages: fromAttendeePackages.reducer,
  event: fromEvent.reducer,
  shortUrl: fromShortUrl.reducer,
  modal: fromModal.reducer,
  loader: fromLoader.reducer,
  packageDetailsState: PackageDetailsReducer,
  actionsState: ActionsReducer,
  contactsState: ContactsReducer,
  surveysState: SurveysReducer,
  attendeesState: AttendeesReducer,
  configurationState: ConfigurationReducer,
}

// TODO: make this switch on whether the environment is dev or not
export const metaReducers: MetaReducer<State>[] = [storeFreeze]; 
// export const metaReducers: MetaReducer<State>[] = !environment.production ? [storeFreeze]: []; // from github docs

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state.
 */
export const getCreatedEvents = (state: State) => _cloneDeep(state.createdEvents); // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
export const getTransferredEvents = (state: State) => _cloneDeep(state.transferredEvents); // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
export const getCreatedForms = (state: State) => state.createdForms;
export const getInvitedEvents = (state: State) => _cloneDeep(state.invitedEvents); // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
export const getSelectedEvent = (state: State) => state.selectedEvent;
export const getSelectedEventCopy = (state: State) => _cloneDeep(state.selectedEvent); // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
export const getEventOverview = (state: State) => state.eventOverview;
export const getEventActivities = (state: State) => state.eventActivities;
export const getGledEventItemState = (state: State) => state.gledEventItemState;
export const getGroupLeaderProfile = (state: State) => state.groupLeaderProfile;
export const getAlternateLeaders = (state: State) => state.alternateGroupLeaders;

export const getGLEDState = (state: State) => state.gledState;
export const getEventMessagesNew = (state: State) => state.messagesState.eventMessagesNew;

export const getUserState = (state: State) => state.user;
export const getUserId = createSelector(getUserState, fromUser.getUserID);
export const getLoggedIn = createSelector(getUserState, fromUser.getLoggedIn);
export const getLoginStatus = createSelector(getUserState, fromUser.getLoginStatus);
export const getRegisteredStatus = createSelector(getUserState, fromUser.getRegisteredStatus);
export const getVerifiedStatus = createSelector(getUserState, fromUser.getVerifiedStatus);
export const getUserEmail = createSelector(getUserState, fromUser.getUserEmail);
export const getFirstName = createSelector(getUserState, fromUser.getFirstName);
export const getLastName = createSelector(getUserState, fromUser.getLastName);
export const getPhoneNumber = createSelector(getUserState, fromUser.getPhoneNumber);
export const getAddressState = createSelector(getUserState, fromUser.getAddressState);
export const getProfilePicture = createSelector(getUserState, fromUser.getProfilePicture);
export const getLeaderStatus = createSelector(getUserState, fromUser.getLeaderStatus);
export const getPaymentMethod = createSelector(getUserState, fromUser.getPaymentMethod);
export const getAlternatePaymentMethod = createSelector(getUserState, fromUser.getAlternatePaymentMethod);
export const getAbout = createSelector(getUserState, fromUser.getAbout);
export const getFormsByPackageId = (id) => createSelector(getCreatedForms, (forms) => (forms !== undefined) ? forms.filter(f => f.packageId == id) : []);
export const getUnreadEmailPreference = createSelector(getUserState, fromUser.getUnreadMessagesPreference);

export const getAttendeePackagesState = (state: State) => state.attendeePackages;
export const getAttendeeSelectedPackageId = createSelector(getAttendeePackagesState, fromAttendeePackages.getSelectedPackageId);

export const getEventState = (state: State) => state.event;
export const getSelectedEventId = createSelector(getEventState, fromEvent.getSelectedEventId);

export const getShortUrlState = (state: State) => state.shortUrl;
export const getShortUrl = createSelector(getShortUrlState, fromShortUrl.getShortUrl);

export const getModalState = (state: State) => state.modal;
export const getModalIsOpen = createSelector(getModalState, fromModal.getIsOpen);
export const getModalTitle = createSelector(getModalState, fromModal.getModalTitle);
export const getModalSize = createSelector(getModalState, fromModal.getModalSize);
export const getIsFullScreenMobile = createSelector(getModalState, fromModal.getIsFullScreenMobile);
export const getModalComponent = createSelector(getModalState, fromModal.getModalComponent);
export const getModalIsStatic = createSelector(getModalState, fromModal.getModalIsStatic);
export const getModalDisplayHeader = createSelector(getModalState, fromModal.getModalDisplayHeader);

export const getLoaderState = (state: State) => state.loader;
export const getLoaderIsSpinning = createSelector(getLoaderState, fromLoader.getIsLoading);
