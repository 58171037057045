import { EventItem, getGroupingDisplayName } from "app/store/models/eventItem";
import { createSelector, select, Store } from "@ngrx/store";
import { AppState } from "app/store/models/app-state";
import { attendeeUpsertEntitySelectors, eventItemEntitySelectors, mixedAttendeeEntitySelectors, showInfoEntitySelectors } from "app/store/entity-adapters/eventItem.adapter";
import { cloneDeep as _cloneDeep } from "lodash";
import { EventItemAndAttendeeDisjoint, EventItemAndAttendeeDisjointGroup, EventItemWithAttendee, EventItemWithAttendeeGroup } from "app/store/models/eventItemAssignedAttendee";
import { AttendeeUpsertRequest } from "app/store/models/requests/attendee-upsert-request";
import { ApiState, DataState } from "../models/apiData";
import { EventItemAssignment } from "app/store/models/eventItemState";
import { State as RootStoreState } from 'app/store/reducers';
import { Observable } from "rxjs";
import { map, takeWhile } from "rxjs/operators";


const getEventItemState = (state: AppState) => state.eventItemState;

// =================================================================================
//                          Event Item Entity Selectors
// =================================================================================
const getEventItemEntity = createSelector(
    getEventItemState,
    (eventItemState) => eventItemState.eventItems
);
const getEventItemEntities = createSelector(
    getEventItemEntity,
    (eventItemEntity) => eventItemEntitySelectors.selectEntities(eventItemEntity)
);
const getEventItemAll = createSelector(
    getEventItemEntity,
    eventItemEntity => eventItemEntitySelectors.selectAll(eventItemEntity)
);
const getEventItemMap = createSelector(
    getEventItemAll,
    eventItems => {
        let eventItemMap = new Map<number, EventItem>();
        for (const eventItem of eventItems) { eventItemMap.set(eventItem.id, eventItem); }
        return eventItemMap;
    }
);

const getEventItemDataState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.eventItems.dataState
);

export const getEventItems = createSelector(
    getEventItemState,
    eventItemState => eventItemEntitySelectors.selectAll(eventItemState.eventItems),
);

export const getEventItemsApiState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.eventItems.apiState,
);

export const getEventItemsCount = createSelector(
    getEventItems,
    eventItems => eventItems.length,
);

// =================================================================================
//                        Autosaving Attendee Entity Selectors
// =================================================================================

export const isAttendeeAutosavingInFlight = createSelector(
    getEventItemState,
    eventItemState => {
        const numberOfInFlightAttendees = mixedAttendeeEntitySelectors.selectTotal(eventItemState.inFlightAttendees);
        return numberOfInFlightAttendees > 0;
    }
);

export const getWaitingAttendees = createSelector(
    getEventItemState,
    eventItemState => mixedAttendeeEntitySelectors.selectAll(eventItemState.waitingAttendees)
);

export const getInvalidAttendees = createSelector(
    getEventItemState,
    eventItemState => mixedAttendeeEntitySelectors.selectAll(eventItemState.invalidAttendees)
);

export const areAttendeesWaitingToBeSaved = createSelector(
    getEventItemState,
    eventItemState => mixedAttendeeEntitySelectors.selectTotal(eventItemState.waitingAttendees) > 0
);

export const getSavedInputAttendeesDictionary = createSelector(
    getEventItemState,
    eventItemState => attendeeUpsertEntitySelectors.selectEntities(eventItemState.savedInputAttendees)
);

export const getSavedInputNewEventAttendeeIdMap = createSelector(
    getEventItemState,
    eventItemState => eventItemState.savedInputNewIdMap
);

export const getSavedInputNewEventAttendeeIdMapReverse = createSelector(
    getEventItemState,
    eventItemState => eventItemState.savedInputNewIdMapReverse
);

export const getInFlightAttendeesDictionary = createSelector(
    getEventItemState,
    eventItemState => mixedAttendeeEntitySelectors.selectEntities(eventItemState.inFlightAttendees)
);

// export const isAutosavingComplete = createSelector(
//     getEventItemState,
//     eventItemState => {
//         return mixedAttendeeEntitySelectors.selectTotal(eventItemState.waitingAttendees) <= 0
//             && mixedAttendeeEntitySelectors.selectTotal(eventItemState.inFlightAttendees) <= 0;
//     }
// );

// TODO: If the backend fails to respond, this will cause the UI to show a successful "Saved" indicator to the user
// To recreate, add a new attendee, break in the api before saving, and stop running the api
export const attendeeAutosavingState = createSelector(
    getEventItemState,
    eventItemState => {
        const isAutosavingComplete = mixedAttendeeEntitySelectors.selectTotal(eventItemState.waitingAttendees) <= 0
            && mixedAttendeeEntitySelectors.selectTotal(eventItemState.inFlightAttendees) <= 0;
        if (isAutosavingComplete) {
            return ApiState.Success;
        } else {
            return ApiState.InFlight;
        }
    }
);

// =================================================================================
//                            Deleted Attendee Selectors
// =================================================================================

export const getDeletedEventAttendeeIds = createSelector(
    getEventItemState,
    eventItemState => {
        return {
            deletedEventAttendeeIds: eventItemState.deletedEventAttendeeIds,
            deletedNewEventAttendeeIds: eventItemState.deletedNewEventAttendeeIds,
            failedToDeleteEventAttendeeIds: eventItemState.failedToDeleteEventAttendeeIds,
            failedToDeleteNewEventAttendeeIds: eventItemState.failedToDeleteNewEventAttendeeIds,
        };
    }
)

export const getFailedAutosaveEventAttendeeIds = createSelector(
    getEventItemState,
    eventItemState => {
        return {
            failedToAutosaveEventAttendeeIds: eventItemState.failedToAutosaveEventAttendeeIds,
            failedToAutosaveNewEventAttendeeIds: eventItemState.failedToAutosaveNewEventAttendeeIds,
        };
    }
)

// =================================================================================
//                            Attendee Entity Selectors
// =================================================================================
const getAttendeeEntity = createSelector(
    getEventItemState,
    eventItemState => eventItemState.attendees
);
const getAttendeeEntities = createSelector(
    getAttendeeEntity,
    attendeeEntity => attendeeUpsertEntitySelectors.selectEntities(attendeeEntity)
);
const getAttendeeAll = createSelector(
    getAttendeeEntity,
    attendeeEntity => attendeeUpsertEntitySelectors.selectAll(attendeeEntity)
);
export const getAttendeeAllWithParentInfo = createSelector(
    getAttendeeAll,
    getAttendeeEntities,
    (attendees, attendeeEntities) => {
        const attendeesWithParentInfo:AttendeeUpsertRequest[] = [];
        for (const attendee of attendees) {
            let attendeeCopy = AttendeeUpsertRequest.copy(attendee);
            // The attendee here is not a full AttendeeUpsertRequest, I suspect this is because ngrx entity adapters
            // strip all the getter/setter/methods from the object when modifying the collection (in the reducer).  So only attendeeCopy
            // has the isChild, isParent, etc...  getters/setters/methods that a full AttendeeUpsertRequest object should have.
            attendeesWithParentInfo.push(attendeeCopy);
            if (!attendeeCopy.isChild) continue; // Not a child attendee, skip
            let parentId = attendeeCopy.parentUserId;
            let parentAttendee = attendeeEntities[parentId];
            if (!parentAttendee) continue; // Could not find parent, skip
            attendeeCopy.parentFullName = parentAttendee.fullName;
        }
        return attendeesWithParentInfo;
    }
)
const getAttendeeMappedByEventAttendeeId = createSelector(
    getAttendeeAll,
    attendees =>  {
        const attendeeMap = new Map<number, AttendeeUpsertRequest>();
        for (const attendee of attendees) { attendeeMap.set(attendee.eventAttendeeId, attendee); }
        return attendeeMap;
    }
);

const getAttendeeDataState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.attendees.dataState
);

// =================================================================================
//                          Assignments Entity Selectors
// =================================================================================

export const getAssignments = createSelector(
    getEventItemAll,
    eventItems => {
        return eventItems.map(eventItem => { 
            return {
                eventItemId: eventItem.id,
                eventAttendeeId: eventItem.eventAttendeeId,
            } as EventItemAssignment; 
        });
    }
);

export const getAssignmentsApiState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.assignmentsState.apiState,
);

// =================================================================================
//                   Unsaved Updated Assignments Entity Selectors
// =================================================================================

const getAssignedEventAttendeeIdsSet = createSelector(
    getEventItems,
    eventItems => {
        const assignedEventAttendeeIdSet = new Set<number>();
        for (const eventItem of eventItems) {
            if (!!eventItem.eventAttendeeId) assignedEventAttendeeIdSet.add(eventItem.eventAttendeeId);
        }
        return assignedEventAttendeeIdSet;
    }
);

// =================================================================================
//                                Show List Selectors
// =================================================================================

export const getShowList = createSelector(
    getEventItemState,
    eventItemState => showInfoEntitySelectors.selectAll(eventItemState.showList),
);

export const getShowListApiState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.showList.apiState,
);

// =================================================================================
//                           Delivery Page Selection State
// =================================================================================

export const getDeliverySelectionState = createSelector(
    getEventItemState,
    getEventItems,
    (eventItemState, eventItems) => {
        const completeMap = new Map<number, boolean>();
        for (const eventItem of eventItems) {
            completeMap.set(eventItem.id, false);
        }
        for (const entry of eventItemState.deliveryPageSelectionState.entries()) {
            completeMap.set(entry[0], entry[1]);
        }
        return completeMap;
    }
);

export const getSelectedDeliveryEventItemIds = createSelector(
    getDeliverySelectionState,
    deliverySelectionState => {
        const selectedDeliveryEventItemIds: number[] = [];
        for (let [eventItemId, isSelected] of deliverySelectionState) {
            if (isSelected === true) selectedDeliveryEventItemIds.push(eventItemId);
        }
        return selectedDeliveryEventItemIds;
    }
);

export const isAnythingSelectedOnDeliveryTab = createSelector(
    getSelectedDeliveryEventItemIds,
    deliverySelectedEventItemIds => deliverySelectedEventItemIds.length > 0,
);

// =================================================================================
//                                 Public Selectors
// =================================================================================


export const getUnassignedAttendees = createSelector(
    getAttendeeAllWithParentInfo,
    getAssignedEventAttendeeIdsSet,
    (attendees, assignedEventAttendeeIds) => {
        const unassignedAttendees: AttendeeUpsertRequest[] = [];
        for (const attendee of attendees) {
            if (assignedEventAttendeeIds.has(attendee.eventAttendeeId)) continue; // skip
            unassignedAttendees.push(attendee);
        }
        return unassignedAttendees;
    }
);

export const getAttendeesApiState = createSelector(
    getEventItemState,
    (eventItemState) => eventItemState.attendees.apiState
);

export const getInitallyLoadedAttendeesApiState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.initiallyLoadedAttendees.apiState
);

export const getAllAttendees = createSelector(
    getAttendeeAll,
    (attendees) => {
        return attendees;
    }
);

export const getInitiallyLoadedAttendees = createSelector(
    getEventItemState,
    eventItemState => {
        return {
            initiallyLoadedAttendees: attendeeUpsertEntitySelectors.selectAll(eventItemState.initiallyLoadedAttendees),
            apiState: eventItemState.initiallyLoadedAttendees.apiState,
        };
    }
);

export const getTicketQuantity = createSelector(
    getEventItemState,
    eventItemState => eventItemEntitySelectors.selectTotal(eventItemState.eventItems)
);

export const selectEimSelectedTab = createSelector(
    getEventItemState,
    eventItemState => eventItemState.selectedTab
);

export const getUnassignedAttendeesAsDisjoint = createSelector(
    getUnassignedAttendees,
    (unassignedAttendees) => unassignedAttendees.map(a => <EventItemAndAttendeeDisjoint>{eventItem: null, assignedAttendee: a})
);

export const getInitiallyLoadedAttendeesWithDataState = createSelector(
    getEventItemState,
    eventItemState => {
        return {
            initiallyLoadedAttendees: attendeeUpsertEntitySelectors.selectAll(eventItemState.initiallyLoadedAttendees),
            dataState: eventItemState.initiallyLoadedAttendees.dataState,
        };
    }
);
export function getInitiallyLoadedAttendees_OnceObservable(store$: Store<RootStoreState>): Observable<AttendeeUpsertRequest[]> {
    return store$.pipe(
        select(getInitiallyLoadedAttendeesWithDataState),
        takeWhile((data, index) => {
            // This is a very misleading takeWhile, it looks like we want the value when dataState is Initial (and indeed we get it),
            // but actually we want the first (and only the first) value where initallyLoadedAttendees are NOT Initial
            // We also need the initial state so the page doesn't crash passing undefined to the Inputs
            // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
            // test below fails, but inclusive set to true delivers this last failed result
            return data.dataState === DataState.Initial;
        }, true),
        map(data => data.initiallyLoadedAttendees)
    );
}

// export const getImportedAttendees = createSelector(
//     getEventItemState,
//     eventItemState => eventItemState.importedAttendees
// );

const getUnassignedAttendeesAsDisjointWithDataStates = createSelector(
    getUnassignedAttendeesAsDisjoint,
    getAttendeeDataState,
    getEventItemDataState,
    (unassignedAttendeesDisjoint, attendeeDataState, eventItemDataState) => {
        return {unassignedAttendeesDisjoint, attendeeDataState, eventItemDataState};
    }
);

export function getUnassignedAttendeesAsDisjoint_OnceObservable(store$: Store<RootStoreState>): Observable<EventItemAndAttendeeDisjoint[]> {
    return store$.pipe(
        select(getUnassignedAttendeesAsDisjointWithDataStates),
        takeWhile((data, index) => {
            // This is a very misleading takeWhile, it looks like we want the value when either is Initial (and indeed we get it),
            // but actually we want the first (and only the first) value where both attendees and eventItems are NOT Initial
            // We also need the initial state so the page doesn't crash passing undefined to the Inputs
            // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
            // test below fails, but inclusive set to true delivers this last failed result
            return (data.attendeeDataState === DataState.Initial || data.eventItemDataState === DataState.Initial); 
        }, true),
        map(data => data.unassignedAttendeesDisjoint),
    );
}

export const getEventItemWithAttendeeFlatList = createSelector(
    getEventItems,
    getAttendeeMappedByEventAttendeeId,
    (eventItems, attendeesMap) => {
        const eventItemWithAttendeeList: EventItemWithAttendee[] = [];
        for (const eventItem of eventItems) {
            const attendee = attendeesMap.get(eventItem.eventAttendeeId);
            if (!attendee) {
                eventItemWithAttendeeList.push({
                    eventItem: eventItem,
                    assignedAttendee: null,
                } as EventItemWithAttendee);
            } else {
                eventItemWithAttendeeList.push({
                    eventItem: eventItem,
                    assignedAttendee: attendee,
                } as EventItemWithAttendee);
            }
        }
        return eventItemWithAttendeeList;
    }
);

export const getEventItemWithAttendeeFlatListWithDataStates = createSelector(
    getEventItemWithAttendeeFlatList,
    getAttendeeDataState,
    getEventItemDataState,
    (eventItemWithAttendeeFlatList, attendeeDataState, eventItemDataState) => {
        return {eventItemWithAttendeeFlatList, attendeeDataState, eventItemDataState};
    }
)

export function getEventItemWithAttendeeFlatList_OnceObservable(store$: Store<RootStoreState>): Observable<EventItemWithAttendee[]> {
    return store$.pipe(
        select(getEventItemWithAttendeeFlatListWithDataStates),
        takeWhile((data, index) => {
            // This is a very misleading takeWhile, it looks like we want the value when either is Initial (and indeed we get it),
            // but actually we want the first (and only the first) value where both attendees and eventItems are NOT Initial
            // We also need the initial state so the page doesn't crash passing undefined to the Inputs
            // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
            // test below fails, but inclusive set to true delivers this last failed result
            return (data.attendeeDataState === DataState.Initial || data.eventItemDataState === DataState.Initial);
        }, true),
        map(data => data.eventItemWithAttendeeFlatList),
    );
}

export const getEventItemsWithAttendeeGroupings = createSelector(
    getEventItems,
    getAttendeeMappedByEventAttendeeId,
    (eventItems, attendeesMap) => {
        const groupings: EventItemWithAttendeeGroup[] = [];
        const groupingsIdxMap = new Map<string, number>(); // key = group name, value = index into groupings

        for (const eventItem of eventItems) {
            const groupName = eventItem.groupingName;
            let groupingsIdx = groupingsIdxMap.get(groupName);

            // If this group doesn't yet exist, initialize it
            if (typeof groupingsIdx !== 'number') {
                groupingsIdx = groupings.length;
                groupingsIdxMap.set(groupName, groupingsIdx);
                groupings.push({
                        title: getGroupingDisplayName(eventItem),
                        assignments: [],
                    } as EventItemWithAttendeeGroup
                );
            }

            if (eventItem.eventAttendeeId === null) {
                groupings[groupingsIdx].assignments.push({
                    eventItem: eventItem,
                    assignedAttendee: null,
                } as EventItemWithAttendee);
            } else {
                const attendee = attendeesMap.get(eventItem.eventAttendeeId);
                if (!attendee) {
                    groupings[groupingsIdx].assignments.push({
                        eventItem: eventItem,
                        assignedAttendee: null,
                    } as EventItemWithAttendee);
                } else {
                    groupings[groupingsIdx].assignments.push({
                        eventItem: eventItem,
                        assignedAttendee: attendee,
                    } as EventItemWithAttendee);
                }
            }
        }
        return groupings;
    }
);

// This just maps the output of selector getEventItemsWithAttendeeGroupings from EventItemWithAttendeeGroup[] -> EventItemAndAttendeeDisjointGroup[]
// So it is not a 'true' disjoint in that it does not contain unassigned attendees (it does contain assigned event items, and unassigned event items).
// It is used with the drag/drop interface when assigning seats.
export const getEventItemsWithAttendeeGroupingsAsDisjoint = createSelector(
    getEventItemsWithAttendeeGroupings,
    (groupings) => groupings.map(group => {
            return {
                title: group.title,
                assignments: group.assignments.map(assignment => {
                    return {
                        eventItem: assignment.eventItem,
                        assignedAttendee: assignment.assignedAttendee,
                    } as EventItemAndAttendeeDisjoint;
                })
            } as EventItemAndAttendeeDisjointGroup;
        })
);

const getEventItemsWithAttendeeGroupingsAsDisjointWithDataStates = createSelector(
    getEventItemsWithAttendeeGroupingsAsDisjoint,
    getAttendeeDataState,
    getEventItemDataState,
    (groupings, attendeeDataState, eventItemDataState) => {
        return {groupings, attendeeDataState, eventItemDataState};
    }
);

export function getEventItemsWithAttendeeGroupingsAsDisjoint_OnceObservable(store$: Store<RootStoreState>): Observable<EventItemAndAttendeeDisjointGroup[]> {
    return store$.pipe(
        select(getEventItemsWithAttendeeGroupingsAsDisjointWithDataStates),
        takeWhile((data, index) => {
            // This is a very misleading takeWhile, it looks like we want the value when either is Initial (and indeed we get it),
            // but actually we want the first (and only the first) value where both attendees and eventItems are NOT Initial
            // We also need the initial state so the page doesn't crash passing undefined to the Inputs
            // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
            // test below fails, but inclusive set to true delivers this last failed result
            return (data.attendeeDataState === DataState.Initial || data.eventItemDataState === DataState.Initial);
        }, true),
        map(data => data.groupings),
    );
}

export const getDownloadTicketState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.downloadState
);

export const getNotifyAttendeeState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.notifyState
);

export const getDownloadManifestApiState = createSelector(
    getEventItemState,
    eventItemState => eventItemState.downloadManifestState.apiState
);
