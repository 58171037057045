
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
import { AttendeeDTO_Old } from 'app/store/models/packages/AttendeeDTO';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { Survey } from 'app/store/models/survey';

@Injectable()
export class SurveyResponseService {
    public previousUrl: string = "";
    constructor(
        private http: HttpClient,
        private URLs: URLConfig,
    ) {}

    // TODO: The methods here should be used in ngrx flow, currently they are called directly in components

    public getSurveyDetails(surveyId: number): Observable<Survey> {
        return this.http.get<GTResponse<Survey>>(this.URLs._getSurveyResponses(surveyId)).pipe(
            handleGTResponse(),
        );
    }

    public updateSurvey(updatedSurvey: Survey): Observable<Survey> {
        return this.http.post<GTResponse<Survey>>(this.URLs._updateSurvey(), updatedSurvey).pipe(
            handleGTResponse(),
        );
    }

    // TODO: This is deprecated, instead use event-details.service.ts getAttendees method
    public getSurveyInviteeDetails(packageId): Observable<AttendeeDTO_Old[]> {
        return this.http.get<GTResponse<AttendeeDTO_Old[]>>(this.URLs._packageAttendeeDetails(packageId)).pipe(
            handleGTResponse(),
        );
    }

    public getSupplierURL(supplierName: string): Observable<any> {
        return this.http.get<any>(this.URLs._getSupplierURL(supplierName)).pipe(
        map(res => {
            if (res.Result) {
                return res;
            }
        }));
    }
}
