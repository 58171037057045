import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EventDetailsService } from "app/pages/package-details/event-details.service";
import { GroupTixService } from "app/store/services/group-tix.service";
import { catchError, concatMap, debounceTime, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import * as EimActions from 'app/store/actions/eventItem.action';
import * as fromRoot from 'app/store/reducers';
import * as EventItemSelectors from 'app/store/selectors/eventItem.selector';
import * as EventSelectors from 'app/store/selectors/event.selector';
import * as PackageSelectors from 'app/store/selectors/packageDetails.selector';
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import * as EventItemActions from 'app/store/actions/eventItem.action';
// import { retryWithDelay } from 'rxjs-boost/dist/cjs/operators'; // TODO: According to rxjs-boost docs, this should be 'rxjs-boost/operators';
import { DragDropMessagingService } from 'app/store/services/drag-drop-messaging.service';
import { TooManyTicketsRequestedErrorDialogComponent, TooManyTicketsRequestedErrorDialogData, TooManyTicketsRequestedErrorDialogResult } from 'app/dialogs/too-many-tickets-requested-error-dialog/too-many-tickets-requested-error-dialog.component';
import { DownloadTicketsErrorDialogComponent, DownloadTicketsErrorDialogData, DownloadTicketsErrorDialogResult } from 'app/dialogs/download-tickets-error-dialog/download-tickets-error-dialog.component';
import { NotifyAttendeesErrorDialogComponent, NotifyAttendeesErrorDialogData, NotifyAttendeesErrorDialogResult } from 'app/dialogs/notify-attendees-error-dialog/notify-attendees-error-dialog.component';
import { TooEarlyToGenerateTicketsErrorDialogComponent, TooEarlyToGenerateTicketsErrorDialogData, TooEarlyToGenerateTicketsErrorDialogResult } from 'app/dialogs/too-early-to-generate-tickets-error-dialog/too-early-to-generate-tickets-error-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'app/app.configure-options';
import { GTResponseCodes, GTResponseError } from "app/store/models/gtResponse";
import { UpsertAttendeesRequest } from "../models/requests/upsert-attendees-request";


@Injectable()
export class EventItemEffects {

    constructor(
        private actions$: Actions,
        private store$: Store<fromRoot.State>,
        private eventService: EventDetailsService,
        private groupTixService: GroupTixService,
        private dragDropMessagingService: DragDropMessagingService,
        private dialog: MatDialog,
        private configService: ConfigService,
    ) {}

    @Effect()
    loadEventItems = this.actions$
        .pipe(
            ofType<EimActions.EimPageLoadEventItems>(EimActions.ActionTypes.EimPageLoadEventItems),
            withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
            // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                            (action, eventId) => {
                                return {action, eventId};
                            }
            ),
            switchMap(data => this.eventService.getEventItems(data.eventId)
                        .pipe(
                            map(eventItems => new EimActions.EimApiLoadEventItemsSuccess({eventItems: eventItems})),
                            catchError(error => of(new EimActions.EimApiLoadEventItemsFailure({errorMessage: error.toString()})))
                        )
            )
        );


    @Effect({dispatch: false})
    clearLocalEventItemStatusVisualState = this.actions$
        .pipe(
            ofType<EimActions.EimPageLoadEventItems>(EimActions.ActionTypes.EimPageLoadEventItems),
            tap(_ => {
                // Don't want to clear this in the ngrxGetValuesOnce methods because the event item
                // state is only updated from the api here (it would cause switching drag/drop views to reset the state prematurely)
                // If the popup isn't showing for a seat when it should, this is a good place to look, its possible
                // it could clear the temporary event item status state not often enough.
                this.dragDropMessagingService.clearEventItemLinkInvalidatedState();
            }),
        );

    @Effect()
    loadAttendees = this.actions$
        .pipe(
            ofType<EimActions.EimPageLoadAttendees>(EimActions.ActionTypes.EimPageLoadAttendees),
            withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
            // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                            (action, eventId) => {
                                return {action, eventId};
                            }
            ),
            switchMap(data => this.groupTixService.getDistributionAttendees(data.eventId)
                        .pipe(
                            map(attendees => {
                                // Why are we mapping an AttendeeUpsertRequest to another AttendeeUpsertRequest? Great Question!
                                // It is because when the POJO is sent over http and parsed by angular's http client, it does NOT
                                // parse it into an actual AttendeeUpsertRequest class.  It treats it more like an interface, so it
                                // is missing any getters/setters/methods the actual class has, like isParent and isChild.
                                const mappedAttendees = attendees.map(a => AttendeeUpsertRequest.createAttendeeFromAttendee(a));
                                return new EimActions.EimApiLoadAttendeesSuccess({attendees: mappedAttendees})
                            }),
                            catchError(error => of(new EimActions.EimApiLoadAttendeesFailure({errorMessage: error.toString()})))
                        )
            )
        );

    // This effect is to kick off an autosave (if possible) after attendee changes have been made on the attendee tab
    // This is the first of two places an autosave can be kicked off
    @Effect()
    attendeesHaveBeenEdited = this.actions$
        .pipe(
            ofType<EimActions.EimPageAttendeesHaveBeenEdited>(EimActions.ActionTypes.EimPageAttendeesHaveBeenEdited),
            debounceTime(1000),
            // The reducer for this action will add all valid attendees to Waiting
            // This effect attempts to trigger an autosave request if none is already in progress
            // If a save request is already in progress, another will be triggered when it returns in a different effect
            withLatestFrom(this.store$.pipe(select(EventItemSelectors.isAttendeeAutosavingInFlight)),
                            (action, isAttendeeAutosavingInFlight) => isAttendeeAutosavingInFlight
            ),
            // Kick off autosaving if there is no autosaving already in flight
            filter(isAttendeeAutosavingInFlight => !isAttendeeAutosavingInFlight),
            map(_ => new EventItemActions.EiEffectsSaveAttendees()),
        );

    @Effect()
    eiEffectsSaveAttendees = this.actions$
        .pipe(
            ofType<EimActions.EiEffectsSaveAttendees>(EimActions.ActionTypes.EiEffectsSaveAttendees),
            withLatestFrom(this.store$.pipe(select(EventItemSelectors.getWaitingAttendees)),
                            this.store$.pipe(select(EventItemSelectors.getSavedInputAttendeesDictionary)),
                            this.store$.pipe(select(EventItemSelectors.getSavedInputNewEventAttendeeIdMap)),
                            this.store$.pipe(select(EventItemSelectors.getInFlightAttendeesDictionary)),
                            this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
                            // this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                            (action, waitingAttendees, savedInputAttendeesDict, savedInputNewAttendeeIdMap, inFlightAttendeesDict, eventId) => {
                                return {action, waitingAttendees, savedInputAttendeesDict, savedInputNewAttendeeIdMap, inFlightAttendeesDict, eventId};
                            }
            ),
            map(data => {

                // iterate waiting attendees
                //   update new id in waiting if needed (using new id map) (also update parent id if needed)
                //   find corresponding saved attendee (its possible none exists if truly a new attendee)
                //   diff compare to saved attendee (if exists), 
                //     if same, then ignore (already saved)
                //     else if different, or none existant, then save to api

                const attendeesToSave: AttendeeUpsertRequest[] = [];
                for (let waitingAttendee of data.waitingAttendees) {
                    waitingAttendee = AttendeeUpsertRequest.copy(waitingAttendee); // copy the ngrx state object into a new object
                    // If this is a new attendee, try to update the eventAttendeeId if already saved
                    if (waitingAttendee.isNewAttendee) {
                        const eventAttendeeId = data.savedInputNewAttendeeIdMap.get(waitingAttendee.newEventAttendeeId);
                        if (eventAttendeeId > 0) {
                            // then this 'new attendee' has already been saved, and has a legitimate eventAttendeeId
                            waitingAttendee.eventAttendeeId = eventAttendeeId;
                        }
                    }

                    // If parent is new and has already been saved, update the child's parent id
                    if (waitingAttendee.isChildOfNewParent) {
                        const parentUserId = data.savedInputNewAttendeeIdMap.get(waitingAttendee.newEventAttendeeIdParent);
                        if (parentUserId > 0) {
                            waitingAttendee.parentUserId = parentUserId;
                        }
                    }

                    // get saved attendee if exists (will be undefined if doesn't exist) (saved attendees will always have an eventAttendeeId)
                    let savedAttendee:AttendeeUpsertRequest;
                    if (waitingAttendee.eventAttendeeId > 0) {
                        savedAttendee = data.savedInputAttendeesDict[waitingAttendee.eventAttendeeId];
                    }

                    let shouldSaveAttendee:boolean;
                    if (!savedAttendee) {
                        // if saved attendee doesn't exist, then this is a new attendee, need to save
                        console.warn("No matching savedAttendee found for waitingAttendee (" + waitingAttendee.eventAttendeeId + "-" + waitingAttendee.newEventAttendeeId + ")");
                        shouldSaveAttendee = true;
                    } else {
                        // compare waiting to saved, if the same, don't save, if different save
                        // TODO: this leaves open the bug for changing attendee, change is in flight, changing back, no change detected in saved, so change back isn't actually saved :-O
                        //       Actually, rethinking that bug, if this effect is only executed when there is no request in flight, then I don't think that bug is an issue
                        if (waitingAttendee.isEqualTo(savedAttendee)) {
                            // TODO: check that isEqualTo performs this function correctly...
                            // Then the attendee in waiting is identical to what is already saved, don't bother saving
                            // console.log("waitingAttendee is equal to savedAttendee");
                            shouldSaveAttendee = false;
                        } else {
                            // Then the attendee in waiting has changes from what is saved, need to save changes
                            console.log("waitingAttendee (" + waitingAttendee.eventAttendeeId + "-" + waitingAttendee.newEventAttendeeId + ") is NOT equal to savedAttendee (" + savedAttendee.eventAttendeeId + "-" + savedAttendee.newEventAttendeeId + ")");
                            // console.log(waitingAttendee);
                            // console.log(savedAttendee);
                            shouldSaveAttendee = true;
                        }
                    }

                    if (shouldSaveAttendee) {
                        attendeesToSave.push(waitingAttendee);
                    }
                }

                // Need to clear out waitingAttendees (this will be done in the reducer for either of the dispatched actions below)
                if (attendeesToSave.length > 0) {
                    const upsertAttendeeRequest = {
                        attendees: attendeesToSave,
                        skipValidation: false,
                    } as UpsertAttendeesRequest;

                    return new EventItemActions.EimPageSaveAttendees({attendees: upsertAttendeeRequest, eventId: data.eventId});
                } else {
                    return new EventItemActions.EiEffectsNoAttendeesToSave();
                }
            })
        );

    @Effect()
    saveAttendees = this.actions$
        .pipe(
            ofType<EimActions.EimPageSaveAttendees>(EimActions.ActionTypes.EimPageSaveAttendees),
            concatMap(action => this.groupTixService.upsertAttendees(action.payload.attendees, action.payload.eventId)
                        .pipe(
                            map(attendeeCollectionPayload => new EimActions.EimApiSaveAttendeesSuccess({attendeeCollectionPayload})),
                            catchError(error => of(new EimActions.EimApiSaveAttendeesFailure({errorMessage: error.toString(), failedAttendees: action.payload.attendees.attendees}))),
                        )
            )
        );
    
    // This effect is to kick off an autosave (if necessary) after a previous autosave finishes
    // This is the second of two places an autosave can be kicked off
    @Effect()
    eimApiSaveAttendeesResponse = this.actions$
        .pipe(
            ofType<EimActions.EimApiSaveAttendeesSuccess | EimActions.EimApiSaveAttendeesFailure>(
                EimActions.ActionTypes.EimApiSaveAttendeesSuccess, EimActions.ActionTypes.EimApiSaveAttendeesFailure),
            withLatestFrom(this.store$.pipe(select(EventItemSelectors.areAttendeesWaitingToBeSaved)),
                            (action, areAttendeesWaitingToBeSaved) => areAttendeesWaitingToBeSaved
            ),
            // Kick off autosaving if there are new changes waiting to be saved
            filter(areAttendeesWaitingToBeSaved => areAttendeesWaitingToBeSaved),
            map(data => new EventItemActions.EiEffectsSaveAttendees()),
        );

    @Effect()
    deleteAttendee = this.actions$.pipe(
        ofType<EimActions.EimPageDeleteAttendee>(EimActions.ActionTypes.EimPageDeleteAttendee),
        withLatestFrom(this.store$.pipe(select(EventItemSelectors.getSavedInputNewEventAttendeeIdMap)),
                        (action, newIdMap) => {
                            return {action, newIdMap};
                        }),
        filter(data => data.action.payload.eventId > 0 && (data.action.payload.eventAttendeeId > 0 || data.action.payload.newEventAttendeeId > 0)),
        map(data => {
            const eventId: number = data.action.payload.eventId;
            let eventAttendeeId: number = data.action.payload.eventAttendeeId;
            if (eventAttendeeId > 0) return new EventItemActions.EiEffectsDeleteExistingAttendee({eventAttendeeId: eventAttendeeId, eventId: eventId});
            let newEventAttendeeId: number = data.action.payload.newEventAttendeeId;
            eventAttendeeId = data.newIdMap.get(newEventAttendeeId);
            if (eventAttendeeId > 0) return new EventItemActions.EiEffectsDeleteExistingAttendee({eventAttendeeId: eventAttendeeId, eventId: eventId});
            // There is no known valid eventAttendeeId
            return new EventItemActions.EiEffectsDeleteNewAttendee({newEventAttendeeId: newEventAttendeeId});
        })
    );

    @Effect()
    deleteExistingAttendee = this.actions$.pipe(
        ofType<EimActions.EiEffectsDeleteExistingAttendee>(EimActions.ActionTypes.EiEffectsDeleteExistingAttendee),
        concatMap(action => this.groupTixService.deleteAttendee(action.payload.eventId, action.payload.eventAttendeeId)
                    .pipe(
                        map(deletedEventAttendeeIds => new EimActions.EimApiDeleteAttendeeSuccess({deletedEventAttendeeIds: deletedEventAttendeeIds})),
                        catchError(error => of(new EimActions.EimApiDeleteAttendeeFailure({errorMessage: error.toString(), failedEventAttendeeId: action.payload.eventAttendeeId})))
                    )
        )
    );

    @Effect()
    autosaveEventItemAssignments = this.actions$.pipe(
        ofType<EimActions.EimPageAssignAttendeeToEventItem | EimActions.EimPageUnassignAttendeeFromEventItem>(EimActions.ActionTypes.EimPageAssignAttendeeToEventItem, 
            EimActions.ActionTypes.EimPageUnassignAttendeeFromEventItem),
        debounceTime(3000),
        withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEvent)),
                        this.store$.pipe(select(EventItemSelectors.getAssignments)), // TODO: Implement selector
                        (action, selectedEventId, assignments) => {
                            return {action: action, eventId: selectedEventId, assignments: assignments};
                        }
        ),
        // TODO: test if a filter is needed b/c withLatestFrom sometimes emits bad values the first time
        concatMap(data => { 
                    return this.eventService.assignAttendeesToItems(data.eventId, data.assignments)
                        .pipe(
                            map(eventItems => new EimActions.EimApiAutosaveAssignmentsSuccess({eventItems: eventItems})),
                            catchError(error => of(new EimActions.EimApiAutosaveAssignmentsFailure({errorMessage: error.toString()})))
                        )}
        )
    );

    @Effect()
    retrieveShowList = this.actions$.pipe(
        ofType<EventItemActions.EimGetShowList>(EventItemActions.ActionTypes.EimGetShowList),
        concatMap(action => this.groupTixService.retrieveShowList().pipe(
                    map(showListResponse => {
                        return new EventItemActions.EimGetShowListSuccess({showList: showListResponse});
                    }),
                    catchError(error => of(new EventItemActions.EimGetShowListFailure({errorMessage: error.toString()})))
        ))
    );

    @Effect()
    notifySelectedAttendeesTicketsReady = this.actions$.pipe(
        ofType<EventItemActions.EimPageNotifySelectedAttendeesTicketsReady>(EventItemActions.ActionTypes.EimPageNotifySelectedAttendeesTicketsReady),
        withLatestFrom(this.store$.pipe(select(EventItemSelectors.getSelectedDeliveryEventItemIds)),
                        (action, selectedDeliveryEventItemIds) => selectedDeliveryEventItemIds
        ),
        map(selectedDeliveryEventItemIds => new EventItemActions.EimPageNotifyAttendeesTicketsReady({eventItemIds: selectedDeliveryEventItemIds})),
    );

    @Effect()
    notifyAttendeesTicketsReady = this.actions$.pipe(
        ofType<EventItemActions.EimPageNotifyAttendeesTicketsReady>(EventItemActions.ActionTypes.EimPageNotifyAttendeesTicketsReady),
        withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                        (action, selectedEventId) => {
                            return {action: action, selectedEventId: selectedEventId};
                        }
        ),
        concatMap(data => this.groupTixService.notifyAttendeesTicketsReady(data.selectedEventId, data.action.payload.eventItemIds).pipe(
                    switchMap(notificationCollectionPayload => [
                        new EventItemActions.EimApiNotifyAttendeesTicketsReadyResponse({notificationCollectionPayload: notificationCollectionPayload}),
                        new EventItemActions.EimPageLoadEventItems(),
                    ]),
                    catchError(error => {
                        let responseCode: number | null = null;
                        if (error instanceof GTResponseError && GTResponseCodes.TooEarlyToGenerateTickets === error.code) {
                            return of(new EventItemActions.EimApiNotifyAttendeesTicketsReadyTooSoonError());
                        }
                        if (error instanceof GTResponseError) {
                            responseCode = error.code;
                        }
                        return of(new EventItemActions.EimApiNotifyAttendeesTicketsReadyFailure({errorMessage: error.toString(), responseCode: responseCode}));
                    }),
                    catchError(error => of(new EventItemActions.EimApiNotifyAttendeesTicketsReadyFailure({errorMessage: error.toString(), responseCode: null})))
            )
        ),
    );

    @Effect()
    groupLeaderDownloadSelectedTickets = this.actions$.pipe(
        ofType<EventItemActions.EimPageGroupLeaderDownloadSelectedTickets>(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadSelectedTickets),
        withLatestFrom(this.store$.pipe(select(EventItemSelectors.getSelectedDeliveryEventItemIds)),
                        (action, selectedEventItemIds) => {
                            return {selectedEventItemIds, ticketFormat: action.payload.ticketFormat};
                        }
        ),
        map(data => new EventItemActions.EimPageGroupLeaderDownloadTickets({eventItemIds: data.selectedEventItemIds, ticketFormat: data.ticketFormat}))
    );

    @Effect()
    groupLeaderDownloadTickets = this.actions$.pipe(
        ofType<EventItemActions.EimPageGroupLeaderDownloadTickets>(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadTickets),
        withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                        (action, selectedEventId) => {
                            return {action: action, selectedEventId: selectedEventId};
                        }
        ),
        concatMap(data => {
            const numberOfTicketsRequested = data.action.payload.eventItemIds.length;
            const maxNumberOfTicketsAllowed = this.configService.config.maxNumOfTicketsToDownloadAtOnce;
            if (numberOfTicketsRequested > maxNumberOfTicketsAllowed) {
                return of(new EventItemActions.EimPageGroupLeaderDownloadTooManyTicketsError({numberOfTicketsRequested, maxNumberOfTicketsAllowed}));
            }
            const numberOfDownloadRetryRequests = this.configService.config.numberOfDownloadRetryRequests;
            const millisecondsToWaitBetweenRetryRequests = this.configService.config.millisecondsToWaitBetweenRetryRequests;
            return this.groupTixService.groupLeaderDownloadTickets(data.selectedEventId, data.action.payload.eventItemIds, data.action.payload.ticketFormat).pipe(
                // retryWithDelay(millisecondsToWaitBetweenRetryRequests, numberOfDownloadRetryRequests),
                switchMap(gtFile => [
                        new EventItemActions.EimEffectsDownloadFile({gtFile: gtFile}),
                        new EventItemActions.EimPageLoadEventItems(),
                    ]),
                catchError(error => {
                    let responseCode: number | null = null;
                    if (error instanceof GTResponseError && GTResponseCodes.TooEarlyToGenerateTickets === error.code) {
                        return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsTooSoonError());
                    }
                    if (error instanceof GTResponseError) {
                        responseCode = error.code;
                    }
                    return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsFailure({errorMessage: error.toString(), responseCode: responseCode}));
                }),
            )
        }),
    );

    @Effect()
    groupLeaderDownloadAllTickets = this.actions$.pipe(
        ofType<EventItemActions.EimPageGroupLeaderDownloadAllTickets>(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadAllTickets),
        withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                        this.store$.pipe(select(EventItemSelectors.getEventItemsCount)),
                        (action, selectedEventId, eventItemCount) => {
                            return {action, selectedEventId, eventItemCount};
                        }
        ),
        concatMap(data => {
            const numberOfTicketsRequested = data.eventItemCount;
            const maxNumberOfTicketsAllowed = this.configService.config.maxNumOfTicketsToDownloadAtOnce;
            if (numberOfTicketsRequested > maxNumberOfTicketsAllowed) {
                return of(new EventItemActions.EimPageGroupLeaderDownloadTooManyTicketsError({numberOfTicketsRequested, maxNumberOfTicketsAllowed}));
            }
            const numberOfDownloadRetryRequests = this.configService.config.numberOfDownloadRetryRequests;
            const millisecondsToWaitBetweenRetryRequests = this.configService.config.millisecondsToWaitBetweenRetryRequests;
            return this.groupTixService.groupLeaderDownloadAllTickets(data.selectedEventId, data.action.payload.ticketFormat).pipe(
                    // retryWithDelay(millisecondsToWaitBetweenRetryRequests, numberOfDownloadRetryRequests),
                    switchMap(gtFile => [
                        new EventItemActions.EimEffectsDownloadFile({gtFile: gtFile}),
                        new EventItemActions.EimPageLoadEventItems(),
                    ]),
                    catchError(error => {
                        let responseCode: number | null = null;
                        if (error instanceof GTResponseError && GTResponseCodes.TooEarlyToGenerateTickets === error.code) {
                            return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsTooSoonError());
                        }
                        if (error instanceof GTResponseError) {
                            responseCode = error.code;
                        }
                        return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsFailure({errorMessage: error.toString(), responseCode: responseCode}));
                    }),
            )
        }),
    );

    @Effect()
    downloadManifest = this.actions$.pipe(
        ofType<EventItemActions.EimPageDownloadManifest>(EventItemActions.ActionTypes.EimPageDownloadManifest),
        withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
                        (action, selectedEventId) => {
                            return {action: action, selectedEventId: selectedEventId};
                        }
        ),
        concatMap(data => this.groupTixService.groupLeaderDownloadManifest(data.selectedEventId).pipe(
                map(gtFile => new EventItemActions.EimApiDownloadManifestResponse({gtFile: gtFile})),
                catchError(error => of(new EventItemActions.EimApiDownloadManifestFailure({errorMessage: error.toString(), responseCode: null}))),
            )
        ),
    )

    @Effect({dispatch: false})
    downloadFile = this.actions$.pipe(
        ofType<EventItemActions.EimEffectsDownloadFile | EventItemActions.EimApiDownloadManifestResponse>
        (EventItemActions.ActionTypes.EimEffectsDownloadFile, EventItemActions.ActionTypes.EimApiDownloadManifestResponse),
        tap(action => {
            const gtFile = action.payload.gtFile;
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = gtFile.fileName;
            a.href = URL.createObjectURL(gtFile.blob);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        }),
    );


    // @Effect({dispatch: false})
    // showTooManyRequestDownloadedTicketsError = this.actions$.pipe(
    //     ofType<EventItemActions.EimPageGroupLeaderDownloadTooManyTicketsError>(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadTooManyTicketsError),
    //     tap(action => {
    //         const dialogRef: MatDialogRef<TooManyTicketsRequestedErrorDialogComponent, TooManyTicketsRequestedErrorDialogResult> = this.dialog.open(TooManyTicketsRequestedErrorDialogComponent, {
    //         width: '400px',
    //         data: {
    //             numberOfTickets: action.payload.numberOfTicketsRequested,
    //             maxNumberOfTicketsAllowed: action.payload.maxNumberOfTicketsAllowed,
    //         } as TooManyTicketsRequestedErrorDialogData,
    //         });
    //     }),
    // );

    // @Effect({dispatch: false})
    // showDownloadTicketError = this.actions$.pipe(
    //     ofType<EventItemActions.EimApiGroupLeaderDownloadTicketsFailure>(EventItemActions.ActionTypes.EimApiGroupLeaderDownloadTicketsFailure),
    //     tap(action => {
    //         const dialogRef: MatDialogRef<DownloadTicketsErrorDialogComponent, DownloadTicketsErrorDialogResult> = this.dialog.open(DownloadTicketsErrorDialogComponent, {
    //             width: '400px',
    //             data: {} as DownloadTicketsErrorDialogData,
    //         });
    //     }),
    // );

    // @Effect({dispatch: false})
    // showNotifyAttendeesError = this.actions$.pipe(
    //     ofType<EventItemActions.EimApiNotifyAttendeesTicketsReadyFailure>(EventItemActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyFailure),
    //     tap(action => {
    //         const dialogRef: MatDialogRef<NotifyAttendeesErrorDialogComponent, NotifyAttendeesErrorDialogResult> = this.dialog.open(NotifyAttendeesErrorDialogComponent, {
    //             width: '400px',
    //             data: {} as NotifyAttendeesErrorDialogData,
    //         });
    //     }),
    // );

    // @Effect({dispatch: false})
    // showTooEarlyToGenerateTicketsError = this.actions$.pipe(
    //     ofType<EventItemActions.EimApiGroupLeaderDownloadTicketsTooSoonError | EventItemActions.EimApiNotifyAttendeesTicketsReadyTooSoonError>
    //     (EventItemActions.ActionTypes.EimApiGroupLeaderDownloadTicketsTooSoonError, EventItemActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyTooSoonError),
    //     tap(action => {
    //         const dialogRef: MatDialogRef<TooEarlyToGenerateTicketsErrorDialogComponent, TooEarlyToGenerateTicketsErrorDialogResult> = this.dialog.open(TooEarlyToGenerateTicketsErrorDialogComponent, {
    //             width: '400px',
    //             data: {} as TooEarlyToGenerateTicketsErrorDialogData,
    //         });
    //     }),
    // );

    // @Effect()
    // groupLeaderDownloadSelectedTickets_Old = this.actions$.pipe(
    //     ofType<EventItemActions.EimPageGroupLeaderDownloadSelectedTickets>(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadSelectedTickets),
    //     withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
    //     //withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
    //                     this.store$.pipe(select(EventItemSelectors.getSelectedDeliveryEventItemIds)),
    //                     (action, selectedEventId, selectedDeliveryEventItemIds) => {
    //                         return {action: action, selectedEventId: selectedEventId, selectedDeliveryEventItemIds: selectedDeliveryEventItemIds};
    //                     }
    //     ),
    //     concatMap(data => this.eventService.groupLeaderDownloadTickets(data.selectedEventId, data.selectedDeliveryEventItemIds).pipe(
    //                 map(ticketsCollectionPayload => new EventItemActions.EimApiGroupLeaderDownloadTicketsResponse({ticketsCollectionPayload: ticketsCollectionPayload})),
    //                 catchError(error => of(new EventItemActions.EimApiGroupLeaderDownloadTicketsFailure({errorMessage: error.toString()})))
    //         )
    //     ),
    // );

    // @Effect()
    // groupLeaderDownloadAllTickets_Old = this.actions$.pipe(
    //     ofType<EventItemActions.EimPageGroupLeaderDownloadAllTickets>(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadAllTickets),
    //     withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)),
    //     //withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
    //                     (action, selectedEventId) => {
    //                         return {action: action, selectedEventId: selectedEventId};
    //                     }
    //     ),
    //     concatMap(data => this.eventService.groupLeaderDownloadAllTickets(data.selectedEventId).pipe(
    //                 map(ticketsCollectionPayload => new EventItemActions.EimApiGroupLeaderDownloadTicketsResponse({ticketsCollectionPayload: ticketsCollectionPayload})),
    //                 catchError(error => of(new EventItemActions.EimApiGroupLeaderDownloadTicketsFailure({errorMessage: error.toString()})))
    //         )
    //     ),
    // );
}
