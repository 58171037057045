import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { ContactService } from "app/pages/contacts/contact.service";
import * as ContactActions from "app/store/actions/contacts.action";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";

@Injectable()
export class ContactsEffects {

    @Effect()
    loadContacts = this.actions$.pipe(
        ofType<ContactActions.AuthenticationServiceLoadContacts |
                ContactActions.InviteContactsComponentReloadContacts |
                ContactActions.AttendeesSummaryComponentReloadContacts |
                ContactActions.AttendeesComponentReloadContacts |
                ContactActions.PackageDetailsEffectLoadContacts |
                ContactActions.ContactComponentReloadContacts |
                ContactActions.CreateFromScratchComponentReloadContacts |
                ContactActions.SurveyCreationComponentReloadContacts |
                ContactActions.GettingStartedComponentReloadContacts |
                ContactActions.MessagesCardComponentReloadContacts |
                ContactActions.DashboardComponentLoadContacts |
                ContactActions.GroupToolsListComponentReloadContacts>
        (ContactActions.ActionTypes.AuthenticationServiceLoadContacts,
        ContactActions.ActionTypes.InviteContactsComponentReloadContacts,
        ContactActions.ActionTypes.AttendeesSummaryComponentReloadContacts,
        ContactActions.ActionTypes.AttendeesComponentReloadContacts,
        ContactActions.ActionTypes.PackageDetailsEffectLoadContacts,
        ContactActions.ActionTypes.ContactComponentReloadContacts,
        ContactActions.ActionTypes.CreateFromScratchComponentReloadContacts,
        ContactActions.ActionTypes.SurveyCreationComponentReloadContacts,
        ContactActions.ActionTypes.GettingStartedComponentReloadContacts,
        ContactActions.ActionTypes.MessagesCardComponentReloadContacts,
        ContactActions.ActionTypes.DashboardComponentLoadContacts,
        ContactActions.ActionTypes.GroupToolsListComponentReloadContacts,),
        concatMap(action => this.contactsService.getContactsData().pipe(
            map(contacts => new ContactActions.ContactsEffectLoadContactsSuccess({contacts})),
            catchError(error => of(new ContactActions.ContactsEffectLoadContactsFailure({errorMessage: error.toString()}))),
            )
        ),
    );

    // @Effect()
    // loadGroupLeaderContacts$ = this.actions$.pipe(
    //     ofType<ContactActions.>(ContactActions.ActionTypes.),
    //     concatMap(action => this.contactsService.getEventLeaderContacts(action.payload)
    //                 .pipe(
    //                     map(contacts => new ContactActions.(contacts)),
    //                     catchError(error => of(new ContactActions.({errorMessage: error.toString()})))
    //                 )
    //     )
    // );

    constructor(
        private actions$: Actions,
        private contactsService: ContactService,
    ) {}
}
