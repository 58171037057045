import * as gledStateActions from '../actions/groupLeaderEventDetails.action';
import { GroupLeaderEventDetailsState } from '../models/event';

export type State = GroupLeaderEventDetailsState;

export const initialState: State = { state: 'eventDetails' };

export function reducer(state: State = initialState, action: gledStateActions.Actions): State {
    switch (action.type) {
        case gledStateActions.SET_GLED_STATE: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

