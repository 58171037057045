import * as eventActions from '../actions/event.action';
import { Event } from '../models/event';

export type State = Event;

export const initialState: State = {
    id: 0,
    tenantId: 0,
    name: '',
    description: '',
    plan: '',
    shortDescription: '',
    date: null,
    ownerName: '',
    ownerId: 0,
    ownerIds: '',
    tags: [],
    types: [],
    status: '',
    shortUrl: '',
    options: {},
    photoUrl: ''
}

export function reducer(state: State = initialState, action: eventActions.Actions): State {
    switch (action.type) {
        case eventActions.LOAD_SELECTED_EVENT_OK: {
            return action.payload;
        }
        case eventActions.UPDATE_EVENT_OK: {
            // make sure we don't over-write event photo
            return {
                ...state,
                ...action.payload
            };
        }
        case eventActions.CLEAR_SELECTED_EVENT: {
            return {...initialState};
        }
        default: {
            return state;
        }
    }
}

