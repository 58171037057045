import { User, UnreadMessagesPreferences } from '../models/user';
import * as user from '../actions/user';

export type State = User;

export const initialState: State = {
  isLoggedIn: false,
  loginStatus: 'none',
  isRegistered: false,
  isVerified: false,
  userID: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  addressState: null,
  about: null,
  profilePictureSrc: '',
  isGroupLeader: false,
  paymentMethod: '',
  alternatePaymentMethod: '',
  roles: [],
  unreadMessagesPreferences: UnreadMessagesPreferences.Unassigned
};

export function reducer(state = initialState, action: user.Actions): State {
  switch (action.type) {
    case user.LOAD_USER_STATE_OK:
      {
        return Object.assign({}, state, action.payload);
      }
    case user.SET_LOGGED_IN:
      {
        return Object.assign({}, state, {
          isLoggedIn: action.payload
        });
      }
    case user.SET_USER_ID:
      {
        return Object.assign({}, state, {
          userID: action.payload
        })
      }
    case user.SET_LOGIN_STATUS:
      {
        return Object.assign({}, state, {
          loginStatus: action.payload
        })
      }
    case user.SET_DEFAULT:
      {
        return Object.assign({}, initialState);
      }
    case user.SET_EMAIL:
      {
        return Object.assign({}, state, {
          email: action.payload
        })
      }
    case user.SET_REGISTERED_STATUS:
      {
        return Object.assign({}, state, {
          isRegistered: action.payload
        })
      }
    case user.SET_VERIFIED_STATUS:
      {
        return Object.assign({}, state, {
          isVerified: action.payload
        })
      }
    case user.SET_FIRST_NAME:
      {
        return Object.assign({}, state, {
          firstName: action.payload
        })
      }
    case user.SET_LAST_NAME:
      {
        return Object.assign({}, state, {
          lastName: action.payload
        })
      }
    case user.SET_PHONE_NUMBER:
      {
        return Object.assign({}, state, {
          phoneNumber: action.payload
        })
      }
    case user.SET_ADDRESS_STATE:
      {
        return Object.assign({}, state, {
          addressState: action.payload
        })
      }
    case user.SET_PROFILE_PICTURE:
      {
        return Object.assign({}, state, {
          profilePictureSrc: action.payload
        })
      }
    case user.SET_DEFAULT_PROFILE_PICTURE:
      {
        return Object.assign({}, state, {
          profilePictureSrc: initialState.profilePictureSrc
        })
      }
    case user.SET_LEADER_STATUS:
      {
        return Object.assign({}, state, {
          isGroupLeader: action.payload
        })
      }
    case user.SET_PAYMENT_METHOD:
      {
        return Object.assign({}, state, {
          paymentMethod: action.payload.paymentMethod
        })
      }
    case user.SET_ALTERNATE_PAYMENT_METHOD:
      {
        return Object.assign({}, state, {
          alternatePaymentMethod: action.payload
        })
      }
    case user.SET_ROLES:
      {
        return Object.assign({}, state, {
          roles: action.payload
        })
      }
    case user.SET_UNREAD_EMAIL_PREFERENCE_OK:
      {
        return Object.assign({}, state, action.payload);
      }
    case user.SET_ABOUT:
      {
        return Object.assign({}, state, {
          about: action.payload
        });
      }
    case user.SET_USER_REGISTERED_DATA:
        {
            return Object.assign({}, state, {
                ...action.payload
            })
        }
    case user.SAVE_ABOUT_OK:
      {
        return Object.assign({}, state, action.payload);
      }
    case user.LOGOUT:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
}

export const getLoggedIn = (state: State) => state.isLoggedIn;
export const getVerifiedStatus = (state: State) => state.isVerified;
export const getLoginStatus = (state: State) => state.loginStatus;
export const getUserID = (state: State) => state.userID;
export const getUserEmail = (state: State) => state.email;
export const getRegisteredStatus = (state: State) => state.isRegistered;
export const getFirstName = (state: State) => state.firstName;
export const getLastName = (state: State) => state.lastName;
export const getPhoneNumber = (state: State) => state.phoneNumber;
export const getAddressState = (state: State) => state.addressState;
export const getProfilePicture = (state: State) => state.profilePictureSrc;
export const getLeaderStatus = (state: State) => state.isGroupLeader;
export const getPaymentMethod = (state: State) => state.paymentMethod;
export const getAlternatePaymentMethod = (state: State) => state.alternatePaymentMethod;
export const getAbout = (state: State) => state.about;

export const getUnreadMessagesPreference = (state: State) => state.unreadMessagesPreferences;
