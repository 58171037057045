import * as tslib_1 from "tslib";
import { QuestionBase } from "app/pages/surveys/survey/components/survey-body/questions/question-bases/question-base";
var RadioQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(RadioQuestion, _super);
    function RadioQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.questionType = 'radio';
        _this.questionChoices = [];
        _this.questionChoices = options['questionChoices'] || [];
        _this.otherAdded = options['otherAdded'] || false;
        return _this;
    }
    return RadioQuestion;
}(QuestionBase));
export { RadioQuestion };
