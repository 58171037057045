import { URLConfig } from '../pages/helpers/config.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
    }
    ConfigurationService.prototype.getConfiguration = function (getConfigurationRequest) {
        return this.http.post(this.URLs._getConfiguration(), getConfigurationRequest).pipe(handleGTResponse());
    };
    return ConfigurationService;
}());
export { ConfigurationService };
