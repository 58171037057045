
import {catchError, map, tap} from 'rxjs/operators';
import { URLConfig } from '../helpers/config.service';
import { DatePipe } from '@angular/common';
import { Injectable, SecurityContext } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Message } from 'app/store/models/message';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { UserMessageDTO } from 'app/store/models/packages/UserMessageDTO';
import { MessageRequestDTO } from 'app/store/models/requests/MessageRequestDTO';
import { MessageSummaryDTO } from 'app/store/models/packages/MessageSummaryDTO';
import { ListOptions } from 'app/widgets/grouptools-list/grouptools-list.component';

@Injectable()
export class MessagesService {

  public messageOptions: ListOptions<UserMessageDTO> = {
    hideDate: true,
    multiSelectMode: true,
    hideMenu: false,
    columns: [{
        fieldName: 'CreatedAt',
        label: 'Date Sent',
        pipe: new DatePipe('en-US')
      },
      {
        fieldName: 'SenderName',
        label: 'Sent By'
      },
      {
        fieldName: 'Type',
        label: 'Type'
      },
      {
        fieldName: 'State',
        label: 'State'
      },
      {
        fieldName: 'Subject',
        label: 'Subject'
      }
    ],
    singleActions: ['View Message', 'Mark as Read', 'Delete'],
    bulkActions: ['Mark as Read', 'Delete'],
    collapsed: true
  };

  constructor(private http: HttpClient,
    private URLs: URLConfig,
    private sanitizer: DomSanitizer
  ) {}

  // ==================================================================================
  // KC: 2020/05/26: The new pattern is in this section
  public getMessagesNew(eventId: number): Observable<Message[]> {

    const mockMessage: Message = {
      id: 42,
      packageId: null,
      surveyId: null,
      subject: 'Mock Message Subject',
      body: 'Mock Message Body'
    }
    return of([mockMessage]);

    // TODO: implement the below and remove the mock above. Also remove 'of' import.  Also fix _getMessagesUrl.
    // return this.http.get<GTResponse<Message[]>>(this.URLs._getMessagesUrl(eventId)).pipe(
    //          handleGTResponse(),
    // );
  }
  // ==================================================================================

  public GetMessages(options: MessageRequestDTO): Observable<UserMessageDTO[]> {
    return this.http.post<GTResponse<UserMessageDTO[]>>(this.URLs._messages(), options).pipe(
      handleGTResponse(),
      tap(userMessages => {
        userMessages.map(msg => {
          msg.isSent = false;
          let parsed;
          try {
            parsed = JSON.parse(msg.JsonMessage);
            if (parsed.message) {
              parsed.message = this.sanitizer.sanitize(SecurityContext.HTML, parsed.message);
            }
          } catch (e) {
            parsed = {
              message: 'ERROR: Uh Oh! We couldn\'t read this message!'
            };
          }
          msg.JsonMessage = {
            JsonMessage: parsed,
            State: msg.State,
            isSent: msg.isSent,
            Date: msg.CreatedAt,
            Subject: msg.Subject
          }
          msg.CombinedFromSubject = {
            SenderName: msg.SenderName,
            Subject: msg.Subject,
            State: msg.State
          };
          msg.CombinedFromDate = {
            Date: msg.CreatedAt,
            State: msg.State,
            isSent: msg.isSent
          };

          // create a new property that is just a getter, this will be sortable based on our rules
          // unread 1st, then date descending
          Object.defineProperty(msg, 'sortable', {
              get: function() {
                // this will be 'z' for unread, 'a' for read, followed by the iso sortable date
                // e.g. 'a2018-08-07T19:23:55', use descending sort to get newest unread
                return '' + (msg.State.toLocaleLowerCase() === 'unread' ? 'z' : 'a')
                  + msg.CreatedAt.substring(0, 19);
              },
              set: function() { },
              configurable: false,
              enumerable: true
          });
        });
      }),
    );
  }

  public GetSentMessages(options: MessageRequestDTO): Observable<UserMessageDTO[]> {
    return this.http.post<GTResponse<UserMessageDTO[]>>(this.URLs._getSentMessages(), options).pipe(
      handleGTResponse(),
      tap(userMessages => {
        userMessages.map(msg => {
          msg.isSent = true;
          let parsed;
          try {
            parsed = JSON.parse(msg.JsonMessage);
            if (parsed.message) {
              parsed.message = this.sanitizer.sanitize(SecurityContext.HTML, parsed.message);
            }
          } catch (e) {
            parsed = {
              message: 'ERROR: Uh Oh! We couldn\'t read this message!'
            };
          }
          msg.JsonMessage = {
            JsonMessage: parsed,
            State: msg.State,
            isSent: msg.isSent,
            Date: msg.CreatedAt,
            Subject: msg.Subject
          }
          msg.CombinedFromSubject = {
            RecipientName: msg.RecipientName,
            Subject: msg.Subject
          };
          msg.CombinedFromDate = {
            Date: msg.CreatedAt,
            State: msg.State,
            isSent: msg.isSent
          };
        });
      }),
      // catchError(error => {console.log("CAUGHT ERROR"); return of([]);}),
    );
  }

  public GetMessagesSummary(options: MessageRequestDTO): Observable<MessageSummaryDTO> {
    return this.http.post<GTResponse<MessageSummaryDTO>>(this.URLs._messagesSummary(), options).pipe(
      handleGTResponse(),
    );
  }

  public SendMessages(messageObj): Observable < any > {
    let token = '';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    return this.http.post < any > (this.URLs._sendMessages(), messageObj, {
      headers: headers
    });
  }

  public SendPackageMessages(messageObj): Observable <any> {
    return this.http.post<any>(this.URLs._sendPackageMessages(), messageObj);
  }

  public MarkAsRead(message: UserMessageDTO): Observable<number> {
    let token = '';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    return this.http.get<GTResponse<number>>(this.URLs._markMessageAsRead(message.IdUserMessage)).pipe(
      handleGTResponse(),
    );
  }

  // returns IdUserMessage of successfully deleted message
  public DeleteMessages(message: UserMessageDTO): Observable<number> {
    let token = '';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    return this.http.post<GTResponse<number>>(this.URLs._deleteMessages(), message.IdUserMessage, {headers: headers}).pipe(
      handleGTResponse(),
    );
  }
}

