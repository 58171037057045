import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupToolsLoaderService } from 'app/grouptools-theme/services/grouptoolsLoader/grouptoolsLoader.service';
import { GtLoaderConfigService } from 'app/pages/helpers/gt-loader-config.service';
// borrowed from https://grensesnittet.computas.com/loading-status-in-angular-done-right/
var LoaderInterceptor = /** @class */ (function () {
    function LoaderInterceptor(gtLoader, gtLoaderConfig) {
        this.gtLoader = gtLoader;
        this.gtLoaderConfig = gtLoaderConfig;
        this.requests = [];
    }
    LoaderInterceptor.prototype.removeRequest = function (req) {
        var i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0) {
            this.gtLoader.show();
        }
        else {
            this.gtLoader.hide();
        }
    };
    LoaderInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if (!this.gtLoaderConfig.shouldShowGTLoaderForRequest(req)) {
            return next.handle(req); // Skip showing GT Loader for this request
        }
        this.requests.push(req);
        this.gtLoader.show();
        // We create a new observable which we return instead of the original
        return Observable.create(function (observer) {
            // And subscribe to the original observable to ensure the HttpRequest is made
            var subscription = next.handle(req)
                .subscribe(function (event) {
                if (event instanceof HttpResponse) {
                    _this.removeRequest(req);
                    observer.next(event);
                }
            }, function (err) { _this.removeRequest(req); observer.error(err); }, function () { _this.removeRequest(req); observer.complete(); });
            // return teardown logic in case of cancelled requests
            return function () {
                _this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    };
    return LoaderInterceptor;
}());
export { LoaderInterceptor };
