import { AbstractControl, ValidatorFn } from "@angular/forms";

export function minimumValueValidator(minValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control.value !== 0 && (!control.value || control.value === '')) {
            return null;
        }

        if (minValue > control.value) {
            return { tooSmall: { valid: false } };
        }
        return;
    };
}