// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { ExpressInviteComponent } from './express-invite.component';
import { WidgetsModule } from 'app/widgets/widgets.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetsModule
    ],
    declarations: [
        ExpressInviteComponent
    ],
    exports: [
        ExpressInviteComponent
    ]
})

export class ExpressInviteModule { }