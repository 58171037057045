<div class="row">
    <div class="col-xs-12 bottomMargin">
        <h1 class="grouptools center"><span class="group">Group</span>Tools</h1>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 center bottomMargin">
        <i class="fa fa-cog fa-spin fa-3x fa-fw spinner"></i>
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div #pRow class="row">
    <div [class.col-xs-6]="!isModal" [class.offset-xs-3]="!isModal" [class.col-xs-12]="isModal">
        <p class="center">We're sincerely sorry you reached this page, but this is a feature we've not yet implemented. Try back soon as our awesome team pushes new code and exciting features almost daily!</p>
    </div>
</div>
