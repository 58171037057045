var CurrencyHelper = /** @class */ (function () {
    function CurrencyHelper() {
    }
    CurrencyHelper.prototype.getLocaleFromCurrencyAbbreviation = function (currencyAbbreviation) {
        switch (currencyAbbreviation) {
            case "USD":
                return "en-US";
            case "GBP":
                return "en-GBP";
            case "EUR":
                return "fr-FR";
            default:
                return "en-US";
        }
    };
    return CurrencyHelper;
}());
export { CurrencyHelper };
