/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-from-supplier-order-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pages/event-builder/create-from-supplier-order/create-from-supplier-order-view.component.ngfactory";
import * as i3 from "../../pages/event-builder/create-from-supplier-order/create-from-supplier-order-view.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@ngrx/effects";
import * as i7 from "@angular/router";
import * as i8 from "@ngrx/store";
import * as i9 from "ngx-configure";
import * as i10 from "@angular/common";
import * as i11 from "./create-from-supplier-order-dialog.component";
var styles_CreateFromSupplierOrderDialogComponent = [i0.styles];
var RenderType_CreateFromSupplierOrderDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateFromSupplierOrderDialogComponent, data: {} });
export { RenderType_CreateFromSupplierOrderDialogComponent as RenderType_CreateFromSupplierOrderDialogComponent };
export function View_CreateFromSupplierOrderDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "create-from-supplier-order-view", [], null, [[null, "dateSelectedEvent"], [null, "getStartedEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateSelectedEvent" === en)) {
        var pd_0 = (_co.dateSelected() !== false);
        ad = (pd_0 && ad);
    } if (("getStartedEvent" === en)) {
        var pd_1 = (_co.getStartedClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CreateFromSupplierOrderViewComponent_0, i2.RenderType_CreateFromSupplierOrderViewComponent)), i1.ɵdid(1, 245760, null, 0, i3.CreateFromSupplierOrderViewComponent, [i4.FormBuilder, i5.MatDialog, i6.Actions, i7.ActivatedRoute, i8.Store, i9.NgxConfigureService], { matFormFieldAppearance: [0, "matFormFieldAppearance"], isInModal: [1, "isInModal"], authApiState: [2, "authApiState"], SupplierOrderNumber: [3, "SupplierOrderNumber"] }, { dateSelectedEvent: "dateSelectedEvent", getStartedEvent: "getStartedEvent" }), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "standard"; var currVal_1 = true; var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform(_co.supplierOrderNumberCreateEventApiState$)); var currVal_3 = _co.data.SupplierOrderNumber; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CreateFromSupplierOrderDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "create-from-supplier-order-dialog", [], null, null, null, View_CreateFromSupplierOrderDialogComponent_0, RenderType_CreateFromSupplierOrderDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.CreateFromSupplierOrderDialogComponent, [i5.MatDialogRef, i5.MAT_DIALOG_DATA, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateFromSupplierOrderDialogComponentNgFactory = i1.ɵccf("create-from-supplier-order-dialog", i11.CreateFromSupplierOrderDialogComponent, View_CreateFromSupplierOrderDialogComponent_Host_0, {}, {}, []);
export { CreateFromSupplierOrderDialogComponentNgFactory as CreateFromSupplierOrderDialogComponentNgFactory };
