
export interface ApiData<T> {
    data: T;
    // isLoading: boolean;
    // isInitial: boolean;
    apiState: ApiState;
    error: string | null; // null means no error
    // TODO: I can also see the need to know if it is in an initial state,
    // and if adding another boolean for more state, then what about using an enum like AutosaveState ?
}

export enum ApiState {
    Initial = "Initial",
    InFlight = "InFlight", // For both Loading and Saving, any in-flight api request really
    Success = "Success",
    Error = "Error",
}

// DataState is used to determine when the ngrx values have been loaded from the api,
// With ApiState, the difficulty is the InFlight status.  If it is the first InFlight, then data hasn't loaded,
// but if it is the second or subsequent InFlight, then the data has been initially loaded.
// Also this helps more generally because if an ApiState is InFlight, there is no way to tell what is the current data state
export enum DataState {
    Initial = "Initial",
    Success = "Success",
    Error = "Error",
}

// TODO: Could refactor ApiState and DataState as they have partially overlapping functions.
//       It would be better to create a class that could abstract away the base pieces of data, and 
//       expose a nice interface to set the number of in flight requests, when the data has exited initial state, etc...

export interface ApiStateNew {
    dataState: DataState,
    isInFlight: boolean, // For both Loading and Saving, any in-flight api request really
}
