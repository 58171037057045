import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromUser from '../actions/user';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { Injectable } from '@angular/core';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { AuthService } from 'app/authentication/authentication.service';
import { UserService } from 'app/pages/profilePage/user.service';
import { User } from '../models/user';
import { of } from 'rxjs';

@Injectable()
export class UserEffects {

    @Effect()
    loadGroupLeaderProfile = this.actions$
        .pipe(
            ofType<fromUser.LoadGroupLeaderProfile>(fromUser.LOAD_GROUP_LEADER_PROFILE),
            switchMap(action => this.eventService.getGroupLeaderProfile(action.payload)
                        .pipe(
                            map(profile => new fromUser.LoadGroupLeaderProfileOk(profile)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadUser = this.actions$
        .pipe(
            ofType<fromUser.LoadUserState>(fromUser.LOAD_USER_STATE),
            switchMap(action => this.service.getUser()
                        .pipe(
                            map(u => new fromUser.LoadUserStateOk(u)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    setAbout = this.actions$
        .pipe(
            ofType<fromUser.SaveAbout>(fromUser.SAVE_ABOUT),
            switchMap(action => this.service.updateUser(action.payload)
                        .pipe(
                            map(user => new fromUser.SaveAboutOk(user)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    constructor(
        private actions$: Actions,
        private service: UserService,
        private eventService: EventDetailsService
    ) {}
}
