import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FacebookLoginComponent } from './facebook-login.component';
import { FacebookRegisterComponent } from './facebook-register.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [FacebookLoginComponent, FacebookRegisterComponent],
    exports: [FacebookLoginComponent, FacebookRegisterComponent]
})
export class FacebookLoginModule { }