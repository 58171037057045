export var LOAD_SELECTED_ACTIVITIES = '[Event] Load Selected Activities';
export var LOAD_SELECTED_ACTIVITIES_OK = '[Event] Load Selected Activities Ok';
export var CLEAR_SELECTED_ACTIVITIES = '[Event] Clear Selected Activities';
export var CREATE_ACTIVITY = '[Event] Create Activity';
export var CREATE_ACTIVITY_OK = '[Event] Create Activity Ok';
export var UPDATE_ACTIVITY = '[Event] Update Activity';
export var UPDATE_ACTIVITY_OK = '[Event] Update Activity Ok';
export var DELETE_ACTIVITY = '[Event] Delete Activity';
export var DELETE_ACTIVITY_OK = '[Event] Delete Activity Ok';
export var CREATE_OPTION = '[Event] Create Option';
export var CREATE_OPTION_OK = '[Event] Create Option Ok';
export var UPDATE_OPTION = '[Event] Update Option';
export var UPDATE_OPTION_OK = '[Event] Update Option Ok';
export var DELETE_OPTION = '[Event] Delete Option';
export var DELETE_OPTION_OK = '[Event] Delete Option Ok';
export var CREATE_ACTIVITY_AND_OPTIONS = '[Event] Create Activity and Options';
export var CREATE_ACTIVITY_AND_OPTIONS_OK = '[Event] Create Activity and Options Ok';
export var UPDATE_ACTIVITY_AND_OPTIONS = '[Event] Update Activity and Options';
export var UPDATE_ACTIVITY_AND_OPTIONS_OK = '[Event] Update Activity and Options Ok';
export var DELETE_ACTIVITY_AND_OPTIONS = '[Event] Delete Activity and Options';
export var DELETE_ACTIVITY_AND_OPTIONS_OK = '[Event] Delete Activity and Options Ok';
var LoadSelectedActivities = /** @class */ (function () {
    function LoadSelectedActivities(payload) {
        this.payload = payload;
        this.type = LOAD_SELECTED_ACTIVITIES;
    }
    return LoadSelectedActivities;
}());
export { LoadSelectedActivities };
var LoadSelectedActivitiesOk = /** @class */ (function () {
    function LoadSelectedActivitiesOk(payload) {
        this.payload = payload;
        this.type = LOAD_SELECTED_ACTIVITIES_OK;
    }
    return LoadSelectedActivitiesOk;
}());
export { LoadSelectedActivitiesOk };
var ClearSelectedActivities = /** @class */ (function () {
    function ClearSelectedActivities() {
        this.type = CLEAR_SELECTED_ACTIVITIES;
    }
    return ClearSelectedActivities;
}());
export { ClearSelectedActivities };
var CreateActivity = /** @class */ (function () {
    function CreateActivity(payload) {
        this.payload = payload;
        this.type = CREATE_ACTIVITY;
    } // TODO: determine if this payload is needed
    return CreateActivity;
}());
export { CreateActivity };
var CreateActivityOk = /** @class */ (function () {
    function CreateActivityOk(payload) {
        this.payload = payload;
        this.type = CREATE_ACTIVITY_OK;
    }
    return CreateActivityOk;
}());
export { CreateActivityOk };
var UpdateActivity = /** @class */ (function () {
    function UpdateActivity(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY;
    } // TODO: determine if this payload is needed
    return UpdateActivity;
}());
export { UpdateActivity };
var UpdateActivityOk = /** @class */ (function () {
    function UpdateActivityOk(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_OK;
    }
    return UpdateActivityOk;
}());
export { UpdateActivityOk };
var DeleteActivity = /** @class */ (function () {
    function DeleteActivity(payload) {
        this.payload = payload;
        this.type = DELETE_ACTIVITY;
    } // TODO: determine if this payload is needed
    return DeleteActivity;
}());
export { DeleteActivity };
var DeleteActivityOk = /** @class */ (function () {
    function DeleteActivityOk(payload) {
        this.payload = payload;
        this.type = DELETE_ACTIVITY_OK;
    }
    return DeleteActivityOk;
}());
export { DeleteActivityOk };
var CreateOption = /** @class */ (function () {
    function CreateOption(payload) {
        this.payload = payload;
        this.type = CREATE_OPTION;
    } // TODO: determine if this payload is needed
    return CreateOption;
}());
export { CreateOption };
var CreateOptionOk = /** @class */ (function () {
    function CreateOptionOk(payload) {
        this.payload = payload;
        this.type = CREATE_OPTION_OK;
    }
    return CreateOptionOk;
}());
export { CreateOptionOk };
var UpdateOption = /** @class */ (function () {
    function UpdateOption(payload) {
        this.payload = payload;
        this.type = UPDATE_OPTION;
    } // TODO: determine if this payload is needed
    return UpdateOption;
}());
export { UpdateOption };
var UpdateOptionOk = /** @class */ (function () {
    function UpdateOptionOk(payload) {
        this.payload = payload;
        this.type = UPDATE_OPTION_OK;
    }
    return UpdateOptionOk;
}());
export { UpdateOptionOk };
var DeleteOption = /** @class */ (function () {
    function DeleteOption(payload) {
        this.payload = payload;
        this.type = DELETE_OPTION;
    } // TODO: determine if this payload is needed
    return DeleteOption;
}());
export { DeleteOption };
var DeleteOptionOk = /** @class */ (function () {
    function DeleteOptionOk(payload) {
        this.payload = payload;
        this.type = DELETE_OPTION_OK;
    }
    return DeleteOptionOk;
}());
export { DeleteOptionOk };
var CreateActivityAndOptions = /** @class */ (function () {
    function CreateActivityAndOptions(payload) {
        this.payload = payload;
        this.type = CREATE_ACTIVITY_AND_OPTIONS;
    } // TODO: determine if this payload is needed
    return CreateActivityAndOptions;
}());
export { CreateActivityAndOptions };
var CreateActivityAndOptionsOk = /** @class */ (function () {
    function CreateActivityAndOptionsOk(payload) {
        this.payload = payload;
        this.type = CREATE_ACTIVITY_AND_OPTIONS_OK;
    } // TODO: determine if this payload is needed
    return CreateActivityAndOptionsOk;
}());
export { CreateActivityAndOptionsOk };
var UpdateActivityAndOptions = /** @class */ (function () {
    function UpdateActivityAndOptions(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_AND_OPTIONS;
    } // TODO: determine if this payload is needed
    return UpdateActivityAndOptions;
}());
export { UpdateActivityAndOptions };
var UpdateActivityAndOptionsOk = /** @class */ (function () {
    function UpdateActivityAndOptionsOk(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_AND_OPTIONS_OK;
    } // TODO: determine if this payload is needed
    return UpdateActivityAndOptionsOk;
}());
export { UpdateActivityAndOptionsOk };
var DeleteActivityAndOptions = /** @class */ (function () {
    function DeleteActivityAndOptions(payload) {
        this.payload = payload;
        this.type = DELETE_ACTIVITY_AND_OPTIONS;
    } // TODO: determine if this payload is needed
    return DeleteActivityAndOptions;
}());
export { DeleteActivityAndOptions };
var DeleteActivityAndOptionsOk = /** @class */ (function () {
    function DeleteActivityAndOptionsOk(payload) {
        this.payload = payload;
        this.type = DELETE_ACTIVITY_AND_OPTIONS_OK;
    } // TODO: determine if this payload is needed
    return DeleteActivityAndOptionsOk;
}());
export { DeleteActivityAndOptionsOk };
