import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EventBuilderContainerComponent } from './event-builder-container.component';
import { GTNgaModule } from '../../../../grouptools-theme/gtnga.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GTNgaModule
    ],
    declarations: [
        EventBuilderContainerComponent,
    ],
    exports: [
        EventBuilderContainerComponent
    ]
})
export class EventBuilderContainerModule { }