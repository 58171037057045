import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';

@Component({
  selector: 'grouptools-loader',
  templateUrl: './grouptoolsLoader.component.html',
  styleUrls: ['./grouptoolsLoader.component.scss']
})
export class GroupToolsLoaderComponent implements AfterViewInit{
  @ViewChild('loaderElement') loaderEl: ElementRef;

  constructor(
    private store: Store<fromRoot.State>
  ) { }
  
  ngAfterViewInit() {
    this.store.select(state => state.loader)
         .subscribe((loader) => {
          if (loader.show === true) {
            this.loaderEl.nativeElement.style['display'] = 'block';
          } else {
            this.loaderEl.nativeElement.style['display'] = 'none';
          }
        });
  }
}