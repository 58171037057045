export var SET_SELECTED_ID = '[AttendeePackages] Set Selected ID';
export var LOGOUT = '[AttendeePackages] Logout';
var SetSelectedPackageId = /** @class */ (function () {
    function SetSelectedPackageId(payload) {
        this.payload = payload;
        this.type = SET_SELECTED_ID;
    }
    return SetSelectedPackageId;
}());
export { SetSelectedPackageId };
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = LOGOUT;
    }
    return Logout;
}());
export { Logout };
