import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { URLConfig, ClientAuthConfig } from '../helpers/config.service';


import { HttpClient } from '@angular/common/http';

@Injectable()
export class PasswordResetService {
    constructor(private http: HttpClient,
                private URLs: URLConfig,
                private ClientAuth: ClientAuthConfig) {}

    public verifySecurityToken(token): Observable<any> {
        return this.http.post(
        this.URLs._verifySecurityToken(), token)
    }

  public resetPassword(resetPasswordRequest): Observable<any> {
        return this.http.post(
        this.URLs._resetPassword(), resetPasswordRequest)
    }
}
