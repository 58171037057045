import { createSelector } from "@ngrx/store";
import { cloneDeep as _cloneDeep } from "lodash";
var getActionsState = function (state) { return state.actionsState; };
var ɵ0 = getActionsState;
// =================================================================================
//                                      Selectors
// =================================================================================
export var getActionsList = createSelector(getActionsState, function (actionsState) { return actionsState.actions; });
export var getActionsListCopy = createSelector(getActionsList, function (actionsList) { return _cloneDeep(actionsList); } // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
);
export var getActionsEventId = createSelector(getActionsState, function (actionsState) { return actionsState.eventId; });
export { ɵ0 };
