import { createSelector } from "@ngrx/store";
import { AppState } from "../models/app-state";
import { cloneDeep as _cloneDeep } from "lodash";
import { createOldAttendeeFromAttendee } from "../models/attendee";
import { attendeeEntitySelectors } from "../models/attendees.state";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import { ApiStateNew } from "../models/apiData";

const getAttendeesState = (state: AppState) => state.attendeesState;

// =================================================================================
//                                      Selectors
// =================================================================================

//-----------------------------------------------------------
const getAttendeesEntity = createSelector(
    getAttendeesState,
    attendeesState => attendeesState.attendees
);
//-----------------------------------------------------------
export const getAttendees = createSelector(
    getAttendeesEntity,
    attendeesEntity => attendeeEntitySelectors.selectAll(attendeesEntity)
);
//-----------------------------------------------------------
export const getAttendeesSortedNewest = createSelector(
    getAttendees,
    attendees => {
        return [...attendees].sort((a, b) => {
            if (a.eventAttendeeId > b.eventAttendeeId) return -1;

            if (a.eventAttendeeId < b.eventAttendeeId) return 1;
            
            return 0;
        });
    }
);
//-----------------------------------------------------------
export const getAttendeesCopy = createSelector(
    getAttendees,
    attendees => _cloneDeep(attendees)
);
//-----------------------------------------------------------
export const getAttendeesAsOld = createSelector(
    getAttendees,
    attendees => attendees.map(createOldAttendeeFromAttendee)
);
//-----------------------------------------------------------
export const getAttendeesAsAttendeeUpserts = createSelector(
    getAttendeesSortedNewest,
    attendees => attendees.map(AttendeeUpsertRequest.createFromAttendee)
);
//-----------------------------------------------------------
export const getAttendesApiState = createSelector(
    getAttendeesEntity,
    attendeesEntity => {
        return {
            dataState: attendeesEntity.dataState,
            isInFlight: attendeesEntity.isInFlight,
        } as ApiStateNew;
    }
)
//-----------------------------------------------------------
export const getLeaderSummary = createSelector(
    getAttendeesState,
    attendeesState => attendeesState.leaderSummary
);
//-----------------------------------------------------------

// =================================================================================
//                                      End
// =================================================================================