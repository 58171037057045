export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["EimPageAssignAttendeeToEventItem"] = "[Event Item Manager Page] Assign Attendee to Event Item";
    ActionTypes["EimPageUnassignAttendeeFromEventItem"] = "[Event Item Manager Page] Unassign Attendee from Event Item";
    ActionTypes["EimApiAutosaveAssignmentsSuccess"] = "[Event Item Api] Autosave Assignments Success";
    ActionTypes["EimApiAutosaveAssignmentsFailure"] = "[Event Item Api] Autosave Assignments Failure";
    ActionTypes["EimPageLoadAttendees"] = "[Event Item Manager Page] Load Attendees";
    ActionTypes["EimApiLoadAttendeesSuccess"] = "[Event Item Api] Load Attendees Success";
    ActionTypes["EimApiLoadAttendeesFailure"] = "[Event Item Api] Load Attendees Failure";
    // Parsing attendees from csv is taken care of locally
    // EIM_PAGE_PARSE_ATTENDEES_FROM_CSV = '[Event Item Manager Page] Parse Attendees From Csv',
    // EIM_PAGE_PARSE_ATTENDEES_FROM_CSV_SUCCESS = '[Event Item Manager Page] Parse Attendees From Csv Success',
    // EIM_PAGE_PARSE_ATTENDEES_FROM_CSV_FAILURE = '[Event Item Manager Page] Parse Attendees From Csv Failure',
    // EIM_PAGE_ADD_IMPORTED_ATTENDEES = '[Event Item Manager Page] Add Imported Attendees',
    ActionTypes["EimPageLoadEventItems"] = "[Event Item Manager Page] Load Event Items";
    ActionTypes["EimApiLoadEventItemsSuccess"] = "[Event Item Api] Load Event Items Success";
    ActionTypes["EimApiLoadEventItemsFailure"] = "[Event Item Api] Load Event Items Failure";
    ActionTypes["EimPageDeleteAttendee"] = "[Event Item Manager Page] Delete Attendee";
    ActionTypes["EiEffectsDeleteExistingAttendee"] = "[Event Item Effects] Delete Existing Attendee";
    ActionTypes["EiEffectsDeleteNewAttendee"] = "[Event Item Effects] Delete New Attendee";
    ActionTypes["EimApiDeleteAttendeeSuccess"] = "[Event Item Api] Delete Attendee Success";
    ActionTypes["EimApiDeleteAttendeeFailure"] = "[Event Item Api] Delete Attendee Failure";
    ActionTypes["EimPageDeletedIdsProcessed"] = "[Event Item Manager Page] Deleted Ids Processed";
    ActionTypes["EimPageAttendeesHaveBeenEdited"] = "[Event Item Manager Page] Attendees Have Been Edited";
    ActionTypes["EiEffectsSaveAttendees"] = "[Event Item Effects] Save Attendees";
    ActionTypes["EiEffectsNoAttendeesToSave"] = "[Event Item Effects] No Attendees To Save";
    ActionTypes["EimPageSaveAttendees"] = "[Event Item Manager Page] Save Attendees";
    ActionTypes["EimApiSaveAttendeesSuccess"] = "[Event Item Manager Api] Save Attendees Success";
    ActionTypes["EimApiSaveAttendeesFailure"] = "[Event Item Manager Api] Save Attendees Failure";
    ActionTypes["EimPageSelectTab"] = "[Event Item Manager Page] Select Tab";
    ActionTypes["EimGetShowList"] = "[Order Number Login Page] Get Show List";
    ActionTypes["EimGetShowListSuccess"] = "[Auth Api] Get Show List Success";
    ActionTypes["EimGetShowListFailure"] = "[Auth Api] Get Show List Failure";
    ActionTypes["EimPageNotifyAttendeesTicketsReady"] = "[Event Item Manager Page] Notify Attendees Tickets Ready";
    ActionTypes["EimPageNotifySelectedAttendeesTicketsReady"] = "[Event Item Manager Page] Notify Selected Attendees Tickets Ready";
    ActionTypes["EimApiNotifyAttendeesTicketsReadyResponse"] = "[Event Item Manager Api] Notify Attendees Tickets Ready Response";
    ActionTypes["EimApiNotifyAttendeesTicketsReadyFailure"] = "[Event Item Manager Api] Notify Attendees Tickets Ready Failure";
    ActionTypes["EimApiNotifyAttendeesTicketsReadyTooSoonError"] = "[Event Item Manager Api] Notify Attendees Tickets Ready Too Soon Error";
    ActionTypes["EimPageGroupLeaderDownloadTickets"] = "[Event Item Manager Page] Group Leader Download Tickets";
    ActionTypes["EimPageGroupLeaderDownloadSelectedTickets"] = "[Event Item Manager Page] Group Leader Download Selected Tickets";
    ActionTypes["EimPageGroupLeaderDownloadAllTickets"] = "[Event Item Manager Page] Group Leader Download All Tickets";
    ActionTypes["EimPageGroupLeaderDownloadTooManyTicketsError"] = "[Event Item Manager Page] Group Leader Download Too Many Tickets Error";
    ActionTypes["EimApiGroupLeaderDownloadTicketsTooSoonError"] = "[Event Item Manager Api] Group Leader Download Tickets Too Soon Error";
    ActionTypes["EimApiGroupLeaderDownloadTicketsResponse"] = "[Event Item Manager Api] Group Leader Download Tickets Response";
    ActionTypes["EimApiGroupLeaderDownloadTicketsFailure"] = "[Event Item Manager Api] Group Leader Download Tickets Failure";
    ActionTypes["EimPageDownloadManifest"] = "[Event Item Manager Page] Download Manifest";
    ActionTypes["EimApiDownloadManifestResponse"] = "[Event Item Manager Page] Download Manifest Response";
    ActionTypes["EimApiDownloadManifestFailure"] = "[Event Item Manager Page] Download Manifest Failure";
    ActionTypes["EimEffectsDownloadFile"] = "[Event Item Effects] Download File";
    ActionTypes["EimPageSetDeliverySelectionState"] = "[Event Item Manager Page] Set Delivery Selection State";
    ActionTypes["EimPageSetDeliverySelectionStateAll"] = "[Event Item Manager Page] Set Delivery Selection State All";
    // WARNING: The string below needs be the exact same string from the same action in authentication.action.ts to prevent warnings
    ActionTypes["AuthEffectsClearAllData"] = "[Auth Effect] Clear All Data";
})(ActionTypes || (ActionTypes = {}));
var EimPageAssignAttendeeToEventItem = /** @class */ (function () {
    function EimPageAssignAttendeeToEventItem(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageAssignAttendeeToEventItem;
    }
    return EimPageAssignAttendeeToEventItem;
}());
export { EimPageAssignAttendeeToEventItem };
var EimPageUnassignAttendeeFromEventItem = /** @class */ (function () {
    function EimPageUnassignAttendeeFromEventItem(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageUnassignAttendeeFromEventItem;
    }
    return EimPageUnassignAttendeeFromEventItem;
}());
export { EimPageUnassignAttendeeFromEventItem };
var EimApiAutosaveAssignmentsSuccess = /** @class */ (function () {
    function EimApiAutosaveAssignmentsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiAutosaveAssignmentsSuccess;
    }
    return EimApiAutosaveAssignmentsSuccess;
}());
export { EimApiAutosaveAssignmentsSuccess };
var EimApiAutosaveAssignmentsFailure = /** @class */ (function () {
    function EimApiAutosaveAssignmentsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiAutosaveAssignmentsFailure;
    }
    return EimApiAutosaveAssignmentsFailure;
}());
export { EimApiAutosaveAssignmentsFailure };
var EimPageLoadAttendees = /** @class */ (function () {
    function EimPageLoadAttendees() {
        this.type = ActionTypes.EimPageLoadAttendees;
    }
    return EimPageLoadAttendees;
}());
export { EimPageLoadAttendees };
var EimApiLoadAttendeesSuccess = /** @class */ (function () {
    function EimApiLoadAttendeesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiLoadAttendeesSuccess;
    }
    return EimApiLoadAttendeesSuccess;
}());
export { EimApiLoadAttendeesSuccess };
var EimApiLoadAttendeesFailure = /** @class */ (function () {
    function EimApiLoadAttendeesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiLoadAttendeesFailure;
    }
    return EimApiLoadAttendeesFailure;
}());
export { EimApiLoadAttendeesFailure };
// export class EimPageAddImportedAttendees implements Action {
//     readonly type = ActionTypes.EIM_PAGE_ADD_IMPORTED_ATTENDEES;
//     constructor(public payload: {importedAttendees: AttendeeUpsertRequest[]}) {}
// }
var EimPageLoadEventItems = /** @class */ (function () {
    function EimPageLoadEventItems() {
        this.type = ActionTypes.EimPageLoadEventItems;
    }
    return EimPageLoadEventItems;
}());
export { EimPageLoadEventItems };
var EimApiLoadEventItemsSuccess = /** @class */ (function () {
    function EimApiLoadEventItemsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiLoadEventItemsSuccess;
    }
    return EimApiLoadEventItemsSuccess;
}());
export { EimApiLoadEventItemsSuccess };
var EimApiLoadEventItemsFailure = /** @class */ (function () {
    function EimApiLoadEventItemsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiLoadEventItemsFailure;
    }
    return EimApiLoadEventItemsFailure;
}());
export { EimApiLoadEventItemsFailure };
var EimPageDeleteAttendee = /** @class */ (function () {
    function EimPageDeleteAttendee(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageDeleteAttendee;
    }
    return EimPageDeleteAttendee;
}());
export { EimPageDeleteAttendee };
var EiEffectsDeleteExistingAttendee = /** @class */ (function () {
    function EiEffectsDeleteExistingAttendee(payload) {
        this.payload = payload;
        this.type = ActionTypes.EiEffectsDeleteExistingAttendee;
    }
    return EiEffectsDeleteExistingAttendee;
}());
export { EiEffectsDeleteExistingAttendee };
var EiEffectsDeleteNewAttendee = /** @class */ (function () {
    function EiEffectsDeleteNewAttendee(payload) {
        this.payload = payload;
        this.type = ActionTypes.EiEffectsDeleteNewAttendee;
    }
    return EiEffectsDeleteNewAttendee;
}());
export { EiEffectsDeleteNewAttendee };
var EimApiDeleteAttendeeSuccess = /** @class */ (function () {
    function EimApiDeleteAttendeeSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiDeleteAttendeeSuccess;
    }
    return EimApiDeleteAttendeeSuccess;
}());
export { EimApiDeleteAttendeeSuccess };
var EimApiDeleteAttendeeFailure = /** @class */ (function () {
    function EimApiDeleteAttendeeFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiDeleteAttendeeFailure;
    }
    return EimApiDeleteAttendeeFailure;
}());
export { EimApiDeleteAttendeeFailure };
var EimPageDeletedIdsProcessed = /** @class */ (function () {
    function EimPageDeletedIdsProcessed(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageDeletedIdsProcessed;
    }
    return EimPageDeletedIdsProcessed;
}());
export { EimPageDeletedIdsProcessed };
var EimPageAttendeesHaveBeenEdited = /** @class */ (function () {
    function EimPageAttendeesHaveBeenEdited(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageAttendeesHaveBeenEdited;
    }
    return EimPageAttendeesHaveBeenEdited;
}());
export { EimPageAttendeesHaveBeenEdited };
var EiEffectsSaveAttendees = /** @class */ (function () {
    function EiEffectsSaveAttendees() {
        this.type = ActionTypes.EiEffectsSaveAttendees;
    }
    return EiEffectsSaveAttendees;
}());
export { EiEffectsSaveAttendees };
var EiEffectsNoAttendeesToSave = /** @class */ (function () {
    function EiEffectsNoAttendeesToSave() {
        this.type = ActionTypes.EiEffectsNoAttendeesToSave;
    }
    return EiEffectsNoAttendeesToSave;
}());
export { EiEffectsNoAttendeesToSave };
var EimPageSaveAttendees = /** @class */ (function () {
    function EimPageSaveAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageSaveAttendees;
    }
    return EimPageSaveAttendees;
}());
export { EimPageSaveAttendees };
var EimApiSaveAttendeesSuccess = /** @class */ (function () {
    function EimApiSaveAttendeesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiSaveAttendeesSuccess;
    }
    return EimApiSaveAttendeesSuccess;
}());
export { EimApiSaveAttendeesSuccess };
var EimApiSaveAttendeesFailure = /** @class */ (function () {
    function EimApiSaveAttendeesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiSaveAttendeesFailure;
    }
    return EimApiSaveAttendeesFailure;
}());
export { EimApiSaveAttendeesFailure };
var EimPageSelectTab = /** @class */ (function () {
    function EimPageSelectTab(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageSelectTab;
    }
    return EimPageSelectTab;
}());
export { EimPageSelectTab };
var EimGetShowList = /** @class */ (function () {
    function EimGetShowList() {
        this.type = ActionTypes.EimGetShowList;
    }
    return EimGetShowList;
}());
export { EimGetShowList };
var EimGetShowListSuccess = /** @class */ (function () {
    function EimGetShowListSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimGetShowListSuccess;
    }
    return EimGetShowListSuccess;
}());
export { EimGetShowListSuccess };
var EimGetShowListFailure = /** @class */ (function () {
    function EimGetShowListFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimGetShowListFailure;
    }
    return EimGetShowListFailure;
}());
export { EimGetShowListFailure };
var EimPageNotifyAttendeesTicketsReady = /** @class */ (function () {
    function EimPageNotifyAttendeesTicketsReady(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageNotifyAttendeesTicketsReady;
    }
    return EimPageNotifyAttendeesTicketsReady;
}());
export { EimPageNotifyAttendeesTicketsReady };
var EimPageNotifySelectedAttendeesTicketsReady = /** @class */ (function () {
    function EimPageNotifySelectedAttendeesTicketsReady() {
        this.type = ActionTypes.EimPageNotifySelectedAttendeesTicketsReady;
    }
    return EimPageNotifySelectedAttendeesTicketsReady;
}());
export { EimPageNotifySelectedAttendeesTicketsReady };
var EimApiNotifyAttendeesTicketsReadyResponse = /** @class */ (function () {
    function EimApiNotifyAttendeesTicketsReadyResponse(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiNotifyAttendeesTicketsReadyResponse;
    }
    return EimApiNotifyAttendeesTicketsReadyResponse;
}());
export { EimApiNotifyAttendeesTicketsReadyResponse };
var EimApiNotifyAttendeesTicketsReadyFailure = /** @class */ (function () {
    function EimApiNotifyAttendeesTicketsReadyFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiNotifyAttendeesTicketsReadyFailure;
    }
    return EimApiNotifyAttendeesTicketsReadyFailure;
}());
export { EimApiNotifyAttendeesTicketsReadyFailure };
var EimApiNotifyAttendeesTicketsReadyTooSoonError = /** @class */ (function () {
    function EimApiNotifyAttendeesTicketsReadyTooSoonError() {
        this.type = ActionTypes.EimApiNotifyAttendeesTicketsReadyTooSoonError;
    }
    return EimApiNotifyAttendeesTicketsReadyTooSoonError;
}());
export { EimApiNotifyAttendeesTicketsReadyTooSoonError };
var EimPageGroupLeaderDownloadTickets = /** @class */ (function () {
    function EimPageGroupLeaderDownloadTickets(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageGroupLeaderDownloadTickets;
    }
    return EimPageGroupLeaderDownloadTickets;
}());
export { EimPageGroupLeaderDownloadTickets };
var EimPageGroupLeaderDownloadSelectedTickets = /** @class */ (function () {
    function EimPageGroupLeaderDownloadSelectedTickets(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageGroupLeaderDownloadSelectedTickets;
    }
    return EimPageGroupLeaderDownloadSelectedTickets;
}());
export { EimPageGroupLeaderDownloadSelectedTickets };
var EimPageGroupLeaderDownloadAllTickets = /** @class */ (function () {
    function EimPageGroupLeaderDownloadAllTickets(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageGroupLeaderDownloadAllTickets;
    }
    return EimPageGroupLeaderDownloadAllTickets;
}());
export { EimPageGroupLeaderDownloadAllTickets };
var EimPageGroupLeaderDownloadTooManyTicketsError = /** @class */ (function () {
    function EimPageGroupLeaderDownloadTooManyTicketsError(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageGroupLeaderDownloadTooManyTicketsError;
    }
    return EimPageGroupLeaderDownloadTooManyTicketsError;
}());
export { EimPageGroupLeaderDownloadTooManyTicketsError };
var EimApiGroupLeaderDownloadTicketsTooSoonError = /** @class */ (function () {
    function EimApiGroupLeaderDownloadTicketsTooSoonError() {
        this.type = ActionTypes.EimApiGroupLeaderDownloadTicketsTooSoonError;
    }
    return EimApiGroupLeaderDownloadTicketsTooSoonError;
}());
export { EimApiGroupLeaderDownloadTicketsTooSoonError };
var EimApiGroupLeaderDownloadTicketsResponse = /** @class */ (function () {
    function EimApiGroupLeaderDownloadTicketsResponse(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiGroupLeaderDownloadTicketsResponse;
    }
    return EimApiGroupLeaderDownloadTicketsResponse;
}());
export { EimApiGroupLeaderDownloadTicketsResponse };
var EimApiGroupLeaderDownloadTicketsFailure = /** @class */ (function () {
    function EimApiGroupLeaderDownloadTicketsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiGroupLeaderDownloadTicketsFailure;
    }
    return EimApiGroupLeaderDownloadTicketsFailure;
}());
export { EimApiGroupLeaderDownloadTicketsFailure };
var EimPageDownloadManifest = /** @class */ (function () {
    function EimPageDownloadManifest() {
        this.type = ActionTypes.EimPageDownloadManifest;
    }
    return EimPageDownloadManifest;
}());
export { EimPageDownloadManifest };
var EimApiDownloadManifestResponse = /** @class */ (function () {
    function EimApiDownloadManifestResponse(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiDownloadManifestResponse;
    }
    return EimApiDownloadManifestResponse;
}());
export { EimApiDownloadManifestResponse };
var EimApiDownloadManifestFailure = /** @class */ (function () {
    function EimApiDownloadManifestFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimApiDownloadManifestFailure;
    }
    return EimApiDownloadManifestFailure;
}());
export { EimApiDownloadManifestFailure };
var EimEffectsDownloadFile = /** @class */ (function () {
    function EimEffectsDownloadFile(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimEffectsDownloadFile;
    }
    return EimEffectsDownloadFile;
}());
export { EimEffectsDownloadFile };
var EimPageSetDeliverySelectionState = /** @class */ (function () {
    function EimPageSetDeliverySelectionState(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageSetDeliverySelectionState;
    }
    return EimPageSetDeliverySelectionState;
}());
export { EimPageSetDeliverySelectionState };
var EimPageSetDeliverySelectionStateAll = /** @class */ (function () {
    function EimPageSetDeliverySelectionStateAll(payload) {
        this.payload = payload;
        this.type = ActionTypes.EimPageSetDeliverySelectionStateAll;
    }
    return EimPageSetDeliverySelectionStateAll;
}());
export { EimPageSetDeliverySelectionStateAll };
//-----------------------------------------------------------------
// This isn't used directly, but its string matches the same action in authentication.action.ts so it prevents warnings from showing
var AuthEffectsClearAllData = /** @class */ (function () {
    function AuthEffectsClearAllData() {
        this.type = ActionTypes.AuthEffectsClearAllData;
    }
    return AuthEffectsClearAllData;
}());
export { AuthEffectsClearAllData };
