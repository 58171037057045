import { Router, ActivatedRoute } from '@angular/router';
import { EqualPasswordsValidator } from 'app/grouptools-theme/validators/equalsPasswords.validator';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginPerson, AuthService } from '../../authentication/authentication.service';
import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import 'style-loader!./facebook-register.component.scss';

declare const facebook: any;

@Component({
    selector: 'facebook-register',
    templateUrl: './facebook-register.component.html'
})
export class FacebookRegisterComponent {
    public PASSWORD_REGX: string = '^((?=.*[A-Z])(?=.*\\d)(?=.*[a-z])[A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{8,})|([A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{12,})$';
    public EMAIL_REGX: string = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
    
    public auth2: any;

    @Output() registerComplete: EventEmitter<any> = new EventEmitter<any>();
    @Input() user: any;
    @Input() inModal: boolean;
    @ViewChild('button') button: ElementRef;

    form: FormGroup;
    public email: AbstractControl;
    public password: AbstractControl;
    public repeatPassword: AbstractControl;
    public passwords: FormGroup;

    constructor(
        public fb: FormBuilder,
        private _service: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group(this.formFields);
        this.email = <FormGroup>this.form.controls['email'];
        this.email.setValue(this.user.Email);
        this.passwords = <FormGroup>this.form.controls['passwords'];
        this.password = this.passwords.controls['password'];
        this.repeatPassword = this.passwords.controls['repeatPassword'];

        this.form.valueChanges
            .subscribe(data => {
                this.onValueChanged(data);
            });
    }

    onSubmit(formValue) {
        if (this.form.valid) {
            if(!this.user.Email) {
                if(this.email.value) {
                    this.user.Email = this.email.value;
                }
            }
            this.user.hasEmail = !!this.user.Email;

            this._service.registerViaFacebook({
                ExternalId: this.user.Id,
                ExternalSourceHasEmail: this.user.hasEmail,
                FirstName: this.user.FirstName,
                LastName: this.user.LastName,
                Email: this.user.Email,
                Password: this.password.value,
                AccessToken: this.user.AccessToken
            }).subscribe(res => {
                // try to login
                this.login();
            });
        }
    }

    login() {
        let loginPerson = new LoginPerson(
            this.user.Email,
            this.password.value);

        this._service.login(loginPerson).subscribe(
            token => {
                if (token.token_type) {
                    localStorage.setItem('access_token', token.access_token);
                    localStorage.setItem('userID', token.userID);
                    localStorage.setItem('login_status', token.login_status);
                    this._service.isLoggedInObservable().subscribe(res => {

                        if (!this.inModal) {
                            let loggedIn = true;
                            let returnUrl = this.route.snapshot.queryParams['returnUrl'];
                            if (returnUrl) {
                                this.router.navigate([returnUrl]);
                            }
                            else {
                                this.router.navigate(['/pages/dashboard']);
                            }
                        } else {
                            // not in modal
                            this.registerComplete.emit('facebook-registered');
                        }

                    });
                }
            },
            error => {
                if (error.status > 0) {
                    alert(JSON.parse(error._body).error_description);
                    //console.log('Server is unresponsive.');
                }
                else {
                    alert('Server is unresponsive.');
                    //console.log('Server is unresponsive.');
                }
            });
    }

    onValueChanged(data?: any) {
        if (!this.form) { return; }

        for (const field in this.formErrors) {
            this.formErrors[field] = '';
            let control = this.form.get(field);

            if (field === "password") {
                let intCont = this.form.get('passwords');
                control = intCont.get('password');
            }

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }

    includeSpecialChar(){
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        return format.test(this.password.value);
    }
    
    formFields = {
        passwords: this.fb.group({
            password: ['', Validators.pattern(this.PASSWORD_REGX)],
            repeatPassword: ['', Validators.pattern(this.PASSWORD_REGX)]
        },
            { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') }),
        email: ['', [Validators.pattern(this.EMAIL_REGX), Validators.required]]
    }

    formErrors = {
        'passwords': '',
        'password': '',
        'email': ''
    };

    validationMessages = {
        'password': {
            'pattern': 'If you create a password, it must be at least 8 characters long and contain an upper case letter, a lower case letter and 1 number.'
        },
        'passwords': {
            'passwordsEqual': 'Passwords must match.',
        },
        'email': {
            'pattern': 'Email address must be valid.',
            'required': 'Email address is required.'
        }
    };
}