import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EventDetailsService } from "app/pages/package-details/event-details.service";
import { GroupTixService } from "app/store/services/group-tix.service";
import { catchError, concatMap, debounceTime, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import * as EimActions from 'app/store/actions/eventItem.action';
import * as EventItemSelectors from 'app/store/selectors/eventItem.selector';
import * as PackageSelectors from 'app/store/selectors/packageDetails.selector';
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import * as EventItemActions from 'app/store/actions/eventItem.action';
// import { retryWithDelay } from 'rxjs-boost/dist/cjs/operators'; // TODO: According to rxjs-boost docs, this should be 'rxjs-boost/operators';
import { DragDropMessagingService } from 'app/store/services/drag-drop-messaging.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from 'app/app.configure-options';
import { GTResponseCodes, GTResponseError } from "app/store/models/gtResponse";
var EventItemEffects = /** @class */ (function () {
    function EventItemEffects(actions$, store$, eventService, groupTixService, dragDropMessagingService, dialog, configService) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.eventService = eventService;
        this.groupTixService = groupTixService;
        this.dragDropMessagingService = dragDropMessagingService;
        this.dialog = dialog;
        this.configService = configService;
        this.loadEventItems = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EimPageLoadEventItems), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        function (action, eventId) {
            return { action: action, eventId: eventId };
        }), switchMap(function (data) { return _this.eventService.getEventItems(data.eventId)
            .pipe(map(function (eventItems) { return new EimActions.EimApiLoadEventItemsSuccess({ eventItems: eventItems }); }), catchError(function (error) { return of(new EimActions.EimApiLoadEventItemsFailure({ errorMessage: error.toString() })); })); }));
        this.clearLocalEventItemStatusVisualState = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EimPageLoadEventItems), tap(function (_) {
            // Don't want to clear this in the ngrxGetValuesOnce methods because the event item
            // state is only updated from the api here (it would cause switching drag/drop views to reset the state prematurely)
            // If the popup isn't showing for a seat when it should, this is a good place to look, its possible
            // it could clear the temporary event item status state not often enough.
            _this.dragDropMessagingService.clearEventItemLinkInvalidatedState();
        }));
        this.loadAttendees = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EimPageLoadAttendees), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        function (action, eventId) {
            return { action: action, eventId: eventId };
        }), switchMap(function (data) { return _this.groupTixService.getDistributionAttendees(data.eventId)
            .pipe(map(function (attendees) {
            // Why are we mapping an AttendeeUpsertRequest to another AttendeeUpsertRequest? Great Question!
            // It is because when the POJO is sent over http and parsed by angular's http client, it does NOT
            // parse it into an actual AttendeeUpsertRequest class.  It treats it more like an interface, so it
            // is missing any getters/setters/methods the actual class has, like isParent and isChild.
            var mappedAttendees = attendees.map(function (a) { return AttendeeUpsertRequest.createAttendeeFromAttendee(a); });
            return new EimActions.EimApiLoadAttendeesSuccess({ attendees: mappedAttendees });
        }), catchError(function (error) { return of(new EimActions.EimApiLoadAttendeesFailure({ errorMessage: error.toString() })); })); }));
        // This effect is to kick off an autosave (if possible) after attendee changes have been made on the attendee tab
        // This is the first of two places an autosave can be kicked off
        this.attendeesHaveBeenEdited = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EimPageAttendeesHaveBeenEdited), debounceTime(1000), 
        // The reducer for this action will add all valid attendees to Waiting
        // This effect attempts to trigger an autosave request if none is already in progress
        // If a save request is already in progress, another will be triggered when it returns in a different effect
        withLatestFrom(this.store$.pipe(select(EventItemSelectors.isAttendeeAutosavingInFlight)), function (action, isAttendeeAutosavingInFlight) { return isAttendeeAutosavingInFlight; }), 
        // Kick off autosaving if there is no autosaving already in flight
        filter(function (isAttendeeAutosavingInFlight) { return !isAttendeeAutosavingInFlight; }), map(function (_) { return new EventItemActions.EiEffectsSaveAttendees(); }));
        this.eiEffectsSaveAttendees = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EiEffectsSaveAttendees), withLatestFrom(this.store$.pipe(select(EventItemSelectors.getWaitingAttendees)), this.store$.pipe(select(EventItemSelectors.getSavedInputAttendeesDictionary)), this.store$.pipe(select(EventItemSelectors.getSavedInputNewEventAttendeeIdMap)), this.store$.pipe(select(EventItemSelectors.getInFlightAttendeesDictionary)), this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        function (action, waitingAttendees, savedInputAttendeesDict, savedInputNewAttendeeIdMap, inFlightAttendeesDict, eventId) {
            return { action: action, waitingAttendees: waitingAttendees, savedInputAttendeesDict: savedInputAttendeesDict, savedInputNewAttendeeIdMap: savedInputNewAttendeeIdMap, inFlightAttendeesDict: inFlightAttendeesDict, eventId: eventId };
        }), map(function (data) {
            // iterate waiting attendees
            //   update new id in waiting if needed (using new id map) (also update parent id if needed)
            //   find corresponding saved attendee (its possible none exists if truly a new attendee)
            //   diff compare to saved attendee (if exists), 
            //     if same, then ignore (already saved)
            //     else if different, or none existant, then save to api
            var e_1, _a;
            var attendeesToSave = [];
            try {
                for (var _b = tslib_1.__values(data.waitingAttendees), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var waitingAttendee = _c.value;
                    waitingAttendee = AttendeeUpsertRequest.copy(waitingAttendee); // copy the ngrx state object into a new object
                    // If this is a new attendee, try to update the eventAttendeeId if already saved
                    if (waitingAttendee.isNewAttendee) {
                        var eventAttendeeId = data.savedInputNewAttendeeIdMap.get(waitingAttendee.newEventAttendeeId);
                        if (eventAttendeeId > 0) {
                            // then this 'new attendee' has already been saved, and has a legitimate eventAttendeeId
                            waitingAttendee.eventAttendeeId = eventAttendeeId;
                        }
                    }
                    // If parent is new and has already been saved, update the child's parent id
                    if (waitingAttendee.isChildOfNewParent) {
                        var parentUserId = data.savedInputNewAttendeeIdMap.get(waitingAttendee.newEventAttendeeIdParent);
                        if (parentUserId > 0) {
                            waitingAttendee.parentUserId = parentUserId;
                        }
                    }
                    // get saved attendee if exists (will be undefined if doesn't exist) (saved attendees will always have an eventAttendeeId)
                    var savedAttendee = void 0;
                    if (waitingAttendee.eventAttendeeId > 0) {
                        savedAttendee = data.savedInputAttendeesDict[waitingAttendee.eventAttendeeId];
                    }
                    var shouldSaveAttendee = void 0;
                    if (!savedAttendee) {
                        // if saved attendee doesn't exist, then this is a new attendee, need to save
                        console.warn("No matching savedAttendee found for waitingAttendee (" + waitingAttendee.eventAttendeeId + "-" + waitingAttendee.newEventAttendeeId + ")");
                        shouldSaveAttendee = true;
                    }
                    else {
                        // compare waiting to saved, if the same, don't save, if different save
                        // TODO: this leaves open the bug for changing attendee, change is in flight, changing back, no change detected in saved, so change back isn't actually saved :-O
                        //       Actually, rethinking that bug, if this effect is only executed when there is no request in flight, then I don't think that bug is an issue
                        if (waitingAttendee.isEqualTo(savedAttendee)) {
                            // TODO: check that isEqualTo performs this function correctly...
                            // Then the attendee in waiting is identical to what is already saved, don't bother saving
                            // console.log("waitingAttendee is equal to savedAttendee");
                            shouldSaveAttendee = false;
                        }
                        else {
                            // Then the attendee in waiting has changes from what is saved, need to save changes
                            console.log("waitingAttendee (" + waitingAttendee.eventAttendeeId + "-" + waitingAttendee.newEventAttendeeId + ") is NOT equal to savedAttendee (" + savedAttendee.eventAttendeeId + "-" + savedAttendee.newEventAttendeeId + ")");
                            // console.log(waitingAttendee);
                            // console.log(savedAttendee);
                            shouldSaveAttendee = true;
                        }
                    }
                    if (shouldSaveAttendee) {
                        attendeesToSave.push(waitingAttendee);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            // Need to clear out waitingAttendees (this will be done in the reducer for either of the dispatched actions below)
            if (attendeesToSave.length > 0) {
                var upsertAttendeeRequest = {
                    attendees: attendeesToSave,
                    skipValidation: false,
                };
                return new EventItemActions.EimPageSaveAttendees({ attendees: upsertAttendeeRequest, eventId: data.eventId });
            }
            else {
                return new EventItemActions.EiEffectsNoAttendeesToSave();
            }
        }));
        this.saveAttendees = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EimPageSaveAttendees), concatMap(function (action) { return _this.groupTixService.upsertAttendees(action.payload.attendees, action.payload.eventId)
            .pipe(map(function (attendeeCollectionPayload) { return new EimActions.EimApiSaveAttendeesSuccess({ attendeeCollectionPayload: attendeeCollectionPayload }); }), catchError(function (error) { return of(new EimActions.EimApiSaveAttendeesFailure({ errorMessage: error.toString(), failedAttendees: action.payload.attendees.attendees })); })); }));
        // This effect is to kick off an autosave (if necessary) after a previous autosave finishes
        // This is the second of two places an autosave can be kicked off
        this.eimApiSaveAttendeesResponse = this.actions$
            .pipe(ofType(EimActions.ActionTypes.EimApiSaveAttendeesSuccess, EimActions.ActionTypes.EimApiSaveAttendeesFailure), withLatestFrom(this.store$.pipe(select(EventItemSelectors.areAttendeesWaitingToBeSaved)), function (action, areAttendeesWaitingToBeSaved) { return areAttendeesWaitingToBeSaved; }), 
        // Kick off autosaving if there are new changes waiting to be saved
        filter(function (areAttendeesWaitingToBeSaved) { return areAttendeesWaitingToBeSaved; }), map(function (data) { return new EventItemActions.EiEffectsSaveAttendees(); }));
        this.deleteAttendee = this.actions$.pipe(ofType(EimActions.ActionTypes.EimPageDeleteAttendee), withLatestFrom(this.store$.pipe(select(EventItemSelectors.getSavedInputNewEventAttendeeIdMap)), function (action, newIdMap) {
            return { action: action, newIdMap: newIdMap };
        }), filter(function (data) { return data.action.payload.eventId > 0 && (data.action.payload.eventAttendeeId > 0 || data.action.payload.newEventAttendeeId > 0); }), map(function (data) {
            var eventId = data.action.payload.eventId;
            var eventAttendeeId = data.action.payload.eventAttendeeId;
            if (eventAttendeeId > 0)
                return new EventItemActions.EiEffectsDeleteExistingAttendee({ eventAttendeeId: eventAttendeeId, eventId: eventId });
            var newEventAttendeeId = data.action.payload.newEventAttendeeId;
            eventAttendeeId = data.newIdMap.get(newEventAttendeeId);
            if (eventAttendeeId > 0)
                return new EventItemActions.EiEffectsDeleteExistingAttendee({ eventAttendeeId: eventAttendeeId, eventId: eventId });
            // There is no known valid eventAttendeeId
            return new EventItemActions.EiEffectsDeleteNewAttendee({ newEventAttendeeId: newEventAttendeeId });
        }));
        this.deleteExistingAttendee = this.actions$.pipe(ofType(EimActions.ActionTypes.EiEffectsDeleteExistingAttendee), concatMap(function (action) { return _this.groupTixService.deleteAttendee(action.payload.eventId, action.payload.eventAttendeeId)
            .pipe(map(function (deletedEventAttendeeIds) { return new EimActions.EimApiDeleteAttendeeSuccess({ deletedEventAttendeeIds: deletedEventAttendeeIds }); }), catchError(function (error) { return of(new EimActions.EimApiDeleteAttendeeFailure({ errorMessage: error.toString(), failedEventAttendeeId: action.payload.eventAttendeeId })); })); }));
        this.autosaveEventItemAssignments = this.actions$.pipe(ofType(EimActions.ActionTypes.EimPageAssignAttendeeToEventItem, EimActions.ActionTypes.EimPageUnassignAttendeeFromEventItem), debounceTime(3000), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEvent)),
        this.store$.pipe(select(EventItemSelectors.getAssignments)), // TODO: Implement selector
        function (action, selectedEventId, assignments) {
            return { action: action, eventId: selectedEventId, assignments: assignments };
        }), 
        // TODO: test if a filter is needed b/c withLatestFrom sometimes emits bad values the first time
        concatMap(function (data) {
            return _this.eventService.assignAttendeesToItems(data.eventId, data.assignments)
                .pipe(map(function (eventItems) { return new EimActions.EimApiAutosaveAssignmentsSuccess({ eventItems: eventItems }); }), catchError(function (error) { return of(new EimActions.EimApiAutosaveAssignmentsFailure({ errorMessage: error.toString() })); }));
        }));
        this.retrieveShowList = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimGetShowList), concatMap(function (action) { return _this.groupTixService.retrieveShowList().pipe(map(function (showListResponse) {
            return new EventItemActions.EimGetShowListSuccess({ showList: showListResponse });
        }), catchError(function (error) { return of(new EventItemActions.EimGetShowListFailure({ errorMessage: error.toString() })); })); }));
        this.notifySelectedAttendeesTicketsReady = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimPageNotifySelectedAttendeesTicketsReady), withLatestFrom(this.store$.pipe(select(EventItemSelectors.getSelectedDeliveryEventItemIds)), function (action, selectedDeliveryEventItemIds) { return selectedDeliveryEventItemIds; }), map(function (selectedDeliveryEventItemIds) { return new EventItemActions.EimPageNotifyAttendeesTicketsReady({ eventItemIds: selectedDeliveryEventItemIds }); }));
        this.notifyAttendeesTicketsReady = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimPageNotifyAttendeesTicketsReady), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        function (action, selectedEventId) {
            return { action: action, selectedEventId: selectedEventId };
        }), concatMap(function (data) { return _this.groupTixService.notifyAttendeesTicketsReady(data.selectedEventId, data.action.payload.eventItemIds).pipe(switchMap(function (notificationCollectionPayload) { return [
            new EventItemActions.EimApiNotifyAttendeesTicketsReadyResponse({ notificationCollectionPayload: notificationCollectionPayload }),
            new EventItemActions.EimPageLoadEventItems(),
        ]; }), catchError(function (error) {
            var responseCode = null;
            if (error instanceof GTResponseError && GTResponseCodes.TooEarlyToGenerateTickets === error.code) {
                return of(new EventItemActions.EimApiNotifyAttendeesTicketsReadyTooSoonError());
            }
            if (error instanceof GTResponseError) {
                responseCode = error.code;
            }
            return of(new EventItemActions.EimApiNotifyAttendeesTicketsReadyFailure({ errorMessage: error.toString(), responseCode: responseCode }));
        }), catchError(function (error) { return of(new EventItemActions.EimApiNotifyAttendeesTicketsReadyFailure({ errorMessage: error.toString(), responseCode: null })); })); }));
        this.groupLeaderDownloadSelectedTickets = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadSelectedTickets), withLatestFrom(this.store$.pipe(select(EventItemSelectors.getSelectedDeliveryEventItemIds)), function (action, selectedEventItemIds) {
            return { selectedEventItemIds: selectedEventItemIds, ticketFormat: action.payload.ticketFormat };
        }), map(function (data) { return new EventItemActions.EimPageGroupLeaderDownloadTickets({ eventItemIds: data.selectedEventItemIds, ticketFormat: data.ticketFormat }); }));
        this.groupLeaderDownloadTickets = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadTickets), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        function (action, selectedEventId) {
            return { action: action, selectedEventId: selectedEventId };
        }), concatMap(function (data) {
            var numberOfTicketsRequested = data.action.payload.eventItemIds.length;
            var maxNumberOfTicketsAllowed = _this.configService.config.maxNumOfTicketsToDownloadAtOnce;
            if (numberOfTicketsRequested > maxNumberOfTicketsAllowed) {
                return of(new EventItemActions.EimPageGroupLeaderDownloadTooManyTicketsError({ numberOfTicketsRequested: numberOfTicketsRequested, maxNumberOfTicketsAllowed: maxNumberOfTicketsAllowed }));
            }
            var numberOfDownloadRetryRequests = _this.configService.config.numberOfDownloadRetryRequests;
            var millisecondsToWaitBetweenRetryRequests = _this.configService.config.millisecondsToWaitBetweenRetryRequests;
            return _this.groupTixService.groupLeaderDownloadTickets(data.selectedEventId, data.action.payload.eventItemIds, data.action.payload.ticketFormat).pipe(
            // retryWithDelay(millisecondsToWaitBetweenRetryRequests, numberOfDownloadRetryRequests),
            switchMap(function (gtFile) { return [
                new EventItemActions.EimEffectsDownloadFile({ gtFile: gtFile }),
                new EventItemActions.EimPageLoadEventItems(),
            ]; }), catchError(function (error) {
                var responseCode = null;
                if (error instanceof GTResponseError && GTResponseCodes.TooEarlyToGenerateTickets === error.code) {
                    return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsTooSoonError());
                }
                if (error instanceof GTResponseError) {
                    responseCode = error.code;
                }
                return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsFailure({ errorMessage: error.toString(), responseCode: responseCode }));
            }));
        }));
        this.groupLeaderDownloadAllTickets = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimPageGroupLeaderDownloadAllTickets), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        this.store$.pipe(select(EventItemSelectors.getEventItemsCount)), function (action, selectedEventId, eventItemCount) {
            return { action: action, selectedEventId: selectedEventId, eventItemCount: eventItemCount };
        }), concatMap(function (data) {
            var numberOfTicketsRequested = data.eventItemCount;
            var maxNumberOfTicketsAllowed = _this.configService.config.maxNumOfTicketsToDownloadAtOnce;
            if (numberOfTicketsRequested > maxNumberOfTicketsAllowed) {
                return of(new EventItemActions.EimPageGroupLeaderDownloadTooManyTicketsError({ numberOfTicketsRequested: numberOfTicketsRequested, maxNumberOfTicketsAllowed: maxNumberOfTicketsAllowed }));
            }
            var numberOfDownloadRetryRequests = _this.configService.config.numberOfDownloadRetryRequests;
            var millisecondsToWaitBetweenRetryRequests = _this.configService.config.millisecondsToWaitBetweenRetryRequests;
            return _this.groupTixService.groupLeaderDownloadAllTickets(data.selectedEventId, data.action.payload.ticketFormat).pipe(
            // retryWithDelay(millisecondsToWaitBetweenRetryRequests, numberOfDownloadRetryRequests),
            switchMap(function (gtFile) { return [
                new EventItemActions.EimEffectsDownloadFile({ gtFile: gtFile }),
                new EventItemActions.EimPageLoadEventItems(),
            ]; }), catchError(function (error) {
                var responseCode = null;
                if (error instanceof GTResponseError && GTResponseCodes.TooEarlyToGenerateTickets === error.code) {
                    return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsTooSoonError());
                }
                if (error instanceof GTResponseError) {
                    responseCode = error.code;
                }
                return of(new EventItemActions.EimApiGroupLeaderDownloadTicketsFailure({ errorMessage: error.toString(), responseCode: responseCode }));
            }));
        }));
        this.downloadManifest = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimPageDownloadManifest), withLatestFrom(this.store$.pipe(select(PackageSelectors.getSelectedPackageId)), 
        // withLatestFrom(this.store$.pipe(select(EventSelectors.getSelectedEventId)),
        function (action, selectedEventId) {
            return { action: action, selectedEventId: selectedEventId };
        }), concatMap(function (data) { return _this.groupTixService.groupLeaderDownloadManifest(data.selectedEventId).pipe(map(function (gtFile) { return new EventItemActions.EimApiDownloadManifestResponse({ gtFile: gtFile }); }), catchError(function (error) { return of(new EventItemActions.EimApiDownloadManifestFailure({ errorMessage: error.toString(), responseCode: null })); })); }));
        this.downloadFile = this.actions$.pipe(ofType(EventItemActions.ActionTypes.EimEffectsDownloadFile, EventItemActions.ActionTypes.EimApiDownloadManifestResponse), tap(function (action) {
            var gtFile = action.payload.gtFile;
            var a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = gtFile.fileName;
            a.href = URL.createObjectURL(gtFile.blob);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "loadEventItems", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "clearLocalEventItemStatusVisualState", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "loadAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "attendeesHaveBeenEdited", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "eiEffectsSaveAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "saveAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "eimApiSaveAttendeesResponse", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "deleteAttendee", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "deleteExistingAttendee", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "autosaveEventItemAssignments", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "retrieveShowList", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "notifySelectedAttendeesTicketsReady", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "notifyAttendeesTicketsReady", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "groupLeaderDownloadSelectedTickets", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "groupLeaderDownloadTickets", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "groupLeaderDownloadAllTickets", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "downloadManifest", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], EventItemEffects.prototype, "downloadFile", void 0);
    return EventItemEffects;
}());
export { EventItemEffects };
