import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { ContactService } from "app/pages/contacts/contact.service";
import * as ContactActions from "app/store/actions/contacts.action";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
var ContactsEffects = /** @class */ (function () {
    // @Effect()
    // loadGroupLeaderContacts$ = this.actions$.pipe(
    //     ofType<ContactActions.>(ContactActions.ActionTypes.),
    //     concatMap(action => this.contactsService.getEventLeaderContacts(action.payload)
    //                 .pipe(
    //                     map(contacts => new ContactActions.(contacts)),
    //                     catchError(error => of(new ContactActions.({errorMessage: error.toString()})))
    //                 )
    //     )
    // );
    function ContactsEffects(actions$, contactsService) {
        var _this = this;
        this.actions$ = actions$;
        this.contactsService = contactsService;
        this.loadContacts = this.actions$.pipe(ofType(ContactActions.ActionTypes.AuthenticationServiceLoadContacts, ContactActions.ActionTypes.InviteContactsComponentReloadContacts, ContactActions.ActionTypes.AttendeesSummaryComponentReloadContacts, ContactActions.ActionTypes.AttendeesComponentReloadContacts, ContactActions.ActionTypes.PackageDetailsEffectLoadContacts, ContactActions.ActionTypes.ContactComponentReloadContacts, ContactActions.ActionTypes.CreateFromScratchComponentReloadContacts, ContactActions.ActionTypes.SurveyCreationComponentReloadContacts, ContactActions.ActionTypes.GettingStartedComponentReloadContacts, ContactActions.ActionTypes.MessagesCardComponentReloadContacts, ContactActions.ActionTypes.DashboardComponentLoadContacts, ContactActions.ActionTypes.GroupToolsListComponentReloadContacts), concatMap(function (action) { return _this.contactsService.getContactsData().pipe(map(function (contacts) { return new ContactActions.ContactsEffectLoadContactsSuccess({ contacts: contacts }); }), catchError(function (error) { return of(new ContactActions.ContactsEffectLoadContactsFailure({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContactsEffects.prototype, "loadContacts", void 0);
    return ContactsEffects;
}());
export { ContactsEffects };
