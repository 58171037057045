import { createSelector } from "@ngrx/store";
import { cloneDeep as _cloneDeep } from "lodash";
var getContactsState = function (state) { return state.contactsState; };
var ɵ0 = getContactsState;
// =================================================================================
//                                      Selectors
// =================================================================================
export var getContacts = createSelector(getContactsState, function (contactsState) { return contactsState.contacts; });
export var getContactsCopy = createSelector(getContacts, function (contacts) { return _cloneDeep(contacts); } // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
);
export { ɵ0 };
