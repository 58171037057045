import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { DataState } from "./apiData";
import { Attendee } from "./attendee";
import { LeaderSummary } from "./leader-summary";

export interface AttendeesState {
    attendees: AttendeeEntity;
    leaderSummary: LeaderSummary;
}

export interface AttendeeEntity extends EntityState<Attendee> {
    // additional entity state properties
    isInFlight: boolean;
    dataState: DataState;
    errorMessage: string | null;
}

export const attendeeAdapter: EntityAdapter<Attendee> = createEntityAdapter<Attendee>({
    selectId: eventAttendee => eventAttendee.eventAttendeeId,
});

export const attendeeEntitySelectors = attendeeAdapter.getSelectors();
