import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SurveyAddQuestionComponent } from './survey-add-question.component';
import { GTNgaModule } from '../../../../../grouptools-theme/gtnga.module';
import { WidgetsModule } from 'app/widgets/widgets.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GTNgaModule,
        WidgetsModule
    ],
    declarations: [
        SurveyAddQuestionComponent,
    ],
    exports: [
        SurveyAddQuestionComponent
    ]
})
export class SurveyAddQuestionModule { }