export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ActionsComponentLoadActions"] = "[Actions Component] Load Actions";
    ActionTypes["ActionsEffectLoadActions"] = "[Actions Effect] Load Actions";
    ActionTypes["ActionsEffectLoadActionsSuccess"] = "[Actions Effect] Load Actions Success";
    ActionTypes["ActionsEffectLoadActionsFailure"] = "[Actions Effect] Load Actions Failure";
    ActionTypes["SurveyComponentUpdateActions"] = "[Survey Component] Update Actions";
    ActionTypes["AttendeeFormResponseComponentUpdateActions"] = "[Attendee Form Response Component] Update Actions";
    ActionTypes["ActionsEffectUserWasVerified"] = "[Actions Effect] User Was Verified";
    ActionTypes["ActionsComponentMarkAsRead"] = "[Actions Component] Mark As Read";
    ActionTypes["ActionsEffectMarkAsReadSuccess"] = "[Actions Effect] Mark As Read Success";
    ActionTypes["ActionsEffectMarkAsReadFailure"] = "[Actions Effect] Mark As Read Failure";
    ActionTypes["ActionsComponentDismissSurveyActions"] = "[Actions Component] Dismiss Survey Actions";
    ActionTypes["ActionsEffectDismissSurveyActionsSuccess"] = "[Actions Effect] Dismiss Survey Actions Success";
    ActionTypes["ActionsEffectDismissSurveyActionsFailure"] = "[Actions Effect] Dismiss Survey Actions Failure";
    ActionTypes["AuthenticationServiceLogout"] = "[Authentication Service] Logout";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var ActionsComponentLoadActions = /** @class */ (function () {
    function ActionsComponentLoadActions(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsComponentLoadActions;
    } // TODO: can eventId be null for actions endpoint?
    return ActionsComponentLoadActions;
}());
export { ActionsComponentLoadActions };
var ActionsEffectLoadActions = /** @class */ (function () {
    function ActionsEffectLoadActions(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectLoadActions;
    } // TODO: can eventId be null for actions endpoint?
    return ActionsEffectLoadActions;
}());
export { ActionsEffectLoadActions };
var ActionsEffectLoadActionsSuccess = /** @class */ (function () {
    function ActionsEffectLoadActionsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectLoadActionsSuccess;
    }
    return ActionsEffectLoadActionsSuccess;
}());
export { ActionsEffectLoadActionsSuccess };
var ActionsEffectLoadActionsFailure = /** @class */ (function () {
    function ActionsEffectLoadActionsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectLoadActionsFailure;
    }
    return ActionsEffectLoadActionsFailure;
}());
export { ActionsEffectLoadActionsFailure };
//----------------------------------------------------------------
var SurveyComponentUpdateActions = /** @class */ (function () {
    function SurveyComponentUpdateActions() {
        this.type = ActionTypes.SurveyComponentUpdateActions;
    }
    return SurveyComponentUpdateActions;
}());
export { SurveyComponentUpdateActions };
var AttendeeFormResponseComponentUpdateActions = /** @class */ (function () {
    function AttendeeFormResponseComponentUpdateActions() {
        this.type = ActionTypes.AttendeeFormResponseComponentUpdateActions;
    }
    return AttendeeFormResponseComponentUpdateActions;
}());
export { AttendeeFormResponseComponentUpdateActions };
var ActionsEffectUserWasVerified = /** @class */ (function () {
    function ActionsEffectUserWasVerified() {
        this.type = ActionTypes.ActionsEffectUserWasVerified;
    }
    return ActionsEffectUserWasVerified;
}());
export { ActionsEffectUserWasVerified };
//----------------------------------------------------------------
var ActionsComponentMarkAsRead = /** @class */ (function () {
    function ActionsComponentMarkAsRead(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsComponentMarkAsRead;
    } // TODO: can eventId be null for actions endpoint?
    return ActionsComponentMarkAsRead;
}());
export { ActionsComponentMarkAsRead };
var ActionsEffectMarkAsReadSuccess = /** @class */ (function () {
    function ActionsEffectMarkAsReadSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectMarkAsReadSuccess;
    } // TODO: what is return type
    return ActionsEffectMarkAsReadSuccess;
}());
export { ActionsEffectMarkAsReadSuccess };
var ActionsEffectMarkAsReadFailure = /** @class */ (function () {
    function ActionsEffectMarkAsReadFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectMarkAsReadFailure;
    }
    return ActionsEffectMarkAsReadFailure;
}());
export { ActionsEffectMarkAsReadFailure };
//----------------------------------------------------------------
var ActionsComponentDismissSurveyActions = /** @class */ (function () {
    function ActionsComponentDismissSurveyActions(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsComponentDismissSurveyActions;
    } // TODO: can eventId be null for actions endpoint?
    return ActionsComponentDismissSurveyActions;
}());
export { ActionsComponentDismissSurveyActions };
var ActionsEffectDismissSurveyActionsSuccess = /** @class */ (function () {
    function ActionsEffectDismissSurveyActionsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectDismissSurveyActionsSuccess;
    } // TODO: what is return type
    return ActionsEffectDismissSurveyActionsSuccess;
}());
export { ActionsEffectDismissSurveyActionsSuccess };
var ActionsEffectDismissSurveyActionsFailure = /** @class */ (function () {
    function ActionsEffectDismissSurveyActionsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ActionsEffectDismissSurveyActionsFailure;
    }
    return ActionsEffectDismissSurveyActionsFailure;
}());
export { ActionsEffectDismissSurveyActionsFailure };
//----------------------------------------------------------------
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = ActionTypes.AuthenticationServiceLogout;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
