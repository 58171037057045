import * as tslib_1 from "tslib";
import * as modalActions from '../actions/modal.action';
var initialState = {
    title: '',
    componentWithOptions: null,
    size: null,
    isFullScreenMobile: false,
    isStatic: false,
    isOpen: false,
    displayHeader: true
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case modalActions.OPEN_MODAL:
            {
                return Object.assign({}, initialState, tslib_1.__assign({}, action.payload, { isOpen: true }));
            }
        case modalActions.CLOSE_MODAL:
            {
                return initialState;
            }
        case modalActions.SET_MODAL_COMPONENT:
            {
                return Object.assign({}, state, {
                    componentWithOptions: action.payload
                });
            }
        case modalActions.SET_MODAL_SIZE:
            {
                return Object.assign({}, state, {
                    size: action.payload
                });
            }
        case modalActions.SET_MODAL_TITLE:
            {
                return Object.assign({}, state, {
                    title: action.payload
                });
            }
        case modalActions.SET_FULL_SCREEN_MOBILE:
            {
                return Object.assign({}, state, {
                    isFullScreenMobile: action.payload
                });
            }
        default:
            {
                return state;
            }
    }
}
export var getIsOpen = function (state) { return state.isOpen; };
export var getModalTitle = function (state) { return state.title; };
export var getModalSize = function (state) { return state.size; };
export var getIsFullScreenMobile = function (state) { return state.isFullScreenMobile; };
export var getModalComponent = function (state) { return state.componentWithOptions; };
export var getModalIsStatic = function (state) { return state.isStatic; };
export var getModalDisplayHeader = function (state) { return state.displayHeader; };
