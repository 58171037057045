import { Action } from '@ngrx/store';
import { GroupLeaderEventDetailsState } from '../models/event';

export const SET_GLED_STATE = '[GLED] Set Group Leader Event Details State';

export class SetGLEDState implements Action {
    readonly type: string = SET_GLED_STATE;
    constructor(public payload: GroupLeaderEventDetailsState) {}
}

export type Actions = SetGLEDState;
