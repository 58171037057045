import * as tslib_1 from "tslib";
import * as PaymentActions from 'app/store/actions/payment.action';
export var initialState = {
    separatedPayments: {
        AttendeePayments: [],
        SupplierPayments: [],
    },
    paymentsReceivedSummary: { Received: 0, AmountDue: 0, Balance: 0 },
    paymentsSentSummary: { Sent: 0, AmountDue: 0, Balance: 0, PaymentDueDate: null },
    paymentsNew: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsSuccess:
            {
                return tslib_1.__assign({}, state, { separatedPayments: action.payload.separatedPayments });
            }
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsFailure:
            {
                return tslib_1.__assign({}, state, { separatedPayments: {
                        // Make these blank for Payments Component when no payments exist for event
                        AttendeePayments: [],
                        SupplierPayments: [],
                    } });
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsReceivedSummarySuccess:
            {
                return tslib_1.__assign({}, state, { paymentsReceivedSummary: action.payload.paymentsReceivedSummary });
            }
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsReceivedSummaryFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsSentSummarySuccess:
            {
                return tslib_1.__assign({}, state, { paymentsSentSummary: action.payload.paymentsSentSummary });
            }
        case PaymentActions.ActionTypes.PaymentEffectLoadPaymentsSentSummaryFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsNewSuccess:
            {
                return tslib_1.__assign({}, state, { paymentsNew: action.payload.payments });
            }
        case PaymentActions.ActionTypes.PaymentEffectLoadEventPaymentsNewFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.ATEDComponentMakeSupplierPaymentSuccess:
            {
                return tslib_1.__assign({}, state);
            }
        case PaymentActions.ActionTypes.ATEDComponentMakeSupplierPaymentFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.ATEDComponentAddAndAssociateCustomerSuccess:
            {
                return tslib_1.__assign({}, state);
            }
        case PaymentActions.ActionTypes.ATEDComponentAddAndAssociateCustomerFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.GLEDComponentMakeSupplierGLPaymentSuccess:
            {
                return tslib_1.__assign({}, state);
            }
        case PaymentActions.ActionTypes.GLEDComponentMakeSupplierGLPaymentFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //-------------------------------------------------------------------------------------
        case PaymentActions.ActionTypes.AuthenticationServiceLogout:
            {
                return tslib_1.__assign({}, initialState);
            }
        default:
            {
                return state;
            }
        //-------------------------------------------------------------------------------------
    }
}
