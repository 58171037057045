<div *ngIf="!omitTitle" class="login-title">
    <p>Let's get started</p>
</div>
<hr *ngIf="!omitTitle" class="login-hr">
<div class="auth-block">
    <!-- TITLE -->
    <div class="auth-title">
        <div class="register-link-to">
            <div class="col-xs-12 register-link less-padding"> 
                <p class="register-text">Don't have an account? <span id="create-account" class="create-account clickable" (click)="navToRegister()">Sign Up</span></p>
            </div>
            <!-- <div class="col-sm-12 hidden-sm-up">
                <div class="pull-right">
                    <span id="create-account" class="create-account clickable" (click)="navToRegister()">I'd like to register</span>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
            </div> -->
        </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form-horizontal">
        <!-- EMAIL -->
        <div class="form-group row">
            <div class="col-xs-12" [ngClass]="{'has-error': formErrors.email && (email.dirty || formSubmitted) , 'has-success': !formErrors.email && email.valid }">
                <label for="inputEmail3">Email Address</label>
                <input id="inputEmail3" [formControl]="email" type="email" class="form-control">
                <div *ngIf="formErrors.email && formSubmitted" class="error-block">
                <p class="error-text">{{ formErrors.email }}</p>
                </div>
            </div>
        </div>

        <!-- PASSWORD -->
        <div class="form-group row" [ngClass]="{'has-error': formErrors.password && (password.dirty || formSubmitted) , 'has-success': !formErrors.password && password.valid}">
            <div class="col-xs-12">
                <label for="inputPassword3">Password</label>
                <input [formControl]="password" type="password" class="form-control" class="form-control" id="inputPassword3">
                <div *ngIf="formErrors.password && formSubmitted" class="error-block">
                <p class="error-text">{{ formErrors.password }}</p>
                </div>
            </div>
        </div>

        <!-- FORGOT PASSWORD -->
        <div class="form-group row">
            <div class="col-sm-12">
                <a href="javascript:;" (click)="forgotPassword();" class="forgot-pass"><span id="forgot-password" class="create-account">Forgot my password</span></a>
            </div>
        </div>

        <!-- LOGIN BUTTON -->
        <div class="form-group row">
            <div class="col-sm-12 btn-row">
                <button id="login-button" type="submit" class="btn btn-primary form-control">
                    <span class="create-btn-span">Log In to GroupTools</span>
                </button>
            </div>
        </div>
        <div [ngClass]="loginError === false ? 'error-text' : 'hide-text'">
            <span>
                The email address or password you entered is incorrect. If you don't have an account, please <a href="register">sign up.</a>
            </span>
        </div>
    </form>

    <login-with-social 
        (emailPrefill)="emailPrefill"
        (amazonUser)="amazonUser"
        (googleUser)="googleUser"
        (facebookUser)="facebookUser"
        (inModal)="inModal"
        (differentAccount)="differentAccount.emit($event)"
        (googleRegisterEmit)="googleRegisterEmit.emit($event)"
        (amazonRegisterEmit)="amazonRegisterEmit.emit($event)"
        (facebookRegisterEmit)="facebookRegisterEmit.emit($event)"
        (handlePostLogin)="handlePostLogin($event)"
        (openDifferentAccountModal)="openDifferentAccountModal()"
        (openGoogleRegisterModal)="openGoogleRegisterModal($event)"
        (openAmazonRegisterModal)="openAmazonRegisterModal($event)"
        (openFacebookRegisterModal)="openFacebookRegisterModal($event)">
    </login-with-social>
</div>

<!-- MODAL -->
<grouptools-modal #modal 
    [title]="modalTitle"
    (onHidden)="destroyContents()">
    <div modal-body [ngSwitch]="modalState">

        <!-- GROUPTOOLS PLACEHOLDER -->
        <grouptools-placeholder *ngSwitchCase="'placeholder'" 
            id="modalPlaceholder" 
            [isModal]="true" >
        </grouptools-placeholder>

        <!-- PASSWORD RESET SUCCESS -->
        <grouptools-success *ngSwitchCase="'passwordReset'">
            <div class="login-center">
                <span class="row">If you have an account with GroupTools, your password reset instructions will be sent to <b>{{email.value}}</b>. Don't have an account? <a href="register">Please register.</a></span>
            </div>
            <div class="col-lg-12">
                <button class="btn btn-primary login-btn" type="button" (click)="modal.grouptoolsModal.hide()">Return to Login</button>
            </div>
        </grouptools-success>

        <grouptools-success *ngSwitchCase="'differentAccount'"
            successHeading="Warning"
            type="pause">
            <div class="login-center">
                <span class="row">
                    You are about to login to GroupTools with a different email address. 
                    If you continue, you will not have access to the events associated with 
                    <b>{{emailPrefill.value}}</b>
                </span>
            </div>
            <div class="col-lg-12">
                <button class="btn btn-primary login-btn" type="button" (click)="modal.grouptoolsModal.hide()">Continue</button>
            </div>
            <div class="col-lg-12">
                <button class="btn btn-secondary login-btn" type="button" (click)="cancelLogin()">Cancel</button>
            </div>
        </grouptools-success>

        <google-register *ngSwitchCase="'googleRegister'" 
            [googleUser]="googleUser"
            (registerComplete)="handlePostLogin()"
            >
        </google-register>

        <amazon-register *ngSwitchCase="'amazonRegister'" 
            [user]="amazonUser">
        </amazon-register>

        <facebook-register *ngSwitchCase="'facebookRegister'"
            [user]="facebookUser">
        </facebook-register>
    </div>
</grouptools-modal>
