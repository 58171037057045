import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { URLConfig, ClientAuthConfig } from '../helpers/config.service';


import { HttpClient } from '@angular/common/http';

@Injectable()
export class SurveyInviteService {
    constructor(private http: HttpClient,
                private URLs: URLConfig,
                private ClientAuth: ClientAuthConfig) {}

    GetByEmailToken(token: String): Observable<any> {
        let p: Object = {'emailToken': token};
        return this.http.post(this.URLs._surveyGetByInvite(), p);
    }

}
