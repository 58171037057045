import { URLConfig, ClientAuthConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
var SurveyInviteService = /** @class */ (function () {
    function SurveyInviteService(http, URLs, ClientAuth) {
        this.http = http;
        this.URLs = URLs;
        this.ClientAuth = ClientAuth;
    }
    SurveyInviteService.prototype.GetByEmailToken = function (token) {
        var p = { 'emailToken': token };
        return this.http.post(this.URLs._surveyGetByInvite(), p);
    };
    return SurveyInviteService;
}());
export { SurveyInviteService };
