<label class="gt-label"
    [ngClass]="{'full-width': pullRight}"
    #pop="bs-popover"
    [for]="for"
    [popover]="helperText"
     outsideClick="true">
    <ng-content></ng-content>
    <span class="gt-label-span" [ngClass]="{'pull-right': pullRight}" *ngIf="helperText && !isQuestion">
        <i class="fa fa-info-circle" aria-hidden="true" (mouseenter)="pop.show()" (mouseleave)="pop.hide()" (click)="pop.toggle()"></i>
    </span>
    <span class="gt-label-span" *ngIf="helperText && isQuestion" (mouseenter)="pop.show()" (mouseleave)="pop.hide()" (click)="pop.toggle()">
        <i class="fa fa-question-circle question-circle" aria-hidden="true"></i>
    </span>
</label>