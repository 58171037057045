import { QuestionBase } from "app/pages/surveys/survey/components/survey-body/questions/question-bases/question-base";

export class RadioQuestion extends QuestionBase<string> {
  questionType = 'radio';
  questionChoices: {choiceID: number, order: number, value: string}[] = [];
  otherAdded: boolean;


  constructor(options: {} = {}) {
    super(options);
    this.questionChoices = options['questionChoices'] || [];
    this.otherAdded = options['otherAdded'] || false;
  }
}