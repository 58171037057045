<div class="container-fluid page-top clearfix" maxHeight="50">
    <div class="row">
        <div class="col-sm-5 col-xs-6">
            <div class="brand pull-sm-left">
                <img [src]="LOGO" class="gt-logo img-fluid pull-left clickable" alt="GT" href="/" />
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="col-sm-7 col-xs-6">
            <div class="user-profile pull-xs-right">
                <a class="dropdown-toggle help-menu-toggle" href="https://support.grouptools.com/hc/en-us">Help</a>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<div class="al-content container">
    <div class="row logo-row">
        <div class="col-sm-12 logo-col">
            <div class="logo-container">
                <img [src]="errorImage">
            </div>
        </div>
    </div>
    <div class="row heading-row">
        <div class="col-sm-12 heading-col">
            <div class="heading-container">
                <p class="header-title">Yikes</p>
                <p class="header-error">Unfortunately, this browser is not supported.</p>
                <p class="header-error"><a href="https://www.google.com/chrome/">Click here</a> to download Google Chrome.</p>
            </div>
        </div>
    </div>
    <div class="row content-row">
        <div class="col-sm-12 content-col">
            <div class="content-container">
                <span>If you continue to receive this message, please contact our <span class="support-team">support team</span>.</span>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="container">
    <div class="row">
        <div class="col-xs-12">
        <p>
            &copy; {{currentYear}} GroupTools, LLC. All rights reserved. <br/><a href="https://grouptools.com/privacy-policy" target="_blank">Privacy Policy</a> | <a href="https://grouptools.com/terms-of-service" target="_blank">Terms of Service</a>
        </p>
        </div>
    </div>
    </div>
</div>
