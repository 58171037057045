import { Component, OnInit } from '@angular/core';
import * as fromRoot from 'app/store/reducers';
import { Store } from '@ngrx/store';
import { ModalSize, Modal } from 'app/store/models/modal';
import * as modalActions from 'app/store/actions/modal.action';
import { AddActivityLodgingModalConfig } from './gled-add-activity-lodging/gled-add-activity-lodging.component';
import { AddActivityOtherModalConfig } from './gled-add-activity-other/gled-add-activity-other.component';
import { AddActivityRestaurantModalConfig } from './gled-add-activity-restaurant/gled-add-activity-restaurant.component';
import { AddActivityShowModalConfig } from './gled-add-activity-show/gled-add-activity-show.component';
import { AddActivityTransportationModalConfig } from './gled-add-activity-transportation/gled-add-activity-transportation.component';


enum GledAddActivityState {
  ChooseActivityType = 'ChooseActivityType',
  Show = 'Show',
  Restaurant = 'Restaurant',
  Lodging = 'Lodging',
  Transportation = 'Transportation',
  Other = 'Other'
}


@Component({
  selector: 'gled-add-activity',
  templateUrl: './gled-add-activity.component.html',
  styleUrls: ['./gled-add-activity.component.scss']
})
export class GledAddActivityComponent implements OnInit {

  enumGledAddActivityState = GledAddActivityState; // This is so we can use the enum type in the template

  public state: GledAddActivityState = GledAddActivityState.ChooseActivityType;

  constructor(
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
  }

  openAddActivityModal(modalType: GledAddActivityState): void {
    switch (modalType) {
      case GledAddActivityState.Lodging:
        this.store.dispatch(new modalActions.OpenModal(<Modal>{ ...AddActivityLodgingModalConfig }));
        break;
      case GledAddActivityState.Other:
        this.store.dispatch(new modalActions.OpenModal(<Modal>{ ...AddActivityOtherModalConfig }));
        break;
      case GledAddActivityState.Restaurant:
        this.store.dispatch(new modalActions.OpenModal(<Modal>{ ...AddActivityRestaurantModalConfig }));
        break;
      case GledAddActivityState.Show:
        this.store.dispatch(new modalActions.OpenModal(<Modal>{ ...AddActivityShowModalConfig }));
        break;
      case GledAddActivityState.Transportation:
        this.store.dispatch(new modalActions.OpenModal(<Modal>{ ...AddActivityTransportationModalConfig }));
        break;
      default:
        alert('test test');
        // TODO: maybe notify user it was an invalid choice?  Or there was an error?  Maybe an apologetic error modal?
        break;
    }
  }

}


export const AddActivityModalConfig: Modal = {
  title: 'Choose Activity Type',
  size: ModalSize.Medium,
  isFullScreenMobile: true,
  componentWithOptions: {
    component: GledAddActivityComponent
  }
};