import { Component, Input } from '@angular/core';

@Component({
    selector: 'grouptools-label',
    templateUrl: './grouptools-label.component.html',
    styleUrls: ['./grouptools-label.component.scss']
})
export class GrouptoolsLabelComponent {
    @Input() for: string;
    @Input() helperText: string;
    @Input() isQuestion: boolean = false;
    @Input() pullRight: boolean = true;

};
