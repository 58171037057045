import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { EventDetailsService } from "app/pages/package-details/event-details.service";
import { of } from "rxjs";
import { catchError, exhaustMap, map, switchMap } from "rxjs/operators";
import * as fromEventItem from '../actions/gledEventItem.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
var GledEventItemEffects = /** @class */ (function () {
    function GledEventItemEffects(actions$, eventService) {
        var _this = this;
        this.actions$ = actions$;
        this.eventService = eventService;
        this.loadEventItems = this.actions$
            .pipe(ofType(fromEventItem.LOAD_EVENT_ITEMS), switchMap(function (action) { return _this.eventService.getEventItems(action.eventId, action.activityId, action.optionId)
            .pipe(map(function (eventItems) { return new fromEventItem.LoadEventItemsOk(eventItems); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        // @Effect()
        // assignAttendeesToItems = this.actions$
        //     .pipe(
        //         ofType<fromEventItem.AssignAttendeesToItems>(fromEventItem.ASSIGN_ATTENDEES_TO_ITEMS),
        //         exhaustMap(action => this.eventService.assignAttendeesToItems(action.eventId, action.eventItems)
        //                     .pipe(
        //                         map(eventItems => new fromEventItem.AssignAttendeesToItemsOk(eventItems)),
        //                         catchError(error => of(new fromEventItem.AssignAttendeesToItemsError({errorMessage: error.toString()})))
        //                     )
        //         )
        //     );
        this.assignAttendeesToItemsAutomatically = this.actions$
            .pipe(ofType(fromEventItem.ASSIGN_ATTENDEES_TO_ITEMS_AUTOMATICALLY), exhaustMap(function (action) { return _this.eventService.assignAttendeesToItemsAutomatically(action.eventId)
            .pipe(map(function (eventItems) { return new fromEventItem.AssignAttendeesToItemsAutomaticallyOk(eventItems); }), catchError(function (error) { return of(new fromEventItem.AssignAttendeesToItemsAutomaticallyError({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], GledEventItemEffects.prototype, "loadEventItems", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], GledEventItemEffects.prototype, "assignAttendeesToItemsAutomatically", void 0);
    return GledEventItemEffects;
}());
export { GledEventItemEffects };
