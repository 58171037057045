export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
  
  export function toString(value: any): string {
    return (value !== undefined && value !== null) ? `${value}` : '';
  }
  
  export function getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
  }
  
  export function isString(value: any): value is string {
    return typeof value === 'string';
  }
  
  export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
  }
  
  export function isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }
  
  export function isDefined(value: any): boolean {
    return value !== undefined && value !== null;
  }
  
  export function padNumber(value: number) {
    if (isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
  
  export function regExpEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  export function shallowCopy<T>(obj: T) : T {
    return { ...obj }
  }

// Because this utility is used in critical functions, I have made it as error proof as possible 
// It should almost always return an array, even if its an empty array
// One feature is each array overwrites any previous array's elements that match the getId function
export function mergeArrays<T>(getId: (t:T) => number, ...arrays: T[][]): T[] {
  if (!Array.isArray(arrays)) return [];
  const map = new Map<number, T>();
  for (const array of arrays) {
    if (!Array.isArray(array)) continue;
    for (const element of array) {
      if (element === null || element === undefined) continue;
      const possibleNumber = getId(element);
      if (typeof possibleNumber !== 'number') continue;
      map.set(possibleNumber, element);
    }
  }
  return Array.from(map.values());
}

// This returns a string of the name of the given property on the given type T
// yes you have to already pass in the string of the property, so you already have it, why not just use it directly?
// Because the benefit to using this is that it will generate a compile time error if the property string doesn't exist on the given type
// I plan on using this to add compile safe typing for the clOptions input passed to the grouptools-list component
export const nameof = <T>(name: keyof T) => name;