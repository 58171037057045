import * as tslib_1 from "tslib";
import { createSelector } from "@ngrx/store";
import { cloneDeep as _cloneDeep } from "lodash";
import { createOldAttendeeFromAttendee } from "../models/attendee";
import { attendeeEntitySelectors } from "../models/attendees.state";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
var getAttendeesState = function (state) { return state.attendeesState; };
var ɵ0 = getAttendeesState;
var ɵ1 = function (attendeesState) { return attendeesState.attendees; };
// =================================================================================
//                                      Selectors
// =================================================================================
//-----------------------------------------------------------
var getAttendeesEntity = createSelector(getAttendeesState, ɵ1);
//-----------------------------------------------------------
export var getAttendees = createSelector(getAttendeesEntity, function (attendeesEntity) { return attendeeEntitySelectors.selectAll(attendeesEntity); });
//-----------------------------------------------------------
export var getAttendeesSortedNewest = createSelector(getAttendees, function (attendees) {
    return tslib_1.__spread(attendees).sort(function (a, b) {
        if (a.eventAttendeeId > b.eventAttendeeId)
            return -1;
        if (a.eventAttendeeId < b.eventAttendeeId)
            return 1;
        return 0;
    });
});
//-----------------------------------------------------------
export var getAttendeesCopy = createSelector(getAttendees, function (attendees) { return _cloneDeep(attendees); });
//-----------------------------------------------------------
export var getAttendeesAsOld = createSelector(getAttendees, function (attendees) { return attendees.map(createOldAttendeeFromAttendee); });
//-----------------------------------------------------------
export var getAttendeesAsAttendeeUpserts = createSelector(getAttendeesSortedNewest, function (attendees) { return attendees.map(AttendeeUpsertRequest.createFromAttendee); });
//-----------------------------------------------------------
export var getAttendesApiState = createSelector(getAttendeesEntity, function (attendeesEntity) {
    return {
        dataState: attendeesEntity.dataState,
        isInFlight: attendeesEntity.isInFlight,
    };
});
//-----------------------------------------------------------
export var getLeaderSummary = createSelector(getAttendeesState, function (attendeesState) { return attendeesState.leaderSummary; });
export { ɵ0, ɵ1 };
//-----------------------------------------------------------
// =================================================================================
//                                      End
// =================================================================================
