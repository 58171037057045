<style>
    .collapsed i::before {
        content: "\F123";
    }
    .not-clickable {
        cursor: default;
    }
</style>
<div class="animated card {{cardType}} {{baCardClass || ''}}" zoom-in>
    <div *ngIf="title" class="card-header clearfix" style="height: auto;">
        <a class="card-collapse-toggle pull-left" (click)="toggleShowBody();" [class.not-clickable]="!collapsible">
            <i [hidden]="!collapsible" class="fa" [ngClass]="{'fa-chevron-up':(gtCardShow),'fa-chevron-down':(!gtCardShow)}"></i>
            <span *ngIf="showTitleString" [class]="titleClass" [ngStyle]="titleStyle">{{title}}</span>
            <ng-content select="[title-text]"></ng-content>
        </a>
        <ng-content select="[card-header]"></ng-content>
    </div>

    <div [hidden]="collapsible&&!gtCardShow" class="card-body">
        <ng-content></ng-content>
    </div>
</div>