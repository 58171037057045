import { createSelector } from "@ngrx/store";
import { AppState } from "app/store/models/app-state";
import { cloneDeep as _cloneDeep } from "lodash";

const getPackageDetailsState = (state: AppState) => state.packageDetailsState;

// =================================================================================
//                                      Selectors
// =================================================================================

export const getPackageDetails = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.packageDetails
);

export const getSelectedPackageId = createSelector(
    getPackageDetails,
    packageDetails => packageDetails.IdPackage as number
);

export const getTagInfo = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.tagInfo
);

export const getPackageComponents = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.components
);

export const getPackageRecord = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.packageRecord
);

export const getPaymentsReceivedSummary = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.paymentsReceivedSummary
);

export const getPaymentsSentSummary = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.paymentsSentSummary
);

export const getSuppliers = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.allSuppliers
);

export const getTickets = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.tickets
);

export const isBiEnabled = createSelector(
    getSuppliers,
    suppliers => {
        for (const supplier of suppliers) {
            if (supplier.SupplierCode.toLocaleUpperCase() === 'BI')
                return true;
        }
        return false;
    }
);

export const getPackageLogs = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.packageLogs
);

export const getPackageSuppliers = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.packageSuppliersByActivity
);

export const getBibOrderNumberCreateEventApiState = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.bibOrderNumberCreateEventApiState.apiState
);

export const getSupplierOrderNumberCreateEventApiState = createSelector(
    getPackageDetailsState,
    packageDetailsState => packageDetailsState.supplierOrderNumberCreateEventApiState.apiState
);

// =================================================================================
//                                      End
// =================================================================================
