import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as loaderActions from 'app/store/actions/loader.action';
import * as fromRoot from 'app/store/reducers';

export interface LoaderState {
  show: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GroupToolsLoaderService {
 
  constructor(private store: Store<fromRoot.State>) { }
  
  show() {
    this.store.dispatch(new loaderActions.ShowLoader);
  }
  hide() {
    this.store.dispatch(new loaderActions.HideLoader);
  }
}
