import * as tslib_1 from "tslib";
import { SurveysService } from 'app/pages/surveys/surveys.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SurveysActions from "app/store/actions/surveys.action";
import { AppStateService } from 'app/pages/helpers/appState.service';
var SurveysEffects = /** @class */ (function () {
    function SurveysEffects(actions$, surveyService, appStateService) {
        var _this = this;
        this.actions$ = actions$;
        this.surveyService = surveyService;
        this.appStateService = appStateService;
        this.loadEventSurveys = this.actions$.pipe(ofType(SurveysActions.ActionTypes.GLEDLoadEventSurveys, SurveysActions.ActionTypes.AttendeePackageDetailsComponentLoadEventSurveys, SurveysActions.ActionTypes.AttendeeSurveyViewComponentLoadEventSurveys, SurveysActions.ActionTypes.PackageDetailsEffectLoadEventSurveys, SurveysActions.ActionTypes.SurveyListComponentLoadEventSurveys), concatMap(function (action) { return _this.surveyService.getSurveys(action.payload.eventId).pipe(map(function (eventSurveys) { return new SurveysActions.SurveysEffectLoadEventSurveysSuccess({ eventSurveys: eventSurveys }); }), catchError(function (error) { return of(new SurveysActions.SurveysEffectLoadEventSurveysFailure({ errorMessage: error.toString() })); })); }));
        this.loadSurveyDetails = this.actions$.pipe(ofType(SurveysActions.ActionTypes.AttendeePackageDetailsComponentLoadSurveyDetails, SurveysActions.ActionTypes.ApdViewComponentLoadSurveyDetails, SurveysActions.ActionTypes.AttendeeSurveyListComponentLoadSurveyDetails, SurveysActions.ActionTypes.SurveyComponentLoadSurveyDetails), concatMap(function (action) { return _this.surveyService.getSurveyDetails(action.payload.surveyId).pipe(map(function (survey) { return new SurveysActions.SurveysEffectLoadSurveyDetailsSuccess({ survey: survey }); }), catchError(function (error) { return of(new SurveysActions.SurveysEffectLoadSurveyDetailsFailure({ errorMessage: error.toString() })); })); }));
        this.loadCreatedSurveys = this.actions$.pipe(ofType(SurveysActions.ActionTypes.DashboardComponentLoadCreatedSurveys, SurveysActions.ActionTypes.GroupLeaderDashboardComponentLoadCreatedSurveys, SurveysActions.ActionTypes.SurveyDashboardComponentLoadCreatedSurveys), concatMap(function (action) { return _this.appStateService.getSurveyEvents().pipe(map(function (createdSurveys) { return new SurveysActions.SurveysEffectLoadCreatedSurveysSuccess({ createdSurveys: createdSurveys }); }), catchError(function (error) { return of(new SurveysActions.SurveysEffectLoadCreatedSurveysFailure({ errorMessage: error.toString() })); })); }));
        this.loadInvitedSurveys = this.actions$.pipe(ofType(SurveysActions.ActionTypes.DashboardComponentLoadInvitedSurveys, SurveysActions.ActionTypes.SurveyAttendeeComponentLoadInvitedSurveys), concatMap(function (action) { return _this.appStateService.getSurveyAttendees().pipe(map(function (invitedSurveys) { return new SurveysActions.SurveysEffectLoadInvitedSurveysSuccess({ invitedSurveys: invitedSurveys }); }), catchError(function (error) { return of(new SurveysActions.SurveysEffectLoadInvitedSurveysFailure({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SurveysEffects.prototype, "loadEventSurveys", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SurveysEffects.prototype, "loadSurveyDetails", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SurveysEffects.prototype, "loadCreatedSurveys", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SurveysEffects.prototype, "loadInvitedSurveys", void 0);
    return SurveysEffects;
}());
export { SurveysEffects };
