import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';




import { TagInputAutocompleteComponent } from './components/tag-input-autocomplete/tag-input-autocomplete.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TagInputItemComponent } from './components/tag-input-item/tag-input-item.component';
import { TagSelectComponent } from './components/tag-select-item/tag-select-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    TagInputAutocompleteComponent,
    TagInputComponent,
    TagInputItemComponent,
    TagSelectComponent
  ],
  exports: [
    TagInputComponent,
    TagSelectComponent
  ]
})
export class RlTagInputModule {}
