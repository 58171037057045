import { Injectable } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
import { SupplierMakePaymentRequest } from 'app/store/models/requests/av-make-payment-request';

@Injectable()
export class CurrencyHelper {

  constructor() {}

  public getLocaleFromCurrencyAbbreviation(currencyAbbreviation: string) {
    switch (currencyAbbreviation)
    {
        case "USD":
            return "en-US";
        case "GBP":
            return "en-GBP";
        case "EUR":
            return "fr-FR";
        default:
            return "en-US";
    }
  }
}

