import { AbstractControl, Validator, ValidatorFn } from '@angular/forms';
var BlacklistStringsValidator = /** @class */ (function () {
    function BlacklistStringsValidator() {
    }
    BlacklistStringsValidator.prototype.validate = function (control) {
        return blacklistStringValidator(this.blacklist);
    };
    BlacklistStringsValidator.prototype.setBlacklist = function (list) {
        this.blacklist = list;
    };
    return BlacklistStringsValidator;
}());
export { BlacklistStringsValidator };
export function blacklistStringValidator(blacklist) {
    return function (control) {
        return blacklist.indexOf(control.value) === -1 ? null :
            { 'blacklistedString': { value: control.value } };
    };
}
