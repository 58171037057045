import { OnInit } from '@angular/core';
var LoadingSpinnerComponent = /** @class */ (function () {
    function LoadingSpinnerComponent() {
        this.cssWidth = '400px';
        this.cssHeight = '500px';
        this.cssBackgroundColor = '';
        this.text = 'Loading';
        this.opacity = 1;
        this.cssMarginTop = '40px'; // Only used when verticalPlacement == 'top'
        this.verticalPlacement = 'center';
        this.maxWidth = '100%';
        this.position = 'absolute';
        this.zIndex = '10';
    }
    Object.defineProperty(LoadingSpinnerComponent.prototype, "shouldCenterSpinnerVertically", {
        get: function () {
            return this.verticalPlacement === 'center';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoadingSpinnerComponent.prototype, "shouldTopAlignSpinnerVertically", {
        get: function () {
            return this.verticalPlacement === 'top';
        },
        enumerable: true,
        configurable: true
    });
    LoadingSpinnerComponent.prototype.ngOnInit = function () {
    };
    return LoadingSpinnerComponent;
}());
export { LoadingSpinnerComponent };
