export class QuestionBase<T>{
    response: T;
    questionId: number;
    questionName: string;
    questionType: string;
    questionOrder: number;
    required: boolean;
   
    constructor(options: {
        response?: T,
        questionId?: number,
        questionName?: string,
        questionType?: string,
        questionOrder?: number,
        required?: boolean
      } = {}) {

        this.response = options.response;
        this.questionId = options.questionId;
        this.questionName = options.questionName || '';
        this.questionType = options.questionType || '';
        this.questionOrder = options.questionOrder || 0;
        this.required = options.required || false;
      }
  }