import * as eventActions from '../actions/event.action';
import { TransferredEvent } from '../models/transferred-event';

export type State = TransferredEvent[];

// initialize state to an empty array
export function reducer(state: State = [], action: eventActions.Actions): State {
    switch (action.type) {
        case eventActions.LOAD_TRANSFERRED_EVENTS_OK:
        {
            return action.payload;
        }
        default:
        {
            return state;
        }
    }
}


