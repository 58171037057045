import { OnInit, ViewContainerRef, ComponentFactoryResolver, OnDestroy, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { ModalSize } from 'app/store/models/modal';
import { takeUntil } from 'rxjs/operators';
import { CloseModal } from 'app/store/actions/modal.action';
var GroupToolsNewModalComponent = /** @class */ (function () {
    function GroupToolsNewModalComponent(_componentFactoryResolver, _store, _element) {
        this._componentFactoryResolver = _componentFactoryResolver;
        this._store = _store;
        this._element = _element;
        this._unsubscribe$ = new Subject();
        this._component$ = this._store.select(fromRoot.getModalComponent);
        this._size$ = this._store.select(fromRoot.getModalSize);
        this._title$ = this._store.select(fromRoot.getModalTitle);
        this._isStatic$ = this._store.select(fromRoot.getModalIsStatic);
        this._displayHeader$ = this._store.select(fromRoot.getModalDisplayHeader);
        this._isFullScreenMobile$ = this._store.select(fromRoot.getIsFullScreenMobile);
    }
    GroupToolsNewModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._component$.pipe(takeUntil(this._unsubscribe$)).subscribe(function (componentWithOptions) {
            _this._loadComponent(componentWithOptions);
        });
        this._size$.pipe(takeUntil(this._unsubscribe$)).subscribe(function (size) {
            _this._setSize(size);
        });
        this._title$.pipe(takeUntil(this._unsubscribe$)).subscribe(function (title) {
            _this.title = title;
        });
        this._isStatic$.pipe(takeUntil(this._unsubscribe$)).subscribe(function (isStatic) {
            _this.isStatic = isStatic;
        });
        this._displayHeader$.pipe(takeUntil(this._unsubscribe$)).subscribe(function (displayHeader) {
            _this.displayHeader = displayHeader;
        });
        this._isFullScreenMobile$.pipe(takeUntil(this._unsubscribe$)).subscribe(function (isFullScreenMobile) {
            _this._toggleFullScreenMobile(isFullScreenMobile);
        });
    };
    GroupToolsNewModalComponent.prototype.close = function () {
        this._store.dispatch(new CloseModal());
    };
    //https://stackoverflow.com/questions/48725651/dynamically-load-component-in-div-angular5
    GroupToolsNewModalComponent.prototype._loadComponent = function (componentWithOptions) {
        if (!!componentWithOptions) {
            this.container.clear();
            var factory = this._componentFactoryResolver.resolveComponentFactory(componentWithOptions.component);
            var componentRef_1 = this.container.createComponent(factory);
            if (!!componentWithOptions.options) {
                Object.keys(componentWithOptions.options).forEach(function (element) {
                    // if(componentRef.instance.hasOwnProperty(element)) {
                    componentRef_1.instance[element] = componentWithOptions.options[element];
                    // }
                });
            }
            componentRef_1.changeDetectorRef.detectChanges();
        }
    };
    //TODO: NATIVE APP: REFACTOR:
    //If we ever turn this into a native app, we need to revisit both _setSize and _toggleFullScreenMobile.
    //We will not inherently have access to the DOM and will need a new way to accomplish this on-demand.
    GroupToolsNewModalComponent.prototype._setSize = function (modalSize) {
        var modalDialog = this._element.nativeElement.closest('.modal-dialog');
        if (modalDialog) {
            modalDialog.classList.remove(ModalSize.Small.toString(), ModalSize.Medium.toString(), ModalSize.Large.toString());
            modalDialog.classList.add(modalSize.toString());
        }
    };
    GroupToolsNewModalComponent.prototype._toggleFullScreenMobile = function (isFullScreenMobile) {
        var modalDialog = this._element.nativeElement.closest('.modal-dialog');
        var modal = this._element.nativeElement.closest('.modal');
        if (modalDialog && modal) {
            modal.classList.remove('auto');
            modalDialog.classList.remove('auto');
            if (isFullScreenMobile) {
                modal.classList.add('auto');
                modalDialog.classList.add('auto');
            }
        }
    };
    GroupToolsNewModalComponent.prototype.ngOnDestroy = function () {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    };
    return GroupToolsNewModalComponent;
}());
export { GroupToolsNewModalComponent };
