import { Action } from "@ngrx/store";
import { Contact } from "../models/contacts";

export enum ActionTypes {
    AuthenticationServiceLoadContacts = "[Authentication Service] Load Contacts", // TODO: Finish name
    ContactsEffectLoadContactsSuccess = "[Contacts Effect] Load Contacts Success",
    ContactsEffectLoadContactsFailure = "[Contacts Effect] Load Contacts Failure",

    InviteContactsComponentReloadContacts = "[Invite Contacts Componenet] Reload Contacts",
    AttendeesSummaryComponentReloadContacts = "[Attendees Summary Componenet] Reload Contacts",
    AttendeesComponentReloadContacts = "[Attendees Componenet] Reload Contacts",
    PackageDetailsEffectLoadContacts = "[Package Details Effect] Load Contacts",
    ContactComponentReloadContacts = "[Contact Component] Reload Contacts",
    CreateFromScratchComponentReloadContacts = "[Create From Scratch Component] Reload Contacts",
    SurveyCreationComponentReloadContacts = "[Survey Creation Component] Reload Contacts",
    GettingStartedComponentReloadContacts = "[Getting Started Component] Reload Contacts",
    MessagesCardComponentReloadContacts = "[Messages Card Component] Reload Contacts",
    DashboardComponentLoadContacts = "[Dashboard Component] Load Contacts",
    GroupToolsListComponentReloadContacts = "[GroupTools List Component] Reload Contacts",

    AuthenticationServiceLogout = "[Authentication Service] Logout", // TODO: Move into component based action file?
}

//----------------------------------------------------------------
export class AuthenticationServiceLoadContacts implements Action {
    readonly type = ActionTypes.AuthenticationServiceLoadContacts;
    constructor() {}
}
export class ContactsEffectLoadContactsSuccess implements Action {
    readonly type = ActionTypes.ContactsEffectLoadContactsSuccess;
    constructor(public payload: {contacts: Contact[]}) {} // TODO: can eventId be null for actions endpoint?
}
export class ContactsEffectLoadContactsFailure implements Action {
    readonly type = ActionTypes.ContactsEffectLoadContactsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class InviteContactsComponentReloadContacts implements Action {
    readonly type = ActionTypes.InviteContactsComponentReloadContacts;
    constructor() {}
}
export class AttendeesSummaryComponentReloadContacts implements Action {
    readonly type = ActionTypes.AttendeesSummaryComponentReloadContacts;
    constructor() {}
}
export class AttendeesComponentReloadContacts implements Action {
    readonly type = ActionTypes.AttendeesComponentReloadContacts;
    constructor() {}
}
export class PackageDetailsEffectLoadContacts implements Action {
    readonly type = ActionTypes.PackageDetailsEffectLoadContacts;
    constructor() {}
}
export class ContactComponentReloadContacts implements Action {
    readonly type = ActionTypes.ContactComponentReloadContacts;
    constructor() {}
}
export class CreateFromScratchComponentReloadContacts implements Action {
    readonly type = ActionTypes.CreateFromScratchComponentReloadContacts;
    constructor() {}
}
export class SurveyCreationComponentReloadContacts implements Action {
    readonly type = ActionTypes.SurveyCreationComponentReloadContacts;
    constructor() {}
}
export class GettingStartedComponentReloadContacts implements Action {
    readonly type = ActionTypes.GettingStartedComponentReloadContacts;
    constructor() {}
}
export class MessagesCardComponentReloadContacts implements Action {
    readonly type = ActionTypes.MessagesCardComponentReloadContacts;
    constructor() {}
}
export class DashboardComponentLoadContacts implements Action {
    readonly type = ActionTypes.DashboardComponentLoadContacts;
    constructor() {}
}
export class GroupToolsListComponentReloadContacts implements Action {
    readonly type = ActionTypes.GroupToolsListComponentReloadContacts;
    constructor() {}
}
//----------------------------------------------------------------
export class AuthenticationServiceLogout implements Action {
    readonly type = ActionTypes.AuthenticationServiceLogout;
    constructor() {}
}
//----------------------------------------------------------------

export type Actions
    = AuthenticationServiceLoadContacts
    | ContactsEffectLoadContactsSuccess
    | ContactsEffectLoadContactsFailure

    | InviteContactsComponentReloadContacts
    | AttendeesSummaryComponentReloadContacts
    | AttendeesComponentReloadContacts
    | PackageDetailsEffectLoadContacts
    | ContactComponentReloadContacts
    | CreateFromScratchComponentReloadContacts
    | SurveyCreationComponentReloadContacts
    | GettingStartedComponentReloadContacts
    | MessagesCardComponentReloadContacts
    | DashboardComponentLoadContacts
    | GroupToolsListComponentReloadContacts

    | AuthenticationServiceLogout
;
