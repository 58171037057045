export interface GledEventItemState { 
    items: EventItem[];
    assignmentsAutosaveState: AutosaveState;
    enteredOrderNumber: number;
    selectedShow: string;
    selectedPerformance: string;
    wasShowPerformanceOrderNumberCorrect: boolean;
}

export interface EventItem {
    id: number,
    eventId: number,
    activityId: number | null, // could be null
    optionId: number | null, // could be null
    groupingName: string,
    name: string,
    infoJson: string | null, // could be null
    itemType: EventItemType, // typescript string enum variables are treated as strings during runtime
    eventAttendeeId: number | null, // could be null
    status: EventItemStatus // typescript string enum variables are treated as strings during runtime
    favorability: number | null, // could be null
}

export function getStatusDisplayText(eventItem: EventItem): string {
    if (!eventItem) return '';
    if (eventItem.eventAttendeeId < 1) return '';
    
    switch (eventItem.status) {
        case EventItemStatus.Initial:
        {
            return '';
        }
        case EventItemStatus.Delivered:
        {
            return EventItemStatus.Delivered;
        }
        case EventItemStatus.Received:
        {
            return EventItemStatus.Received;
        }
        case EventItemStatus.Printed:
        {
            return EventItemStatus.Printed;
        }
        default:
        {
            return '';
        }
    }
}

export type EventItemSortDirection = 'databaseOrder' | 'groupAndNameAsc' | 'groupAndNameDesc';

export function compareEventItems(a: EventItem, b: EventItem, sortDirection: EventItemSortDirection): number {
    switch (sortDirection) {
        default:
        case 'databaseOrder':
        {
            if (!a && !b) return 0;
            if (!a) return -1;
            if (!b) return 1;
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        }
        case 'groupAndNameAsc':
        {
            if (!a && !b) return 0;
            if (!a) return -1;
            if (!b) return 1;
            const combinedNameA = a.groupingName + a.name;
            const combinedNameB = b.groupingName + b.name;
            return combinedNameA.localeCompare(combinedNameB);
        }
        case 'groupAndNameDesc':
        {
            if (!a && !b) return 0;
            if (!a) return 1;
            if (!b) return -1;
            const combinedNameA = a.groupingName + a.name;
            const combinedNameB = b.groupingName + b.name;
            return combinedNameB.localeCompare(combinedNameA);
        }
    }
}

export function getGroupingDisplayName(eventItem: EventItem): string {
    if (!eventItem.groupingName) return "";
    
    var test = eventItem.groupingName.split('|'); // TODO: This was a hack before a presentation: Remove and implement correctly (by splitting title into section and row? or by including Sec here as well)
    if (test.length < 1) return "";
    if (test.length < 2) return test[0];
    let final = test[0] + ", Row " + test[test.length-1];
    return final;
    
    // This uses regexp to replace all pipes (|) with a space,
    // It has the added benefit of replacing multiple consecutive pipes with only one space
    return eventItem.groupingName.replace(/\|/g, " ");

}

// See FormDropDownLookup table for definition of these enum values:

export enum EventItemType {
    ETicket = "ETicket"
}

export enum EventItemStatus {
    Initial = "Initial",
    Delivered = "Delivered",
    Received = "Received",
    Printed = "Printed",
}

// TODO: This could be moved to a more general place, as it could be reused anywhere autosaving happens
//       Maybe create a separate file for it?
export enum AutosaveState {
    Initial = "Initial",
    Loading = "Loading",
    Success = "Success",
    Error = "Error"
}
