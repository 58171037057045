<div bsModal #grouptoolsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config] = "config" (onHidden)="onHidden.emit()" [ngClass]="auto">
  <div class="modal-dialog" [ngClass]="styles">
    <div class="modal-content">
      
      <!-- Modal Header -->
      <div *ngIf="showHeader" class="modal-header">
        <button *ngIf="config.backdrop !== 'static'" type="button" class="close pull-right" (click)="grouptoolsModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title text-center">{{title}}</h4>
      </div>

      <!-- Modal HR -->
      <hr class="gt-modal-hr" *ngIf="showHeader">

      <!-- Modal Body -->
      <div class="modal-body">

        <!-- Custom Content Area -->
        <div class="row">
          <div class="col-sm-12">
            <ng-content select="[modal-body]"></ng-content>
          </div>
        </div>

        <!-- Standard Button Area -->
        <div *ngIf="buttonConfig" class="row button-group-row" [ngClass]="{'no-top-padding': !buttonConfig.primary}">
          <div class="col-sm-12">

            <!-- Primary Button -->
            <div *ngIf="buttonConfig.primary" class="row button-row">
              <!-- LARGE MODAL BUTTON -->
              <div *ngIf="size === 'modal-lg'" class="col-sm-6 offset-sm-3 button-col">
                <button class="btn btn-primary gt-modal-button"
                  [disabled]="buttonConfig.primary.disabled"
                  [ngStyle]="{'cursor': buttonConfig.primary.disabled ? 'wait' : 'pointer'}"
                  (click)="primaryButtonClick.emit(buttonConfig.primary.action)">{{buttonConfig.primary.text}}</button>
              </div>
              <!-- SMALL and MEDIUM MODAL BUTTON -->
              <div *ngIf="size !== 'modal-lg'" class="col-sm-12 button-col">
                <button class="btn btn-primary gt-modal-button" 
                  [disabled]="buttonConfig.primary.disabled"
                  [ngStyle]="{'cursor': buttonConfig.primary.disabled ? 'wait' : 'pointer'}"
                  (click)="primaryButtonClick.emit(buttonConfig.primary.action)">{{buttonConfig.primary.text}}</button>
              </div>
            </div>

            <!-- Secondary Button -->
            <div *ngIf="buttonConfig.secondary" class="row button-row">
              <!-- LARGE MODAL BUTTON -->
              <div *ngIf="size === 'modal-lg'" class="col-sm-6 offset-sm-3 button-col">
                <button class="btn btn-secondary gt-modal-button" 
                  [disabled]="buttonConfig.secondary.disabled"
                  [ngStyle]="{'cursor': buttonConfig.secondary.disabled ? 'wait' : 'pointer'}"
                  (click)="secondaryButtonClick.emit(buttonConfig.secondary.action)">{{buttonConfig.secondary.text}}</button>
              </div>
              <!-- SMALL and MEDIUM MODAL BUTTON -->
              <div *ngIf="size !== 'modal-lg'" class="col-sm-12 button-col">
                <button class="btn btn-secondary gt-modal-button" 
                  [disabled]="buttonConfig.secondary.disabled"
                  [ngStyle]="{'cursor': buttonConfig.secondary.disabled ? 'wait' : 'pointer'}"
                  (click)="secondaryButtonClick.emit(buttonConfig.secondary.action)">{{buttonConfig.secondary.text}}</button>
              </div>
            </div>


          </div>
        </div>

      </div>


    </div>
  </div>
</div>