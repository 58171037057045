import {tap, map, catchError} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { URLConfig } from '../helpers/config.service';
import { Contact } from 'app/store/models/contacts';
import { HttpClient } from '@angular/common/http';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { UpdateResponseDTO } from 'app/store/models/packages/UpdateResponseDTO';
import { UpdateInvitationResponse } from 'app/store/models/packages/UpdateInvitationResponse';


@Injectable()
export class ContactService {
  constructor(
    private http: HttpClient,
    private URLs: URLConfig,
  ) {}

  public count: number = 0;

  insertContact(person): Observable<any> {
    return this.http.post(
      this.URLs._insertContactUrl(), person);
  }

  insertContactForUser(person, userId): Observable<any> {
    return this.http.post(
      this.URLs._insertContactUrlForUser(userId), person);
  }

  deleteContactTags (person): Observable<any> {
    if (person.TagsToDelete != undefined && person.TagsToDelete.length !== 0 ) {
      const deleteBody = {
        tagText: person.TagsToDelete,
        record: "Contact",
        recordId: person.IdContact
      };

      return this.http.post(this.URLs._deleteTagsInfo(), deleteBody).pipe(
      catchError((err: Response) => { 
        const details = err;
        return observableThrowError(details);
      }))
    }
    else {
      return;
    }
  }
  
  addContactTags (person): Observable<any> {
    if (person.TagsToAdd != undefined && person.TagsToAdd.length !== 0 ) {
      const addBody = {
        tagText: person.TagsToAdd,
        record: "Contact",
        recordId: person.IdContact
      };

      return this.http.post(this.URLs._insertTagsInfo(), addBody).pipe(
      catchError((err: Response) => { 
        const details = err;
        return observableThrowError(details);
      }))
    }
    else {
      return;
    }
  }

  insertContacts(people): Observable<any> {
    return this.http.post(
      this.URLs._insertContactsUrl(), people).pipe(
      catchError((err: Response) => {
        const details = err;
        return observableThrowError(details);
      }))
  }

  insertContactsForUser(people, userId): Observable<any> {
    return this.http.post(
      this.URLs._insertManyContactsUrlForUser(userId), people).pipe(
      catchError((err: Response) => {
        const details = err;
        return observableThrowError(details);
      }))
  }

  removeContact(person): Observable<any> {
    return this.http.post<any>(
      this.URLs._removeContactUrl(), person).pipe(
      map(res => {
        if (res.Result) {
        }
        return res;
      }));
  }

  removeContacts(person): Observable<any> {
    return this.http.post<any>(
      this.URLs._removeContactsUrl(), person).pipe(
      map(res => {
        if (res.Result) {
        }
        return res;
      }));
  }

  inviteContact(contact): Observable<any> {
    return this.http.post<any>(
      this.URLs._inviteContactUrl(), contact).pipe(
      map((res) => {
        if (res.Result) {
        }
        return res;
      }));
  }
  inviteContacts(contacts): Observable<any> {
    return this.http.post<any>(
      this.URLs._inviteContactsUrl(), contacts).pipe(
      map((res) => {
        if (res.Result) {
        }
        return res;
      }));
  }

  inviteContactsToPackage(requestBody): Observable<any> {
    return this.http.post(this.URLs._packageInvite(), requestBody);
  }

  inviteContactsToSurvey(requestBody): Observable<any> {
    return this.http.post<any>(this.URLs._surveyInvite(), requestBody).pipe(
      map(res => res.Obj));
  }

  contactDashboard(userID = ''): Observable<any> {
    if (userID === '') {
      userID = localStorage.getItem('userid');
    }

    return this.http.post<any>(
      this.URLs._contactDashboardUrl(), userID).pipe(
      map((res) => {
        return res.Obj;
      }));
  }

  public getContactsData(): Observable<Contact[]> {
    return this.http.get<GTResponse<Contact[]>>(this.URLs._contactsUrl()).pipe(
      handleGTResponse(),
      tap(contacts => {
        contacts.forEach(c => {
          c.Tags = (c.Tags !== undefined) ? c.Tags.replace(/ /g,',').split(',').sort().join() : "";
          c.IsNew = false;
        });
      }),);
  }

  public getEventLeaderContacts(eventId: number): Observable<Contact[]> {
    return this.http.get<GTResponse<Contact[]>>(this.URLs._getGroupLeaderContacts(eventId)).pipe(
      handleGTResponse(),
    );
  }

  public updateGLResponse(update: UpdateResponseDTO): Observable<UpdateInvitationResponse> {
    return this.http.post<GTResponse<UpdateInvitationResponse>>(this.URLs._packageUpdateResponse(), update).pipe(
      handleGTResponse(),
    );
  }

}
