import { Component, Input, ViewChild } from '@angular/core';
 
@Component({
  selector: 'grouptools-placeholder',
  templateUrl: './grouptools-placeholder.component.html',
  styleUrls: ['./grouptools-placeholder.component.scss']
})
export class GrouptoolsPlaceholderComponent {
    @ViewChild('pRow') pRow;
    @Input() isModal = false

    ngOnInit() {
        if(this.pRow.nativeElement.parentElement.id === "modalPlaceholder") {
            this.isModal = true;
        }
    }
}