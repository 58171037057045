import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { customDateValidator } from 'app/grouptools-theme/validators/customDate.validator';
import { minDateValidator } from 'app/grouptools-theme/validators/minDate.validator';
import { maxDateValidator } from 'app/grouptools-theme/validators/maxDate.validator';
import { NbgDatePickerUtility } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDatePickerUtility.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { ActivityType } from 'app/pages/package-details/package-details.service';
import { minimumValueValidator } from 'app/grouptools-theme/validators/minimumValue.validator';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as modalActions from 'app/store/actions/modal.action';
import * as activityActions from 'app/store/actions/activity.action';
import { Modal, ModalSize } from 'app/store/models/modal';
import { Activity} from 'app/store/models/activity'
import { Option } from 'app/store/models/option';
import { Event } from 'app/store/models/event';


@Component({
  selector: 'gled-add-activity-show',
  templateUrl: './gled-add-activity-show.component.html',
  styleUrls: ['./gled-add-activity-show.component.scss']
})
export class GledAddActivityShowComponent implements OnInit {

  public event$: Observable<Event>;
  private event: Event;

  public steps = {
    stepOne: {
      clickable: false,
      expand: true,
      check: false
    },
    stepTwo: {
      clickable: false,
      expand: true,
      check: false
    },
    stepThree: {
      clickable: false,
      expand: true,
      check: false
    }
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  formSubmitted: boolean = false;
  // saveConfirm: boolean = false;
  AdvancedMode: boolean = false;

  @Input() isEdit: boolean = false;
  @Input() isFirstFeature: boolean = false;
  // @Input() disableSave_FeatureAddOn: boolean = false;
  // @Input() disableSave_FeatureTickets: boolean = false;
  @Input() invitesSent: boolean = false;
  @Input() isRequiredDisabled: boolean = true;
  // @Input() attendeeCount: number = 0;
  @Input() multiOptions: boolean = false;

  // @Output() updateStepStatus_FeatureTickets: EventEmitter<any> = new EventEmitter();
  // @Output() updateStepStatus_FeatureAddOn: EventEmitter<any> = new EventEmitter();
  // @Output() setFormInit: EventEmitter<any> = new EventEmitter();

  @ViewChild('placesRef') placesRef: GooglePlaceDirective; // TODO: test if we need two separate placesRefs for each form

  public initAddShowForm = {
    // --------------- <feature-add-on> -----------------------
    isRequired: ['Yes'],
    ComponentName: [null, Validators.required],
    // tslint:disable-next-line:max-line-length
    ComponentOccurDate: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
    // ComponentEndDate: [null],
    ComponentOccurTime: [''],
    // ComponentEndTime: [''],
    ComponentDesc: [null, Validators.required],
    // RSVPDueDate: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
    VenueName: [null, Validators.required],
    ComponentVenueLocation: [null, Validators.required],
    // --------------- <feature-tickets> ----------------------
    // GetTickets: [''],
    SeatLocation: [''],
    // LodgingSeatLocation: [''],
    // TranSeatLocation: [''],
    SupplierCapacity: ['', minimumValueValidator(1)],
    SupplyPayDate: [null, [customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
    ComponentCapacity: ['', [minimumValueValidator(1)]],
    ComponentCost: ['', minimumValueValidator(0)],
    TotalCost: [{
      value: 0,
      disabled: true
    }],
    ComponentPrice: ['', [minimumValueValidator(0)]],
    DepositAmount: ['', [minimumValueValidator(0)]],
    DepositDueDate: [null, [customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
    // FinalPaymentDue: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
    FinalPaymentDue: [null, []],
    // MenuPDF: ['']
  };
  form: FormGroup;

  generateNewComponent(): Activity {

    const option = {
          // identifiers
      id: 0, // number (0 indicates new option)
      activityId: 0, // number (0 indicates new option)
      eventId: this.event.id, // number
      // Not a property: OptionName: this.radioModel === 'lodging' ? featureTickets.LodgingSeatLocation.value : (this.radioModel === 'transportation' ? featureTickets.TranSeatLocation.value : featureTickets.SeatLocation.value),

      // info
      description: this.form.controls.SeatLocation.value, // string
      attendeeCost: this.form.controls.ComponentCost.value, // number // what leader owes to supplier
      attendeePrice: this.form.controls.ComponentPrice.value, // number // what attendee pays
      quantity: this.form.controls.ComponentCapacity.value, // number
      optionType: 'Optional', // string
      optionGroup: null, // number
      lineItemId: '0', // string
      status: null, // string (maybe 'Visible' is the correct value? See VisibilityStatus in add-features component)
      availability: null, // number
      committed: null, // boolean
    } as Option;

    const activity: Activity = {
      // identifiers
      id: 0, // number (0 indicates new activity)
      eventId: this.event.id,
      catalogItemId: null, // number

      // where to put this.form.controls.RSVPDueDate.value ? this.form.controls.RSVPDueDate.value : null
      // where to put this.form.controls.TotalCost.value // which is object like this-> { value: number, disabled: boolean }

      // basic info
      name: this.form.controls.ComponentName.value,
      description: null,
      shortDescription: this.form.controls.ComponentDesc.value,
      imageUrl: null,
      image: null,
      imageType: null,
      customProperties: null,
      category: ActivityType.Default,
      occurDate: this.form.controls.ComponentOccurDate.value ? this.form.controls.ComponentOccurDate.value : null,
      occurTime: this.form.controls.ComponentOccurTime.value,
      endDate: null, // this.form.controls.ComponentEndDate.value ? this.form.controls.ComponentEndDate.value : null,
      endTime: null, // this.form.controls.ComponentEndTime.value,
      duration: null,
      durationDescription: null,
      venueName: this.form.controls.VenueName.value,
      venueAddress: this.form.controls.ComponentVenueLocation.value,
      inventoryOption: null,
      requirementOption: this.form.controls.isRequired.value === 'Yes' ? 'Required' : 'Optional',
      reservations: null,
      reservationPayments: null,
      currencyType: null,
      displayOrder: 0, // TODO: implement
      status: null,

      // show specific
      seatDescription: null, // string
      seatingAreaDescription: null, // string
      genre: null, // string
      audience: null, // string

      // restaurant specific
      menuPdf: null, // this.form.controls.MenuPDF.value,
      menuOptionsDescription: null, // string
      specialMenuDescription: null, // string

      // attendee requirements
      attendeePayByDate: this.form.controls.FinalPaymentDue.value ? this.form.controls.FinalPaymentDue.value : null,
      requiredDepositAmount: this.form.controls.DepositAmount.value,
      depositDueDate: this.form.controls.DepositDueDate.value ? this.form.controls.DepositDueDate.value : null,

      // supplier information
      supplierId: null, // number
      supplierName: null, // this.form.controls.ComponentSupplierName.value,
      supplierCost: null, // number
      supplierMinimumQuantity: this.form.controls.SupplierCapacity.value,
      detailDescriptionUrl: null, // string
      supplierPayByDate: this.form.controls.SupplyPayDate.value ? this.form.controls.SupplyPayDate.value : null,
      supplierInvoiceId: null, // string
      supplierProductCode: null, // string

      // options
      options: [option] // Array<Option>

    };

    return activity;

    // // Old implementation from add-features component's generateNewComponent() method
    // const featureAddOn = this.featureAddOnForm.controls;
    // const featureTickets = this.featureTicketsForm.controls;

    // const componentOption = {
    //   IdPackage: this.packageId,
    //   IdPackageComponent: this.isEdit && this.packageFeature.Options.length > 0 ? this.packageFeature.Options[0].IdPackageComponent : 0,
    //   // tslint:disable-next-line:max-line-length
    //   IdPackageComponentOption: this.isEdit && this.packageFeature.Options.length > 0 ? this.packageFeature.Options[0].IdPackageComponentOption : 0,
    //   OptionName: this.radioModel === 'lodging' ? featureTickets.LodgingSeatLocation.value : (this.radioModel === 'transportation' ? featureTickets.TranSeatLocation.value : featureTickets.SeatLocation.value) ,
    //   OptionDescription: featureTickets.SeatLocation.value,
    //   Quantity: featureTickets.ComponentCapacity.value,
    //   AttendeeCost: featureTickets.ComponentCost.value,
    //   AttendeePrice: featureTickets.ComponentPrice.value,
    //   VisibilityStatus: 'Visible',
    //   OptionType: 'Required',
    //   LineItemId: '0',
    //   PriceIncreaseMessage: ''
    // }

    // const component = {
    //   IdPackage: this.packageId,
    //   IdPackageComponent: this.isEdit ? this.packageFeature.IdPackageComponent : 0,
    //   ComponentRequirementOption: featureAddOn.isRequired.value === 'Yes' ? 'Required' : 'Optional',
    //   ComponentName: featureAddOn.ComponentName.value,
    //   ComponentShortDesc: featureAddOn.ComponentDesc.value,
    //   ComponentOccurDate: featureAddOn.ComponentOccurDate.value ? featureAddOn.ComponentOccurDate.value : null,
    //   ComponentOccurTime: featureAddOn.ComponentOccurTime.value,
    //   ComponentEndDate: featureAddOn.ComponentEndDate.value ? featureAddOn.ComponentEndDate.value : null,
    //   ComponentEndTime: featureAddOn.ComponentEndTime.value,
    //   VenueName: featureAddOn.VenueName.value,
    //   ComponentVenueLocation: featureAddOn.ComponentVenueLocation.value,
    //   ComponentSupplierName: featureTickets.GetTickets.value,
    //   ComponentSupplierMinimumQty: featureTickets.SupplierCapacity.value,
    //   ComponentSupplierPayByDate: featureTickets.SupplyPayDate.value,
    //   ComponentCustomFeatureImage: this.featureImage,
    //   ComponentCustomFeatureImageType: this.featureImageType,
    //   RequiredDepositAmount: featureTickets.DepositAmount.value,
    //   DepositDueDate: featureTickets.DepositDueDate.value,
    //   ComponentAttendeePayByDate: featureTickets.FinalPaymentDue.value,
    //   ComponentCategory: this.radioModel,
    //   DisplayOrder: this.displayOrder,
    //   MenuPDF: featureTickets.MenuPDF.value,
    //   Options: [componentOption]
    // };

    // return component;
  }



  constructor(public fb: FormBuilder,
    public datePickerUtility: NbgDatePickerUtility,
    private store: Store<fromRoot.State>
  ) { }

  // -------------------------------------------------------------------------------------------
  // Angular Life Cycle Methods
  // -------------------------------------------------------------------------------------------

  ngOnInit() {
    this.form = this.fb.group(this.initAddShowForm)
    // this.form.controls

    // this.subscribeToFeatureAddOnForm();
    this.subscribeToFormsOnValueChanged();

    this.event$ = this.store.select(fromRoot.getSelectedEvent);

    this.event$.pipe(takeUntil(this.unsubscribe$))
    .subscribe(event => { this.event = event; });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // --------------- <feature-add-on> -----------------------
    if (Boolean(changes.isEdit)) {
      if (changes.isEdit.currentValue === false) {
        if (Boolean(changes.invitesSent)) {
          if (changes.invitesSent.currentValue === true) {
            this.form.controls.isRequired.disable();
            if (!this.form.controls.isRequired.value) {
              this.form.controls.isRequired.setValue('No');
            }
          } else {
            this.form.controls.isRequired.enable();
          }
        }
      }
      if (changes.isEdit.currentValue === true) {
        if (this.isRequiredDisabled) {
          if (changes.isRequiredDisabled.currentValue === true) {
            this.form.controls.isRequired.disable();
          }
        }
      }
    }

    // --------------- <feature-tickets> ----------------------
    if (Boolean(changes.featureTicketsForm)) {
      // this.subscribeToFormsOnValueChanged(); // TODO: check if this lost features because it used to subscribe/unsubscribe each time ngOnChanges was called
      this.onValueChanged();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // -------------------------------------------------------------------------------------------
  // Form Validation and Management Methods
  // -------------------------------------------------------------------------------------------
  onValueChanged(data?: any) {
    if (Boolean(this.form) !== true) { return; }
    if (this.formSubmitted === true) {
      this.clearAndSetFormErrors(this.addShowFormErrors, this.form, this.addShowFormValidationMessages, false, false);
    } else {
      this.clearAndSetFormErrors(this.addShowFormErrors, this.form, this.addShowFormValidationMessages, true, false);
    }

  }

  onSubmit() {
    this.formSubmitted = true;

    this.clearAndSetFormErrors(this.addShowFormErrors, this.form, this.addShowFormValidationMessages, false, false);

    if (this.form.valid !== true) { console.log(`form is not valid!`); console.log(this.addShowFormErrors); console.log(this.form); return; }

    // TODO: send form to be saved (or could use saveConfirm to show confirmation modal)
    const activity = this.generateNewComponent();
    // this.store.dispatch(new activityActions.CreateActivity(activity));
    this.store.dispatch(new activityActions.CreateActivityAndOptions(activity));

    // TODO: catch OK or error actions to show appropriate modal

    this.closeModal();
  }

  // -------------------------------------------------------------------------------------------
  // Miscellaneous Methods
  // -------------------------------------------------------------------------------------------
  subscribeToFormsOnValueChanged(): void {
    this.form.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(data => {
      // if (Boolean(this.formSubmitted)) { this.onValueChanged(data); } // TODO: this was an alternate version used in feature-add-on form
      return this.onValueChanged(data); // TODO: this was used in feature-tickets form
    });

    this.onValueChanged();
  }

  cancel(): void {
    this.closeModal();
  }

  closeModal(): void {
    this.store.dispatch(new modalActions.CloseModal());
  }

  public handleVenueNameChange(address) {
    if (address.name) {
      this.form.controls.VenueName.setValue(address.name);
    }
    if (address.formatted_address) {
      this.form.controls.ComponentVenueLocation.setValue(address.formatted_address);
    }
  }

  public handleVenueAddressChange(address) {
    // --------------- <feature-add-on> -----------------------
    const addressList = address.formatted_address.split(',');
    if (addressList[0] === address.name) {
      this.form.controls.ComponentVenueLocation.setValue(address.formatted_address);
    } else {
      this.form.controls.ComponentVenueLocation.setValue(address.name + ', ' + address.formatted_address);
    }
  }

  public calculateTotalCost(event) {
    if (event.currentTarget.attributes.formcontrolname.nodeValue === 'ComponentCost') {
      const capacity = this.form.controls.ComponentCapacity.value;
      if (capacity === '') {
        this.form.controls.TotalCost.setValue(0);
      }
      this.form.controls.TotalCost.setValue(capacity * this.form.controls.ComponentCost.value);
    } else {
      const cost = this.form.controls.ComponentCost.value;
      if (cost === '') {
        this.form.controls.TotalCost.setValue(0);
      }
      this.form.controls.TotalCost.setValue(cost * this.form.controls.ComponentCapacity.value);
    }
  }

// TODO: Put clearAndSetFormErrors somewhere common to all activities
  clearAndSetFormErrors(formErrors: any, form: FormGroup, validationMessages: any, checkIfControlIsDirty: boolean, skipFormLevelErrors?: boolean): void {
    // checkIfControlIsDirty is used in onValueChanges but not when formSubmitted is true
    // checkFormLevelErrors is hopefully safe to be used always
    for (const field in formErrors) { if (formErrors.hasOwnProperty(field) !== true) { continue; }
      formErrors[field] = '';
    }
    // check for form-level errors
    if (!form.valid && skipFormLevelErrors !== true) {
      for (const key in form.errors) { if (form.errors.hasOwnProperty(key) !== true) { continue; }
        const messages = validationMessages[key];
        if (typeof form.errors[key] === 'object') {
          for (const subKey in form.errors[key]) { if (form.errors[key].hasOwnProperty(subKey) !== true) { continue; }
            formErrors[key] += messages[subKey] + ' ';
          }
        } else {
          formErrors[key] += messages[form.errors[key]] + ' ';
        }
      }
    }

    if (formErrors.hasOwnProperty("isRequired") !== true) {
      console.log('fault');
      console.log(formErrors);
      console.log(formErrors.hasOwnProperty("isRequired"));
      console.log('end fault');
    }

    for (const field in formErrors) {
      if (formErrors.hasOwnProperty(field) !== true) {
        console.log("Problem with " + field);
        continue;
      }
      // this.formErrors[field] = '';
      const control = form.get(field)

      if (control && !control.valid && (checkIfControlIsDirty === false || (control.dirty)) ) {
        const messages = validationMessages[field];
        for (const key in control.errors) { if (control.errors.hasOwnProperty(key) !== true) { continue; }
          formErrors[field] += messages[key] + ' ';
          // break; // this was here in the feature-add-on classes onValueChanges method.  I think it is to only show one error message per control at a time.
          // TODO: add parameter setMultipleErrorsPerControl: boolean and use this to toggle break statement.
        }
      }
    }
  }

  // // This logic has been moved into the template
  // public getComponentNamePlaceholder(){
  // public getComponentDescLabel(){
  // public getComponentDescPlaceholder(){


  // -------------------------------------------------------------------------------------------
  // Form Validation Config
  // -------------------------------------------------------------------------------------------

   // tslint:disable-next-line:member-ordering
   addShowFormErrors = {
    // --------------- <feature-add-on> -----------------------
    'isRequired': '',
    'ComponentName': '',
    'ComponentOccurDate': '',
    // 'ComponentEndDate': '',
    // 'RSVPDueDate': '', // TODO: RSVPDueDate should be commented in for show, but it is not displayed with not first feature, so this validation should be disabled in that case.
    'ComponentDesc': '',
    'VenueName': '',
    'ComponentVenueLocation': '',
    // --------------- <feature-tickets> ----------------------
    // 'TicketOccurDate': '',
    'SupplyPayDate': '',
    'DepositDueDate': '',
    'FinalPaymentDue': '',
    'ComponentCapacity': '',
    'ComponentCost': '',
    'ComponentPrice': '',
    // 'LodgingSeatLocation': '',
    // 'TranSeatLocation': '',
    'SupplierCapacity': '',
    'DepositAmount': ''
  }

  // tslint:disable-next-line:member-ordering
  // public ComponentCapacityMessages = {
  //     'tooSmall': 'Quantity must be 1 or greater',
  //     'required': 'If you enter a price, you must enter a quantity',
  //     'fullRequired': 'Quantity is required.'
  // };
  // tslint:disable-next-line:member-ordering
  public ComponentCapacitySeatsMessages = {
    'tooSmall': 'Seats must be 1 or greater',
    'required': 'If you enter a price, you must enter a seat quantity',
    'fullRequired': 'Seats is required.'
  };
  // tslint:disable-next-line:member-ordering
  // public ComponentPriceMessages = {
  //     'tooSmall': 'Price must be 0 or greater',
  //     'required': 'If you enter a quantity, you must enter a price',
  //     'fullRequired': 'Price is required.'
  // };
  // tslint:disable-next-line:member-ordering
  public ComponentPriceSeatsMessages = {
    'tooSmall': 'Price must be 0 or greater',
    'required': 'If you enter a seat quantity, you must enter a price',
    'fullRequired': 'Price is required.'
  };

  // tslint:disable-next-line:member-ordering
  addShowFormValidationMessages = {
    // --------------- <feature-add-on> -----------------------
    'isRequired': {
      'required': 'Please select one.'
    },
    'ComponentName': {
      'required': 'Name is required.'
    },
    'VenueName': {
      'required': 'Location is required.'
    },
    'ComponentVenueLocation': {
      'required': 'Address is required.'
    },
    'ComponentOccurDate': {
      'required': 'Date is required.',
      'beforeToday': 'Date must be in the future, greater than today',
      'dateTooLate': 'Date cannot be that far in the future',
      'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    },
    // 'ComponentEndDate': { // not used for shows
    //   'beforeToday': 'Date must be in the future, greater than today',
    //   'dateTooLate': 'Date cannot be that far in the future',
    //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    // },
    // 'RSVPDueDate': { // TODO: RSVPDueDate should be commented in for show, but it is not displayed with not first feature, so this validation should be disabled in that case.
    //   'required': 'RSVP Due Date is required.',
    //   'beforeToday': 'Date must be in the future, greater than today',
    //   'dateTooLate': 'Date cannot be that far in the future',
    //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    // },
    'ComponentDesc': {
      'required': 'Description is required.'
    },
    // --------------- <feature-tickets> ----------------------
    // 'TicketOccurDate': {
    //   'beforeToday': 'Date must be in the future, greater than today',
    //   'dateTooLate': 'Date cannot be that far in the future',
    //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    // },
    'SupplyPayDate': {
      'beforeToday': 'Date must be in the future, greater than today',
      'dateTooLate': 'Date cannot be that far in the future',
      'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    },
    'DepositDueDate': {
      'beforeToday': 'Date must be in the future, greater than today',
      'dateTooLate': 'Date cannot be that far in the future',
      'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    },
    'FinalPaymentDue': {
      'required': 'Final Payment Due Date is required',
      'beforeToday': 'Date must be in the future, greater than today',
      'dateTooLate': 'Date cannot be that far in the future',
      'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
    },
    'ComponentCapacity': this.ComponentCapacitySeatsMessages,
    'ComponentPrice': this.ComponentPriceSeatsMessages,
    // 'LodgingSeatLocation': {
    //   'required': 'Room Type is required'
    // },
    // 'TranSeatLocation': {
    //   'required': 'Pickup / Drop Off Location is required'
    // },
    'SupplierCapacity': {
      'tooSmall': 'This field accepts positive numbers only'
    },
    'ComponentCost': {
      'tooSmall': 'Cost must be 0 or greater'
    },
    'DepositAmount': {
      'tooSmall': 'Deposit must be 0 or greater'
    }
  }


  // -------------------------------------------------------------------------------------------
  // Previously <additional-info>
  // -------------------------------------------------------------------------------------------

  @Input() image;
  @Input() eventImage;
  @Input() categoryType;
  // @Input() isFirstFeature: boolean = true;
  @Output() changePhoto: EventEmitter<any> = new EventEmitter();
  @Output() changeEventPhoto: EventEmitter <null> = new EventEmitter();

  public photoType = 'an Activity Photo';

  // ngOnChanges() {
  //   switch (this.categoryType) {
  //     case 'show':
  //       this.photoType = 'an Activity Photo';
  //       break;
  //     case 'lodging':
  //       this.photoType = 'a Lodging Photo';
  //       break;
  //     case 'restaurant':
  //       this.photoType = 'a Restaurant Photo';
  //       break;
  //     case 'transportation':
  //       this.photoType = 'a Transportation Photo';
  //       break;
  //     case 'other':
  //       this.photoType = 'Photo';
  //       break;
  //     default:
  //       this.photoType = 'Photo';
  //       break;
  //   }
  // }

  // -------------------------------------------------------------------------------------------
  // Previously <additional-info> end
  // -------------------------------------------------------------------------------------------
}


export const AddActivityShowModalConfig: Modal = {
  title: 'Add Show',
  size: ModalSize.Large,
  isFullScreenMobile: true,
  componentWithOptions: {
    component: GledAddActivityShowComponent
  }
};