import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiState } from 'app/store/models/apiData';
import { State as RootStoreState } from 'app/store/reducers';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import { CreateEventFromBibOrderRequest } from 'app/store/models/requests/create-event-from-bib-order-request';
import * as PackageDetailsActions from "app/store/actions/packageDetails.actions";
import { CreateEventFromSupplierOrderRequest } from 'app/store/models/requests/create-event-from-supplier-order-request';

export interface CreateFromSupplierOrderDialogData {
  SupplierOrderNumber: string;
}
export interface CreateFromSupplierOrderDialogResult {}

@Component({
  selector: 'create-from-supplier-order-dialog',
  templateUrl: './create-from-supplier-order-dialog.component.html',
  styleUrls: ['./create-from-supplier-order-dialog.component.scss']
})
export class CreateFromSupplierOrderDialogComponent implements OnInit {

  authApiState$: Observable<ApiState>;
  supplierOrderNumberCreateEventApiState$: Observable<ApiState>;

  constructor(
    public dialogRef: MatDialogRef<CreateFromSupplierOrderDialogComponent, CreateFromSupplierOrderDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: CreateFromSupplierOrderDialogData,
    private store$: Store<RootStoreState>,    
  ) { }

  ngOnInit() {
    this.supplierOrderNumberCreateEventApiState$ = this.store$.select(PackageDetailsSelectors.getSupplierOrderNumberCreateEventApiState);
  }

  showSelected() {}
  dateSelected() {}

  getStartedClicked(createEventFromSupplierOrderRequest: CreateEventFromSupplierOrderRequest) {
    this.store$.dispatch(new PackageDetailsActions.CreateEventFromSupplierOrderDialog({createEventFromSupplierOrderRequest: createEventFromSupplierOrderRequest}));
  }

}
