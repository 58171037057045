import {switchMap, filter, take, finalize, catchError} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from 'app/authentication/authentication.service';
import { ClientAuthConfig } from '../pages/helpers/config.service';
import { NgxConfigureService } from 'ngx-configure';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private injector: Injector, 
    private configService: NgxConfigureService,
    ) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    const clientAuthConfig = this.injector.get(ClientAuthConfig);

    if (this.isTokenEndpoint(req)) { req = this.addBasicAuth(req, clientAuthConfig); } 
    else { req = this.addBearerAuth(req, authService.getToken()); }

    return next.handle(req).pipe(catchError((error, caught) => {
      if (error instanceof HttpErrorResponse) {
        switch ((<HttpErrorResponse>error).status) {
          case 400:
            return this.handle400Error(error, authService);
          case 401:
            return this.handle401Error(req, next, authService);
          case 500:
            return observableThrowError(new Error(error.error.ExceptionMessage));
        }
      }
        return observableThrowError(error);
    }));
  }

  isTokenEndpoint(req: HttpRequest<any>): boolean {
    return req.url === `${this.configService.config.apiUrl}token`;
  }

  isGetAttendeesFromCsvRequest(req: HttpRequest<any>): boolean {
    return req.url.includes("GetAttendeesFromCsvText");
  }

  addBearerAuth(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (this.isGetAttendeesFromCsvRequest(req)) {
      return req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'text/csv'
        }
      });
    }
    else {
      return req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    }
  }

  addBasicAuth(req: HttpRequest<any>, clientAuthConfig: ClientAuthConfig): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': clientAuthConfig.basicAuthHeader()
      }
    });
  }

  handle400Error(error: any, authService: AuthService) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant' && !!error.error.error_message) {
        // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
        // We also have to check if there's an error message. if there's not, this came from the refresh token.
        // If there is, then it's most likely invalid username/password and we don't want to call logout.
        authService.logout();
    }

    return observableThrowError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, authService: AuthService) {
    if(!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      //Hold any other requests until we get the new bearer token back
      this.tokenSubject.next(null);

      return authService.refresh().pipe(
          switchMap((newTokenResponse: any) => {
              if (newTokenResponse) {
                  const newToken = newTokenResponse.access_token;
                  this.tokenSubject.next(newToken);
                  return next.handle(this.addBearerAuth(req, newToken));
              }

              // If we don't get a new token, logout.
              authService.logout();
              return observableThrowError("");
          }),
          catchError(error => {
              // If there is an exception calling 'refreshToken', logout.
              authService.logout();
              return observableThrowError(error);
          }),
          finalize(() => {
              this.isRefreshingToken = false;
          }),);
      } else {
          //Hold any other requests until we get the new bearer token back
          return this.tokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(token => {
                  return next.handle(this.addBearerAuth(req, token));
              }),);  
      }
  }
}
