import { Injectable } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
import { SupplierMakePaymentRequest } from 'app/store/models/requests/av-make-payment-request';

@Injectable()
export class URLConfig {

  constructor(private configService: NgxConfigureService) {}

  get apiURL(): string { return this.configService.config.apiUrl; }
  get apiKey(): string { return this.configService.config.authVerifyToken; }

  // Authorization / Authentication
  public _emailurl() {return this.apiURL + `api/Auth/UniqueEmail?apiKey=${this.apiKey}&email=`; }
  public _getUser() { return this.apiURL + 'api/user/'; }
  public _updateUser() { return this.apiURL + 'api/user/'; }
  public _contactRegisterUrl() { return this.apiURL + 'api/Auth/Register?'; }
  public _updateProfile() { return this.apiURL + 'api/Auth/Updateprofile'; }
  public _loginurl() { return this.apiURL + 'api/Auth/Login?'; }
  public _passwordchangeurl() { return this.apiURL + 'api/Auth/ChangeCurrentUserPassword?'; }
  public _paymentinfoaddurl() { return this.apiURL + 'api/Auth/AddPaymentInfo?'; }
  public _paymentinfogeturl() { return this.apiURL + 'api/Auth/GetPaymentInfo?'; }
  public _verifytokenurl() { return this.apiURL + 'api/Auth/VerifyToken/'; }
  public _verifyuseremailtokenurl() { return this.apiURL + 'api/auth/verifyuseremailtoken/';}
  public _tokenUrl() { return this.apiURL + 'token'; }
  public _userinfourl() { return this.apiURL + 'api/Auth/GetCurrentUserInfo?'; }
  public _isregistereduserurl() { return this.apiURL + 'api/Auth/IsRegisteredUser'; }
  public _forgotpass(user: string) { return this.apiURL +  `api/Auth/ForgotPassword/${user}/?apiKey=${this.apiKey}`; }
  public _requestNewRole() { return this.apiURL +  `api/Auth/RequestNewRole`; }
  public _registerViaGoogle() { return this.apiURL + 'api/Auth/RegisterViaGoogle'; }
  public _registerViaAmazon() { return this.apiURL + 'api/Auth/RegisterViaAmazon'; }
  public _registerViaFacebook() { return this.apiURL + 'api/Auth/RegisterViaFacebook'; }
  public _getAmazonTokenFromCode(code) { return this.apiURL + `api/Auth/AmazonAccessToken/${code}/?apiKey=${this.apiKey}`; }
  public _getFacebookTokenFromCode(code) { return this.apiURL + `api/Auth/FacebookAccessToken/${code}/?apiKey=${this.apiKey}`; }
  public _createWePayAccount() { return this.apiURL + 'api/WePay/CreateAccount'; }
  public _removeWePayAccount() { return this.apiURL + 'api/WePay/RemoveAccount'; }
  public _createStripeAccount() { return this.apiURL + 'api/Stripe/CreateAccount'; }
  public _removeStripeAccount() { return this.apiURL + 'api/Stripe/RemoveAccount'; }
  public _generatePayPalSignUpUrl() { return this.apiURL + 'api/PayPal/GenerateSignUpUrl'; }
  public _associatePayPalAccount() { return this.apiURL + 'api/PayPal/AssociateAccount'; }
  public _removePayPalAccount() { return this.apiURL + 'api/PayPal/RemoveAccount'; }
  public _getLeaderPayPalMerchantId() { return this.apiURL + 'api/PayPal/GetLeaderMerchantId'; }
  public _userHasWePayAccount(userId) { return this.apiURL + `api/Auth/HasWePayAccount/${userId}`; }
  public _sendVerifyEmail() { return this.apiURL + 'api/Auth/SendVerifyEmail'; }
  public _verifySecurityToken() { return this.apiURL + 'api/Auth/VerifySecurityToken'; }
  public _resetPassword() { return this.apiURL + 'api/Auth/ResetPassword'; }
  public _sendVerifyCode() { return this.apiURL + 'api/Auth/SendVerificationCode'; }
  public _validateVerificationCode() { return this.apiURL + 'api/Auth/ValidateVerificationCode'; }
  public _getVerificationCodeExpiration() { return this.apiURL + 'api/Auth/GetVerificationCodeExpiration'; }

  // Configuration
  public _getConfiguration() { return this.apiURL + 'api/config/GetConfig'; }

  // Contacts
  public _contactsUrl() { return this.apiURL + 'API/ContactController/Get'; }
  public _insertContactUrl() { return this.apiURL + 'API/ContactController/Insert'; }
  public _insertContactsUrl() { return this.apiURL + 'API/ContactController/InsertMany'; }
  public _removeContactUrl() { return this.apiURL + 'API/ContactController/Remove'; }
  public _removeContactsUrl() { return this.apiURL + 'API/ContactController/RemoveMany'; }
  public _inviteContactUrl() { return this.apiURL + 'API/ContactController/Invite'; }
  public _inviteContactsUrl() { return this.apiURL + 'API/ContactController/InviteMany'; }
  public _labelContactUrl() { return this.apiURL + 'API/ContactController/Label'; }
  public _labelContactsUrl() { return this.apiURL + 'API/ContactController/LabelMany'; }
  public _contactDashboardUrl() { return this.apiURL + 'API/ContactController/Dashboard'; }
  public _importFromCsv() { return this.apiURL + 'API/ContactController/ImportFromCsv'; } // KC: Nothing in dashboard references this, so its not used
  public _processCsv() { return this.apiURL + 'API/ContactController/ProcessCsv'; } // KC: Not Used (no endpoint on api)
  public _getContactsFromCsv() { return this.apiURL + 'API/ContactController/GetContactsFromCsv?'; } // i think this is used...
  public _getGoogleContacts() { return this.apiURL + 'API/ContactController/GetGoogleContacts'; }
  public _getFacebookContacts() { return this.apiURL + 'API/ContactController/GetFacebookContacts'; }
  public _getOutlookContacts() { return this.apiURL + 'API/ContactController/GetOutlookContacts'; }
  public _getContactsForPackage() { return this.apiURL +  'API/ContactController/GetContactsForPackage/'; }
  public _getGroupLeaderContacts(eventId: number) { return this.apiURL +  `API/Events/${eventId}/groupleader/contacts`; }
  public _insertContactUrlForUser(userId: number) { return this.apiURL + `API/ContactController/user/${userId}/Insert`; }
  public _insertManyContactsUrlForUser(userId: number) { return this.apiURL + `API/ContactController/user/${userId}/InsertMany`; }
  public _importFromCsvForUser(userId: number) { return this.apiURL + `API/ContactController/user/${userId}/ImportFromCsv`; } // KC: Nothing in dashboard references this, so its not used

  // Extraneous
  public _errorMsgLoggingUrl() { return this.apiURL + 'Api/Log/LogErrorMessage/'; }
  public _errorLoggingUrl() { return this.apiURL + 'Api/Log/LogError'; }
  public _pictureuploadurl() { return this.apiURL + 'api/Pic/UploadPicture?'; }
  public _pdfuploadurl(categoryType) { return this.apiURL + `api/Media/Insert/${categoryType}`; }
  public _attachFilesToForm() { return this.apiURL + 'api/Media/attachFilesToForm'; }
  public _getMediaFiles(categoryType) { return this.apiURL + `api/Media/Get/${categoryType}`; }
  public _picturegeturl() { return this.apiURL + 'api/Pic/GetPicture?'; }
  public _ordersUrl() { return this.apiURL + 'api/orders'; }
  public _deliverForms(formId) { return this.apiURL + `api/form/deliver/${formId}`; }

  // Payments
  // public _paymentGetUrl() { return this.apiURL + 'api/payments/get/'; }
  public _paymentGetTypes() { return this.apiURL + 'api/payments/paymentTypes/'; }
  public _paymentRefund() { return this.apiURL + 'api/payments/refund/'; }
   
  public _paymentDistributionGetUrl() { return this.apiURL + 'api/payments/distribution/get/'; }
  public _paymentDistributionGetByPackage(packageId) { return this.apiURL + `api/payments/Distribution/Package/${packageId}`; }
  public _paymentDistributionInsertMany() { return this.apiURL + 'api/payments/distribution/InsertMany'; }
  public _paymentDistributionAutoUrl() { return this.apiURL + 'api/payments/distribution/auto'; }
  public _insertAttendeePayment() { return this.apiURL + 'api/payments/InsertAttendeePayment'; }
  public _getSuppliersForPackage() { return this.apiURL + 'api/supplier/GetSuppliersForPackage/'; }
  public _insertSupplierPayment() { return this.apiURL + 'api/payments/InsertSupplierPayment'; }
  public _getSuppliers() { return this.apiURL + 'api/Supplier/'; }
  public _paymentsGetUrlOld(packageId: number) { return this.apiURL + `api/Payments/Get/${packageId}`; }
  public _paymentsGetUrl(eventId: number): string { return this.apiURL + `api/Payments/${eventId}`; }
  public _paymentsFeatureSummaryUrl(packageId, componentId) { return this.apiURL +
    `api/Packages/${packageId}/Component/${componentId}/PaymentsSummary`; }
  public _makePayment() { return this.apiURL + 'api/payments/MakePayment'; }
  public _deletePayment(IdPayment) { return this.apiURL + `api/payments/Delete/${IdPayment}`; }
  public _paymentStatusUpdate() { return this.apiURL + 'api/payments/UpdateStatus'; }
  public _createWePayCheckout(paymentId) { return this.apiURL + `api/WePay/Checkout/${paymentId}`; }
  public _createStripeCheckout(paymentId) { return this.apiURL + `api/Stripe/Checkout/${paymentId}`; }
  public _getPaymentMethod(id?) { return this.apiURL + `api/Payments/GetPaymentMethod/${id}`; }
  public _paymentSetAlternatePaymentMethod() { return this.apiURL + 'api/Payments/SetAlternatePaymentMethod' }
  public _paymentGetAlternatePaymentMethod(id?) { return this.apiURL + `api/Payments/GetAlternatePaymentMethod/${id}` }

  // Supplier Payments
  public _addAndAssociateCustomerUrl(): string { return this.apiURL + `api/SupplierPayment/AddAndAssociateCustomer`; }
  public _supplierMakePaymentUrl(): string { return this.apiURL + `api/SupplierPayment/MakePayment`; }
  public _supplierMakeGLPaymentUrl(): string { return this.apiURL + `api/SupplierPayment/MakeSupplierGLPayment`; }

  /**
   * PackageReturns
   *
   * @returns
   *
   * @memberOf URLConfig
   */
  // BI DIRECT
  public _packageInvoiceDetails(invoiceNumber) { return this.apiURL + `api/invoice/getinvoice/${invoiceNumber}`; }
  public _getPotentialPackagesForInvoice(invoiceNumber) { return this.apiURL + `API/Packages/GetPotentialPackagesForInvoice/${invoiceNumber}`; }
  public _applyInvoiceToEvent() { return this.apiURL + 'api/packages/component/add'; }

  // EVENTS
  public _packageInsertUrl() { return this.apiURL + 'api/Packages/Insert?'; }
  public _packageUpdateUrl() { return this.apiURL + 'api/Packages/Update?'; }
  public _packageGetUrl() { return this.apiURL + 'api/Packages/Get'; }
  public _packagesOwnedUrl() { return this.apiURL + 'api/Packages/GetOwnedPackages'; }
  public _packageMyPackagesUrl() { return this.apiURL + 'api/Packages/MyPackages'; }
  public _packageTemplateUrl() { return this.apiURL + 'api/Packages/GetPackageTemplate'; }
  public _packageLandingDetails() { return this.apiURL + 'api/Packages/LandingDetails'; }
  public _packageGetByInvite() { return this.apiURL + 'api/Packages/GetByInvite'; }
  public _packageInvite() { return this.apiURL + 'api/Packages/Invite'; }
  public _packageInviteResponse() { return this.apiURL + 'api/Packages/InviteResponse'; }
  public _packageUpdateResponse() { return this.apiURL + 'Api/Events/UpdateResponse'; }
  public _packageInsertComponent() { return this.apiURL + 'api/packages/component/insert'; }
  public _addComponentOption() { return this.apiURL + 'Api/Events/Component/Option/Add'; }
  public _addComponentOptionWithDefaults() { return this.apiURL + 'Api/Packages/Component/Option/AddWithDefaults'; }
  public _packageRemoveComponent() { return this.apiURL + 'api/packages/component/remove'; }
  public _packageAttendeeFeature(IdPackageComponent) { return this.apiURL + `api/Packages/Component/${IdPackageComponent}/Choose`; }
  public _packageAttendeeFeatureOptionChoose(IdPackage, IdPackageComponent, IdPackageComponentOption) { return this.apiURL + `api/Events/${IdPackage}/Component/Option/${IdPackageComponent}/${IdPackageComponentOption}/Choose`; }
  public _packageGetComponentStatus(IdPackage) { return this.apiURL + `api/Packages/Component/${IdPackage}/Status`; }
  public _packageGetByID(packageId) { return this.apiURL + `api/Packages/GetByPid/${packageId}`; }
  public _packageShortUrl(packageId) { return this.apiURL + `api/Packages/ShortUrl/${packageId}`; }
  public _packageInfoByID(packageId) { return this.apiURL + `api/Packages/PackageInfo/${packageId}`; }
  public _packageDetails(packageId) { return this.apiURL + `api/Packages/Details/${packageId}`; }
  public _packageComponentsGetByID(packageId) { return this.apiURL + `api/Packages/GetComponentsByPid/${packageId}`; }
  public _packageAttendeeSummary(packageId: number) { return this.apiURL + `api/Packages/Attendees/Summary/${packageId}`; }
  // TODO: The below endpont _packageAttendeeDetails is deprecated. Refactor out. instead use _getAttendees
  public _packageAttendeeDetails(packageId) { return this.apiURL + `api/Packages/Attendees/${packageId}`; }
  public _paymentsReceivedSummary(packageId) { return this.apiURL + `api/Packages/${packageId}/Payments/Received`; }
  public _paymentsSentSummary(packageId) { return this.apiURL + `api/Packages/${packageId}/Payments/Sent`; }
  public _paymentsSentSummaryForAttendee(packageId) { return this.apiURL + `api/Packages/${packageId}/Attendee/Payments/Sent`; }
  public _getMySurveyEvents() { return this.apiURL + 'API/packages/GetPackagesOnlySurvey'; }
  public _getSurveyAttendees() { return this.apiURL + 'API/packages/GetSurveyEventAttendee'; }
  public _packageItineraryRecord(IdPackage) { return this.apiURL + `API/packages/ItineraryRecord/${IdPackage}`; }
  public _createEventFromBibOrder() { return this.apiURL + `api/events/CreateEventFromBibOrder`; }
  public _createEventFromSupplierOrder() { return this.apiURL + `api/events/CreateEventFromSupplierOrder`; }
  public _updateEventFromSupplierOrder() { return this.apiURL + `api/events/UpdateEventFromSupplierOrder`; }
  public _loadTickets() { return this.apiURL + `api/events/GetTickets`; }
  public _purchaseTickets() { return this.apiURL + `api/events/PurchaseTickets`; }
  public _getSeatMapImage() { return this.apiURL + `api/events/GetSeatMapImage`; }
  public _recordSupplierAttendeePayment() { return this.apiURL + `api/events/RecordSupplierAttendeePayment`; }
  public _getAlternateGroupLeaderInfo() { return this.apiURL + `api/events/GetAlternateGroupLeaderInfo`; }
  public _inviteEventAttendees() { return this.apiURL + `api/events/invite`; }
  public _markPublicUrlAsShared(eventId) { return this.apiURL + `api/Events/MarkPublicUrlAsShared/${eventId}`; }

  public _packageDetailsForAttendee(packageId) { return this.apiURL + `api/Packages/Details/attendee/${packageId}`; }
  public _packagePaymentsForAttendee(packageId) { return this.apiURL + `api/Payments/Package/${packageId}`; }
  public _packageAttendeeHasGuests(packageId) { return this.apiURL + `api/PlusOnes/HasGuests/${packageId}`; }
  public _packageUpdatePackageDetails() { return this.apiURL + 'api/Packages/UpdatePackageDetails'; }
  public _updateComponentDetails() { return this.apiURL + 'api/packages/UpdateComponentDetails'; }
  public _updateComponentOption() { return this.apiURL + 'Api/Packages/Component/Options/Update'; }
  public _getComponentOptions(IdPackage, IdComponent) { return this.apiURL + `Api/Packages/${IdPackage}/GetComponentOptions/${IdComponent}`; }
  public _packageRemoveComponentOption() { return this.apiURL + 'api/packages/Component/Options/Remove'; }
  public _tagsInfo(recordId: number, record: string) {
    return this.apiURL + `api/Packages/${record}/${recordId}/Tags`; 
  }
  public _insertTagsInfo() { return this.apiURL + 'api/packages/InsertTags'; }
  public _deleteTagsInfo() { return this.apiURL + 'api/packages/DeleteTags'; }
  public _getContactTagsByOwner() { return this.apiURL + 'api/packages/GetContactsTagsByOwner'; }
  public _uploadPackageImage(packageId) { return this.apiURL + `api/Packages/UploadPackagePicture/${packageId}`; }
  public _getPackageImage(packageId) { return this.apiURL + `api/Packages/GetPicture/${packageId}`; }
  public _cancelEvent(id) { return this.apiURL + `api/packages/cancel/${id}`}
  public _canCancelEvent(id) { return this.apiURL + `api/packages/canCancel/${id}`}
  public _getPackageLogs(id: number) { return this.apiURL + `api/packages/changes/${id}`}
  public _packageRecord(packageId: number) { return this.apiURL + `api/Packages/Record/${packageId}`; }
  public _packageUpdateComponentAttendeeNotesJson() { return this.apiURL + 'api/packages/UpdateComponentAttendeeNotesJson'; }
  public _packageRemovePlusOnes(IdPackage) { return this.apiURL + `api/Packages/DeletePlusOnes/${IdPackage}`; }
  public _packageResendInvitations() { return this.apiURL + 'api/Packages/ResendInvitations'; }
  
  // events refactor
  public _getOwnedEvents() { return this.apiURL + `api/events/owned`; }
  public _getTransferredEvents() { return this.apiURL + `api/events/transferred`; }
  public _getInvitedEvents() { return this.apiURL + `api/events/invited`; }
  public _getEvent(eventId: number) { return this.apiURL + `api/events/${eventId}`; }
  public _getEventOverview(eventId: number) { return this.apiURL + `api/events/${eventId}/overview`; }
  public _getLeaderEventSummary(eventId: number) { return this.apiURL + `api/events/${eventId}/summary`; }
  public _getGroupLeaderProfile(eventId: number) { return this.apiURL + `api/events/${eventId}/groupLeader`; }
  public _getAlternateLeaders(eventId: number) { return this.apiURL + `api/events/${eventId}/alternateLeaders`; }
  public _updateEvent(eventId: number) { return this.apiURL + `api/events/${eventId}`; }
  public _requestShortUrl(eventId: number) { return this.apiURL + `api/events/${eventId}/shorturl`; }
  public _makeTemplate(eventId: number) { return this.apiURL + `api/events/${eventId}/makeTemplate`; }
  public _cloneEvent(templateId: number) { return this.apiURL + `api/events/Templates/${templateId}/CreateEvent`; }
  public _assignEvent(eventId: number) { return this.apiURL + `api/events/${eventId}/Assign`; }
  public _cloneAndAssignEvent(templateId: number) { return this.apiURL + `api/events/Templates/${templateId}/CreateEventForUser`; }
  public _getActivities(eventId: number) { return this.apiURL + `api/events/${eventId}/activities`; }
  public _createActivity(eventId: number) { return this.apiURL + `api/events/${eventId}/activity`; }
  public _updateActivity(eventId: number, activityId: number) { return this.apiURL + `api/events/${eventId}/activity/${activityId}`; }
  public _deleteActivity(eventId: number, activityId: number) { return this.apiURL + `api/events/${eventId}/activity/${activityId}`; }
  public _createOption(eventId: number, activityId: number) { return this.apiURL + `api/events/${eventId}/activity/${activityId}/option`; }
  public _updateOption(eventId: number, activityId: number, optionId: number) { return this.apiURL + `api/events/${eventId}/activity/${activityId}/option/${optionId}`; }
  public _deleteOption(eventId: number, activityId: number, optionId: number) { return this.apiURL + `api/events/${eventId}/activity/${activityId}/option/${optionId}`; }
  public _getAttendees(eventId: number) { return this.apiURL + `api/events/${eventId}/attendees`; }
  public _changeAttendeeEmails(eventId: number) { return this.apiURL + `api/events/${eventId}/attendees/changeEmails`; }
  public _addAlternateLeader(eventId: number) { return this.apiURL + `api/events/${eventId}/alternateLeader`; }

  // Event Item Manager Endpoints
  // Event Item Manager Endpoints
  public _getShowList() { return this.apiURL + `api/eim/ShowList/?apiKey=${this.apiKey}`; }
  public _getEventAndActivity(eventId: number) { return this.apiURL + `api/eim/${eventId}/EventAndActivity`; }
  public _getEventItems(eventId: number) { return this.apiURL + `api/eim/${eventId}/EventItems/`; }
  public _assignAttendeesToItems(eventId: number) { return this.apiURL + `api/eim/${eventId}/EventItems/UpdateAssignments`; }
  public _assignAttendeesToItemsAutomatically(eventId: number) { return this.apiURL + `api/eim/${eventId}/EventItems/AssignItemsAutomatically`; }
  public _getAttendeesEim(eventId: number) { return this.apiURL + `api/eim/${eventId}/attendees`; }
  public _upsertAttendees(eventId: number) { return this.apiURL + `api/eim/${eventId}/attendees`; }
  public _publicEventAddAttendees(eventId: number) { return this.apiURL + `api/events/${eventId}/AddAttendees`; }
  public _deleteAttendee(eventId: number, eventAttendeeId: number) { return this.apiURL + `api/eim/${eventId}/deleteattendee/${eventAttendeeId}`; }
  public _downloadTickets() { return this.apiURL + `api/eim/DownloadTickets`; }
  public _downloadAllTickets() { return this.apiURL + `api/eim/DownloadAllTickets`; }
  public _downloadManifest(eventId: number) { return this.apiURL + `api/eim/${eventId}/DownloadManifest`; }
  
  public _parseAttendeesFromCsv(eventId: number) { return this.apiURL + `api/eim/${eventId}/GetAttendeesFromCsvText`; }
  public _parseAttendeesFromCsvInJson(eventId: number) { return this.apiURL + `api/eim/${eventId}/GetAttendeesFromCsvInJson`; }
  // Messages Endpoints
  public _notifyAttendeesTicketsReady(eventId: number) { return this.apiURL + `api/messages/${eventId}/NotifyAttendeesTicketsReady`; }
  
  // Auth Endpoints
  public _orderNumberLogin() { return this.apiURL + `api/eim/OrderNumberLogin/?apiKey=${this.apiKey}`; }
  public _bibTokenLogin() { return this.apiURL + `api/eim/TokenLogin/?apiKey=${this.apiKey}`; }
  // public _tokenUrl() { return this.apiURL + 'token'; }
  public _deauthorizedBearerToken() { return this.apiURL + 'api/eim/DeauthorizeBearerToken'; }

  // Forms
  public _getFormsForPackage(packageId) { return this.apiURL + `api/Form/GetFormsForPackage/${packageId}`; }
  public _createForm() { return this.apiURL + 'api/Form/CreateForm' }
  public _getForm(formId) { return this.apiURL + `api/Form/GetForm/${formId}`; }
  public _updateForm() { return this.apiURL + 'api/Form/UpdateForm'; }
  public _respondToForm(formId) { return this.apiURL + `api/Form/Respond/${formId}`; }
  public _getOwnedForms() { return this.apiURL + `api/form/getownedforms`; }

  // Messages
  public _getMessagesUrl(eventId: number): string { return this.apiURL + `something/${eventId}`; } // TODO: finalize endpoint
  public _messages() { return this.apiURL + 'api/messages/'; }
  public _getSentMessages() { return this.apiURL + 'api/messages/GetSentMessages'; }
  public _messagesSummary() { return this.apiURL + 'api/messages/summary'; }
  public _isGroupLeader() { return this.apiURL + 'api/Auth/IsGroupLeader'; }
  public _hasWePayAccount() { return this.apiURL + 'api/Auth/HasWePayAccount'; }
  public _sendMessages() { return this.apiURL + 'api/Messages/Send'; }
  public _sendPackageMessages() { return this.apiURL + 'api/Messages/Package/Send'; }
  public _markMessageAsRead(id: number) { return this.apiURL + `api/Messages/MarkAsRead/${id}`; }
  public _deleteMessages() { return this.apiURL + 'api/Messages/Delete'; }
  public _actions() { return this.apiURL + 'api/actions/'; }

  public _markActionAsRead() { return this.apiURL + `api/Actions/Dismiss/`; }
  public _dismissAllSurveyActions(surveyId: number) { return this.apiURL + `api/Actions/Dismiss/Survey/${surveyId}`; }

  public _userMenu(role){ 
    // return this.apiURL+`api/Auth/MenuItems`; // Not implemented yet.
    return `./assets/${role}-menu.json`;
  }

  //Public page request for invite.
  public _registerUserNoPassword() { return this.apiURL + 'api/Messages/SendPublicMessage'; }

  //Plus Ones
  public _plusOneRelationships() { return this.apiURL + 'api/plusones/relationshiptypes'; }
  public _addPlusOnes() { return this.apiURL + 'api/plusones/add'; }

  // SURVEYS
  public _getSurveysNew(eventId: number) { return this.apiURL + `something/${eventId}`; } //TODO: finalize endpoint
  public _getSurveys(packageId) { return this.apiURL + `api/survey/GetSurveysForPackage/${packageId}`}
  public _getSurveyDetails(surveyId) { return this.apiURL + `API/Survey/GetSurvey/${surveyId}`}
  public _hasResponded(surveyId) { return this.apiURL + `API/Survey/HasResponded/${surveyId}`}
  public _createSurveyForPackage() { return this.apiURL + 'api/Survey/CreateSurveyForPackage' }
  public _answerSurvey() { return this.apiURL + 'api/Survey/AnswerSurvey' };
  public _surveyGetByInvite() { return this.apiURL + 'api/Survey/GetByInvite'; }
  public _getSurveyResponses(surveyId) { return this.apiURL + `API/Survey/GetSurveyResponses/${surveyId}`}
  public _surveyInvite() { return this.apiURL + 'api/survey/invite'; }
  public _updateSurvey() { return this.apiURL + 'api/survey/update'; }

  // CATALOG
  public _getCatalogShows() { return this.apiURL + 'api/Catalog/GetAllCatalogItemsByCatalogTypeName/Show' };
  public _getCatalogWorkshops() { return this.apiURL + 'api/Catalog/GetAllCatalogItemsByCatalogTypeName/Workshop' };
  public _getSupplierURL(supplierName) { return this.apiURL + `api/Catalog/GetSupplierURL/${supplierName}`; }
  public _getProvinceState() { return this.apiURL + 'Api/Catalog/GetProvinceState'; }
}

@Injectable()
export class ClientAuthConfig {
  public clientId() {
    let clientId = '';

    // Yes, this is unnecessary right now. But, it's providing a way to have different IDs per environment.
    // A client has an allowed referrer. The goal is to have different client for local, dev, qa, and prod
    // so we can assign the allowed referrers. Local will be *, meaning allow all, while other environments
    // will be locked down in the future.
    
    switch (process.env.NODE_ENV) {
      case 'dev':
      case 'development':
        clientId = '1';
        break;
      case 'qa':
        clientId = '1';
        break;
      case 'prod':
      case 'production':
        clientId = '1';
        break;
      case 'local':
      default: 
        clientId = '1';
        break;
    }

    return(clientId + '');
  }

  public clientSecret() {
    return '';
  }

  public basicAuthHeader() {
    return ['Basic', btoa([this.clientId(), ':', this.clientSecret()].join(''))].join(' ');
  }
}
