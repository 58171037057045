import { Component, OnInit } from '@angular/core';
import * as modalActions from 'app/store/actions/modal.action';
import * as fromRoot from 'app/store/reducers';
import { Store } from '@ngrx/store';
import { FormsCardComponent } from 'app/pages/package-details/interactions/forms-view/forms-card/forms-card.component';

@Component({
  selector: 'hotel-planner-modal',
  templateUrl: './hotel-planner-modal.component.html',
  styleUrls: ['./hotel-planner-modal.component.scss']
})
export class HotelPlannerModalComponent implements OnInit {

  public hotelPlannerLogo = require(`assets/img/hotel-planner-orange.png`);
  constructor(
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
  }

  bookHotelLessRoom() {
    this.store.dispatch(new modalActions.ModalButtonPressed('small'));
    this.store.dispatch(new modalActions.CloseModal());
  }

  bookHotelMoreRoom() {
    this.store.dispatch(new modalActions.ModalButtonPressed('large'));
    this.store.dispatch(new modalActions.CloseModal());
  }
}
