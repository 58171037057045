import * as tslib_1 from "tslib";
import * as EimActions from "app/store/actions/eventItem.action";
import { ApiState, DataState } from "app/store/models/apiData";
import { attendeeUpsertAdapter, eventItemAdapter, eventItemEntitySelectors, mixedAttendeeAdapter, showInfoAdapter } from "app/store/entity-adapters/eventItem.adapter";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import { GTResponseCodes } from "../models/gtResponse";
export var initialState = {
    showList: showInfoAdapter.getInitialState({
        apiState: ApiState.Initial,
        errorMessage: null,
    }),
    eventItems: eventItemAdapter.getInitialState({
        apiState: ApiState.Initial,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    // initiallyLoadedAttendees will be used to prevent autosaving from triggering the @Input of the attendees tab changing,
    // which then causes parts of the page to reload.  We want to load them initially, then allow the page to be the source of truth after that.
    // only the attendee tab will use it, the rest of the app will use the 'attendees' property, which will represent the currently saved state.
    initiallyLoadedAttendees: attendeeUpsertAdapter.getInitialState({
        apiState: ApiState.Initial,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    attendees: attendeeUpsertAdapter.getInitialState({
        apiState: ApiState.Initial,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    // importedAttendees: [],
    waitingAttendees: mixedAttendeeAdapter.getInitialState(),
    invalidAttendees: mixedAttendeeAdapter.getInitialState(),
    inFlightAttendees: mixedAttendeeAdapter.getInitialState(),
    savedInputAttendees: attendeeUpsertAdapter.getInitialState(),
    savedInputNewIdMap: new Map(),
    savedInputNewIdMapReverse: new Map(),
    deletedEventAttendeeIds: [],
    deletedNewEventAttendeeIds: [],
    failedToDeleteEventAttendeeIds: [],
    failedToDeleteNewEventAttendeeIds: [],
    failedToAutosaveEventAttendeeIds: [],
    failedToAutosaveNewEventAttendeeIds: [],
    deletedNewEventAttendeeIdsSet: new Set(),
    assignmentsState: {
        apiState: ApiState.Initial,
        errorMessage: null,
    },
    selectedTab: 'attendees',
    deliveryPageSelectionState: new Map(),
    downloadState: {
        apiState: ApiState.Initial,
        errorMessage: null,
        responseCode: GTResponseCodes.Initial,
    },
    notifyState: {
        apiState: ApiState.Initial,
        errorMessage: null,
        responseCode: GTResponseCodes.Initial,
    },
    downloadManifestState: {
        apiState: ApiState.Initial,
        errorMessage: null,
        responseCode: GTResponseCodes.Initial,
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
    switch (action.type) {
        // ===============================================================================
        //                            Delivery Page Selection State
        // ===============================================================================
        case EimActions.ActionTypes.EimPageSetDeliverySelectionState:
            {
                // here need to take the input (partial selection map) and assign only those partial values to the whole
                // but remember immutability!!
                var newMap = new Map(state.deliveryPageSelectionState);
                try {
                    for (var _e = tslib_1.__values(action.payload.selectionMap.entries()), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var entry = _f.value;
                        var eventItemId = entry[0];
                        var isSelected = entry[1];
                        newMap.set(eventItemId, isSelected);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return tslib_1.__assign({}, state, { deliveryPageSelectionState: newMap });
            }
        case EimActions.ActionTypes.EimPageSetDeliverySelectionStateAll:
            {
                // need to look at all the event items, and just create a new map with all set to true (or all set to false)
                var newMap = new Map();
                var eventItems = eventItemEntitySelectors.selectAll(state.eventItems);
                try {
                    for (var eventItems_1 = tslib_1.__values(eventItems), eventItems_1_1 = eventItems_1.next(); !eventItems_1_1.done; eventItems_1_1 = eventItems_1.next()) {
                        var eventItem = eventItems_1_1.value;
                        newMap.set(eventItem.id, action.payload.isAllSelected);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (eventItems_1_1 && !eventItems_1_1.done && (_b = eventItems_1.return)) _b.call(eventItems_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                return tslib_1.__assign({}, state, { deliveryPageSelectionState: newMap });
            }
        // ===============================================================================
        //                            Notifying the Attendee
        // ===============================================================================
        case EimActions.ActionTypes.EimPageNotifyAttendeesTicketsReady:
        case EimActions.ActionTypes.EimPageNotifySelectedAttendeesTicketsReady:
            {
                return tslib_1.__assign({}, state, { notifyState: tslib_1.__assign({}, state.notifyState, { apiState: ApiState.InFlight }) });
            }
        case EimActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyResponse:
            {
                // Here get GTCollectionPayload<TicketReadyNotificationResponse>, some notifications could be successful, some failures
                // The endpoint should make all the eventAttendeeIds valid for the successes (it is of type "number | null")
                // But the failures might be null or valid ids, so this might need to be checked, heck could check the successes too, no big deal
                // Usefulness: need to show user result of notifications, might need to itemize who was/wasn't notified, maybe even visually indicate the failures in the list
                //     need to see the state of the api request, to show visual feedback of progress
                //     need to update the status of each eventItem and/or attendee that was notified (start with eventItem and go from there)
                //     (but if only updating eventItem, then do we need rules in place to block the eventItem from being re-assigned?)
                //     Once the attendee is notified, they could download at any time, and the app's store might be out of date,
                //     So assignments could fail individually, what to do in this case? - could lock eventItem from assigning once notified status
                //     Could allow user to 'Revoke Notification' with warning that this will only work if the attendee has not downloaded the ticket yet 
                //        - Do we send revoked notification when revoked? or just show revoked info when the attendee tries to download from revoked link
                return tslib_1.__assign({}, state, { notifyState: {
                        apiState: ApiState.Success,
                        errorMessage: null,
                        responseCode: GTResponseCodes.Success,
                    } });
            }
        case EimActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyTooSoonError:
            {
                return tslib_1.__assign({}, state, { notifyState: {
                        apiState: ApiState.Error,
                        errorMessage: null,
                        responseCode: GTResponseCodes.TooEarlyToGenerateTickets,
                    } });
            }
        case EimActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyFailure:
            {
                // This means the entire request failed from an unexpected error
                return tslib_1.__assign({}, state, { notifyState: {
                        apiState: ApiState.Error,
                        errorMessage: action.payload.errorMessage,
                        responseCode: action.payload.responseCode,
                    } });
            }
        // ===============================================================================
        //                            Downloading Tickets
        // ===============================================================================
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadTickets:
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadSelectedTickets:
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadAllTickets:
            {
                return tslib_1.__assign({}, state, { downloadState: tslib_1.__assign({}, state.downloadState, { apiState: ApiState.InFlight }) });
            }
        case EimActions.ActionTypes.EimApiGroupLeaderDownloadTicketsTooSoonError:
            {
                return tslib_1.__assign({}, state, { downloadState: {
                        apiState: ApiState.Success,
                        errorMessage: null,
                        responseCode: GTResponseCodes.TooEarlyToGenerateTickets,
                    } });
            }
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadTooManyTicketsError:
        case EimActions.ActionTypes.EimEffectsDownloadFile:
        case EimActions.ActionTypes.EimApiGroupLeaderDownloadTicketsResponse:
            {
                // Here we get GTCollectionPayload<FetchedTicketResult>, some tickets (event items) could be successful, some failures
                // In fact, the errors mean the ticket failed to download for some unexpected reason,
                // but a success could actually be any of the FetchedTicketStatus types, so even they could be purposeful failures
                // Usefulness: Need to show user the result of the downloads, which tickets are included in the download, which failed, were denied, etc
                //             Also need to kick of the actual download (this might be done in an effect or triggered from a selector)
                return tslib_1.__assign({}, state, { downloadState: {
                        apiState: ApiState.Success,
                        errorMessage: null,
                        responseCode: GTResponseCodes.Success,
                    } });
            }
        case EimActions.ActionTypes.EimApiGroupLeaderDownloadTicketsFailure:
            {
                // This means the entire request failed from an unexpected error
                return tslib_1.__assign({}, state, { downloadState: {
                        apiState: ApiState.Error,
                        errorMessage: action.payload.errorMessage,
                        responseCode: action.payload.responseCode,
                    } });
            }
        // ===============================================================================
        //                            Downloading Manifest
        // ===============================================================================
        case EimActions.ActionTypes.EimPageDownloadManifest:
            {
                return tslib_1.__assign({}, state, { downloadManifestState: {
                        apiState: ApiState.InFlight,
                        errorMessage: null,
                        responseCode: null,
                    } });
            }
        case EimActions.ActionTypes.EimApiDownloadManifestResponse:
            {
                return tslib_1.__assign({}, state, { downloadManifestState: {
                        apiState: ApiState.Success,
                        errorMessage: null,
                        responseCode: GTResponseCodes.Success,
                    } });
            }
        case EimActions.ActionTypes.EimApiDownloadManifestFailure:
            {
                return tslib_1.__assign({}, state, { downloadManifestState: {
                        apiState: ApiState.Error,
                        errorMessage: action.payload.errorMessage,
                        responseCode: action.payload.responseCode,
                    } });
            }
        // ===============================================================================
        //                            Retrieving the Show List
        // ===============================================================================
        case EimActions.ActionTypes.EimGetShowList:
            {
                return tslib_1.__assign({}, state, { showList: tslib_1.__assign({}, state.showList, { apiState: ApiState.InFlight }) });
            }
        case EimActions.ActionTypes.EimGetShowListSuccess:
            {
                return tslib_1.__assign({}, state, { showList: tslib_1.__assign({}, showInfoAdapter.addAll(action.payload.showList, state.showList), { apiState: ApiState.Success, errorMessage: null }) });
            }
        case EimActions.ActionTypes.EimGetShowListFailure:
            {
                return tslib_1.__assign({}, state, { showList: tslib_1.__assign({}, state.showList, { apiState: ApiState.Error, errorMessage: action.payload.errorMessage }) });
            }
        // ===============================================================================
        //                            Assigning Attendees
        // ===============================================================================
        case EimActions.ActionTypes.EimPageAssignAttendeeToEventItem:
            {
                var eventItemUpdate = {
                    id: action.payload.eventItem.id,
                    changes: {
                        eventAttendeeId: action.payload.attendee.eventAttendeeId
                    }
                };
                var assignment = { eventItemId: action.payload.eventItem.id, eventAttendeeId: action.payload.attendee.eventAttendeeId };
                return tslib_1.__assign({}, state, { assignmentsState: tslib_1.__assign({}, state.assignmentsState, { apiState: ApiState.InFlight }), eventItems: eventItemAdapter.updateOne(eventItemUpdate, state.eventItems) });
            }
        case EimActions.ActionTypes.EimPageUnassignAttendeeFromEventItem:
            {
                var eventItemUpdate = {
                    id: action.payload.eventItem.id,
                    changes: { eventAttendeeId: null }
                };
                var assignment = { eventItemId: action.payload.eventItem.id, eventAttendeeId: null };
                return tslib_1.__assign({}, state, { assignmentsState: tslib_1.__assign({}, state.assignmentsState, { apiState: ApiState.InFlight }), eventItems: eventItemAdapter.updateOne(eventItemUpdate, state.eventItems) });
            }
        case EimActions.ActionTypes.EimApiAutosaveAssignmentsSuccess:
            {
                return tslib_1.__assign({}, state, { 
                    // Updating the eventItems here will cause an assignment dropped during a pending api request to be overwritten by the api response, causing weird bugs
                    // eventItems: eventItemAdapter.upsertMany(action.payload.eventItems, state.eventItems),
                    assignmentsState: {
                        apiState: ApiState.Success,
                        errorMessage: null,
                    } });
            }
        case EimActions.ActionTypes.EimApiAutosaveAssignmentsFailure:
            {
                return tslib_1.__assign({}, state, { assignmentsState: {
                        apiState: ApiState.Error,
                        errorMessage: action.payload.errorMessage,
                    } });
            }
        // ===============================================================================
        //                            Eim Page Actions
        // ===============================================================================
        case EimActions.ActionTypes.EimPageAttendeesHaveBeenEdited:
            {
                var validAttendees = action.payload.allAttendees.filter(function (attendee) { return attendee.isValid; });
                var invalidAttendees = action.payload.allAttendees.filter(function (attendee) { return !attendee.isValid; });
                return tslib_1.__assign({}, state, { waitingAttendees: mixedAttendeeAdapter.addAll(validAttendees, state.waitingAttendees), invalidAttendees: mixedAttendeeAdapter.addAll(invalidAttendees, state.invalidAttendees) });
            }
        // ===============================================================================
        //                            Loading Attendees
        // ===============================================================================
        case EimActions.ActionTypes.EimPageLoadAttendees:
            {
                return tslib_1.__assign({}, state, { initiallyLoadedAttendees: tslib_1.__assign({}, state.initiallyLoadedAttendees, { apiState: ApiState.InFlight }), attendees: tslib_1.__assign({}, state.attendees, { apiState: ApiState.InFlight }) });
            }
        // case EimActions.EIM_PAGE_ADD_IMPORTED_ATTENDEES:
        // {
        //     return {
        //         ...state,
        //         // initiallyLoadedAttendees: {
        //         //     ...state.initiallyLoadedAttendees,
        //         //     ...attendeeUpsertAdapter.addMany(action.payload.importedAttendees, state.initiallyLoadedAttendees),
        //         // },
        //         // attendees: {
        //         //     ...state.attendees,
        //         //     ...attendeeUpsertAdapter.addMany(action.payload.importedAttendees, state.initiallyLoadedAttendees),
        //         // }
        //         importedAttendees: action.payload.importedAttendees,
        //     };
        // }
        case EimActions.ActionTypes.EimApiLoadAttendeesSuccess:
            {
                return tslib_1.__assign({}, state, { initiallyLoadedAttendees: tslib_1.__assign({}, attendeeUpsertAdapter.addAll(action.payload.attendees, state.initiallyLoadedAttendees), { apiState: ApiState.Success, dataState: DataState.Success, errorMessage: null }), attendees: tslib_1.__assign({}, attendeeUpsertAdapter.addAll(action.payload.attendees, state.attendees), { apiState: ApiState.Success, dataState: DataState.Success, errorMessage: null }), savedInputAttendees: tslib_1.__assign({}, attendeeUpsertAdapter.addAll(action.payload.attendees, state.savedInputAttendees)), savedInputNewIdMap: new Map(), savedInputNewIdMapReverse: new Map(), waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees), invalidAttendees: mixedAttendeeAdapter.removeAll(state.invalidAttendees) });
            }
        case EimActions.ActionTypes.EimApiLoadAttendeesFailure:
            {
                return tslib_1.__assign({}, state, { initiallyLoadedAttendees: tslib_1.__assign({}, state.initiallyLoadedAttendees, { apiState: ApiState.Error, dataState: DataState.Error, errorMessage: action.payload.errorMessage }), attendees: tslib_1.__assign({}, state.attendees, { apiState: ApiState.Error, dataState: DataState.Error, errorMessage: action.payload.errorMessage }) });
            }
        // ===============================================================================
        //                            Loading Event Items
        // ===============================================================================
        case EimActions.ActionTypes.EimPageLoadEventItems:
            {
                return tslib_1.__assign({}, state, { eventItems: tslib_1.__assign({}, state.eventItems, { apiState: ApiState.InFlight }) });
            }
        case EimActions.ActionTypes.EimApiLoadEventItemsSuccess:
            {
                return tslib_1.__assign({}, state, { eventItems: tslib_1.__assign({}, eventItemAdapter.addAll(action.payload.eventItems, state.eventItems), { apiState: ApiState.Success, dataState: DataState.Success, errorMessage: null }), assignmentsState: {
                        apiState: ApiState.Success,
                        errorMessage: null,
                    } });
            }
        case EimActions.ActionTypes.EimApiLoadEventItemsFailure:
            {
                return tslib_1.__assign({}, state, { eventItems: tslib_1.__assign({}, state.eventItems, { apiState: ApiState.Error, dataState: DataState.Error, errorMessage: action.payload.errorMessage }), assignmentsState: {
                        apiState: ApiState.Error,
                        errorMessage: action.payload.errorMessage,
                    } });
            }
        // ===============================================================================
        //                            Deleting Attendees
        // ===============================================================================
        // case EimActions.ActionTypes.EimPageDeleteAttendee:
        // {
        //     return {
        //         ...state,
        //         attendees: {
        //             ...state.attendees,
        //             apiState: ApiState.InFlight,
        //         }
        //     };
        // }
        case EimActions.ActionTypes.EiEffectsDeleteExistingAttendee:
            {
                return tslib_1.__assign({}, state, { initiallyLoadedAttendees: tslib_1.__assign({}, state.initiallyLoadedAttendees, { apiState: ApiState.InFlight }), attendees: tslib_1.__assign({}, state.attendees, { apiState: ApiState.InFlight }) });
            }
        case EimActions.ActionTypes.EimApiDeleteAttendeeSuccess:
            {
                return tslib_1.__assign({}, state, { initiallyLoadedAttendees: tslib_1.__assign({}, attendeeUpsertAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.initiallyLoadedAttendees), { apiState: ApiState.Success, dataState: DataState.Success, errorMessage: state.initiallyLoadedAttendees.errorMessage }), attendees: tslib_1.__assign({}, attendeeUpsertAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.attendees), { apiState: ApiState.Success, 
                        // state.attendees.apiState, // This will be set when autosaving kicks in
                        dataState: DataState.Success, 
                        // state.attendees.dataState, // This will be set when autosaving kicks in 
                        errorMessage: state.attendees.errorMessage }), waitingAttendees: mixedAttendeeAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.waitingAttendees), invalidAttendees: mixedAttendeeAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.invalidAttendees), savedInputAttendees: attendeeUpsertAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.savedInputAttendees), deletedEventAttendeeIds: state.deletedEventAttendeeIds.concat(action.payload.deletedEventAttendeeIds) });
            }
        case EimActions.ActionTypes.EimApiDeleteAttendeeFailure:
            {
                return tslib_1.__assign({}, state, { initiallyLoadedAttendees: tslib_1.__assign({}, state.initiallyLoadedAttendees, { apiState: ApiState.Error, dataState: DataState.Error, errorMessage: action.payload.errorMessage }), attendees: tslib_1.__assign({}, state.attendees, { apiState: ApiState.Error, dataState: DataState.Error, errorMessage: action.payload.errorMessage }), failedToDeleteEventAttendeeIds: state.failedToDeleteEventAttendeeIds.concat([action.payload.failedEventAttendeeId]) });
            }
        case EimActions.ActionTypes.EiEffectsDeleteNewAttendee:
            {
                var newIdSet = new Set(state.deletedEventAttendeeIds);
                newIdSet.add(action.payload.newEventAttendeeId);
                return tslib_1.__assign({}, state, { 
                    // Below the action.payload.newEventAttendeeId is negative b/c it waitingAttendees is indexed by the combinedEventAttendeeId
                    waitingAttendees: mixedAttendeeAdapter.removeOne(-action.payload.newEventAttendeeId, state.waitingAttendees), invalidAttendees: mixedAttendeeAdapter.removeOne(-action.payload.newEventAttendeeId, state.invalidAttendees), deletedNewEventAttendeeIds: state.deletedNewEventAttendeeIds.concat(action.payload.newEventAttendeeId), deletedNewEventAttendeeIdsSet: newIdSet });
            }
        case EimActions.ActionTypes.EimPageDeletedIdsProcessed:
            {
                var deletedEventAttendeeIdsSet_1 = new Set(action.payload.deletedEventAttendeeIds);
                var deletedNewEventAttendeeIdsSet_1 = new Set(action.payload.deletedNewEventAttendeeIds);
                var failedToDeleteEventAttendeeIdsSet_1 = new Set(action.payload.failedToDeleteEventAttendeeIds);
                var failedToDeleteNewEventAttendeeIdsSet_1 = new Set(action.payload.failedToDeleteNewEventAttendeeIds);
                return tslib_1.__assign({}, state, { deletedEventAttendeeIds: state.deletedEventAttendeeIds.filter(function (id) { return !deletedEventAttendeeIdsSet_1.has(id); }), deletedNewEventAttendeeIds: state.deletedNewEventAttendeeIds.filter(function (newId) { return !deletedNewEventAttendeeIdsSet_1.has(newId); }), failedToDeleteEventAttendeeIds: state.failedToDeleteEventAttendeeIds.filter(function (id) { return !failedToDeleteEventAttendeeIdsSet_1.has(id); }), failedToDeleteNewEventAttendeeIds: state.failedToDeleteNewEventAttendeeIds.filter(function (newId) { return !failedToDeleteNewEventAttendeeIdsSet_1.has(newId); }) });
            }
        // ===============================================================================
        //                            Saving Attendees
        // ===============================================================================
        case EimActions.ActionTypes.EiEffectsNoAttendeesToSave:
            {
                return tslib_1.__assign({}, state, { waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees) });
            }
        case EimActions.ActionTypes.EimPageSaveAttendees:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { apiState: ApiState.InFlight }), waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees), inFlightAttendees: mixedAttendeeAdapter.addAll(action.payload.attendees.attendees, state.inFlightAttendees) });
            }
        case EimActions.ActionTypes.EimApiSaveAttendeesSuccess:
            {
                // inFlightAttendees has the requested state of saved attendees, but some could be success, others failure, also some could be new
                // need to find the matching requested attendee for each successfully saved attendee.
                var inFlightAttendeesDict = state.inFlightAttendees.entities;
                var savedInputAttendeesToUpsert = [];
                var newMap = new Map(state.savedInputNewIdMap);
                var newMapReverse = new Map(state.savedInputNewIdMapReverse);
                try {
                    for (var _g = tslib_1.__values(action.payload.attendeeCollectionPayload.Succeeded), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var successfulResponseAttendee = _h.value;
                        var requestedAttendee = inFlightAttendeesDict[successfulResponseAttendee.eventAttendeeId];
                        if (!requestedAttendee && successfulResponseAttendee.newEventAttendeeId > 0) {
                            // The below index is negative because inFlightAttendees should use the combined event attendee id, which puts the new ids negative
                            requestedAttendee = inFlightAttendeesDict[-successfulResponseAttendee.newEventAttendeeId];
                        }
                        if (!requestedAttendee) {
                            // Then there is no matching requested attendee.  This is unexpected (and likely an error)
                            console.warn("WARNING: Could not find requested in-flight attendee!");
                            console.log(successfulResponseAttendee);
                            console.log(state.inFlightAttendees);
                            continue; // skip this attendee
                        }
                        requestedAttendee = AttendeeUpsertRequest.copy(requestedAttendee);
                        // Now we have the original request object, and the successfully saved response object.
                        // // Check if this successfully saved attendee was deleted locally while it was initially saving
                        // if (requestedAttendee.newEventAttendeeId > 0) {
                        //     const shouldDeleteThisAttendee = state.deletedNewEventAttendeeIdsSet.has(requestedAttendee.newEventAttendeeId);
                        //     if (shouldDeleteThisAttendee) {
                        //         // TODO: what to do here?
                        //     }
                        // }
                        // Update the id fields that could have been assigned in the response
                        requestedAttendee.eventAttendeeId = successfulResponseAttendee.eventAttendeeId;
                        requestedAttendee.parentUserId = successfulResponseAttendee.parentUserId;
                        // requestedAttendee will be used to update the savedInputAttendees, which represent the client's input of what has been saved,
                        // it represents the client input, and not what was returned from the response so that it can be accurately compared to future client changes
                        // to know if there are any differences that need to be saved.
                        savedInputAttendeesToUpsert.push(requestedAttendee);
                        if (requestedAttendee.newEventAttendeeId > 0) {
                            newMap.set(requestedAttendee.newEventAttendeeId, requestedAttendee.eventAttendeeId);
                            newMapReverse.set(requestedAttendee.eventAttendeeId, requestedAttendee.newEventAttendeeId);
                        }
                        // Then the response attendee should be used to update the attendees property, which will be used everywhere else in the application
                        // to represent the currently saved state of attendees.
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_c = _g.return)) _c.call(_g);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                // Trying to just clean out the old values, and assign fresh from the latest response, 
                // we'll start simple, and see how this approach fairs in the wild!
                // Turns out this approach might actually work, but why isn't straightforward
                // Since the attendee autosaving saves only the changed attendees, this leaves the door open for the problem of a failed attendee, 
                // then a different successful attendee on next request overwriting the previous failed id 
                // BUT, luckily the failed attendees seem to stay in the queue, and keep getting sent in every request until they are saved, which prevents this problem
                // and so this approach works...
                var failedToAutosaveEventAttendeeIds = [];
                var failedToAutosaveNewEventAttendeeIds = [];
                try {
                    for (var _j = tslib_1.__values(action.payload.attendeeCollectionPayload.Failed), _k = _j.next(); !_k.done; _k = _j.next()) {
                        var failedResponseAttendee = _k.value;
                        if (failedResponseAttendee.newEventAttendeeId > 0)
                            failedToAutosaveNewEventAttendeeIds.push(failedResponseAttendee.newEventAttendeeId);
                        else
                            failedToAutosaveEventAttendeeIds.push(failedResponseAttendee.eventAttendeeId);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_k && !_k.done && (_d = _j.return)) _d.call(_j);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                // TODO: Handle the situation where action.payload.attendeeCollectionPayload.Failed is not empty - what to do?
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, attendeeUpsertAdapter.upsertMany(action.payload.attendeeCollectionPayload.Succeeded, state.attendees), { apiState: ApiState.Success, dataState: DataState.Success, errorMessage: null }), savedInputAttendees: attendeeUpsertAdapter.upsertMany(savedInputAttendeesToUpsert, state.savedInputAttendees), savedInputNewIdMap: newMap, savedInputNewIdMapReverse: newMapReverse, inFlightAttendees: mixedAttendeeAdapter.removeAll(state.inFlightAttendees), failedToAutosaveEventAttendeeIds: failedToAutosaveEventAttendeeIds, failedToAutosaveNewEventAttendeeIds: failedToAutosaveNewEventAttendeeIds });
            }
        case EimActions.ActionTypes.EimApiSaveAttendeesFailure:
            {
                return tslib_1.__assign({}, state, { attendees: tslib_1.__assign({}, state.attendees, { apiState: ApiState.Error, dataState: DataState.Error, errorMessage: action.payload.errorMessage }), inFlightAttendees: mixedAttendeeAdapter.removeAll(state.inFlightAttendees) });
            }
        // ===============================================================================
        //                            Miscellaneous Actions
        // ===============================================================================
        case EimActions.ActionTypes.EimPageSelectTab:
            {
                return tslib_1.__assign({}, state, { selectedTab: action.payload.selectedTab });
            }
        // case AuthActions.AuthActionTypes.AuthEffectsClearAllData:
        // {
        //     return {
        //         ...initialState,
        //     };
        // }
        //-------------------------------------------------------------------------------------
        default:
            {
                return state;
            }
    }
}
// function updateAssignmentsEntity(eventItem: EventItem, eventAttendeeId: number | null, state: EventItemState): EventItemAssignmentEntity {
//     let newUnsavedAssignments: EventItemAssignmentEntity;
//     const existingEventItem = state.eventItems.entities[eventItem.id];
//     if (!!existingEventItem && existingEventItem.eventAttendeeId === eventAttendeeId) {
//         // Then this update is setting the eventAttendeeId back to its' original value,
//         // so no need to save, just remove it from the update assignments property if its been added
//         newUnsavedAssignments = unsavedAssignmentsAdapter.removeOne(eventItem.id, state.unsavedAssignments);
//     } else {
//         const assignmentToUpsert: EventItemAssignment = {eventItemId: eventItem.id, eventAttendeeId: eventAttendeeId};
//         newUnsavedAssignments = unsavedAssignmentsAdapter.upsertOne(assignmentToUpsert, state.unsavedAssignments);
//     }
//     if (unsavedEntitySelectors.selectTotal(newUnsavedAssignments) > 0) {
//         newUnsavedAssignments.apiState = ApiState.Initial;
//         // Don't change errorMessage (could still exist from previous failed request)
//     } else {
//         newUnsavedAssignments.apiState = ApiState.Success;
//         newUnsavedAssignments.errorMessage = null;
//     }
//     return newUnsavedAssignments;
// }
