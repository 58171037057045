import * as tslib_1 from "tslib";
import * as messageActions from '../actions/message.action';
import { userMessageEntityAdapter } from "../entity-adapters/message.entity";
import { cloneDeep as _cloneDeep } from "lodash";
export var initialState = {
    eventMessages: userMessageEntityAdapter.getInitialState({
    // Initialize extra properties here
    }),
    eventSentMessages: userMessageEntityAdapter.getInitialState({
    // Initialize extra properties here
    }),
    messageSummary: null,
    eventMessagesNew: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case messageActions.ActionTypes.MessageEffectsLoadEventMessagesSuccess:
            {
                return tslib_1.__assign({}, state, { eventMessages: userMessageEntityAdapter.addAll(action.payload.eventMessages, state.eventMessages) });
            }
        case messageActions.ActionTypes.MessageEffectsLoadEventMessagesFailure:
            {
                return tslib_1.__assign({}, state); // TODO: Implement somewhere to store error
            }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.MessageEffectsLoadEventSentMessagesSuccess:
            {
                return tslib_1.__assign({}, state, { eventSentMessages: userMessageEntityAdapter.addAll(action.payload.eventSentMessages, state.eventSentMessages) });
            }
        case messageActions.ActionTypes.MessageEffectsLoadEventSentMessagesFailure:
            {
                return tslib_1.__assign({}, state); // TODO: Implement somewhere to store error
            }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.LoadEventMessagesNewSuccess:
            {
                return tslib_1.__assign({}, state, { eventMessagesNew: action.payload.eventMessagesNew });
            }
        case messageActions.ActionTypes.LoadEventMessagesNewFailure:
            {
                return tslib_1.__assign({}, state); // TODO: Implement somewhere to store error
            }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.MessageEffectsDeleteMessageSuccess:
            {
                return tslib_1.__assign({}, state, { 
                    // Removing from both because not sure if this action was for a sent or received message, but the IdUserMessage should be unique across both
                    eventMessages: userMessageEntityAdapter.removeOne(action.payload.idUserMessage, state.eventMessages), eventSentMessages: userMessageEntityAdapter.removeOne(action.payload.idUserMessage, state.eventSentMessages) });
            }
        case messageActions.ActionTypes.MessageEffectsDeleteMessageFailure:
            {
                return tslib_1.__assign({}, state); // TODO: Implement somewhere to store error
            }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.MessageEffectsMarkMessageAsReadSuccess:
            {
                var clonedMessage = _cloneDeep(action.payload.message);
                clonedMessage.State = 'Read';
                clonedMessage.CombinedFromDate.State = 'Read';
                clonedMessage.CombinedFromSubject.State = 'Read';
                clonedMessage.JsonMessage.State = 'Read';
                var clonedMessageUpdate = {
                    id: action.payload.message.IdUserMessage.toString(),
                    changes: clonedMessage,
                };
                return tslib_1.__assign({}, state, { 
                    // Updating both because not sure if this action was for a sent or received message, but the IdUserMessage should be unique across both
                    eventMessages: userMessageEntityAdapter.updateOne(clonedMessageUpdate, state.eventMessages), eventSentMessages: userMessageEntityAdapter.updateOne(clonedMessageUpdate, state.eventSentMessages) });
            }
        case messageActions.ActionTypes.MessageEffectsMarkMessageAsReadFailure:
            {
                return tslib_1.__assign({}, state); // TODO: Implement somewhere to store error
            }
        //---------------------------------------------------------------------
        case messageActions.ActionTypes.AuthenticationServiceLogout:
            {
                return tslib_1.__assign({}, initialState);
            }
        default:
            {
                return state;
            }
    }
}
