
import {tap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { URLConfig } from 'app/pages/helpers/config.service';
import { QuestionBase } from 'app/pages/surveys/survey/components/survey-body/questions/question-bases/question-base';
import { FreeFormQuestion } from 'app/pages/surveys/survey/components/survey-body/questions/question-bases/freeForm';
import { RadioQuestion } from 'app/pages/surveys/survey/components/survey-body/questions/question-bases/radio';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { Question, ResponseForm, Survey } from 'app/store/models/survey';
import * as fromRoot from 'app/store/reducers';
import { HttpClient } from '@angular/common/http';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';


@Injectable()
export class SurveysService {
    constructor(
        private http: HttpClient,
        private urls: URLConfig,
        private store: Store<fromRoot.State>
    ) {}

    // TODO: The methods here should be used in ngrx flow, currently they are called directly in components

    public getSurveys(packageId: number): Observable<Survey[]> {
        return this.http.get<GTResponse<Survey[]>>(this.urls._getSurveys(packageId)).pipe(
            handleGTResponse(),
        );
    }

    public hasResponded(surveyId: number): Observable<boolean> {
        return this.http.get<GTResponse<boolean>>(this.urls._hasResponded(surveyId)).pipe(
            handleGTResponse(),
        );
    }

    public getSurveyDetails(surveyID: number): Observable<Survey> {
        return this.http.get<GTResponse<Survey>>(this.urls._getSurveyDetails(surveyID)).pipe(
            handleGTResponse(),
        );
    }

    public createSurvey(survey: Survey): Observable<Survey> {
        return this.http.post<GTResponse<Survey>>(this.urls._createSurveyForPackage(), survey).pipe(
            handleGTResponse(),
        );
    }

    public answerSurvey(surveyResponse: ResponseForm): Observable<ResponseForm> {
        return this.http.post<GTResponse<ResponseForm>>(this.urls._answerSurvey(), surveyResponse).pipe(
            handleGTResponse(),
        );
    }

    public formatSurveyQuestions(questions: Array<Question>): QuestionBase<any>[] {
        let formattedQuestions:  QuestionBase<any>[] = [];
        questions.forEach(question => {
            if(question.questionType === 'freeForm') {
                formattedQuestions.push(new FreeFormQuestion(question))
            }
            if(question.questionType === 'radio') {
                formattedQuestions.push(new RadioQuestion(question))
            }
            if(question.questionType === 'show') {
                formattedQuestions.push(new RadioQuestion(question))
            }
            if (question.questionType === 'workshop') {
                formattedQuestions.push(new RadioQuestion(question))
            }
        })
        return formattedQuestions.sort((a, b) => a.questionOrder - b.questionOrder);
    }

    // TEMP: TODO add this to the store with refactor
    // tslint:disable-next-line:member-ordering
    public creatingSurvey: boolean = false;
}
