import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Config from './assets/config/config.json';
import 'hammerjs';

if(Config.production === "true") {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
    preserveWhitespaces: true
   });
