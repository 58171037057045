import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PaymentsService } from 'app/pages/payments/payments.service';
import * as PaymentActions from '../actions/payment.action';
import { map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PackageDetailsService } from 'app/pages/package-details/package-details.service';


@Injectable()
export class PaymentEffects {

    @Effect()
    loadEventPayments = this.actions$.pipe(
        ofType<
            PaymentActions.PackageDetailsEffectsLoadEventPayments
            | PaymentActions.PaymentsComponentLoadEventPayments
            | PaymentActions.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData
            | PaymentActions.PaymentsFromAttendeesComponentReloadEventPaymentsData
            | PaymentActions.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData
            >(
            PaymentActions.ActionTypes.PackageDetailsEffectsLoadEventPayments
            , PaymentActions.ActionTypes.PaymentsComponentLoadEventPayments
            , PaymentActions.ActionTypes.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData
            , PaymentActions.ActionTypes.PaymentsFromAttendeesComponentReloadEventPaymentsData
            , PaymentActions.ActionTypes.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData
            ), // TODO: Should the below be this.paymentsService.getPayments...
        concatMap(action => this.packageDetailsService.getPayments(action.payload.eventId).pipe(
            map(separatedPayments => new PaymentActions.PaymentEffectLoadEventPaymentsSuccess({separatedPayments})),
            catchError(error => of(new PaymentActions.PaymentEffectLoadEventPaymentsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadPaymentsReceivedSummary = this.actions$.pipe(
        ofType<
            PaymentActions.PackageDetailsEffectsLoadPaymentsReceivedSummary
            | PaymentActions.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData
            | PaymentActions.PaymentsFromAttendeesComponentReloadEventPaymentsData
            | PaymentActions.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData
            >(
            PaymentActions.ActionTypes.PackageDetailsEffectsLoadPaymentsReceivedSummary
            , PaymentActions.ActionTypes.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData
            , PaymentActions.ActionTypes.PaymentsFromAttendeesComponentReloadEventPaymentsData
            , PaymentActions.ActionTypes.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData
            ),
        concatMap(action => this.packageDetailsService.GetPaymentsReceivedSummary(action.payload.eventId).pipe(
            map(paymentsReceivedSummary => new PaymentActions.PaymentEffectLoadPaymentsReceivedSummarySuccess({paymentsReceivedSummary})),
            catchError(error => of(new PaymentActions.PaymentEffectLoadPaymentsReceivedSummaryFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadPaymentsSentSummary = this.actions$.pipe(
        ofType<PaymentActions.PackageDetailsEffectsLoadPaymentsSentSummary>(PaymentActions.ActionTypes.PackageDetailsEffectsLoadPaymentsSentSummary),
        concatMap(action => this.packageDetailsService.GetPaymentsSentSummary(action.payload.eventId).pipe(
            map(paymentsSentSummary => new PaymentActions.PaymentEffectLoadPaymentsSentSummarySuccess({paymentsSentSummary})),
            catchError(error => of(new PaymentActions.PaymentEffectLoadPaymentsSentSummaryFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadEventPaymentsNew = this.actions$.pipe(
        ofType<PaymentActions.GLEDComponentLoadEventPaymentsNew>(PaymentActions.ActionTypes.GLEDComponentLoadEventPaymentsNew),
        concatMap(action => this.paymentService.getPaymentsNew(action.payload.eventId).pipe(
            map(payments => new PaymentActions.PaymentEffectLoadEventPaymentsNewSuccess({payments})),
            catchError(error => of(new PaymentActions.PaymentEffectLoadEventPaymentsNewFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    makeSupplierPayment = this.actions$.pipe(
        ofType<PaymentActions.ATEDComponentMakeSupplierPayment>(PaymentActions.ActionTypes.ATEDComponentMakeSupplierPayment),
        concatMap(action => this.paymentService.makeSupplierPayment(action.payload.makePaymentRequest).pipe(
            map(response => new PaymentActions.ATEDComponentMakeSupplierPaymentSuccess({makePaymentResponse: response})),
            catchError(error => of(new PaymentActions.ATEDComponentMakeSupplierPaymentFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    addAndAssociateCustomer = this.actions$.pipe(
        ofType<PaymentActions.ATEDComponentAddAndAssociateCustomer>(PaymentActions.ActionTypes.ATEDComponentAddAndAssociateCustomer),
        concatMap(action => this.paymentService.addAndAssociateCustomer(action.payload.addAndAssociateCustomerRequest).pipe(
            map(response => new PaymentActions.ATEDComponentAddAndAssociateCustomerSuccess({addAndAssociateCustomerResponse: response})),
            catchError(error => of(new PaymentActions.ATEDComponentAddAndAssociateCustomerFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    makeSupplierGLPayment = this.actions$.pipe(
        ofType<PaymentActions.GLEDComponentMakeSupplierGLPayment>(PaymentActions.ActionTypes.GLEDComponentMakeSupplierGLPayment),
        concatMap(action => this.paymentService.makeSupplierGLPayment(action.payload.makePaymentRequest).pipe(
            map(response => new PaymentActions.GLEDComponentMakeSupplierGLPaymentSuccess({makePaymentResponse: response})),
            catchError(error => of(new PaymentActions.GLEDComponentMakeSupplierGLPaymentFailure({errorMessage: error.toString()}))),
        )),
    );


    constructor(
        private actions$: Actions,
        private paymentService: PaymentsService,
        private packageDetailsService: PackageDetailsService,
    ) {}
}