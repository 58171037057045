import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { isNumber, toInteger, padNumber } from 'app/store/models/util';
var NgbDateCustomParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateCustomParserFormatter, _super);
    function NgbDateCustomParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateCustomParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { month: toInteger(dateParts[0]), day: null, year: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { month: toInteger(dateParts[0]), day: toInteger(dateParts[1]), year: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { month: toInteger(dateParts[0]), day: toInteger(dateParts[1]), year: dateParts[2].length === 2 ? toInteger(dateParts[2]) + 2000 : toInteger(dateParts[2]) };
            }
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.format = function (date) {
        return date ?
            (isNumber(date.month) ? padNumber(date.month) : '') + "/" + (isNumber(date.day) ? padNumber(date.day) : '') + "/" + date.year :
            '';
    };
    return NgbDateCustomParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatter };
