import * as eventActions from '../actions/event.action';
import { Event } from '../models/event';

export type State = Event[];

// initialize state to an empty array
export function reducer(state: State = [], action: eventActions.Actions): State {
    switch (action.type) {
        case eventActions.LOAD_INVITED_EVENTS_OK:
        {
            return action.payload;
        }
        default:
        {
            return state;
        }
    }
}

