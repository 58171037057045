export var ActionTypes;
(function (ActionTypes) {
    // These three are caught for both LoadEventPayments and LoadPaymentsReceivedSummary
    ActionTypes["PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData"] = "[Payments From Attendees Summary Component] Reload Event Payments Data";
    ActionTypes["PaymentsFromAttendeesComponentReloadEventPaymentsData"] = "[Payments From Attendees Component] Reload Event Payments Data";
    ActionTypes["PaymentsToSuppliersSummaryComponentReloadEventPaymentsData"] = "[Payments To Suppliers Component] Reload Event Payments Data";
    ActionTypes["PaymentsComponentLoadEventPayments"] = "[Payments Component] Load Event Payments";
    ActionTypes["PackageDetailsEffectsLoadEventPayments"] = "[Package Details Effects] Load Event Payments";
    ActionTypes["PaymentEffectLoadEventPaymentsSuccess"] = "[Payment Effect] Load Event Payments Success";
    ActionTypes["PaymentEffectLoadEventPaymentsFailure"] = "[Payment Effect] Load Event Payments Failure";
    ActionTypes["PackageDetailsEffectsLoadPaymentsReceivedSummary"] = "[Package Details Effect] Load Payments Received Summary";
    ActionTypes["PaymentEffectLoadPaymentsReceivedSummarySuccess"] = "[Payment Effect] Load Payments Received Summary Success";
    ActionTypes["PaymentEffectLoadPaymentsReceivedSummaryFailure"] = "[Payment Effect] Load Payments Received Summary Failure";
    ActionTypes["PackageDetailsEffectsLoadPaymentsSentSummary"] = "[Package Details Effect] Load Payments Sent Summary";
    ActionTypes["PaymentEffectLoadPaymentsSentSummarySuccess"] = "[Payment Effect] Load Payments Sent Summary Success";
    ActionTypes["PaymentEffectLoadPaymentsSentSummaryFailure"] = "[Payment Effect] Load Payments Sent Summary Failure";
    //-------------------------------------------------------------------------------------
    // This is the newer payment endpoint that is (so far) only used on the GLED page
    ActionTypes["GLEDComponentLoadEventPaymentsNew"] = "[GLED Component] Load Event Payments New";
    ActionTypes["PaymentEffectLoadEventPaymentsNewSuccess"] = "[Payment Effect] Load Event Payments New Success";
    ActionTypes["PaymentEffectLoadEventPaymentsNewFailure"] = "[Payment Effect] Load Event Payments New Failure";
    //--------------------------------------------------------------------------------------
    // Supplier Payments Actions
    ActionTypes["ATEDComponentMakeSupplierPayment"] = "[ATED Component] Make Supplier Payment";
    ActionTypes["ATEDComponentMakeSupplierPaymentSuccess"] = "[Payment Effect] Make Supplier Payment Success";
    ActionTypes["ATEDComponentMakeSupplierPaymentFailure"] = "[Payment Effect] Make Supplier Payment Failure";
    ActionTypes["ATEDComponentAddAndAssociateCustomer"] = "[ATED Component] Add And Associate Customer";
    ActionTypes["ATEDComponentAddAndAssociateCustomerSuccess"] = "[Payment Effect] Add And Associate Customer Success";
    ActionTypes["ATEDComponentAddAndAssociateCustomerFailure"] = "[Payment Effect] Add And Associate Customer Failure";
    ActionTypes["GLEDComponentMakeSupplierGLPayment"] = "[GLED Component] Make Supplier GL Payment";
    ActionTypes["GLEDComponentMakeSupplierGLPaymentSuccess"] = "[Payment Effect] Make Supplier GL Payment Success";
    ActionTypes["GLEDComponentMakeSupplierGLPaymentFailure"] = "[Payment Effect] Make Supplier GL Payment Failure";
    ActionTypes["AuthenticationServiceLogout"] = "[Authentication Service] Logout";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData = /** @class */ (function () {
    function PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData;
    }
    return PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData;
}());
export { PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData };
var PaymentsFromAttendeesComponentReloadEventPaymentsData = /** @class */ (function () {
    function PaymentsFromAttendeesComponentReloadEventPaymentsData(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentsFromAttendeesComponentReloadEventPaymentsData;
    }
    return PaymentsFromAttendeesComponentReloadEventPaymentsData;
}());
export { PaymentsFromAttendeesComponentReloadEventPaymentsData };
var PaymentsToSuppliersSummaryComponentReloadEventPaymentsData = /** @class */ (function () {
    function PaymentsToSuppliersSummaryComponentReloadEventPaymentsData(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData;
    }
    return PaymentsToSuppliersSummaryComponentReloadEventPaymentsData;
}());
export { PaymentsToSuppliersSummaryComponentReloadEventPaymentsData };
//----------------------------------------------------------------
var PaymentsComponentLoadEventPayments = /** @class */ (function () {
    function PaymentsComponentLoadEventPayments(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentsComponentLoadEventPayments;
    }
    return PaymentsComponentLoadEventPayments;
}());
export { PaymentsComponentLoadEventPayments };
var PackageDetailsEffectsLoadEventPayments = /** @class */ (function () {
    function PackageDetailsEffectsLoadEventPayments(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsEffectsLoadEventPayments;
    }
    return PackageDetailsEffectsLoadEventPayments;
}());
export { PackageDetailsEffectsLoadEventPayments };
var PaymentEffectLoadEventPaymentsSuccess = /** @class */ (function () {
    function PaymentEffectLoadEventPaymentsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadEventPaymentsSuccess;
    }
    return PaymentEffectLoadEventPaymentsSuccess;
}());
export { PaymentEffectLoadEventPaymentsSuccess };
var PaymentEffectLoadEventPaymentsFailure = /** @class */ (function () {
    function PaymentEffectLoadEventPaymentsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadEventPaymentsFailure;
    }
    return PaymentEffectLoadEventPaymentsFailure;
}());
export { PaymentEffectLoadEventPaymentsFailure };
//-----------------------------------------------------------------
var PackageDetailsEffectsLoadPaymentsReceivedSummary = /** @class */ (function () {
    function PackageDetailsEffectsLoadPaymentsReceivedSummary(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsEffectsLoadPaymentsReceivedSummary;
    }
    return PackageDetailsEffectsLoadPaymentsReceivedSummary;
}());
export { PackageDetailsEffectsLoadPaymentsReceivedSummary };
var PaymentEffectLoadPaymentsReceivedSummarySuccess = /** @class */ (function () {
    function PaymentEffectLoadPaymentsReceivedSummarySuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadPaymentsReceivedSummarySuccess;
    }
    return PaymentEffectLoadPaymentsReceivedSummarySuccess;
}());
export { PaymentEffectLoadPaymentsReceivedSummarySuccess };
var PaymentEffectLoadPaymentsReceivedSummaryFailure = /** @class */ (function () {
    function PaymentEffectLoadPaymentsReceivedSummaryFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadPaymentsReceivedSummaryFailure;
    }
    return PaymentEffectLoadPaymentsReceivedSummaryFailure;
}());
export { PaymentEffectLoadPaymentsReceivedSummaryFailure };
//-----------------------------------------------------------------
var PackageDetailsEffectsLoadPaymentsSentSummary = /** @class */ (function () {
    function PackageDetailsEffectsLoadPaymentsSentSummary(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsEffectsLoadPaymentsSentSummary;
    }
    return PackageDetailsEffectsLoadPaymentsSentSummary;
}());
export { PackageDetailsEffectsLoadPaymentsSentSummary };
var PaymentEffectLoadPaymentsSentSummarySuccess = /** @class */ (function () {
    function PaymentEffectLoadPaymentsSentSummarySuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadPaymentsSentSummarySuccess;
    }
    return PaymentEffectLoadPaymentsSentSummarySuccess;
}());
export { PaymentEffectLoadPaymentsSentSummarySuccess };
var PaymentEffectLoadPaymentsSentSummaryFailure = /** @class */ (function () {
    function PaymentEffectLoadPaymentsSentSummaryFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadPaymentsSentSummaryFailure;
    }
    return PaymentEffectLoadPaymentsSentSummaryFailure;
}());
export { PaymentEffectLoadPaymentsSentSummaryFailure };
//----------------------------------------------------------------
var GLEDComponentLoadEventPaymentsNew = /** @class */ (function () {
    function GLEDComponentLoadEventPaymentsNew(payload) {
        this.payload = payload;
        this.type = ActionTypes.GLEDComponentLoadEventPaymentsNew;
    }
    return GLEDComponentLoadEventPaymentsNew;
}());
export { GLEDComponentLoadEventPaymentsNew };
var PaymentEffectLoadEventPaymentsNewSuccess = /** @class */ (function () {
    function PaymentEffectLoadEventPaymentsNewSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadEventPaymentsNewSuccess;
    }
    return PaymentEffectLoadEventPaymentsNewSuccess;
}());
export { PaymentEffectLoadEventPaymentsNewSuccess };
var PaymentEffectLoadEventPaymentsNewFailure = /** @class */ (function () {
    function PaymentEffectLoadEventPaymentsNewFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PaymentEffectLoadEventPaymentsNewFailure;
    }
    return PaymentEffectLoadEventPaymentsNewFailure;
}());
export { PaymentEffectLoadEventPaymentsNewFailure };
//----------------------------------------------------------------
var ATEDComponentMakeSupplierPayment = /** @class */ (function () {
    function ATEDComponentMakeSupplierPayment(payload) {
        this.payload = payload;
        this.type = ActionTypes.ATEDComponentMakeSupplierPayment;
    }
    return ATEDComponentMakeSupplierPayment;
}());
export { ATEDComponentMakeSupplierPayment };
var ATEDComponentMakeSupplierPaymentSuccess = /** @class */ (function () {
    function ATEDComponentMakeSupplierPaymentSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ATEDComponentMakeSupplierPaymentSuccess;
    }
    return ATEDComponentMakeSupplierPaymentSuccess;
}());
export { ATEDComponentMakeSupplierPaymentSuccess };
var ATEDComponentMakeSupplierPaymentFailure = /** @class */ (function () {
    function ATEDComponentMakeSupplierPaymentFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ATEDComponentMakeSupplierPaymentFailure;
    }
    return ATEDComponentMakeSupplierPaymentFailure;
}());
export { ATEDComponentMakeSupplierPaymentFailure };
//----------------------------------------------------------------
var ATEDComponentAddAndAssociateCustomer = /** @class */ (function () {
    function ATEDComponentAddAndAssociateCustomer(payload) {
        this.payload = payload;
        this.type = ActionTypes.ATEDComponentAddAndAssociateCustomer;
    }
    return ATEDComponentAddAndAssociateCustomer;
}());
export { ATEDComponentAddAndAssociateCustomer };
var ATEDComponentAddAndAssociateCustomerSuccess = /** @class */ (function () {
    function ATEDComponentAddAndAssociateCustomerSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ATEDComponentAddAndAssociateCustomerSuccess;
    }
    return ATEDComponentAddAndAssociateCustomerSuccess;
}());
export { ATEDComponentAddAndAssociateCustomerSuccess };
var ATEDComponentAddAndAssociateCustomerFailure = /** @class */ (function () {
    function ATEDComponentAddAndAssociateCustomerFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ATEDComponentAddAndAssociateCustomerFailure;
    }
    return ATEDComponentAddAndAssociateCustomerFailure;
}());
export { ATEDComponentAddAndAssociateCustomerFailure };
//----------------------------------------------------------------
var GLEDComponentMakeSupplierGLPayment = /** @class */ (function () {
    function GLEDComponentMakeSupplierGLPayment(payload) {
        this.payload = payload;
        this.type = ActionTypes.GLEDComponentMakeSupplierGLPayment;
    }
    return GLEDComponentMakeSupplierGLPayment;
}());
export { GLEDComponentMakeSupplierGLPayment };
var GLEDComponentMakeSupplierGLPaymentSuccess = /** @class */ (function () {
    function GLEDComponentMakeSupplierGLPaymentSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.GLEDComponentMakeSupplierGLPaymentSuccess;
    }
    return GLEDComponentMakeSupplierGLPaymentSuccess;
}());
export { GLEDComponentMakeSupplierGLPaymentSuccess };
var GLEDComponentMakeSupplierGLPaymentFailure = /** @class */ (function () {
    function GLEDComponentMakeSupplierGLPaymentFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.GLEDComponentMakeSupplierGLPaymentFailure;
    }
    return GLEDComponentMakeSupplierGLPaymentFailure;
}());
export { GLEDComponentMakeSupplierGLPaymentFailure };
//----------------------------------------------------------------
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = ActionTypes.AuthenticationServiceLogout;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
