<div [ngSwitch]="switchCase">
    <grouptools-success *ngSwitchCase="'createAccountSuccess'" [successHeading] ="'Success!'">
        <div class="register-success-contents">
            <div *ngIf='!isPackageInvite'>
                <p class="register-success-title">{{loginName}}, your account has been successfully created.</p>

                <p class="register-success-info">In order to use all of the features within group tools, you'll need to verify your email. Verification allows you to:</p>
                <ul class="register-success-info register-success-desc">
                    <li>Invite & communicate with attendees</li>
                    <li>Make payments</li>
                    <li>Receive payments</li>
                    <li>View important messages about events</li>
                    <li>And much more...</li>
                </ul>
            </div>

            <div *ngIf='isPackageInvite'>
                <h3>You've <span *ngIf="action === 'tentative'">tentatively accepted</span><span *ngIf="action === 'accept'">successfully accepted</span></h3>
                <p class="margin-more-bottom">Only verified accounts can add guests.  To verify, please select the Send Verification Code button below to proceed with the verification process.  Otherwise, click Skip this step & Continue to proceed to the Event Details Page.</p>
            </div>

            <div class="row">
                <button type="button" class="btn btn-primary form-control" (click)="Login()">Send Verification Code</button>
            </div>
            <div class="row">
                <button type="button" class="btn btn-secondary form-control register-success-button" (click)="skipVerification.emit()">Skip this step & Continue</button>
            </div>
        </div>
    </grouptools-success>

    <div *ngSwitchCase="'verifyAccount'">
        <div class="register-success-contents">
            <div class="verify-code-info">
                <p>Your verification code has been sent.</p>
                <p>Please check your email and enter the verification code below.</p>
            </div>
            
            <div class="row">
                <div class="form-group col-lg-7 offset-lg-3 col-md-8 offset-md-2 verify-code-input">
                    <label for="verifyCode">Verification Code:</label>
                    <input type="text" class="form-control" [(ngModel)]="verifyCode" id="verifyCode">
                    <label for="verifyCode" *ngIf="countDown > 0 && !enterOver" class="count-down-text">The code will be valid for 24 hours.</label>
                    <span></span>
                </div>
            </div>

            <label class="error-text" *ngIf="!verifyingAccount && verifyAccountClicked && !isVerified && countDown > 0 && !enterOver" class="error-text">The code you have entered is invalid. please try again</label>
            <label for="verifyCode" *ngIf="!verifyingAccount && (countDown <= 0 || enterOver)" class="error-text">The code you have received is no longer valid. <span class="link-color clickable" (click)="sendVerifyCode()">Click here</span><span style="color:#5f5f5f"> to send a new verification code.</span></label>

            <div class="row">
                <button type="button" class="btn btn-primary form-control verify-btn" (click)="verifyAccount()" [disabled]="!verifyingAccount && (countDown <= 0 || enterOver)">Verify my email</button>
            </div>
            <div class="row">
                <button type="button" class="btn btn-secondary form-control register-success-button" (click)="skipVerification.emit()">Cancel</button>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'groupleaderVerification'">
        <div class="register-success-contents">
            <p class="gl-verify-title">Please verify your email.</p>

            <p class="gl-verify-info">Verification allows you to:</p>
            <ul class="register-success-info register-success-desc">
                <li>Invite & communicate with attendees</li>
                <li>Make payments</li>
                <li>Receive payments</li>
                <li>View important messages about events</li>
                <li>And much more...</li>
            </ul>

            <div class="row">
                <button type="button" class="btn btn-primary form-control gl-verify-btn" (click)="sendVerificationCode()">Send Verification Code</button>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'attendeeVerification'">
        <div class="register-success-contents">
            <p class="register-success-title margin-more">To Invite Others, please verify your account.</p>

            <p class="gl-verify-info">Verification allows you to:</p>
            <ul class="register-success-info register-success-desc margin-more-bottom">
                <li>Invite others to the event</li>
                <li>Make payments</li>
                <li>View important messages about events</li>
            </ul>

            <div class="row">
                <button type="button" class="btn btn-primary form-control" (click)="sendVerificationCode()">Send Verification Code</button>
            </div>
            <div class="row">
                <button type="button" class="btn btn-secondary form-control register-success-button" (click)="skipVerification.emit()">Return to Event Details</button>
            </div>
        </div>
    </div>
</div>