
import {map} from 'rxjs/operators';
import { AmazonService } from './amazon.service';
import { LoginPerson, AuthService } from '../../authentication/authentication.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';

declare const amazon: any;

@Component({
    selector: 'amazon-login',
    templateUrl: 'amazon-login.component.html'
})
export class AmazonLoginComponent implements OnInit {
    @Output('loginComplete') public loginComplete: EventEmitter<any> = new EventEmitter<any>();

    public amazonButtonImage = require(`assets/buttons/btn-amazon.png`);

    private clientId = this.configService.config.amazonAppId;
    
    constructor(
        private _service: AmazonService,
        private configService: NgxConfigureService
    ) { }

    ngOnInit() {
        amazon.Login.setClientId(this.clientId);
    }

    amazonLogin() {
        let options = {
            scope: 'profile',
            response_type: 'code',
            state: 'test_state',
        };
        amazon.Login.authorize(options,
            (response) => this.onAmazonResponse(response)
        );

        return false;
    }

    onAmazonResponse(response: any): void {
        //console.log(response);
        if (!response) {
            //console.log('Amazon callback returned null value');
            return;
        }

        // if (response.status !== 'complete') {
        //     // error
        //     //console.log('Amazon callback returned an error');
        // }

        if (response.code) {
            this._service.GetAccessTokenFromCode(response.code).pipe(
            map(res => res.Obj))
            .subscribe((user) => {
                this.loginComplete.emit(user);

            });
        }
    }

}
