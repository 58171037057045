import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromActivity from '../actions/activity.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { map, switchMap, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
var ActivityEffects = /** @class */ (function () {
    function ActivityEffects(actions$, eventService) {
        var _this = this;
        this.actions$ = actions$;
        this.eventService = eventService;
        this.loadActivities = this.actions$
            .pipe(ofType(fromActivity.LOAD_SELECTED_ACTIVITIES), switchMap(function (action) { return _this.eventService.getActivities(action.payload)
            .pipe(map(function (activities) { return new fromActivity.LoadSelectedActivitiesOk(activities); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.createActivity = this.actions$
            .pipe(ofType(fromActivity.CREATE_ACTIVITY), exhaustMap(function (action) { return _this.eventService.createActivity(action.payload)
            .pipe(map(function (activity) { return new fromActivity.CreateActivityOk(activity); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.updateActivity = this.actions$
            .pipe(ofType(fromActivity.UPDATE_ACTIVITY), exhaustMap(function (action) { return _this.eventService.updateActivity(action.payload)
            .pipe(map(function (activity) { return new fromActivity.UpdateActivityOk(activity); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.deleteActivity = this.actions$
            .pipe(ofType(fromActivity.DELETE_ACTIVITY), exhaustMap(function (action) { return _this.eventService.deleteActivity(action.payload)
            .pipe(map(function (activity) { return new fromActivity.DeleteActivityOk(activity); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.createOption = this.actions$
            .pipe(ofType(fromActivity.CREATE_OPTION), exhaustMap(function (action) { return _this.eventService.createOption(action.payload)
            .pipe(map(function (option) { return new fromActivity.CreateOptionOk(option); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.updateOption = this.actions$
            .pipe(ofType(fromActivity.UPDATE_OPTION), exhaustMap(function (action) { return _this.eventService.updateOption(action.payload)
            .pipe(map(function (option) { return new fromActivity.UpdateOptionOk(option); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.deleteOption = this.actions$
            .pipe(ofType(fromActivity.DELETE_OPTION), exhaustMap(function (action) { return _this.eventService.deleteOption(action.payload)
            .pipe(map(function (option) { return new fromActivity.DeleteOptionOk(option); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.createActivityAndOptions = this.actions$
            .pipe(ofType(fromActivity.CREATE_ACTIVITY_AND_OPTIONS), exhaustMap(function (action) { return _this.eventService.createActivityAndOptions(action.payload)
            .pipe(map(function (activity) { return new fromActivity.CreateActivityAndOptionsOk(activity); }), 
        // TODO: Test the below catchError gets hit correctly.  this.eventService.createActivityAndOptions
        // doesn't call the http request directly, it has an upstream observable that does that,
        // need to be sure the error propogates through all the observables to here.
        catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "loadActivities", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "createActivity", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "updateActivity", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "deleteActivity", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "createOption", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "updateOption", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "deleteOption", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActivityEffects.prototype, "createActivityAndOptions", void 0);
    return ActivityEffects;
}());
export { ActivityEffects };
