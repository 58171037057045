import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiState } from 'app/store/models/apiData';
import { State as RootStoreState } from 'app/store/reducers';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import { CreateEventFromBibOrderRequest } from 'app/store/models/requests/create-event-from-bib-order-request';
import * as PackageDetailsActions from "app/store/actions/packageDetails.actions";

export interface CreateFromBibOrderDialogData {}
export interface CreateFromBibOrderDialogResult {}

@Component({
  selector: 'create-from-bib-order-dialog',
  templateUrl: './create-from-bib-order-dialog.component.html',
  styleUrls: ['./create-from-bib-order-dialog.component.scss']
})
export class CreateFromBibOrderDialogComponent implements OnInit {

  authApiState$: Observable<ApiState>;
  bibOrderNumberCreateEventApiState$: Observable<ApiState>;

  constructor(
    public dialogRef: MatDialogRef<CreateFromBibOrderDialogComponent, CreateFromBibOrderDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: CreateFromBibOrderDialogData,
    private store$: Store<RootStoreState>,    
  ) { }

  ngOnInit() {
    this.bibOrderNumberCreateEventApiState$ = this.store$.select(PackageDetailsSelectors.getBibOrderNumberCreateEventApiState);
  }

  showSelected() {}
  dateSelected() {}

  getStartedClicked(createEventFromBibOrderRequest: CreateEventFromBibOrderRequest) {
    this.store$.dispatch(new PackageDetailsActions.CreateEventFromBibOrderDialog({createEventFromBibOrderRequest}));
  }

}
