import * as tslib_1 from "tslib";
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromForm from '../actions/forms';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { AppStateService } from '../../pages/helpers/appState.service';
import { of } from 'rxjs';
var FormEffects = /** @class */ (function () {
    // constructor last to prevent member-ordering ts-lint warnings
    function FormEffects(actions$, service) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.loadCreatedForms = this.actions$
            .pipe(ofType(fromForm.LOAD_OWNED_FORMS), exhaustMap(function (action) { return _this.service.getOwnedForms()
            .pipe(map(function (forms) { return new fromForm.LoadOwnedFormsOk(forms); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadFormsForEvent = this.actions$
            .pipe(ofType(fromForm.LOAD_EVENT_FORMS), exhaustMap(function (action) { return _this.service.getFormsForEvent(action.payload)
            .pipe(map(function (forms) { return new fromForm.LoadEventFormsOk(forms); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FormEffects.prototype, "loadCreatedForms", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FormEffects.prototype, "loadFormsForEvent", void 0);
    return FormEffects;
}());
export { FormEffects };
