import { Action } from "@ngrx/store";
import { AttendeeDTO_Old } from "../models/packages/AttendeeDTO";
import { AttendeeSummaryDTO } from "../models/packages/AttendeeSummaryDTO";
import { LogEntry } from "../models/packages/LogEntry";
import { PackageComponentDTO } from "../models/packages/PackageComponentDTO";
import { PackageRecordDTO } from "../models/packages/PackageRecordDTO";
import { PaymentsReceivedSummaryDTO } from "../models/packages/PaymentsReceivedSummaryDTO";
import { PaymentsSentSummaryDTO } from "../models/packages/paymentsSentSummaryDTO";
import { SeparatedPaymentsDTO } from "../models/packages/SeparatedPaymentsDTO";
import { SimpleSupplierDTO } from "../models/packages/SimpleSupplierDTO";
import { SupplierDTO } from "../models/packages/SupplierDTO";
import { TagsDTO } from "../models/packages/TagsDTO";
import { CreateEventFromBibOrderRequest } from "../models/requests/create-event-from-bib-order-request";
import { CreateEventFromBibOrderResponse } from "../models/responses/create-event-from-bib-order-response";
import { InviteEventAttendeesRequest } from '../models/requests/invite-event-attendees-request';
import { InviteEventAttendeesResponse } from '../models/responses/invite-event-attendees-response';
import { GTCollectionPayload } from '../models/gtResponse';
import { CreateEventFromSupplierOrderRequest } from '../models/requests/create-event-from-supplier-order-request';
import { CreateEventFromSupplierOrderResponse } from '../models/responses/create-event-from-supplier-order-response';
import { UpdateEventFromSupplierOrderRequest } from "../models/requests/update-event-from-supplier-order-request";
import { UpdateEventFromSupplierOrderResponse } from "../models/responses/update-event-from-supplier-order-response";
import { LoadTicketsRequest } from "../models/requests/load-tickets-request";
import { LoadTicketsResponse } from "../models/responses/load-tickets-response";
import { PurchaseTicketsRequest } from "../models/requests/purchase-tickets-request";
import { PurchaseTicketsResponse } from "../models/responses/purchase-tickets-response";
import { UpdateEventOk } from "./event.action";

export enum ActionTypes {
    // TODO: Create separate actions file for component/page actions?
    PackageDetailsPageLoad = '[Package Details Page] Page Load',
    PackageDetailsPageGetPackageDetails = '[Package Details Page] Get Package Details',
    AttendeePackageDetailsPageLoad = '[Attendee Package Details Page] Page Load',
    InboxPageLoad = '[Inbox Page] Page Load',

    EventRelatedMessagesComponentLoadMessages = '[Event Related Messages Component] Load Messages',
    MessagesComponentUpdateSentMessages = '[Messages Component] Update Sent Messages',

    EventReportComponentPageLoad = '[Event Report Component] Page Load',

    // PDEffectsLoadSelectedEvent = '[Package Details Effect] Load Selected Event',
    // PDApiLoadSelectedEventSuccess = '[Package Details Api] Load Selected Event Success',
    // PDApiLoadSelectedEventFailure = '[Package Details Api] Load Selected Event Failure',

    // PDEffectsLoadLeaderSummary = '[Package Details Effect] Load Leader Summary',
    // PDApiLoadLeaderSummarySuccess = '[Package Details Api] Load Leader Summary Success',
    // PDApiLoadLeaderSummaryFailure = '[Package Details Api] Load Leader Summary Failure',

    // PDEffectsLoadAlternateLeaders = '[Package Details Effect] Load Alternate Leaders',
    // PDApiLoadAlternateLeadersSuccess = '[Package Details Api] Load Alternate Leaders Success',
    // PDApiLoadAlternateLeadersFailure = '[Package Details Api] Load Alternate Leaders Failure',

    // PDEffectsLoadGroupLeaderProfile = '[Package Details Effect] Load Group Leader Profile',
    // PDApiLoadGroupLeaderProfileSuccess = '[Package Details Api] Load Group Leader Profile Success',
    // PDApiLoadGroupLeaderProfileFailure = '[Package Details Api] Load Group Leader Profile Failure',

    // PDEffectsLoadSelectedActivities = '[Package Details Effect] Load Selected Activities',
    // PDApiLoadSelectedActivitiesSuccess = '[Package Details Api] Load Selected Activities Success',
    // PDApiLoadSelectedActivitiesFailure = '[Package Details Api] Load Selected Activities Failure',

    // PDEffectsLoadAttendees = '[Package Details Effect] Load Attendees',
    // PDApiLoadAttendeesSuccess = '[Package Details Api] Load Attendees Success',
    // PDApiLoadAttendeesFailure = '[Package Details Api] Load Attendees Failure',

    // PDEffectsLoadEventContacts = '[Package Details Effect] Load Event Contacts',
    // PDApiLoadEventContactsSuccess = '[Package Details Api] Load Event Contacts Success',
    // PDApiLoadEventContactsFailure = '[Package Details Api] Load Event Contacts Failure',

    PDEffectsLoadPackageDetails = '[Package Details Effect] Load Package Details',
    PDApiLoadPackageDetailsSuccess = '[Package Details Api] Load Package Details Success',
    PDApiLoadPackageDetailsFailure = '[Package Details Api] Load Package Details Failure',

    PDEffectsLoadTagInfo = '[Package Details Effect] Load Tag Info',
    PDApiLoadTagInfoSuccess = '[Package Details Api] Load Tag Info Success',
    PDApiLoadTagInfoFailure = '[Package Details Api] Load Tag Info Failure',

    PDEffectsLoadPackageComponents = '[Package Details Effect] Load Package Components',
    PDApiLoadPackageComponentsSuccess = '[Package Details Api] Load Package Components Success',
    PDApiLoadPackageComponentsFailure = '[Package Details Api] Load Package Components Failure',

    EventFeaturesViewComponentReloadPackageRecord = '[Event Features View Component] Reload Package Record',
    PDEffectsLoadPackageRecord = '[Package Details Effect] Load Package Record',
    PDApiLoadPackageRecordSuccess = '[Package Details Api] Load Package Record Success',
    PDApiLoadPackageRecordFailure = '[Package Details Api] Load Package Record Failure',

    PDEffectsLoadSuppliers = '[Package Details Effect] Load Suppliers',
    PDApiLoadSuppliersSuccess = '[Package Details Api] Load Suppliers Success',
    PDApiLoadSuppliersFailure = '[Package Details Api] Load Suppliers Failure',

    PDEffectsLoadPackageLogs = '[Package Details Effect] Load Package Logs',
    PDApiLoadPackageLogsSuccess = '[Package Details Api] Load Package Logs Success',
    PDApiLoadPackageLogsFailure = '[Package Details Api] Load Package Logs Failure',

    PDEffectsLoadSuppliersForPackage = '[Package Details Effect] Load Suppliers For Package',
    PDApiLoadSuppliersForPackageSuccess = '[Package Details Api] Load Suppliers For Package Success',
    PDApiLoadSuppliersForPackageFailure = '[Package Details Api] Load Suppliers For Package Failure',

    PDEffectsLoadSurveys = '[Package Details Effect] Load Surveys',

    CreateEventFromBibOrderDialog = '[Create Event From Bib Order Dialog] Create Event From Bib Order',
    PDApiCreateEventFromBibOrderSuccess = '[Package Details Api] Create Event From Bib Order Success',
    PDApiCreateEventFromBibOrderFailure = '[Package Details Api] Create Event From Bib Order Failure',

    CreateEventFromSupplierOrderDialog = '[Create Event From AV Order Dialog] Create Event From AV Order',
    PDApiCreateEventFromSupplierOrderSuccess = '[Package Details Api] Create Event From AV Order Success',
    PDApiCreateEventFromSupplierOrderFailure = '[Package Details Api] Create Event From AV Order Failure',

    UpdateEventFromSupplierOrder = '[Update Event From AV Order] Update Event From AV Order',
    PDApiUpdateEventFromSupplierOrderSuccess = '[Package Details Api] Update Event From AV Order Success',
    PDApiUpdateEventFromSupplierOrderFailure = '[Package Details Api] Update Event From AV Order Failure',

    LoadTickets = '[Load Tickets] Load Tickets',
    PDApiLoadTicketsSuccess = '[Package Details Api] Load Tickets Success',
    PDApiLoadTicketsFailure = '[Package Details Api] Load Tickets Failure',

    PurchaseTickets = '[Purchase Tickets] Purchase Tickets',
    PDApiPurchaseTicketsSuccess = '[Package Details Api] Purchase Tickets Success',
    PDApiPurchaseTicketsFailure = '[Package Details Api] Purchase Tickets Failure',

    UpdateEventOk = '[Event] Update Event Ok',

    // PDEffectsLoadReplaceMe = '[Package Details Effect] Load ReplaceMe',
    // PDApiLoadReplaceMeSuccess = '[Package Details Api] Load ReplaceMe Success',
    // PDApiLoadReplaceMeFailure = '[Package Details Api] Load ReplaceMe Failure',

    AuthenticationServiceLogout = "[Authentication Service] Logout",
}

//-----------------------------------------------------------------
// export class PDEffectsLoadReplaceMe implements Action {
//     readonly type = ActionTypes.PDEffectsLoadReplaceMe;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadReplaceMeSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadReplaceMeSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadReplaceMeFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadReplaceMeFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
//-----------------------------------------------------------------
export class PackageDetailsPageLoad implements Action {
    readonly type = ActionTypes.PackageDetailsPageLoad;
    constructor(public payload: {eventId: number}) {}
}
export class PackageDetailsPageGetPackageDetails implements Action {
    readonly type = ActionTypes.PackageDetailsPageGetPackageDetails;
    constructor(public payload: {eventId: number}) {}
}
export class AttendeePackageDetailsPageLoad implements Action {
    readonly type = ActionTypes.AttendeePackageDetailsPageLoad;
    constructor(public payload: {eventId: number}) {}
}
export class InboxPageLoad implements Action {
    readonly type = ActionTypes.InboxPageLoad;
    constructor() {}
}
//-----------------------------------------------------------------
export class EventRelatedMessagesComponentLoadMessages implements Action {
    readonly type = ActionTypes.EventRelatedMessagesComponentLoadMessages;
    constructor(public payload: {eventId: number}) {}
}
export class MessagesComponentUpdateSentMessages implements Action {
    readonly type = ActionTypes.MessagesComponentUpdateSentMessages;
    constructor(public payload: {eventId: number | null}) {}
}
//-----------------------------------------------------------------
export class EventReportComponentPageLoad implements Action {
    readonly type = ActionTypes.EventReportComponentPageLoad;
    constructor(public payload: {eventId: number}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadPackageDetails implements Action {
    readonly type = ActionTypes.PDEffectsLoadPackageDetails;
    constructor(public payload: {eventId: number}) {}
}
export class PDApiLoadPackageDetailsSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadPackageDetailsSuccess;
    constructor(public payload: {packageDetails: any}) {}
}
export class PDApiLoadPackageDetailsFailure implements Action {
    readonly type = ActionTypes.PDApiLoadPackageDetailsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadTagInfo implements Action {
    readonly type = ActionTypes.PDEffectsLoadTagInfo;
    constructor(public payload: {eventId: number}) {}
}
export class PDApiLoadTagInfoSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadTagInfoSuccess;
    constructor(public payload: {tagInfo: TagsDTO}) {}
}
export class PDApiLoadTagInfoFailure implements Action {
    readonly type = ActionTypes.PDApiLoadTagInfoFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadPackageComponents implements Action {
    readonly type = ActionTypes.PDEffectsLoadPackageComponents;
    constructor(public payload: {eventId: number}) {}
}
export class PDApiLoadPackageComponentsSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadPackageComponentsSuccess;
    constructor(public payload: {components: PackageComponentDTO[]}) {}
}
export class PDApiLoadPackageComponentsFailure implements Action {
    readonly type = ActionTypes.PDApiLoadPackageComponentsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class EventFeaturesViewComponentReloadPackageRecord implements Action {
    readonly type = ActionTypes.EventFeaturesViewComponentReloadPackageRecord;
    constructor(public payload: {eventId: number}) {}
}
export class PDEffectsLoadPackageRecord implements Action {
    readonly type = ActionTypes.PDEffectsLoadPackageRecord;
    constructor(public payload: {eventId: number}) {}
}
export class PDApiLoadPackageRecordSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadPackageRecordSuccess;
    constructor(public payload: {packageRecord: PackageRecordDTO}) {}
}
export class PDApiLoadPackageRecordFailure implements Action {
    readonly type = ActionTypes.PDApiLoadPackageRecordFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadSuppliers implements Action {
    readonly type = ActionTypes.PDEffectsLoadSuppliers;
    constructor() {}
}
export class PDApiLoadSuppliersSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadSuppliersSuccess;
    constructor(public payload: {suppliers: SupplierDTO[]}) {}
}
export class PDApiLoadSuppliersFailure implements Action {
    readonly type = ActionTypes.PDApiLoadSuppliersFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadPackageLogs implements Action {
    readonly type = ActionTypes.PDEffectsLoadPackageLogs;
    constructor(public payload: {eventId: number}) {}
}
export class PDApiLoadPackageLogsSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadPackageLogsSuccess;
    constructor(public payload: {packageLogs: LogEntry[]}) {}
}
export class PDApiLoadPackageLogsFailure implements Action {
    readonly type = ActionTypes.PDApiLoadPackageLogsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadSuppliersForPackage implements Action {
    readonly type = ActionTypes.PDEffectsLoadSuppliersForPackage;
    constructor(public payload: {eventId: number}) {}
}
export class PDApiLoadSuppliersForPackageSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadSuppliersForPackageSuccess;
    constructor(public payload: {packageSuppliersByActivity: SimpleSupplierDTO[]}) {}
}
export class PDApiLoadSuppliersForPackageFailure implements Action {
    readonly type = ActionTypes.PDApiLoadSuppliersForPackageFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PDEffectsLoadSurveys implements Action {
    readonly type = ActionTypes.PDEffectsLoadSurveys;
    constructor(public payload: {eventId: number}) {}
}
//-----------------------------------------------------------------
export class CreateEventFromBibOrderDialog implements Action {
    readonly type = ActionTypes.CreateEventFromBibOrderDialog;
    constructor(public payload: {createEventFromBibOrderRequest: CreateEventFromBibOrderRequest}) {}
}
export class PDApiCreateEventFromBibOrderSuccess implements Action {
    readonly type = ActionTypes.PDApiCreateEventFromBibOrderSuccess;
    constructor(public payload: {createEventFromBibOrderResponse: CreateEventFromBibOrderResponse}) {}
}
export class PDApiCreateEventFromBibOrderFailure implements Action {
    readonly type = ActionTypes.PDApiCreateEventFromBibOrderFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class CreateEventFromSupplierOrderDialog implements Action {
    readonly type = ActionTypes.CreateEventFromSupplierOrderDialog;
    constructor(public payload: {createEventFromSupplierOrderRequest: CreateEventFromSupplierOrderRequest}) {}
}
export class PDApiCreateEventFromSupplierOrderSuccess implements Action {
    readonly type = ActionTypes.PDApiCreateEventFromSupplierOrderSuccess;
    constructor(public payload: {createEventFromSupplierOrderResponse: CreateEventFromSupplierOrderResponse}) {}
}
export class PDApiCreateEventFromSupplierOrderFailure implements Action {
    readonly type = ActionTypes.PDApiCreateEventFromSupplierOrderFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class UpdateEventFromSupplierOrder implements Action {
    readonly type = ActionTypes.UpdateEventFromSupplierOrder;
    constructor(public payload: {updateEventFromSupplierOrderRequest: UpdateEventFromSupplierOrderRequest}) {}
}
export class PDApiUpdateEventFromSupplierOrderSuccess implements Action {
    readonly type = ActionTypes.PDApiUpdateEventFromSupplierOrderSuccess;
    constructor(public payload: {updateEventFromSupplierOrderResponse: UpdateEventFromSupplierOrderResponse}) {}
}
export class PDApiUpdateEventFromSupplierOrderFailure implements Action {
    readonly type = ActionTypes.PDApiUpdateEventFromSupplierOrderFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class LoadTickets implements Action {
    readonly type = ActionTypes.LoadTickets;
    constructor(public payload: {loadTicketsRequest: LoadTicketsRequest}) {}
}
export class PDApiLoadTicketsSuccess implements Action {
    readonly type = ActionTypes.PDApiLoadTicketsSuccess;
    constructor(public payload: {loadTicketsResponse: LoadTicketsResponse}) {}
}
export class PDApiLoadTicketsFailure implements Action {
    readonly type = ActionTypes.PDApiLoadTicketsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//-----------------------------------------------------------------
export class PurchaseTickets implements Action {
    readonly type = ActionTypes.PurchaseTickets;
    constructor(public payload: {purchaseTicketsRequest: PurchaseTicketsRequest}) {}
}
export class PDApiPurchaseTicketsSuccess implements Action {
    readonly type = ActionTypes.PDApiPurchaseTicketsSuccess;
    constructor(public payload: {purchaseTicketsResponse: PurchaseTicketsResponse}) {}
}
export class PDApiPurchaseTicketsFailure implements Action {
    readonly type = ActionTypes.PDApiPurchaseTicketsFailure;
    constructor(public payload: {errorMessage: string}) {}
}

//-----------------------------------------------------------------
// export class PDEffectsLoadSelectedEvent implements Action {
//     readonly type = ActionTypes.PDEffectsLoadSelectedEvent;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedEventSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedEventSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedEventFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedEventFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadLeaderSummary implements Action {
//     readonly type = ActionTypes.PDEffectsLoadLeaderSummary;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadLeaderSummarySuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadLeaderSummarySuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadLeaderSummaryFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadLeaderSummaryFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadAlternateLeaders implements Action {
//     readonly type = ActionTypes.PDEffectsLoadAlternateLeaders;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAlternateLeadersSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadAlternateLeadersSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAlternateLeadersFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadAlternateLeadersFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadGroupLeaderProfile implements Action {
//     readonly type = ActionTypes.PDEffectsLoadGroupLeaderProfile;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadGroupLeaderProfileSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadGroupLeaderProfileSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadGroupLeaderProfileFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadGroupLeaderProfileFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadSelectedActivities implements Action {
//     readonly type = ActionTypes.PDEffectsLoadSelectedActivities;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedActivitiesSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedActivitiesSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadSelectedActivitiesFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadSelectedActivitiesFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadAttendees implements Action {
//     readonly type = ActionTypes.PDEffectsLoadAttendees;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAttendeesSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadAttendeesSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadAttendeesFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadAttendeesFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
// //-----------------------------------------------------------------
// export class PDEffectsLoadEventContacts implements Action {
//     readonly type = ActionTypes.PDEffectsLoadEventContacts;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadEventContactsSuccess implements Action {
//     readonly type = ActionTypes.PDApiLoadEventContactsSuccess;
//     constructor(public payload: {eventId: number}) {}
// }
// export class PDApiLoadEventContactsFailure implements Action {
//     readonly type = ActionTypes.PDApiLoadEventContactsFailure;
//     constructor(public payload: {errorMessage: string}) {}
// }
//-----------------------------------------------------------------
export class AuthenticationServiceLogout implements Action {
    readonly type = ActionTypes.AuthenticationServiceLogout;
    constructor() {}
}
//----------------------------------------------------------------

export type Actions
    = PackageDetailsPageLoad
    | PackageDetailsPageGetPackageDetails
    | AttendeePackageDetailsPageLoad
    | InboxPageLoad

    | EventRelatedMessagesComponentLoadMessages
    | MessagesComponentUpdateSentMessages

    | EventReportComponentPageLoad

    // | PDEffectsLoadSelectedEvent
    // | PDApiLoadSelectedEventSuccess
    // | PDApiLoadSelectedEventFailure

    // | PDEffectsLoadLeaderSummary
    // | PDApiLoadLeaderSummarySuccess
    // | PDApiLoadLeaderSummaryFailure

    // | PDEffectsLoadAlternateLeaders
    // | PDApiLoadAlternateLeadersSuccess
    // | PDApiLoadAlternateLeadersFailure

    // | PDEffectsLoadGroupLeaderProfile
    // | PDApiLoadGroupLeaderProfileSuccess
    // | PDApiLoadGroupLeaderProfileFailure

    // | PDEffectsLoadSelectedActivities
    // | PDApiLoadSelectedActivitiesSuccess
    // | PDApiLoadSelectedActivitiesFailure

    // | PDEffectsLoadAttendees
    // | PDApiLoadAttendeesSuccess
    // | PDApiLoadAttendeesFailure

    // | PDEffectsLoadEventContacts
    // | PDApiLoadEventContactsSuccess
    // | PDApiLoadEventContactsFailure

    | PDEffectsLoadPackageDetails
    | PDApiLoadPackageDetailsSuccess
    | PDApiLoadPackageDetailsFailure

    | PDEffectsLoadTagInfo
    | PDApiLoadTagInfoSuccess
    | PDApiLoadTagInfoFailure

    | PDEffectsLoadPackageComponents
    | PDApiLoadPackageComponentsSuccess
    | PDApiLoadPackageComponentsFailure

    | EventFeaturesViewComponentReloadPackageRecord
    | PDEffectsLoadPackageRecord
    | PDApiLoadPackageRecordSuccess
    | PDApiLoadPackageRecordFailure

    | PDEffectsLoadSuppliers
    | PDApiLoadSuppliersSuccess
    | PDApiLoadSuppliersFailure

    | PDEffectsLoadPackageLogs
    | PDApiLoadPackageLogsSuccess
    | PDApiLoadPackageLogsFailure

    | PDEffectsLoadSuppliersForPackage
    | PDApiLoadSuppliersForPackageSuccess
    | PDApiLoadSuppliersForPackageFailure

    | PDEffectsLoadSurveys

    | CreateEventFromBibOrderDialog
    | PDApiCreateEventFromBibOrderSuccess
    | PDApiCreateEventFromBibOrderFailure

    | CreateEventFromSupplierOrderDialog
    | PDApiCreateEventFromSupplierOrderSuccess
    | PDApiCreateEventFromSupplierOrderFailure

    | UpdateEventFromSupplierOrder
    | PDApiUpdateEventFromSupplierOrderSuccess
    | PDApiUpdateEventFromSupplierOrderFailure

    | LoadTickets
    | PDApiLoadTicketsSuccess
    | PDApiLoadTicketsFailure

    | PurchaseTickets
    | PDApiPurchaseTicketsSuccess
    | PDApiPurchaseTicketsFailure

    | UpdateEventOk

    // | PDEffectsLoadReplaceMe
    // | PDApiLoadReplaceMeSuccess
    // | PDApiLoadReplaceMeFailure

    | AuthenticationServiceLogout
;