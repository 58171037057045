import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as AttendeesActions from 'app/store/actions/attendees.action';
import { ModalSize } from 'app/store/models/modal';
import { CloseModal } from 'app/store/actions/modal.action';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
var UpdateAttendeeStatusComponent = /** @class */ (function () {
    function UpdateAttendeeStatusComponent(_store, actions$) {
        this._store = _store;
        this.actions$ = actions$;
        this.isLeader = false;
        this.isSupplierEvent = false;
        this.state = 'modal';
        this.unsubscribe$ = new Subject();
    }
    UpdateAttendeeStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.attendee) {
            this.status = this.attendee.Status;
        }
        this.userId$ = this._store.select(fromRoot.getUserId);
        this.userId$.subscribe(function (res) {
            _this.userId = res;
        });
    };
    UpdateAttendeeStatusComponent.prototype.update = function () {
        var _this = this;
        //this.attendee.Status = this.status;      
        var updatedAttendee = { eventId: this.attendee.IdPackage, id: this.userId, status: this.status };
        this._store.dispatch(new AttendeesActions.UpdateAttendeeStatusComponentUpdateAttendeeStatus({ attendee: updatedAttendee }));
        // TODO: Catching an action directly in a component is not a great pattern,
        //       On the other hand, this 'get once/next' behavior can be tricky to implement with selectors
        this.actions$.pipe(ofType(AttendeesActions.ActionTypes.AttendeeEffectUpdateAttendeeStatusSuccess), take(1)).subscribe(function (res) {
            _this.state = 'success';
        });
    };
    UpdateAttendeeStatusComponent.prototype.close = function () {
        this._store.dispatch(new CloseModal());
    };
    UpdateAttendeeStatusComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
    };
    return UpdateAttendeeStatusComponent;
}());
export { UpdateAttendeeStatusComponent };
export var UpdateAttendeeStatusConfig = {
    title: 'Attendee Status',
    size: ModalSize.Large,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: UpdateAttendeeStatusComponent
    }
};
