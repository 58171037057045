import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { Register } from '../register/register.component';
import { AccountVerificationComponent } from '../register/account-verification/account-verification.component';
import { RegisterPage } from './register-page.component';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { GoogleLoginModule } from 'app/pages/google-login/google-login.module';
import { FacebookLoginModule } from 'app/pages/facebook-login/facebook-login.module';
import { LoginPageModule } from '../loginPage/login-page.module';
import { AmazonLoginModule } from '../amazon-login/amazon-login.module';
import { LoginModule } from '../login/login.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule,
    WidgetsModule,
    GoogleLoginModule,
    FacebookLoginModule,
    LoginPageModule,
    AmazonLoginModule,
    LoginModule
  ],
  declarations: [
    RegisterPage,
    Register,
    AccountVerificationComponent
  ],
  exports: [
    RegisterPage,
    Register,
    AccountVerificationComponent
  ]
})
export class RegisterModule { }