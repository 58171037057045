import { CommonModule } from '@angular/common';

// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { PasswordResetComponent } from 'app/pages/password-reset/password-reset.component';
import { LoginModule } from 'app/pages/login/login.module';
import { RegisterModule } from 'app/pages/register-page/register-page.module';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { PasswordResetService } from './password-reset.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        LoginModule,
        RegisterModule,
        WidgetsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        PasswordResetComponent
    ],
    exports: [
        PasswordResetComponent
    ],
    providers: [
        PasswordResetService
    ]
})
export class PasswordResetModule {

}