import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import * as fromRoot from 'app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Contact } from 'app/store/models/contacts';
import { takeUntil, tap } from 'rxjs/operators';
import { Modal, ModalSize } from 'app/store/models/modal';
import { GrouptoolsListComponent, ListOptions } from 'app/widgets/grouptools-list/grouptools-list.component';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { Event } from 'app/store/models/event';
import * as modalActions from 'app/store/actions/modal.action';
import * as eventActions from 'app/store/actions/event.action';
import { GroupToolsSuccessModalConfig, GroupToolsSuccessModal } from '../grouptools-success-modal/grouptools-success-modal.component';
import { Profile } from 'app/store/models/profile';
import * as ContactsSelectors from 'app/store/selectors/contacts.selector';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'add-alternate-leader',
  templateUrl: './add-alternate-leader.component.html',
  styleUrls: ['./add-alternate-leader.component.scss']
})
export class AddAlternateLeaderComponent implements AfterViewInit, OnDestroy {

  public contact$: Observable<Contact[]>;
  public alternateLeaders$: Observable<Profile[]>;
  public alternateLeaders: Profile[];
  public selectedEvent$: Observable<Event>;
  public unsubscribe$: Subject<void> = new Subject<void>();
  public working: boolean = false;
  public pristine: boolean = true;

  public addGLForm: FormGroup;
  public formSubmitted: boolean = false;

  public NAME_REGX: string = "^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ \',.-]+$";
  public EMAIL_REGX: string = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

  @ViewChild('contactList') contactList: GrouptoolsListComponent;

  constructor(
    private store: Store<fromRoot.State>,
    private eventService: EventDetailsService,
    private fb: FormBuilder
  ) {
    this.contact$ = this.store.pipe(select(ContactsSelectors.getContacts));
    this.alternateLeaders$ = store.select(fromRoot.getAlternateLeaders).pipe(
      takeUntil(this.unsubscribe$),
      tap(leaders => this.alternateLeaders = leaders)
    );
    this.selectedEvent$ = store.select(fromRoot.getSelectedEvent).pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  public addGLFormErrors: any = {
    'FirstName': '',
    'LastName': '',
    'Email': ''
  };

  addGLFormValidationMessages = {
    'FirstName': {
        'pattern': 'First Name contains invalid characters.'
    },
    'LastName': {
        'pattern': 'Last Name contains invalid characters.'
    },
    'Email': {
        'required': 'Email is required.',
        'pattern': 'Invalid email.'
    }
  };

  addGLFormFields = {
    FirstName: [null, [Validators.pattern(this.NAME_REGX)]],
    LastName: [null, [Validators.pattern(this.NAME_REGX)]],
    Email: [null, [Validators.required, Validators.pattern(this.EMAIL_REGX)]],
  };

  ngOnInit(): void {
    this.buildAddGLForm();
  }

  ngAfterViewInit(): void {
    this.alternateLeaders$.subscribe(leaders => {
      this.alternateLeaders = leaders;
      if (this.contactList) {
        for (const l of leaders) {
          const row = this.contactList.glGrid.rows.find((r) => r.value.UserID === l.id);
          if (row && l.isActive) {
            this.contactList.multiSelect(row);
            row.cells[0].isNew = true;
          }
        }
        
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addLeaders(event: Event) {
    if (!this.addGLForm.valid) {
      this.onAddGLFormValueChanged();
      return;
    };

    this.working = true;
    // get list of users that should be alternate leaders
    // const userList = this.contactList.multiSelectedData.map(row => {
    //   return <Profile>{
    //     id: row.value.UserID,
    //     email: row.value.Email,
    //     isActive: true
    //   };
    // });

    // // get current alternate leaders that need to be disabled
    // const disabledList = this.alternateLeaders.filter(alt => {
    //   // find the row representing this user
    //   const row = this.contactList.glGrid.rows.find(r => r.value.UserID === alt.id);
    //   // only include in the list if they are now unselected
    //   if (alt.isActive && row && !row.multiSelected) {
    //     alt.isActive = false; // update the record
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // const totalList = userList.concat(disabledList);

    const firstName = this.addGLForm.get('FirstName').value as string;
    const lastName = this.addGLForm.get('LastName').value as string;
    const email = this.addGLForm.get('Email').value as string;

    const profile = <Profile>{
      email: email,
      firstName: firstName,
      lastName: lastName,
      isActive: true
    };

    this.eventService.addAlternateLeaders(event.id, [profile]).subscribe((res) => {
      this.working = false;
      const config = <Modal>{
      ...GroupToolsSuccessModalConfig,
      title: 'Alternate Group Leaders Updated',
      componentWithOptions: {
        component: GroupToolsSuccessModal,
        size: ModalSize.Small,
        options: {
          headerText: 'Success',
          type: 'success',
          buttons: {
            primary: {
              text: 'Return to Event',
              action: () => {
                this.store.dispatch(new eventActions.LoadAlternateLeaders(event.id));
                this.store.dispatch(new modalActions.CloseModal());
              }
            }
          }
        }
      }
    };
    this.store.dispatch(new modalActions.OpenModal(config));
    });
  }

  removeLeaders(event: Event) {
    if (!this.addGLForm.valid) {
      this.onAddGLFormValueChanged();
      return;
    };

    this.working = true;
    // get list of users that should be alternate leaders
    // const userList = this.contactList.multiSelectedData.map(row => {
    //   return <Profile>{
    //     id: row.value.UserID,
    //     email: row.value.Email,
    //     isActive: true
    //   };
    // });

    // // get current alternate leaders that need to be disabled
    // const disabledList = this.alternateLeaders.filter(alt => {
    //   // find the row representing this user
    //   const row = this.contactList.glGrid.rows.find(r => r.value.UserID === alt.id);
    //   // only include in the list if they are now unselected
    //   if (alt.isActive && row && !row.multiSelected) {
    //     alt.isActive = false; // update the record
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // const totalList = userList.concat(disabledList);

    const firstName = this.addGLForm.get('FirstName').value as string;
    const lastName = this.addGLForm.get('LastName').value as string;
    const email = this.addGLForm.get('Email').value as string;

    const profile = <Profile>{
      email: email,
      firstName: firstName,
      lastName: lastName,
      isActive: false
    };

    this.eventService.addAlternateLeaders(event.id, [profile]).subscribe((res) => {
      this.working = false;
      const config = <Modal>{
      ...GroupToolsSuccessModalConfig,
      title: 'Alternate Group Leaders Updated',
      componentWithOptions: {
        component: GroupToolsSuccessModal,
        size: ModalSize.Small,
        options: {
          headerText: 'Success',
          type: 'success',
          buttons: {
            primary: {
              text: 'Return to Event',
              action: () => {
                this.store.dispatch(new eventActions.LoadAlternateLeaders(event.id));
                this.store.dispatch(new modalActions.CloseModal());
              }
            }
          }
        }
      }
    };
    this.store.dispatch(new modalActions.OpenModal(config));
    });
  }

  close() {
    this.store.dispatch(new modalActions.CloseModal());
  }

  // tslint:disable-next-line:member-ordering
  public listOptions: ListOptions<Contact> = {
    hideDate: true,
    multiSelectMode: true,
    innerCard: false,
    hideHeader: false,
    hideMenu: false,
    rowsOnPage: 5,
    columns: [
      {
        fieldName: 'FirstName',
        label: 'First'
      },
      {
        fieldName: 'LastName',
        label: 'Last'
      },
      {
        fieldName: 'Email',
        label: 'Email'
      },
    ],
    collapsed: false,
    defaultMessage: {
      link: 'no contacts found'
    }
  };


  buildAddGLForm(): void {
    this.addGLForm = this.fb.group(this.addGLFormFields);

    this.addGLForm.valueChanges
        .subscribe(data => this.onAddGLFormValueChanged(data));

    this.onAddGLFormValueChanged(); //(re)set validation messages now
  }

  onAddGLFormValueChanged(data?: any) {
    if (!this.addGLForm) { return; }
    if (this.formSubmitted) {
        this.onFormSubmit();
        return;
    }
    const form = this.addGLForm;

    for (const field in this.addGLFormErrors) {
        if (this.addGLFormErrors.hasOwnProperty(field)) {
            this.addGLFormErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.addGLFormValidationMessages[field];
                for (const key in control.errors) {
                    if (control.errors.hasOwnProperty(key)) {
                        this.addGLFormErrors[field] += messages[key] + ' ';
                    }
                }
            }
        }
    }
  }

  onFormSubmit() {
    const form = this.addGLForm;

    for (const field in this.addGLFormErrors) {
        if (this.addGLFormErrors.hasOwnProperty(field)) {
            this.addGLFormErrors[field] = '';
            const control = form.get(field);

            if (control && !control.valid) {
                const messages = this.addGLFormValidationMessages[field];
                for (const key in control.errors) {
                    if (control.errors.hasOwnProperty(key)) {
                        this.addGLFormErrors[field] += messages[key] + ' ';
                    }
                }
            }
        }
    }
  }
}

export const AddAlternateLeaderConfig: Modal = {
  title: 'Manage Group Leaders',
  size: ModalSize.Medium,
  isFullScreenMobile: true,
  componentWithOptions: {
    component: AddAlternateLeaderComponent
  }
};
