
import { takeUntil } from 'rxjs/operators';
import { Component, ViewContainerRef, HostListener, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

import { GlobalState } from './global.state';
import { BaThemeSpinner } from './grouptools-theme/services/baThemeSpinner/baThemeSpinner.service';
import { BaThemePreloader } from './grouptools-theme/services/baThemePreloader/baThemePreloader.service';
import { AuthService } from 'app/authentication/authentication.service';
import { NgxConfigureService } from 'ngx-configure';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as reducer from 'app/store/reducers/shortUrl.reducer';
import * as loaderActions from 'app/store/actions/loader.action';
import { ShortUrl } from 'app/store/models/shortUrl.interface';
import { GroupToolsNewModalService } from './widgets/grouptools-modal/grouptools-new-modal.service';
import { GroupToolsLoaderService } from './grouptools-theme/services/grouptoolsLoader/grouptoolsLoader.service';

declare const gtag: Function;

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  template: `
    <main [ngClass]="{'menu-collapsed': isMenuCollapsed}">
      <div class="additional-bg"></div>

      <!-- App wide loading spinner; use GroupToolsLoaderService to access -->
      <grouptools-loader></grouptools-loader>
      <router-outlet></router-outlet>
    </main>
  `,
  providers: [GroupToolsLoaderService]
})
export class App {

  isMenuCollapsed: boolean = false;
  private googleAnalyticsCode: string;
  private googleApiKey: string;

  public titleMeta: any;
  public imageMeta: any;
  public descMeta: any;
  public urlMeta: any;
  public siteMeta: any;

  private unsubscribe$ = new Subject();

  constructor(
    private _state: GlobalState,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any,
    private _authService: AuthService,
    private _configureService: NgxConfigureService,
    private _router: Router,
    private _store: Store<ShortUrl>,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private activatedRoute: ActivatedRoute,
    //The modal service is injected here because something needs to 'new' it up.
    //This causes it to start listening for state changes in the store.
    //Do not remove, even though it looks like it's not used.
    private groupToolsNewModalService: GroupToolsNewModalService,
    private groupToolsLoaderService: GroupToolsLoaderService
  ) {

    this._authService.isLoggedIn();
    this.googleAnalyticsCode = this._configureService.config.googleAnalyticsId;
    this.googleApiKey = this._configureService.config.googleApiKey;

    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this.ngbDatepickerConfig.firstDayOfWeek = 7;
  }

  ngOnInit(): void {
    // Add tags to <head> for Google Analytics, this is the end result:
    // <!-- Global site tag (gtag.js) - Google Analytics -->
    // <script async src="https://www.googletagmanager.com/gtag/js?id=UA-107907822-5"></script>
    // <script>
    //  window.dataLayer = window.dataLayer || [];
    //  function gtag(){dataLayer.push(arguments);}
    //  gtag('js', new Date());
    //
    //  gtag('config', 'UA-107907822-5');
    // </script>
    // this.groupToolsLoaderService.show();
    const extScript = this.renderer.createElement('script');
    this.renderer.setProperty(extScript, 'async', true);
    this.renderer.setAttribute(extScript, 'src', `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsCode}`);
    this.renderer.appendChild(this.document.head, extScript);

    // Add tag to bottom of document for MouseFlow mouse tracking (only in production)
    // note, we can just do this part here and add the tag directly to the head
    //   <script type="text/javascript">
    //   window._mfq = window._mfq || [];
    //   (function() {
    //       var mf = document.createElement("script");
    //       mf.type = "text/javascript"; mf.async = true;
    //       mf.src = "//cdn.mouseflow.com/projects/075c91ed-1fe8-4e1b-b833-1aead04343a1.js";
    //       document.getElementsByTagName("head")[0].appendChild(mf);
    //   })();
    // </script>

    //PJ 2019-09-11: MouseFlow emporarily disabled due to page freezing in production
    // if (this._configureService.config.production === 'true') {
    //   const mfScript = this.renderer.createElement('script');
    //   mfScript.type = 'text/javascript';
    //   mfScript.async = true;
    //   mfScript.src = '//cdn.mouseflow.com/projects/075c91ed-1fe8-4e1b-b833-1aead04343a1.js';
    //   this.renderer.appendChild(this.document.head, mfScript);
    // }

    const tagScript = this.renderer.createElement('script');
    // tslint:disable-next-line:max-line-length
    tagScript.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${this.googleAnalyticsCode}');`;
    this.renderer.appendChild(this.document.head, tagScript);

    //<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAp_2b1mCDmgEQDeMJRV8C4BelCnZZLgyA&libraries=places&sensor=false&language=en"></script>
    const googleMapsScript = this.renderer.createElement('script');
    this.renderer.setProperty(googleMapsScript, 'async', true); //was not originally async, setting it here for better performance
    this.renderer.setAttribute(googleMapsScript, 'src', `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places&sensor=false&language=en`);
    this.renderer.appendChild(this.document.head, googleMapsScript);

    // ShareThis meta data
    this.initUrlMetaData();

    //if (this._configureService.config.production == "true") {
    const zendeskScript = this.renderer.createElement('script');
    this.renderer.setProperty(zendeskScript, 'async', true);
    this.renderer.setProperty(zendeskScript, 'defer', true);
    this.renderer.setAttribute(zendeskScript, 'id', `ze-snippet`);
    this.renderer.setAttribute(zendeskScript, 'src', `https://static.zdassets.com/ekr/snippet.js?key=1aa46382-e4ed-484c-ac11-2a7a1992a645`);
    this.renderer.appendChild(this.document.head, zendeskScript);
    //}

    // subscribe to the router and send a pageview when it changes
    this._router.events.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          // scroll to top of page after navigations
          window.scrollTo(0, 0);
          let path = event.urlAfterRedirects;
          // remove any inner-tags or query string parameters
          let index = path.indexOf('#');
          index = path.indexOf('?') > index ? path.indexOf('?') : index;
          index = index >= 0 ? index : path.length;
          path = path.substring(0, index);
          gtag('config', this.googleAnalyticsCode, { 'page_path': path });
          // make sure the spinner isn't up from the previous page
          // this._store.dispatch(new loaderActions.HideLoader());
        }
      });

    // subscribe to package short url values to set meta-data
    this._store.select<ShortUrl>(reducer.getShortUrl).pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((urlState: any) => {
        // ska 2018-10-03: most og properties are set statically for the webcrawler to read them
        // update meta tags with new data
        // this.renderer.setElementAttribute(this.titleMeta, 'content', urlState.shortUrl.title);
        this.renderer.setAttribute(this.urlMeta, 'content', urlState.shortUrl.shortUrl);
        // this.renderer.setElementAttribute(this.imageMeta, 'content', urlState.shortUrl.image);
        // this.renderer.setElementAttribute(this.descMeta, 'content', urlState.shortUrl.description);
        // this.renderer.setElementAttribute(this.siteMeta, 'content', urlState.shortUrl.site_name);
      });

    //Grab any query string parameters that were passed to the application (init only), and listen for all changes (subscriptions).
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(queryParams => this.persistTrackingParamsToSessionStorage(queryParams));

    this.persistTrackingParamsToSessionStorage(this.parseQueryString(window.location.href));

    //Track the referrer, if we have one
    if (document.referrer) {
      window.sessionStorage.setItem('referrer', document.referrer);
    }
  }

  //HACK: We can't easily get the initial query string params, so we're parsing the initial application URL.
  private parseQueryString(url: string) {
    let params = {};

    url = url || '';
    const pos = url.indexOf('?');

    if (pos >= 0 && (pos + 1) < url.length) {
      const queryString = url.substring(pos + 1);

      queryString.split('&').forEach(kvp => {
        const parts = kvp.split('=');

        const key = parts[0];
        const val = kvp.length > 1 ? parts[1] : '';

        params[key] = val;
      });
    }

    return params;
  }

  //Find all parameters that start with "utm_" and persist them to sessionStorage.
  private persistTrackingParamsToSessionStorage(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      const lowercaseKey = key.toLowerCase();
      if (lowercaseKey.startsWith('utm_')) {
        window.sessionStorage.setItem(lowercaseKey, queryParams[key]);
      }
    });
  }

  public initUrlMetaData() {
    // ska 2018-10-03: most og properties are set statically for the webcrawler to read them

    // this.titleMeta = this.renderer.createElement(this.document.head, 'meta');
    // this.renderer.setElementAttribute(this.titleMeta, 'property', 'og:title');
    // this.renderer.setElementAttribute(this.titleMeta, 'content', '');
    this.urlMeta = this.renderer.createElement('meta');
    this.renderer.setAttribute(this.urlMeta, 'property', 'og:url');
    this.renderer.setAttribute(this.urlMeta, 'content', '');
    // this.imageMeta = this.renderer.createElement(this.document.head, 'meta');
    // this.renderer.setElementAttribute(this.imageMeta, 'property', 'og:image');
    // this.renderer.setElementAttribute(this.imageMeta, 'content', '');
    // this.descMeta = this.renderer.createElement(this.document.head, 'meta');
    // this.renderer.setElementAttribute(this.descMeta, 'property', 'og:description');
    // this.renderer.setElementAttribute(this.descMeta, 'content', '');
    // this.siteMeta = this.renderer.createElement(this.document.head, 'meta');
    // this.renderer.setElementAttribute(this.siteMeta, 'property', 'og:site_name');
    // this.renderer.setElementAttribute(this.siteMeta, 'content', '');
  }

  public ngAfterViewInit(): void {
    // setTimeout(() => this.groupToolsLoaderService.hide(), 3000);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    if (localStorage.login_status) {
      if (localStorage.login_status === 'partial') {
        //localStorage.clear();
      }
    }

    // unsubscribe from everything
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
