export var SHOW_LOADER = '[Loader] Set Loader Show';
export var HIDE_LOADER = '[Loader] Set Loader Hide';
var ShowLoader = /** @class */ (function () {
    function ShowLoader() {
        this.type = SHOW_LOADER;
    }
    return ShowLoader;
}());
export { ShowLoader };
var HideLoader = /** @class */ (function () {
    function HideLoader() {
        this.type = HIDE_LOADER;
    }
    return HideLoader;
}());
export { HideLoader };
