/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grouptoolsLoader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./grouptoolsLoader.component";
import * as i3 from "@ngrx/store";
var styles_GroupToolsLoaderComponent = [i0.styles];
var RenderType_GroupToolsLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupToolsLoaderComponent, data: {} });
export { RenderType_GroupToolsLoaderComponent as RenderType_GroupToolsLoaderComponent };
export function View_GroupToolsLoaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { loaderEl: 0 }), (_l()(), i1.ɵted(-1, null, ["  "])), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["loaderElement", 1]], null, 21, "div", [["class", "container"], ["id", "preloader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 18, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "loader-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "loader-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "loader-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "loader-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "loader-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "loader-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "loader-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_GroupToolsLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "grouptools-loader", [], null, null, null, View_GroupToolsLoaderComponent_0, RenderType_GroupToolsLoaderComponent)), i1.ɵdid(1, 4243456, null, 0, i2.GroupToolsLoaderComponent, [i3.Store], null, null)], null, null); }
var GroupToolsLoaderComponentNgFactory = i1.ɵccf("grouptools-loader", i2.GroupToolsLoaderComponent, View_GroupToolsLoaderComponent_Host_0, {}, {}, []);
export { GroupToolsLoaderComponentNgFactory as GroupToolsLoaderComponentNgFactory };
