import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { URLConfig } from '../helpers/config.service';

import { HttpClient } from '@angular/common/http';
import { User } from 'app/store/models/user';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { JsonPipe } from '@angular/common';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class UserService {

    constructor(private http: HttpClient,
        private URLs: URLConfig,
    ) { }

    public getUser(): Observable<User> {
        return this.http.get<GTResponse<User>>(this.URLs._getUser()).pipe(
            handleGTResponse(),
            map(user => {
                user.unreadMessagesPreferences = user.preferences.email.unreadMessages;
                return user;
            }) // map to store model
        );
    }

    public updateUser(user: User): Observable<User> {
        // format for server
        const prefObj = {
            email: {
                unreadMessages: user.unreadMessagesPreferences
            }
        };
        const socialObj = Object.assign({}, {
            about: user.about
        });
        const formatted = {
            ...user,
            id: user.userID,
            preferences: prefObj,
            socialData: socialObj,
            preferencesJson: JSON.stringify(prefObj),
            socialDataJson: JSON.stringify(socialObj)
        };
        return this.http.put<GTResponse<User>>(this.URLs._updateUser(), formatted).pipe(
            handleGTResponse(),
        );
    }

}
