import * as tslib_1 from "tslib";
import { createSelector } from "@ngrx/store";
var getPackageDetailsState = function (state) { return state.packageDetailsState; };
var ɵ0 = getPackageDetailsState;
// =================================================================================
//                                      Selectors
// =================================================================================
export var getPackageDetails = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.packageDetails; });
export var getSelectedPackageId = createSelector(getPackageDetails, function (packageDetails) { return packageDetails.IdPackage; });
export var getTagInfo = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.tagInfo; });
export var getPackageComponents = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.components; });
export var getPackageRecord = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.packageRecord; });
export var getPaymentsReceivedSummary = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.paymentsReceivedSummary; });
export var getPaymentsSentSummary = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.paymentsSentSummary; });
export var getSuppliers = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.allSuppliers; });
export var getTickets = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.tickets; });
export var isBiEnabled = createSelector(getSuppliers, function (suppliers) {
    var e_1, _a;
    try {
        for (var suppliers_1 = tslib_1.__values(suppliers), suppliers_1_1 = suppliers_1.next(); !suppliers_1_1.done; suppliers_1_1 = suppliers_1.next()) {
            var supplier = suppliers_1_1.value;
            if (supplier.SupplierCode.toLocaleUpperCase() === 'BI')
                return true;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (suppliers_1_1 && !suppliers_1_1.done && (_a = suppliers_1.return)) _a.call(suppliers_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
});
export var getPackageLogs = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.packageLogs; });
export var getPackageSuppliers = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.packageSuppliersByActivity; });
export var getBibOrderNumberCreateEventApiState = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.bibOrderNumberCreateEventApiState.apiState; });
export var getSupplierOrderNumberCreateEventApiState = createSelector(getPackageDetailsState, function (packageDetailsState) { return packageDetailsState.supplierOrderNumberCreateEventApiState.apiState; });
export { ɵ0 };
// =================================================================================
//                                      End
// =================================================================================
