
import {map, tap} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { URLConfig } from '../helpers/config.service';
import { Observable } from 'rxjs';
import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { AttendeeSummaryDTO } from 'app/store/models/packages/AttendeeSummaryDTO';
import { PackageComponentDTO } from 'app/store/models/packages/PackageComponentDTO';
import { DomSanitizer } from '@angular/platform-browser';
import { PackageDetailForAttendeeDTO } from 'app/store/models/packages/PackageDetailForAttendeeDTO';
import { ListOptions } from 'app/widgets/grouptools-list/grouptools-list.component';
import { PaymentDTO } from 'app/store/models/packages/SeparatedPaymentsDTO';
import { UpdateResponseDTO } from 'app/store/models/packages/UpdateResponseDTO';
import { UpdateInvitationResponse } from 'app/store/models/packages/UpdateInvitationResponse';

@Injectable()
export class AttendeePackageDetailService {

  public paymentsMadeOptions: ListOptions<PaymentDTO> = {
    hideDate: true,
    multiSelectMode: false,
    hideMenu: false,
    columns: [{
        fieldName: 'PaymentDate',
        label: 'Payment Date',
        pipe: new DatePipe('en-US'),
        type: 'date'
      },
      {
        fieldName: 'PaymentType',
        label: 'Payment Type',
        mobile: {
          showInMobile: false
        }
      },
      {
        fieldName: 'PayeeName',
        label: 'To',
        mobile: {
          showInMobile: false
        }
      },
      {
        fieldName: 'Amount',
        label: 'Amount',
        type: 'currency'
      },
      {
        fieldName: 'Status',
        label: 'Status',
        mobile: {
          showInMobile: false
        }
      }
    ],
    collapsed: false,
    singleActions: [],
    headerActions: ['Make Payment'],
    defaultMessage: {
      message: 'No payments have been submitted for this event.'
    }
  };

  constructor(
    private http: HttpClient,
    private URLs: URLConfig,
    private sanitizer: DomSanitizer,
    ) {}

  GetPackageDetails(IdPackage: number): Observable<PackageDetailForAttendeeDTO> {
    return this.http.get<GTResponse<PackageDetailForAttendeeDTO>>(this.URLs._packageDetailsForAttendee(IdPackage)).pipe(
      handleGTResponse(),
      map(packageDetails => {
        try {
          packageDetails.options = JSON.parse(packageDetails.JsonDoc);
        } catch (e) {
          packageDetails.options = {};
        }
        if (packageDetails.options.inviteOthers === undefined) {
          packageDetails.options.inviteOthers = true;
        }
        if (packageDetails.options.inviteOthersCount === undefined) {
          packageDetails.options.inviteOthersCount = 99;
        }
        return packageDetails;
      }),
      map(packageDetails => {
        if (packageDetails.OwnerPhoto) {
          packageDetails.OwnerPhoto = 'data:image/jpg;base64,' + packageDetails.OwnerPhoto;
        }
        return packageDetails;
      }),
    );
  }

  GetComponents(IdPackage: number): Observable<PackageComponentDTO[]> {
    return this.http.get<GTResponse<PackageComponentDTO[]>>(this.URLs._packageComponentsGetByID(IdPackage)).pipe(
      handleGTResponse(),
    );
  }

  GetMyPayments(IdPackage: number): Observable<PaymentDTO[]> {
    return this.http.get<GTResponse<PaymentDTO[]>>(this.URLs._packagePaymentsForAttendee(IdPackage)).pipe(
      handleGTResponse(),
    );
  }

  public GetPaymentsSentSummary(packageId): Observable < any > {
    return this.http.get<any>(this.URLs._paymentsSentSummaryForAttendee(packageId)).pipe(
    map(res => {
        if (res.Result) {
            return res.Obj;
        }
        return null;
    }));
  }

  public GetPackageInfo(packageId): Observable<string> {
    return this.http.get<GTResponse<string>>(this.URLs._packageGetByID(packageId)).pipe(
      handleGTResponse(),
    );
  }

  public GetAttendeeSummary(packageId: number): Observable<AttendeeSummaryDTO> {
    return this.http.get<GTResponse<AttendeeSummaryDTO>>(this.URLs._packageAttendeeSummary(packageId)).pipe(
      handleGTResponse(),
    );
  }

  public SetStatus(update: UpdateResponseDTO): Observable<UpdateInvitationResponse> {
    return this.http.post<GTResponse<UpdateInvitationResponse>>(this.URLs._packageUpdateResponse(), update).pipe(
      handleGTResponse(),
    );
  }

  public AttendeeFeature(packageComponent): Observable < any > {
    return this.http.post(this.URLs._packageAttendeeFeature(packageComponent.IdPackageComponent), packageComponent);
  }

  public GetComponentStatus(IdPackage): Observable < any > {
    return this.http.get<any>(this.URLs._packageGetComponentStatus(IdPackage)).pipe(
      map(res => {
          if (res.Result) {
              return res.Obj;
          } else {
              return null;
          }
      }));
  }

  public HasGuests(IdPackage: number): Observable < any > {
    return this.http.get(this.URLs._packageAttendeeHasGuests(IdPackage));
  }

  public MakePaymentAsAttendee(payment: any): Observable < any > {
    return this.http.post(this.URLs._makePayment(), payment);
  }

  public DeletePayment(IdPayment: number): Observable < any > {
    return this.http.post(this.URLs._deletePayment(IdPayment), null);
  }

  public UpdatePaymentStatus(paymentId: number, status: string): Observable < any > {
    const payload = {
      'IdPayment': paymentId,
      'Status': status
    };
    return this.http.post(this.URLs._paymentStatusUpdate(), payload);
  }

  public CreateWePayCheckout(payload: any): Observable < any > {
    return this.http.post(this.URLs._createWePayCheckout(payload.IdPayment), payload);
  }

  public CreateStripeCheckout(payload: any): Observable < any > {
    return this.http.post(this.URLs._createStripeCheckout(payload.IdPayment), payload);
  }

  public GetLeaderPaymentMethod(leaderId: number): Observable<string> {
    return this.http.get<GTResponse<string>>(this.URLs._getPaymentMethod(leaderId)).pipe(
      handleGTResponse(),
    );
  }

  public GetAlternatePaymentMethod(userId: number): Observable<string> {
    return this.http.get<GTResponse<string>>(this.URLs._paymentGetAlternatePaymentMethod(userId)).pipe(
      handleGTResponse(),
      map(alternatePaymentMethod => this.sanitizer.sanitize(SecurityContext.HTML, alternatePaymentMethod)),
    );
  }

  public DeletePlusOnes(IdPackage: number): Observable < any > {
    return this.http.get<any>(this.URLs._packageRemovePlusOnes(IdPackage)).pipe(
    map(res => res.Result));
  }

  /********************
   * Calculations
   *******************/
  // TODO: REFACTOR: change this to use a standard model used in the store
  public getComponentPrice(component: any): number {
    let price = 0;

    if (!component.Options)  { return 0; } // if somehow no options, price is 0

    // if there is only one option, price is that option's price
    if (component.Options.length === 1) {
        price = component.Options[0].AttendeePrice;
    } else {
        // if there are multiple options, find either the one that
        // the user is attending (Status === 'accept') OR
        // the minimum of option prices
        let min = component.Options[0].AttendeePrice;
        for (const opt of component.Options) {
            if (opt.AttendeePrice < min) {
                min = opt.AttendeePrice;
            }
            if (opt.Status === 'accept' || opt.Status === 'tentative') {
                price = opt.AttendeePrice;
            }
        }
        // at this point, if price is not set, the user is not attending an option
        // show them the minimum price
        if (!price) {
            price = min;
        }
    }

    return price;
  }

  public getAttendingPartySize(component: any): number {
    let party = 0;
    // if they have accepted any option, the whole party is attending
    for (const opt of component.Options) {
        if (opt.Status === 'accept' || opt.Status === 'tentative') {
            party = component.PartySize;
        }
    }
    return party;
  }

  public getTotalDue(components: Array<any>): number {
      let sum = 0;
      for (const comp of components) {
          sum += this.getComponentPrice(comp) * this.getAttendingPartySize(comp);
          sum -= comp.TotalDistributedPayments;
      }
      return sum;
  }

  public getTotalCost(components: Array<any>): number {
      let sum = 0;
      for (const comp of components) {
          sum += this.getComponentPrice(comp) * this.getAttendingPartySize(comp);
      }
      return sum;
  }

}

