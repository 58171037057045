import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { ActionsService } from "app/actions/actions.service";
import * as ActionActions from "app/store/actions/actions.action";
import { of } from "rxjs";
import { catchError, concatMap, filter, map, withLatestFrom } from "rxjs/operators";
import * as UserActions from "app/store/actions/user";
import { select, Store } from "@ngrx/store";
import * as ActionSelectors from "app/store/selectors/actions.selector";
import * as AttendeesActions from "app/store/actions/attendees.action";
import * as PackageDetailsActions from "app/store/actions/packageDetails.actions";
var ActionsEffects = /** @class */ (function () {
    function ActionsEffects(actions$, actionsService, store$) {
        var _this = this;
        this.actions$ = actions$;
        this.actionsService = actionsService;
        this.store$ = store$;
        // TODO: Could redesign this so it only fires if the status is being set to true AND it was previously false
        //       This functionality isn't immediately available because reducers always fire before effects, so we can't
        //       test the previous value of the status without creating an intermediary user action (which I don't have time for atm).
        this.reloadActionsAfterUserIsVerified = this.actions$.pipe(ofType(UserActions.SET_VERIFIED_STATUS), filter(function (action) { return action.payload === true; }), map(function (action) { return new ActionActions.ActionsEffectUserWasVerified(); }));
        // Grab other miscellaneous actions to update the actionsList that don't have the eventId info,
        // get the eventId from actions state, and forward to effect to send api request
        this.getEventIdAndForwardToLoadActions = this.actions$.pipe(ofType(ActionActions.ActionTypes.SurveyComponentUpdateActions, ActionActions.ActionTypes.AttendeeFormResponseComponentUpdateActions, ActionActions.ActionTypes.ActionsEffectUserWasVerified, AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess, PackageDetailsActions.ActionTypes.PackageDetailsPageGetPackageDetails, ActionActions.ActionTypes.ActionsEffectMarkAsReadSuccess), withLatestFrom(this.store$.pipe(select(ActionSelectors.getActionsEventId)), function (action, actionEventId) { return actionEventId; }), map(function (actionEventId) { return new ActionActions.ActionsEffectLoadActions({ eventId: actionEventId }); }));
        this.loadActions = this.actions$.pipe(ofType(ActionActions.ActionTypes.ActionsComponentLoadActions, ActionActions.ActionTypes.ActionsEffectLoadActions), concatMap(function (action) {
            var options = {
                IdPackage: action.payload.eventId,
                state: 'Unread',
                type: null
            };
            return _this.actionsService.GetActions(options).pipe(map(function (userActions) { return new ActionActions.ActionsEffectLoadActionsSuccess({ userActions: userActions }); }), catchError(function (error) { return of(new ActionActions.ActionsEffectLoadActionsFailure({ errorMessage: error.toString() })); }));
        }));
        this.markActionAsRead = this.actions$.pipe(ofType(ActionActions.ActionTypes.ActionsComponentMarkAsRead), concatMap(function (action) { return _this.actionsService.MarkAsRead(action.payload.userAction).pipe(map(function (userActionId) { return new ActionActions.ActionsEffectMarkAsReadSuccess({ userActionId: userActionId }); }), catchError(function (error) { return of(new ActionActions.ActionsEffectMarkAsReadFailure({ errorMessage: error.toString() })); })); }));
        this.dismissSurveyActions = this.actions$.pipe(ofType(ActionActions.ActionTypes.ActionsComponentDismissSurveyActions), concatMap(function (action) { return _this.actionsService.DismissSurveyActions(action.payload.surveyId).pipe(map(function (userActionId) { return new ActionActions.ActionsEffectDismissSurveyActionsSuccess({ userActionId: userActionId }); }), catchError(function (error) { return of(new ActionActions.ActionsEffectDismissSurveyActionsFailure({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActionsEffects.prototype, "reloadActionsAfterUserIsVerified", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActionsEffects.prototype, "getEventIdAndForwardToLoadActions", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActionsEffects.prototype, "loadActions", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActionsEffects.prototype, "markActionAsRead", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ActionsEffects.prototype, "dismissSurveyActions", void 0);
    return ActionsEffects;
}());
export { ActionsEffects };
