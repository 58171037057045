import { URLConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
var FacebookService = /** @class */ (function () {
    function FacebookService(_http, _Urls) {
        this._http = _http;
        this._Urls = _Urls;
    }
    FacebookService.prototype.GetAccessTokenFromCode = function (code) {
        return this._http.get(this._Urls._getFacebookTokenFromCode(code));
    };
    return FacebookService;
}());
export { FacebookService };
