<div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form-horizontal">

    <div class="row"><div class="col-xs-12 amazon-email">{{user?.FirstName + ' ' + user?.LastName}}</div></div>
    <div class="row"><div class="col-xs-12">Would you like to register a GroupTools account with this email address?</div></div>
    <div class="row"><div class="col-xs-12 amazon-email">{{user?.Email}}</div></div>
    <div class="row"><div class="col-xs-12">You can add a password to your new account to log in directly (without Amazon) if you want. Feel free to leave the password blank if you only want to log in with your Amazon account.</div></div>

    <!--PASSWORD  -->
    <div class="form-group row" [ngClass]="{'has-error': formErrors.password && (password.dirty) , 'has-success': !formErrors.password && password.valid}">
        <div class="col-xs-12">
        <input [formControl]="password" type="password" class="form-control" class="form-control" id="inputPassword3" placeholder="Password">
        <div *ngIf="formErrors.password" class="error-block">
            <p class="error-text">{{ formErrors.password }}</p>
        </div>
        </div>
    </div>

    <!--REPEAT PASSWORD  -->
    <div class="form-group row" [ngClass]="{'has-error': formErrors.passwords && (repeatPassword.dirty) , 'has-success': !formErrors.passwords && passwords.valid}">
        <div class="col-xs-12">
        <input [formControl]="repeatPassword" type="password" class="form-control" class="form-control" id="inputPassword4" placeholder="Repeat">
        <div *ngIf="formErrors.passwords" class="error-block">
            <p class="error-text">{{ formErrors.passwords }}</p>
        </div>
        </div>
    </div>

    <!--PASSWORD STRENGTH METER -->
    <div class="form-group row" *ngIf="password.value.length > 0">
        <div class="col-xs-12 psw-strength-block">
            <div class="strong-block" *ngIf="!formErrors.password && (password.value.length >= 12 || includeSpecialChar())">Password Strength: Strong</div>
            <div class="moderate-block" *ngIf="!formErrors.password && (password.value.length < 12 && !includeSpecialChar())">Password Strength: Moderate</div>
            <div class="weak-block" *ngIf="formErrors.password">Password Strength: Weak</div>
        </div>
    </div>

    <button #button id="LoginWithAmazon" type="submit" class="btn btn-secondary form-control social-button amazon-button">
        <span class="no-margin" style="width:auto;"><i id="amazon-icon" class="fa fa-amazon" style="padding-right:20px;"></i>Register with Amazon</span>
    </button>

    </form>
</div>
