import * as tslib_1 from "tslib";
import { getGroupingDisplayName } from "app/store/models/eventItem";
import { createSelector, select } from "@ngrx/store";
import { attendeeUpsertEntitySelectors, eventItemEntitySelectors, mixedAttendeeEntitySelectors, showInfoEntitySelectors } from "app/store/entity-adapters/eventItem.adapter";
import { AttendeeUpsertRequest } from "app/store/models/requests/attendee-upsert-request";
import { ApiState, DataState } from "../models/apiData";
import { map, takeWhile } from "rxjs/operators";
var getEventItemState = function (state) { return state.eventItemState; };
var ɵ0 = getEventItemState;
var ɵ1 = function (eventItemState) { return eventItemState.eventItems; };
// =================================================================================
//                          Event Item Entity Selectors
// =================================================================================
var getEventItemEntity = createSelector(getEventItemState, ɵ1);
var ɵ2 = function (eventItemEntity) { return eventItemEntitySelectors.selectEntities(eventItemEntity); };
var getEventItemEntities = createSelector(getEventItemEntity, ɵ2);
var ɵ3 = function (eventItemEntity) { return eventItemEntitySelectors.selectAll(eventItemEntity); };
var getEventItemAll = createSelector(getEventItemEntity, ɵ3);
var ɵ4 = function (eventItems) {
    var e_1, _a;
    var eventItemMap = new Map();
    try {
        for (var eventItems_1 = tslib_1.__values(eventItems), eventItems_1_1 = eventItems_1.next(); !eventItems_1_1.done; eventItems_1_1 = eventItems_1.next()) {
            var eventItem = eventItems_1_1.value;
            eventItemMap.set(eventItem.id, eventItem);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (eventItems_1_1 && !eventItems_1_1.done && (_a = eventItems_1.return)) _a.call(eventItems_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return eventItemMap;
};
var getEventItemMap = createSelector(getEventItemAll, ɵ4);
var ɵ5 = function (eventItemState) { return eventItemState.eventItems.dataState; };
var getEventItemDataState = createSelector(getEventItemState, ɵ5);
export var getEventItems = createSelector(getEventItemState, function (eventItemState) { return eventItemEntitySelectors.selectAll(eventItemState.eventItems); });
export var getEventItemsApiState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.eventItems.apiState; });
export var getEventItemsCount = createSelector(getEventItems, function (eventItems) { return eventItems.length; });
// =================================================================================
//                        Autosaving Attendee Entity Selectors
// =================================================================================
export var isAttendeeAutosavingInFlight = createSelector(getEventItemState, function (eventItemState) {
    var numberOfInFlightAttendees = mixedAttendeeEntitySelectors.selectTotal(eventItemState.inFlightAttendees);
    return numberOfInFlightAttendees > 0;
});
export var getWaitingAttendees = createSelector(getEventItemState, function (eventItemState) { return mixedAttendeeEntitySelectors.selectAll(eventItemState.waitingAttendees); });
export var getInvalidAttendees = createSelector(getEventItemState, function (eventItemState) { return mixedAttendeeEntitySelectors.selectAll(eventItemState.invalidAttendees); });
export var areAttendeesWaitingToBeSaved = createSelector(getEventItemState, function (eventItemState) { return mixedAttendeeEntitySelectors.selectTotal(eventItemState.waitingAttendees) > 0; });
export var getSavedInputAttendeesDictionary = createSelector(getEventItemState, function (eventItemState) { return attendeeUpsertEntitySelectors.selectEntities(eventItemState.savedInputAttendees); });
export var getSavedInputNewEventAttendeeIdMap = createSelector(getEventItemState, function (eventItemState) { return eventItemState.savedInputNewIdMap; });
export var getSavedInputNewEventAttendeeIdMapReverse = createSelector(getEventItemState, function (eventItemState) { return eventItemState.savedInputNewIdMapReverse; });
export var getInFlightAttendeesDictionary = createSelector(getEventItemState, function (eventItemState) { return mixedAttendeeEntitySelectors.selectEntities(eventItemState.inFlightAttendees); });
// export const isAutosavingComplete = createSelector(
//     getEventItemState,
//     eventItemState => {
//         return mixedAttendeeEntitySelectors.selectTotal(eventItemState.waitingAttendees) <= 0
//             && mixedAttendeeEntitySelectors.selectTotal(eventItemState.inFlightAttendees) <= 0;
//     }
// );
// TODO: If the backend fails to respond, this will cause the UI to show a successful "Saved" indicator to the user
// To recreate, add a new attendee, break in the api before saving, and stop running the api
export var attendeeAutosavingState = createSelector(getEventItemState, function (eventItemState) {
    var isAutosavingComplete = mixedAttendeeEntitySelectors.selectTotal(eventItemState.waitingAttendees) <= 0
        && mixedAttendeeEntitySelectors.selectTotal(eventItemState.inFlightAttendees) <= 0;
    if (isAutosavingComplete) {
        return ApiState.Success;
    }
    else {
        return ApiState.InFlight;
    }
});
// =================================================================================
//                            Deleted Attendee Selectors
// =================================================================================
export var getDeletedEventAttendeeIds = createSelector(getEventItemState, function (eventItemState) {
    return {
        deletedEventAttendeeIds: eventItemState.deletedEventAttendeeIds,
        deletedNewEventAttendeeIds: eventItemState.deletedNewEventAttendeeIds,
        failedToDeleteEventAttendeeIds: eventItemState.failedToDeleteEventAttendeeIds,
        failedToDeleteNewEventAttendeeIds: eventItemState.failedToDeleteNewEventAttendeeIds,
    };
});
export var getFailedAutosaveEventAttendeeIds = createSelector(getEventItemState, function (eventItemState) {
    return {
        failedToAutosaveEventAttendeeIds: eventItemState.failedToAutosaveEventAttendeeIds,
        failedToAutosaveNewEventAttendeeIds: eventItemState.failedToAutosaveNewEventAttendeeIds,
    };
});
var ɵ6 = function (eventItemState) { return eventItemState.attendees; };
// =================================================================================
//                            Attendee Entity Selectors
// =================================================================================
var getAttendeeEntity = createSelector(getEventItemState, ɵ6);
var ɵ7 = function (attendeeEntity) { return attendeeUpsertEntitySelectors.selectEntities(attendeeEntity); };
var getAttendeeEntities = createSelector(getAttendeeEntity, ɵ7);
var ɵ8 = function (attendeeEntity) { return attendeeUpsertEntitySelectors.selectAll(attendeeEntity); };
var getAttendeeAll = createSelector(getAttendeeEntity, ɵ8);
export var getAttendeeAllWithParentInfo = createSelector(getAttendeeAll, getAttendeeEntities, function (attendees, attendeeEntities) {
    var e_2, _a;
    var attendeesWithParentInfo = [];
    try {
        for (var attendees_1 = tslib_1.__values(attendees), attendees_1_1 = attendees_1.next(); !attendees_1_1.done; attendees_1_1 = attendees_1.next()) {
            var attendee = attendees_1_1.value;
            var attendeeCopy = AttendeeUpsertRequest.copy(attendee);
            // The attendee here is not a full AttendeeUpsertRequest, I suspect this is because ngrx entity adapters
            // strip all the getter/setter/methods from the object when modifying the collection (in the reducer).  So only attendeeCopy
            // has the isChild, isParent, etc...  getters/setters/methods that a full AttendeeUpsertRequest object should have.
            attendeesWithParentInfo.push(attendeeCopy);
            if (!attendeeCopy.isChild)
                continue; // Not a child attendee, skip
            var parentId = attendeeCopy.parentUserId;
            var parentAttendee = attendeeEntities[parentId];
            if (!parentAttendee)
                continue; // Could not find parent, skip
            attendeeCopy.parentFullName = parentAttendee.fullName;
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (attendees_1_1 && !attendees_1_1.done && (_a = attendees_1.return)) _a.call(attendees_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return attendeesWithParentInfo;
});
var ɵ9 = function (attendees) {
    var e_3, _a;
    var attendeeMap = new Map();
    try {
        for (var attendees_2 = tslib_1.__values(attendees), attendees_2_1 = attendees_2.next(); !attendees_2_1.done; attendees_2_1 = attendees_2.next()) {
            var attendee = attendees_2_1.value;
            attendeeMap.set(attendee.eventAttendeeId, attendee);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (attendees_2_1 && !attendees_2_1.done && (_a = attendees_2.return)) _a.call(attendees_2);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return attendeeMap;
};
var getAttendeeMappedByEventAttendeeId = createSelector(getAttendeeAll, ɵ9);
var ɵ10 = function (eventItemState) { return eventItemState.attendees.dataState; };
var getAttendeeDataState = createSelector(getEventItemState, ɵ10);
// =================================================================================
//                          Assignments Entity Selectors
// =================================================================================
export var getAssignments = createSelector(getEventItemAll, function (eventItems) {
    return eventItems.map(function (eventItem) {
        return {
            eventItemId: eventItem.id,
            eventAttendeeId: eventItem.eventAttendeeId,
        };
    });
});
export var getAssignmentsApiState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.assignmentsState.apiState; });
var ɵ11 = function (eventItems) {
    var e_4, _a;
    var assignedEventAttendeeIdSet = new Set();
    try {
        for (var eventItems_2 = tslib_1.__values(eventItems), eventItems_2_1 = eventItems_2.next(); !eventItems_2_1.done; eventItems_2_1 = eventItems_2.next()) {
            var eventItem = eventItems_2_1.value;
            if (!!eventItem.eventAttendeeId)
                assignedEventAttendeeIdSet.add(eventItem.eventAttendeeId);
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (eventItems_2_1 && !eventItems_2_1.done && (_a = eventItems_2.return)) _a.call(eventItems_2);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return assignedEventAttendeeIdSet;
};
// =================================================================================
//                   Unsaved Updated Assignments Entity Selectors
// =================================================================================
var getAssignedEventAttendeeIdsSet = createSelector(getEventItems, ɵ11);
// =================================================================================
//                                Show List Selectors
// =================================================================================
export var getShowList = createSelector(getEventItemState, function (eventItemState) { return showInfoEntitySelectors.selectAll(eventItemState.showList); });
export var getShowListApiState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.showList.apiState; });
// =================================================================================
//                           Delivery Page Selection State
// =================================================================================
export var getDeliverySelectionState = createSelector(getEventItemState, getEventItems, function (eventItemState, eventItems) {
    var e_5, _a, e_6, _b;
    var completeMap = new Map();
    try {
        for (var eventItems_3 = tslib_1.__values(eventItems), eventItems_3_1 = eventItems_3.next(); !eventItems_3_1.done; eventItems_3_1 = eventItems_3.next()) {
            var eventItem = eventItems_3_1.value;
            completeMap.set(eventItem.id, false);
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (eventItems_3_1 && !eventItems_3_1.done && (_a = eventItems_3.return)) _a.call(eventItems_3);
        }
        finally { if (e_5) throw e_5.error; }
    }
    try {
        for (var _c = tslib_1.__values(eventItemState.deliveryPageSelectionState.entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
            var entry = _d.value;
            completeMap.set(entry[0], entry[1]);
        }
    }
    catch (e_6_1) { e_6 = { error: e_6_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
        }
        finally { if (e_6) throw e_6.error; }
    }
    return completeMap;
});
export var getSelectedDeliveryEventItemIds = createSelector(getDeliverySelectionState, function (deliverySelectionState) {
    var e_7, _a;
    var selectedDeliveryEventItemIds = [];
    try {
        for (var deliverySelectionState_1 = tslib_1.__values(deliverySelectionState), deliverySelectionState_1_1 = deliverySelectionState_1.next(); !deliverySelectionState_1_1.done; deliverySelectionState_1_1 = deliverySelectionState_1.next()) {
            var _b = tslib_1.__read(deliverySelectionState_1_1.value, 2), eventItemId = _b[0], isSelected = _b[1];
            if (isSelected === true)
                selectedDeliveryEventItemIds.push(eventItemId);
        }
    }
    catch (e_7_1) { e_7 = { error: e_7_1 }; }
    finally {
        try {
            if (deliverySelectionState_1_1 && !deliverySelectionState_1_1.done && (_a = deliverySelectionState_1.return)) _a.call(deliverySelectionState_1);
        }
        finally { if (e_7) throw e_7.error; }
    }
    return selectedDeliveryEventItemIds;
});
export var isAnythingSelectedOnDeliveryTab = createSelector(getSelectedDeliveryEventItemIds, function (deliverySelectedEventItemIds) { return deliverySelectedEventItemIds.length > 0; });
// =================================================================================
//                                 Public Selectors
// =================================================================================
export var getUnassignedAttendees = createSelector(getAttendeeAllWithParentInfo, getAssignedEventAttendeeIdsSet, function (attendees, assignedEventAttendeeIds) {
    var e_8, _a;
    var unassignedAttendees = [];
    try {
        for (var attendees_3 = tslib_1.__values(attendees), attendees_3_1 = attendees_3.next(); !attendees_3_1.done; attendees_3_1 = attendees_3.next()) {
            var attendee = attendees_3_1.value;
            if (assignedEventAttendeeIds.has(attendee.eventAttendeeId))
                continue; // skip
            unassignedAttendees.push(attendee);
        }
    }
    catch (e_8_1) { e_8 = { error: e_8_1 }; }
    finally {
        try {
            if (attendees_3_1 && !attendees_3_1.done && (_a = attendees_3.return)) _a.call(attendees_3);
        }
        finally { if (e_8) throw e_8.error; }
    }
    return unassignedAttendees;
});
export var getAttendeesApiState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.attendees.apiState; });
export var getInitallyLoadedAttendeesApiState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.initiallyLoadedAttendees.apiState; });
export var getAllAttendees = createSelector(getAttendeeAll, function (attendees) {
    return attendees;
});
export var getInitiallyLoadedAttendees = createSelector(getEventItemState, function (eventItemState) {
    return {
        initiallyLoadedAttendees: attendeeUpsertEntitySelectors.selectAll(eventItemState.initiallyLoadedAttendees),
        apiState: eventItemState.initiallyLoadedAttendees.apiState,
    };
});
export var getTicketQuantity = createSelector(getEventItemState, function (eventItemState) { return eventItemEntitySelectors.selectTotal(eventItemState.eventItems); });
export var selectEimSelectedTab = createSelector(getEventItemState, function (eventItemState) { return eventItemState.selectedTab; });
export var getUnassignedAttendeesAsDisjoint = createSelector(getUnassignedAttendees, function (unassignedAttendees) { return unassignedAttendees.map(function (a) { return ({ eventItem: null, assignedAttendee: a }); }); });
export var getInitiallyLoadedAttendeesWithDataState = createSelector(getEventItemState, function (eventItemState) {
    return {
        initiallyLoadedAttendees: attendeeUpsertEntitySelectors.selectAll(eventItemState.initiallyLoadedAttendees),
        dataState: eventItemState.initiallyLoadedAttendees.dataState,
    };
});
export function getInitiallyLoadedAttendees_OnceObservable(store$) {
    return store$.pipe(select(getInitiallyLoadedAttendeesWithDataState), takeWhile(function (data, index) {
        // This is a very misleading takeWhile, it looks like we want the value when dataState is Initial (and indeed we get it),
        // but actually we want the first (and only the first) value where initallyLoadedAttendees are NOT Initial
        // We also need the initial state so the page doesn't crash passing undefined to the Inputs
        // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
        // test below fails, but inclusive set to true delivers this last failed result
        return data.dataState === DataState.Initial;
    }, true), map(function (data) { return data.initiallyLoadedAttendees; }));
}
var ɵ12 = function (unassignedAttendeesDisjoint, attendeeDataState, eventItemDataState) {
    return { unassignedAttendeesDisjoint: unassignedAttendeesDisjoint, attendeeDataState: attendeeDataState, eventItemDataState: eventItemDataState };
};
// export const getImportedAttendees = createSelector(
//     getEventItemState,
//     eventItemState => eventItemState.importedAttendees
// );
var getUnassignedAttendeesAsDisjointWithDataStates = createSelector(getUnassignedAttendeesAsDisjoint, getAttendeeDataState, getEventItemDataState, ɵ12);
export function getUnassignedAttendeesAsDisjoint_OnceObservable(store$) {
    return store$.pipe(select(getUnassignedAttendeesAsDisjointWithDataStates), takeWhile(function (data, index) {
        // This is a very misleading takeWhile, it looks like we want the value when either is Initial (and indeed we get it),
        // but actually we want the first (and only the first) value where both attendees and eventItems are NOT Initial
        // We also need the initial state so the page doesn't crash passing undefined to the Inputs
        // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
        // test below fails, but inclusive set to true delivers this last failed result
        return (data.attendeeDataState === DataState.Initial || data.eventItemDataState === DataState.Initial);
    }, true), map(function (data) { return data.unassignedAttendeesDisjoint; }));
}
export var getEventItemWithAttendeeFlatList = createSelector(getEventItems, getAttendeeMappedByEventAttendeeId, function (eventItems, attendeesMap) {
    var e_9, _a;
    var eventItemWithAttendeeList = [];
    try {
        for (var eventItems_4 = tslib_1.__values(eventItems), eventItems_4_1 = eventItems_4.next(); !eventItems_4_1.done; eventItems_4_1 = eventItems_4.next()) {
            var eventItem = eventItems_4_1.value;
            var attendee = attendeesMap.get(eventItem.eventAttendeeId);
            if (!attendee) {
                eventItemWithAttendeeList.push({
                    eventItem: eventItem,
                    assignedAttendee: null,
                });
            }
            else {
                eventItemWithAttendeeList.push({
                    eventItem: eventItem,
                    assignedAttendee: attendee,
                });
            }
        }
    }
    catch (e_9_1) { e_9 = { error: e_9_1 }; }
    finally {
        try {
            if (eventItems_4_1 && !eventItems_4_1.done && (_a = eventItems_4.return)) _a.call(eventItems_4);
        }
        finally { if (e_9) throw e_9.error; }
    }
    return eventItemWithAttendeeList;
});
export var getEventItemWithAttendeeFlatListWithDataStates = createSelector(getEventItemWithAttendeeFlatList, getAttendeeDataState, getEventItemDataState, function (eventItemWithAttendeeFlatList, attendeeDataState, eventItemDataState) {
    return { eventItemWithAttendeeFlatList: eventItemWithAttendeeFlatList, attendeeDataState: attendeeDataState, eventItemDataState: eventItemDataState };
});
export function getEventItemWithAttendeeFlatList_OnceObservable(store$) {
    return store$.pipe(select(getEventItemWithAttendeeFlatListWithDataStates), takeWhile(function (data, index) {
        // This is a very misleading takeWhile, it looks like we want the value when either is Initial (and indeed we get it),
        // but actually we want the first (and only the first) value where both attendees and eventItems are NOT Initial
        // We also need the initial state so the page doesn't crash passing undefined to the Inputs
        // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
        // test below fails, but inclusive set to true delivers this last failed result
        return (data.attendeeDataState === DataState.Initial || data.eventItemDataState === DataState.Initial);
    }, true), map(function (data) { return data.eventItemWithAttendeeFlatList; }));
}
export var getEventItemsWithAttendeeGroupings = createSelector(getEventItems, getAttendeeMappedByEventAttendeeId, function (eventItems, attendeesMap) {
    var e_10, _a;
    var groupings = [];
    var groupingsIdxMap = new Map(); // key = group name, value = index into groupings
    try {
        for (var eventItems_5 = tslib_1.__values(eventItems), eventItems_5_1 = eventItems_5.next(); !eventItems_5_1.done; eventItems_5_1 = eventItems_5.next()) {
            var eventItem = eventItems_5_1.value;
            var groupName = eventItem.groupingName;
            var groupingsIdx = groupingsIdxMap.get(groupName);
            // If this group doesn't yet exist, initialize it
            if (typeof groupingsIdx !== 'number') {
                groupingsIdx = groupings.length;
                groupingsIdxMap.set(groupName, groupingsIdx);
                groupings.push({
                    title: getGroupingDisplayName(eventItem),
                    assignments: [],
                });
            }
            if (eventItem.eventAttendeeId === null) {
                groupings[groupingsIdx].assignments.push({
                    eventItem: eventItem,
                    assignedAttendee: null,
                });
            }
            else {
                var attendee = attendeesMap.get(eventItem.eventAttendeeId);
                if (!attendee) {
                    groupings[groupingsIdx].assignments.push({
                        eventItem: eventItem,
                        assignedAttendee: null,
                    });
                }
                else {
                    groupings[groupingsIdx].assignments.push({
                        eventItem: eventItem,
                        assignedAttendee: attendee,
                    });
                }
            }
        }
    }
    catch (e_10_1) { e_10 = { error: e_10_1 }; }
    finally {
        try {
            if (eventItems_5_1 && !eventItems_5_1.done && (_a = eventItems_5.return)) _a.call(eventItems_5);
        }
        finally { if (e_10) throw e_10.error; }
    }
    return groupings;
});
// This just maps the output of selector getEventItemsWithAttendeeGroupings from EventItemWithAttendeeGroup[] -> EventItemAndAttendeeDisjointGroup[]
// So it is not a 'true' disjoint in that it does not contain unassigned attendees (it does contain assigned event items, and unassigned event items).
// It is used with the drag/drop interface when assigning seats.
export var getEventItemsWithAttendeeGroupingsAsDisjoint = createSelector(getEventItemsWithAttendeeGroupings, function (groupings) { return groupings.map(function (group) {
    return {
        title: group.title,
        assignments: group.assignments.map(function (assignment) {
            return {
                eventItem: assignment.eventItem,
                assignedAttendee: assignment.assignedAttendee,
            };
        })
    };
}); });
var ɵ13 = function (groupings, attendeeDataState, eventItemDataState) {
    return { groupings: groupings, attendeeDataState: attendeeDataState, eventItemDataState: eventItemDataState };
};
var getEventItemsWithAttendeeGroupingsAsDisjointWithDataStates = createSelector(getEventItemsWithAttendeeGroupingsAsDisjoint, getAttendeeDataState, getEventItemDataState, ɵ13);
export function getEventItemsWithAttendeeGroupingsAsDisjoint_OnceObservable(store$) {
    return store$.pipe(select(getEventItemsWithAttendeeGroupingsAsDisjointWithDataStates), takeWhile(function (data, index) {
        // This is a very misleading takeWhile, it looks like we want the value when either is Initial (and indeed we get it),
        // but actually we want the first (and only the first) value where both attendees and eventItems are NOT Initial
        // We also need the initial state so the page doesn't crash passing undefined to the Inputs
        // Setting takeWhile's inclusive property to true is what gives us the first non-Initial value, as the
        // test below fails, but inclusive set to true delivers this last failed result
        return (data.attendeeDataState === DataState.Initial || data.eventItemDataState === DataState.Initial);
    }, true), map(function (data) { return data.groupings; }));
}
export var getDownloadTicketState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.downloadState; });
export var getNotifyAttendeeState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.notifyState; });
export var getDownloadManifestApiState = createSelector(getEventItemState, function (eventItemState) { return eventItemState.downloadManifestState.apiState; });
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
