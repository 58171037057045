import { MatDialog } from '@angular/material';
import { CreateFromBibOrderDialogComponent } from './create-from-bib-order-dialog/create-from-bib-order-dialog.component';
import { CreateFromSupplierOrderDialogComponent } from './create-from-supplier-order-dialog/create-from-supplier-order-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
        this.bibOrderNumberLoginDialogRef = null;
        this.supplierOrderNumberDialogRef = null;
    }
    DialogService.prototype.showBibOrderNumberLoginDialog = function () {
        this.bibOrderNumberLoginDialogRef = this.dialog.open(CreateFromBibOrderDialogComponent, {
            width: '400px',
            data: {},
        });
    };
    DialogService.prototype.showSupplierOrderNumberDialog = function (SupplierOrderNumber) {
        this.supplierOrderNumberDialogRef = this.dialog.open(CreateFromSupplierOrderDialogComponent, {
            width: '400px',
            data: { SupplierOrderNumber: SupplierOrderNumber },
        });
    };
    DialogService.prototype.closeBibOrderNumberLoginDialog = function () {
        if (this.bibOrderNumberLoginDialogRef == null)
            return;
        this.bibOrderNumberLoginDialogRef.close();
        this.bibOrderNumberLoginDialogRef = null;
    };
    DialogService.prototype.closeSupplierOrderNumberLoginDialog = function () {
        if (this.supplierOrderNumberDialogRef == null)
            return;
        this.supplierOrderNumberDialogRef.close();
        this.supplierOrderNumberDialogRef = null;
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
