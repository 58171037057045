import { Profile } from '../models/profile';
import * as userActions from '../actions/user';

export type State = Profile;

const initialState = <Profile> {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    photoUrl: '',
    paymentMethod: '',
    alternamePaymentMethod: ''
};

// initialize state to an empty array
export function reducer(state: State = initialState, action: userActions.Actions): State {
    switch (action.type) {
        case userActions.LOAD_GROUP_LEADER_PROFILE_OK:
        {
            return action.payload;
        }
        case userActions.LOGOUT:
        {
            return {
                ...initialState,
            };
        }
        default:
        {
            return state;
        }
    }
}
