import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AttendeesActions from '../actions/attendees.action';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { map, catchError, concatMap } from 'rxjs/operators';
import { AttendeePackageDetailService } from 'app/pages/attendee-package-details/attendee-package-details.service';
import { of } from 'rxjs';
import { GroupTixService } from '../services/group-tix.service';
var AttendeesEffects = /** @class */ (function () {
    function AttendeesEffects(actions$, eventService, apdService, groupTixService) {
        var _this = this;
        this.actions$ = actions$;
        this.eventService = eventService;
        this.apdService = apdService;
        this.groupTixService = groupTixService;
        this.loadAttendees = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.GeneralLoadAttendees, AttendeesActions.ActionTypes.GroupMembersTabLoadAttendees), concatMap(function (action) { return _this.eventService.getAttendees(action.payload.eventId).pipe(map(function (attendees) { return new AttendeesActions.AttendeeEffectLoadAttendeesSuccess({ attendees: attendees }); }), catchError(function (error) { return of(new AttendeesActions.AttendeeEffectLoadAttendeesFailure({ errorMessage: error.toString() })); })); }));
        this.saveAttendees = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.GroupMemberTabSaveAllAttendees, AttendeesActions.ActionTypes.GroupMemberTabAddNewAttendees, AttendeesActions.ActionTypes.ImportContactsToEventSaveAttendees, AttendeesActions.ActionTypes.PreviousAttendeesSaveAttendees), concatMap(function (action) { return _this.groupTixService.upsertAttendees(action.payload.attendees, action.payload.eventId).pipe(map(function (attendeeCollectionPayload) { return new AttendeesActions.AttendeeEffectSaveAttendeesSuccess({ attendeeCollectionPayload: attendeeCollectionPayload }); }), catchError(function (error) { return of(new AttendeesActions.AttendeeEffectSaveAttendeesFailure({ errorMessage: error.toString(), failedAttendees: action.payload.attendees.attendees })); })); }));
        this.addAttendees = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.PublicEventAddNewAttendee), concatMap(function (action) { return _this.groupTixService.publicEventAddAttendees(action.payload.attendee, action.payload.eventId).pipe(map(function (response) { return new AttendeesActions.PublicEventAddNewAttendeeSuccess({ response: response }); }), catchError(function (error) { return of(new AttendeesActions.PublicEventAddNewAttendeeFailure({ errorMessage: error.toString() })); })); }));
        this.updateAttendeeStatus = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.UpdateAttendeeStatusComponentUpdateAttendeeStatus), concatMap(function (action) {
            var formatted = {
                IdPackage: action.payload.attendee.eventId,
                status: action.payload.attendee.status,
                IdUser: action.payload.attendee.id
            };
            return _this.apdService.SetStatus(formatted).pipe(map(function (updateInvitationResponse) { return new AttendeesActions.AttendeeEffectUpdateAttendeeStatusSuccess({ updateInvitationResponse: updateInvitationResponse }); }), catchError(function (error) { return of(new AttendeesActions.AttendeeEffectUpdateAttendeeStatusFailure({ errorMessage: error.toString() })); }));
        }));
        this.changeAttendeeEmails = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.ChangeAttendeeEmailsComponentChangeAttendeeEmails), concatMap(function (action) { return _this.eventService.changeAttendeeEmails(action.payload.attendees).pipe(map(function (attendees) { return new AttendeesActions.AttendeeEffectChangeAttendeeEmailsSuccess({ attendees: attendees }); }), catchError(function (error) { return of(new AttendeesActions.AttendeeEffectChangeAttendeeEmailsFailure({ errorMessage: error.toString() })); })); }));
        this.inviteEventAttendees = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.GroupMemberTab_InviteEventAttendees), concatMap(function (action) { return _this.groupTixService.inviteEventAttendees(action.payload.inviteEventAttendeesRequest).pipe(map(function (inviteEventAttendeesResponse) { return new AttendeesActions.AttendeeEffect_InviteAttendeesSuccess({ attendeeIds: inviteEventAttendeesResponse }); }), catchError(function (error) { return of(new AttendeesActions.AttendeeEffect_InviteAttendeesFailure({ errorMessage: error.toString() })); })); }));
        this.loadLeaderSummary = this.actions$.pipe(ofType(AttendeesActions.ActionTypes.PackageDetailsEffectLoadLeaderSummary), concatMap(function (action) { return _this.eventService.getLeaderSummary(action.payload.eventId).pipe(map(function (leaderSummary) { return new AttendeesActions.AttendeeEffectLoadLeaderSummarySuccess({ leaderSummary: leaderSummary }); }), catchError(function (error) { return of(new AttendeesActions.AttendeeEffectLoadLeaderSummaryFailure({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "loadAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "saveAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "addAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "updateAttendeeStatus", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "changeAttendeeEmails", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "inviteEventAttendees", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AttendeesEffects.prototype, "loadLeaderSummary", void 0);
    return AttendeesEffects;
}());
export { AttendeesEffects };
