import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromUser from '../actions/user';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { UserService } from 'app/pages/profilePage/user.service';
import { of } from 'rxjs';
var UserEffects = /** @class */ (function () {
    function UserEffects(actions$, service, eventService) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.eventService = eventService;
        this.loadGroupLeaderProfile = this.actions$
            .pipe(ofType(fromUser.LOAD_GROUP_LEADER_PROFILE), switchMap(function (action) { return _this.eventService.getGroupLeaderProfile(action.payload)
            .pipe(map(function (profile) { return new fromUser.LoadGroupLeaderProfileOk(profile); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadUser = this.actions$
            .pipe(ofType(fromUser.LOAD_USER_STATE), switchMap(function (action) { return _this.service.getUser()
            .pipe(map(function (u) { return new fromUser.LoadUserStateOk(u); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.setAbout = this.actions$
            .pipe(ofType(fromUser.SAVE_ABOUT), switchMap(function (action) { return _this.service.updateUser(action.payload)
            .pipe(map(function (user) { return new fromUser.SaveAboutOk(user); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "loadGroupLeaderProfile", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "loadUser", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserEffects.prototype, "setAbout", void 0);
    return UserEffects;
}());
export { UserEffects };
