var QuestionBase = /** @class */ (function () {
    function QuestionBase(options) {
        if (options === void 0) { options = {}; }
        this.response = options.response;
        this.questionId = options.questionId;
        this.questionName = options.questionName || '';
        this.questionType = options.questionType || '';
        this.questionOrder = options.questionOrder || 0;
        this.required = options.required || false;
    }
    return QuestionBase;
}());
export { QuestionBase };
