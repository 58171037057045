import * as tslib_1 from "tslib";
import { OnDestroy, AfterViewInit } from '@angular/core';
import * as fromRoot from 'app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ModalSize } from 'app/store/models/modal';
import { GrouptoolsListComponent } from 'app/widgets/grouptools-list/grouptools-list.component';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import * as modalActions from 'app/store/actions/modal.action';
import * as eventActions from 'app/store/actions/event.action';
import { GroupToolsSuccessModalConfig, GroupToolsSuccessModal } from '../grouptools-success-modal/grouptools-success-modal.component';
import * as ContactsSelectors from 'app/store/selectors/contacts.selector';
import { Validators, FormBuilder } from '@angular/forms';
var AddAlternateLeaderComponent = /** @class */ (function () {
    function AddAlternateLeaderComponent(store, eventService, fb) {
        var _this = this;
        this.store = store;
        this.eventService = eventService;
        this.fb = fb;
        this.unsubscribe$ = new Subject();
        this.working = false;
        this.pristine = true;
        this.formSubmitted = false;
        this.NAME_REGX = "^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ \',.-]+$";
        this.EMAIL_REGX = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
        this.addGLFormErrors = {
            'FirstName': '',
            'LastName': '',
            'Email': ''
        };
        this.addGLFormValidationMessages = {
            'FirstName': {
                'pattern': 'First Name contains invalid characters.'
            },
            'LastName': {
                'pattern': 'Last Name contains invalid characters.'
            },
            'Email': {
                'required': 'Email is required.',
                'pattern': 'Invalid email.'
            }
        };
        this.addGLFormFields = {
            FirstName: [null, [Validators.pattern(this.NAME_REGX)]],
            LastName: [null, [Validators.pattern(this.NAME_REGX)]],
            Email: [null, [Validators.required, Validators.pattern(this.EMAIL_REGX)]],
        };
        // tslint:disable-next-line:member-ordering
        this.listOptions = {
            hideDate: true,
            multiSelectMode: true,
            innerCard: false,
            hideHeader: false,
            hideMenu: false,
            rowsOnPage: 5,
            columns: [
                {
                    fieldName: 'FirstName',
                    label: 'First'
                },
                {
                    fieldName: 'LastName',
                    label: 'Last'
                },
                {
                    fieldName: 'Email',
                    label: 'Email'
                },
            ],
            collapsed: false,
            defaultMessage: {
                link: 'no contacts found'
            }
        };
        this.contact$ = this.store.pipe(select(ContactsSelectors.getContacts));
        this.alternateLeaders$ = store.select(fromRoot.getAlternateLeaders).pipe(takeUntil(this.unsubscribe$), tap(function (leaders) { return _this.alternateLeaders = leaders; }));
        this.selectedEvent$ = store.select(fromRoot.getSelectedEvent).pipe(takeUntil(this.unsubscribe$));
    }
    AddAlternateLeaderComponent.prototype.ngOnInit = function () {
        this.buildAddGLForm();
    };
    AddAlternateLeaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.alternateLeaders$.subscribe(function (leaders) {
            var e_1, _a;
            _this.alternateLeaders = leaders;
            if (_this.contactList) {
                var _loop_1 = function (l) {
                    var row = _this.contactList.glGrid.rows.find(function (r) { return r.value.UserID === l.id; });
                    if (row && l.isActive) {
                        _this.contactList.multiSelect(row);
                        row.cells[0].isNew = true;
                    }
                };
                try {
                    for (var leaders_1 = tslib_1.__values(leaders), leaders_1_1 = leaders_1.next(); !leaders_1_1.done; leaders_1_1 = leaders_1.next()) {
                        var l = leaders_1_1.value;
                        _loop_1(l);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (leaders_1_1 && !leaders_1_1.done && (_a = leaders_1.return)) _a.call(leaders_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    AddAlternateLeaderComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    AddAlternateLeaderComponent.prototype.addLeaders = function (event) {
        var _this = this;
        if (!this.addGLForm.valid) {
            this.onAddGLFormValueChanged();
            return;
        }
        ;
        this.working = true;
        // get list of users that should be alternate leaders
        // const userList = this.contactList.multiSelectedData.map(row => {
        //   return <Profile>{
        //     id: row.value.UserID,
        //     email: row.value.Email,
        //     isActive: true
        //   };
        // });
        // // get current alternate leaders that need to be disabled
        // const disabledList = this.alternateLeaders.filter(alt => {
        //   // find the row representing this user
        //   const row = this.contactList.glGrid.rows.find(r => r.value.UserID === alt.id);
        //   // only include in the list if they are now unselected
        //   if (alt.isActive && row && !row.multiSelected) {
        //     alt.isActive = false; // update the record
        //     return true;
        //   } else {
        //     return false;
        //   }
        // });
        // const totalList = userList.concat(disabledList);
        var firstName = this.addGLForm.get('FirstName').value;
        var lastName = this.addGLForm.get('LastName').value;
        var email = this.addGLForm.get('Email').value;
        var profile = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            isActive: true
        };
        this.eventService.addAlternateLeaders(event.id, [profile]).subscribe(function (res) {
            _this.working = false;
            var config = tslib_1.__assign({}, GroupToolsSuccessModalConfig, { title: 'Alternate Group Leaders Updated', componentWithOptions: {
                    component: GroupToolsSuccessModal,
                    size: ModalSize.Small,
                    options: {
                        headerText: 'Success',
                        type: 'success',
                        buttons: {
                            primary: {
                                text: 'Return to Event',
                                action: function () {
                                    _this.store.dispatch(new eventActions.LoadAlternateLeaders(event.id));
                                    _this.store.dispatch(new modalActions.CloseModal());
                                }
                            }
                        }
                    }
                } });
            _this.store.dispatch(new modalActions.OpenModal(config));
        });
    };
    AddAlternateLeaderComponent.prototype.removeLeaders = function (event) {
        var _this = this;
        if (!this.addGLForm.valid) {
            this.onAddGLFormValueChanged();
            return;
        }
        ;
        this.working = true;
        // get list of users that should be alternate leaders
        // const userList = this.contactList.multiSelectedData.map(row => {
        //   return <Profile>{
        //     id: row.value.UserID,
        //     email: row.value.Email,
        //     isActive: true
        //   };
        // });
        // // get current alternate leaders that need to be disabled
        // const disabledList = this.alternateLeaders.filter(alt => {
        //   // find the row representing this user
        //   const row = this.contactList.glGrid.rows.find(r => r.value.UserID === alt.id);
        //   // only include in the list if they are now unselected
        //   if (alt.isActive && row && !row.multiSelected) {
        //     alt.isActive = false; // update the record
        //     return true;
        //   } else {
        //     return false;
        //   }
        // });
        // const totalList = userList.concat(disabledList);
        var firstName = this.addGLForm.get('FirstName').value;
        var lastName = this.addGLForm.get('LastName').value;
        var email = this.addGLForm.get('Email').value;
        var profile = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            isActive: false
        };
        this.eventService.addAlternateLeaders(event.id, [profile]).subscribe(function (res) {
            _this.working = false;
            var config = tslib_1.__assign({}, GroupToolsSuccessModalConfig, { title: 'Alternate Group Leaders Updated', componentWithOptions: {
                    component: GroupToolsSuccessModal,
                    size: ModalSize.Small,
                    options: {
                        headerText: 'Success',
                        type: 'success',
                        buttons: {
                            primary: {
                                text: 'Return to Event',
                                action: function () {
                                    _this.store.dispatch(new eventActions.LoadAlternateLeaders(event.id));
                                    _this.store.dispatch(new modalActions.CloseModal());
                                }
                            }
                        }
                    }
                } });
            _this.store.dispatch(new modalActions.OpenModal(config));
        });
    };
    AddAlternateLeaderComponent.prototype.close = function () {
        this.store.dispatch(new modalActions.CloseModal());
    };
    AddAlternateLeaderComponent.prototype.buildAddGLForm = function () {
        var _this = this;
        this.addGLForm = this.fb.group(this.addGLFormFields);
        this.addGLForm.valueChanges
            .subscribe(function (data) { return _this.onAddGLFormValueChanged(data); });
        this.onAddGLFormValueChanged(); //(re)set validation messages now
    };
    AddAlternateLeaderComponent.prototype.onAddGLFormValueChanged = function (data) {
        if (!this.addGLForm) {
            return;
        }
        if (this.formSubmitted) {
            this.onFormSubmit();
            return;
        }
        var form = this.addGLForm;
        for (var field in this.addGLFormErrors) {
            if (this.addGLFormErrors.hasOwnProperty(field)) {
                this.addGLFormErrors[field] = '';
                var control = form.get(field);
                if (control && control.dirty && !control.valid) {
                    var messages = this.addGLFormValidationMessages[field];
                    for (var key in control.errors) {
                        if (control.errors.hasOwnProperty(key)) {
                            this.addGLFormErrors[field] += messages[key] + ' ';
                        }
                    }
                }
            }
        }
    };
    AddAlternateLeaderComponent.prototype.onFormSubmit = function () {
        var form = this.addGLForm;
        for (var field in this.addGLFormErrors) {
            if (this.addGLFormErrors.hasOwnProperty(field)) {
                this.addGLFormErrors[field] = '';
                var control = form.get(field);
                if (control && !control.valid) {
                    var messages = this.addGLFormValidationMessages[field];
                    for (var key in control.errors) {
                        if (control.errors.hasOwnProperty(key)) {
                            this.addGLFormErrors[field] += messages[key] + ' ';
                        }
                    }
                }
            }
        }
    };
    return AddAlternateLeaderComponent;
}());
export { AddAlternateLeaderComponent };
export var AddAlternateLeaderConfig = {
    title: 'Manage Group Leaders',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: AddAlternateLeaderComponent
    }
};
