import { FacebookService } from './facebook.service';
import { LoginPerson, AuthService } from '../../authentication/authentication.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';

declare const FB: any;

@Component({
    selector: 'facebook-login',
    templateUrl: 'facebook-login.component.html'
})
export class FacebookLoginComponent implements OnInit {
    @Output('loginComplete') public loginComplete: EventEmitter<any> = new EventEmitter<any>();

    public facebookButtonImage = require(`assets/buttons/btn-facebook.png`);

    private appId = this.configService.config.facebookAppId; // '143944522840009'
    public permissions = this.configService.config.facebookPermissions; // 'public_profile,email'
    private fields = this.configService.config.facebookFields; // 'id,first_name,last_name,name,email'

    constructor(
        private _service: FacebookService,
        private configService: NgxConfigureService
    ) { }

    ngOnInit() {
        FB.init({
            appId : this.appId,
            autoLogAppEvents : true,
            xfbml : true,
            cookie: true,
            version : 'v5.0'
        });
        FB.AppEvents.logPageView();
    }

    facebookLogin() {
        FB.getLoginStatus((response) => {
            if(response.status === 'connected') {
                this.onFacebookResponse(response);
            } else {
                FB.login((res) => {
                    this.onFacebookResponse(res);
                }, { scope : this.permissions, return_scopes : true });
            }
        });

        return false;
    }

    onLoginComplete(response: any, accessToken: any): void {
        
        var hasEmail = false;
        if(response.email) {
            hasEmail = true;
        }

        var user: any = {
            Id: response.id,
            AccessToken: accessToken,
            Email: response.email,
            HasEmail: hasEmail,
            FirstName: response.first_name,
            LastName: response.last_name
        };

        //console.log(user);
        this.loginComplete.emit(user);
    }

    onFacebookResponse(response: any): void {
        //console.log(response);
        if (!response) {
            //console.log('Facebook callback returned null value');
            return;
        }

        // if (!response.authResponse) {
        //     // error
        //     //console.log('User cancelled login or did not fully authorize.');
        // }

        if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            FB.api('/me', 'get', (resp) => {
                this.onLoginComplete(resp, accessToken);
            }, { fields: this.fields });
        }
    }
}