<div class="row"><div class="col-xs-12 step-info-text">
  {{ isTemplateEvent ? templateInfoText : eventInfoText }}
</div></div>
<div class="row"></div>
<div class="row"><div class="col-xs-12">
  <!-- <div class="form-group">
    <label for="GroupLeaderFirstName">Group Leader First Name:</label>
    <input [(ngModel)]="leaderFirstName" type="text" class="form-control" id="GroupLeaderFirstName" placeholder="First Name">
  </div>
  <div class="form-group">
    <label for="GroupLeaderLastName">Group Leader Last Name:</label>
    <input [(ngModel)]="leaderLastName" type="text" class="form-control" id="GroupLeaderFirstName" placeholder="Last Name">
  </div>
  <div class="form-group">
      <label for="GroupLeaderEmail">Group Leader Email:</label>
      <input [(ngModel)]="leaderEmail" type="text" class="form-control" id="GroupLeaderEmail" placeholder="Email">
  </div> -->

  <form [(formGroup)]="assignEventForm">
    <div class="form-group row">
        <div class="col-md-8" [class.has-error]="assignEventFormErrors.FirstName">
          <label for="GroupLeaderFirstName">Group Leader First Name:</label>
          <input id="firstNameInput" type="text" class="form-control" formControlName="FirstName" placeholder="First Name">
        </div>
        <div *ngIf="assignEventFormErrors.FirstName" class="help-block sub-little-text col-xs-8">
            <p class="error-text">{{ assignEventFormErrors.FirstName }}</p>
        </div>                       
    </div>
    <div class="form-group row">
        <div class="col-md-8" [class.has-error]="assignEventFormErrors.LastName">
          <label for="GroupLeaderLastName">Group Leader Last Name:</label>
          <input id="lastNameInput" type="text" class="form-control" formControlName="LastName" placeholder="Last Name">
        </div>
        <div *ngIf="assignEventFormErrors.LastName" class="help-block sub-little-text col-xs-8">
            <p class="error-text">{{ assignEventFormErrors.LastName }}</p>
        </div>                     
    </div>
    <div class="form-group row">
        <div class="col-md-8" [class.has-error]="assignEventFormErrors.Email">
          <label for="GroupLeaderEmail">Group Leader Email:</label>
          <input id="emailInput" type="text" class="form-control" formControlName="Email" placeholder="Email">
        </div>                                               
        <div *ngIf="assignEventFormErrors.Email" class="help-block sub-little-text col-xs-8">
            <p class="error-text">{{ assignEventFormErrors.Email }}</p>
        </div>                      
    </div>                                      
  </form>

</div></div>
<div class="step-info-text-error" *ngIf="errorMessage">
  {{errorMessage}}
</div>
<div *ngIf="selectedEvent$ | async as selectedEvent" class="button-group-row">
  <div class="row button-row"><div class="col-xs-12 button-col">
    <button type="button" class="btn btn-primary form-control" 
      [disabled]="working" [ngStyle]="{'cursor': working ? 'progress' : 'pointer'}" (click)="assignEvent(selectedEvent)">Assign Event</button>
  </div></div>
  <div class="row button-row"><div class="col-xs-12 button-col">
    <button type="button" class="btn btn-secondary form-control" (click)="close()">Return to {{selectedEvent.status.toLowerCase() === 'template' ? 'Template' : 'Event'}}</button>
  </div></div>
</div>