
import {timer as observableTimer,  Observable, Subscription } from 'rxjs';

import {map, takeUntil, finalize} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { RegisterService } from '../register.service';
import { AuthService } from 'app/authentication/authentication.service';
import 'style-loader!./account-verification.component.scss';
import * as user from 'app/store/actions/user';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';

@Component({
  selector: 'account-verification',
  templateUrl: './account-verification.component.html',
  providers: [
    RegisterService
  ]
})

export class AccountVerificationComponent {
    @Input() loginName: string;
    @Input() loginEmail: string;
    @Input() registerInModal: boolean = true;
    @Input() isEventDetail: boolean = false;
    @Input() isInvitation: boolean = false;
    @Input() isAttendee: boolean = false;
    @Input() isPackageInvite: boolean = false;
    @Input() isEnterCode: boolean = false;
    @Input() action; 
    @Output() skipVerification: EventEmitter<null> = new EventEmitter();
    @Output() onlyLogin: EventEmitter<null> = new EventEmitter();
    @Output() showSuccessModal: EventEmitter<null> = new EventEmitter();
    @Output() changeModalTitle: EventEmitter<null> = new EventEmitter();

    public verifyCode: string;
    public switchCase: string = 'createAccountSuccess';
    public countDown;
    public count: number = 24*60*60;
    public verifyAccountClicked: boolean = false;
    public verifyingAccount: boolean = false;
    public enterTimes: number = 0;
    public enterOver: boolean = false;
    public timeOver: boolean = false;
    public timer$;
    public isVerified: boolean = false;

    constructor(
        private store: Store < fromRoot.State > ,
        private _registerService: RegisterService,
        private _authServie: AuthService,
      ) { }
    
    ngOnChanges(change: SimpleChanges){
        if(change.isEventDetail){
            if(change.isEventDetail){
                this.switchCase = "groupleaderVerification";
            }
        }
        if(change.isInvitation){
            if(change.isInvitation){
                this.switchCase = "groupleaderVerification";
            }
        }
        if(change.isAttendee){
            if(change.isAttendee){
                this.switchCase = "attendeeVerification";
            }
        }
        if(change.isEnterCode){
            if(change.isEnterCode){
                this.switchCase = "verifyAccount";
                this._registerService.GetVerificationCodeExpiration().subscribe(
                    res=>{
                        if(res.ResponseCode === 200){
                            var current = new Date();
                            var expiration = new Date(res.Obj);
                            var compare = (expiration.getTime()-current.getTime())/1000;
                            if(compare < 0){
                                this.countDown = 0;
                                this.enterOver = true;
                                this.verifyingAccount = false;
                            }
                            else
                                this.count = compare;
                        }
                        else{
                            this.countDown = 0;
                            this.enterOver = true;
                            this.verifyingAccount = false;
                        }

                    });
                        
                this.verifyCodeCountDown();
            }
        }
    }

    sendVerificationCode(){
        this._registerService.sendVerifyCode().subscribe(
            res=>{
                if(res.ResponseCode == 200){
                    this.changeModalTitle.emit();
                    this.switchCase = 'verifyAccount';
                    this.verifyCodeCountDown();
                }
            }
        );
    }

    Login(){
        if(this.registerInModal)
            this.sendVerificationCode();
        else
            this.onlyLogin.emit();
    }

    verifyAccount(){
        this.verifyingAccount = true;
        this.verifyAccountClicked = true;
        this.enterTimes++;

        this._registerService.ValidateVerificationCode({code: this.verifyCode}).subscribe(
            res=>{
                if(res.ResponseCode == 200){
                    this.isVerified = true;
                    if(this.isPackageInvite)
                        this.accountVerificationSuccess();
                    else if(!this.isEventDetail && !this.isInvitation && !this.isAttendee && !this.isEnterCode)
                        this.skipVerification.emit();
                    else
                        this.showSuccessModal.emit();
                }
                if(res.ResponseCode == 401){
                    this.isVerified = false;
                    if(this.enterTimes == 3){
                        this.enterOver = true;
                        this.verifyAccountClicked = false;
                        this.countDown = 0;
                        this.timer$.unsubscribe();
                    }
                }
                this.verifyingAccount = false;
            }
        );
    }

    sendVerifyCode(){
        this.enterOver = false;
        this.enterTimes = 0;
        this.timeOver = false;
        this.verifyCode='';
        this.verifyAccountClicked = false;
        this.sendVerificationCode();
        
    }

    verifyCodeCountDown(){
        const interval = 1000;
        const duration = this.count * 1000;
        const stream$ = observableTimer(0, interval).pipe(
          finalize(() => {
              //console.log("All done!");
          }),
          takeUntil(observableTimer(duration + interval)),
          map(value => duration - value * interval),);
        this.timer$ = stream$.subscribe(value => this.countDown = value/1000);
    }

    // getButtonText(){
    //     if(this.isEventDetail || this.isAttendee)
    //         return 'Return to Event Details';
    //     if(this.isInvitation)
    //         return 'Return to Invitation';
    //     return 'Cancel'
    // }

    accountVerificationSuccess(){
        this._authServie.refresh().subscribe(suc=>{
          this.store.dispatch(new user.SetVerifiedStatus(true));
          this.skipVerification.emit();
        });
      }
}
