import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { URLConfig } from 'app/pages/helpers/config.service';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
var GettingStartedService = /** @class */ (function () {
    function GettingStartedService(_http, _urls, store, _fb) {
        this._http = _http;
        this._urls = _urls;
        this.store = store;
        this._fb = _fb;
        this.enticingImg = require('assets/img/enticing_image.png');
        this.basicEventFormFields = {
            IdTenant: [1],
            IdUser: [''],
            PackageName: ['', Validators.required],
            PackageDesc: ['', Validators.required]
        };
        this.eventForm = this._fb.group(this.basicEventFormFields);
    }
    GettingStartedService.prototype.saveEvent = function (eventData) {
        return this._http.post(this._urls._packageInsertUrl(), eventData).pipe(map(function (res) {
            return res.Obj;
        }));
    };
    GettingStartedService.prototype.getShowData = function () {
        var _this = this;
        return this._http.get(this._urls._getCatalogShows()).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.map(function (show) {
            if (!show.Details.Logo) {
                show.Details.Logo = _this.enticingImg;
            }
            var newShow = show.Details;
            newShow.catalogItemId = show.catalogItemId;
            return newShow;
        }); }));
    };
    GettingStartedService.prototype.addShowComponent = function (eventId, catalogId) {
        return this._http.post(this._urls._packageInsertComponent(), this.formatShow(eventId, catalogId));
    };
    GettingStartedService.prototype.addInvoiceShowComponent = function (componentObj) {
        return this._http.post(this._urls._packageInsertComponent(), componentObj);
    };
    GettingStartedService.prototype.addComponentOption = function (optionObj) {
        return this._http.post(this._urls._addComponentOption(), optionObj);
    };
    GettingStartedService.prototype.addComponentOptionWithDefaults = function (optionObj) {
        return this._http.post(this._urls._addComponentOptionWithDefaults(), optionObj);
    };
    GettingStartedService.prototype.getOptionFromInvoiceLineItem = function (lineItem, packageId, componentId) {
        return {
            IdPackageComponent: componentId,
            IdPackage: packageId,
            OptionName: lineItem.Description,
            OptionDescription: lineItem.Description,
            AttendeeCost: lineItem.BasePrice,
            AttendeePrice: lineItem.AttendeePrice,
            Quantity: lineItem.Quantity,
            LineItemId: lineItem.IdExternalInvoice
        };
    };
    GettingStartedService.prototype.formatShow = function (eventId, catalogId) {
        return {
            IdPackage: eventId,
            ComponentSupplier: 1,
            ComponentCategory: 'show',
            catalogItemId: catalogId,
        };
    };
    GettingStartedService.prototype.getInvoiceDetails = function (invoiceNumber) {
        if (invoiceNumber === void 0) { invoiceNumber = ''; }
        return this._http.get(this._urls._packageInvoiceDetails(invoiceNumber)).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.InvoiceLineItems; }), map(function (res) {
            res.map(function (item) { return item.ProductTimeFormatted = new Date("01-01-01 " + item.ProductTime); });
            return res;
        }), map(function (lineItems) {
            if (lineItems.length === 1) {
                lineItems[0].Include = true;
            }
            return lineItems;
        }), catchError(this.handleError));
    };
    GettingStartedService.prototype.getPotentialPackagesForInvoice = function (invoiceNumber) {
        return this._http.get(this._urls._getPotentialPackagesForInvoice(invoiceNumber)).pipe(map(function (res) { return res.Obj; }));
    };
    GettingStartedService.prototype.applyInvoiceToEvent = function (formattedData) {
        return this._http.post(this._urls._applyInvoiceToEvent(), formattedData);
    };
    GettingStartedService.prototype.handleError = function (error) {
        var errMsg;
        if (error instanceof Response) {
            var body = error || '';
            var err = body || JSON.stringify(body);
            errMsg = error.status + " - " + (error.statusText || '') + " " + err;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        //console.error(errMsg);
        return observableThrowError(errMsg);
    };
    return GettingStartedService;
}());
export { GettingStartedService };
