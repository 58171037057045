import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { CreateFromBibOrderDialogComponent } from './create-from-bib-order-dialog/create-from-bib-order-dialog.component';
import { CreateFromSupplierOrderDialogComponent } from './create-from-supplier-order-dialog/create-from-supplier-order-dialog.component';
import { EventBuilderModule } from 'app/pages/event-builder/event-builder.module';

@NgModule({
  declarations: [
    // GenericDialogComponent,
    CreateFromBibOrderDialogComponent,
    CreateFromSupplierOrderDialogComponent,
  ],
  imports: [
    CommonModule,
    EventBuilderModule,
  ],
  entryComponents: [
    CreateFromBibOrderDialogComponent,
    CreateFromSupplierOrderDialogComponent,
  ]
})
export class DialogsModule { }
