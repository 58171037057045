<div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form-horizontal">

    <div class="row">
        <div class="col-xs-12">
            <div class="profile-picture">
                <img *ngIf="profile?.getImageUrl()" class="profile-image" [src]="profile?.getImageUrl()" />
            </div>
        </div>
    </div>

    <div class="row"><div class="col-xs-12 centered google-name">{{profile?.getName()}}</div></div>
    <div class="row"><div class="col-xs-12 helper-text">Would you like to register a GroupTools account with this email address?</div></div>
    <div class="row"><div class="col-xs-12 google-email">{{profile?.getEmail()}}</div></div>
    <div class="row"><div class="col-xs-12 helper-text padding-bottom">You can add a password to your new account to log in directly (without Google) if you want. Feel free to leave the password blank if you only want to log in with your Google account.</div></div>

    <!--PASSWORD  -->
    <div class="form-group row" [ngClass]="{'has-error': formErrors.password && (password.dirty) , 'has-success': !formErrors.password && password.valid}">
        <div class="col-xs-12">
        <input [formControl]="password" type="password" class="form-control" class="form-control" id="inputPassword3" placeholder="Password">
        <div *ngIf="formErrors.password" class="error-block">
            <p class="error-text">{{ formErrors.password }}</p>
        </div>
        </div>
    </div>

    <!--REPEAT PASSWORD  -->
    <div class="form-group row" [ngClass]="{'has-error': formErrors.passwords && (repeatPassword.dirty) , 'has-success': !formErrors.passwords && passwords.valid}">
        <div class="col-xs-12">
        <input [formControl]="repeatPassword" type="password" class="form-control" class="form-control" id="inputPassword4" placeholder="Repeat">
        <div *ngIf="formErrors.passwords" class="error-block">
            <p class="error-text">{{ formErrors.passwords }}</p>
        </div>
        </div>
    </div>

    <!--PASSWORD STRENGTH METER -->
    <div class="form-group row" *ngIf="password.value.length > 0">
        <div class="col-xs-12 psw-strength-block">
            <div class="strong-block" *ngIf="!formErrors.password && (password.value.length >= 12 || includeSpecialChar())">Password Strength: Strong</div>
            <div class="moderate-block" *ngIf="!formErrors.password && (password.value.length < 12 && !includeSpecialChar())">Password Strength: Moderate</div>
            <div class="weak-block" *ngIf="formErrors.password">Password Strength: Weak</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-xs-12">
            <button #button type="submit" class="btn btn-primary form-control googleBtn">Continue to GroupTools</button>
        </div>
    </div>

    </form>
</div>
