
import {map, switchMap, catchError, exhaustMap} from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromForm from '../actions/forms';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { AppStateService } from '../../pages/helpers/appState.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class FormEffects {
    @Effect()
    loadCreatedForms = this.actions$
        .pipe(
            ofType(fromForm.LOAD_OWNED_FORMS),
            exhaustMap(action => this.service.getOwnedForms()
                        .pipe(
                            map(forms => new fromForm.LoadOwnedFormsOk(forms)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadFormsForEvent = this.actions$
        .pipe(
            ofType<fromForm.LoadEventForms>(fromForm.LOAD_EVENT_FORMS),
            exhaustMap(action => this.service.getFormsForEvent(action.payload)
                        .pipe(
                            map(forms => new fromForm.LoadEventFormsOk(forms)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    // constructor last to prevent member-ordering ts-lint warnings
    constructor(
        private actions$: Actions,
        private service: AppStateService
    ) { }
}
