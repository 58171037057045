
import {
  Component, Input, Output, EventEmitter, OnChanges, SimpleChange, ComponentFactoryResolver, ViewContainerRef, Compiler
} from '@angular/core';

@Component({
  selector: 'ba-card',
  templateUrl: './baCard.component.html',
  styleUrls: []
})
export class BaCard implements OnChanges {
  @Input() collapsible = true;
  @Input() title: any;
  @Input() titleStyle: string = '';
  @Input() titleClass: string;
  @Input() baCardClass: String;
  @Input() cardType: String;
  @Input() IsExpand: boolean = false;
  @Output() CardEvent = new EventEmitter<boolean>();
  gtCardShow: boolean = true;
  showTitleString: boolean
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef, private compiler: Compiler) {
  }
  @Input() set collapsed(value) {
    if (value && value.toString() !== 'false') {
      this.gtCardShow = false;
    } else {
      this.gtCardShow = true;
    }
  }
  get collapsed() {
    return this.gtCardShow === false;
  }
  public toggleShowBody() {
    this.gtCardShow = !this.gtCardShow;
    this.CardEvent.emit(this.gtCardShow);
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (let propName in changes) {
      if (propName == 'IsExpand') {
        let change = changes[propName];
        if (change.currentValue) {
          this.gtCardShow = false;
        }
      }
      if (propName == 'title') {
        this.showTitleString = typeof this.title === 'string';
      }
    }
  }
}
