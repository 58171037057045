import { map } from 'rxjs/operators';
import { AmazonService } from './amazon.service';
import { EventEmitter, OnInit } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
var AmazonLoginComponent = /** @class */ (function () {
    function AmazonLoginComponent(_service, configService) {
        this._service = _service;
        this.configService = configService;
        this.loginComplete = new EventEmitter();
        this.amazonButtonImage = require("assets/buttons/btn-amazon.png");
        this.clientId = this.configService.config.amazonAppId;
    }
    AmazonLoginComponent.prototype.ngOnInit = function () {
        amazon.Login.setClientId(this.clientId);
    };
    AmazonLoginComponent.prototype.amazonLogin = function () {
        var _this = this;
        var options = {
            scope: 'profile',
            response_type: 'code',
            state: 'test_state',
        };
        amazon.Login.authorize(options, function (response) { return _this.onAmazonResponse(response); });
        return false;
    };
    AmazonLoginComponent.prototype.onAmazonResponse = function (response) {
        var _this = this;
        //console.log(response);
        if (!response) {
            //console.log('Amazon callback returned null value');
            return;
        }
        // if (response.status !== 'complete') {
        //     // error
        //     //console.log('Amazon callback returned an error');
        // }
        if (response.code) {
            this._service.GetAccessTokenFromCode(response.code).pipe(map(function (res) { return res.Obj; }))
                .subscribe(function (user) {
                _this.loginComplete.emit(user);
            });
        }
    };
    return AmazonLoginComponent;
}());
export { AmazonLoginComponent };
