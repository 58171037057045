
import {map} from 'rxjs/operators';
import { URLConfig } from '../../../pages/helpers/config.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { BaMenuService } from '../../services/baMenu/baMenu.service';
import { GlobalState } from '../../../global.state';

import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { AuthService } from '../../../authentication/authentication.service';

@Component({
  selector: 'ba-menu',
  templateUrl: './baMenu.html'
})
export class BaMenu {

  @Input() sidebarCollapsed: boolean = true;
  @Input() menuHeight: number;
  @Input() loggedIn: boolean;
  @Input() isGroupLeader: boolean;
  public LOGO = require(`assets/logo/GroupTools-Logo-nav.svg`);

  staticItems =
  [
    {
      'path': ['/pages/user'],
      'title': 'Profile',
      'icon': ''
    },
    {
      'path': 'https://support.grouptools.com/hc/',
      'title': 'Support',
      'icon': '',
      'link': true
    }
  ];
  @Output() expandMenu = new EventEmitter<any>();
  // public menuItems: any[];
  public menuItems: Observable<Array<any>>;
  protected _menuItemsSub: Subscription;
  public showHoverElem: boolean;
  public hoverElemHeight: number;
  public hoverElemTop: number;
  protected _onRouteChange: Subscription;
  public outOfArea: number = -200;

  constructor(
    private _router: Router,
    private _menuService: BaMenuService,
    private _authService: AuthService,
    private _state: GlobalState,
    private _urls: URLConfig,
    private store: Store<fromRoot.State>
  ) { }

  public ngOnChanges(change) {
    // update 2018-05-16 ska - check that access token exists because sometimes this is having
    // weird results on initial page load
    if (change.loggedIn) {
      if (change.loggedIn.currentValue && localStorage.access_token) {
        this.reloadMenuItems().subscribe();
      }
    }
    if (change.isGroupLeader) {
      if (change.isGroupLeader.currentValue) {
        this.reloadMenuItems().subscribe();
      }
    }
  }

  public reloadMenuItems(): Observable<any> {
    return this._authService.getLeaderStatus().pipe(map(({Obj: leaderStatus}) => {
      this._menuService.getMenuItems(leaderStatus).pipe(map((menuItems) => {
        this.menuItems = menuItems;
      })).subscribe();
    }));
  }

  public toggleMenu() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.sidebarCollapsed);
    return false;
  }
}
