<div class="row">
    <div class="col-xs-12 hotel-planner-logo centered">
        <img [src]="hotelPlannerLogo" alt="hotel-planner-logo">
    </div>
    <div class="col-xs-12">
        <p class="order-confirm-title">Listed among the top 50 travel sites in the world and ranked number one in group travel, HotelPlanner.com now receives over 5 million unique visitors each month.</p>
        <p>GroupTools has partnered with HotelPlanner.com to get you the best group rates for your event. Once your rooms have been booked, simply add them to your event and GroupTools will take care of the rest.</p>
    </div>
    <div class="col-xs-12 centered">
        <button type="button" class="btn btn-primary form-control hotel-btn" (click)="bookHotelLessRoom()">8 Rooms or Less, Continue Booking</button>
    </div>
    <div class="col-xs-12 centered">
        <button type="button" class="btn btn-primary form-control hotel-btn" (click)="bookHotelMoreRoom()">9 Rooms or More, Continue Booking</button>
    </div>
</div>
