import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EventDetailsFormComponent } from './event-details-form.component';
import { GTNgaModule } from '../../../../grouptools-theme/gtnga.module';
import { WidgetsModule } from '../../../../widgets/widgets.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GTNgaModule,
        WidgetsModule
    ],
    declarations: [
        EventDetailsFormComponent,
    ],
    exports: [
        EventDetailsFormComponent
    ]
})
export class EventDetailsFormModule { }