export var SET_SELECTED_ID = '[Event] Set Selected Event ID';
export var LOGOUT = '[Event] Logout';
var SetSelectedEventId = /** @class */ (function () {
    function SetSelectedEventId(payload) {
        this.payload = payload;
        this.type = SET_SELECTED_ID;
    }
    return SetSelectedEventId;
}());
export { SetSelectedEventId };
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = LOGOUT;
    }
    return Logout;
}());
export { Logout };
