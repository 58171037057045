import * as tslib_1 from "tslib";
import * as ConfigurationActions from "../actions/configuration.action";
export var initialState = {
    venueConfiguration: {
        allowPartialPayments: false,
        daysBeforeEventTicketsAreAvailable: 14,
        barcodeFormat: "",
        maxTicketQuantity: 5,
        requireNames: true,
        defaultEventImage: "",
        defaultActivityImage: "",
        ticketDistributionPreference: "",
        allowHybridPayments: false,
        allowPriceChange: false,
        additionalPayPalFee: 0.00,
        enablePayToSupplier: false,
        enableSeatAssignment: false,
    }
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ConfigurationActions.ActionTypes.GeneralLoadConfiguration:
            {
                return tslib_1.__assign({}, state);
            }
        case ConfigurationActions.ActionTypes.ConfigurationEffectLoadConfigurationSuccess:
            {
                return tslib_1.__assign({}, state, { venueConfiguration: action.payload.configurationState.venueConfiguration });
            }
        case ConfigurationActions.ActionTypes.ConfigurationEffectLoadConfigurationFailure:
            {
                return tslib_1.__assign({}, state);
            }
        //---------------------------------------------------------------------        
        default:
            {
                return state;
            }
    }
}
