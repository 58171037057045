
import {throwError as observableThrowError, Subject,  Observable } from 'rxjs';

import {map, catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../../authentication/authentication.service';
import { URLConfig } from './config.service';
import { HttpClient } from '@angular/common/http';
import { GTResponse, GTResponseHelper, handleGTResponse } from 'app/store/models/gtResponse';
import { Event } from 'app/store/models/event';
import { Form } from 'app/store/models/form';
import { LeaderSummary } from 'app/store/models/leader-summary';
import { Router, NavigationEnd } from '@angular/router';
import { TransferredEvent } from 'app/store/models/transferred-event';
import { PackageDTO } from 'app/store/models/packages/PackageDTO';
import { MessageSummaryDTO } from 'app/store/models/packages/MessageSummaryDTO';
import { PackageSurveyDTO } from 'app/store/models/packages/PackageSurveyDTO';
import { SimplePackageDTO } from 'app/store/models/packages/SimplePackageDTO';

export interface PackageAttendeeInterface {
  ContactName: string;
  IdContact: number;
  IdUser: number;
  Status: string;
}

export interface PackageSupplierInterface {
  ComponentName: string;
  IdPackageComponent: number;
  SupplierId: number;
  SupplierCode: string;
  SupplierName: string;
  SupplierType: number;
}

export interface AttendeeSupplierInterface {
  eventAttendeeId: number;
  id: number;
  ContactName?: string;
  IdContact?: number;
  IdUser?: number;
  Status?: string;
  ComponentName?: string;
  IdPackageComponent?: number;
  SupplierId?: number;
  SupplierCode?: string;
  SupplierName?: string;
  SupplierType?: number;
}

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AppStateService {
  public imgSrc: any;
  public myPackages: PackageDTO[];
  public currentPackage: any;
  public isGroupLeader: Observable<any>;

  private emailPrefill$: Subject<string> = new Subject<string>();

  constructor(
    private URLs: URLConfig,
    private http: HttpClient
  ) {
  }

  public getMyPackages(): Observable<PackageDTO[]> {
    return this.http.get<GTResponse<PackageDTO[]>>(this.URLs._packageGetUrl()).pipe(
      handleGTResponse(),
      tap(packages => {
        this.myPackages = packages;
      }),
      tap(packages => {
        packages.map(p => {
          if (p.EventStatus.toUpperCase() !== 'CANCELLED') {
            p.customActions = ['View Details'];
          }
        })
      }),
      catchError(handleError),
    );
  }

  public getOwnedPackages(): Observable<SimplePackageDTO[]> {
    return this.http.get<GTResponse<SimplePackageDTO[]>>(this.URLs._packagesOwnedUrl()).pipe(
      handleGTResponse(),
    );
  }

  public getInvitedEvents(): Observable<Event[]> {
    return this.http.get<GTResponse<any[]>>(this.URLs._getInvitedEvents()).pipe(
      handleGTResponse(),
    );
  }

  public getOwnedEvents(): Observable<Event[]> {
    return this.http.get<GTResponse<any[]>>(this.URLs._getOwnedEvents()).pipe(
      handleGTResponse(),
    );
  }

  public getTransferredEvents(): Observable<TransferredEvent[]> {
    return this.http.get<GTResponse<any[]>>(this.URLs._getTransferredEvents()).pipe(
      handleGTResponse(),
    );
  }

  public getOwnedForms(): Observable<Form[]> {
    return this.http.get<GTResponse<any[]>>(this.URLs._getOwnedForms()).pipe(
      handleGTResponse(),
    );
  }

  public getFormsForEvent(eventId: number): Observable<Form[]> {
    return this.http.get<GTResponse<Form[]>>(this.URLs._getFormsForPackage(eventId)).pipe(
      handleGTResponse(),
    );
  }

  public getMessagesCount(): Observable<MessageSummaryDTO> {
    return this.http.get<GTResponse<MessageSummaryDTO>>(this.URLs._messagesSummary()).pipe(
      handleGTResponse(),
    );
  }

  public getEvents(): Observable<any> {
    return this.http.get<any>(this.URLs._packageMyPackagesUrl());
  }

  //==================================================================================================================
  public getSurveyEvents(): Observable<PackageSurveyDTO[]> { // called in dashboard, gl dashboard, and survey dashboard
    return this.http.get<GTResponse<PackageSurveyDTO[]>>(this.URLs._getMySurveyEvents()).pipe(
      handleGTResponse(),
    );
  }
  public getSurveyAttendees(): Observable<PackageSurveyDTO[]> { // called in dashboard, and survey dashboard
    return this.http.get<GTResponse<PackageSurveyDTO[]>>(this.URLs._getSurveyAttendees()).pipe(
      handleGTResponse(),
    );
  }
  //==================================================================================================================

  public getEmailPrefill(): Observable<string> {
    return this.emailPrefill$.asObservable();
  }

  public setEmailPrefill(email: string): void {
    this.emailPrefill$.next(email);
  }

}

export const handleError = (error: Response | any) => {
  let errMsg: string;
  if (error instanceof Response) {
    const body = error || '';
    const err = body || JSON.stringify(body);
    errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  } else {
    errMsg = error.message ? error.message : error.toString();
  }
  //console.error(errMsg);
  return observableThrowError(errMsg);
};
