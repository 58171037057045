import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ConfigurationActions from '../actions/configuration.action';
import { Injectable } from '@angular/core';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigurationService } from 'app/util/configuration.service';

@Injectable()
export class ConfigurationEffects {

    @Effect()
    loadConfiguration = this.actions$.pipe(
        ofType<ConfigurationActions.GeneralLoadConfiguration>
                (ConfigurationActions.ActionTypes.GeneralLoadConfiguration),
        concatMap(action => this.configurationService.getConfiguration(action.payload.getConfigurationRequest).pipe(
            map(configurationState => new ConfigurationActions.ConfigurationEffectLoadConfigurationSuccess({configurationState})),
            catchError(error => of(new ConfigurationActions.ConfigurationEffectLoadConfigurationFailure({errorMessage: error.toString()}))),
        )),
    );

    constructor(
        private actions$: Actions,
        private configurationService: ConfigurationService,
    ) {}
}
