import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericQuestionComponent } from './generic-question.component';
import { GTNgaModule } from '../../../../../../../grouptools-theme/gtnga.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GTNgaModule
    ],
    declarations: [
        GenericQuestionComponent,
    ],
    exports: [
        GenericQuestionComponent
    ]
})
export class GenericQuestionModule { }