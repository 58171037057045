import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { ImportContactsComponent } from 'app/pages/contacts/import-contacts/import-contacts.component';
import { ImportListComponent } from 'app/pages/contacts/import-contacts/import-list/import-list.component';
import { CsvImportComponent } from 'app/pages/contacts/import-contacts/csv-import/csv-import.component';
import { ImportContactsService } from 'app/pages/contacts/import-contacts/import-contacts.service';
import { GoogleLoginModule } from 'app/pages/google-login/google-login.module';
import { NgxUploaderModule } from 'ngx-uploader';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { SocialComponentsModule } from 'app/pages/social-components/social-components.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        WidgetsModule,
        GoogleLoginModule,
        NgxUploaderModule,
        GTNgaModule,
        SocialComponentsModule
    ],
    providers: [
        ImportContactsService
    ],
    declarations: [
        ImportContactsComponent,
        ImportListComponent,
        CsvImportComponent
    ],
    exports: [
        ImportContactsComponent
    ]
})
export class ImportContactsModule { }