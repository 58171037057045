import * as tslib_1 from "tslib";
import { map, switchMap, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromEvent from '../actions/event.action';
import * as activityActions from '../actions/activity.action';
import * as AttendeesActions from 'app/store/actions/attendees.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { AppStateService } from '../../pages/helpers/appState.service';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { of } from 'rxjs';
var EventEffects = /** @class */ (function () {
    // constructor last to prevent member-ordering ts-lint warnings
    function EventEffects(actions$, service, eventService) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.eventService = eventService;
        // The effect will listen to the LOAD_OWNED_EVENTS action. When it is dispatched,
        // this effect will call the web service to retrieve all owned events.
        // If successful, it will switchmap to a new action: LOAD_OWNED_EVENTS_OK with
        //   the events as the payload.
        // If not successful, it will call the error handler
        this.loadCreatedEvents = this.actions$
            .pipe(ofType(fromEvent.LOAD_OWNED_EVENTS), exhaustMap(function (action) { return _this.service.getOwnedEvents()
            .pipe(map(function (events) { return new fromEvent.LoadOwnedEventsOk(events); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadTransferredEvents = this.actions$
            .pipe(ofType(fromEvent.LOAD_TRANSFERRED_EVENTS), exhaustMap(function (action) { return _this.service.getTransferredEvents()
            .pipe(map(function (events) { return new fromEvent.LoadTransferredEventsOk(events); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadInvitedEvents = this.actions$
            .pipe(ofType(fromEvent.LOAD_INVITED_EVENTS), switchMap(function (action) { return _this.service.getInvitedEvents()
            .pipe(map(function (events) { return new fromEvent.LoadInvitedEventsOk(events); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadSelectedEvent = this.actions$
            .pipe(ofType(fromEvent.LOAD_SELECTED_EVENT), switchMap(function (action) { return _this.eventService.getSelectedEvent(action.payload)
            .pipe(map(function (event) {
            if (!event.shortUrl) {
                return new fromEvent.RequestShortUrl(event);
            }
            return new fromEvent.LoadSelectedEventOk(event);
        }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.updateEvent = this.actions$
            .pipe(ofType(fromEvent.UPDATE_EVENT), exhaustMap(function (action) { return _this.eventService.updateEvent(action.payload)
            .pipe(map(function (event) { return new fromEvent.UpdateEventOk(event); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadAlternateLeaders = this.actions$
            .pipe(ofType(fromEvent.LOAD_ALTERNATE_LEADERS), switchMap(function (action) { return _this.eventService.getAlternateLeaders(action.payload)
            .pipe(map(function (alternates) { return new fromEvent.LoadAlternateLeadersOk(alternates); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.loadEventOverview = this.actions$
            .pipe(ofType(fromEvent.LOAD_EVENT_OVERVIEW), switchMap(function (action) { return _this.eventService.getEventOverview(action.payload)
            .pipe(map(function (eventOverview) { return new fromEvent.LoadEventOverviewOk(eventOverview); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.requestShortUrl = this.actions$
            .pipe(ofType(fromEvent.REQUEST_SHORT_URL), switchMap(function (action) { return _this.eventService.requestShortUrl(action.payload)
            .pipe(
        // if we successfully update the shortUrl, push an update event ok message
        // because it's the same result: an updated event!
        map(function (event) { return new fromEvent.UpdateEventOk(event); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.convertToTemplate = this.actions$
            .pipe(ofType(fromEvent.CONVERT_TO_TEMPLATE), exhaustMap(function (action) { return _this.eventService.convertToTemplate(action.payload)
            .pipe(mergeMap(function (event) { return [
            new fromEvent.ConvertToTemplateOk(event),
            new fromEvent.LoadSelectedEventOk(event)
        ]; }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.cloneEvent = this.actions$
            .pipe(ofType(fromEvent.CLONE_EVENT), exhaustMap(function (action) { return _this.eventService.cloneEvent(action.payload)
            .pipe(map(function (event) { return new fromEvent.CloneEventOk(event); }), catchError(function (error) { return of(new UnhandledErrorActions.UnhandledError({ errorMessage: error.toString() })); })); }));
        this.assignEvent = this.actions$
            .pipe(ofType(fromEvent.ASSIGN_EVENT), exhaustMap(function (action) { return _this.eventService.assignEvent(action.payload)
            .pipe(map(function (event) { return new fromEvent.AssignEventOk(event); }), catchError(function (error) { return of(new fromEvent.AssignEventError({ errorMessage: error.toString() })); })); }));
        this.cloneAndAssignEvent = this.actions$
            .pipe(ofType(fromEvent.CLONE_AND_ASSIGN_EVENT), exhaustMap(function (action) { return _this.eventService.cloneAndAssignEvent(action.payload)
            .pipe(map(function (event) { return new fromEvent.CloneAndAssignEventOk(event); }), catchError(function (error) { return of(new fromEvent.AssignEventError({ errorMessage: error.toString() })); })); }));
        // Aggregate reducer to clear selected data
        // An Effect() must return an Observable of an Action. By default if nothing is returned
        // The same observable<Action> that triggered it will be sent again.
        // Instead, we want to switchmap to an observable of all of the individual actions we
        // want to take. Fortunately, we can just return an array of actions
        this.clearSelectedEvent = this.actions$
            .pipe(ofType(fromEvent.CLEAR_SELECTED_EVENT_DATA), switchMap(function (event) { return [
            new fromEvent.ClearSelectedEvent(),
            new activityActions.ClearSelectedActivities(),
            new AttendeesActions.EventEffectClearAttendees(),
        ]; }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "loadCreatedEvents", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "loadTransferredEvents", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "loadInvitedEvents", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "loadSelectedEvent", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "updateEvent", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "loadAlternateLeaders", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "loadEventOverview", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "requestShortUrl", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "convertToTemplate", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "cloneEvent", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "assignEvent", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "cloneAndAssignEvent", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], EventEffects.prototype, "clearSelectedEvent", void 0);
    return EventEffects;
}());
export { EventEffects };
