import { URLConfig } from '../helpers/config.service';
import { concat, forkJoin } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
var EventDetailsService = /** @class */ (function () {
    function EventDetailsService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
    }
    EventDetailsService.prototype.getSelectedEvent = function (eventId) {
        return this.http.get(this.URLs._getEvent(eventId)).pipe(handleGTResponse(), tap(function (event) { return event.options = JSON.parse(JSON.stringify(event.options)); }), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.getLeaderSummary = function (eventId) {
        return this.http.get(this.URLs._getLeaderEventSummary(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.getAlternateLeaders = function (eventId) {
        return this.http.get(this.URLs._getAlternateLeaders(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.getGroupLeaderProfile = function (eventId) {
        return this.http.get(this.URLs._getGroupLeaderProfile(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.getActivities = function (eventId) {
        return this.http.get(this.URLs._getActivities(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.createActivity = function (activity) {
        return this.http.post(this.URLs._createActivity(activity.eventId), activity).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.updateActivity = function (activity) {
        return this.http.put(this.URLs._updateActivity(activity.eventId, activity.id), activity).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.deleteActivity = function (activity) {
        // return this.http.delete<GTResponse<Activity>>(this.URLs._deleteActivity(activity.eventId), activity).pipe(
        //     handleGTResponse(),
        // );
        // This is used instead of the above because the delete method doesn't have an overload that passes a body (because this is not a RESTful use of the DELETE http verb)
        return this.http.request('delete', this.URLs._deleteActivity(activity.eventId, activity.id), { body: activity }).pipe(handleGTResponse()); // TODO: change the delete endpoint to be RESTful so we can use the official "delete" method on HttpClient
    };
    EventDetailsService.prototype.createOption = function (option) {
        return this.http.post(this.URLs._createOption(option.eventId, option.activityId), option).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.updateOption = function (option) {
        return this.http.put(this.URLs._updateOption(option.eventId, option.activityId, option.id), option).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.createActivityAndOptions = function (activity) {
        var _this = this;
        // TODO: Check with Kyle about memory leaks here. I don't think it will be an issue since they are local variables, but good to be sure.
        var createActivity$ = this.createActivity(activity).pipe(tap(function (a) { return activity.options.forEach(function (option) { return option.activityId = a.id; }); }) // Set the options activityId after the activity is created
        );
        // Create an array of observables to create each option
        var createOption$Array = activity.options.map(function (option) { return _this.createOption(option); });
        // forkJoin waits until all observables have completed, then combines the last emitted value of each into an array.
        // since Angular's HttpClient methods (ex:this.http.post) return an observable that emit one value (the response) then complete, forkJoin works well here.
        // It is essentially a way of sending http request in parallel.
        var createAllOptionsInParallel$ = forkJoin(createOption$Array);
        // concat executes the given observables in series, so essentially the activity is created first, then after, all the options are created in parallel (from forkJoin above)
        var createActivityAndOptions$ = concat(createActivity$, createAllOptionsInParallel$).pipe(toArray(), // combines the two observable results into a single array result
        map(function (resultArray) {
            if (resultArray.length <= 0) {
                console.log('resultArray is empty');
                throw new Error('resultArray has 0 length');
            }
            var activityResult = resultArray[0];
            if (resultArray.length > 1) {
                var options = resultArray[1];
                activityResult.options = options; // Since we are creating a new activity and option, it can just be replaced instead of appended/merged.
            }
            return activityResult;
        }));
        return createActivityAndOptions$;
    };
    EventDetailsService.prototype.getEventOverview = function (eventId) {
        return this.http.get(this.URLs._getEventOverview(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.deleteOption = function (option) {
        // return this.http.delete<GTResponse<Option>>(this.URLs._deleteOption(option.eventId, option.activityId), option).pipe(
        //     handleGTResponse(),
        // );
        // This is used instead of the above because the delete method doesn't have an overload that passes a body (because this is not a RESTful use of the DELETE http verb)
        return this.http.request('delete', this.URLs._deleteOption(option.eventId, option.activityId, option.id), { body: option }).pipe(handleGTResponse()); // TODO: change the delete endpoint to be RESTful so we can use the official "delete" method on HttpClient
    };
    EventDetailsService.prototype.getEventItems = function (eventId, activityId, optionId) {
        var params = new HttpParams(); // Careful with HttpParams, it is immutable. Must reassign to add params.
        if (typeof activityId === 'number')
            params = params.set('activityId', activityId.toString());
        if (typeof optionId === 'number')
            params = params.set('optionId', optionId.toString());
        var options = { params: params };
        return this.http.get(this.URLs._getEventItems(eventId), options).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.assignAttendeesToItems = function (eventId, eventItemAssignments) {
        return this.http.post(this.URLs._assignAttendeesToItems(eventId), eventItemAssignments).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.assignAttendeesToItemsAutomatically = function (eventId) {
        return this.http.post(this.URLs._assignAttendeesToItemsAutomatically(eventId), {}).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.getAttendees = function (eventId) {
        return this.http.get(this.URLs._getAttendees(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.getAttendeesEim = function (eventId) {
        return this.http.get(this.URLs._getAttendeesEim(eventId)).pipe(handleGTResponse());
    };
    EventDetailsService.prototype.changeAttendeeEmails = function (updatedAttendees) {
        return this.http.post(this.URLs._changeAttendeeEmails(updatedAttendees[0].eventId), updatedAttendees).pipe(handleGTResponse());
    };
    // packageComponentsGetByID(packageId): Observable<any> {
    //     return this.http.get<any>(this.URLs._packageComponentsGetByID(packageId)).pipe(
    //         map(res => {
    //             if (res.Result) {
    //                 return res.Obj;
    //             } else {
    //                 return []; //initialize the components, otherwise it will get error when there is no component in a new event
    //             }
    //         }),
    //     );
    // }
    // TODO: Make this return an Observable with a concrete type
    // public getPayments(packageId: number): Observable<SeparatedPaymentsDTO> {
    //     return this.http.get<GTResponse<SeparatedPaymentsDTO>>(this.URLs._paymentsGetUrlOld(packageId)).pipe(
    //         handleGTResponse(),
    //     );
    // }
    EventDetailsService.prototype.updateEvent = function (e) {
        return this.http.put(this.URLs._updateEvent(e.id), e).pipe(handleGTResponse(), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.requestShortUrl = function (e) {
        return this.http.put(this.URLs._requestShortUrl(e.id), e).pipe(handleGTResponse(), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.convertToTemplate = function (e) {
        return this.http.post(this.URLs._makeTemplate(e.id), e).pipe(handleGTResponse(), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.cloneEvent = function (e) {
        return this.http.post(this.URLs._cloneEvent(e.id), e).pipe(handleGTResponse(), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.assignEvent = function (e) {
        return this.http.put(this.URLs._assignEvent(e.id), e).pipe(handleGTResponse(), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.cloneAndAssignEvent = function (e) {
        return this.http.post(this.URLs._cloneAndAssignEvent(e.id), e).pipe(handleGTResponse(), setDefaultPhotoUrl());
    };
    EventDetailsService.prototype.addAlternateLeaders = function (eventId, users) {
        return this.http.post(this.URLs._addAlternateLeader(eventId), users).pipe(handleGTResponse());
    };
    return EventDetailsService;
}());
export { EventDetailsService };
// custom operator for setting default image
var setDefaultPhotoUrl = function () { return function (source) {
    return source.pipe(tap(function (event) {
        if (!event.photoUrl) {
            event.photoUrl = require("assets/img/default-image.jpg");
        }
    }));
}; };
var ɵ0 = setDefaultPhotoUrl;
export { ɵ0 };
