import { NgModule } from '@angular/core';
import { 
  MatButtonModule, 
  MatButtonToggleModule, 
  MatCardModule, 
  MatCheckboxModule, 
  MatDatepickerModule, 
  MatFormFieldModule, 
  MatIconModule, 
  MatInputModule, 
  MatSelectModule, 
  MatSortModule, 
  MatTableModule, 
  MatTabsModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatStepperModule,
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatStepperModule,
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    DragDropModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatStepperModule,
  ]
})
export class MaterialModule { }
