import * as tslib_1 from "tslib";
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
var NgbDateNativeAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateNativeAdapter, _super);
    function NgbDateNativeAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateNativeAdapter.prototype.fromModel = function (date) {
        var realDate;
        if (date && date.indexOf('T') !== -1) {
            // this is in the server's format, un-timezone it
            var year = date.substring(0, 4);
            var month = date.substring(5, 7) - 1; // months are 1-indexed...
            var day = date.substring(8, 10);
            realDate = new Date(year, month, day);
        }
        else {
            // this is in the datepicker's format, leave it be
            var parsedDate = new Date(Date.parse(date));
            realDate = parsedDate;
        }
        return (realDate && realDate.getFullYear) ?
            { year: realDate.getFullYear(), month: realDate.getMonth() + 1, day: realDate.getDate() }
            : null;
    };
    NgbDateNativeAdapter.prototype.toModel = function (date) {
        return date ? date.month + "/" + date.day + "/" + date.year : null;
    };
    return NgbDateNativeAdapter;
}(NgbDateAdapter));
export { NgbDateNativeAdapter };
