import { AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap, delay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as user from 'app/store/actions/user';
import { AuthService } from 'app/authentication/authentication.service';
import { GrouptoolsModalComponent } from 'app/widgets/grouptools-modal/grouptools-modal.component';
import { LoginComponent } from 'app/pages/login/login.component';
var IframeLoginComponent = /** @class */ (function () {
    function IframeLoginComponent(store, authService) {
        this.store = store;
        this.authService = authService;
        this.state = 'login';
        this.modalSize = 'modal-md';
        this.unsubscribe$ = new Subject();
    }
    IframeLoginComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.user$ = this.store.select(fromRoot.getUserState).pipe(delay(0), tap(function (u) { return _this.isVerified = u.isVerified; }), tap(function (u) {
            _this.loginName = u.firstName + " " + u.lastName;
            _this.loginEmail = u.email;
            if (u.isLoggedIn) {
                if (_this.isVerified) {
                    _this.modal.hideModal();
                    _this.state = 'loggedIn';
                }
                else {
                    _this.modalState = 'accountVerification';
                    _this.modalTitle = 'Account Verification';
                    _this.modal.showModal();
                }
            }
            else {
                _this.state = 'login';
            }
            if (window.parent) {
                window.parent.postMessage(u, 'http://grouptools.com');
            }
        }), takeUntil(this.unsubscribe$));
    };
    IframeLoginComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    IframeLoginComponent.prototype.setState = function (state) {
        this.state = state;
    };
    IframeLoginComponent.prototype.logout = function () {
        this.authService.logoutNoNavigation();
    };
    IframeLoginComponent.prototype.loginAction = function (event) {
        var _this = this;
        switch (event) {
            case 'register':
                this.state = 'register';
                break;
            case 'passwordReset':
                this.modalState = 'passwordReset';
                this.modalTitle = 'Reset Password';
                this.modal.showModal();
                break;
            case 'closeModal':
                break;
            case 'login':
                this.state = 'login';
                break;
            case 'loggedIn':
                break;
            case 'verified':
                this.authService.refresh().subscribe(function (suc) {
                    _this.store.dispatch(new user.SetVerifiedStatus(true));
                    _this.state = 'loggedIn';
                });
                this.modal.hideModal();
                break;
            default:
                break;
        }
    };
    return IframeLoginComponent;
}());
export { IframeLoginComponent };
