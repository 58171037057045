import { Component, ViewChild, ElementRef, Input, Output, OnInit, EventEmitter,SimpleChanges, SimpleChange } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
 
@Component({
  selector: 'grouptools-modal',
  templateUrl: './grouptools-modal.component.html'
})
export class GrouptoolsModalComponent {
  public styles: string[] = ['modal-md']
  public auto: string;
  @Input() title: string;
  @Input() size: string;
  @Input() static: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() buttonConfig: ButtonConfig;
  
  @Output() onHidden: EventEmitter<any> = new EventEmitter();
  @Output() primaryButtonClick: EventEmitter<string> = new EventEmitter();
  @Output() secondaryButtonClick: EventEmitter<string> = new EventEmitter();

  @ViewChild('grouptoolsModal') public grouptoolsModal:ModalDirective;

  config: ModalConfig = {
    backdrop: true
  };
  public showModal(){
    this.grouptoolsModal.show();
  }
  public hideModal(){
    this.grouptoolsModal.hide();
  }
  ngOnChanges(change: SimpleChanges) {
    const staticChange: SimpleChange = change.static;
    const sizeChange: SimpleChange = change.size;
    if(staticChange){
      if(this.static) {
        this.config = {backdrop: 'static'};
      }
      else{
        this.config.backdrop = true;
      }
    }
    if(sizeChange) {
      if(this.size) {
        this.styles.length = 0;
        if(this.size.indexOf('modal-sm') > -1) {
          this.styles.push('modal-sm');
        }
        if (this.size.indexOf('modal-md') > -1) {
          this.styles.push('modal-md');
        }
        if (this.size.indexOf('modal-lg') > -1) {
          this.styles.push('modal-lg');
        }
        if(this.size.indexOf('auto') > -1) {
          this.styles.push('auto');
          this.auto = 'auto';
        }
      }
    } 
  }
}

export interface ModalConfig {
  state?: string;
  title?: string;
  size?: string;
  buttonConfig?: ButtonConfig;
  backdrop?: any;
  static?: boolean;
  showHeader?: boolean;
};

export interface ButtonConfig {
  primary?: {
    text: string;
    action: any;
    disabled?: boolean;
  },
  secondary?: {
    text: string;
    action: any;
    disabled?: boolean;
  }
}