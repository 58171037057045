<div class="row"><div class="col-xs-12">
  <!-- <div *ngIf="contact$ | async as contacts">
    <grouptools-list #contactList
      [source]="contacts"
      [title]="'Contacts'"
      [clOptions]="listOptions"
    ></grouptools-list>
  </div>  -->

  <form [(formGroup)]="addGLForm">
    <div class="form-group row">
        <div class="col-md-8" [class.has-error]="addGLFormErrors.FirstName">
          <label for="GroupLeaderFirstName">Group Leader First Name:</label>
          <input id="firstNameInput" type="text" class="form-control" formControlName="FirstName" placeholder="First Name">
        </div>
        <div *ngIf="addGLFormErrors.FirstName" class="help-block sub-little-text col-xs-8">
            <p class="error-text">{{ addGLFormErrors.FirstName }}</p>
        </div>                       
    </div>
    <div class="form-group row">
        <div class="col-md-8" [class.has-error]="addGLFormErrors.LastName">
          <label for="GroupLeaderLastName">Group Leader Last Name:</label>
          <input id="lastNameInput" type="text" class="form-control" formControlName="LastName" placeholder="Last Name">
        </div>
        <div *ngIf="addGLFormErrors.LastName" class="help-block sub-little-text col-xs-8">
            <p class="error-text">{{ addGLFormErrors.LastName }}</p>
        </div>                     
    </div>
    <div class="form-group row">
        <div class="col-md-8" [class.has-error]="addGLFormErrors.Email">
          <label for="GroupLeaderEmail">Group Leader Email:</label>
          <input id="emailInput" type="text" class="form-control" formControlName="Email" placeholder="Email">
        </div>                                               
        <div *ngIf="addGLFormErrors.Email" class="help-block sub-little-text col-xs-8">
            <p class="error-text">{{ addGLFormErrors.Email }}</p>
        </div>                      
    </div>                                      
  </form>

</div></div>
<div *ngIf="selectedEvent$ | async as selectedEvent" class="button-group-row">
  <div class="row button-row"><div class="col-xs-12 button-col">
    <button type="button" class="btn btn-primary form-control" 
      [disabled]="working" [ngStyle]="{'cursor': working ? 'progress' : 'default'}" (click)="addLeaders(selectedEvent)">Add Leader</button>
  </div></div>
  <div class="row button-row"><div class="col-xs-12 button-col">
    <button type="button" class="btn btn-primary form-control" 
      [disabled]="working" [ngStyle]="{'cursor': working ? 'progress' : 'default'}" (click)="removeLeaders(selectedEvent)">Remove Leader</button>
  </div></div>
  <div class="row button-row"><div class="col-xs-12 button-col">
    <button type="button" class="btn btn-secondary form-control" (click)="close()">Return to Event</button>
  </div></div>
</div>