import { Injectable } from '@angular/core';
import { Event, EventWithEmail } from 'app/store/models/event'
import { URLConfig } from '../helpers/config.service';
import { Observable, concat, forkJoin } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import { LeaderSummary } from 'app/store/models/leader-summary';
import { Profile } from 'app/store/models/profile';
import { Activity } from 'app/store/models/activity';
import { Attendee } from 'app/store/models/attendee';
import { Option } from 'app/store/models/option';
import { EventOverview } from 'app/store/models/eventOverview';
import { EventItem } from 'app/store/models/eventItem';
import { SeparatedPaymentsDTO } from 'app/store/models/packages/SeparatedPaymentsDTO';
import { EventItemAssignment } from 'app/store/models/eventItemState';


@Injectable()
export class EventDetailsService {

    public constructor(
        private http: HttpClient,
        private URLs: URLConfig
    ) { }

    public getSelectedEvent(eventId: number): Observable<Event> {
        return this.http.get<GTResponse<Event>>(this.URLs._getEvent(eventId)).pipe(
            handleGTResponse(),
            tap(event => event.options = JSON.parse(JSON.stringify(event.options))),
            setDefaultPhotoUrl(),
        );
    }

    public getLeaderSummary(eventId: number): Observable<LeaderSummary> {
        return this.http.get<GTResponse<LeaderSummary>>(this.URLs._getLeaderEventSummary(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public getAlternateLeaders(eventId: number): Observable<Profile[]> {
        return this.http.get<GTResponse<Profile[]>>(this.URLs._getAlternateLeaders(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public getGroupLeaderProfile(eventId: number): Observable<Profile> {
        return this.http.get<GTResponse<Profile>>(this.URLs._getGroupLeaderProfile(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public getActivities(eventId: number): Observable<Activity[]> {
        return this.http.get<GTResponse<Activity[]>>(this.URLs._getActivities(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public createActivity(activity: Activity): Observable<Activity> {
        return this.http.post<GTResponse<Activity>>(this.URLs._createActivity(activity.eventId), activity).pipe(
            handleGTResponse(),
        );
    }

    public updateActivity(activity: Activity): Observable<Activity> {
        return this.http.put<GTResponse<Activity>>(this.URLs._updateActivity(activity.eventId, activity.id), activity).pipe(
            handleGTResponse(),
        );
    }

    public deleteActivity(activity: Activity): Observable<Activity> {
        // return this.http.delete<GTResponse<Activity>>(this.URLs._deleteActivity(activity.eventId), activity).pipe(
        //     handleGTResponse(),
        // );

        // This is used instead of the above because the delete method doesn't have an overload that passes a body (because this is not a RESTful use of the DELETE http verb)
        return this.http.request<GTResponse<Activity>>('delete', this.URLs._deleteActivity(activity.eventId, activity.id), { body: activity }).pipe(
            handleGTResponse(),
        ); // TODO: change the delete endpoint to be RESTful so we can use the official "delete" method on HttpClient
    }

    public createOption(option: Option): Observable<Option> {
        return this.http.post<GTResponse<Option>>(this.URLs._createOption(option.eventId, option.activityId), option).pipe(
            handleGTResponse(),
        );
    }

    public updateOption(option: Option): Observable<Option> {
        return this.http.put<GTResponse<Option>>(this.URLs._updateOption(option.eventId, option.activityId, option.id), option).pipe(
            handleGTResponse(),
        );
    }

    public createActivityAndOptions(activity: Activity): Observable<Activity> {
        // TODO: Check with Kyle about memory leaks here. I don't think it will be an issue since they are local variables, but good to be sure.
        const createActivity$: Observable<Activity> = this.createActivity(activity).pipe(
            tap(a => activity.options.forEach(option => option.activityId = a.id)) // Set the options activityId after the activity is created
        );

        // Create an array of observables to create each option
        const createOption$Array: Observable<Option>[] = activity.options.map(option => this.createOption(option));

        // forkJoin waits until all observables have completed, then combines the last emitted value of each into an array.
        // since Angular's HttpClient methods (ex:this.http.post) return an observable that emit one value (the response) then complete, forkJoin works well here.
        // It is essentially a way of sending http request in parallel.
        const createAllOptionsInParallel$: Observable<Option[]> = forkJoin(createOption$Array);

        // concat executes the given observables in series, so essentially the activity is created first, then after, all the options are created in parallel (from forkJoin above)
        const createActivityAndOptions$ = concat(
            createActivity$,
            createAllOptionsInParallel$
        ).pipe(
            toArray(), // combines the two observable results into a single array result
            map((resultArray: (Activity | Option[])[]) => { // this map combines results into a single Activity object
                if (resultArray.length <= 0) { console.log('resultArray is empty'); throw new Error('resultArray has 0 length'); }
                const activityResult: Activity = resultArray[0] as Activity;
                if (resultArray.length > 1) {
                    const options: Option[] = resultArray[1] as Option[];
                    activityResult.options = options; // Since we are creating a new activity and option, it can just be replaced instead of appended/merged.
                }
                return activityResult;
            })
        );

        return createActivityAndOptions$;
    }

    public getEventOverview(eventId: number): Observable<EventOverview> {
        return this.http.get<GTResponse<EventOverview>>(this.URLs._getEventOverview(eventId)).pipe(
            handleGTResponse(),
        )
    }

    public deleteOption(option: Option): Observable<Option> {
        // return this.http.delete<GTResponse<Option>>(this.URLs._deleteOption(option.eventId, option.activityId), option).pipe(
        //     handleGTResponse(),
        // );

        // This is used instead of the above because the delete method doesn't have an overload that passes a body (because this is not a RESTful use of the DELETE http verb)
        return this.http.request<GTResponse<Option>>('delete', this.URLs._deleteOption(option.eventId, option.activityId, option.id), { body: option }).pipe(
            handleGTResponse(),
        ); // TODO: change the delete endpoint to be RESTful so we can use the official "delete" method on HttpClient
    }

    public getEventItems(eventId: number, activityId?: number, optionId?: number): Observable<EventItem[]> {
        let params = new HttpParams(); // Careful with HttpParams, it is immutable. Must reassign to add params.
        if (typeof activityId === 'number') params = params.set('activityId', activityId.toString());
        if (typeof optionId === 'number') params = params.set('optionId', optionId.toString());
        const options = { params: params };
        return this.http.get<GTResponse<EventItem[]>>(this.URLs._getEventItems(eventId), options).pipe(
            handleGTResponse(),
        );
    }

    public assignAttendeesToItems(eventId: number, eventItemAssignments: EventItemAssignment[]): Observable<EventItem[]> {
        return this.http.post<GTResponse<EventItem[]>>(this.URLs._assignAttendeesToItems(eventId), eventItemAssignments).pipe(
            handleGTResponse(),
        );
    }

    public assignAttendeesToItemsAutomatically(eventId: number) { // TODO: rethink if PATCH is the best http method for this endpoint
        return this.http.post<GTResponse<EventItem[]>>(this.URLs._assignAttendeesToItemsAutomatically(eventId), {}).pipe(
            handleGTResponse(),
        );
    }

    public getAttendees(eventId: number): Observable<Attendee[]> {
        return this.http.get<GTResponse<Attendee[]>>(this.URLs._getAttendees(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public getAttendeesEim(eventId: number): Observable<Attendee[]> {
        return this.http.get<GTResponse<Attendee[]>>(this.URLs._getAttendeesEim(eventId)).pipe(
            handleGTResponse(),
        );
    }

    public changeAttendeeEmails(updatedAttendees: Attendee[]): Observable<Attendee[]> {
        return this.http.post<GTResponse<Attendee[]>>(this.URLs._changeAttendeeEmails(updatedAttendees[0].eventId), updatedAttendees).pipe(
            handleGTResponse(),
        );
    }

    // packageComponentsGetByID(packageId): Observable<any> {
    //     return this.http.get<any>(this.URLs._packageComponentsGetByID(packageId)).pipe(
    //         map(res => {
    //             if (res.Result) {
    //                 return res.Obj;
    //             } else {
    //                 return []; //initialize the components, otherwise it will get error when there is no component in a new event
    //             }
    //         }),
    //     );
    // }

    // TODO: Make this return an Observable with a concrete type
    // public getPayments(packageId: number): Observable<SeparatedPaymentsDTO> {
    //     return this.http.get<GTResponse<SeparatedPaymentsDTO>>(this.URLs._paymentsGetUrlOld(packageId)).pipe(
    //         handleGTResponse(),
    //     );
    // }

    public updateEvent(e: Event): Observable<Event> {
        return this.http.put<GTResponse<Event>>(this.URLs._updateEvent(e.id), e).pipe(
            handleGTResponse(),
            setDefaultPhotoUrl(),
        );
    }

    public requestShortUrl(e: Event): Observable<Event> {
        return this.http.put<GTResponse<Event>>(this.URLs._requestShortUrl(e.id), e).pipe(
            handleGTResponse(),
            setDefaultPhotoUrl(),
        );
    }

    public convertToTemplate(e: Event): Observable<Event> {
        return this.http.post<GTResponse<Event>>(this.URLs._makeTemplate(e.id), e).pipe(
            handleGTResponse(),
            setDefaultPhotoUrl(),
        );
    }

    public cloneEvent(e: Event): Observable<Event> {
        return this.http.post<GTResponse<Event>>(this.URLs._cloneEvent(e.id), e).pipe(
            handleGTResponse(),
            setDefaultPhotoUrl(),
        );
    }

    public assignEvent(e: EventWithEmail): Observable<Event> {
        return this.http.put<GTResponse<Event>>(this.URLs._assignEvent(e.id), e).pipe(
            handleGTResponse(),
            setDefaultPhotoUrl(),
        );
    }

    public cloneAndAssignEvent(e: EventWithEmail): Observable<Event> {
        return this.http.post<GTResponse<Event>>(this.URLs._cloneAndAssignEvent(e.id), e).pipe(
            handleGTResponse(),
            setDefaultPhotoUrl(),
        );
    }

    public addAlternateLeaders(eventId: number, users: Profile[]): Observable<Profile[]> {
        return this.http.post<GTResponse<Profile[]>>(this.URLs._addAlternateLeader(eventId), users).pipe(
            handleGTResponse(),
        );
    }
}

// custom operator for setting default image
const setDefaultPhotoUrl = () => (source: Observable<Event>) =>
    source.pipe(
        tap(event => {
            if (!event.photoUrl) {
                event.photoUrl = require(`assets/img/default-image.jpg`);
            }
        })
    );
