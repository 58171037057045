import { Action } from '@ngrx/store';
import { Modal, ModalSize, ComponentWithOptions } from 'app/store/models/modal';

export const OPEN_MODAL = '[Modal] Open';
export const CLOSE_MODAL = '[Modal] Close';
export const MODAL_BUTTON_PRESSED = '[Modal] Button Pressed';
export const SET_MODAL_COMPONENT = '[Modal] Set Component';
export const SET_MODAL_SIZE = '[Modal] Set Size';
export const SET_MODAL_TITLE = '[Modal] Set Title';
export const SET_FULL_SCREEN_MOBILE = '[Modal] Set Is Full Screen Mobile';

export class OpenModal implements Action {
    readonly type = OPEN_MODAL;

    constructor(public payload: Modal) {}
}

export class CloseModal implements Action {
    readonly type = CLOSE_MODAL;

    constructor() {}
}

export class ModalButtonPressed implements Action {
    readonly type = MODAL_BUTTON_PRESSED;

    constructor(public payload: string) {}
}

export class SetModalComponent implements Action {
    readonly type = SET_MODAL_COMPONENT;

    constructor(public payload: ComponentWithOptions) {}
}

export class SetModalSize implements Action {
    readonly type = SET_MODAL_SIZE;

    constructor(public payload: ModalSize) {}
}

export class SetModalTitle implements Action {
    readonly type = SET_MODAL_TITLE;

    constructor(public payload: string) {}
}

export class SetIsFullScreenMobile implements Action {
    readonly type = SET_FULL_SCREEN_MOBILE;

    constructor(public payload: boolean) {}
}

export type Actions 
 = OpenModal |
 CloseModal |
 SetModalComponent |
 SetModalTitle |
 SetModalSize |
 SetIsFullScreenMobile;
 