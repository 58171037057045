import { Action } from '@ngrx/store';

export const SET_SELECTED_ID = '[Event] Set Selected Event ID';
export const LOGOUT = '[Event] Logout';

export class SetSelectedEventId implements Action {
  readonly type = SET_SELECTED_ID;
  constructor(public payload: number) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}


export type Actions
  = SetSelectedEventId |
  Logout;
