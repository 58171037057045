import { EventEmitter, NgZone } from '@angular/core';
import 'style-loader!../login.component.scss';
import { AuthService, LoginPerson } from '../../../authentication/authentication.service';
var LoginWithSocialComponent = /** @class */ (function () {
    function LoginWithSocialComponent(_authService, zone) {
        this._authService = _authService;
        this.zone = zone;
        this.emailPrefill = '';
        this.inModal = false;
        this.differentAccount = new EventEmitter();
        this.googleRegisterEmit = new EventEmitter();
        this.amazonRegisterEmit = new EventEmitter();
        this.facebookRegisterEmit = new EventEmitter();
        this.handlePostLogin = new EventEmitter();
        this.openDifferentAccountModal = new EventEmitter();
        this.openGoogleRegisterModal = new EventEmitter();
        this.openAmazonRegisterModal = new EventEmitter();
        this.openFacebookRegisterModal = new EventEmitter();
    }
    LoginWithSocialComponent.prototype.socialMatchesPrefillCheck = function (email, socialType, userObj) {
        if (this.emailPrefill &&
            (this.emailPrefill.toLocaleLowerCase() === email.toLocaleLowerCase())) {
            if (socialType === 'google') {
                this.googleLogin(userObj);
            }
            else if (socialType === 'amazon') {
                this.amazonLogin(userObj);
            }
            else if (socialType === 'facebook') {
                this.facebookLogin(userObj);
            }
        }
        else if (this.emailPrefill) {
            // make sure user knows they're logging into a different account
            if (this.inModal) {
                this.differentAccount.emit({ type: socialType, value: userObj });
            }
            else {
                this.openDifferentAccountModal.emit();
            }
        }
        else {
            if (socialType === 'google') {
                this.googleLogin(userObj);
            }
            else if (socialType === 'amazon') {
                this.amazonLogin(userObj);
            }
            else if (socialType === 'facebook') {
                this.facebookLogin(userObj);
            }
        }
    };
    LoginWithSocialComponent.prototype.google = function (googleUser) {
        this.googleUser = googleUser;
        var profile = googleUser.getBasicProfile();
        var email = profile.getEmail();
        this.socialMatchesPrefillCheck(email, 'google', googleUser);
    };
    LoginWithSocialComponent.prototype.googleLogin = function (googleUser) {
        var _this = this;
        this.zone.run(function () {
            _this.googleUser = googleUser;
            var profile = googleUser.getBasicProfile();
            var email = profile.getEmail();
            var idToken = googleUser.getAuthResponse().id_token;
            var accessToken = googleUser.getAuthResponse().access_token;
            var encodedPassword = encodeURIComponent(idToken);
            var encodedAccessToken = encodeURIComponent(accessToken);
            _this.loginPerson = new LoginPerson(email, encodedPassword, encodedAccessToken);
            _this._authService.login(_this.loginPerson, 'google')
                .subscribe(function (token) {
                _this.handlePostLogin.emit(token);
            }, function (err) {
                if (_this.inModal) {
                    _this.googleRegisterEmit.emit(googleUser);
                }
                else {
                    _this.openGoogleRegisterModal.emit(googleUser);
                }
            });
        });
    };
    LoginWithSocialComponent.prototype.amazon = function (amazonUser) {
        this.amazonUser = amazonUser;
        var email = amazonUser.Email;
        this.socialMatchesPrefillCheck(email, 'amazon', amazonUser);
    };
    LoginWithSocialComponent.prototype.amazonLogin = function (amazonUser) {
        var _this = this;
        this.zone.run(function () {
            var token = encodeURIComponent(amazonUser.accessToken);
            _this.amazonUser = amazonUser;
            _this.loginPerson = new LoginPerson(amazonUser.Email, token);
            _this._authService.login(_this.loginPerson, 'amazon')
                .subscribe(function (authToken) {
                _this.handlePostLogin.emit(authToken);
            }, function (err) {
                if (_this.inModal) {
                    _this.amazonRegisterEmit.emit(amazonUser);
                }
                else {
                    _this.openAmazonRegisterModal.emit(amazonUser);
                }
            });
        });
    };
    LoginWithSocialComponent.prototype.facebook = function (facebookUser) {
        this.facebookUser = facebookUser;
        var email = facebookUser.Email;
        this.socialMatchesPrefillCheck(email, 'facebook', facebookUser);
    };
    LoginWithSocialComponent.prototype.facebookLogin = function (facebookUser) {
        var _this = this;
        this.zone.run(function () {
            var token = encodeURIComponent(facebookUser.AccessToken);
            _this.facebookUser = facebookUser;
            _this.loginPerson = new LoginPerson(facebookUser.Email, token);
            _this._authService.login(_this.loginPerson, 'facebook')
                .subscribe(function (authToken) {
                _this.handlePostLogin.emit(authToken);
            }, function (err) {
                if (_this.inModal) {
                    _this.facebookRegisterEmit.emit(facebookUser);
                }
                else {
                    _this.openFacebookRegisterModal.emit(facebookUser);
                }
            });
        });
    };
    return LoginWithSocialComponent;
}());
export { LoginWithSocialComponent };
