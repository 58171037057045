import { ApplicationRef } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from './app.service';
import { GlobalState } from './global.state';
import { AuthService } from './authentication/authentication.service';
import { URLConfig } from './pages/helpers/config.service';
import { CurrencyHelper } from './pages/helpers/currency-helper';
import { ContactService } from 'app/pages/contacts/contact.service';
import { NgbDateNativeAdapter } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDatePickerAdapter.service';
import { NgbDateCustomParserFormatter } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDateParser.service';
// import { TestModalComponent } from './modals/test-modal.component';
import { GettingStartedService } from './pages/getting-started/getting-started.service';
import { UpdateAttendeeStatusComponent } from './modals/update-attendee-status/update-attendee-status.component';
import { AttendeePackageDetailService } from './pages/attendee-package-details/attendee-package-details.service';
import { GtLoaderConfigService } from './pages/helpers/gt-loader-config.service';
import { ConfigurationService } from './util/configuration.service';
import { PayPalHelper } from './pages/helpers/paypal-helper';
// Application wide providers
var APP_PROVIDERS = [
    AppState,
    GlobalState,
    AuthService,
    GettingStartedService,
    ContactService,
    AttendeePackageDetailService,
    URLConfig,
    CurrencyHelper,
    PayPalHelper,
    GtLoaderConfigService,
    UpdateAttendeeStatusComponent,
    ConfigurationService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
];
/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
var AppModule = /** @class */ (function () {
    function AppModule(appRef, appState) {
        this.appRef = appRef;
        this.appState = appState;
    }
    return AppModule;
}());
export { AppModule };
