import { Action } from "@ngrx/store";
import { Attendee } from "../models/attendee";
import { GTCollectionPayload } from "../models/gtResponse";
import { LeaderSummary } from "../models/leader-summary";
import { UpdateInvitationResponse } from "../models/packages/UpdateInvitationResponse";
import { UpsertAttendeesRequest } from "../models/requests/upsert-attendees-request";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import { InviteEventAttendeesRequest } from "../models/requests/invite-event-attendees-request";
import { AddPublicAttendeeResponse } from "../models/responses/add-public-attendee-response";

export enum ActionTypes {

    GeneralLoadAttendees = "[General] Load Attendees",
    GroupMembersTabLoadAttendees = "[Group Members Tab] Load Attendees",
    AttendeeEffectLoadAttendeesSuccess = "[Attendee Effect] Load Attendees Success",
    AttendeeEffectLoadAttendeesFailure = "[Attendee Effect] Load Attendees Failure",

    // These are synchronous actions (not part of autosaving, need to block ui until completed)
    GroupMemberTabSaveAllAttendees = '[Group Member Tab] Save All Attendees',
    GroupMemberTabAddNewAttendees = '[Group Member Tab] Add New Attendees',
    ImportContactsToEventSaveAttendees = '[Import Contacts to Event] Save Attendees',
    PreviousAttendeesSaveAttendees = '[Previous Attendees] Save Attendees',
    AttendeeEffectSaveAttendeesSuccess = '[Attendee Effect] Save Attendees Success',
    AttendeeEffectSaveAttendeesFailure = '[Attendee Effect] Save Attendees Failure',

    PublicEventAddNewAttendee = '[Public Event] Add New Attendee',
    PublicEventAddNewAttendeeSuccess = '[Public Event] Add New Attendee Success',
    PublicEventAddNewAttendeeFailure = '[Public Event] Add New Attendee Failure',
    

    UpdateAttendeeStatusComponentUpdateAttendeeStatus = "[Update Attendee Status Component] Update Attendee Status",
    AttendeeEffectUpdateAttendeeStatusSuccess = "[Attendee Effect] Update Attendee Status Success",
    AttendeeEffectUpdateAttendeeStatusFailure = "[Attendee Effect] Update Attendee Status Failure",

    ChangeAttendeeEmailsComponentChangeAttendeeEmails = "[Change Attendee Emails Component] Change Attendee Emails",
    AttendeeEffectChangeAttendeeEmailsSuccess = "[Attendee Effect] Change Attendee Emails Success",
    AttendeeEffectChangeAttendeeEmailsFailure = "[Attendee Effect] Change Attendee Emails Failure",

    GroupMemberTab_InviteEventAttendees = '[Group Member Tab] Invite Attendees',
    AttendeeEffect_InviteAttendeesSuccess = '[Attendee Effect] Invite Attendees Success',
    AttendeeEffect_InviteAttendeesFailure = '[Attendee Effect] Invite Attendees Failure',

    PackageDetailsEffectLoadLeaderSummary = "[Package Details Effect] Load Leader Summary",
    AttendeeEffectLoadLeaderSummarySuccess = "[Attendee Effect] Load Leader Summary Success",
    AttendeeEffectLoadLeaderSummaryFailure = "[Attendee Effect] Load Leader Summary Failure",

    EventEffectClearAttendees = "[Event Effect] Clear Attendees",
}

//----------------------------------------------------------------
export class GeneralLoadAttendees implements Action {
    readonly type = ActionTypes.GeneralLoadAttendees;
    constructor(public payload: {eventId: number}) {}
}
export class GroupMembersTabLoadAttendees implements Action {
    readonly type = ActionTypes.GroupMembersTabLoadAttendees;
    constructor(public payload: {eventId: number}) {}
}
export class AttendeeEffectLoadAttendeesSuccess implements Action {
    readonly type = ActionTypes.AttendeeEffectLoadAttendeesSuccess;
    constructor(public payload: {attendees: Attendee[]}) {}
}
export class AttendeeEffectLoadAttendeesFailure implements Action {
    readonly type = ActionTypes.AttendeeEffectLoadAttendeesFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class GroupMemberTabSaveAllAttendees implements Action {
    readonly type = ActionTypes.GroupMemberTabSaveAllAttendees;
    constructor(public payload: {attendees: UpsertAttendeesRequest, eventId: number}) {}
}
export class GroupMemberTabAddNewAttendees implements Action {
    readonly type = ActionTypes.GroupMemberTabAddNewAttendees;
    constructor(public payload: {attendees: UpsertAttendeesRequest, eventId: number, skipValidation: boolean}) {}
}
export class ImportContactsToEventSaveAttendees implements Action {
    readonly type = ActionTypes.ImportContactsToEventSaveAttendees;
    constructor(public payload: {attendees: UpsertAttendeesRequest, eventId: number}) {}
}
export class PreviousAttendeesSaveAttendees implements Action {
    readonly type = ActionTypes.PreviousAttendeesSaveAttendees;
    constructor(public payload: {attendees: UpsertAttendeesRequest, eventId: number}) {}
}
export class AttendeeEffectSaveAttendeesSuccess implements Action {
    readonly type = ActionTypes.AttendeeEffectSaveAttendeesSuccess;
    constructor(public payload: {attendeeCollectionPayload: GTCollectionPayload<AttendeeUpsertRequest>}) {}
}
export class AttendeeEffectSaveAttendeesFailure implements Action {
    readonly type = ActionTypes.AttendeeEffectSaveAttendeesFailure;
    constructor(public payload: {errorMessage: string, failedAttendees: AttendeeUpsertRequest[]}) {}
}
//----------------------------------------------------------------
export class PublicEventAddNewAttendee implements Action {
    readonly type = ActionTypes.PublicEventAddNewAttendee;
    constructor(public payload: {attendee: AttendeeUpsertRequest, eventId: number}) {}
}
export class PublicEventAddNewAttendeeSuccess implements Action {
    readonly type = ActionTypes.PublicEventAddNewAttendeeSuccess;
    constructor(public payload: {response: AddPublicAttendeeResponse}) {}
}
export class PublicEventAddNewAttendeeFailure implements Action {
    readonly type = ActionTypes.PublicEventAddNewAttendeeFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class UpdateAttendeeStatusComponentUpdateAttendeeStatus implements Action {
    readonly type = ActionTypes.UpdateAttendeeStatusComponentUpdateAttendeeStatus;
    constructor(public payload: {attendee: Attendee}) {}
}
export class AttendeeEffectUpdateAttendeeStatusSuccess implements Action {
    readonly type = ActionTypes.AttendeeEffectUpdateAttendeeStatusSuccess;
    constructor(public payload: {updateInvitationResponse: UpdateInvitationResponse}) {}
}
export class AttendeeEffectUpdateAttendeeStatusFailure implements Action {
    readonly type = ActionTypes.AttendeeEffectUpdateAttendeeStatusFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class ChangeAttendeeEmailsComponentChangeAttendeeEmails implements Action {
    readonly type = ActionTypes.ChangeAttendeeEmailsComponentChangeAttendeeEmails;
    constructor(public payload: {attendees: Attendee[]}) {}
}
export class AttendeeEffectChangeAttendeeEmailsSuccess implements Action {
    readonly type = ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess;
    constructor(public payload: {attendees: Attendee[]}) {}
}
export class AttendeeEffectChangeAttendeeEmailsFailure implements Action {
    readonly type = ActionTypes.AttendeeEffectChangeAttendeeEmailsFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class GroupMemberTab_InviteEventAttendees implements Action {
    readonly type = ActionTypes.GroupMemberTab_InviteEventAttendees;
    constructor(public payload: {inviteEventAttendeesRequest: InviteEventAttendeesRequest}) {}
}
export class AttendeeEffect_InviteAttendeesSuccess implements Action {
    readonly type = ActionTypes.AttendeeEffect_InviteAttendeesSuccess;
    constructor(public payload: {attendeeIds: GTCollectionPayload<number>}) {}
}
export class AttendeeEffect_InviteAttendeesFailure implements Action {
    readonly type = ActionTypes.AttendeeEffect_InviteAttendeesFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class PackageDetailsEffectLoadLeaderSummary implements Action {
    readonly type = ActionTypes.PackageDetailsEffectLoadLeaderSummary;
    constructor(public payload: {eventId: number}) {}
}
export class AttendeeEffectLoadLeaderSummarySuccess implements Action {
    readonly type = ActionTypes.AttendeeEffectLoadLeaderSummarySuccess;
    constructor(public payload: {leaderSummary: LeaderSummary}) {}
}
export class AttendeeEffectLoadLeaderSummaryFailure implements Action {
    readonly type = ActionTypes.AttendeeEffectLoadLeaderSummaryFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class EventEffectClearAttendees implements Action {
    readonly type = ActionTypes.EventEffectClearAttendees;
    constructor() {}
}
//----------------------------------------------------------------

export type Actions
    = GeneralLoadAttendees
    | GroupMembersTabLoadAttendees
    | AttendeeEffectLoadAttendeesSuccess
    | AttendeeEffectLoadAttendeesFailure

    | GroupMemberTabSaveAllAttendees
    | GroupMemberTabAddNewAttendees
    | ImportContactsToEventSaveAttendees
    | PreviousAttendeesSaveAttendees
    | AttendeeEffectSaveAttendeesSuccess
    | AttendeeEffectSaveAttendeesFailure

    | PublicEventAddNewAttendee
    | PublicEventAddNewAttendeeSuccess
    | PublicEventAddNewAttendeeFailure

    | UpdateAttendeeStatusComponentUpdateAttendeeStatus
    | AttendeeEffectUpdateAttendeeStatusSuccess
    | AttendeeEffectUpdateAttendeeStatusFailure

    | ChangeAttendeeEmailsComponentChangeAttendeeEmails
    | AttendeeEffectChangeAttendeeEmailsSuccess
    | AttendeeEffectChangeAttendeeEmailsFailure

    | GroupMemberTab_InviteEventAttendees
    | AttendeeEffect_InviteAttendeesSuccess
    | AttendeeEffect_InviteAttendeesFailure

    | PackageDetailsEffectLoadLeaderSummary
    | AttendeeEffectLoadLeaderSummarySuccess
    | AttendeeEffectLoadLeaderSummaryFailure

    | EventEffectClearAttendees
;