import { Action } from '@ngrx/store';
import { Activity } from '../models/activity';
import { Option } from 'app/store/models/option';


export const LOAD_SELECTED_ACTIVITIES = '[Event] Load Selected Activities';
export const LOAD_SELECTED_ACTIVITIES_OK = '[Event] Load Selected Activities Ok';
export const CLEAR_SELECTED_ACTIVITIES = '[Event] Clear Selected Activities';
export const CREATE_ACTIVITY = '[Event] Create Activity';
export const CREATE_ACTIVITY_OK = '[Event] Create Activity Ok';
export const UPDATE_ACTIVITY = '[Event] Update Activity';
export const UPDATE_ACTIVITY_OK = '[Event] Update Activity Ok';
export const DELETE_ACTIVITY = '[Event] Delete Activity';
export const DELETE_ACTIVITY_OK = '[Event] Delete Activity Ok';
export const CREATE_OPTION = '[Event] Create Option';
export const CREATE_OPTION_OK = '[Event] Create Option Ok';
export const UPDATE_OPTION = '[Event] Update Option';
export const UPDATE_OPTION_OK = '[Event] Update Option Ok';
export const DELETE_OPTION = '[Event] Delete Option';
export const DELETE_OPTION_OK = '[Event] Delete Option Ok';
export const CREATE_ACTIVITY_AND_OPTIONS = '[Event] Create Activity and Options';
export const CREATE_ACTIVITY_AND_OPTIONS_OK = '[Event] Create Activity and Options Ok';
export const UPDATE_ACTIVITY_AND_OPTIONS = '[Event] Update Activity and Options';
export const UPDATE_ACTIVITY_AND_OPTIONS_OK = '[Event] Update Activity and Options Ok';
export const DELETE_ACTIVITY_AND_OPTIONS = '[Event] Delete Activity and Options';
export const DELETE_ACTIVITY_AND_OPTIONS_OK = '[Event] Delete Activity and Options Ok';


export class LoadSelectedActivities implements Action {
  readonly type = LOAD_SELECTED_ACTIVITIES;
  constructor(public payload: number) {}
}

export class LoadSelectedActivitiesOk implements Action {
  readonly type = LOAD_SELECTED_ACTIVITIES_OK;
  constructor(public payload: Activity[]) {}
}

export class ClearSelectedActivities implements Action {
  readonly type = CLEAR_SELECTED_ACTIVITIES;
  constructor() {}
}

export class CreateActivity implements Action {
  readonly type = CREATE_ACTIVITY;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class CreateActivityOk implements Action {
  readonly type = CREATE_ACTIVITY_OK;
  constructor(public payload: Activity) {}
}

export class UpdateActivity implements Action {
  readonly type = UPDATE_ACTIVITY;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class UpdateActivityOk implements Action {
  readonly type = UPDATE_ACTIVITY_OK;
  constructor(public payload: Activity) {}
}

export class DeleteActivity implements Action {
  readonly type = DELETE_ACTIVITY;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class DeleteActivityOk implements Action {
  readonly type = DELETE_ACTIVITY_OK;
  constructor(public payload: Activity) {}
}

export class CreateOption implements Action {
  readonly type = CREATE_OPTION;
  constructor(public payload: Option) {} // TODO: determine if this payload is needed
}

export class CreateOptionOk implements Action {
  readonly type = CREATE_OPTION_OK;
  constructor(public payload: Option) {}
}

export class UpdateOption implements Action {
  readonly type = UPDATE_OPTION;
  constructor(public payload: Option) {} // TODO: determine if this payload is needed
}

export class UpdateOptionOk implements Action {
  readonly type = UPDATE_OPTION_OK;
  constructor(public payload: Option) {}
}

export class DeleteOption implements Action {
  readonly type = DELETE_OPTION;
  constructor(public payload: Option) {} // TODO: determine if this payload is needed
}

export class DeleteOptionOk implements Action {
  readonly type = DELETE_OPTION_OK;
  constructor(public payload: Option) {}
}

export class CreateActivityAndOptions implements Action {
  readonly type = CREATE_ACTIVITY_AND_OPTIONS;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class CreateActivityAndOptionsOk implements Action {
  readonly type = CREATE_ACTIVITY_AND_OPTIONS_OK;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class UpdateActivityAndOptions implements Action {
  readonly type = UPDATE_ACTIVITY_AND_OPTIONS;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class UpdateActivityAndOptionsOk implements Action {
  readonly type = UPDATE_ACTIVITY_AND_OPTIONS_OK;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class DeleteActivityAndOptions implements Action {
  readonly type = DELETE_ACTIVITY_AND_OPTIONS;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export class DeleteActivityAndOptionsOk implements Action {
  readonly type = DELETE_ACTIVITY_AND_OPTIONS_OK;
  constructor(public payload: Activity) {} // TODO: determine if this payload is needed
}

export type Actions
  = LoadSelectedActivities
  | LoadSelectedActivitiesOk
  | ClearSelectedActivities
  | CreateActivity
  | CreateActivityOk
  | UpdateActivity
  | UpdateActivityOk
  | DeleteActivity
  | DeleteActivityOk
  | CreateOption
  | CreateOptionOk
  | UpdateOption
  | UpdateOptionOk
  | DeleteOption
  | DeleteOptionOk
  | CreateActivityAndOptions
  | CreateActivityAndOptionsOk
  | UpdateActivityAndOptions
  | UpdateActivityAndOptionsOk
  | DeleteActivityAndOptions
  | DeleteActivityAndOptionsOk
;
