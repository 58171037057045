<div *ngIf="attendees$ | async">
  <div *ngIf="attendees?.length > 0; else noBounced">
    <form class="inline-form">
      <grouptools-success *ngIf="savedEmails > 0" [successHeading]="savedMessage">
      </grouptools-success>
      <div class="row">
        <div class="col-sm-12 change-email-description">Please correct the email
          address{{attendees.length > 1 ? 'es' : ''}}es
          for the following attendee{{attendees.length > 1 ? 's' : ''}}:
        </div>
      </div>
      <div class="row change-email-header">
        <div class="col-sm-3">NAME</div>
        <div class="col-sm-6">EMAIL</div>
        <div class="col-sm-3">STATUS</div>
      </div>
      <div class="row change-email-row" *ngFor="let attendee of attendees$ | async">
        <div class="col-sm-3"><label class="label-margin" [for]="'email_'+attendee.id">{{attendee.fullName}}</label>
        </div>
        <div class="col-sm-6"><input [name]="'email_'+attendee.id" class="form-control" type="text"
            [placeholder]="attendee.email" [(ngModel)]="attendee.updatedEmail" /></div>
        <div class="col-sm-3"><label class="label-margin">{{attendee.status | titlecase}}</label></div>
      </div>

      <div class="row">
        <div class="col-sm-12 top-margin">
          <button type="button" [disabled]="saveInProgress" (click)="updateEmails()"
            class="btn btn-primary form-control change-email-row"
            [ngStyle]="{'cursor': saveInProgress ? 'progress' : 'default'}">Update</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button type="button" (click)="close()" class="btn btn-secondary form-control change-email-row">Return to
            Event Details</button>
        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #noBounced>
  <grouptools-success>
    <div class="row">
      <div class="col-sm-12">
        <div class="success-body centered">
          <span>Your attendee email addresses are up to date.</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 centered">
        <button class="btn btn-primary form-control" (click)="close()">Return to Event</button>
      </div>
    </div>
  </grouptools-success>
</ng-template>