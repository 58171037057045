import { Router, ActivatedRoute } from '@angular/router';
import { EqualPasswordsValidator } from 'app/grouptools-theme/validators/equalsPasswords.validator';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginPerson, AuthService } from '../../authentication/authentication.service';
import { ElementRef, EventEmitter, } from '@angular/core';
import 'style-loader!./amazon-register.component.scss';
var AmazonRegisterComponent = /** @class */ (function () {
    function AmazonRegisterComponent(fb, _service, route, router) {
        this.fb = fb;
        this._service = _service;
        this.route = route;
        this.router = router;
        this.PASSWORD_REGX = '^((?=.*[A-Z])(?=.*\\d)(?=.*[a-z])[A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{8,})|([A-Za-z\\d$@$!%*#?&-_+=/<>~:;\'"\(\)]{12,})$';
        this.registerComplete = new EventEmitter();
        this.formFields = {
            passwords: this.fb.group({
                password: ['', Validators.pattern(this.PASSWORD_REGX)],
                repeatPassword: ['', Validators.pattern(this.PASSWORD_REGX)]
            }, { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') })
        };
        this.formErrors = {
            'passwords': '',
            'password': '',
        };
        this.validationMessages = {
            'password': {
                'pattern': 'If you create a password, it must be at least 8 characters long and contain an upper case letter, a lower case letter and 1 number.'
            },
            'passwords': {
                'passwordsEqual': 'Passwords must match.',
            }
        };
    }
    AmazonRegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group(this.formFields);
        this.passwords = this.form.controls['passwords'];
        this.password = this.passwords.controls['password'];
        this.repeatPassword = this.passwords.controls['repeatPassword'];
        this.form.valueChanges
            .subscribe(function (data) {
            _this.onValueChanged(data);
        });
    };
    AmazonRegisterComponent.prototype.onSubmit = function (formValue) {
        var _this = this;
        if (this.form.valid) {
            this._service.registerViaAmazon({
                FirstName: this.user.FirstName,
                LastName: this.user.LastName,
                Email: this.user.Email,
                Password: this.password.value,
                accessToken: this.user.accessToken
            }).subscribe(function (res) {
                // try to login
                _this.login();
            });
        }
    };
    AmazonRegisterComponent.prototype.login = function () {
        var _this = this;
        var loginPerson = new LoginPerson(this.user.Email, this.password.value);
        this._service.login(loginPerson).subscribe(function (token) {
            if (token.token_type) {
                localStorage.setItem('access_token', token.access_token);
                localStorage.setItem('userID', token.userID);
                localStorage.setItem('login_status', token.login_status);
                _this._service.isLoggedInObservable().subscribe(function (res) {
                    if (!_this.inModal) {
                        var loggedIn = true;
                        var returnUrl = _this.route.snapshot.queryParams['returnUrl'];
                        if (returnUrl) {
                            _this.router.navigate([returnUrl]);
                        }
                        else {
                            _this.router.navigate(['/pages/dashboard']);
                        }
                    }
                    else {
                        // not in modal
                        _this.registerComplete.emit('amazon-registered');
                    }
                });
            }
        }, function (error) {
            if (error.status > 0) {
                alert(JSON.parse(error._body).error_description);
                //console.log('Server is unresponsive.');
            }
            else {
                alert('Server is unresponsive.');
                //console.log('Server is unresponsive.');
            }
        });
    };
    AmazonRegisterComponent.prototype.onValueChanged = function (data) {
        if (!this.form) {
            return;
        }
        for (var field in this.formErrors) {
            this.formErrors[field] = '';
            var control = this.form.get(field);
            if (field === "password") {
                var intCont = this.form.get('passwords');
                control = intCont.get('password');
            }
            if (control && control.dirty && !control.valid) {
                var messages = this.validationMessages[field];
                for (var key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    };
    AmazonRegisterComponent.prototype.includeSpecialChar = function () {
        var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        return format.test(this.password.value);
    };
    return AmazonRegisterComponent;
}());
export { AmazonRegisterComponent };
