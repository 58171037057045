import * as tslib_1 from "tslib";
import { tap, map } from 'rxjs/operators';
import { NgZone, SecurityContext } from '@angular/core';
import { URLConfig, ClientAuthConfig } from '../pages/helpers/config.service';
import { Store } from '@ngrx/store';
import * as user from 'app/store/actions/user';
import * as attendeePackages from 'app/store/actions/attendeePackages';
import * as ContactsActions from 'app/store/actions/contacts.action';
import * as event from 'app/store/actions/event';
import * as ActionActions from 'app/store/actions/actions.action';
import * as PaymentActions from 'app/store/actions/payment.action';
import * as PackageDetailsActions from 'app/store/actions/packageDetails.actions';
import * as MessageActions from 'app/store/actions/message.action';
import * as SurveysActions from 'app/store/actions/surveys.action';
import { ContactService } from 'app/pages/contacts/contact.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GettingStartedService } from 'app/pages/getting-started/getting-started.service';
import { AppStateService } from 'app/pages/helpers/appState.service';
import { NgxConfigureService } from 'ngx-configure';
import { handleGTResponse } from 'app/store/models/gtResponse';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../pages/helpers/config.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../pages/contacts/contact.service";
import * as i5 from "@angular/router";
import * as i6 from "../pages/getting-started/getting-started.service";
import * as i7 from "../pages/helpers/appState.service";
import * as i8 from "ngx-configure";
import * as i9 from "@angular/platform-browser";
var EmailVerification = /** @class */ (function () {
    function EmailVerification() {
    }
    return EmailVerification;
}());
export { EmailVerification };
var Person = /** @class */ (function () {
    function Person(email, firstname, lastname, phonevalue, password, repeatPassword, invitationToken, invoiceId) {
        if (email === void 0) { email = ''; }
        if (firstname === void 0) { firstname = ''; }
        if (lastname === void 0) { lastname = ''; }
        if (phonevalue === void 0) { phonevalue = ''; }
        if (password === void 0) { password = ''; }
        if (repeatPassword === void 0) { repeatPassword = ''; }
        if (invitationToken === void 0) { invitationToken = null; }
        if (invoiceId === void 0) { invoiceId = null; }
        this.Email = email;
        this.FirstName = firstname;
        this.LastName = lastname;
        this.PhoneNumber = phonevalue;
        this.Password = password;
        this.RepeatPassword = password;
        this.InvitationToken = invitationToken;
        this.IdInvoice = invoiceId;
    }
    return Person;
}());
export { Person };
var GooglePerson = /** @class */ (function (_super) {
    tslib_1.__extends(GooglePerson, _super);
    function GooglePerson() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GooglePerson;
}(Person));
export { GooglePerson };
var LoginPerson = /** @class */ (function () {
    function LoginPerson(email, password, googleAccessToken) {
        if (email === void 0) { email = ''; }
        if (password === void 0) { password = ''; }
        if (googleAccessToken === void 0) { googleAccessToken = ''; }
        this.Email = email;
        this.Password = password;
        this.GoogleAccessToken = googleAccessToken;
    }
    return LoginPerson;
}());
export { LoginPerson };
export var BIStartPlanningProperties = [
    'GTOrderId',
    'GTEmail',
    'GTTransId1',
    'GTProductName1',
    'GTProductDate1',
    'GTProductTime1'
];
var AuthService = /** @class */ (function () {
    function AuthService(http, URLs, ClientAuth, store, _contactService, zone, router, _gettingStartedService, _appStateService, _route, configService, sanitizer) {
        this.http = http;
        this.URLs = URLs;
        this.ClientAuth = ClientAuth;
        this.store = store;
        this._contactService = _contactService;
        this.zone = zone;
        this.router = router;
        this._gettingStartedService = _gettingStartedService;
        this._appStateService = _appStateService;
        this._route = _route;
        this.configService = configService;
        this.sanitizer = sanitizer;
    }
    AuthService.prototype.isLoggedIn = function () {
        var accessToken = localStorage.getItem('access_token') ? true : false;
        var loginStatus = localStorage.getItem('login_status') || 'none';
        this.store.dispatch(new user.SetLoggedIn(accessToken));
        this.store.dispatch(new user.SetLoginStatus(loginStatus));
        if (accessToken) {
            this.setUserInfo(loginStatus);
        }
        if (!accessToken) {
            this.clearUserState();
        }
    };
    AuthService.prototype.setUserInfo = function (loginStatus) {
        var _this = this;
        if (!localStorage.access_token) {
            return;
        }
        this.getUserInfo().subscribe(function (_a) {
            var userInfo = _a.Obj;
            _this.storeInfo(tslib_1.__assign({}, userInfo, { loginStatus: loginStatus }));
            _this.getPicture().subscribe(function (picture) {
                if (picture !== null) {
                    _this.store.dispatch(new user.SetProfilePicture(picture));
                }
                else {
                    _this.store.dispatch(new user.SetDefaultProfilePicture());
                }
            });
            _this.getLeaderStatus().subscribe(function (_a) {
                var leaderRes = _a.Obj;
                _this.store.dispatch(new user.SetLeaderStatus(leaderRes));
            });
            _this.getPaymentMethod().subscribe(function (paymentMethod) { _this.store.dispatch(new user.SetPaymentMethod({ paymentMethod: paymentMethod })); }, function (error) { _this.store.dispatch(new user.SetPaymentMethod({ paymentMethod: '' })); }, // swallow error // TODO: Handle this error appropriately
            function () { });
            _this.getAlternatePaymentMethod().subscribe(function (paymentMethod) { _this.store.dispatch(new user.SetAlternatePaymentMethod(paymentMethod)); }, function (error) { _this.store.dispatch(new user.SetAlternatePaymentMethod('')); }, // swallow error // TODO: Handle this error appropriately
            function () { });
        });
    };
    AuthService.prototype.getLeaderStatus = function () {
        return this.http.get(this.URLs._isGroupLeader());
    };
    AuthService.prototype.requestGroupLeaderRole = function () {
        return this.http.post(this.URLs._requestNewRole(), { 'role': 'GroupLeader' });
    };
    AuthService.prototype.getPaymentMethod = function () {
        return this.http.get(this.URLs._getPaymentMethod()).pipe(handleGTResponse());
    };
    AuthService.prototype.getAlternatePaymentMethod = function (id) {
        var _this = this;
        return this.http.get(this.URLs._paymentGetAlternatePaymentMethod(id)).pipe(handleGTResponse(), map(function (alternatePaymentMethod) { return _this.sanitizer.sanitize(SecurityContext.HTML, alternatePaymentMethod); }));
    };
    AuthService.prototype.getUserInfo = function (userID) {
        if (userID === void 0) { userID = ''; }
        return this.http.get(this.URLs._userinfourl());
    };
    AuthService.prototype.isRegisteredUser = function (email) {
        if (email === void 0) { email = ''; }
        return this.http.post(this.URLs._isregistereduserurl(), { email: email });
    };
    AuthService.prototype.isLoggedInObservable = function () {
        var accessToken = localStorage.getItem('access_token') ? true : false;
        var loginStatus = localStorage.getItem('login_status') || 'none';
        this.store.dispatch(new user.SetLoggedIn(accessToken));
        this.store.dispatch(new user.SetLoginStatus(loginStatus));
        if (accessToken) {
            return this.getUserInfo();
        }
        if (!accessToken) {
            this.clearUserState();
        }
        return null;
    };
    AuthService.prototype.storeInfo = function (userInfo) {
        this.store.dispatch(new user.SetUserID(Number(userInfo.Id)));
        this.store.dispatch(new user.SetEmail(userInfo.Email));
        if (userInfo.UserStatus === 'Unregistered') {
            this.store.dispatch(new user.SetRegisteredStatus(false));
            this.store.dispatch(new user.SetVerifiedStatus(false));
            this.store.dispatch(new user.SetFirstName(userInfo.FirstName));
            this.store.dispatch(new user.SetLastName(userInfo.LastName));
        }
        else {
            var userDataRegisteredData = {
                isRegistered: true,
                isVerified: userInfo.UserStatus === 'RegisteredAndVerified' ? true : false,
                firstName: userInfo.FirstName,
                lastName: userInfo.LastName,
                roles: userInfo.UserRoles,
                phoneNumber: userInfo.PhoneNumber,
                addressState: userInfo.AddressState,
                about: (userInfo.SocialData && userInfo.SocialData.About) ? userInfo.SocialData.About : null
            };
            this.store.dispatch(new user.SetUserRegisteredData(userDataRegisteredData));
            if (userInfo.loginStatus !== 'partial') {
                this.store.dispatch(new ContactsActions.AuthenticationServiceLoadContacts());
            }
        }
    };
    AuthService.prototype.clearUserState = function () {
        this.store.dispatch(new user.SetDefault);
    };
    AuthService.prototype.forgotPassword = function (userEmail) {
        return this.http.post(this.URLs._forgotpass(''), { userEmail: userEmail });
    };
    AuthService.prototype.logErrorMsg = function (err) {
        return this.http.get(this.URLs._errorLoggingUrl() + err).pipe(map(function (res) { return res.Obj; }));
    };
    AuthService.prototype.verifyUserEmailToken = function (token) {
        return this.http.get("" + this.URLs._verifyuseremailtokenurl() + token + "/?apiKey=" + this.configService.config.authVerifyToken);
    };
    AuthService.prototype.verifyEmailToken = function (token) {
        return this.http.get("" + this.URLs._verifytokenurl() + token + "/?apiKey=" + this.configService.config.authVerifyToken);
    };
    AuthService.prototype.insertPicture = function (picture) {
        return this.http.post(this.URLs._pictureuploadurl(), picture);
    };
    AuthService.prototype.getPicture = function () {
        return this.http.get(this.URLs._picturegeturl()).pipe(handleGTResponse());
    };
    AuthService.prototype.getPaymentInfo = function () {
        return this.http.get(this.URLs._paymentinfogeturl()).pipe(map(function (res) {
            return res.Obj;
        }));
    };
    AuthService.prototype.insertPaymentInfo = function (paymentInfo) {
        return this.http.post(this.URLs._paymentinfoaddurl(), paymentInfo);
    };
    AuthService.prototype.logError = function (err) {
        return this.http.post(this.URLs._errorLoggingUrl(), err);
    };
    AuthService.prototype.getEmailVerification = function (email) {
        return this.http.post(this.URLs._emailurl() + email, JSON.stringify({ email: email })).pipe(map(function (res) {
            return res.Result;
        }));
    };
    AuthService.prototype.facebookLogin = function (facebookUser) {
        var loginPerson;
        this.zone.run(function () {
            var token = encodeURIComponent(facebookUser.AccessToken);
            loginPerson = new LoginPerson(facebookUser.Email, token);
        });
        return this.login(loginPerson, 'facebook');
    };
    AuthService.prototype.amazonLogin = function (amazonUser) {
        var loginPerson;
        this.zone.run(function () {
            var token = encodeURIComponent(amazonUser.accessToken);
            loginPerson = new LoginPerson(amazonUser.Email, token);
        });
        return this.login(loginPerson, 'amazon');
    };
    AuthService.prototype.googleLogin = function (googleUser) {
        var profile;
        this.zone.run(function () {
            profile = googleUser.getBasicProfile();
        });
        var email = profile.getEmail();
        var idToken = googleUser.getAuthResponse().id_token;
        var accessToken = googleUser.getAuthResponse().access_token;
        var encodedPassword = encodeURIComponent(idToken);
        var encodedAccessToken = encodeURIComponent(accessToken);
        var loginPerson = new LoginPerson(email, encodedPassword, encodedAccessToken);
        return this.login(loginPerson, 'google');
    };
    AuthService.prototype.login = function (person, social) {
        var _this = this;
        localStorage.clear();
        this._appStateService.setEmailPrefill('');
        var credentials;
        if (social && social === 'google') {
            credentials = 'grant_type=password'
                + '&userName=' + encodeURIComponent(person.Email)
                + '&password=' + person.Password
                + '&type=google_signin'
                + '&gat=' + person.GoogleAccessToken;
        }
        else if (social && social === 'amazon') {
            credentials = 'grant_type=password'
                + '&userName=' + encodeURIComponent(person.Email)
                + '&password=' + person.Password
                + '&type=amazon_signin';
        }
        else if (social && social === 'facebook') {
            credentials = 'grant_type=password'
                + '&userName=' + encodeURIComponent(person.Email)
                + '&password=' + person.Password
                + '&type=facebook_signin';
        }
        else {
            credentials = 'grant_type=password'
                + '&userName=' + encodeURIComponent(person.Email)
                + '&password=' + encodeURIComponent(person.Password);
        }
        return this.http.post(this.URLs._tokenUrl(), credentials).pipe(map(function (res) {
            if (res.token_type) {
                localStorage.setItem('access_token', res.access_token);
                localStorage.setItem('refresh_token', res.refresh_token);
                localStorage.setItem('userID', res.userID);
                localStorage.setItem('login_status', res.login_status);
                localStorage.setItem('lastLogin', res.LastLogin);
                localStorage.removeItem('need-refresh');
                if (res.user_status && res.user_status === 'RegisteredAndVerified') {
                    _this.store.dispatch(new user.SetVerifiedStatus(true));
                }
                _this.isLoggedIn();
            }
            return res;
        }));
    };
    AuthService.prototype.adminLogin = function (token) {
        var _this = this;
        localStorage.clear();
        var credentials;
        credentials = 'grant_type=password&username=' + '_'
            + '&password=' + token
            + '&type=admin';
        return this.http.post(this.URLs._tokenUrl(), credentials).pipe(map(function (res) {
            if (res.token_type) {
                localStorage.setItem('access_token', res.access_token);
                localStorage.setItem('refresh_token', res.refresh_token);
                localStorage.setItem('userID', res.userID);
                localStorage.setItem('login_status', res.login_status);
                localStorage.setItem('lastLogin', res.LastLogin);
                localStorage.removeItem('need-refresh');
                _this.isLoggedIn();
            }
            return res;
        }));
    };
    AuthService.prototype.setLocalStorage = function (res) {
        if (res.token_type) {
            localStorage.setItem('access_token', res.access_token);
            localStorage.setItem('refresh_token', res.refresh_token);
            localStorage.setItem('userID', res.userID);
            localStorage.setItem('login_status', res.login_status);
            localStorage.setItem('lastLogin', res.LastLogin);
            localStorage.removeItem('need-refresh');
            this.isLoggedIn();
        }
    };
    AuthService.prototype.refresh = function () {
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.ClientAuth.basicAuthHeader()
        });
        var options = { headers: headers };
        var credentials = ['grant_type=refresh_token&refresh_token=', localStorage.getItem('refresh_token')].join('');
        return this.http.post(this.URLs._tokenUrl(), credentials, options).pipe(map(function (res) {
            if (res.token_type) {
                localStorage.setItem('access_token', res.access_token);
                localStorage.setItem('refresh_token', res.refresh_token);
                localStorage.setItem('userID', res.userID);
                localStorage.setItem('login_status', res.login_status);
            }
            return res;
        }));
    };
    AuthService.prototype.logout = function () {
        this.logoutNoNavigation();
        this.router.navigateByUrl('/login', {
            queryParams: tslib_1.__assign({}, this._route.snapshot.queryParams, { returnUrl: undefined })
        });
    };
    AuthService.prototype.logoutNoNavigation = function () {
        localStorage.clear();
        sessionStorage.clear();
        this._appStateService.setEmailPrefill('');
        //If you're logged into the Google app, sign you out
        try {
            gapi.load('auth2', function () {
                var auth2 = gapi.auth2.getAuthInstance();
                if (auth2) {
                    auth2.signOut();
                }
            });
        }
        catch (e) {
            //console.error(e);
        }
        this.store.dispatch(new attendeePackages.Logout());
        this.store.dispatch(new ContactsActions.AuthenticationServiceLogout());
        this.store.dispatch(new event.Logout());
        this.store.dispatch(new user.Logout());
        this.store.dispatch(new ActionActions.AuthenticationServiceLogout());
        this.store.dispatch(new PaymentActions.AuthenticationServiceLogout());
        this.store.dispatch(new PackageDetailsActions.AuthenticationServiceLogout());
        this.store.dispatch(new MessageActions.AuthenticationServiceLogout());
        this.store.dispatch(new ContactsActions.AuthenticationServiceLogout());
        this.store.dispatch(new SurveysActions.AuthenticationServiceLogout());
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem('access_token');
    };
    AuthService.prototype.loginByEmailCode = function (email, code) {
        var token = this.getToken();
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token ? ['Bearer ', token] : this.ClientAuth.basicAuthHeader()
        });
        var options = { headers: headers };
        var credentials = 'grant_type=password'
            + '&userName=' + email
            + '&password=' + code
            + '&type=email_invite';
        return this.http.post(this.URLs._tokenUrl(), credentials, options).pipe(map(function (res) {
            if (res.token_type) {
                localStorage.clear();
                localStorage.setItem('access_token', res.access_token);
                localStorage.setItem('refresh_token', res.refresh_token);
                localStorage.setItem('userID', res.userID);
                localStorage.setItem('login_status', res.login_status);
                localStorage.setItem('lastLogin', res.LastLogin);
            }
        }));
    };
    AuthService.prototype.insertPerson = function (person) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var options = { headers: headers };
        if (person !== undefined) {
            if (typeof person === 'string') {
                person = JSON.parse(person);
            }
            person.APIKey = this.configService.config.authPersonApiKey;
            this.setTrackingDetails(person);
        }
        return this.http.post(this.URLs._contactRegisterUrl(), person, options);
    };
    AuthService.prototype.registerViaGoogle = function (person) {
        if (person !== undefined) {
            if (typeof person === 'string') {
                person = JSON.parse(person);
            }
            person.APIKey = this.configService.config.authPersonApiKey;
            this.setTrackingDetails(person);
        }
        return this.http.post(this.URLs._registerViaGoogle(), person).pipe(map(function (res) { return res.Obj; }));
    };
    AuthService.prototype.registerViaAmazon = function (person) {
        if (person !== undefined) {
            if (typeof person === 'string') {
                person = JSON.parse(person);
            }
            person.APIKey = this.configService.config.authPersonApiKey;
            this.setTrackingDetails(person);
        }
        return this.http.post(this.URLs._registerViaAmazon(), person).pipe(map(function (res) { return res.Obj; }));
    };
    AuthService.prototype.registerViaFacebook = function (person) {
        if (person !== undefined) {
            if (typeof person === 'string') {
                person = JSON.parse(person);
            }
            person.APIKey = this.configService.config.authPersonApiKey;
            this.setTrackingDetails(person);
        }
        return this.http.post(this.URLs._registerViaFacebook(), person);
    };
    AuthService.prototype.updateProfile = function (person) {
        var _this = this;
        return this.http.post(this.URLs._updateProfile(), person).pipe(map(function (res) { return res.Obj; }), tap(function (res) {
            if (res.FirstName) {
                _this.store.dispatch(new user.SetFirstName(res.FirstName));
            }
            if (res.LastName) {
                _this.store.dispatch(new user.SetLastName(res.LastName));
            }
            if (res.PhoneNumber) {
                _this.store.dispatch(new user.SetPhoneNumber(res.PhoneNumber));
            }
            if (res.AddressState) {
                _this.store.dispatch(new user.SetAddressState(res.AddressState));
            }
        }));
    };
    AuthService.prototype.changePassword = function (passwords) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var options = { headers: headers };
        return this.http.post(this.URLs._passwordchangeurl(), passwords, options);
    };
    //Mutates the person object and adds tracking details.
    //The tracking details are retrieved from session storage.
    //Once retrieved, we remove them as we only want to use them for the first registration that occurs per session.
    //Otherwise, tabs that remain open could incorrectly track someone.
    AuthService.prototype.setTrackingDetails = function (person) {
        if (person) {
            //Set Origin Data for Registration
            if (window.sessionStorage) {
                person.TrackingDetails = {};
                Object.keys(window.sessionStorage).forEach(function (key) {
                    var lowercaseKey = key.toLowerCase();
                    if (lowercaseKey.startsWith('utm_') || lowercaseKey === 'referrer') {
                        person.TrackingDetails[lowercaseKey] = window.sessionStorage[key] || '';
                        // ska 2018-10-24 just remove the keys being read here
                        window.sessionStorage.removeItem(key);
                    }
                });
            }
        }
    };
    AuthService.prototype.createBIPlaceHolderEvent = function (data) {
        var e_1, _a;
        var doc = {
            inviteOthers: true,
            inviteOthersCount: 99,
            activityPlaceholders: ['BI']
        };
        if (data) {
            doc.BIData = data;
        }
        try {
            for (var BIStartPlanningProperties_1 = tslib_1.__values(BIStartPlanningProperties), BIStartPlanningProperties_1_1 = BIStartPlanningProperties_1.next(); !BIStartPlanningProperties_1_1.done; BIStartPlanningProperties_1_1 = BIStartPlanningProperties_1.next()) {
                var key = BIStartPlanningProperties_1_1.value;
                sessionStorage.removeItem(key);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (BIStartPlanningProperties_1_1 && !BIStartPlanningProperties_1_1.done && (_a = BIStartPlanningProperties_1.return)) _a.call(BIStartPlanningProperties_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var formValue = {
            IdTenant: 1,
            JsonDoc: JSON.stringify(doc)
        };
        if (data) {
            formValue.PackageName = data.GTProductName1 + ' on ' + data.GTProductDate1;
        }
        this.tryCreate(formValue);
    };
    AuthService.prototype.tryCreate = function (eventData) {
        var _this = this;
        this._gettingStartedService.saveEvent(eventData)
            .subscribe(function (_a) {
            var eventId = _a.IdPackage;
            // navigate to package detail page when the new event is created
            _this.router.navigateByUrl('/pages/package-details/' + eventId);
        }, function (err) {
            _this.requestGroupLeaderRole().subscribe(function (requestLeaderRes) {
                if (requestLeaderRes.Result) {
                    _this.refresh().subscribe(function (authRes) {
                        _this.setUserInfo();
                        // now user is a group leader, try to create the event again
                        _this.tryCreate(eventData);
                    });
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.URLConfig), i0.inject(i2.ClientAuthConfig), i0.inject(i3.Store), i0.inject(i4.ContactService), i0.inject(i0.NgZone), i0.inject(i5.Router), i0.inject(i6.GettingStartedService), i0.inject(i7.AppStateService), i0.inject(i5.ActivatedRoute), i0.inject(i8.NgxConfigureService), i0.inject(i9.DomSanitizer)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
