
import {throwError as observableThrowError,  Observable, Subject } from 'rxjs';

import {catchError, tap, map} from 'rxjs/operators';
import { URLConfig } from '../helpers/config.service';
import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MessagesService } from '../messages/messages.service';
import { Store } from '@ngrx/store';
import { ShortUrl } from 'app/store/models/shortUrl.interface';
import * as reducers from 'app/store/reducers/shortUrl.reducer';
import { SetShortUrl, SET_SHORT_URL } from '../../store/actions/shortUrl.action';
import { Option } from 'app/store/models/option';
import { Form } from 'app/store/models/form';
import { GTResponse, GTResponseHelper, handleGTResponse, handleGTCollectionResponse, GTCollectionResponse, GTCollectionPayload } from 'app/store/models/gtResponse';
import { AttendeeSummaryDTO } from 'app/store/models/packages/AttendeeSummaryDTO';
import { ComponentPaymentSummary } from 'app/store/models/packages/ComponentPaymentSummary';
import { PaymentsReceivedSummaryDTO } from 'app/store/models/packages/PaymentsReceivedSummaryDTO';
import { PaymentsSentSummaryDTO } from 'app/store/models/packages/PaymentsSentSummaryDTO';
import { AttendeeDTO_Old } from 'app/store/models/packages/AttendeeDTO';
import { PackageComponentDTO } from 'app/store/models/packages/PackageComponentDTO';
import { TagsDTO } from 'app/store/models/packages/TagsDTO';
import { PackageRecordDTO } from 'app/store/models/packages/PackageRecordDTO';
import { SeparatedPaymentsDTO } from 'app/store/models/packages/SeparatedPaymentsDTO';
import { SupplierDTO } from 'app/store/models/packages/SupplierDTO';
import { UserMessageDTO } from 'app/store/models/packages/UserMessageDTO';
import { MessageRequestDTO } from 'app/store/models/requests/MessageRequestDTO';
import { LogEntry } from 'app/store/models/packages/LogEntry';
import { CreateEventFromBibOrderRequest } from 'app/store/models/requests/create-event-from-bib-order-request';
import { CreateEventFromBibOrderResponse } from 'app/store/models/responses/create-event-from-bib-order-response';
import { InviteEventAttendeesRequest } from 'app/store/models/requests/invite-event-attendees-request';
import { InviteEventAttendeesResponse } from 'app/store/models/responses/invite-event-attendees-response';
import { CreateEventFromSupplierOrderRequest } from 'app/store/models/requests/create-event-from-supplier-order-request';
import { CreateEventFromSupplierOrderResponse } from 'app/store/models/responses/create-event-from-supplier-order-response';
import { UpdateEventFromSupplierOrderRequest } from 'app/store/models/requests/update-event-from-supplier-order-request';
import { UpdateEventFromSupplierOrderResponse } from 'app/store/models/responses/update-event-from-supplier-order-response';
import { LoadTicketsRequest } from 'app/store/models/requests/load-tickets-request';
import { LoadTicketsResponse } from 'app/store/models/responses/load-tickets-response';
import { PurchaseTicketsRequest } from 'app/store/models/requests/purchase-tickets-request';
import { PurchaseTicketsResponse } from 'app/store/models/responses/purchase-tickets-response';
import { GetSeatMapImageRequest } from 'app/store/models/requests/get-seat-map-image-request';
import { GetSeatMapImageResponse } from 'app/store/models/responses/get-seat-map-image-response';
import { RecordSupplierAttendeePaymentRequest } from 'app/store/models/requests/record-supplier-attendee-payment-request';
import { RecordSupplierAttendeePaymentResponse } from 'app/store/models/responses/record-supplier-attendee-payment-response';
import { GetAlternateGroupLeaderInfoResponse } from 'app/store/models/responses/get-alternate-group-leader-info-response';
import { GetAlternateGroupLeaderInfoRequest } from 'app/store/models/requests/get-alternate-group-leader-info-request';


@Injectable()
export class PackageDetailsService {
  public enticingImage = require('assets/img/enticing_image.png');

  constructor(private http: HttpClient,
    private URLs: URLConfig,
    private sanitizer: DomSanitizer,
    private messagesService: MessagesService,
    private store: Store<reducers.State>
  ) {
  }

  GetDetails(packageId): Observable < any > {
    return this.http.get < any > (this.URLs._packageDetails(packageId)).pipe(
      map(res => res.Obj),
      map(pkg => {
        if (!pkg.hasOwnProperty('ShortPublicUrl') ||  !pkg.ShortPublicUrl || !pkg.ShortPublicUrl.startsWith('http')) {
          this.http.get<any>(this.URLs._packageShortUrl(packageId)).pipe(
          map(res => res.Obj))
          .subscribe(url => {
            pkg.ShortPublicUrl = url;
            this.dispatchShortUrlState(pkg);

            return pkg;
          });
        }
        
        return pkg;
      }),
      map(res => {
        //console.log("RES", res);

        if (res.PackageDesc) {
          res.PackageDesc = this.sanitizer.sanitize(SecurityContext.HTML, res.PackageDesc);
        }
        if (res.PackageShortDesc) {
          res.PackageShortDesc = this.sanitizer.sanitize(SecurityContext.HTML, res.PackageShortDesc);
        }

        if (res.JsonDoc === '') {
          res.JsonDoc = '{}';
        }
        res.options = JSON.parse(res.JsonDoc);
        if (res.options.inviteOthers === undefined) {
          res.options.inviteOthers = true;
        }
        if (res.options.inviteOthersCount === undefined) {
          res.options.inviteOthersCount = 99;
        }
        return res;
      }),
      map(pkg => {
        this.dispatchShortUrlState(pkg);
        return pkg;
      }),);
  }

  dispatchShortUrlState(event: any) {
    // create a new state with the new url info, but old image
    const urlState = Object.assign({}, <ShortUrl>{
      shortUrl: <string> event.ShortPublicUrl,
      title: <string> event.PackageName,
      description: <string> event.PackageDesc,
      image: <string> event.PhotoUrl,
      site_name: 'GroupTools'
    });

    // dispatch
    this.store.dispatch(new SetShortUrl(urlState));
  }



  GetPackage(packageId): Observable<any> {
    return this.http.get<GTResponse<string>>(this.URLs._packageGetByID(packageId)).pipe(
      handleGTResponse(),
      map(res => JSON.parse(res)[0]),
      map(res => {
        if (res.PackageDesc) {
          res.PackageDesc = this.sanitizer.sanitize(SecurityContext.HTML, res.PackageDesc);
        }
        if (res.PackageShortDesc) {
          res.PackageShortDesc = this.sanitizer.sanitize(SecurityContext.HTML, res.PackageShortDesc);
        }
        return res;
      }),);
  }

  PackageInfo(packageId): Observable < any > {
    return this.http.get<any>(this.URLs._packageInfoByID(packageId)).pipe(
      map(res => {
        if (res.Result) {
          //Assume it's a URL
          let imgSrc = res.Obj.packagePic;

          if (imgSrc) {
            if (!imgSrc.startsWith('http')) {
              imgSrc = 'data:image/jpg;base64,' + imgSrc;
              res.Obj.packagePic = imgSrc;
            }
          } else {
            const imgDefult = require(`assets/img/default-image.jpg`);
            res.Obj.packagePic = imgDefult;
          }

        }

        res.Obj.packageDetails = JSON.parse(res.Obj.packageDetails)[0];
        return res.Obj;
      }),
      tap(res => {
        // update open graph meta data by storing the shorturl information
        this.dispatchShortUrlState({
          ShortPublicUrl: res.packageDetails.ShortPublicUrl,
          PackageName: res.packageDetails.PackageName,
          PackageDesc: res.packageDetails.PackageDesc,
          PhotoUrl: res.packagePic
        });
      }),);
  }

  // TODO: This is deprecated, instead use event-details.service.ts getAttendees method
  GetAttendeeDetails(packageId: number): Observable<AttendeeDTO_Old[]> {
    return this.http.get<GTResponse<AttendeeDTO_Old[]>>(this.URLs._packageAttendeeDetails(packageId)).pipe(
      handleGTResponse(),
    );
  }
  packageComponentsGetByID(packageId: number): Observable<PackageComponentDTO[]> {
    return this.http.get<GTResponse<PackageComponentDTO[]>>(this.URLs._packageComponentsGetByID(packageId)).pipe(
      handleGTResponse(),
    );
  }
  public getPayments(packageId: number): Observable<SeparatedPaymentsDTO> {
    return this.http.get<GTResponse<SeparatedPaymentsDTO>>(this.URLs._paymentsGetUrlOld(packageId)).pipe(
      handleGTResponse(),
    );
  }

  public GetPaymentsReceivedSummary(packageId): Observable<PaymentsReceivedSummaryDTO> {
    return this.http.get<GTResponse<PaymentsReceivedSummaryDTO>>(this.URLs._paymentsReceivedSummary(packageId)).pipe(
      handleGTResponse(),
      );
  }

  public GetPaymentsSentSummary(packageId: number): Observable<PaymentsSentSummaryDTO> {
    return this.http.get<GTResponse<PaymentsSentSummaryDTO>>(this.URLs._paymentsSentSummary(packageId)).pipe(
      handleGTResponse(),
      );
  }

  /**
   * getPackageFeatureDetails
   */
  public getFeaturePaymentSummary(packageId: Number, featureId: Number): Observable <ComponentPaymentSummary> {
    return this.http.get <GTResponse<ComponentPaymentSummary>> (this.URLs._paymentsFeatureSummaryUrl(packageId, featureId)).pipe(
      handleGTResponse(),
    );
  }

  public createEventFromBibOrder(createEventFromBibOrderRequest: CreateEventFromBibOrderRequest): Observable<CreateEventFromBibOrderResponse> {
    return this.http.post<GTResponse<CreateEventFromBibOrderResponse>>(this.URLs._createEventFromBibOrder(), createEventFromBibOrderRequest).pipe(
      handleGTResponse(),
    );
  }

  public createEventFromSupplierOrder(createEventFromSupplierOrderRequest: CreateEventFromSupplierOrderRequest): Observable<CreateEventFromSupplierOrderResponse> {
    return this.http.post<GTResponse<CreateEventFromSupplierOrderResponse>>(this.URLs._createEventFromSupplierOrder(), createEventFromSupplierOrderRequest).pipe(
      handleGTResponse(),     
    );
  }

  public updateEventFromSupplierOrder(updateEventFromSupplierOrderRequest: UpdateEventFromSupplierOrderRequest): Observable<UpdateEventFromSupplierOrderResponse> {
    return this.http.post<GTResponse<UpdateEventFromSupplierOrderResponse>>(this.URLs._updateEventFromSupplierOrder(), updateEventFromSupplierOrderRequest).pipe(
      handleGTResponse(),     
    );
  }

  public loadTickets(loadTicketsRequest: LoadTicketsRequest): Observable<LoadTicketsResponse> {
    return this.http.post<GTResponse<LoadTicketsResponse>>(this.URLs._loadTickets(), loadTicketsRequest).pipe(
      handleGTResponse(),     
    );
  }

  public purchaseTickets(purchaseTicketsRequest: PurchaseTicketsRequest): Observable<PurchaseTicketsResponse> {
    return this.http.post<GTResponse<PurchaseTicketsResponse>>(this.URLs._purchaseTickets(), purchaseTicketsRequest).pipe(
      handleGTResponse(),     
    );
  }

  public getSeatMapImage(getSeatMapImageRequest: GetSeatMapImageRequest): Observable<GetSeatMapImageResponse> {
    return this.http.post<GTResponse<GetSeatMapImageResponse>>(this.URLs._getSeatMapImage(), getSeatMapImageRequest).pipe(
      handleGTResponse(),     
    );
  }

  public recordSupplierAttendeePayment(recordSupplierAttendeePaymentRequest: RecordSupplierAttendeePaymentRequest): Observable<RecordSupplierAttendeePaymentResponse> {
    return this.http.post<GTResponse<RecordSupplierAttendeePaymentResponse>>(this.URLs._recordSupplierAttendeePayment(), recordSupplierAttendeePaymentRequest).pipe(
      handleGTResponse(),     
    );
  }

  public getAlternateGroupLeaderInfo(getAlternateGroupLeaderInfoRequest: GetAlternateGroupLeaderInfoRequest): Observable<GetAlternateGroupLeaderInfoResponse> {
    return this.http.post<GTResponse<GetAlternateGroupLeaderInfoResponse>>(this.URLs._getAlternateGroupLeaderInfo(), getAlternateGroupLeaderInfoRequest).pipe(
      handleGTResponse(),     
    );
  }

  public inviteEventAttendees(inviteEventAttendeesRequest: InviteEventAttendeesRequest): Observable<GTCollectionPayload<number>> {
    return this.http.post<GTCollectionResponse<number>>(this.URLs._inviteEventAttendees(), inviteEventAttendeesRequest).pipe(
      handleGTCollectionResponse(),
    );
  }

  public MarkPublicUrlAsShared(eventId: number): Observable<boolean> {
    return this.http.post<GTResponse<boolean>>(this.URLs._markPublicUrlAsShared(eventId), null).pipe(
      handleGTResponse(),
    );
  }

  public updatePackageDetails(packageDetails: any): Observable < any > {
    return this.http.post < any > (this.URLs._packageUpdatePackageDetails(), packageDetails).pipe(
      map((res: Response) => {
        return {
          res
        };
      }),
      catchError((e: any) => {
        return observableThrowError({
          "Errors": e
        });
      }),);
  }

  public updateComponentDetails(componentDetails): Observable < any > {
    return this.http.post(this.URLs._updateComponentDetails(), componentDetails).pipe(
      catchError((e: any) => {
        return observableThrowError({
          "Errors": e
        });
      }));
  }

  public updateComponentOption(optionDetails): Observable < any > {
    return this.http.post(this.URLs._updateComponentOption(), optionDetails).pipe(
      catchError((e: any) => {
        return observableThrowError({
          "Errors": e
        });
      }));
  }

  public getComponentOption(IdPackage, IdComponent): Observable < any > {
    return this.http.get(this.URLs._getComponentOptions(IdPackage, IdComponent) ).pipe(
      catchError((e: any) => {
        return observableThrowError({
          "Errors": e
        });
      }));
  }

  public GetTagsInfo(recordId: number, record: string): Observable<TagsDTO> {
    return this.http.get<GTResponse<TagsDTO>>(this.URLs._tagsInfo(recordId, record)).pipe(
      handleGTResponse(),
    );
  }

  public GetContactsTagsByOwner(): Observable < any > {
    return this.http.get < any > (this.URLs._getContactTagsByOwner()).pipe(
      map(res => {
        if (res.Result) {
          return res.Obj;
        } else {
          return null;
        }
      }));
  }

  public insertTagsInfo(tagTextList, record, recordId) {
    const insertBody = {
      tagText: tagTextList,
      record: record,
      recordId: recordId
    };

    // Return the http Observable -> turned Promise
    return this.http.post(this.URLs._insertTagsInfo(), insertBody).pipe(
      map((res: Response) => {
        return {
          res
        };
      }),
      catchError((e: any) => {
        //console.log(e.status);
        return observableThrowError({
          "Errors": e
        });
      }),);
  }

  public deleteTagsInfo(tagTextList, record, recordId) {
    const deleteBody = {
      tagText: tagTextList,
      record: record,
      recordId: recordId
    };

    // Return the http Observable -> turned Promise
    return this.http.post(this.URLs._deleteTagsInfo(), deleteBody).pipe(
      map((res: Response) => {
        return {
          res
        };
      }),
      catchError((e: any) => {
        //console.log(e.status);
        return observableThrowError({
          "Errors": e
        });
      }),);
  }

  public getWorkshopData(): Observable < any > {
    return this.http.get < any > (this.URLs._getCatalogWorkshops()).pipe(
      map(res => res.Obj),
      map(res => res.map(workshop => {
        if (!workshop.Details.Logo) {
          workshop.Details.Logo = this.enticingImage;
        }
        return Object.assign({
          ApiAccessId: workshop.ApiAccessId,
          ApiItemConfigJson: workshop.ApiItemConfigJson,
          catalogItemId: workshop.catalogItemId,
          catalogItemName: workshop.catalogItemName,
          catalogItemType: workshop.catalogItemType
        }, workshop.Details)
      })),);
  }

  public getShowData(): Observable < any > {
    return this.http.get < any > (this.URLs._getCatalogShows()).pipe(
      map(res => res.Obj),
      map(res => res.map(show => {
        if (!show.Details.Logo) {
          show.Details.Logo = this.enticingImage;
        }
        return Object.assign({
          ApiAccessId: show.ApiAccessId,
          ApiItemConfigJson: show.ApiItemConfigJson,
          catalogItemId: show.catalogItemId,
          catalogItemName: show.catalogItemName,
          catalogItemType: show.catalogItemType
        }, show.Details)
      })),);
  }

  public insertNewOptionToComponent(option): Observable < any > {
    return this.http.post < any > (this.URLs._addComponentOption(), option).pipe(
      map(res => {
        if (res.Result) {
          return res.Obj;
        } else {
          return null;
        }
      }));
  }

  public insertNewFeatureOption(component): Observable < any > {
    return this.http.post < any > (this.URLs._packageInsertComponent(), component).pipe(
      map(res => {
        if (res.Result) {
          return res.Obj;
        } else {
          return null;
        }
      }));
  }

  public insertNewFeature(eventId, featureObj): Observable < any > {
    return this.http.post < any > (this.URLs._packageInsertComponent(), this.formatFeature(eventId, featureObj));
  }

  public formatFeature(eventId, featureObj) {
    return {
      IdPackage: eventId,
      ComponentSupplier: 1,
      ComponentCategory: featureObj.catalogItemType,
      catalogItemId: featureObj.catalogItemId,
    }
  }

  // I'm not sure the consumer of this observable can handle thrown errors, so instead of using handleGTResponse() operator,
  // I am matching the previous implementation of returning null just to be safe.
  // TODO: Refactor to use handleGTResponse() operator?  Or just completely switch to using ngrx store.
  public getFormsForPackage(packageId): Observable<Form[]> {
    return this.http.get<GTResponse<Form[]>>(this.URLs._getFormsForPackage(packageId)).pipe(
      map(gtResponse => { 
        if (GTResponseHelper.hasError(gtResponse)) return null;
        return GTResponseHelper.getPayload(gtResponse);
      }));
  }

  public createForm(detail): Observable < any > {
    return this.http.post < any > (this.URLs._createForm(), detail);
  }

  public getForm(formId: number): Observable<Form> {
    return this.http.get<GTResponse<Form>>(this.URLs._getForm(formId)).pipe(
      handleGTResponse(),
    );
  }

  public updateForm(formDetail): Observable < any > {
    return this.http.post < any > (this.URLs._updateForm(), formDetail).pipe(
      map(res => {
        if (res.Result) {
          return res.Obj;
        } else {
          return null;
        }
      }));
  }

  getPicture(packageId): Observable < any > {
    return this.http.get < any > (
        this.URLs._getPackageImage(packageId)).pipe(
      map((res) => {
        if (res.Result) {
          //Assume it's a URL
          let imgSrc = res.Obj;

          //If it's not, treat it as a base64 string representation of the image
          if(!imgSrc.startsWith('http')) {
            imgSrc = 'data:image/jpg;base64,' + imgSrc;
          }

          return imgSrc;
        } else {
          const imgDefult = require(`assets/img/default-image.jpg`);
          return imgDefult;
        }
      }));
  }

  public canCancel(IdPackage): Observable < any > {
    return this.http.get < any > (this.URLs._canCancelEvent(IdPackage)).pipe(
      map(res => res.Result));
  }

  public cancel(IdPackage): Observable < any > {
    return this.http.post < any > (this.URLs._cancelEvent(IdPackage), null).pipe(
      map(res => res.Result));
  }

  getPackageLogs(IdPackage: number): Observable<LogEntry[]> {
    return this.http.get<GTResponse<LogEntry[]>>(this.URLs._getPackageLogs(IdPackage)).pipe(
      handleGTResponse(),
    );
  }

  GetPackageRecord(packageId: number): Observable<PackageRecordDTO> {
    return this.http.get<GTResponse<PackageRecordDTO>>(this.URLs._packageRecord(packageId)).pipe(
      handleGTResponse(),
      tap(packageRecord => { // Parse NotesJson -> Notes (TODO: KC: Not sure this does anything
        // since switching to GTResponse pattern)
        packageRecord.Components.forEach(component=>{
          component.Attendees.forEach(attendee=>{
            if(attendee.NotesJson){
              attendee.Notes = JSON.parse(attendee.NotesJson).Notes.Value;
            }
            else
              attendee.Notes = '';
          });
        });
      })
    );
  }

  public RemoveComponent(component): Observable < any > {
    return this.http.post <any> (this.URLs._packageRemoveComponent(), component).pipe(
      map(res => res.Result));
  }

  public UpdateComponentAttendeeNotesJson(attendees): Observable < any > {
    return this.http.post <any> (this.URLs._packageUpdateComponentAttendeeNotesJson(), attendees).pipe(
    map(res => res.Result));
  }

  // TODO: This is only used in three places to see if BI is enabled
  // it should be refactored to be saved in ngrx store b/c this data won't change very often
  // and only loaded once on login, or maybe page load
  public GetActiveSuppliers(): Observable<SupplierDTO[]> {
    return this.http.get<GTResponse<SupplierDTO[]>>(this.URLs._getSuppliers()).pipe(
      handleGTResponse(),
    );
  }

  public RemoveComponentOption(option): Observable < any > {
    return this.http.post <any> (this.URLs._packageRemoveComponentOption(), option).pipe(
    map(res => {
      return res;
    }));
  }

  public UploadPackageImage(IdPackage, UploadPackagePicture): Observable < any > {
    return this.http.post(this.URLs._uploadPackageImage(IdPackage), UploadPackagePicture);
  }
  
  public DeliverForms(formId, userIds): Observable < any > {
    return this.http.post(this.URLs._deliverForms(formId), userIds);
  }

  public ItineraryRecord(IdPackage): Observable<any> {
    return this.http.get(this.URLs._packageItineraryRecord(IdPackage));
  }

  public resendInvitations(event): Observable<any> {
    return this.http.post<any>(this.URLs._packageResendInvitations(), event);
  }

  // tslint:disable-next-line:member-ordering
  private _isActivityOrLodgingEvent: boolean;
  // tslint:disable-next-line:member-ordering
  public SetIsActivityOrLodging(value: boolean): boolean {
    this._isActivityOrLodgingEvent = value;
    return value;
  }

  public GetIsActivityOrLodging(): boolean {
    // one time usage, reset after it has been retrieved
    const val = this._isActivityOrLodgingEvent;
    this._isActivityOrLodgingEvent = false;
    return val;
  }

  // tslint:disable-next-line:member-ordering
  private _initialFeatureType: ActivityType = ActivityType.Default;
  // tslint:disable-next-line:member-ordering
  public SetInitialActivityType(value: ActivityType): ActivityType {
    this._initialFeatureType = value;
    return value;
  }

  public GetInitialActivityType(): ActivityType {
    // one time usage, reset after it has been retrieved
    const val = this._initialFeatureType;
    this._initialFeatureType = ActivityType.Default;
    return val;
  }

  public showEventName(event, components): boolean {
    if (components && event) {
      const feature = components.filter(component =>
        component.ComponentName === event.name ||
        component.ComponentName === event.PackageName
        );
      if (feature.length > 0 && components.length === 1) {
        return false;
      }
    }
    return true;
  }

  public showEventDescription(event, components): boolean {
    if (components && event) {
      const feature = components.filter(component =>
        (component.ComponentName === event.name || component.ComponentName === event.PackageName) &&
        (component.ComponentShortDesc === event.description || component.ComponentShortDesc === event.PackageDesc));
      if (feature.length > 0 && components.length === 1) {
        return false;
      }
    }
    return true;
  }

  public showEventDate(components): boolean {
    if (components) {
      const feature = components.filter(component => component.ComponentSupplierInvoiceId);

      if (feature.length > 0) {
        return true;
      }
    }
    return false;
  }

  sortOptions(options: any[]): any[] {
    return options.sort((a, b) => {
      if (a.Availability === 0 && b.Availability > 0) {
        return 1;
      } else if (a.Availability > 0 && b.Availability === 0) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  public isOptionUnavailable(option: any, partySize: number = 0): boolean {
    return option.Availability < partySize
      && (!option.Status || option.Status.toLowerCase() !== 'accept');
  }
}

export enum ActivityType {
  Default = 'show',
  Lodging = 'lodging',
  Restaurant = 'restaurant',
  Transportation = 'transportation',
  Other = 'other'
}
