import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as AttendeesActions from 'app/store/actions/attendees.action';
import { ModalSize, Modal } from 'app/store/models/modal';
import { CloseModal } from 'app/store/actions/modal.action';
import { Subject, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';
import { Attendee } from 'app/store/models/attendee';

@Component({
  selector: 'update-attendee-status',
  templateUrl: './update-attendee-status.component.html',
  styleUrls: ['./update-attendee-status.component.scss']
})
export class UpdateAttendeeStatusComponent implements OnInit {
    @Input() public isLeader: boolean = false;
    // REFACTOR: attendee should be Attendee type, need to keep as any to interface with
    // attendee package details. Eventually use the store models in APD and fix this.
    // make sure to change the template, too
    @Input() public attendee: any;
    @Input() public isSupplierEvent: boolean = false;

    public userId$: Observable<number>;
    public userId;

    public state: string = 'modal';
    public status: string;
    private unsubscribe$: Subject<null> = new Subject<null>();

    constructor(
      private _store: Store<fromRoot.State>,
      private actions$: Actions,
    ) { }

    ngOnInit() {
      if (this.attendee) {
        this.status = this.attendee.Status;
      }
      
      this.userId$ = this._store.select(fromRoot.getUserId)
        this.userId$.subscribe(res => {
            this.userId = res;
        });
    }

    update() {
      //this.attendee.Status = this.status;      
      const updatedAttendee = {eventId: this.attendee.IdPackage, id: this.userId, status: this.status} as Attendee;
      
      this._store.dispatch(new AttendeesActions.UpdateAttendeeStatusComponentUpdateAttendeeStatus({attendee: updatedAttendee}));

      // TODO: Catching an action directly in a component is not a great pattern,
      //       On the other hand, this 'get once/next' behavior can be tricky to implement with selectors
      this.actions$.pipe(
        ofType<AttendeesActions.AttendeeEffectUpdateAttendeeStatusSuccess>(AttendeesActions.ActionTypes.AttendeeEffectUpdateAttendeeStatusSuccess),
        take(1),
      ).subscribe(res => {
          this.state = 'success';
      });
    }

    close() {
        this._store.dispatch(new CloseModal());
    }

    ngOnDestroy(): void {
      this.unsubscribe$.next();
    }
}

export const UpdateAttendeeStatusConfig: Modal = {
    title: 'Attendee Status',
    size: ModalSize.Large,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: UpdateAttendeeStatusComponent
    }
};
