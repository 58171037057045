import { URLConfig, ClientAuthConfig } from '../helpers/config.service';
import { HttpClient } from '@angular/common/http';
var PasswordResetService = /** @class */ (function () {
    function PasswordResetService(http, URLs, ClientAuth) {
        this.http = http;
        this.URLs = URLs;
        this.ClientAuth = ClientAuth;
    }
    PasswordResetService.prototype.verifySecurityToken = function (token) {
        return this.http.post(this.URLs._verifySecurityToken(), token);
    };
    PasswordResetService.prototype.resetPassword = function (resetPasswordRequest) {
        return this.http.post(this.URLs._resetPassword(), resetPasswordRequest);
    };
    return PasswordResetService;
}());
export { PasswordResetService };
