<ng-container *ngIf="user$ | async as user">
<div class="row">
  <div class="col-xs-12">
    <ba-card>
      <!-- Set 'inModal' = true so that login doesn't try to redirect on its own -->
      <login #loginComponent *ngIf="state === 'login'"
          [inModal]="true"
          [doNotRedirect]="true"
          (loginEmit)="loginAction($event)">
      </login>
      <register *ngIf="state === 'register'"
          [inModal]="true"
          [doNotRedirect]="true"
          (registerEmit)="loginAction($event)"
          (loginInfo)="getLoginInfo($event)">
      </register>

      <div *ngIf="state === 'loggedIn'" class="info-center">
        <div class="logged-in-title">
          <p>{{user.firstName}} {{user.lastName}}, you are logged in!</p>
        </div>
        <hr class="logged-in-hr">
        <p>Email: {{user.email}}</p>
        <br />

        <div><button type="submit" class="btn btn-primary logout-btn" (click)="logout()">Log Out</button></div>
      </div>
    </ba-card>  

  </div>
</div>

</ng-container>

<grouptools-modal #modal
    [title]="modalTitle"
    [size]="modalSize">

  <div modal-body [ngSwitch]="modalState">

    <!-- ACCOUNT VERIFICATION -->
    <div *ngSwitchCase="'accountVerification'">
      <account-verification
        [loginName]="loginName" 
        [loginEmail]="loginEmail" 
        (skipVerification)="loginAction('verified')">
      </account-verification>
    </div>

    <!-- PASSWORD RESET SUCCESS -->
    <grouptools-success *ngSwitchCase="'passwordReset'">
      <div class="login-center">
        <span class="row">If you have an account with GroupTools, your password reset instructions will be sent. Don't have an account? <a href="register">Please register.</a></span>
      </div>
      <div class="col-lg-12">
        <button class="btn btn-primary login-btn" type="button" (click)="modal.grouptoolsModal.hide()">Return to Login</button>
      </div>
    </grouptools-success>

  </div>

</grouptools-modal>
