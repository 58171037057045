import { Component, OnInit, Input } from '@angular/core';
import { Modal, ModalSize } from 'app/store/models/modal';

@Component({
  selector: 'grouptools-success-modal',
  templateUrl: './grouptools-success-modal.component.html',
  styleUrls: ['./grouptools-success-modal.component.scss']
})
export class GroupToolsSuccessModal implements OnInit {

  @Input() headerText: string;
  @Input() bodyText: string;
  @Input() type: 'success' | 'error' | 'pause';
  @Input() size = 'modal-md';
  @Input() buttons: ButtonConfig;

  constructor() { }

  ngOnInit() {
  }

}

export interface ButtonConfig {
  primary?: {
    text: string;
    action: any;
    disabled?: boolean;
  },
  secondary?: {
    text: string;
    action: any;
    disabled?: boolean;
  }
}

export const GroupToolsSuccessModalConfig: Modal = {
    title: 'Success',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: GroupToolsSuccessModal
    }
};
