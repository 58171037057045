import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minDateValidator(minDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value || control.value === '') {
        return null;
      }
        const target = new Date(control.value);
        const today = new Date()
        today.setDate(today.getDate());
        const min = new Date(minDate);
        if (minDate === 'today') {
            const beforeToday = today >= target;
            return beforeToday ? { 'beforeToday': { value: control.value } } : null;
        }
        const tooEarly = control.value < minDate;
        return tooEarly ? { 'dateTooEarly': { value: control.value } } : null;
    };
}
