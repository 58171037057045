/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-from-bib-order-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pages/event-builder/create-from-bib-order/create-from-bib-order-view.component.ngfactory";
import * as i3 from "../../pages/event-builder/create-from-bib-order/create-from-bib-order-view.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/common";
import * as i7 from "./create-from-bib-order-dialog.component";
import * as i8 from "@ngrx/store";
var styles_CreateFromBibOrderDialogComponent = [i0.styles];
var RenderType_CreateFromBibOrderDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateFromBibOrderDialogComponent, data: {} });
export { RenderType_CreateFromBibOrderDialogComponent as RenderType_CreateFromBibOrderDialogComponent };
export function View_CreateFromBibOrderDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "create-from-bib-order-view", [], null, [[null, "dateSelectedEvent"], [null, "getStartedEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateSelectedEvent" === en)) {
        var pd_0 = (_co.dateSelected() !== false);
        ad = (pd_0 && ad);
    } if (("getStartedEvent" === en)) {
        var pd_1 = (_co.getStartedClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CreateFromBibOrderViewComponent_0, i2.RenderType_CreateFromBibOrderViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.CreateFromBibOrderViewComponent, [i4.FormBuilder, i5.MatDialog], { matFormFieldAppearance: [0, "matFormFieldAppearance"], isInModal: [1, "isInModal"], authApiState: [2, "authApiState"] }, { dateSelectedEvent: "dateSelectedEvent", getStartedEvent: "getStartedEvent" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "standard"; var currVal_1 = true; var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform(_co.bibOrderNumberCreateEventApiState$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CreateFromBibOrderDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "create-from-bib-order-dialog", [], null, null, null, View_CreateFromBibOrderDialogComponent_0, RenderType_CreateFromBibOrderDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.CreateFromBibOrderDialogComponent, [i5.MatDialogRef, i5.MAT_DIALOG_DATA, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateFromBibOrderDialogComponentNgFactory = i1.ɵccf("create-from-bib-order-dialog", i7.CreateFromBibOrderDialogComponent, View_CreateFromBibOrderDialogComponent_Host_0, {}, {}, []);
export { CreateFromBibOrderDialogComponentNgFactory as CreateFromBibOrderDialogComponentNgFactory };
