import * as formActions from '../actions/forms';
import { Form } from '../models/form';

export type State = Form[];

// initialize state to an empty array
export function reducer(state: State = [], action: formActions.Actions): State {
    switch (action.type) {
        case formActions.LOAD_OWNED_FORMS_OK:
        {
            return action.payload;
        }
        case formActions.LOAD_EVENT_FORMS_OK:
        {
            return action.payload;
        }
        default:
        {
            return state;
        }
    }
}

