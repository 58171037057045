import * as tslib_1 from "tslib";
import { NgxConfigureOptions, NgxConfigureService } from 'ngx-configure';
import * as i0 from "@angular/core";
import * as i1 from "ngx-configure";
var GroupToolsConfigureOptions = /** @class */ (function (_super) {
    tslib_1.__extends(GroupToolsConfigureOptions, _super);
    function GroupToolsConfigureOptions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ConfigurationURL = 'assets/config/config.json';
        _this.AppVersion = '0.0.0';
        _this.BustCache = false;
        return _this;
    }
    return GroupToolsConfigureOptions;
}(NgxConfigureOptions));
export { GroupToolsConfigureOptions };
var ConfigService = /** @class */ (function () {
    // This class serves as a simple wrapper around NgxConfigureService
    // to all type-safe config access :-) If configOptions property is needed,
    // it can be added here as well.  This class should be used instead of NgxConfigureService
    // public config: GTConfig;
    function ConfigService(ngxConfigService) {
        this.ngxConfigService = ngxConfigService;
    }
    Object.defineProperty(ConfigService.prototype, "config", {
        get: function () {
            return this.ngxConfigService.config;
        },
        enumerable: true,
        configurable: true
    });
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.NgxConfigureService)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
