import { enableProdMode } from '@angular/core';
import * as Config from './assets/config/config.json';
import 'hammerjs';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (Config.production === "true") {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    preserveWhitespaces: true
});
