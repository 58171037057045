import { Component, ViewChild, EventEmitter, Output, Input, NgZone } from '@angular/core';

import 'style-loader!../login.component.scss';
import { AuthService, EmailVerification, Person, LoginPerson } from '../../../authentication/authentication.service';

import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as attendeePackages from 'app/store/actions/attendeePackages';
import { Observable } from 'rxjs';

@Component({
  selector: 'login-with-social',
  templateUrl: './login-with-social.component.html',
  providers: [
    AuthService,
  ]
})
export class LoginWithSocialComponent {
    public loginPerson: LoginPerson;
    
    @Input() emailPrefill: string = '';
    @Input() amazonUser: any;
    @Input() googleUser: any;
    @Input() facebookUser: any;
    @Input() inModal: boolean = false;
    @Output() differentAccount: EventEmitter<any> = new EventEmitter();
    @Output() googleRegisterEmit: EventEmitter<any> = new EventEmitter();
    @Output() amazonRegisterEmit: EventEmitter<any> = new EventEmitter();
    @Output() facebookRegisterEmit: EventEmitter<any> = new EventEmitter();
    @Output() handlePostLogin: EventEmitter<any> = new EventEmitter();
    @Output() openDifferentAccountModal: EventEmitter<null> = new EventEmitter();
    @Output() openGoogleRegisterModal: EventEmitter<any> = new EventEmitter();
    @Output() openAmazonRegisterModal: EventEmitter<any> = new EventEmitter();
    @Output() openFacebookRegisterModal: EventEmitter<any> = new EventEmitter();

    constructor(
        private _authService: AuthService,
        private zone: NgZone
      ) {}

    socialMatchesPrefillCheck(email: string, socialType: string, userObj: any) {
        if (this.emailPrefill &&
          (this.emailPrefill.toLocaleLowerCase() === email.toLocaleLowerCase())) {
            if (socialType === 'google') { this.googleLogin(userObj); }
            else if (socialType === 'amazon') { this.amazonLogin(userObj); } 
            else if (socialType === 'facebook') { this.facebookLogin(userObj); } 
          } else if (this.emailPrefill) {
            // make sure user knows they're logging into a different account
            if (this.inModal) {
              this.differentAccount.emit({type: socialType, value: userObj});
            } else {
                this.openDifferentAccountModal.emit();
            }
          } else {
            if (socialType === 'google') { this.googleLogin(userObj); }
            else if (socialType === 'amazon') { this.amazonLogin(userObj); } 
            else if (socialType === 'facebook') { this.facebookLogin(userObj); } 
          }
    }
    
    google(googleUser) {
        this.googleUser = googleUser;
        const profile = googleUser.getBasicProfile();
        const email = profile.getEmail();

        this.socialMatchesPrefillCheck(email, 'google', googleUser);
    }
    
    googleLogin(googleUser) {
        this.zone.run(() => {
          this.googleUser = googleUser;
          const profile = googleUser.getBasicProfile();
          const email = profile.getEmail();
          const idToken = googleUser.getAuthResponse().id_token;
          const accessToken = googleUser.getAuthResponse().access_token;
          const encodedPassword = encodeURIComponent(idToken);
          const encodedAccessToken = encodeURIComponent(accessToken);
    
          this.loginPerson = new LoginPerson(email, encodedPassword, encodedAccessToken);
    
          this._authService.login(this.loginPerson, 'google')
            .subscribe(token => {
              this.handlePostLogin.emit(token);
            }, err => {
              if (this.inModal) {
                this.googleRegisterEmit.emit(googleUser);
              } else {
                this.openGoogleRegisterModal.emit(googleUser);
              }
            });
        });
    }
    
    amazon(amazonUser) {
        this.amazonUser = amazonUser;
        const email = amazonUser.Email;
    
        this.socialMatchesPrefillCheck(email, 'amazon', amazonUser);
    }
    
    amazonLogin(amazonUser) {
        this.zone.run(() => {
          const token = encodeURIComponent(amazonUser.accessToken);
          this.amazonUser = amazonUser;
    
          this.loginPerson = new LoginPerson(amazonUser.Email, token);
    
          this._authService.login(this.loginPerson, 'amazon')
            .subscribe(authToken => {
              this.handlePostLogin.emit(authToken);
            }, err => {
              if (this.inModal) {
                this.amazonRegisterEmit.emit(amazonUser);
              } else {
                this.openAmazonRegisterModal.emit(amazonUser);
              }
            });
        });
    }
    
    facebook (facebookUser) {
        this.facebookUser = facebookUser;
        const email = facebookUser.Email;
    
        this.socialMatchesPrefillCheck(email, 'facebook', facebookUser);
    }
    
    facebookLogin(facebookUser) {
        this.zone.run(() => {
          const token = encodeURIComponent(facebookUser.AccessToken);
          this.facebookUser = facebookUser;
    
          this.loginPerson = new LoginPerson(facebookUser.Email, token);
    
          this._authService.login(this.loginPerson, 'facebook')
            .subscribe(authToken => {
              this.handlePostLogin.emit(authToken);
            }, err => {
              if(this.inModal) {
                this.facebookRegisterEmit.emit(facebookUser);
              } else {
                this.openFacebookRegisterModal.emit(facebookUser);
              }
            });
        });
    }
}