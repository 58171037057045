import { map } from 'rxjs/operators';
import { URLConfig } from '../../../pages/helpers/config.service';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BaMenuService } from '../../services/baMenu/baMenu.service';
import { GlobalState } from '../../../global.state';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../authentication/authentication.service';
var BaMenu = /** @class */ (function () {
    function BaMenu(_router, _menuService, _authService, _state, _urls, store) {
        this._router = _router;
        this._menuService = _menuService;
        this._authService = _authService;
        this._state = _state;
        this._urls = _urls;
        this.store = store;
        this.sidebarCollapsed = true;
        this.LOGO = require("assets/logo/GroupTools-Logo-nav.svg");
        this.staticItems = [
            {
                'path': ['/pages/user'],
                'title': 'Profile',
                'icon': ''
            },
            {
                'path': 'https://support.grouptools.com/hc/',
                'title': 'Support',
                'icon': '',
                'link': true
            }
        ];
        this.expandMenu = new EventEmitter();
        this.outOfArea = -200;
    }
    BaMenu.prototype.ngOnChanges = function (change) {
        // update 2018-05-16 ska - check that access token exists because sometimes this is having
        // weird results on initial page load
        if (change.loggedIn) {
            if (change.loggedIn.currentValue && localStorage.access_token) {
                this.reloadMenuItems().subscribe();
            }
        }
        if (change.isGroupLeader) {
            if (change.isGroupLeader.currentValue) {
                this.reloadMenuItems().subscribe();
            }
        }
    };
    BaMenu.prototype.reloadMenuItems = function () {
        var _this = this;
        return this._authService.getLeaderStatus().pipe(map(function (_a) {
            var leaderStatus = _a.Obj;
            _this._menuService.getMenuItems(leaderStatus).pipe(map(function (menuItems) {
                _this.menuItems = menuItems;
            })).subscribe();
        }));
    };
    BaMenu.prototype.toggleMenu = function () {
        this.sidebarCollapsed = !this.sidebarCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.sidebarCollapsed);
        return false;
    };
    return BaMenu;
}());
export { BaMenu };
