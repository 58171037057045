import { Action } from '@ngrx/store';
import { Form } from '../models/form'

export const LOAD_OWNED_FORMS = '[Forms] Load Owned Forms';
export const LOAD_OWNED_FORMS_OK = '[Forms] Load Owned Forms OK';
export const LOAD_EVENT_FORMS = '[Forms] Load Event Forms';
export const LOAD_EVENT_FORMS_OK = '[Forms] Load Event Forms OK';

export class LoadOwnedForms implements Action {
  readonly type = LOAD_OWNED_FORMS;
  constructor() { }
}

export class LoadOwnedFormsOk implements Action {
  readonly type = LOAD_OWNED_FORMS_OK;
  constructor(public payload: Form[]) { }
}

export class LoadEventForms implements Action {
  readonly type = LOAD_EVENT_FORMS;
  constructor(public payload: number) { }
}

export class LoadEventFormsOk implements Action {
  readonly type = LOAD_EVENT_FORMS_OK;
  constructor(public payload: Form[]) { }
}

export type Actions
  = LoadOwnedForms |
  LoadOwnedFormsOk |
  LoadEventForms |
  LoadEventFormsOk;
