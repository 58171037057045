import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'app/store/models/contacts';

@Pipe({name: 'onlyUninvited'})
export class OnlyUninvitedPipe implements PipeTransform {
    transform(contactsArray: Contact[], invitedArray): Contact[] {
        if (contactsArray && invitedArray ) {
            // const contacts = contactsArray.slice().map(contact => contact.UserId);
            const invited = invitedArray.slice().map(invitee => invitee.id);
            return contactsArray.filter(contact => !invited.includes(contact.UserId));
        }
        if (contactsArray) {
            if (contactsArray.length) {
                return contactsArray;
            }
        }
        return [];
  }
}
