import { PipeTransform } from '@angular/core';
var OnlyUninvitedPipe = /** @class */ (function () {
    function OnlyUninvitedPipe() {
    }
    OnlyUninvitedPipe.prototype.transform = function (contactsArray, invitedArray) {
        if (contactsArray && invitedArray) {
            // const contacts = contactsArray.slice().map(contact => contact.UserId);
            var invited_1 = invitedArray.slice().map(function (invitee) { return invitee.id; });
            return contactsArray.filter(function (contact) { return !invited_1.includes(contact.UserId); });
        }
        if (contactsArray) {
            if (contactsArray.length) {
                return contactsArray;
            }
        }
        return [];
    };
    return OnlyUninvitedPipe;
}());
export { OnlyUninvitedPipe };
