import * as tslib_1 from "tslib";
import * as ActionActions from '../actions/actions.action';
export var initialState = {
    actions: [],
    eventId: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionActions.ActionTypes.ActionsComponentLoadActions:
            {
                return tslib_1.__assign({}, state, { eventId: action.payload.eventId });
            }
        case ActionActions.ActionTypes.ActionsEffectLoadActionsSuccess:
            {
                return tslib_1.__assign({}, state, { actions: action.payload.userActions });
            }
        case ActionActions.ActionTypes.ActionsEffectLoadActionsFailure:
            {
                return tslib_1.__assign({}, state);
            }
        case ActionActions.ActionTypes.AuthenticationServiceLogout:
            {
                return tslib_1.__assign({}, initialState);
            }
        default:
            {
                return state;
            }
    }
}
