import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EventItem } from "app/store/models/eventItem";
import { AttendeeUpsertRequest } from "app/store/models/requests/attendee-upsert-request";
import { ShowInfo } from "app/store/models/show-info";

export const eventItemAdapter: EntityAdapter<EventItem> = createEntityAdapter<EventItem>({
    sortComparer: (eventItemA, eventItemB) => { return eventItemA.id - eventItemB.id; }
});
// Attendee Adapter is used where all attendees are existing (in other words, all have a valid eventAttendeeId)
export const attendeeUpsertAdapter: EntityAdapter<AttendeeUpsertRequest> = createEntityAdapter<AttendeeUpsertRequest>({
    selectId: eventAttendee => eventAttendee.eventAttendeeId,
});
// Mixed Attendee Adapter is used where new and existing attendees might coexist
export const mixedAttendeeAdapter: EntityAdapter<AttendeeUpsertRequest> = createEntityAdapter<AttendeeUpsertRequest>({
    selectId: eventAttendee => eventAttendee.combinedEventAttendeeId,
});
export const showInfoAdapter: EntityAdapter<ShowInfo> = createEntityAdapter<ShowInfo>({
    selectId: showInfo => showInfo.id,
});

export const eventItemEntitySelectors = eventItemAdapter.getSelectors();
export const attendeeUpsertEntitySelectors = attendeeUpsertAdapter.getSelectors();
export const mixedAttendeeEntitySelectors = mixedAttendeeAdapter.getSelectors();
export const showInfoEntitySelectors = showInfoAdapter.getSelectors();
