import * as tslib_1 from "tslib";
import * as PDActions from "app/store/actions/packageDetails.actions";
import { ApiState } from "../models/apiData";
export var initialState = {
    packageDetails: {},
    tagInfo: null,
    components: [],
    packageRecord: {
        Components: [],
        Package: {
            IdPackage: 0,
            PackageName: '',
            PackageShortDesc: '',
            PackageDesc: '',
            PackageDate: null,
            PackageTime: null,
            ComponentCount: 0,
            InviteCount: 0,
            AttendeeCount: 0,
            StartingPrice: 0,
            EventStatus: '',
            CreatedAt: '',
            UpdatedAt: null,
            CreatedBy: '',
            JsonDoc: '{}',
            ShortPublicUrl: '',
            RSVPDueDate: null,
            PhotoUrl: '',
        }
    },
    paymentsReceivedSummary: { Received: 0, AmountDue: 0, Balance: 0 },
    paymentsSentSummary: null,
    allSuppliers: [],
    packageLogs: [],
    separatedPayments: {
        AttendeePayments: [],
        SupplierPayments: [],
    },
    packageSuppliersByActivity: [],
    bibOrderNumberCreateEventApiState: {
        apiState: ApiState.Initial,
        errorMessage: null,
    },
    supplierOrderNumberCreateEventApiState: {
        apiState: ApiState.Initial,
        errorMessage: null,
    },
    tickets: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDEffectsLoadPackageDetails: {
            return tslib_1.__assign({}, state, { packageDetails: tslib_1.__assign({}, state.packageDetails, { IdPackage: action.payload.eventId }) });
        }
        case PDActions.ActionTypes.PDApiLoadPackageDetailsSuccess: {
            return tslib_1.__assign({}, state, { packageDetails: action.payload.packageDetails });
        }
        case PDActions.ActionTypes.PDApiLoadPackageDetailsFailure: {
            return tslib_1.__assign({}, state);
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadTagInfoSuccess: {
            return tslib_1.__assign({}, state, { tagInfo: action.payload.tagInfo });
        }
        case PDActions.ActionTypes.PDApiLoadTagInfoFailure: {
            return tslib_1.__assign({}, state);
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadPackageComponentsSuccess: {
            return tslib_1.__assign({}, state, { components: action.payload.components });
        }
        case PDActions.ActionTypes.PDApiLoadPackageComponentsFailure: {
            return tslib_1.__assign({}, state);
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadPackageRecordSuccess: {
            return tslib_1.__assign({}, state, { packageRecord: action.payload.packageRecord });
        }
        case PDActions.ActionTypes.PDApiLoadPackageRecordFailure: {
            return tslib_1.__assign({}, state);
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadSuppliersSuccess: {
            return tslib_1.__assign({}, state, { allSuppliers: action.payload.suppliers });
        }
        case PDActions.ActionTypes.PDApiLoadSuppliersFailure: {
            return tslib_1.__assign({}, state);
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadPackageLogsSuccess: {
            return tslib_1.__assign({}, state, { packageLogs: action.payload.packageLogs });
        }
        case PDActions.ActionTypes.PDApiLoadPackageLogsFailure: {
            return tslib_1.__assign({}, state);
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadSuppliersForPackageSuccess: {
            return tslib_1.__assign({}, state, { packageSuppliersByActivity: action.payload.packageSuppliersByActivity });
        }
        case PDActions.ActionTypes.PDApiLoadSuppliersForPackageFailure: {
            return tslib_1.__assign({}, state);
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.CreateEventFromBibOrderDialog: {
            return tslib_1.__assign({}, state, { bibOrderNumberCreateEventApiState: tslib_1.__assign({}, state.bibOrderNumberCreateEventApiState, { apiState: ApiState.InFlight }) });
        }
        case PDActions.ActionTypes.PDApiCreateEventFromBibOrderSuccess: {
            return tslib_1.__assign({}, state, { bibOrderNumberCreateEventApiState: tslib_1.__assign({}, state.bibOrderNumberCreateEventApiState, { apiState: ApiState.Success }) });
        }
        case PDActions.ActionTypes.PDApiCreateEventFromBibOrderFailure: {
            return tslib_1.__assign({}, state, { bibOrderNumberCreateEventApiState: tslib_1.__assign({}, state.bibOrderNumberCreateEventApiState, { apiState: ApiState.Error, errorMessage: action.payload.errorMessage }) });
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.CreateEventFromSupplierOrderDialog: {
            return tslib_1.__assign({}, state, { supplierOrderNumberCreateEventApiState: tslib_1.__assign({}, state.supplierOrderNumberCreateEventApiState, { apiState: ApiState.InFlight }) });
        }
        case PDActions.ActionTypes.PDApiCreateEventFromSupplierOrderSuccess: {
            return tslib_1.__assign({}, state, { supplierOrderNumberCreateEventApiState: tslib_1.__assign({}, state.supplierOrderNumberCreateEventApiState, { apiState: ApiState.Success }) });
        }
        case PDActions.ActionTypes.PDApiCreateEventFromSupplierOrderFailure: {
            return tslib_1.__assign({}, state, { supplierOrderNumberCreateEventApiState: tslib_1.__assign({}, state.supplierOrderNumberCreateEventApiState, { apiState: ApiState.Error, errorMessage: action.payload.errorMessage }) });
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.UpdateEventFromSupplierOrder: {
            return tslib_1.__assign({}, state // TODO: May want this to update state in some way
            );
        }
        case PDActions.ActionTypes.PDApiUpdateEventFromSupplierOrderSuccess: {
            return tslib_1.__assign({}, state // TODO: May want this to update state in some way
            );
        }
        case PDActions.ActionTypes.PDApiUpdateEventFromSupplierOrderFailure: {
            return tslib_1.__assign({}, state
            // TODO: Add some error state and record here using action.payload.errorMessage
            );
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.LoadTickets: {
            return tslib_1.__assign({}, state // TODO: May want this to update state in some way
            );
        }
        case PDActions.ActionTypes.PDApiLoadTicketsSuccess: {
            return tslib_1.__assign({}, state, { tickets: action.payload.loadTicketsResponse.tickets });
        }
        case PDActions.ActionTypes.PDApiLoadTicketsFailure: {
            return tslib_1.__assign({}, state
            // TODO: Add some error state and record here using action.payload.errorMessage
            );
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PurchaseTickets: {
            return tslib_1.__assign({}, state // TODO: May want this to update state in some way
            );
        }
        case PDActions.ActionTypes.PDApiPurchaseTicketsSuccess: {
            return tslib_1.__assign({}, state);
        }
        case PDActions.ActionTypes.PDApiPurchaseTicketsFailure: {
            return tslib_1.__assign({}, state
            // TODO: Add some error state and record here using action.payload.errorMessage
            );
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.AuthenticationServiceLogout:
            {
                return tslib_1.__assign({}, initialState);
            }
        //--------------------------------------------------------------------------------------
        case PDActions.ActionTypes.UpdateEventOk: {
            // CD 09/01/2023 - Right now we have selectedEvent and packageDetails slices of state. UpdateEventOk previously only updated selectedEvent, but we need to update both
            //                 since both are actively used.
            return tslib_1.__assign({}, state, { packageDetails: tslib_1.__assign({}, state.packageDetails, { JsonDoc: action.payload.jsonDoc }) });
        }
        default: {
            return state;
        }
    }
}
