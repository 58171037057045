import { throwError as observableThrowError, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { URLConfig } from './config.service';
import { HttpClient } from '@angular/common/http';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
import * as i2 from "@angular/common/http";
var AppStateService = /** @class */ (function () {
    function AppStateService(URLs, http) {
        this.URLs = URLs;
        this.http = http;
        this.emailPrefill$ = new Subject();
    }
    AppStateService.prototype.getMyPackages = function () {
        var _this = this;
        return this.http.get(this.URLs._packageGetUrl()).pipe(handleGTResponse(), tap(function (packages) {
            _this.myPackages = packages;
        }), tap(function (packages) {
            packages.map(function (p) {
                if (p.EventStatus.toUpperCase() !== 'CANCELLED') {
                    p.customActions = ['View Details'];
                }
            });
        }), catchError(handleError));
    };
    AppStateService.prototype.getOwnedPackages = function () {
        return this.http.get(this.URLs._packagesOwnedUrl()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getInvitedEvents = function () {
        return this.http.get(this.URLs._getInvitedEvents()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getOwnedEvents = function () {
        return this.http.get(this.URLs._getOwnedEvents()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getTransferredEvents = function () {
        return this.http.get(this.URLs._getTransferredEvents()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getOwnedForms = function () {
        return this.http.get(this.URLs._getOwnedForms()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getFormsForEvent = function (eventId) {
        return this.http.get(this.URLs._getFormsForPackage(eventId)).pipe(handleGTResponse());
    };
    AppStateService.prototype.getMessagesCount = function () {
        return this.http.get(this.URLs._messagesSummary()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getEvents = function () {
        return this.http.get(this.URLs._packageMyPackagesUrl());
    };
    //==================================================================================================================
    AppStateService.prototype.getSurveyEvents = function () {
        return this.http.get(this.URLs._getMySurveyEvents()).pipe(handleGTResponse());
    };
    AppStateService.prototype.getSurveyAttendees = function () {
        return this.http.get(this.URLs._getSurveyAttendees()).pipe(handleGTResponse());
    };
    //==================================================================================================================
    AppStateService.prototype.getEmailPrefill = function () {
        return this.emailPrefill$.asObservable();
    };
    AppStateService.prototype.setEmailPrefill = function (email) {
        this.emailPrefill$.next(email);
    };
    AppStateService.ngInjectableDef = i0.defineInjectable({ factory: function AppStateService_Factory() { return new AppStateService(i0.inject(i1.URLConfig), i0.inject(i2.HttpClient)); }, token: AppStateService, providedIn: "root" });
    return AppStateService;
}());
export { AppStateService };
export var handleError = function (error) {
    var errMsg;
    if (error instanceof Response) {
        var body = error || '';
        var err = body || JSON.stringify(body);
        errMsg = error.status + " - " + (error.statusText || '') + " " + err;
    }
    else {
        errMsg = error.message ? error.message : error.toString();
    }
    //console.error(errMsg);
    return observableThrowError(errMsg);
};
