
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { URLConfig } from 'app/pages/helpers/config.service';

import { Store } from '@ngrx/store';
import { Contact } from 'app/store/models/contacts';
import * as fromRoot from 'app/store/reducers';
import { ListOptions } from 'app/widgets/grouptools-list/grouptools-list.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions } from '@angular/http';

@Injectable()
export class ImportContactsService {
  public listOptions: ListOptions<any> = { // TODO: Change from any type to concrete
    multiSelectMode: true,
    hideHeader: false,
    hideSubHeader: false,
    hideDate: false,
    hideMenu: false,
    hidePagination: false,
    hideHead: false,
    collapsed: false,
    columns: [{
        label: 'name',
        fieldName: 'FullName'
      },
      {
        label: 'Email',
        fieldName: 'Email'
      },
      {
        label: 'Phone',
        fieldName: 'PhoneValue'
      }
    ],
    singleActions: ['Import']
  }
  constructor(
    private http: HttpClient,
    private URLs: URLConfig,
    private store: Store < fromRoot.State >
  ) {}


  public processCsv(data: FormData): Observable < any > {
    return this.http.post(this.URLs._processCsv(), data);
  }

  public getGoogleContacts(contacts, accessToken ? ): Observable < any > {
    let options;
    if (accessToken) {
      options = { params: new HttpParams().set('accessToken', accessToken ) }
    }
    return this.http.get(this.URLs._getGoogleContacts(), options).pipe(
      map(res => this.formatGoogleData(res, contacts)),
      catchError((err: Response) => {
        //console.error(err);
        return observableThrowError(err);
      }),)
  }

  public formatGoogleData(googleData, contacts): FormattedGoogleData {
    if (googleData.ResponseMessage === 'unavailable') {
      return 'Unavailable';
    }
    const data = googleData.Obj;
    const emailArray = contacts.map(contact => contact.Email);
    const formattedGoogleData = data
      .reduce((output, connection) => {
        output.push({
          FirstName: connection.names ? connection.names[0].givenName : null,
          LastName: connection.names ? connection.names[0].familyName : null,
          FullName: connection.names ? `${connection.names[0].givenName ? connection.names[0].givenName : ''} ${connection.names[0].familyName ? connection.names[0].familyName : ''}` : null,
          Email: connection.emailAddresses ? connection.emailAddresses[0].value : null,
          PhoneValue: connection.phoneNumbers ? connection.phoneNumbers[0].value : null,
        });
        return output;
      }, [])
      .filter(connection => !!(connection.FullName))
    return formattedGoogleData;
  }

  public getFacebookContacts(): Observable < any > {
    return this.http.get(this.URLs._getFacebookContacts());
  }

  public getOutlookContacts(): Observable < any > {
    return this.http.get(this.URLs._getOutlookContacts());
  }

  public formatOutlookData(outlookData, contacts): FormattedOutlookData {
    const data = outlookData.value;
    const emailArray = contacts.map(contact => contact.Email);
    const formattedOutlookData = data
      .reduce((output, connection) => {
        output.push({
          FirstName: connection.GivenName ? connection.GivenName : null,
          LastName: connection.Surname ? connection.Surname : null,
          FullName: connection.GivenName ? `${connection.GivenName} ${connection.Surname}` : null,
          Email: connection.EmailAddresses ? connection.EmailAddresses[0].Address : null,
        });
        return output;
      }, [])
      .filter(connection => !!(connection.FullName))
    return formattedOutlookData;
  }

}

export interface FormattedGoogleData {

}

export interface FormattedOutlookData {

}

