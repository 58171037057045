import { NgModule, ApplicationRef, Provider, FactoryProvider, ValueProvider } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// App is our top level component
import { App } from './app.component';
import { routing } from './app.routing';

import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { AuthService } from './authentication/authentication.service';
import { AppStateService } from './pages/helpers/appState.service';
import { URLConfig, ClientAuthConfig } from './pages/helpers/config.service';
import { CurrencyHelper } from './pages/helpers/currency-helper';
import { AppInterceptor } from 'app/authentication/app-interceptor.service';
import { ContactService } from 'app/pages/contacts/contact.service';

import { ModalModule, TabsModule, PopoverModule, BsDropdownModule } from 'ngx-bootstrap';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './store/reducers';
import * as fromUser from './store/reducers/user';
import * as fromAttendeePackages from './store/reducers/attendeePackages';
import * as fromEvent from './store/reducers/event';
import { NgbDateNativeAdapter } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDatePickerAdapter.service';
import { NgbDateCustomParserFormatter } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDateParser.service';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';

// store side effects
import { EffectsModule } from '@ngrx/effects';
import { ActionsEffects } from './store/effects/actions.effect';
import { EventEffects } from './store/effects/event.effect';
import { FormEffects } from './store/effects/form.effect';
import { UserEffects } from './store/effects/user.effect';
import { ContactsEffects } from './store/effects/contacts.effect';
import { MessageEffects } from './store/effects/message.effect';
import { ActivityEffects } from './store/effects/activity.effect';
import { AttendeesEffects } from './store/effects/attendees.effect';
import { PaymentEffects } from './store/effects/payment.effect';
import { GledEventItemEffects } from './store/effects/gledEventItem.effect';
import { EventItemEffects } from './store/effects/eventItem.effect';
import { PackageDetailsEffects } from './store/effects/packageDetails.effect';
import { SurveysEffects } from './store/effects/surveys.effect';

// NGX-Configure
import { NgxConfigureModule, NgxConfigureOptions } from 'ngx-configure'
import { GroupToolsConfigureOptions } from './app.configure-options';
import { NbgDatePickerUtility } from './grouptools-theme/services/ngDatePickerHelpers/ngDatePickerUtility.service';
import { NotSupportedModule } from './not-supported/not-supported.module';
import { NotIEGuard } from './grouptools-theme/guards/not-ie.guard';
import { ActionsService } from './actions/actions.service';
import { EventDetailsService } from './pages/package-details/event-details.service';
import { PaymentsService } from './pages/payments/payments.service';
import { SurveysService } from './pages/surveys/surveys.service';
import { MessagesService } from './pages/messages/messages.service';
import { GroupTixService } from './store/services/group-tix.service';

import { WidgetsModule } from './widgets/widgets.module';
import { ModalsModule } from './modals/modals.module';
import { GroupToolsNewModalService } from './widgets/grouptools-modal/grouptools-new-modal.service';
import { GroupToolsNewModalComponent } from './widgets/grouptools-modal/grouptools-new-modal.component';
// import { TestModalComponent } from './modals/test-modal.component';
import { GettingStartedService } from './pages/getting-started/getting-started.service';
import { GroupToolsSize } from './grouptools-theme/services/grouptoolsSize/grouptoolsSize.service';
import { UserService } from './pages/profilePage/user.service';
import { UpdateAttendeeStatusComponent } from './modals/update-attendee-status/update-attendee-status.component';
import { AttendeePackageDetailService } from './pages/attendee-package-details/attendee-package-details.service';
import { HotelPlannerModalComponent } from './modals/hotel-planner-modal/hotel-planner-modal.component';
import { ChangeAttendeeEmailsComponent } from './modals/change-attendee-emails/change-attendee-emails.component';
import { GrouptoolsModalComponent } from './widgets/grouptools-modal/grouptools-modal.component';
import { GroupToolsSuccessModal } from './modals/grouptools-success-modal/grouptools-success-modal.component';
import { LoaderInterceptor } from './authentication/loading-interceptor.service';
import { IframeLoginComponent } from './iframe-login/iframe-login.component';
import { LoginModule } from './pages/login/login.module';
import { RegisterModule } from './pages/register-page/register-page.module';
import { FacebookService } from './pages/facebook-login/facebook.service';
import { AmazonService } from './pages/amazon-login/amazon.service';
import { TitleCasePipe } from '@angular/common';
import { PasswordResetService } from './pages/password-reset/password-reset.service';
import { PasswordResetModule } from './pages/password-reset/password-reset.module';
import { GtLoaderConfigService } from './pages/helpers/gt-loader-config.service';
import { GestureConfig, MatNativeDateModule } from '@angular/material';
import { PackageDetailsService } from './pages/package-details/package-details.service';
import { DialogsModule } from './dialogs/dialogs.module';
import { ConfigurationEffects } from './store/effects/configuration.effect';
import { ConfigurationService } from './util/configuration.service';
import { PayPalHelper } from './pages/helpers/paypal-helper';
// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
  AuthService,
  GettingStartedService,
  ContactService,
  AttendeePackageDetailService,
  URLConfig,
  CurrencyHelper,
  PayPalHelper,
  GtLoaderConfigService,
  UpdateAttendeeStatusComponent,
  ConfigurationService,
  { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
  { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
];

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [App],
    declarations: [
      App,
      IframeLoginComponent
    ],
    imports: [ // import Angular's modules
      BrowserModule,
      BrowserAnimationsModule,
      NgxConfigureModule.forRoot(),
      HttpClientModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      ModalModule.forRoot(),
      TabsModule.forRoot(),
      PopoverModule.forRoot(),
      BsDropdownModule.forRoot(),
      NgbModule.forRoot(),
      NotSupportedModule,
      GTNgaModule,
      StoreModule.forRoot(reducers, {
        initialState: {
          user: fromUser.initialState,
          attendeePackages: fromAttendeePackages.initialState,
          event: fromEvent.initialState,
        },
        metaReducers: metaReducers
      }),
      StoreDevtoolsModule.instrument({
        name: 'GroupTools', // This name appears in Redux DevTools extension
        maxAge: 100, // Retains last 25 states
        // logOnly: environment.production // Restrict extension to log-only mode
      }),
      EffectsModule.forRoot([
        ActionsEffects,
        EventEffects,
        FormEffects,
        UserEffects,
        ActivityEffects,
        ContactsEffects,
        AttendeesEffects,
        PaymentEffects,
        SurveysEffects,
        MessageEffects,
        GledEventItemEffects,
        EventItemEffects,
        PackageDetailsEffects,
        ConfigurationEffects,
      ]),
      LoginModule,
      RegisterModule,
      WidgetsModule,
      ModalsModule,
      DialogsModule,
      PasswordResetModule,
      MatNativeDateModule,
      routing,
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
      {
        provide: NgxConfigureOptions,
        useClass: GroupToolsConfigureOptions
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AppInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true
      },
      { // This is to fix hammerjs (used by material) not available to lazy-loaded modules: https://stackoverflow.com/a/56633021/1677693
        provide: HAMMER_GESTURE_CONFIG, 
        useClass: GestureConfig 
      },
      ClientAuthConfig,
      AppStateService,
      UserService,
      APP_PROVIDERS,
      NbgDatePickerUtility,
      NotIEGuard,
      ActionsService,
      EventDetailsService,
      GroupTixService,
      PaymentsService,
      SurveysService,
      MessagesService,
      NgbActiveModal,
      GroupToolsNewModalService,
      AmazonService,
      FacebookService,
      GroupToolsSize,
      PasswordResetService,
      TitleCasePipe,
      PackageDetailsService,
    ],
})

export class AppModule {

  constructor(public appRef: ApplicationRef, public appState: AppState) { }

}
