import { AfterViewInit, ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
var GoogleLoginComponent = /** @class */ (function () {
    function GoogleLoginComponent(renderer, configService) {
        this.renderer = renderer;
        this.configService = configService;
        this.GOOGLEICON = require("app/grouptools-theme/google_icon.svg");
        this.googleButtonImage = require("assets/buttons/btn-google.png");
        this.scope = [
            'profile',
            'email',
            'https://www.googleapis.com/auth/plus.me'
        ].join(' ');
        this.loginComplete = new EventEmitter();
        this.initListData = new EventEmitter();
        this.clientId = this.configService.config.googleClientId;
    }
    GoogleLoginComponent.prototype.googleInit = function () {
        var _this = this;
        try {
            gapi.load('auth2', function () {
                _this.auth2 = gapi.auth2.init({
                    client_id: _this.clientId,
                    cookiepolicy: 'single_host_origin',
                    scope: _this.scope
                });
                _this.attachSignin(_this.button.nativeElement);
            });
        }
        catch (e) {
            //console.error(e);
        }
    };
    GoogleLoginComponent.prototype.attachSignin = function (element) {
        var _this = this;
        this.auth2.attachClickHandler(element, { prompt: 'select_account' }, function (googleUser) {
            _this.loginComplete.emit(googleUser);
        }, function (error) {
            //console.error(JSON.stringify(error, undefined, 2));
        });
    };
    GoogleLoginComponent.prototype.ngAfterViewInit = function () {
        this.googleInit();
    };
    return GoogleLoginComponent;
}());
export { GoogleLoginComponent };
