import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CreateFromBibOrderDialogComponent, CreateFromBibOrderDialogData, CreateFromBibOrderDialogResult } from './create-from-bib-order-dialog/create-from-bib-order-dialog.component';
import { CreateFromSupplierOrderDialogComponent, CreateFromSupplierOrderDialogResult, CreateFromSupplierOrderDialogData } from './create-from-supplier-order-dialog/create-from-supplier-order-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private bibOrderNumberLoginDialogRef: MatDialogRef<CreateFromBibOrderDialogComponent, CreateFromBibOrderDialogResult> = null;
  private supplierOrderNumberDialogRef: MatDialogRef<CreateFromSupplierOrderDialogComponent, CreateFromSupplierOrderDialogResult> = null;

  constructor(
    private dialog: MatDialog,
  ) { }

  showBibOrderNumberLoginDialog() {
    this.bibOrderNumberLoginDialogRef = this.dialog.open(CreateFromBibOrderDialogComponent, {
      width: '400px',
      data: {} as CreateFromBibOrderDialogData,
    });
  }

  showSupplierOrderNumberDialog(SupplierOrderNumber: string) {
    this.supplierOrderNumberDialogRef = this.dialog.open(CreateFromSupplierOrderDialogComponent, {
      width: '400px',
      data: { SupplierOrderNumber: SupplierOrderNumber } as CreateFromSupplierOrderDialogData,
    });
  }

  closeBibOrderNumberLoginDialog() {
    if (this.bibOrderNumberLoginDialogRef == null) return;
    this.bibOrderNumberLoginDialogRef.close();
    this.bibOrderNumberLoginDialogRef = null;
  }

  closeSupplierOrderNumberLoginDialog() {
    if (this.supplierOrderNumberDialogRef == null) return;
    this.supplierOrderNumberDialogRef.close();
    this.supplierOrderNumberDialogRef = null;
  }

}
