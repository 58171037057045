import { Action } from "@ngrx/store";
import { Message } from "../models/message";
import { UserMessageDTO } from "../models/packages/UserMessageDTO";

export enum ActionTypes {

    PDEffectsLoadEventMessages = "[Package Details Effects] Load Event Messages",
    MessageEffectsLoadEventMessagesSuccess = "[Message Effects] Load Event Messages Success",
    MessageEffectsLoadEventMessagesFailure = "[Message Effects] Load Event Messages Failure",

    PDEffectsLoadEventSentMessages = "[Package Details Effects] Load Event Sent Messages",
    MessageEffectsLoadEventSentMessagesSuccess = "[Message Effects] Load Event Sent Messages Success",
    MessageEffectsLoadEventSentMessagesFailure = "[Message Effects] Load Event Sent Messages Failure",

    LoadEventMessagesNew = "[GLED] Load Event Message New",
    LoadEventMessagesNewSuccess = "[Message Effect] Load Event Messages New Success",
    LoadEventMessagesNewFailure = "[Message Effect] Load Event Messages New Failure",

    MessagesListComponentDeleteMessage = "[Messages List Component] Delete Message",
    MessageEffectsDeleteMessageSuccess = "[Message Effects] Delete Message Success",
    MessageEffectsDeleteMessageFailure = "[Message Effects] Delete Message Failure",

    MessagesListComponentMarkMessageAsRead = "[Messages List Component] Mark Message As Read",
    MessageEffectsMarkMessageAsReadSuccess = "[Message Effects] Mark Message As Read Success",
    MessageEffectsMarkMessageAsReadFailure = "[Message Effects] Mark Message As Read Failure",

    AuthenticationServiceLogout = "[Authentication Service] Logout",
}

//----------------------------------------------------------------
export class PDEffectsLoadEventMessages implements Action {
    readonly type = ActionTypes.PDEffectsLoadEventMessages;
    constructor(public payload: {eventId: number | null}) {}
}
export class MessageEffectsLoadEventMessagesSuccess implements Action {
    readonly type = ActionTypes.MessageEffectsLoadEventMessagesSuccess;
    constructor(public payload: {eventMessages: UserMessageDTO[]}) {}
}
export class MessageEffectsLoadEventMessagesFailure implements Action {
    readonly type = ActionTypes.MessageEffectsLoadEventMessagesFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class PDEffectsLoadEventSentMessages implements Action {
    readonly type = ActionTypes.PDEffectsLoadEventSentMessages;
    constructor(public payload: {eventId: number | null}) {}
}
export class MessageEffectsLoadEventSentMessagesSuccess implements Action {
    readonly type = ActionTypes.MessageEffectsLoadEventSentMessagesSuccess;
    constructor(public payload: {eventSentMessages: UserMessageDTO[]}) {}
}
export class MessageEffectsLoadEventSentMessagesFailure implements Action {
    readonly type = ActionTypes.MessageEffectsLoadEventSentMessagesFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class LoadEventMessagesNew implements Action {
    readonly type = ActionTypes.LoadEventMessagesNew;
    constructor(public payload: {eventId: number}) {}
}
export class LoadEventMessagesNewSuccess implements Action {
    readonly type = ActionTypes.LoadEventMessagesNewSuccess;
    constructor(public payload: {eventMessagesNew: Message[]}) {}
}
export class LoadEventMessagesNewFailure implements Action {
    readonly type = ActionTypes.LoadEventMessagesNewFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class MessagesListComponentDeleteMessage implements Action {
    readonly type = ActionTypes.MessagesListComponentDeleteMessage;
    constructor(public payload: {message: UserMessageDTO}) {}
}
export class MessageEffectsDeleteMessageSuccess implements Action {
    readonly type = ActionTypes.MessageEffectsDeleteMessageSuccess;
    constructor(public payload: {idUserMessage: number}) {}
}
export class MessageEffectsDeleteMessageFailure implements Action {
    readonly type = ActionTypes.MessageEffectsDeleteMessageFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class MessagesListComponentMarkMessageAsRead implements Action {
    readonly type = ActionTypes.MessagesListComponentMarkMessageAsRead;
    constructor(public payload: {message: UserMessageDTO}) {}
}
export class MessageEffectsMarkMessageAsReadSuccess implements Action {
    readonly type = ActionTypes.MessageEffectsMarkMessageAsReadSuccess;
    constructor(public payload: {message: UserMessageDTO}) {}
}
export class MessageEffectsMarkMessageAsReadFailure implements Action {
    readonly type = ActionTypes.MessageEffectsMarkMessageAsReadFailure;
    constructor(public payload: {errorMessage: string}) {}
}
//----------------------------------------------------------------
export class AuthenticationServiceLogout implements Action {
    readonly type = ActionTypes.AuthenticationServiceLogout;
    constructor() {}
}
//----------------------------------------------------------------

export type Actions
    = PDEffectsLoadEventMessages
    | MessageEffectsLoadEventMessagesSuccess
    | MessageEffectsLoadEventMessagesFailure
    
    | PDEffectsLoadEventSentMessages
    | MessageEffectsLoadEventSentMessagesSuccess
    | MessageEffectsLoadEventSentMessagesFailure

    | LoadEventMessagesNew
    | LoadEventMessagesNewSuccess
    | LoadEventMessagesNewFailure

    | MessagesListComponentDeleteMessage
    | MessageEffectsDeleteMessageSuccess
    | MessageEffectsDeleteMessageFailure

    | MessagesListComponentMarkMessageAsRead
    | MessageEffectsMarkMessageAsReadSuccess
    | MessageEffectsMarkMessageAsReadFailure

    | AuthenticationServiceLogout
;
