import { Injectable } from '@angular/core';
import { NgxConfigureOptions, NgxConfigureService } from 'ngx-configure';

export class GroupToolsConfigureOptions extends NgxConfigureOptions {
    ConfigurationURL: string = 'assets/config/config.json';
    AppVersion: string = '0.0.0';
    BustCache: boolean = false;
}

export interface GTConfig {
    production: string;
    apiUrl: string;
    wepayUri: string;
    wepayRoot: string;
    stripeClientId: string;
    stripeKey: string;
    stripeRedirectUri: string;
    clientId: string;
    clientSecret: string;
    paypalClientId: string;
    paypalPartnerAttributionId: string;
    paypalPercentFeeUS: number;
    paypalFlatFeeUS: number;
    paypalPercentFeeUK: number;
    paypalFlatFeeUK: number;
    paypalPercentFeeEU: number;
    paypalFlatFeeEU: number;
    paypalFirstPartyFlatFee: number,
    amazonAppId: string;
    facebookAppId: string;
    facebookPermissions: string;
    facebookFields: string;
    googleAnalyticsId: string;
    googleClientId: string;
    googleApiKey: string;
    reCaptchaSiteKey: string;
    authVerifyToken: string;
    authPersonApiKey: string;
    // These are from GroupTix, they might not be used in GT
    maxNumOfTicketsToDownloadAtOnce: number;
    numberOfDownloadRetryRequests: number;
    millisecondsToWaitBetweenRetryRequests: number;
    ticketDeliveryWindowAvailabilityMessage: string;
    fullNameMaxLength: number;
    emailPhoneMaxLength: number;
    enableSupplierEventUpdates: string;
    eventCreationSupplierTypes: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    // This class serves as a simple wrapper around NgxConfigureService
    // to all type-safe config access :-) If configOptions property is needed,
    // it can be added here as well.  This class should be used instead of NgxConfigureService
    // public config: GTConfig;
    constructor(private ngxConfigService: NgxConfigureService) {}

    get config(): GTConfig {
        return this.ngxConfigService.config as GTConfig;
    }
}

// TODO: Replace NgxConfigureService uses with ConfigService
