import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild, Input, Renderer2 } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
declare const gapi: any;

@Component({
  selector: 'google-login',
  templateUrl: './google-login.component.html'
})
export class GoogleLoginComponent implements AfterViewInit {
    public GOOGLEICON = require(`app/grouptools-theme/google_icon.svg`);
    public googleButtonImage = require(`assets/buttons/btn-google.png`);
    public googleUser;

    private clientId: string;
  
    private scope = [
        'profile',
        'email',
        'https://www.googleapis.com/auth/plus.me'
    ].join(' ');

    public auth2: any;

    @Output() loginComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() initListData: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('button') button: ElementRef;

    constructor(
        private renderer: Renderer2,
        private configService: NgxConfigureService
    ) {
        this.clientId  = this.configService.config.googleClientId;
    }

    public googleInit() {
        try {
            gapi.load('auth2', () => {
                this.auth2 = gapi.auth2.init({
                    client_id: this.clientId,
                    cookiepolicy: 'single_host_origin',
                    scope: this.scope
                });

                this.attachSignin(this.button.nativeElement);
            });
        } catch(e) {
            //console.error(e);
        }
    }

    public attachSignin(element) {
        this.auth2.attachClickHandler(element, { prompt: 'select_account' }, googleUser => {
            this.loginComplete.emit(googleUser);
        }, error => {
            //console.error(JSON.stringify(error, undefined, 2));
        });
    }

    ngAfterViewInit() {
        this.googleInit();
    }

}