import { GlobalState } from '../../../global.state';
var BaContentTop = /** @class */ (function () {
    function BaContentTop(_state) {
        var _this = this;
        this._state = _state;
        this.activePageTitle = '';
        this._state.subscribe('menu.activeLink', function (activeLink) {
            if (activeLink) {
                _this.activePageTitle = activeLink.title;
            }
        });
    }
    return BaContentTop;
}());
export { BaContentTop };
