/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gled-add-activity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./gled-add-activity.component";
import * as i3 from "@ngrx/store";
var styles_GledAddActivityComponent = [i0.styles];
var RenderType_GledAddActivityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GledAddActivityComponent, data: {} });
export { RenderType_GledAddActivityComponent as RenderType_GledAddActivityComponent };
export function View_GledAddActivityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "gled-add-activity"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Which type of activity would you like to add?"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  \n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddActivityModal(_co.enumGledAddActivityState.Show) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Show"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddActivityModal(_co.enumGledAddActivityState.Restaurant) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Restaurant"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddActivityModal(_co.enumGledAddActivityState.Lodging) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Lodging"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddActivityModal(_co.enumGledAddActivityState.Transportation) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Transportation"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddActivityModal(_co.enumGledAddActivityState.Other) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Custom Activity"])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], null, null); }
export function View_GledAddActivityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gled-add-activity", [], null, null, null, View_GledAddActivityComponent_0, RenderType_GledAddActivityComponent)), i1.ɵdid(1, 114688, null, 0, i2.GledAddActivityComponent, [i3.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GledAddActivityComponentNgFactory = i1.ɵccf("gled-add-activity", i2.GledAddActivityComponent, View_GledAddActivityComponent_Host_0, {}, {}, []);
export { GledAddActivityComponentNgFactory as GledAddActivityComponentNgFactory };
