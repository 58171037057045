import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { URLConfig } from '../helpers/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
import { ListOptions } from 'app/widgets/grouptools-list/grouptools-list.component';
var PaymentsService = /** @class */ (function () {
    function PaymentsService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
        this.attendeePaymentClOptions = {
            hideDate: true,
            multiSelectMode: false,
            hideMenu: false,
            columns: [
                {
                    fieldName: 'PaymentDate',
                    label: 'Payment Date',
                    type: 'date',
                    pipe: new DatePipe('en-US'),
                    action: 'Distribute'
                },
                {
                    fieldName: 'PaymentType',
                    label: 'Type',
                    mobile: { showInMobile: false },
                    action: 'Distribute'
                },
                {
                    fieldName: 'PayerName',
                    label: 'Attendee',
                    mobile: { showInMobile: true, title: 'Attendee' },
                    action: 'Distribute'
                },
                {
                    fieldName: 'Amount',
                    label: 'Amount',
                    type: 'currency',
                    action: 'Distribute'
                },
                {
                    fieldName: 'Status',
                    label: 'Status',
                    mobile: { showInMobile: false },
                    action: 'Distribute'
                }
            ],
            singleActions: ['Distribute'],
            headerActions: ['Record Attendee Payment'],
            defaultMessage: { message: 'No payments have been received.' }
        };
        this.supplierPaymentClOptions = {
            hideDate: true,
            multiSelectMode: false,
            hideMenu: false,
            columns: [
                {
                    fieldName: 'PaymentDate',
                    label: 'Payment Date',
                    type: 'date',
                    pipe: new DatePipe('en-US'),
                    action: 'Distribute'
                },
                {
                    fieldName: 'PaymentType',
                    label: 'Type',
                    mobile: { showInMobile: false },
                    action: 'Distribute'
                },
                {
                    fieldName: 'PayeeName',
                    label: 'Supplier',
                    action: 'Distribute'
                },
                {
                    fieldName: 'Amount',
                    label: 'Amount',
                    type: 'currency',
                    action: 'Distribute'
                },
                {
                    fieldName: 'Status',
                    label: 'Status',
                    mobile: { showInMobile: false },
                    action: 'Distribute'
                }
            ],
            singleActions: ['Distribute'],
            headerActions: ['Record Supplier Payment'],
            defaultMessage: { message: 'No supplier payments have been recorded.' }
        };
        this.paymentRemindersModal$ = new Subject();
    }
    //==================================================================================
    // KC: 2020/05/22: The new pattern is in this section
    PaymentsService.prototype.getPaymentsNew = function (eventId) {
        return this.http.get(this.URLs._paymentsGetUrl(eventId)).pipe(handleGTResponse());
    };
    PaymentsService.prototype.makeSupplierPayment = function (makePaymentRequest) {
        return this.http.post(this.URLs._supplierMakePaymentUrl(), makePaymentRequest).pipe(handleGTResponse());
    };
    PaymentsService.prototype.addAndAssociateCustomer = function (addAndAssociateCustomerRequest) {
        return this.http.post(this.URLs._addAndAssociateCustomerUrl(), addAndAssociateCustomerRequest).pipe(handleGTResponse());
    };
    PaymentsService.prototype.makeSupplierGLPayment = function (makePaymentRequest) {
        return this.http.post(this.URLs._supplierMakeGLPaymentUrl(), makePaymentRequest).pipe(handleGTResponse());
    };
    //==================================================================================
    PaymentsService.prototype.getPayments = function (packageId) {
        var token = '';
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.get(this.URLs._paymentsGetUrlOld(packageId), { headers: headers }).pipe(handleGTResponse());
    };
    PaymentsService.prototype.getPaymentTypes = function () {
        var token = '';
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.get(this.URLs._paymentGetTypes(), { headers: headers }).pipe(map(function (res) { return res.Obj; }));
    };
    PaymentsService.prototype.refundPayment = function (payment) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        return this.http.post(this.URLs._paymentRefund(), payment, { headers: headers });
    };
    PaymentsService.prototype.saveAttendeePayment = function (paymentObject) {
        return this.http.post(this.URLs._insertAttendeePayment(), paymentObject).pipe(map(function (res) {
            //console.log(res);
            return res;
        }));
    };
    PaymentsService.prototype.saveSupplierPayment = function (paymentObject) {
        return this.http.post(this.URLs._insertSupplierPayment(), paymentObject).pipe(map(function (res) {
            //console.log(res);
            return res;
        }));
    };
    PaymentsService.prototype.getAttendees = function (packageId) {
        return this.http.get(this.URLs._getContactsForPackage() + packageId);
    };
    PaymentsService.prototype.getSuppliersForPackage = function (packageId) {
        return this.http.get(this.URLs._getSuppliersForPackage() + packageId).pipe(handleGTResponse());
    };
    PaymentsService.prototype.getDistribution = function (paymentId) {
        return this.http.get(this.URLs._paymentDistributionGetUrl() + paymentId);
    };
    PaymentsService.prototype.getDistributionsForPackage = function (packageId) {
        return this.http.get(this.URLs._paymentDistributionGetByPackage(packageId)).pipe(map(function (res) { return res.Obj; }));
    };
    PaymentsService.prototype.insertDistributions = function (distributions) {
        return this.http.post(this.URLs._paymentDistributionInsertMany(), distributions);
    };
    PaymentsService.prototype.autoDistribute = function (payment) {
        return this.http.post(this.URLs._paymentDistributionAutoUrl(), payment);
    };
    PaymentsService.prototype.SetAlternatePaymentMethod = function (paymentMethod) {
        return this.http.post(this.URLs._paymentSetAlternatePaymentMethod(), paymentMethod);
    };
    PaymentsService.prototype.showSendPaymentReminders = function () {
        this.paymentRemindersModal$.next(true);
    };
    return PaymentsService;
}());
export { PaymentsService };
