import * as ConfigurationActions from "../actions/configuration.action";
import { cloneDeep as _cloneDeep } from "lodash";
import { ConfigurationState } from '../models/configuration.state';

export type State = ConfigurationState;

export const initialState: State = {
    venueConfiguration: {
        allowPartialPayments: false,
        daysBeforeEventTicketsAreAvailable: 14,
        barcodeFormat: "",
        maxTicketQuantity: 5,
        requireNames: true,
        defaultEventImage: "",
        defaultActivityImage: "",
        ticketDistributionPreference: "",
        allowHybridPayments: false,
        allowPriceChange: false,
        additionalPayPalFee: 0.00,
        enablePayToSupplier: false,
        enableSeatAssignment: false,
    }
}

export function reducer(state: State = initialState, action: ConfigurationActions.Actions): State {
    switch (action.type) {
        case ConfigurationActions.ActionTypes.GeneralLoadConfiguration:
        {
            return {
                ...state,
            };
        }
        case ConfigurationActions.ActionTypes.ConfigurationEffectLoadConfigurationSuccess:
        {
            return {
                ...state,   // TODO: As more configuration gets added, they will need to be updated here
                venueConfiguration: action.payload.configurationState.venueConfiguration,
            };
        }
        case ConfigurationActions.ActionTypes.ConfigurationEffectLoadConfigurationFailure:
        {
            return {
                ...state,
                // TODO: Handle error on updating config state
            };
        }
        //---------------------------------------------------------------------        
        default:
        {
            return state;
        }
    }
}
