// Angular Imports
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';

// This Module's Components
import { ContactDataFilter, GrouptoolsListComponent, OrderBy } from './grouptools-list/grouptools-list.component';
import { GrouptoolsModalComponent } from './grouptools-modal/grouptools-modal.component';
import { GroupToolsNewModalComponent } from './grouptools-modal/grouptools-new-modal.component';
import { GrouptoolsPlaceholderComponent } from './grouptools-placeholder/grouptools-placeholder.component';
import { GrouptoolsSuccessComponent } from './grouptools-success/grouptools-success.component';
import { DataTable } from 'app/widgets/grouptools-list/components/grouptools-list-table.directive';
import { Paginator, BootstrapPaginator } from 'app/widgets/grouptools-list/components/grouptools-list-paginator.component';
import { GrouptoolsFeatureListComponent } from 'app/widgets/grouptools-feature-list/grouptools-feature-list.component';
import { FeatureWrapperComponent } from 'app/widgets/grouptools-feature-list/feature-wrapper/feature-wrapper.component';
import { GrouptoolsFeatureComponent } from 'app/widgets/grouptools-feature-list/grouptools-feature/grouptools-feature.component';
import { FeatureSearchComponent } from 'app/widgets/grouptools-feature-list/feature-search/feature-search.component';
import { GrouptoolsListHeadComponent } from './grouptools-list/components/grouptools-list-head/grouptools-list-head.component';
import { RlTagInputModule } from 'app/pages/package-details/interactions/tags/tag-input.module';
import {
    GrouptoolsListBodyComponent,
    GtCellVisible
} from './grouptools-list/components/grouptools-list-body/grouptools-list-body.component';
import { BsDropdownModule, PaginationModule, PopoverModule  } from 'ngx-bootstrap';
import { EventDetailTable } from 'app/widgets/event-detail-table/event-detail-table.component';
import { GrouptoolsLabelComponent } from 'app/widgets/grouptools-label/grouptools-label.component';
import { GrouptoolsScreenSizeWidget } from 'app/widgets/grouptools-screen-size/grouptools-screen-size.component';
import { GrouptoolsEditorComponent } from './grouptools-editor/grouptools-editor.component';
import { GrouptoolsCropperComponent } from 'app/widgets/grouptools-cropper/grouptools-cropper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PublicUrlComponent } from 'app/widgets/event-detail-table/public-url/public-url.component';
import { TextCopyDirective } from 'app/widgets/event-detail-table/public-url/text-copy.directive';
import { ActivityTypeTabBarComponent } from './activity-type-tab-bar/activity-type-tab-bar.component';
import { EventCreationBannerComponent } from './event-creation-banner/event-creation-banner.component';
import { GrouptoolsPhoneDirective } from './grouptools-phone/grouptools-phone.directive';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        GTNgaModule,
        ModalModule.forRoot(),
        BsDropdownModule,
        PaginationModule.forRoot(),
        PopoverModule,
        NgbModule,
        RlTagInputModule
    ],
    declarations: [
        GrouptoolsListComponent,
        ContactDataFilter, DataTable, GtCellVisible,
        Paginator,
        BootstrapPaginator,
        GrouptoolsListBodyComponent, GrouptoolsListHeadComponent,
        GrouptoolsModalComponent,
        GroupToolsNewModalComponent,
        GrouptoolsPlaceholderComponent,
        GrouptoolsSuccessComponent,
        GrouptoolsFeatureListComponent,
        FeatureWrapperComponent,
        GrouptoolsFeatureComponent,
        FeatureSearchComponent,
        OrderBy,
        EventDetailTable,
        GrouptoolsLabelComponent,
        GrouptoolsScreenSizeWidget,
        GrouptoolsEditorComponent,
        GrouptoolsCropperComponent,
        PublicUrlComponent,
        TextCopyDirective,
        ActivityTypeTabBarComponent,
        EventCreationBannerComponent,
        GrouptoolsPhoneDirective,
    ],
    exports: [
        GrouptoolsListComponent, DataTable,
        Paginator,
        BootstrapPaginator,
        GrouptoolsModalComponent,
        GroupToolsNewModalComponent,
        GrouptoolsPlaceholderComponent,
        GrouptoolsSuccessComponent,
        GrouptoolsFeatureListComponent,
        EventDetailTable,
        GrouptoolsLabelComponent,
        GrouptoolsScreenSizeWidget,
        GrouptoolsEditorComponent,
        GrouptoolsCropperComponent,
        PublicUrlComponent,
        ActivityTypeTabBarComponent,
        EventCreationBannerComponent,
        GrouptoolsPhoneDirective
    ]
})
export class WidgetsModule {

}
