<div *ngIf="!omitTitle" class="login-title">
    <p>Let's get started</p>
</div>
<hr *ngIf="!omitTitle" class="login-hr">

<div class="auth-main register-component">
  <div style = "width:100%" class="auth-block row ">
      
    <p class="register-new animate fadeInRight">I'm new. <span id="register-create-account" class="create-account">I'd like to register<i class="fa fa-chevron-down" aria-hidden="true"></i></span></p>
    
    <div class="animate fadeIn two"> 
      <p class="provide">Please provide some basic information below.</p>
      
      <div class="col-xs-12" >
        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form-horizontal col-md-12 form-bottom less-padding">
          
          <!--FIRSTNAME  -->
          <div class="form-group row">
            <div class="col-xs-12 less-padding" [ngClass]="{'has-error': formErrors.firstname, 'has-success': (!formErrors.firstname && firstname.valid)}">
              <input #inputFirstName (input)="inputFirstName.value=titlecasePipe.transform($event.target.value)" [formControl]="firstname" type="text" class="form-control" id="firstName" placeholder="First Name">
              <div *ngIf="formErrors.firstname" class="error-block">
                <p class="error-text">{{ formErrors.firstname }}</p>
              </div>
            </div>
          </div>

          <!--LASTNAME  -->
          <div class="form-group row">
            <div class="col-xs-12 less-padding" [ngClass]="{'has-error': formErrors.lastname, 'has-success': (!formErrors.lastname && lastname.valid)}">
              <input #inputLastName (input)="inputLastName.value=titlecasePipe.transform($event.target.value)" [formControl]="lastname" type="text" class="form-control" id="lastName" placeholder="Last Name">
              <div *ngIf="formErrors.lastname" class="error-block">
                <p class="error-text">{{ formErrors.lastname }}</p>
              </div>
            </div>
          </div>

          <!--EMAIL  -->
          <div class="form-group row">
            <div class="col-xs-12 less-padding" [ngClass]="{'has-error': formErrors.email && (email.dirty || formSubmitted) , 'has-success': !formErrors.email && email.valid }">
              <input id="inputEmail3" [formControl]="email" type="email" class="form-control" placeholder="Email">
              <div *ngIf="formErrors.email" class="error-block">
                <p class="error-text">{{ formErrors.email }}</p>
              </div>
            </div>
          </div>

          <!--PHONE  -->
          <div class="form-group row">
            <div class="col-xs-12 less-padding" [ngClass]="{'has-error': formErrors.phonevalue && (phonevalue.dirty || formSubmitted) , 'has-success': !formErrors.phonevalue && phonevalue.valid}">
                <input groupToolsPhone id="inputPhone" [formControl]="phonevalue" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" class="form-control" placeholder="Phone (Optional) Format: xxx-xxx-xxxx">
                <div *ngIf="formErrors.phonevalue" class="error-block">
                    <p class="error-text">{{ formErrors.phonevalue }}</p>
                </div>
            </div>
          </div>

          <!--REPEAT EMAIL  
          <div class="form-group row">
            <div class="col-xs-12" [ngClass]="{'has-error': formErrors.emails && (repeatEmail.dirty || formSubmitted) , 'has-success': !formErrors.emails && repeatEmail.valid }">
              <input [formControl]="repeatEmail" type="email" class="form-control" id="inputEmail3" placeholder="Email">
              <div *ngIf="formErrors.emails" class="error-block">
                <p class="error-text">{{ formErrors.emails }}</p>
              </div>
            </div>
          </div>-->

          <!--PASSWORD  -->
          <div class="form-group row" [ngClass]="{'has-error': formErrors.password && (password.dirty || formSubmitted) , 'has-success': !formErrors.password && password.valid}">
            <div class="col-xs-12 less-padding">
              <input [formControl]="password" type="password" class="form-control" class="form-control" id="inputPassword3" placeholder="Password">
              <div *ngIf="formErrors.password" class="error-block">
                <p class="error-text">{{ formErrors.password }}</p>
              </div>
            </div>
          </div>

          <!--REPEAT PASSWORD  -->
          <div class="form-group row" [ngClass]="{'has-error': formErrors.passwords && (repeatPassword.dirty || formSubmitted) , 'has-success': !formErrors.passwords && passwords.valid}">
            <div class="col-xs-12 less-padding">
              <input [formControl]="repeatPassword" type="password" class="form-control" class="form-control" id="inputPassword4" placeholder="Confirm Password">
              <div *ngIf="formErrors.passwords" class="error-block">
                <p class="error-text">{{ formErrors.passwords }}</p>
              </div>
            </div>
          </div>

          <!--PASSWORD STRENGTH METER -->
          <div class="form-group row" *ngIf="password.value.length > 0">
            <div class="col-xs-12 less-padding psw-strength-block">
              <span>Password Strength: </span>
              <span class="strong-label" *ngIf="!formErrors.password && (password.value.length >= 12 || includeSpecialChar())">
                Strong
                <div class="strong-block"></div>
              </span>
              <span class="moderate-label" *ngIf="!formErrors.password && (password.value.length < 12 && !includeSpecialChar())">
                Moderate
                <div class="moderate-block"></div>
              </span>
              <span class="weak-label" *ngIf="formErrors.password">
                Weak
                <div class="weak-block"></div>
              </span>
              
            </div>
          </div>

          <!--TOS Check  -->
          <div class="form-group row" [ngClass]="{'has-error': formErrors.tosBox && (tosBox.dirty || formSubmitted) , 'has-success': !formErrors.tosBox && tosBox.valid}">
            <div class="col-xs-12 less-padding">
              <div>
                <label class="form-check-label">
                  <input [formControl]="tosBox" type="checkbox" class="form-check-input" id="tosBox">
                  I agree to the <a href="https://grouptools.com/terms-of-service" target="_blank" class="tos-link">Terms of Service</a> 
                </label>
              </div>
              <div *ngIf="formErrors.tosBox" class="error-block">
                <p class="error-text">{{ formErrors.tosBox }}</p>
              </div>
            </div>
          </div>

          <!--SUBMIT BUTTON  -->
          <div class="form-group row">
            <div class="col-xs-12 less-padding">
              <button style="color:white;" type="submit" class="btn btn-primary btn-auth form-control create-btn">Create Account</button>
            </div>
          </div>

          <login-with-social 
            (emailPrefill)="emailPrefill"
            (inModal)="inModal"
            (differentAccount)="differentAccount.emit($event)"
            (googleRegisterEmit)="googleRegisterEmit.emit($event)"
            (amazonRegisterEmit)="amazonRegisterEmit.emit($event)"
            (facebookRegisterEmit)="facebookRegisterEmit.emit($event)"
            (handlePostLogin)="handlePostLogin($event)"
            (openDifferentAccountModal)="openDifferentAccountModal()"
            (openGoogleRegisterModal)="openGoogleRegisterModal($event)"
            (openAmazonRegisterModal)="openAmazonRegisterModal($event)"
            (openFacebookRegisterModal)="openFacebookRegisterModal($event)">
          </login-with-social>
        </form>
 
          <!--<div class="auth-sep"><span><span>or Sign up with one click</span></span></div>-->
          <!--TODO:
        Setup Facebook one click form filling.-->
      
          <!--<div class="al-share-auth">
          <ul class="al-share clearfix">
            <li><i class="socicon socicon-facebook" title="Share on Facebook"></i></li>
            <li><i class="socicon socicon-twitter" title="Share on Twitter"></i></li>
            <li><i class="socicon socicon-google" title="Share on Google Plus"></i></li>
          </ul>
        </div>-->
      </div>

      <div class="auth-link row">
        <span class="account-question">Already have a GroupTools account?<span class="clickable nav-to-login" (click)="routeToLogin()">Sign in!</span></span>
      </div>
    </div>
  </div>                
</div>

<!-- Registered modal -->
<grouptools-modal #staticModal
  [static]="true"
  [title]="title">

  <account-verification #accountVerification modal-body [loginName]="loginName" [loginEmail]="loginEmail" [registerInModal]="false" 
    (skipVerification)="skipVerification()" (onlyLogin)="onlyLogin()" (changeModalTitle)="changeModalTitle()"></account-verification>

</grouptools-modal>

<!-- Registered user exists modal -->
<grouptools-modal #existsModal [static]="true" [title]="'User Already Registered'">
  <div modal-body>
    <h1 class=" gt-error-icon"><i class="fa fa-times-circle-o"></i></h1>
    <h3>{{loginName}}, it looks like you already have an account. </h3>
    <small>Please Log in instead.</small>
    <input [(ngModel)]="existsPassword" type="password" (keyup.enter)="existsLogin()" placeholder="Password">
    <div>
      <button style="margin-top:2rem;" class="btn btn-primary btn-block" (click)="existsLogin()">Log In to GroupTools</button>                
      <button style="margin-top:1rem;" class="btn btn-secondary btn-block" (click)="existsModal.hideModal()">Cancel</button>
    </div>
    <div style="margin-top:1rem;" class="sub-links">
      <a class="clickable" href="/login" >Log In with a Different Account</a>
      <a class="clickable" (click)="resetPassword();" style="float:right;">Reset Password</a>
    </div>
  </div>
</grouptools-modal>

<!-- MODAL -->
<grouptools-modal #modal 
[title]="modalTitle"
(onHidden)="destroyContents()">
<div modal-body [ngSwitch]="modalState">

    <grouptools-success *ngSwitchCase="'differentAccount'"
        successHeading="Warning"
        type="pause">
        <div class="login-center">
            <span class="row">
                You are about to login to GroupTools with a different email address. 
                If you continue, you will not have access to the events associated with 
                <b>{{emailPrefill.value}}</b>
            </span>
        </div>
        <div class="col-lg-12">
            <button class="btn btn-primary login-btn" type="button" (click)="modal.grouptoolsModal.hide()">Continue</button>
        </div>
        <div class="col-lg-12">
            <button class="btn btn-secondary login-btn" type="button" (click)="cancelLogin()">Cancel</button>
        </div>
    </grouptools-success>

    <google-register *ngSwitchCase="'googleRegister'" 
        [googleUser]="googleUser">
    </google-register>

    <amazon-register *ngSwitchCase="'amazonRegister'" 
        [user]="amazonUser">
    </amazon-register>

    <facebook-register *ngSwitchCase="'facebookRegister'"
        [user]="facebookUser">
    </facebook-register>
</div>
</grouptools-modal>


