import * as event from '../actions/event';

export interface State {
  selectedEventId: number;
};

export const initialState: State = {
  selectedEventId: null,
};

export function reducer(state = initialState, action: event.Actions): State {
  switch (action.type) {
    case event.SET_SELECTED_ID:
      {
        return Object.assign({}, state, {
          selectedEventId: action.payload
        });
      }
    case event.LOGOUT:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
}

export const getSelectedEventId = (state: State) => state.selectedEventId;
