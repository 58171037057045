import { Type } from "@angular/compiler/src/core";

export enum ModalSize {
    Small = 'modal-sm',
    Medium = 'modal-md',
    Large = 'modal-lg'
};

export interface ComponentWithOptions {
    component: Type,
    options?: any
};

export interface Modal {
    title: string,
    componentWithOptions: ComponentWithOptions,
    size?: ModalSize,
    isFullScreenMobile?: boolean,
    isStatic?: boolean,
    isOpen?: boolean,
    displayHeader?: boolean
};
