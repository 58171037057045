import { NgxConfigureService } from "ngx-configure";
import { select, Store } from '@ngrx/store';
import * as PDActions from '../store/actions/packageDetails.actions';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
var NotSupportedComponent = /** @class */ (function () {
    function NotSupportedComponent(configService, store) {
        this.configService = configService;
        this.store = store;
        this.LOGO = require("assets/logo/GroupTools-Logo.svg");
        this.errorImage = require("assets/img/gt-error-faces.png");
        this.currentYear = (new Date()).getFullYear();
        this.unsubscribe$ = new Subject();
    }
    ;
    NotSupportedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new PDActions.PDEffectsLoadSuppliers());
        this.suppliers$ = this.store.pipe(select(PackageDetailsSelectors.getSuppliers));
        this.suppliers$.pipe(takeUntil(this.unsubscribe$)).subscribe(function (suppliers) {
            // Change the logo based on the hostname.
            var hostname = window.location.hostname;
            var supplier = suppliers.filter(function (s) { return s.Url == hostname; });
            if (supplier.length > 0 && supplier[0].LogoImage != undefined && supplier[0].LogoImage != '') {
                var fileName = supplier[0].LogoImage;
                _this.LOGO = "assets/logo/" + fileName;
            }
            else {
                _this.LOGO = "assets/logo/GroupTools-Logo.svg";
            }
        });
    };
    NotSupportedComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return NotSupportedComponent;
}());
export { NotSupportedComponent };
