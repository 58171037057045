/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../google-login/google-login.component.ngfactory";
import * as i2 from "../../google-login/google-login.component";
import * as i3 from "ngx-configure";
import * as i4 from "../../facebook-login/facebook-login.component.ngfactory";
import * as i5 from "../../facebook-login/facebook-login.component";
import * as i6 from "../../facebook-login/facebook.service";
import * as i7 from "../../amazon-login/amazon-login.component.ngfactory";
import * as i8 from "../../amazon-login/amazon-login.component";
import * as i9 from "../../amazon-login/amazon.service";
import * as i10 from "../../../authentication/authentication.service";
import * as i11 from "@angular/common/http";
import * as i12 from "../../helpers/config.service";
import * as i13 from "@ngrx/store";
import * as i14 from "../../contacts/contact.service";
import * as i15 from "@angular/router";
import * as i16 from "../../getting-started/getting-started.service";
import * as i17 from "../../helpers/appState.service";
import * as i18 from "@angular/platform-browser";
import * as i19 from "./login-with-social.component";
var styles_LoginWithSocialComponent = [];
var RenderType_LoginWithSocialComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginWithSocialComponent, data: {} });
export { RenderType_LoginWithSocialComponent as RenderType_LoginWithSocialComponent };
export function View_LoginWithSocialComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "form-group row or-group"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h4", [["class", "or"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "line-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["OR Log In and Register with"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(9, 0, null, null, 7, "div", [["class", "form-group row social-row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "google-login", [], null, [[null, "loginComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginComplete" === en)) {
        var pd_0 = (_co.google($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_GoogleLoginComponent_0, i1.RenderType_GoogleLoginComponent)), i0.ɵdid(14, 4243456, null, 0, i2.GoogleLoginComponent, [i0.Renderer2, i3.NgxConfigureService], null, { loginComplete: "loginComplete" }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(18, 0, null, null, 7, "div", [["class", "form-group row social-row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(20, 0, null, null, 4, "div", [["class", "col-sm-12 btn-row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(22, 0, null, null, 1, "facebook-login", [], null, [[null, "loginComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginComplete" === en)) {
        var pd_0 = (_co.facebook($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FacebookLoginComponent_0, i4.RenderType_FacebookLoginComponent)), i0.ɵdid(23, 114688, null, 0, i5.FacebookLoginComponent, [i6.FacebookService, i3.NgxConfigureService], null, { loginComplete: "loginComplete" }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(27, 0, null, null, 7, "div", [["class", "form-group row social-row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(29, 0, null, null, 4, "div", [["class", "col-sm-12 btn-row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(31, 0, null, null, 1, "amazon-login", [], null, [[null, "loginComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginComplete" === en)) {
        var pd_0 = (_co.amazon($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AmazonLoginComponent_0, i7.RenderType_AmazonLoginComponent)), i0.ɵdid(32, 114688, null, 0, i8.AmazonLoginComponent, [i9.AmazonService, i3.NgxConfigureService], null, { loginComplete: "loginComplete" }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 23, 0); _ck(_v, 32, 0); }, null); }
export function View_LoginWithSocialComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "login-with-social", [], null, null, null, View_LoginWithSocialComponent_0, RenderType_LoginWithSocialComponent)), i0.ɵprd(512, null, i10.AuthService, i10.AuthService, [i11.HttpClient, i12.URLConfig, i12.ClientAuthConfig, i13.Store, i14.ContactService, i0.NgZone, i15.Router, i16.GettingStartedService, i17.AppStateService, i15.ActivatedRoute, i3.NgxConfigureService, i18.DomSanitizer]), i0.ɵdid(2, 49152, null, 0, i19.LoginWithSocialComponent, [i10.AuthService, i0.NgZone], null, null)], null, null); }
var LoginWithSocialComponentNgFactory = i0.ɵccf("login-with-social", i19.LoginWithSocialComponent, View_LoginWithSocialComponent_Host_0, { emailPrefill: "emailPrefill", amazonUser: "amazonUser", googleUser: "googleUser", facebookUser: "facebookUser", inModal: "inModal" }, { differentAccount: "differentAccount", googleRegisterEmit: "googleRegisterEmit", amazonRegisterEmit: "amazonRegisterEmit", facebookRegisterEmit: "facebookRegisterEmit", handlePostLogin: "handlePostLogin", openDifferentAccountModal: "openDifferentAccountModal", openGoogleRegisterModal: "openGoogleRegisterModal", openAmazonRegisterModal: "openAmazonRegisterModal", openFacebookRegisterModal: "openFacebookRegisterModal" }, []);
export { LoginWithSocialComponentNgFactory as LoginWithSocialComponentNgFactory };
