<div class="horizontal-center-container">

  <div class="inner-container" *ngIf="true">

    <img *ngIf="!!supplierImage" [src]="supplierImage" alt="supplier img" class="supplier-img">
    <h4 class="mat-display-1 login-title">Create your event from a Broadway Inbound order</h4>

    <!-- Conditionally put the form in a mat-card -->
    <ng-container *ngIf="shouldPutFormInCard then formInCardTemplate else formNotInCardTemplate"></ng-container>

    <ng-template #formInCardTemplate>
      <mat-card class="order-login-card"><ng-container *ngTemplateOutlet="formTemplate"></ng-container></mat-card>
    </ng-template>

    <ng-template #formNotInCardTemplate>
      <div class="order-login-card">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
      </div>
    </ng-template>

    <ng-template #formTemplate>
      <form [formGroup]="form">
        <loading-spinner *ngIf="shouldShowLoader"
          cssWidth="300px"
          cssHeight="150px"
        ></loading-spinner>
        <div *ngIf="shouldShowLoginForm" class="order-login-form-container">
          <!-- <div *ngIf="showListApiState === ApiState.Error" style="text-align: center;">
            <h1 class="mat-subheading-2" style="color:red;">Error communicating with API</h1>
          </div> -->
          <!-- <div>
            <mat-form-field class="form-field">
              <input matInput type="text" formControlName="autocompleteShow" placeholder="Show" [matAutocomplete]="auto">
            </mat-form-field>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <ng-container *ngFor="let show of filteredShowList$ | async">
                <mat-option [value]="show.title">{{show.title}}</mat-option>
              </ng-container>
            </mat-autocomplete>
          </div> -->
          <div>
            <mat-form-field class="form-field" [appearance]="matFormFieldAppearance">
              <mat-label>Event Date</mat-label>
              <input matInput [matDatepicker]="picker" placeholder="Event Date"
                    (dateChange)="dateSelected($event)" formControlName="date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- <div>
            <mat-form-field class="form-field" [appearance]="matFormFieldAppearance">
              <mat-label>Time</mat-label>
              <mat-select>
                <mat-option> -- </mat-option>
                <mat-option value="2pm">2:00 PM EST</mat-option>
                <mat-option value="6pm">6:30 PM EST</mat-option>
                <mat-option value="8pm">8:00 PM EST</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <!-- <div>
            <mat-form-field class="example-full-width form-field" [appearance]="matFormFieldAppearance">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required') && emailFormControl.touched">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div> -->
          <div>
            <mat-form-field class="form-field" [appearance]="matFormFieldAppearance">
              <mat-label>Order Number</mat-label>
              <input matInput placeholder="Order Number" type="number" formControlName="orderNumber" (keyup.enter)="loginFormEnterKeyup()">
              <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            </mat-form-field>
          </div>
          <div *ngIf="authApiState === ApiState.Error" style="text-align: center;">
            <h1 class="mat-subheading-2" style="color:red;">Failed to find event</h1>
          </div>
          <div class="get-started-button-container">
            <button mat-raised-button
                    class="get-started-button"
                    type="button"
                    color="primary"
                    (click)="getStarted()">Get Started</button>
          </div>
        </div>
      </form>
    </ng-template>

  </div>
</div>
<!-- <powered-by-grouptools></powered-by-grouptools> -->

