import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { SurveyCreationComponent } from 'app/pages/event-builder/survey-event-builder/components/survey-creation/survey-creation.component';
import { SurveyPreviewComponent } from 'app/pages/event-builder/survey-event-builder/components/survey-preview/survey-preview.component';
import { SurveyAddQuestionModule } from 'app/pages/surveys/survey-builder/components/survey-add-question/survey-add-question.module';
import { GenericQuestionModule } from 'app/pages/surveys/survey/components/survey-body/questions/generic-question/generic-question.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EventBuilderContainerModule } from 'app/pages/event-builder/components/event-builder-container/event-builder-container.module';
import { EventDetailsFormModule } from 'app/pages/event-builder/components/event-details-form/event-details-form.module';
import { WidgetsModule } from '../../../../../widgets/widgets.module';
import { InviteContactsModule } from 'app/pages/contacts/invite-contacts/invite-contacts.module';
import { ImportContactsModule } from 'app/pages/contacts/import-contacts/import-contacts.module';

@NgModule({
    imports: [
      CommonModule,
      GTNgaModule,
      FormsModule,
      ReactiveFormsModule,
      SurveyAddQuestionModule,
      GenericQuestionModule,
      EventBuilderContainerModule,
      EventDetailsFormModule,
      WidgetsModule,
      InviteContactsModule,
      ImportContactsModule
    ],
    declarations: [
      SurveyCreationComponent,
      SurveyPreviewComponent
    ],
    exports: [
      SurveyCreationComponent,
      SurveyPreviewComponent
    ]
  })

  export class SurveyCreationModule {}