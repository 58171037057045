import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PaymentsService } from 'app/pages/payments/payments.service';
import * as PaymentActions from '../actions/payment.action';
import { map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PackageDetailsService } from 'app/pages/package-details/package-details.service';
var PaymentEffects = /** @class */ (function () {
    function PaymentEffects(actions$, paymentService, packageDetailsService) {
        var _this = this;
        this.actions$ = actions$;
        this.paymentService = paymentService;
        this.packageDetailsService = packageDetailsService;
        this.loadEventPayments = this.actions$.pipe(ofType(PaymentActions.ActionTypes.PackageDetailsEffectsLoadEventPayments, PaymentActions.ActionTypes.PaymentsComponentLoadEventPayments, PaymentActions.ActionTypes.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData, PaymentActions.ActionTypes.PaymentsFromAttendeesComponentReloadEventPaymentsData, PaymentActions.ActionTypes.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData), // TODO: Should the below be this.paymentsService.getPayments...
        concatMap(function (action) { return _this.packageDetailsService.getPayments(action.payload.eventId).pipe(map(function (separatedPayments) { return new PaymentActions.PaymentEffectLoadEventPaymentsSuccess({ separatedPayments: separatedPayments }); }), catchError(function (error) { return of(new PaymentActions.PaymentEffectLoadEventPaymentsFailure({ errorMessage: error.toString() })); })); }));
        this.loadPaymentsReceivedSummary = this.actions$.pipe(ofType(PaymentActions.ActionTypes.PackageDetailsEffectsLoadPaymentsReceivedSummary, PaymentActions.ActionTypes.PaymentsFromAttendeesSummaryComponentReloadEventPaymentsData, PaymentActions.ActionTypes.PaymentsFromAttendeesComponentReloadEventPaymentsData, PaymentActions.ActionTypes.PaymentsToSuppliersSummaryComponentReloadEventPaymentsData), concatMap(function (action) { return _this.packageDetailsService.GetPaymentsReceivedSummary(action.payload.eventId).pipe(map(function (paymentsReceivedSummary) { return new PaymentActions.PaymentEffectLoadPaymentsReceivedSummarySuccess({ paymentsReceivedSummary: paymentsReceivedSummary }); }), catchError(function (error) { return of(new PaymentActions.PaymentEffectLoadPaymentsReceivedSummaryFailure({ errorMessage: error.toString() })); })); }));
        this.loadPaymentsSentSummary = this.actions$.pipe(ofType(PaymentActions.ActionTypes.PackageDetailsEffectsLoadPaymentsSentSummary), concatMap(function (action) { return _this.packageDetailsService.GetPaymentsSentSummary(action.payload.eventId).pipe(map(function (paymentsSentSummary) { return new PaymentActions.PaymentEffectLoadPaymentsSentSummarySuccess({ paymentsSentSummary: paymentsSentSummary }); }), catchError(function (error) { return of(new PaymentActions.PaymentEffectLoadPaymentsSentSummaryFailure({ errorMessage: error.toString() })); })); }));
        this.loadEventPaymentsNew = this.actions$.pipe(ofType(PaymentActions.ActionTypes.GLEDComponentLoadEventPaymentsNew), concatMap(function (action) { return _this.paymentService.getPaymentsNew(action.payload.eventId).pipe(map(function (payments) { return new PaymentActions.PaymentEffectLoadEventPaymentsNewSuccess({ payments: payments }); }), catchError(function (error) { return of(new PaymentActions.PaymentEffectLoadEventPaymentsNewFailure({ errorMessage: error.toString() })); })); }));
        this.makeSupplierPayment = this.actions$.pipe(ofType(PaymentActions.ActionTypes.ATEDComponentMakeSupplierPayment), concatMap(function (action) { return _this.paymentService.makeSupplierPayment(action.payload.makePaymentRequest).pipe(map(function (response) { return new PaymentActions.ATEDComponentMakeSupplierPaymentSuccess({ makePaymentResponse: response }); }), catchError(function (error) { return of(new PaymentActions.ATEDComponentMakeSupplierPaymentFailure({ errorMessage: error.toString() })); })); }));
        this.addAndAssociateCustomer = this.actions$.pipe(ofType(PaymentActions.ActionTypes.ATEDComponentAddAndAssociateCustomer), concatMap(function (action) { return _this.paymentService.addAndAssociateCustomer(action.payload.addAndAssociateCustomerRequest).pipe(map(function (response) { return new PaymentActions.ATEDComponentAddAndAssociateCustomerSuccess({ addAndAssociateCustomerResponse: response }); }), catchError(function (error) { return of(new PaymentActions.ATEDComponentAddAndAssociateCustomerFailure({ errorMessage: error.toString() })); })); }));
        this.makeSupplierGLPayment = this.actions$.pipe(ofType(PaymentActions.ActionTypes.GLEDComponentMakeSupplierGLPayment), concatMap(function (action) { return _this.paymentService.makeSupplierGLPayment(action.payload.makePaymentRequest).pipe(map(function (response) { return new PaymentActions.GLEDComponentMakeSupplierGLPaymentSuccess({ makePaymentResponse: response }); }), catchError(function (error) { return of(new PaymentActions.GLEDComponentMakeSupplierGLPaymentFailure({ errorMessage: error.toString() })); })); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "loadEventPayments", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "loadPaymentsReceivedSummary", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "loadPaymentsSentSummary", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "loadEventPaymentsNew", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "makeSupplierPayment", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "addAndAssociateCustomer", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PaymentEffects.prototype, "makeSupplierGLPayment", void 0);
    return PaymentEffects;
}());
export { PaymentEffects };
