import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { MessagesService } from "app/pages/messages/messages.service";
import * as fromMessage from '../actions/message.action';
import { exhaustMap, map, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import * as MessageActions from "app/store/actions/message.action";
var MessageEffects = /** @class */ (function () {
    function MessageEffects(actions$, messageService) {
        var _this = this;
        this.actions$ = actions$;
        this.messageService = messageService;
        this.loadEventMessages = this.actions$.pipe(ofType(MessageActions.ActionTypes.PDEffectsLoadEventMessages), concatMap(function (action) {
            var options = { IdPackage: action.payload.eventId };
            return _this.messageService.GetMessages(options).pipe(map(function (eventMessages) { return new MessageActions.MessageEffectsLoadEventMessagesSuccess({ eventMessages: eventMessages }); }), catchError(function (error) { return of(new MessageActions.MessageEffectsLoadEventMessagesFailure({ errorMessage: error.toString() })); }));
        }));
        this.loadEventSentMessages = this.actions$.pipe(ofType(MessageActions.ActionTypes.PDEffectsLoadEventSentMessages), concatMap(function (action) {
            var options = { IdPackage: action.payload.eventId };
            return _this.messageService.GetSentMessages(options).pipe(map(function (eventSentMessages) { return new MessageActions.MessageEffectsLoadEventSentMessagesSuccess({ eventSentMessages: eventSentMessages }); }), catchError(function (error) { return of(new MessageActions.MessageEffectsLoadEventSentMessagesFailure({ errorMessage: error.toString() })); }));
        }));
        this.loadEventMessagesNew = this.actions$.pipe(ofType(fromMessage.ActionTypes.LoadEventMessagesNew), exhaustMap(function (action) { return _this.messageService.getMessagesNew(action.payload.eventId)
            .pipe(map(function (eventMessagesNew) { return new fromMessage.LoadEventMessagesNewSuccess({ eventMessagesNew: eventMessagesNew }); }), catchError(function (error) { return of(new fromMessage.LoadEventMessagesNewFailure({ errorMessage: error.toString() })); })); }));
        this.deleteMessage = this.actions$.pipe(ofType(MessageActions.ActionTypes.MessagesListComponentDeleteMessage), concatMap(function (action) {
            return _this.messageService.DeleteMessages(action.payload.message).pipe(map(function (idUserMessage) { return new MessageActions.MessageEffectsDeleteMessageSuccess({ idUserMessage: idUserMessage }); }), catchError(function (error) { return of(new MessageActions.MessageEffectsDeleteMessageFailure({ errorMessage: error.toString() })); }));
        }));
        this.markMessageAsRead = this.actions$.pipe(ofType(MessageActions.ActionTypes.MessagesListComponentMarkMessageAsRead), concatMap(function (action) {
            return _this.messageService.MarkAsRead(action.payload.message).pipe(map(function (idUserMessage) { return new MessageActions.MessageEffectsMarkMessageAsReadSuccess({ message: action.payload.message }); }), catchError(function (error) { return of(new MessageActions.MessageEffectsMarkMessageAsReadFailure({ errorMessage: error.toString() })); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessageEffects.prototype, "loadEventMessages", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessageEffects.prototype, "loadEventSentMessages", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessageEffects.prototype, "loadEventMessagesNew", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessageEffects.prototype, "deleteMessage", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessageEffects.prototype, "markMessageAsRead", void 0);
    return MessageEffects;
}());
export { MessageEffects };
