import { Action } from '@ngrx/store';

export const SHOW_LOADER = '[Loader] Set Loader Show';
export const HIDE_LOADER = '[Loader] Set Loader Hide';

export class ShowLoader implements Action {
    readonly type = SHOW_LOADER;
    constructor() {}
  }
  
  export class HideLoader implements Action {
    readonly type = HIDE_LOADER;
    constructor() {}
  }

  export type Actions = ShowLoader | HideLoader;