var GrouptoolsPlaceholderComponent = /** @class */ (function () {
    function GrouptoolsPlaceholderComponent() {
        this.isModal = false;
    }
    GrouptoolsPlaceholderComponent.prototype.ngOnInit = function () {
        if (this.pRow.nativeElement.parentElement.id === "modalPlaceholder") {
            this.isModal = true;
        }
    };
    return GrouptoolsPlaceholderComponent;
}());
export { GrouptoolsPlaceholderComponent };
