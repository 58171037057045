import { URLConfig } from 'app/pages/helpers/config.service';
import { FreeFormQuestion } from 'app/pages/surveys/survey/components/survey-body/questions/question-bases/freeForm';
import { RadioQuestion } from 'app/pages/surveys/survey/components/survey-body/questions/question-bases/radio';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { handleGTResponse } from 'app/store/models/gtResponse';
var SurveysService = /** @class */ (function () {
    function SurveysService(http, urls, store) {
        this.http = http;
        this.urls = urls;
        this.store = store;
        // TEMP: TODO add this to the store with refactor
        // tslint:disable-next-line:member-ordering
        this.creatingSurvey = false;
    }
    // TODO: The methods here should be used in ngrx flow, currently they are called directly in components
    SurveysService.prototype.getSurveys = function (packageId) {
        return this.http.get(this.urls._getSurveys(packageId)).pipe(handleGTResponse());
    };
    SurveysService.prototype.hasResponded = function (surveyId) {
        return this.http.get(this.urls._hasResponded(surveyId)).pipe(handleGTResponse());
    };
    SurveysService.prototype.getSurveyDetails = function (surveyID) {
        return this.http.get(this.urls._getSurveyDetails(surveyID)).pipe(handleGTResponse());
    };
    SurveysService.prototype.createSurvey = function (survey) {
        return this.http.post(this.urls._createSurveyForPackage(), survey).pipe(handleGTResponse());
    };
    SurveysService.prototype.answerSurvey = function (surveyResponse) {
        return this.http.post(this.urls._answerSurvey(), surveyResponse).pipe(handleGTResponse());
    };
    SurveysService.prototype.formatSurveyQuestions = function (questions) {
        var formattedQuestions = [];
        questions.forEach(function (question) {
            if (question.questionType === 'freeForm') {
                formattedQuestions.push(new FreeFormQuestion(question));
            }
            if (question.questionType === 'radio') {
                formattedQuestions.push(new RadioQuestion(question));
            }
            if (question.questionType === 'show') {
                formattedQuestions.push(new RadioQuestion(question));
            }
            if (question.questionType === 'workshop') {
                formattedQuestions.push(new RadioQuestion(question));
            }
        });
        return formattedQuestions.sort(function (a, b) { return a.questionOrder - b.questionOrder; });
    };
    return SurveysService;
}());
export { SurveysService };
