import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventItem } from '../models/eventItem';
import { AttendeeUpsertRequest } from '../models/requests/attendee-upsert-request';

// NOTE: The DragDropMessagingService class is only used for visual updates between components
//       It is not used for persistant state, that is handled by ngrx.

@Injectable({
  providedIn: 'root'
})
export class DragDropMessagingService {
  //----------------------------------------------------------------------------------------
  private subject = new Subject<{messageType: DragDropMessageTypes, attendee: AttendeeUpsertRequest, eventItem: EventItem}>();
  public send(messageType: DragDropMessageTypes, attendee: AttendeeUpsertRequest, eventItem: EventItem) {
    this.subject.next({messageType, attendee, eventItem});
  }
  public on(): Observable<{messageType: DragDropMessageTypes, attendee: AttendeeUpsertRequest, eventItem: EventItem}> {
    return this.subject.asObservable();
  }
  //----------------------------------------------------------------------------------------
  // This is just temporary state to be used in a seat assignment component (there are 3)
  private eventItemIdSet = new Set<number>();
  public registerEventItemAsLinkInvalidated(eventItem: EventItem) {
    if (!eventItem) return;
    this.eventItemIdSet.add(eventItem.id);
  }
  public isEventItemLinkInvalidated(eventItem: EventItem): boolean {
    return this.eventItemIdSet.has(eventItem.id);
  }
  public clearEventItemLinkInvalidatedState() {
    this.eventItemIdSet.clear();
  }
  //----------------------------------------------------------------------------------------
  constructor() { }
}

export enum DragDropMessageTypes {
  AttendeeHasBeenUnassigned = "AttendeeHasBeenUnassigned", // dropped into unassigned list (to notify seating groups)
  AttendeeHasBeenKickedOutOfSeat = "AttendeeHasBeenKickedOutOfSeat",
  AttendeeAssignedToSeat = "AttendeeAssignedToSeat",
  AttendeeMovedFromSeat = "AttendeeMovedFromSeat",
}