import * as tslib_1 from "tslib";
import * as ContactsActions from "app/store/actions/contacts.action";
export var initialState = {
    contacts: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ContactsActions.ActionTypes.ContactsEffectLoadContactsSuccess:
            {
                return tslib_1.__assign({}, state, { contacts: action.payload.contacts });
            }
        case ContactsActions.ActionTypes.ContactsEffectLoadContactsFailure:
            {
                return tslib_1.__assign({}, state);
            }
        case ContactsActions.ActionTypes.AuthenticationServiceLogout:
            {
                return initialState;
            }
        case ContactsActions.ActionTypes.AuthenticationServiceLogout:
            {
                return tslib_1.__assign({}, initialState);
            }
        default:
            {
                return state;
            }
    }
}
