import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, OnDestroy, ElementRef, Renderer2, Host } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { ModalSize, ComponentWithOptions } from 'app/store/models/modal';
import { takeUntil } from 'rxjs/operators';
import { CloseModal } from 'app/store/actions/modal.action';

@Component({
    selector: 'grouptools-new-modal', 
    templateUrl: './grouptools-new-modal.component.html'
})
export class GroupToolsNewModalComponent implements OnInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef }) 
    public container: ViewContainerRef;
    public title: string;
    public isStatic: boolean;
    public displayHeader: boolean;

    private _component$: Observable<ComponentWithOptions>;
    private _size$: Observable<ModalSize>;
    private _title$: Observable<string>;
    private _isStatic$: Observable<boolean>;
    private _displayHeader$: Observable<boolean>;
    private _isFullScreenMobile$: Observable<boolean>;
    private _unsubscribe$ = new Subject();
    
    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _store: Store<fromRoot.State>,
        private _element: ElementRef
    ) {
        this._component$ = this._store.select(fromRoot.getModalComponent);
        this._size$ = this._store.select(fromRoot.getModalSize);
        this._title$ = this._store.select(fromRoot.getModalTitle);
        this._isStatic$ = this._store.select(fromRoot.getModalIsStatic);
        this._displayHeader$ = this._store.select(fromRoot.getModalDisplayHeader);
        this._isFullScreenMobile$ = this._store.select(fromRoot.getIsFullScreenMobile);
    }

    ngOnInit(): void { 
        this._component$.pipe(takeUntil(this._unsubscribe$)).subscribe(componentWithOptions => {
            this._loadComponent(componentWithOptions);
        });

        this._size$.pipe(takeUntil(this._unsubscribe$)).subscribe(size => {
            this._setSize(size);
        });

        this._title$.pipe(takeUntil(this._unsubscribe$)).subscribe(title => {
            this.title = title;
        });

        this._isStatic$.pipe(takeUntil(this._unsubscribe$)).subscribe(isStatic => {
            this.isStatic = isStatic;
        });

        this._displayHeader$.pipe(takeUntil(this._unsubscribe$)).subscribe(displayHeader => {
            this.displayHeader = displayHeader;
        });

        this._isFullScreenMobile$.pipe(takeUntil(this._unsubscribe$)).subscribe(isFullScreenMobile => {
            this._toggleFullScreenMobile(isFullScreenMobile);
        });
    }

    public close() {
        this._store.dispatch(new CloseModal());
    }

    //https://stackoverflow.com/questions/48725651/dynamically-load-component-in-div-angular5
    private _loadComponent(componentWithOptions: ComponentWithOptions) {
        if(!!componentWithOptions) {
            this.container.clear();

            const factory = this._componentFactoryResolver.resolveComponentFactory(componentWithOptions.component);
            const componentRef = this.container.createComponent(factory);

            if(!!componentWithOptions.options) {
                Object.keys(componentWithOptions.options).forEach(element => {
                    // if(componentRef.instance.hasOwnProperty(element)) {
                        componentRef.instance[element] = componentWithOptions.options[element];
                    // }
                });
            }

            componentRef.changeDetectorRef.detectChanges();
        }
    }

    //TODO: NATIVE APP: REFACTOR:
    //If we ever turn this into a native app, we need to revisit both _setSize and _toggleFullScreenMobile.
    //We will not inherently have access to the DOM and will need a new way to accomplish this on-demand.
    private _setSize(modalSize: ModalSize) {
        let modalDialog : Element = this._element.nativeElement.closest('.modal-dialog');
        if (modalDialog) {
            modalDialog.classList.remove(ModalSize.Small.toString(), ModalSize.Medium.toString(), ModalSize.Large.toString());
            modalDialog.classList.add(modalSize.toString());
        }
    }

    private _toggleFullScreenMobile(isFullScreenMobile: boolean) {
        let modalDialog : Element = this._element.nativeElement.closest('.modal-dialog');
        let modal : Element = this._element.nativeElement.closest('.modal');

        if (modalDialog && modal) {
            modal.classList.remove('auto');
            modalDialog.classList.remove('auto');

            if(isFullScreenMobile) {
                modal.classList.add('auto');
                modalDialog.classList.add('auto');
            } 
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }
}