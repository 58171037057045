/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./amazon-login.component";
import * as i2 from "./amazon.service";
import * as i3 from "ngx-configure";
var styles_AmazonLoginComponent = [];
var RenderType_AmazonLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AmazonLoginComponent, data: {} });
export { RenderType_AmazonLoginComponent as RenderType_AmazonLoginComponent };
export function View_AmazonLoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["button", 1]], null, 0, "img", [["class", "clickable social-image img-fluid"], ["id", "LoginWithAmazon"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.amazonLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.amazonButtonImage; _ck(_v, 0, 0, currVal_0); }); }
export function View_AmazonLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amazon-login", [], null, null, null, View_AmazonLoginComponent_0, RenderType_AmazonLoginComponent)), i0.ɵdid(1, 114688, null, 0, i1.AmazonLoginComponent, [i2.AmazonService, i3.NgxConfigureService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AmazonLoginComponentNgFactory = i0.ɵccf("amazon-login", i1.AmazonLoginComponent, View_AmazonLoginComponent_Host_0, {}, { loginComplete: "loginComplete" }, []);
export { AmazonLoginComponentNgFactory as AmazonLoginComponentNgFactory };
