import * as loaderActions from '../actions/loader.action';
import { Loader } from '../models/loader';

export type State = Loader;

const initialState: State = {
    show: false
}

export function reducer(state: State = initialState, action: loaderActions.Actions): State {
    switch (action.type) {
        case loaderActions.SHOW_LOADER: {
            return { ...state, ...{ show: true } }
        }
        case loaderActions.HIDE_LOADER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export const getIsLoading = (state: State) => state.show;