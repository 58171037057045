export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["AuthenticationServiceLoadContacts"] = "[Authentication Service] Load Contacts";
    ActionTypes["ContactsEffectLoadContactsSuccess"] = "[Contacts Effect] Load Contacts Success";
    ActionTypes["ContactsEffectLoadContactsFailure"] = "[Contacts Effect] Load Contacts Failure";
    ActionTypes["InviteContactsComponentReloadContacts"] = "[Invite Contacts Componenet] Reload Contacts";
    ActionTypes["AttendeesSummaryComponentReloadContacts"] = "[Attendees Summary Componenet] Reload Contacts";
    ActionTypes["AttendeesComponentReloadContacts"] = "[Attendees Componenet] Reload Contacts";
    ActionTypes["PackageDetailsEffectLoadContacts"] = "[Package Details Effect] Load Contacts";
    ActionTypes["ContactComponentReloadContacts"] = "[Contact Component] Reload Contacts";
    ActionTypes["CreateFromScratchComponentReloadContacts"] = "[Create From Scratch Component] Reload Contacts";
    ActionTypes["SurveyCreationComponentReloadContacts"] = "[Survey Creation Component] Reload Contacts";
    ActionTypes["GettingStartedComponentReloadContacts"] = "[Getting Started Component] Reload Contacts";
    ActionTypes["MessagesCardComponentReloadContacts"] = "[Messages Card Component] Reload Contacts";
    ActionTypes["DashboardComponentLoadContacts"] = "[Dashboard Component] Load Contacts";
    ActionTypes["GroupToolsListComponentReloadContacts"] = "[GroupTools List Component] Reload Contacts";
    ActionTypes["AuthenticationServiceLogout"] = "[Authentication Service] Logout";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var AuthenticationServiceLoadContacts = /** @class */ (function () {
    function AuthenticationServiceLoadContacts() {
        this.type = ActionTypes.AuthenticationServiceLoadContacts;
    }
    return AuthenticationServiceLoadContacts;
}());
export { AuthenticationServiceLoadContacts };
var ContactsEffectLoadContactsSuccess = /** @class */ (function () {
    function ContactsEffectLoadContactsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.ContactsEffectLoadContactsSuccess;
    } // TODO: can eventId be null for actions endpoint?
    return ContactsEffectLoadContactsSuccess;
}());
export { ContactsEffectLoadContactsSuccess };
var ContactsEffectLoadContactsFailure = /** @class */ (function () {
    function ContactsEffectLoadContactsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.ContactsEffectLoadContactsFailure;
    }
    return ContactsEffectLoadContactsFailure;
}());
export { ContactsEffectLoadContactsFailure };
//----------------------------------------------------------------
var InviteContactsComponentReloadContacts = /** @class */ (function () {
    function InviteContactsComponentReloadContacts() {
        this.type = ActionTypes.InviteContactsComponentReloadContacts;
    }
    return InviteContactsComponentReloadContacts;
}());
export { InviteContactsComponentReloadContacts };
var AttendeesSummaryComponentReloadContacts = /** @class */ (function () {
    function AttendeesSummaryComponentReloadContacts() {
        this.type = ActionTypes.AttendeesSummaryComponentReloadContacts;
    }
    return AttendeesSummaryComponentReloadContacts;
}());
export { AttendeesSummaryComponentReloadContacts };
var AttendeesComponentReloadContacts = /** @class */ (function () {
    function AttendeesComponentReloadContacts() {
        this.type = ActionTypes.AttendeesComponentReloadContacts;
    }
    return AttendeesComponentReloadContacts;
}());
export { AttendeesComponentReloadContacts };
var PackageDetailsEffectLoadContacts = /** @class */ (function () {
    function PackageDetailsEffectLoadContacts() {
        this.type = ActionTypes.PackageDetailsEffectLoadContacts;
    }
    return PackageDetailsEffectLoadContacts;
}());
export { PackageDetailsEffectLoadContacts };
var ContactComponentReloadContacts = /** @class */ (function () {
    function ContactComponentReloadContacts() {
        this.type = ActionTypes.ContactComponentReloadContacts;
    }
    return ContactComponentReloadContacts;
}());
export { ContactComponentReloadContacts };
var CreateFromScratchComponentReloadContacts = /** @class */ (function () {
    function CreateFromScratchComponentReloadContacts() {
        this.type = ActionTypes.CreateFromScratchComponentReloadContacts;
    }
    return CreateFromScratchComponentReloadContacts;
}());
export { CreateFromScratchComponentReloadContacts };
var SurveyCreationComponentReloadContacts = /** @class */ (function () {
    function SurveyCreationComponentReloadContacts() {
        this.type = ActionTypes.SurveyCreationComponentReloadContacts;
    }
    return SurveyCreationComponentReloadContacts;
}());
export { SurveyCreationComponentReloadContacts };
var GettingStartedComponentReloadContacts = /** @class */ (function () {
    function GettingStartedComponentReloadContacts() {
        this.type = ActionTypes.GettingStartedComponentReloadContacts;
    }
    return GettingStartedComponentReloadContacts;
}());
export { GettingStartedComponentReloadContacts };
var MessagesCardComponentReloadContacts = /** @class */ (function () {
    function MessagesCardComponentReloadContacts() {
        this.type = ActionTypes.MessagesCardComponentReloadContacts;
    }
    return MessagesCardComponentReloadContacts;
}());
export { MessagesCardComponentReloadContacts };
var DashboardComponentLoadContacts = /** @class */ (function () {
    function DashboardComponentLoadContacts() {
        this.type = ActionTypes.DashboardComponentLoadContacts;
    }
    return DashboardComponentLoadContacts;
}());
export { DashboardComponentLoadContacts };
var GroupToolsListComponentReloadContacts = /** @class */ (function () {
    function GroupToolsListComponentReloadContacts() {
        this.type = ActionTypes.GroupToolsListComponentReloadContacts;
    }
    return GroupToolsListComponentReloadContacts;
}());
export { GroupToolsListComponentReloadContacts };
//----------------------------------------------------------------
var AuthenticationServiceLogout = /** @class */ (function () {
    function AuthenticationServiceLogout() {
        this.type = ActionTypes.AuthenticationServiceLogout;
    }
    return AuthenticationServiceLogout;
}());
export { AuthenticationServiceLogout };
