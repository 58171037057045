import { Component, Input, OnInit } from '@angular/core';

export type SpinnerVerticalPlacement = 'top' | 'center';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() cssWidth: string = '400px';
  @Input() cssHeight: string = '500px';
  @Input() cssBackgroundColor: string = '';
  @Input() text: string = 'Loading';
  @Input() opacity: number = 1;
  @Input() cssMarginTop: string = '40px'; // Only used when verticalPlacement == 'top'
  @Input() verticalPlacement: SpinnerVerticalPlacement = 'center';
  @Input() maxWidth: string = '100%';
  @Input() position: string = 'absolute';
  @Input() zIndex: string = '10';

  get shouldCenterSpinnerVertically(): boolean {
    return this.verticalPlacement === 'center';
  }

  get shouldTopAlignSpinnerVertically(): boolean {
    return this.verticalPlacement === 'top';
  }

  constructor() { }

  ngOnInit() {
  }

}
