import {map, switchMap, catchError, exhaustMap, mergeMap, tap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromEvent from '../actions/event.action';
import * as activityActions from '../actions/activity.action';
import * as AttendeesActions from 'app/store/actions/attendees.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { AppStateService } from '../../pages/helpers/appState.service';
import { Injectable } from '@angular/core';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { of } from 'rxjs';

@Injectable()
export class EventEffects {

    // The effect will listen to the LOAD_OWNED_EVENTS action. When it is dispatched,
    // this effect will call the web service to retrieve all owned events.
    // If successful, it will switchmap to a new action: LOAD_OWNED_EVENTS_OK with
    //   the events as the payload.
    // If not successful, it will call the error handler
    @Effect()
    loadCreatedEvents = this.actions$
        .pipe(
            ofType(fromEvent.LOAD_OWNED_EVENTS),
            exhaustMap(action => this.service.getOwnedEvents()
                        .pipe(
                            map(events => new fromEvent.LoadOwnedEventsOk(events)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadTransferredEvents = this.actions$
        .pipe(
            ofType(fromEvent.LOAD_TRANSFERRED_EVENTS),
            exhaustMap(action => this.service.getTransferredEvents()
                        .pipe(
                            map(events => new fromEvent.LoadTransferredEventsOk(events)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadInvitedEvents = this.actions$
        .pipe(
            ofType(fromEvent.LOAD_INVITED_EVENTS),
            switchMap(action => this.service.getInvitedEvents()
                        .pipe(
                            map(events => new fromEvent.LoadInvitedEventsOk(events)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadSelectedEvent = this.actions$
        .pipe(
            ofType<fromEvent.LoadSelectedEvent>(fromEvent.LOAD_SELECTED_EVENT),
            switchMap(action => this.eventService.getSelectedEvent(action.payload)
                        .pipe(
                            map(event => {
                                if (!event.shortUrl) {
                                    return new fromEvent.RequestShortUrl(event);
                                }
                                return new fromEvent.LoadSelectedEventOk(event);
                            }),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    updateEvent = this.actions$
        .pipe(
            ofType<fromEvent.UpdateEvent>(fromEvent.UPDATE_EVENT),
            exhaustMap(action => this.eventService.updateEvent(action.payload)
                        .pipe(
                            map(event => new fromEvent.UpdateEventOk(event)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadAlternateLeaders = this.actions$
        .pipe(
            ofType<fromEvent.LoadAlternateLeaders>(fromEvent.LOAD_ALTERNATE_LEADERS),
            switchMap(action => this.eventService.getAlternateLeaders(action.payload)
                        .pipe(
                            map(alternates => new fromEvent.LoadAlternateLeadersOk(alternates)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    loadEventOverview = this.actions$
        .pipe(
            ofType<fromEvent.LoadEventOverview>(fromEvent.LOAD_EVENT_OVERVIEW),
            switchMap(action => this.eventService.getEventOverview(action.payload)
                        .pipe(
                            map(eventOverview => new fromEvent.LoadEventOverviewOk(eventOverview)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
    )

    @Effect()
    requestShortUrl = this.actions$
        .pipe(
            ofType<fromEvent.RequestShortUrl>(fromEvent.REQUEST_SHORT_URL),
            switchMap(action => this.eventService.requestShortUrl(action.payload)
                        .pipe(
                            // if we successfully update the shortUrl, push an update event ok message
                            // because it's the same result: an updated event!
                            map(event => new fromEvent.UpdateEventOk(event)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    convertToTemplate = this.actions$
        .pipe(
            ofType<fromEvent.ConvertToTemplate>(fromEvent.CONVERT_TO_TEMPLATE),
            exhaustMap(action => this.eventService.convertToTemplate(action.payload)
                        .pipe(
                            mergeMap(event => [
                                new fromEvent.ConvertToTemplateOk(event),
                                new fromEvent.LoadSelectedEventOk(event)
                            ]),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    cloneEvent = this.actions$
        .pipe(
            ofType<fromEvent.CloneEvent>(fromEvent.CLONE_EVENT),
            exhaustMap(action => this.eventService.cloneEvent(action.payload)
                        .pipe(
                            map(event => new fromEvent.CloneEventOk(event)),
                            catchError(error => of(new UnhandledErrorActions.UnhandledError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    assignEvent = this.actions$
        .pipe(
            ofType<fromEvent.AssignEvent>(fromEvent.ASSIGN_EVENT),
            exhaustMap(action => this.eventService.assignEvent(action.payload)
                        .pipe(
                            map(event => new fromEvent.AssignEventOk(event)),
                            catchError((error) => of(new fromEvent.AssignEventError({errorMessage: error.toString()})))
                        )
            )
        );

    @Effect()
    cloneAndAssignEvent = this.actions$
        .pipe(
            ofType<fromEvent.AssignEvent>(fromEvent.CLONE_AND_ASSIGN_EVENT),
            exhaustMap(action => this.eventService.cloneAndAssignEvent(action.payload)
                        .pipe(
                            map(event => new fromEvent.CloneAndAssignEventOk(event)),
                            catchError((error) => of(new fromEvent.AssignEventError({errorMessage: error.toString()})))
                        )
            )
        );

    // Aggregate reducer to clear selected data
    // An Effect() must return an Observable of an Action. By default if nothing is returned
    // The same observable<Action> that triggered it will be sent again.
    // Instead, we want to switchmap to an observable of all of the individual actions we
    // want to take. Fortunately, we can just return an array of actions
    @Effect()
    clearSelectedEvent = this.actions$
        .pipe(
            ofType<fromEvent.ClearSelectedEventData>(fromEvent.CLEAR_SELECTED_EVENT_DATA),
            switchMap(event => [
                new fromEvent.ClearSelectedEvent(),
                new activityActions.ClearSelectedActivities(),
                new AttendeesActions.EventEffectClearAttendees(),
            ])
        );

    // constructor last to prevent member-ordering ts-lint warnings
    constructor(
        private actions$: Actions,
        private service: AppStateService,
        private eventService: EventDetailsService,
    ) { }
}
