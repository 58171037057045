import { SurveyResponseService } from 'app/pages/survey-response/survey-response.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule, ProgressbarModule } from 'ngx-bootstrap';
import { EventBuilderLandingPageComponent } from 'app/pages/event-builder/landing-page/event-builder-landing-page.component';
import { BiEventCardComponent } from 'app/pages/event-builder/landing-page/event-type-cards/bi-event-card/bi-event-card.component';
import { NewEventCardComponent } from 'app/pages/event-builder/landing-page/event-type-cards/new-event-card/new-event-card.component';
import { EventBuilderContainerModule } from './components/event-builder-container/event-builder-container.module';
import { EventDetailsFormModule } from './components/event-details-form/event-details-form.module';
import { CreateFromScratchComponent } from 'app/pages/event-builder/create-from-scratch/create-from-scratch.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EventBuilderService } from 'app/pages/event-builder/event-builder.service';
import { AddFeaturesModule } from 'app/pages/package-details/interactions/features/add-features/add-features.module';
import { InviteContactsModule } from 'app/pages/contacts/invite-contacts/invite-contacts.module';
import { ImportContactsModule } from 'app/pages/contacts/import-contacts/import-contacts.module';
import { InviteDetailsModule } from 'app/pages/package-invite/invite-details/invite-details.module';
import { WidgetsModule } from '../../widgets/widgets.module';
import { GTNgaModule } from 'app/grouptools-theme/gtnga.module';
import { RegisterModule } from '../register-page/register-page.module';
import { UploadFeatureImgModule } from 'app/pages/package-details/interactions/features/upload-feature-img/upload-feature-img.module';
import { SurveyAddQuestionModule } from 'app/pages/surveys/survey-builder/components/survey-add-question/survey-add-question.module';
import { GenericQuestionModule } from 'app/pages/surveys/survey/components/survey-body/questions/generic-question/generic-question.module';
import { SurveyCreationModule } from 'app/pages/event-builder/survey-event-builder/components/survey-creation/survey-creation.module';
import { ModalModule } from 'ngx-bootstrap';
import { HotelPlannerCardComponent } from './landing-page/event-type-cards/hotel-planner-card/hotel-planner-card.component';
import { CreateFromBibOrderComponent } from './create-from-bib-order/create-from-bib-order.component';
import { CreateFromBibOrderViewComponent } from './create-from-bib-order/create-from-bib-order-view.component';
import { CreateFromSupplierOrderComponent } from './create-from-supplier-order/create-from-supplier-order.component';
import { CreateFromSupplierOrderViewComponent } from './create-from-supplier-order/create-from-supplier-order-view.component';
import { MaterialModule } from 'app/material';

@NgModule({
  imports: [
    CommonModule,
    GTNgaModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    AddFeaturesModule,
    InviteContactsModule,
    ImportContactsModule,
    ProgressbarModule.forRoot(),
    WidgetsModule,
    InviteDetailsModule,
    RegisterModule,
    UploadFeatureImgModule,
    SurveyAddQuestionModule,
    GenericQuestionModule,
    SurveyCreationModule,
    EventBuilderContainerModule,
    EventDetailsFormModule,
    ModalModule,
    MaterialModule,
  ],
  declarations: [
    EventBuilderLandingPageComponent,
    BiEventCardComponent,
    NewEventCardComponent,
    CreateFromScratchComponent,
    HotelPlannerCardComponent,
    CreateFromBibOrderComponent,
    CreateFromBibOrderViewComponent,
    CreateFromSupplierOrderComponent,
    CreateFromSupplierOrderViewComponent,
  ],
  exports: [
    BiEventCardComponent,
    NewEventCardComponent,
    CreateFromBibOrderViewComponent,
    CreateFromSupplierOrderViewComponent,
  ],
  providers: [
      SurveyResponseService,
      EventBuilderService
  ]
})
export class EventBuilderModule {};
