import { EventEmitter } from '@angular/core';
import 'style-loader!./baMenuItem.scss';
var BaMenuItem = /** @class */ (function () {
    function BaMenuItem() {
        this.child = false;
        this.itemHover = new EventEmitter();
    }
    BaMenuItem.prototype.onHoverItem = function ($event) {
        this.itemHover.emit($event);
    };
    BaMenuItem.prototype.openNewTab = function () {
        window.open(this.menuItem.path, "_blank");
    };
    return BaMenuItem;
}());
export { BaMenuItem };
