import * as activityActions from '../actions/activity.action';
import { Activity } from '../models/activity';

export type State = Activity[];

// initialize state to an empty array
export function reducer(state: State = [], action: activityActions.Actions): State {
    switch (action.type) {
        case activityActions.LOAD_SELECTED_ACTIVITIES_OK:
        {
            return action.payload;
        }
        case activityActions.CLEAR_SELECTED_ACTIVITIES:
        {
            return [];
        }
        case activityActions.CREATE_ACTIVITY_OK:
        {
            const createdActivity = (<activityActions.CreateActivityOk>action).payload;
            const newActivityArray = getNewActivityArray(state);
            newActivityArray.push(createdActivity);
            return newActivityArray;
        }
        case activityActions.UPDATE_ACTIVITY_OK:
        {
            const updatedActivity = (<activityActions.UpdateActivityOk>action).payload;
            const newActivityArray = getNewActivityArray(state);

            const index = newActivityArray.findIndex(activity => activity.id === updatedActivity.id);
            if (index >= 0) {
                newActivityArray[index] = updatedActivity;
            } else {
                console.error('eventActivities.reducer: Activity not found when updating activiy', updatedActivity, newActivityArray);
            }
            return newActivityArray;
        }
        case activityActions.DELETE_ACTIVITY_OK:
        {
            const deletedActivity = (<activityActions.DeleteActivityOk>action).payload;
            const newActivityArray = getNewActivityArray(state);
            newActivityArray.filter(activity => activity.id !== deletedActivity.id);
            return newActivityArray;
        }
        case activityActions.CREATE_OPTION_OK:
        {
            const createdOption = (<activityActions.CreateOptionOk>action).payload;
            const newActivityArray = getNewActivityArray(state);
            const index = newActivityArray.findIndex(activity => activity.id === createdOption.activityId);
            if (index >= 0) {
                newActivityArray[index].options.push(createdOption);
            } else {
                console.error('eventActivities.reducer: Activity not found when creating option', createdOption, newActivityArray);
            }
            return newActivityArray;
        }
        case activityActions.UPDATE_OPTION_OK:
        {
            const updatedOption = (<activityActions.UpdateOptionOk>action).payload;
            const newActivityArray = getNewActivityArray(state);
            const index = newActivityArray.findIndex(activity => activity.id === updatedOption.activityId);
            if (index >= 0) {
                const optionsIndex = newActivityArray[index].options.findIndex(option => option.id === updatedOption.id);
                if (optionsIndex >= 0) {
                    newActivityArray[index].options[optionsIndex] = updatedOption;
                } else {
                    console.error('eventActivities.reducer: Option not found when updating option', updatedOption, newActivityArray);
                }
            } else {
                console.error('eventActivities.reducer: Activity not found when updating option', updatedOption, newActivityArray);
            }
            return newActivityArray;
        }
        case activityActions.DELETE_OPTION_OK:
        {
            const deletedOption = (<activityActions.DeleteOptionOk>action).payload;
            const newActivityArray = getNewActivityArray(state);
            const index = newActivityArray.findIndex(activity => activity.id === deletedOption.activityId);
            if (index >= 0) {
                newActivityArray[index].options.filter(option => option.id !== deletedOption.id);
            } else {
                console.error('eventActivities.reducer: Activity not found when deleting option', deletedOption, newActivityArray);
            }
            return newActivityArray;
        }
        case activityActions.CREATE_ACTIVITY_AND_OPTIONS_OK:
        {
            const createdActivity = (<activityActions.CreateActivityAndOptionsOk>action).payload;
            const newActivityArray = getNewActivityArray(state);
            newActivityArray.push(createdActivity);
            return newActivityArray;
        }
        default:
        {
            return state;
        }
    }
}

function getNewActivityArray(state: State): State {
    return [...state]; // returns a new top level object for ngrx to compare against.  Retains same reference to 2nd level and deeper objects.
}
