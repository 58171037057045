import { AfterViewInit, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
var GroupToolsLoaderComponent = /** @class */ (function () {
    function GroupToolsLoaderComponent(store) {
        this.store = store;
    }
    GroupToolsLoaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.store.select(function (state) { return state.loader; })
            .subscribe(function (loader) {
            if (loader.show === true) {
                _this.loaderEl.nativeElement.style['display'] = 'block';
            }
            else {
                _this.loaderEl.nativeElement.style['display'] = 'none';
            }
        });
    };
    return GroupToolsLoaderComponent;
}());
export { GroupToolsLoaderComponent };
