import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phone'})
export class PhonePipe implements PipeTransform {
    transform(phoneNumber: string) {
        let phoneArray: Array<string> = phoneNumber.split('');
        if(phoneArray.length === 10) {
            phoneArray.splice(3,0,'.');
            phoneArray.splice(7,0,'.');
            return phoneArray.join('');
        } else {
            return phoneNumber;
        }
  }
}