import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class NotIEGuard implements CanActivate {
    private deviceInfo = null;

    constructor(
        private router: Router,
        // private deviceService: DeviceDetectorService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.deviceInfo === null) {
            // this.deviceInfo = this.deviceService.getDeviceInfo();
        }

        // if(this.deviceInfo.browser !== 'ie')
            return true;

        this.router.navigate(['/not-supported']);
    }
}