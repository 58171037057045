import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { URLConfig } from 'app/pages/helpers/config.service';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
var EventBuilderService = /** @class */ (function () {
    function EventBuilderService(_http, _urls, store, _fb) {
        this._http = _http;
        this._urls = _urls;
        this.store = store;
        this._fb = _fb;
        this.enticingImg = require('assets/img/enticing_image.png');
        this.basicEventFormFields = {
            IdTenant: [1],
            IdUser: [''],
            PackageName: ['', Validators.required],
            PackageDesc: ['', Validators.required]
        };
        this.eventForm = this._fb.group(this.basicEventFormFields);
    }
    EventBuilderService.prototype.saveEvent = function (eventData) {
        return this._http.post(this._urls._packageInsertUrl(), eventData).pipe(map(function (res) { return res.Obj; }));
    };
    EventBuilderService.prototype.getShowData = function () {
        var _this = this;
        return this._http.get(this._urls._getCatalogShows()).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.map(function (show) {
            if (!show.Details.Logo) {
                show.Details.Logo = _this.enticingImg;
            }
            var newShow = show.Details;
            newShow.catalogItemId = show.catalogItemId;
            return newShow;
        }); }));
    };
    EventBuilderService.prototype.getWorkshopData = function () {
        var _this = this;
        return this._http.get(this._urls._getCatalogWorkshops()).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.map(function (workshop) {
            if (!workshop.Details.Logo) {
                workshop.Details.Logo = _this.enticingImg;
            }
            return Object.assign({
                ApiAccessId: workshop.ApiAccessId,
                ApiItemConfigJson: workshop.ApiItemConfigJson,
                catalogItemId: workshop.catalogItemId,
                catalogItemName: workshop.catalogItemName,
                catalogItemType: workshop.catalogItemType
            }, workshop.Details);
        }); }));
    };
    EventBuilderService.prototype.addShowComponent = function (eventId, catalogId) {
        return this._http.post(this._urls._packageInsertComponent(), this.formatShow(eventId, catalogId));
    };
    EventBuilderService.prototype.addInvoiceShowComponent = function (componentObj) {
        return this._http.post(this._urls._packageInsertComponent(), componentObj);
    };
    EventBuilderService.prototype.formatShow = function (eventId, catalogId) {
        return {
            IdPackage: eventId,
            ComponentSupplier: 1,
            ComponentCategory: 'show',
            catalogItemId: catalogId,
        };
    };
    EventBuilderService.prototype.getInvoiceDetails = function (invoiceNumber) {
        if (invoiceNumber === void 0) { invoiceNumber = 1; }
        return this._http.get(this._urls._packageInvoiceDetails(invoiceNumber)).pipe(map(function (res) { return res.Obj; }), map(function (res) { return res.Invoice; }), map(function (res) {
            var newRes = res;
            newRes.ProductTimeFormatted = new Date("01-01-01 " + res.ProductTime);
            return newRes;
        }), catchError(this.handleError));
    };
    EventBuilderService.prototype.getPotentialPackagesForInvoice = function (invoiceNumber) {
        return this._http.get(this._urls._getPotentialPackagesForInvoice(invoiceNumber)).pipe(map(function (res) { return res.Obj; }));
    };
    EventBuilderService.prototype.applyInvoiceToEvent = function (formattedData) {
        return this._http.post(this._urls._applyInvoiceToEvent(), formattedData);
    };
    EventBuilderService.prototype.handleError = function (error) {
        var errMsg;
        if (error instanceof Response) {
            var body = error || '';
            var err = body || JSON.stringify(body);
            errMsg = error.status + " - " + (error.statusText || '') + " " + err;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        //console.error(errMsg);
        return observableThrowError(errMsg);
    };
    return EventBuilderService;
}());
export { EventBuilderService };
