import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportGoogleContactsComponent } from 'app/pages/social-components/google/import-google-contacts/import-google-contacts.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImportGoogleContactsComponent
    ],
    exports: [
        ImportGoogleContactsComponent
    ]
})
export class SocialComponentsModule {}