import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[groupToolsPhone]'
})
export class GrouptoolsPhoneDirective {

  public allowedCharacters: string = '0123456789';
  private justDigits: string;
  constructor(
    private element: ElementRef,
  ) { }

  @HostListener('keyup', ['$event']) keyup(event: KeyboardEvent) {
    if (!this.allowedCharacters.includes(event.key)
        || this.justDigits.length === 10) {
      // 10 characters is the max length for our phone numbers
      event.preventDefault();
    } else {
      this.processInput();
    }
  }
  @HostListener('keypress', ['$event']) keypress(event: KeyboardEvent) {
    if (!this.allowedCharacters.includes(event.key)
        || this.justDigits.length === 10) {
      event.preventDefault();
    } else {
      this.processInput();
    }
  }
  @HostListener('input') input() {
    this.processInput();
  }
  @HostListener('blur') blur() {
    this.processInput();
  }

  public processInput() {
    let justDigits = '';
    // only grab characters that are in our digit list
    for (let i = 0; i < this.element.nativeElement.value.length; i++) {
      if (this.allowedCharacters.includes(this.element.nativeElement.value[i])) {
        justDigits += this.element.nativeElement.value[i];
      }
    }

    if (this.justDigits === justDigits) {
      return; // don't process if we already did this one
    }

    // add hyphens after 3 and 6 characters
    let processed: string;
    if (justDigits.length >= 6) {
      processed = `${justDigits.substring(0, 3)}-${justDigits.substring(3, 6)}-${justDigits.substring(6, justDigits.length)}`;
    } else if (justDigits.length >= 3) {
      processed = `${justDigits.substring(0, 3)}-${justDigits.substring(3, justDigits.length)}`;
    } else {
      processed = justDigits;
    }

    // update the field
    this.element.nativeElement.value = processed;
    this.justDigits = justDigits;
    // directive occurs after change detection, so we need to dispatch a new
    // event to re-trigger change detection for form validation
    this.element.nativeElement.dispatchEvent(new Event('input'));
  }
}
