import { NgxConfigureService } from 'ngx-configure';
var URLConfig = /** @class */ (function () {
    function URLConfig(configService) {
        this.configService = configService;
    }
    Object.defineProperty(URLConfig.prototype, "apiURL", {
        get: function () { return this.configService.config.apiUrl; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(URLConfig.prototype, "apiKey", {
        get: function () { return this.configService.config.authVerifyToken; },
        enumerable: true,
        configurable: true
    });
    // Authorization / Authentication
    URLConfig.prototype._emailurl = function () { return this.apiURL + ("api/Auth/UniqueEmail?apiKey=" + this.apiKey + "&email="); };
    URLConfig.prototype._getUser = function () { return this.apiURL + 'api/user/'; };
    URLConfig.prototype._updateUser = function () { return this.apiURL + 'api/user/'; };
    URLConfig.prototype._contactRegisterUrl = function () { return this.apiURL + 'api/Auth/Register?'; };
    URLConfig.prototype._updateProfile = function () { return this.apiURL + 'api/Auth/Updateprofile'; };
    URLConfig.prototype._loginurl = function () { return this.apiURL + 'api/Auth/Login?'; };
    URLConfig.prototype._passwordchangeurl = function () { return this.apiURL + 'api/Auth/ChangeCurrentUserPassword?'; };
    URLConfig.prototype._paymentinfoaddurl = function () { return this.apiURL + 'api/Auth/AddPaymentInfo?'; };
    URLConfig.prototype._paymentinfogeturl = function () { return this.apiURL + 'api/Auth/GetPaymentInfo?'; };
    URLConfig.prototype._verifytokenurl = function () { return this.apiURL + 'api/Auth/VerifyToken/'; };
    URLConfig.prototype._verifyuseremailtokenurl = function () { return this.apiURL + 'api/auth/verifyuseremailtoken/'; };
    URLConfig.prototype._tokenUrl = function () { return this.apiURL + 'token'; };
    URLConfig.prototype._userinfourl = function () { return this.apiURL + 'api/Auth/GetCurrentUserInfo?'; };
    URLConfig.prototype._isregistereduserurl = function () { return this.apiURL + 'api/Auth/IsRegisteredUser'; };
    URLConfig.prototype._forgotpass = function (user) { return this.apiURL + ("api/Auth/ForgotPassword/" + user + "/?apiKey=" + this.apiKey); };
    URLConfig.prototype._requestNewRole = function () { return this.apiURL + "api/Auth/RequestNewRole"; };
    URLConfig.prototype._registerViaGoogle = function () { return this.apiURL + 'api/Auth/RegisterViaGoogle'; };
    URLConfig.prototype._registerViaAmazon = function () { return this.apiURL + 'api/Auth/RegisterViaAmazon'; };
    URLConfig.prototype._registerViaFacebook = function () { return this.apiURL + 'api/Auth/RegisterViaFacebook'; };
    URLConfig.prototype._getAmazonTokenFromCode = function (code) { return this.apiURL + ("api/Auth/AmazonAccessToken/" + code + "/?apiKey=" + this.apiKey); };
    URLConfig.prototype._getFacebookTokenFromCode = function (code) { return this.apiURL + ("api/Auth/FacebookAccessToken/" + code + "/?apiKey=" + this.apiKey); };
    URLConfig.prototype._createWePayAccount = function () { return this.apiURL + 'api/WePay/CreateAccount'; };
    URLConfig.prototype._removeWePayAccount = function () { return this.apiURL + 'api/WePay/RemoveAccount'; };
    URLConfig.prototype._createStripeAccount = function () { return this.apiURL + 'api/Stripe/CreateAccount'; };
    URLConfig.prototype._removeStripeAccount = function () { return this.apiURL + 'api/Stripe/RemoveAccount'; };
    URLConfig.prototype._generatePayPalSignUpUrl = function () { return this.apiURL + 'api/PayPal/GenerateSignUpUrl'; };
    URLConfig.prototype._associatePayPalAccount = function () { return this.apiURL + 'api/PayPal/AssociateAccount'; };
    URLConfig.prototype._removePayPalAccount = function () { return this.apiURL + 'api/PayPal/RemoveAccount'; };
    URLConfig.prototype._getLeaderPayPalMerchantId = function () { return this.apiURL + 'api/PayPal/GetLeaderMerchantId'; };
    URLConfig.prototype._userHasWePayAccount = function (userId) { return this.apiURL + ("api/Auth/HasWePayAccount/" + userId); };
    URLConfig.prototype._sendVerifyEmail = function () { return this.apiURL + 'api/Auth/SendVerifyEmail'; };
    URLConfig.prototype._verifySecurityToken = function () { return this.apiURL + 'api/Auth/VerifySecurityToken'; };
    URLConfig.prototype._resetPassword = function () { return this.apiURL + 'api/Auth/ResetPassword'; };
    URLConfig.prototype._sendVerifyCode = function () { return this.apiURL + 'api/Auth/SendVerificationCode'; };
    URLConfig.prototype._validateVerificationCode = function () { return this.apiURL + 'api/Auth/ValidateVerificationCode'; };
    URLConfig.prototype._getVerificationCodeExpiration = function () { return this.apiURL + 'api/Auth/GetVerificationCodeExpiration'; };
    // Configuration
    URLConfig.prototype._getConfiguration = function () { return this.apiURL + 'api/config/GetConfig'; };
    // Contacts
    URLConfig.prototype._contactsUrl = function () { return this.apiURL + 'API/ContactController/Get'; };
    URLConfig.prototype._insertContactUrl = function () { return this.apiURL + 'API/ContactController/Insert'; };
    URLConfig.prototype._insertContactsUrl = function () { return this.apiURL + 'API/ContactController/InsertMany'; };
    URLConfig.prototype._removeContactUrl = function () { return this.apiURL + 'API/ContactController/Remove'; };
    URLConfig.prototype._removeContactsUrl = function () { return this.apiURL + 'API/ContactController/RemoveMany'; };
    URLConfig.prototype._inviteContactUrl = function () { return this.apiURL + 'API/ContactController/Invite'; };
    URLConfig.prototype._inviteContactsUrl = function () { return this.apiURL + 'API/ContactController/InviteMany'; };
    URLConfig.prototype._labelContactUrl = function () { return this.apiURL + 'API/ContactController/Label'; };
    URLConfig.prototype._labelContactsUrl = function () { return this.apiURL + 'API/ContactController/LabelMany'; };
    URLConfig.prototype._contactDashboardUrl = function () { return this.apiURL + 'API/ContactController/Dashboard'; };
    URLConfig.prototype._importFromCsv = function () { return this.apiURL + 'API/ContactController/ImportFromCsv'; }; // KC: Nothing in dashboard references this, so its not used
    URLConfig.prototype._processCsv = function () { return this.apiURL + 'API/ContactController/ProcessCsv'; }; // KC: Not Used (no endpoint on api)
    URLConfig.prototype._getContactsFromCsv = function () { return this.apiURL + 'API/ContactController/GetContactsFromCsv?'; }; // i think this is used...
    URLConfig.prototype._getGoogleContacts = function () { return this.apiURL + 'API/ContactController/GetGoogleContacts'; };
    URLConfig.prototype._getFacebookContacts = function () { return this.apiURL + 'API/ContactController/GetFacebookContacts'; };
    URLConfig.prototype._getOutlookContacts = function () { return this.apiURL + 'API/ContactController/GetOutlookContacts'; };
    URLConfig.prototype._getContactsForPackage = function () { return this.apiURL + 'API/ContactController/GetContactsForPackage/'; };
    URLConfig.prototype._getGroupLeaderContacts = function (eventId) { return this.apiURL + ("API/Events/" + eventId + "/groupleader/contacts"); };
    URLConfig.prototype._insertContactUrlForUser = function (userId) { return this.apiURL + ("API/ContactController/user/" + userId + "/Insert"); };
    URLConfig.prototype._insertManyContactsUrlForUser = function (userId) { return this.apiURL + ("API/ContactController/user/" + userId + "/InsertMany"); };
    URLConfig.prototype._importFromCsvForUser = function (userId) { return this.apiURL + ("API/ContactController/user/" + userId + "/ImportFromCsv"); }; // KC: Nothing in dashboard references this, so its not used
    // Extraneous
    URLConfig.prototype._errorMsgLoggingUrl = function () { return this.apiURL + 'Api/Log/LogErrorMessage/'; };
    URLConfig.prototype._errorLoggingUrl = function () { return this.apiURL + 'Api/Log/LogError'; };
    URLConfig.prototype._pictureuploadurl = function () { return this.apiURL + 'api/Pic/UploadPicture?'; };
    URLConfig.prototype._pdfuploadurl = function (categoryType) { return this.apiURL + ("api/Media/Insert/" + categoryType); };
    URLConfig.prototype._attachFilesToForm = function () { return this.apiURL + 'api/Media/attachFilesToForm'; };
    URLConfig.prototype._getMediaFiles = function (categoryType) { return this.apiURL + ("api/Media/Get/" + categoryType); };
    URLConfig.prototype._picturegeturl = function () { return this.apiURL + 'api/Pic/GetPicture?'; };
    URLConfig.prototype._ordersUrl = function () { return this.apiURL + 'api/orders'; };
    URLConfig.prototype._deliverForms = function (formId) { return this.apiURL + ("api/form/deliver/" + formId); };
    // Payments
    // public _paymentGetUrl() { return this.apiURL + 'api/payments/get/'; }
    URLConfig.prototype._paymentGetTypes = function () { return this.apiURL + 'api/payments/paymentTypes/'; };
    URLConfig.prototype._paymentRefund = function () { return this.apiURL + 'api/payments/refund/'; };
    URLConfig.prototype._paymentDistributionGetUrl = function () { return this.apiURL + 'api/payments/distribution/get/'; };
    URLConfig.prototype._paymentDistributionGetByPackage = function (packageId) { return this.apiURL + ("api/payments/Distribution/Package/" + packageId); };
    URLConfig.prototype._paymentDistributionInsertMany = function () { return this.apiURL + 'api/payments/distribution/InsertMany'; };
    URLConfig.prototype._paymentDistributionAutoUrl = function () { return this.apiURL + 'api/payments/distribution/auto'; };
    URLConfig.prototype._insertAttendeePayment = function () { return this.apiURL + 'api/payments/InsertAttendeePayment'; };
    URLConfig.prototype._getSuppliersForPackage = function () { return this.apiURL + 'api/supplier/GetSuppliersForPackage/'; };
    URLConfig.prototype._insertSupplierPayment = function () { return this.apiURL + 'api/payments/InsertSupplierPayment'; };
    URLConfig.prototype._getSuppliers = function () { return this.apiURL + 'api/Supplier/'; };
    URLConfig.prototype._paymentsGetUrlOld = function (packageId) { return this.apiURL + ("api/Payments/Get/" + packageId); };
    URLConfig.prototype._paymentsGetUrl = function (eventId) { return this.apiURL + ("api/Payments/" + eventId); };
    URLConfig.prototype._paymentsFeatureSummaryUrl = function (packageId, componentId) {
        return this.apiURL +
            ("api/Packages/" + packageId + "/Component/" + componentId + "/PaymentsSummary");
    };
    URLConfig.prototype._makePayment = function () { return this.apiURL + 'api/payments/MakePayment'; };
    URLConfig.prototype._deletePayment = function (IdPayment) { return this.apiURL + ("api/payments/Delete/" + IdPayment); };
    URLConfig.prototype._paymentStatusUpdate = function () { return this.apiURL + 'api/payments/UpdateStatus'; };
    URLConfig.prototype._createWePayCheckout = function (paymentId) { return this.apiURL + ("api/WePay/Checkout/" + paymentId); };
    URLConfig.prototype._createStripeCheckout = function (paymentId) { return this.apiURL + ("api/Stripe/Checkout/" + paymentId); };
    URLConfig.prototype._getPaymentMethod = function (id) { return this.apiURL + ("api/Payments/GetPaymentMethod/" + id); };
    URLConfig.prototype._paymentSetAlternatePaymentMethod = function () { return this.apiURL + 'api/Payments/SetAlternatePaymentMethod'; };
    URLConfig.prototype._paymentGetAlternatePaymentMethod = function (id) { return this.apiURL + ("api/Payments/GetAlternatePaymentMethod/" + id); };
    // Supplier Payments
    URLConfig.prototype._addAndAssociateCustomerUrl = function () { return this.apiURL + "api/SupplierPayment/AddAndAssociateCustomer"; };
    URLConfig.prototype._supplierMakePaymentUrl = function () { return this.apiURL + "api/SupplierPayment/MakePayment"; };
    URLConfig.prototype._supplierMakeGLPaymentUrl = function () { return this.apiURL + "api/SupplierPayment/MakeSupplierGLPayment"; };
    /**
     * PackageReturns
     *
     * @returns
     *
     * @memberOf URLConfig
     */
    // BI DIRECT
    URLConfig.prototype._packageInvoiceDetails = function (invoiceNumber) { return this.apiURL + ("api/invoice/getinvoice/" + invoiceNumber); };
    URLConfig.prototype._getPotentialPackagesForInvoice = function (invoiceNumber) { return this.apiURL + ("API/Packages/GetPotentialPackagesForInvoice/" + invoiceNumber); };
    URLConfig.prototype._applyInvoiceToEvent = function () { return this.apiURL + 'api/packages/component/add'; };
    // EVENTS
    URLConfig.prototype._packageInsertUrl = function () { return this.apiURL + 'api/Packages/Insert?'; };
    URLConfig.prototype._packageUpdateUrl = function () { return this.apiURL + 'api/Packages/Update?'; };
    URLConfig.prototype._packageGetUrl = function () { return this.apiURL + 'api/Packages/Get'; };
    URLConfig.prototype._packagesOwnedUrl = function () { return this.apiURL + 'api/Packages/GetOwnedPackages'; };
    URLConfig.prototype._packageMyPackagesUrl = function () { return this.apiURL + 'api/Packages/MyPackages'; };
    URLConfig.prototype._packageTemplateUrl = function () { return this.apiURL + 'api/Packages/GetPackageTemplate'; };
    URLConfig.prototype._packageLandingDetails = function () { return this.apiURL + 'api/Packages/LandingDetails'; };
    URLConfig.prototype._packageGetByInvite = function () { return this.apiURL + 'api/Packages/GetByInvite'; };
    URLConfig.prototype._packageInvite = function () { return this.apiURL + 'api/Packages/Invite'; };
    URLConfig.prototype._packageInviteResponse = function () { return this.apiURL + 'api/Packages/InviteResponse'; };
    URLConfig.prototype._packageUpdateResponse = function () { return this.apiURL + 'Api/Events/UpdateResponse'; };
    URLConfig.prototype._packageInsertComponent = function () { return this.apiURL + 'api/packages/component/insert'; };
    URLConfig.prototype._addComponentOption = function () { return this.apiURL + 'Api/Events/Component/Option/Add'; };
    URLConfig.prototype._addComponentOptionWithDefaults = function () { return this.apiURL + 'Api/Packages/Component/Option/AddWithDefaults'; };
    URLConfig.prototype._packageRemoveComponent = function () { return this.apiURL + 'api/packages/component/remove'; };
    URLConfig.prototype._packageAttendeeFeature = function (IdPackageComponent) { return this.apiURL + ("api/Packages/Component/" + IdPackageComponent + "/Choose"); };
    URLConfig.prototype._packageAttendeeFeatureOptionChoose = function (IdPackage, IdPackageComponent, IdPackageComponentOption) { return this.apiURL + ("api/Events/" + IdPackage + "/Component/Option/" + IdPackageComponent + "/" + IdPackageComponentOption + "/Choose"); };
    URLConfig.prototype._packageGetComponentStatus = function (IdPackage) { return this.apiURL + ("api/Packages/Component/" + IdPackage + "/Status"); };
    URLConfig.prototype._packageGetByID = function (packageId) { return this.apiURL + ("api/Packages/GetByPid/" + packageId); };
    URLConfig.prototype._packageShortUrl = function (packageId) { return this.apiURL + ("api/Packages/ShortUrl/" + packageId); };
    URLConfig.prototype._packageInfoByID = function (packageId) { return this.apiURL + ("api/Packages/PackageInfo/" + packageId); };
    URLConfig.prototype._packageDetails = function (packageId) { return this.apiURL + ("api/Packages/Details/" + packageId); };
    URLConfig.prototype._packageComponentsGetByID = function (packageId) { return this.apiURL + ("api/Packages/GetComponentsByPid/" + packageId); };
    URLConfig.prototype._packageAttendeeSummary = function (packageId) { return this.apiURL + ("api/Packages/Attendees/Summary/" + packageId); };
    // TODO: The below endpont _packageAttendeeDetails is deprecated. Refactor out. instead use _getAttendees
    URLConfig.prototype._packageAttendeeDetails = function (packageId) { return this.apiURL + ("api/Packages/Attendees/" + packageId); };
    URLConfig.prototype._paymentsReceivedSummary = function (packageId) { return this.apiURL + ("api/Packages/" + packageId + "/Payments/Received"); };
    URLConfig.prototype._paymentsSentSummary = function (packageId) { return this.apiURL + ("api/Packages/" + packageId + "/Payments/Sent"); };
    URLConfig.prototype._paymentsSentSummaryForAttendee = function (packageId) { return this.apiURL + ("api/Packages/" + packageId + "/Attendee/Payments/Sent"); };
    URLConfig.prototype._getMySurveyEvents = function () { return this.apiURL + 'API/packages/GetPackagesOnlySurvey'; };
    URLConfig.prototype._getSurveyAttendees = function () { return this.apiURL + 'API/packages/GetSurveyEventAttendee'; };
    URLConfig.prototype._packageItineraryRecord = function (IdPackage) { return this.apiURL + ("API/packages/ItineraryRecord/" + IdPackage); };
    URLConfig.prototype._createEventFromBibOrder = function () { return this.apiURL + "api/events/CreateEventFromBibOrder"; };
    URLConfig.prototype._createEventFromSupplierOrder = function () { return this.apiURL + "api/events/CreateEventFromSupplierOrder"; };
    URLConfig.prototype._updateEventFromSupplierOrder = function () { return this.apiURL + "api/events/UpdateEventFromSupplierOrder"; };
    URLConfig.prototype._loadTickets = function () { return this.apiURL + "api/events/GetTickets"; };
    URLConfig.prototype._purchaseTickets = function () { return this.apiURL + "api/events/PurchaseTickets"; };
    URLConfig.prototype._getSeatMapImage = function () { return this.apiURL + "api/events/GetSeatMapImage"; };
    URLConfig.prototype._recordSupplierAttendeePayment = function () { return this.apiURL + "api/events/RecordSupplierAttendeePayment"; };
    URLConfig.prototype._getAlternateGroupLeaderInfo = function () { return this.apiURL + "api/events/GetAlternateGroupLeaderInfo"; };
    URLConfig.prototype._inviteEventAttendees = function () { return this.apiURL + "api/events/invite"; };
    URLConfig.prototype._markPublicUrlAsShared = function (eventId) { return this.apiURL + ("api/Events/MarkPublicUrlAsShared/" + eventId); };
    URLConfig.prototype._packageDetailsForAttendee = function (packageId) { return this.apiURL + ("api/Packages/Details/attendee/" + packageId); };
    URLConfig.prototype._packagePaymentsForAttendee = function (packageId) { return this.apiURL + ("api/Payments/Package/" + packageId); };
    URLConfig.prototype._packageAttendeeHasGuests = function (packageId) { return this.apiURL + ("api/PlusOnes/HasGuests/" + packageId); };
    URLConfig.prototype._packageUpdatePackageDetails = function () { return this.apiURL + 'api/Packages/UpdatePackageDetails'; };
    URLConfig.prototype._updateComponentDetails = function () { return this.apiURL + 'api/packages/UpdateComponentDetails'; };
    URLConfig.prototype._updateComponentOption = function () { return this.apiURL + 'Api/Packages/Component/Options/Update'; };
    URLConfig.prototype._getComponentOptions = function (IdPackage, IdComponent) { return this.apiURL + ("Api/Packages/" + IdPackage + "/GetComponentOptions/" + IdComponent); };
    URLConfig.prototype._packageRemoveComponentOption = function () { return this.apiURL + 'api/packages/Component/Options/Remove'; };
    URLConfig.prototype._tagsInfo = function (recordId, record) {
        return this.apiURL + ("api/Packages/" + record + "/" + recordId + "/Tags");
    };
    URLConfig.prototype._insertTagsInfo = function () { return this.apiURL + 'api/packages/InsertTags'; };
    URLConfig.prototype._deleteTagsInfo = function () { return this.apiURL + 'api/packages/DeleteTags'; };
    URLConfig.prototype._getContactTagsByOwner = function () { return this.apiURL + 'api/packages/GetContactsTagsByOwner'; };
    URLConfig.prototype._uploadPackageImage = function (packageId) { return this.apiURL + ("api/Packages/UploadPackagePicture/" + packageId); };
    URLConfig.prototype._getPackageImage = function (packageId) { return this.apiURL + ("api/Packages/GetPicture/" + packageId); };
    URLConfig.prototype._cancelEvent = function (id) { return this.apiURL + ("api/packages/cancel/" + id); };
    URLConfig.prototype._canCancelEvent = function (id) { return this.apiURL + ("api/packages/canCancel/" + id); };
    URLConfig.prototype._getPackageLogs = function (id) { return this.apiURL + ("api/packages/changes/" + id); };
    URLConfig.prototype._packageRecord = function (packageId) { return this.apiURL + ("api/Packages/Record/" + packageId); };
    URLConfig.prototype._packageUpdateComponentAttendeeNotesJson = function () { return this.apiURL + 'api/packages/UpdateComponentAttendeeNotesJson'; };
    URLConfig.prototype._packageRemovePlusOnes = function (IdPackage) { return this.apiURL + ("api/Packages/DeletePlusOnes/" + IdPackage); };
    URLConfig.prototype._packageResendInvitations = function () { return this.apiURL + 'api/Packages/ResendInvitations'; };
    // events refactor
    URLConfig.prototype._getOwnedEvents = function () { return this.apiURL + "api/events/owned"; };
    URLConfig.prototype._getTransferredEvents = function () { return this.apiURL + "api/events/transferred"; };
    URLConfig.prototype._getInvitedEvents = function () { return this.apiURL + "api/events/invited"; };
    URLConfig.prototype._getEvent = function (eventId) { return this.apiURL + ("api/events/" + eventId); };
    URLConfig.prototype._getEventOverview = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/overview"); };
    URLConfig.prototype._getLeaderEventSummary = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/summary"); };
    URLConfig.prototype._getGroupLeaderProfile = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/groupLeader"); };
    URLConfig.prototype._getAlternateLeaders = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/alternateLeaders"); };
    URLConfig.prototype._updateEvent = function (eventId) { return this.apiURL + ("api/events/" + eventId); };
    URLConfig.prototype._requestShortUrl = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/shorturl"); };
    URLConfig.prototype._makeTemplate = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/makeTemplate"); };
    URLConfig.prototype._cloneEvent = function (templateId) { return this.apiURL + ("api/events/Templates/" + templateId + "/CreateEvent"); };
    URLConfig.prototype._assignEvent = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/Assign"); };
    URLConfig.prototype._cloneAndAssignEvent = function (templateId) { return this.apiURL + ("api/events/Templates/" + templateId + "/CreateEventForUser"); };
    URLConfig.prototype._getActivities = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/activities"); };
    URLConfig.prototype._createActivity = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/activity"); };
    URLConfig.prototype._updateActivity = function (eventId, activityId) { return this.apiURL + ("api/events/" + eventId + "/activity/" + activityId); };
    URLConfig.prototype._deleteActivity = function (eventId, activityId) { return this.apiURL + ("api/events/" + eventId + "/activity/" + activityId); };
    URLConfig.prototype._createOption = function (eventId, activityId) { return this.apiURL + ("api/events/" + eventId + "/activity/" + activityId + "/option"); };
    URLConfig.prototype._updateOption = function (eventId, activityId, optionId) { return this.apiURL + ("api/events/" + eventId + "/activity/" + activityId + "/option/" + optionId); };
    URLConfig.prototype._deleteOption = function (eventId, activityId, optionId) { return this.apiURL + ("api/events/" + eventId + "/activity/" + activityId + "/option/" + optionId); };
    URLConfig.prototype._getAttendees = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/attendees"); };
    URLConfig.prototype._changeAttendeeEmails = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/attendees/changeEmails"); };
    URLConfig.prototype._addAlternateLeader = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/alternateLeader"); };
    // Event Item Manager Endpoints
    // Event Item Manager Endpoints
    URLConfig.prototype._getShowList = function () { return this.apiURL + ("api/eim/ShowList/?apiKey=" + this.apiKey); };
    URLConfig.prototype._getEventAndActivity = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/EventAndActivity"); };
    URLConfig.prototype._getEventItems = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/EventItems/"); };
    URLConfig.prototype._assignAttendeesToItems = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/EventItems/UpdateAssignments"); };
    URLConfig.prototype._assignAttendeesToItemsAutomatically = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/EventItems/AssignItemsAutomatically"); };
    URLConfig.prototype._getAttendeesEim = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/attendees"); };
    URLConfig.prototype._upsertAttendees = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/attendees"); };
    URLConfig.prototype._publicEventAddAttendees = function (eventId) { return this.apiURL + ("api/events/" + eventId + "/AddAttendees"); };
    URLConfig.prototype._deleteAttendee = function (eventId, eventAttendeeId) { return this.apiURL + ("api/eim/" + eventId + "/deleteattendee/" + eventAttendeeId); };
    URLConfig.prototype._downloadTickets = function () { return this.apiURL + "api/eim/DownloadTickets"; };
    URLConfig.prototype._downloadAllTickets = function () { return this.apiURL + "api/eim/DownloadAllTickets"; };
    URLConfig.prototype._downloadManifest = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/DownloadManifest"); };
    URLConfig.prototype._parseAttendeesFromCsv = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/GetAttendeesFromCsvText"); };
    URLConfig.prototype._parseAttendeesFromCsvInJson = function (eventId) { return this.apiURL + ("api/eim/" + eventId + "/GetAttendeesFromCsvInJson"); };
    // Messages Endpoints
    URLConfig.prototype._notifyAttendeesTicketsReady = function (eventId) { return this.apiURL + ("api/messages/" + eventId + "/NotifyAttendeesTicketsReady"); };
    // Auth Endpoints
    URLConfig.prototype._orderNumberLogin = function () { return this.apiURL + ("api/eim/OrderNumberLogin/?apiKey=" + this.apiKey); };
    URLConfig.prototype._bibTokenLogin = function () { return this.apiURL + ("api/eim/TokenLogin/?apiKey=" + this.apiKey); };
    // public _tokenUrl() { return this.apiURL + 'token'; }
    URLConfig.prototype._deauthorizedBearerToken = function () { return this.apiURL + 'api/eim/DeauthorizeBearerToken'; };
    // Forms
    URLConfig.prototype._getFormsForPackage = function (packageId) { return this.apiURL + ("api/Form/GetFormsForPackage/" + packageId); };
    URLConfig.prototype._createForm = function () { return this.apiURL + 'api/Form/CreateForm'; };
    URLConfig.prototype._getForm = function (formId) { return this.apiURL + ("api/Form/GetForm/" + formId); };
    URLConfig.prototype._updateForm = function () { return this.apiURL + 'api/Form/UpdateForm'; };
    URLConfig.prototype._respondToForm = function (formId) { return this.apiURL + ("api/Form/Respond/" + formId); };
    URLConfig.prototype._getOwnedForms = function () { return this.apiURL + "api/form/getownedforms"; };
    // Messages
    URLConfig.prototype._getMessagesUrl = function (eventId) { return this.apiURL + ("something/" + eventId); }; // TODO: finalize endpoint
    URLConfig.prototype._messages = function () { return this.apiURL + 'api/messages/'; };
    URLConfig.prototype._getSentMessages = function () { return this.apiURL + 'api/messages/GetSentMessages'; };
    URLConfig.prototype._messagesSummary = function () { return this.apiURL + 'api/messages/summary'; };
    URLConfig.prototype._isGroupLeader = function () { return this.apiURL + 'api/Auth/IsGroupLeader'; };
    URLConfig.prototype._hasWePayAccount = function () { return this.apiURL + 'api/Auth/HasWePayAccount'; };
    URLConfig.prototype._sendMessages = function () { return this.apiURL + 'api/Messages/Send'; };
    URLConfig.prototype._sendPackageMessages = function () { return this.apiURL + 'api/Messages/Package/Send'; };
    URLConfig.prototype._markMessageAsRead = function (id) { return this.apiURL + ("api/Messages/MarkAsRead/" + id); };
    URLConfig.prototype._deleteMessages = function () { return this.apiURL + 'api/Messages/Delete'; };
    URLConfig.prototype._actions = function () { return this.apiURL + 'api/actions/'; };
    URLConfig.prototype._markActionAsRead = function () { return this.apiURL + "api/Actions/Dismiss/"; };
    URLConfig.prototype._dismissAllSurveyActions = function (surveyId) { return this.apiURL + ("api/Actions/Dismiss/Survey/" + surveyId); };
    URLConfig.prototype._userMenu = function (role) {
        // return this.apiURL+`api/Auth/MenuItems`; // Not implemented yet.
        return "./assets/" + role + "-menu.json";
    };
    //Public page request for invite.
    URLConfig.prototype._registerUserNoPassword = function () { return this.apiURL + 'api/Messages/SendPublicMessage'; };
    //Plus Ones
    URLConfig.prototype._plusOneRelationships = function () { return this.apiURL + 'api/plusones/relationshiptypes'; };
    URLConfig.prototype._addPlusOnes = function () { return this.apiURL + 'api/plusones/add'; };
    // SURVEYS
    URLConfig.prototype._getSurveysNew = function (eventId) { return this.apiURL + ("something/" + eventId); }; //TODO: finalize endpoint
    URLConfig.prototype._getSurveys = function (packageId) { return this.apiURL + ("api/survey/GetSurveysForPackage/" + packageId); };
    URLConfig.prototype._getSurveyDetails = function (surveyId) { return this.apiURL + ("API/Survey/GetSurvey/" + surveyId); };
    URLConfig.prototype._hasResponded = function (surveyId) { return this.apiURL + ("API/Survey/HasResponded/" + surveyId); };
    URLConfig.prototype._createSurveyForPackage = function () { return this.apiURL + 'api/Survey/CreateSurveyForPackage'; };
    URLConfig.prototype._answerSurvey = function () { return this.apiURL + 'api/Survey/AnswerSurvey'; };
    ;
    URLConfig.prototype._surveyGetByInvite = function () { return this.apiURL + 'api/Survey/GetByInvite'; };
    URLConfig.prototype._getSurveyResponses = function (surveyId) { return this.apiURL + ("API/Survey/GetSurveyResponses/" + surveyId); };
    URLConfig.prototype._surveyInvite = function () { return this.apiURL + 'api/survey/invite'; };
    URLConfig.prototype._updateSurvey = function () { return this.apiURL + 'api/survey/update'; };
    // CATALOG
    URLConfig.prototype._getCatalogShows = function () { return this.apiURL + 'api/Catalog/GetAllCatalogItemsByCatalogTypeName/Show'; };
    ;
    URLConfig.prototype._getCatalogWorkshops = function () { return this.apiURL + 'api/Catalog/GetAllCatalogItemsByCatalogTypeName/Workshop'; };
    ;
    URLConfig.prototype._getSupplierURL = function (supplierName) { return this.apiURL + ("api/Catalog/GetSupplierURL/" + supplierName); };
    URLConfig.prototype._getProvinceState = function () { return this.apiURL + 'Api/Catalog/GetProvinceState'; };
    return URLConfig;
}());
export { URLConfig };
var ClientAuthConfig = /** @class */ (function () {
    function ClientAuthConfig() {
    }
    ClientAuthConfig.prototype.clientId = function () {
        var clientId = '';
        // Yes, this is unnecessary right now. But, it's providing a way to have different IDs per environment.
        // A client has an allowed referrer. The goal is to have different client for local, dev, qa, and prod
        // so we can assign the allowed referrers. Local will be *, meaning allow all, while other environments
        // will be locked down in the future.
        switch (process.env.NODE_ENV) {
            case 'dev':
            case 'development':
                clientId = '1';
                break;
            case 'qa':
                clientId = '1';
                break;
            case 'prod':
            case 'production':
                clientId = '1';
                break;
            case 'local':
            default:
                clientId = '1';
                break;
        }
        return (clientId + '');
    };
    ClientAuthConfig.prototype.clientSecret = function () {
        return '';
    };
    ClientAuthConfig.prototype.basicAuthHeader = function () {
        return ['Basic', btoa([this.clientId(), ':', this.clientSecret()].join(''))].join(' ');
    };
    return ClientAuthConfig;
}());
export { ClientAuthConfig };
