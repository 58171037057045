import { Injectable } from '@angular/core';
import { NgxConfigureService } from 'ngx-configure';
import { SupplierMakePaymentRequest } from 'app/store/models/requests/av-make-payment-request';
import { ConfigService } from 'app/app.configure-options';
var add = require('sinful-math').add;
var sub = require('sinful-math').sub;
var mul = require('sinful-math').mul;
var div = require('sinful-math').div;

@Injectable()
export class PayPalHelper {

  constructor(private _configService: ConfigService) {}

  public calculatePayPalFee(paymentAmount: number, currencyAbbreviation: string): number {
    var percentFee: number;
    var flatFee: number;

    switch (currencyAbbreviation)
    {
        case "USD":
          percentFee = this._configService.config.paypalPercentFeeUS;
          flatFee = this._configService.config.paypalFlatFeeUS;
          break;
        case "GBP":
          percentFee = this._configService.config.paypalPercentFeeUK;
          flatFee = this._configService.config.paypalFlatFeeUK;
          break;
        case "EUR":
          percentFee = this._configService.config.paypalPercentFeeEU;
          flatFee = this._configService.config.paypalFlatFeeEU;
          break;
        default:
          // If we don't have config values for the currency, return 0.00.
          return 0.00;
    }

    // Formula for calculating: (Desired Payment Amount + Flat Fee) / (1 - Percent Fee)
    // Derived from: X - Px - F = Desired Payment Amount
    // P is Percent Fee, F is Flat Fee
    const paymentAmountPlusFee = div((add(paymentAmount, flatFee)), (sub(1,percentFee)));

    const paypalFees = sub(paymentAmountPlusFee, paymentAmount);

    // Return the payment amount with the added fees, rounded to 2 decimal places.
    return Math.round(paypalFees * 10 ** 2) / 10 ** 2;
  }
}

