<div class="grouptools-success">
    <div class="row success-icon">
        <div class="col-md-12">
            <i *ngIf="type === 'success'" class="fa fa-check-circle-o centered icon success"></i>
            <i *ngIf="type === 'error'" class="fa fa-times-circle-o centered icon error"></i>
            <i *ngIf="type === 'pause'" class="fa fa-exclamation-circle centered icon pause"></i>
        </div>
    </div>
    <div class="row success-message">
        <div class="col-md-12">
            <div class="success-container">
                <span class="success-title">{{ successHeading }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <ng-content></ng-content>
        </div>
    </div>
</div>
