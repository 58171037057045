import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var HttpRequestRegexSearch = /** @class */ (function () {
    function HttpRequestRegexSearch(httpMethod, urlFragment, startsAtStartOfUrl, endsAtEndOfUrl) {
        this.httpMethod = httpMethod;
        var regexEncodedUrlFragment = urlFragment.split("/").join("\\/");
        this.rawRegexPattern = "";
        if (startsAtStartOfUrl)
            this.rawRegexPattern = "^";
        this.rawRegexPattern += regexEncodedUrlFragment;
        if (endsAtEndOfUrl)
            this.rawRegexPattern += "$";
    }
    return HttpRequestRegexSearch;
}());
var GtLoaderConfigService = /** @class */ (function () {
    function GtLoaderConfigService() {
        var e_1, _a, e_2, _b;
        // To add more GT Loader Exceptions, simply create a new HttpRequestRegexSearch object in the array below.
        // CAUTION: Only use forward slashes here, NO BACKSLASHES!!
        var gtLoaderExceptions = [
            new HttpRequestRegexSearch('PATCH', '/EventItems/UpdateAssignments', false, true),
            new HttpRequestRegexSearch('GET', '/pages/create-event', false, true),
            new HttpRequestRegexSearch('GET', '/login', false, true)
        ];
        var tempMap = new Map();
        try {
            for (var gtLoaderExceptions_1 = tslib_1.__values(gtLoaderExceptions), gtLoaderExceptions_1_1 = gtLoaderExceptions_1.next(); !gtLoaderExceptions_1_1.done; gtLoaderExceptions_1_1 = gtLoaderExceptions_1.next()) {
                var gtLdrExc = gtLoaderExceptions_1_1.value;
                if (tempMap.has(gtLdrExc.httpMethod))
                    tempMap.set(gtLdrExc.httpMethod, tempMap.get(gtLdrExc.httpMethod) + '|' + gtLdrExc.rawRegexPattern);
                else
                    tempMap.set(gtLdrExc.httpMethod, gtLdrExc.rawRegexPattern);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (gtLoaderExceptions_1_1 && !gtLoaderExceptions_1_1.done && (_a = gtLoaderExceptions_1.return)) _a.call(gtLoaderExceptions_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // It seems like the performance of repeatedly adding to a regex would be poor.
        // I think it would be better to concat the strings first, then form the regex once.
        this.regexHttpMethodMap = new Map();
        try {
            for (var tempMap_1 = tslib_1.__values(tempMap), tempMap_1_1 = tempMap_1.next(); !tempMap_1_1.done; tempMap_1_1 = tempMap_1.next()) {
                var _c = tslib_1.__read(tempMap_1_1.value, 2), key = _c[0], value = _c[1];
                this.regexHttpMethodMap.set(key, new RegExp(value, 'i')); // 'i' means ignore case
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (tempMap_1_1 && !tempMap_1_1.done && (_b = tempMap_1.return)) _b.call(tempMap_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    GtLoaderConfigService.prototype.shouldShowGTLoaderForRequest = function (req) {
        // Reasoning Behind Regular Expression (RegExp) Implementation:
        // This method could be a performance bottleneck, having to perform a substring search for every exclusion on every request.
        // The regexp are cached so the DFA or NFA they use internally only has to be calculated once,
        // then the regexp *should* run in time O(c) where c is the number of characters in the req.url 
        // (I say *should* b/c I haven't empirically tested the performance)
        // Since all url fragments for each httpMethod are grouped into one regexp, the time complexity should remain the same
        // as more gtLoaderExceptions are added, making this a really great long term solution.
        // The reason for the httpMethod map is because two endpoints can have the same name, but with different httpMethods.
        // so the only way to tell the difference is using the httpMethod.
        if (this.regexHttpMethodMap.has(req.method)
            && this.regexHttpMethodMap.get(req.method).test(req.url))
            return false;
        return true;
    };
    GtLoaderConfigService.ngInjectableDef = i0.defineInjectable({ factory: function GtLoaderConfigService_Factory() { return new GtLoaderConfigService(); }, token: GtLoaderConfigService, providedIn: "root" });
    return GtLoaderConfigService;
}());
export { GtLoaderConfigService };
