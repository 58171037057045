// import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { OrderNumberLoginRequest } from 'app/store/models/requests/order-number-login-request';
// import { ShowInfo } from 'app/store/models/show-info';
import { ApiState } from 'app/store/models/apiData';
import { MatDialog } from '@angular/material/dialog';
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }
// function showTitleValidator(showTitleMap: Map<string, ShowInfo>): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (!control.value || typeof control.value !== 'string')
//       return {showTitle: {value: control.value}};
//     const testValue = control.value.toLowerCase().trim();
//     if (!showTitleMap.has(testValue))
//       return {showTitle: {value: testValue}};
//     return null; // null means the control is valid
//   };
// }
var CreateFromBibOrderViewComponent = /** @class */ (function () {
    // private getShowInfoFromTitle(title: string): ShowInfo | null {
    //   const searchTitle = title.toLowerCase().trim();
    //   if (!this.showInfoTitleMap.has(searchTitle)) return null;
    //   return this.showInfoTitleMap.get(searchTitle);
    // }
    function CreateFromBibOrderViewComponent(fb, dialog) {
        this.fb = fb;
        this.dialog = dialog;
        this.isInModal = true;
        this.ApiState = ApiState; // This is so we can use the enum type in the template
        // _showList: ShowInfo[] = [];
        // showInfoTitleMap: Map<string, ShowInfo>;
        // @Input() set showList(value: ShowInfo[]) {
        //   this._showList = value;
        //   this.showInfoTitleMap = new Map<string, ShowInfo>();
        //   for (const showInfo of this._showList) {
        //     this.showInfoTitleMap.set(showInfo.title.toLowerCase().trim(), showInfo);
        //   }
        //   this.buildForm();
        // }
        // @Input() showListApiState: ApiState;
        // filteredShowList$: Observable<ShowInfo[]>;
        // TODO: Could rename authApiState to bibOrderNumberCreateEventApiState or something simpler like apiState or dialogApiState
        this._authApiState = ApiState.Initial;
        // @Output() showSelectedEvent = new EventEmitter<boolean>(); // TODO: solidify which events are needed, and their type
        this.dateSelectedEvent = new EventEmitter();
        this.getStartedEvent = new EventEmitter();
        // public supplierImage = require(`assets/img/shubertlogo.png`);
        this.supplierImage = null;
    }
    Object.defineProperty(CreateFromBibOrderViewComponent.prototype, "shouldPutFormInCard", {
        get: function () { return !this.isInModal; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFromBibOrderViewComponent.prototype, "authApiState", {
        get: function () { return this._authApiState; },
        set: function (value) {
            if (this._authApiState === ApiState.InFlight && value === ApiState.Error) {
                // Then the error dialog should show, because an error just happened
                this.showLoginErrorDialog();
            }
            this._authApiState = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFromBibOrderViewComponent.prototype, "shouldShowLoader", {
        get: function () {
            // return (this.showListApiState === ApiState.Initial || this.showListApiState === ApiState.InFlight)
            //     || (this.authApiState === ApiState.InFlight);
            return (this.authApiState === ApiState.InFlight);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateFromBibOrderViewComponent.prototype, "shouldShowLoginForm", {
        get: function () {
            return !this.shouldShowLoader;
        },
        enumerable: true,
        configurable: true
    });
    CreateFromBibOrderViewComponent.prototype.buildForm = function () {
        this.form = this.fb.group({
            // autocompleteShow: ['', [Validators.required, showTitleValidator(this.showInfoTitleMap)]],
            date: [null, [Validators.required]],
            orderNumber: [null, [Validators.required]],
        });
        // const autocompleteShowControl = this.form.controls['autocompleteShow'];
        // this.filteredShowList$ = autocompleteShowControl.valueChanges.pipe(
        //   startWith(''),
        //   map (value => typeof value === 'string' ? value : value.title),
        //   map(showInfo => this._filterShowAutocomplete(showInfo))
        // );
    };
    Object.defineProperty(CreateFromBibOrderViewComponent.prototype, "createEventFromBibOrderRequest", {
        // private _filterShowAutocomplete(value: string) {
        //   const filterValue = value.toLowerCase();
        //   return this._showList.filter(showInfo => showInfo.title.toLowerCase().includes(filterValue));
        // }
        get: function () {
            // const autocompleteShowTitle = this.form.get('autocompleteShow').value;// as string
            // const showInfo = this.getShowInfoFromTitle(autocompleteShowTitle);
            // if (showInfo === null) return null;
            var date = new Date(this.form.get('date').value);
            var orderNumber = +this.form.get('orderNumber').value;
            return {
                // starShowCode: showInfo.id,
                perfDate: date,
                bibOrderNumber: orderNumber,
            };
        },
        enumerable: true,
        configurable: true
    });
    CreateFromBibOrderViewComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    // showSelected(event: MatSelectChange) { 
    //   this.showSelectedEvent.emit(true); 
    // }
    CreateFromBibOrderViewComponent.prototype.dateSelected = function (event) {
        this.dateSelectedEvent.emit(true);
    };
    CreateFromBibOrderViewComponent.prototype.getStarted = function () {
        var createEventFromBibOrderRequest = this.createEventFromBibOrderRequest;
        if (this.form.invalid || !createEventFromBibOrderRequest) {
            // this.form.controls['autocompleteShow'].markAsTouched();
            this.form.controls['date'].markAsTouched();
            this.form.controls['orderNumber'].markAsTouched();
            return;
        }
        this.getStartedEvent.emit(createEventFromBibOrderRequest);
    };
    CreateFromBibOrderViewComponent.prototype.loginFormEnterKeyup = function () {
        this.getStarted();
    };
    // ===============================================================================
    //                                 Popup Dialog Logic 
    // ===============================================================================
    CreateFromBibOrderViewComponent.prototype.showLoginErrorDialog = function () {
        // alert("TODO: show login error dialog");
        // const dialogRef: MatDialogRef<LoginErrorDialogComponent, LoginErrorDialogResult> = this.dialog.open(LoginErrorDialogComponent, {
        //   width: '250px',
        //   data: {} as LoginErrorDialogData,
        // });
    };
    return CreateFromBibOrderViewComponent;
}());
export { CreateFromBibOrderViewComponent };
