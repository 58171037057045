import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
export var GTResponseCodes;
(function (GTResponseCodes) {
    GTResponseCodes[GTResponseCodes["Initial"] = -1] = "Initial";
    GTResponseCodes[GTResponseCodes["Success"] = 0] = "Success";
    GTResponseCodes[GTResponseCodes["BadRequest"] = 1] = "BadRequest";
    GTResponseCodes[GTResponseCodes["Unauthorized"] = 2] = "Unauthorized";
    GTResponseCodes[GTResponseCodes["ServerError"] = 3] = "ServerError";
    GTResponseCodes[GTResponseCodes["TooEarlyToGenerateTickets"] = 4] = "TooEarlyToGenerateTickets";
})(GTResponseCodes || (GTResponseCodes = {}));
var GTResponseError = /** @class */ (function () {
    function GTResponseError(gtResponse) {
        this.code = gtResponse.Code;
        this.message = GTResponseHelper.getErrorMessage(gtResponse);
        this._error = new Error(this.message);
    }
    Object.defineProperty(GTResponseError.prototype, "name", {
        get: function () { return this._error.name; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GTResponseError.prototype, "stack", {
        get: function () { return this._error.stack; },
        enumerable: true,
        configurable: true
    });
    GTResponseError.prototype.toString = function () { return this._error.toString(); };
    return GTResponseError;
}());
export { GTResponseError };
var GTResponseHelper = /** @class */ (function () {
    function GTResponseHelper() {
    }
    GTResponseHelper.isSuccessful = function (gtResponse) {
        return gtResponse.Result;
    };
    GTResponseHelper.hasError = function (gtResponse) {
        return !gtResponse || !gtResponse.Result;
    };
    GTResponseHelper.getPayload = function (gtResponse) {
        return gtResponse.Payload;
    };
    GTResponseHelper.getErrorMessage = function (gtResponse) {
        if (!gtResponse) {
            return "Unknown Error";
        }
        else if (!gtResponse.Message) {
            return "Unknown Error";
        }
        else {
            return gtResponse.Message;
        }
    };
    GTResponseHelper.getError = function (gtResponse) {
        return new GTResponseError(gtResponse);
    };
    GTResponseHelper.getError2 = function (code, message) {
        var gtResponse = {
            Result: false,
            Code: code,
            Message: message,
            Payload: null,
        };
        return new GTResponseError(gtResponse);
    };
    return GTResponseHelper;
}());
export { GTResponseHelper };
// This is a custom rxjs operator for handling api responses returning a GTResponse object.
// This code below could certainly be shortened, but since this operator is going to be used in so many places,
// I see no benefit to shortening the code.  Being able to maintain this is much more important than code brevity,
// so it is better to leave it in the verbose form.
// The goal of this operator is to reduce code repetition in the service layer by
// forwarding the GTResponse payload for a successful response,
// and unifying all errors so the error's toString() method can be called in the observer.
export var handleGTResponse = function () { return function (source) {
    var observable = new Observable(function (observer) {
        return source.subscribe({
            next: function (gtResponse) {
                // This is hit for any GTResponse, successful or error.
                // Identity a failed GTResponse, and send an error to the observer
                if (GTResponseHelper.hasError(gtResponse))
                    observer.error(GTResponseHelper.getError(gtResponse));
                // For a successful GTResponse, get the payload and send to observer
                else
                    observer.next(GTResponseHelper.getPayload(gtResponse));
            },
            error: function (err) {
                // This is hit for any unexpected errors with the api
                if (err instanceof HttpErrorResponse) {
                    // HttpErrorResponse happens when there is an unexpected error connecting to the api
                    // For example, during development this error can be simulated by stopping the local api project
                    // The ticky part to HttpErrorResponse is that the error.toString() method returns: "[object Object]" 
                    // which is not very helpful, so I re-wrap it in a new error object with a generic message.
                    // NOTE: the error.name is "HttpErrorResponse" and an example of the error.message is
                    // "Http failure response for https://localhost:44316/api/eim/0/EventItems/UpdateAssignments: 0 Unknown Error"
                    // Just in case this generic error message ever needs to be changed, knowing that ^^^ will save time.
                    observer.error(new Error('The api encountered an unexpected error'));
                    // The benefit of re-wrapping the HttpErrorResponse is that the consuming effect can
                    // use the catchError operator and simply call error.toString() on the error to get the string
                    // to forward onto the appropriate api error action.
                }
                observer.error(err); // Forward non-HttpErrorResponse errors to observer
            },
            complete: function () { observer.complete(); }
        });
    });
    return observable;
}; };
// This is a custom rxjs operator for handling api responses returning a GTCollectionResponse object.
// This code below could certainly be shortened, but since this operator is going to be used in so many places,
// I see no benefit to shortening the code.  Being able to maintain this is much more important than code brevity,
// so it is better to leave it in the verbose form.
// The goal of this operator is to reduce code repetition in the service layer by
// forwarding the GTCollectionResponse payload for a successful response,
// and unifying all errors so the error's toString() method can be called in the observer.
export var handleGTCollectionResponse = function () { return function (source) {
    var observable = new Observable(function (observer) {
        return source.subscribe({
            next: function (gtResponse) {
                // This is hit for any GTCollectionResponse, successful or error.
                // Identity a failed GTCollectionResponse, and send an error to the observer
                if (GTResponseHelper.hasError(gtResponse))
                    observer.error(GTResponseHelper.getError(gtResponse));
                // For a successful GTCollectionResponse, get the payload and send to observer
                else
                    observer.next(GTResponseHelper.getPayload(gtResponse));
            },
            error: function (err) {
                // This is hit for any unexpected errors with the api
                if (err instanceof HttpErrorResponse) {
                    // HttpErrorResponse happens when there is an unexpected error connecting to the api
                    // For example, during development this error can be simulated by stopping the local api project
                    // The ticky part to HttpErrorResponse is that the error.toString() method returns: "[object Object]" 
                    // which is not very helpful, so I re-wrap it in a new error object with a generic message.
                    // NOTE: the error.name is "HttpErrorResponse" and an example of the error.message is
                    // "Http failure response for https://localhost:44316/api/eim/0/EventItems/UpdateAssignments: 0 Unknown Error"
                    // Just in case this generic error message ever needs to be changed, knowing that ^^^ will save time.
                    observer.error(new Error('The api encountered an unexpected error'));
                    // The benefit of re-wrapping the HttpErrorResponse is that the consuming effect can
                    // use the catchError operator and simply call error.toString() on the error to get the string
                    // to forward onto the appropriate api error action.
                }
                observer.error(err); // Forward non-HttpErrorResponse errors to observer
            },
            complete: function () { observer.complete(); }
        });
    });
    return observable;
}; };
// This is a custom rxjs operator for handling api responses returning a GTCollectionResponse object.
// This code below could certainly be shortened, but since this operator is going to be used in so many places,
// I see no benefit to shortening the code.  Being able to maintain this is much more important than code brevity,
// so it is better to leave it in the verbose form.
// The goal of this operator is to reduce code repetition in the service layer by
// forwarding the GTCollectionResponse payload for a successful response,
// and unifying all errors so the error's toString() method can be called in the observer.
export var handleHttpBlobResponse = function (defaultFileName) {
    if (defaultFileName === void 0) { defaultFileName = null; }
    return function (source) {
        var observable = new Observable(function (observer) {
            return source.subscribe({
                next: function (httpResponse) {
                    // TODO: detect errors here if any are possible... this is how errors are handled:
                    // if (someErrorCondition) observer.error(new Error("The error message"));
                    // NOTE: The defaultFileName seems to be able to handle no file extension, so it could be passed
                    // either "Tickets" or "Tickets.zip" (assuming the blob is a zip file, and assuming the defaultFileName is used),
                    // and both will download a file named "Tickets.zip".  This is because the blob has the information of what filetype it is.
                    var gtBlobResultHeader = httpResponse.headers.get("GT-Blob-Result");
                    var gtBlobResponseCodeHeader = httpResponse.headers.get("GT-Blob-Response-Code");
                    var gtBlobMessageHeader = httpResponse.headers.get("GT-Blob-Message");
                    var gtBlobFileNameHeader = httpResponse.headers.get("GT-Blob-File-Name");
                    var gtBlobResult = gtBlobResultHeader === "true" ? true : false;
                    var gtBlobResponseCode = +gtBlobResponseCodeHeader;
                    if (gtBlobResult !== true) {
                        observer.error(GTResponseHelper.getError2(gtBlobResponseCode, gtBlobMessageHeader));
                        return;
                    }
                    var blobFileName;
                    if (gtBlobFileNameHeader == null || gtBlobFileNameHeader.trim() === "") {
                        if (defaultFileName == null || defaultFileName.trim() === "") {
                            blobFileName = "file";
                            console.warn("Using fallback file name");
                        }
                        else {
                            blobFileName = defaultFileName;
                            console.warn("Using default file name");
                        }
                    }
                    else {
                        blobFileName = gtBlobFileNameHeader;
                    }
                    var gtFile = {
                        blob: httpResponse.body,
                        fileName: blobFileName,
                    };
                    observer.next(gtFile);
                },
                error: function (err) {
                    // This is hit for any unexpected errors with the api
                    if (err instanceof HttpErrorResponse) {
                        // HttpErrorResponse happens when there is an unexpected error connecting to the api
                        // For example, during development this error can be simulated by stopping the local api project
                        // The ticky part to HttpErrorResponse is that the error.toString() method returns: "[object Object]" 
                        // which is not very helpful, so I re-wrap it in a new error object with a generic message.
                        // NOTE: the error.name is "HttpErrorResponse" and an example of the error.message is
                        // "Http failure response for https://localhost:44316/api/eim/0/EventItems/UpdateAssignments: 0 Unknown Error"
                        // Just in case this generic error message ever needs to be changed, knowing that ^^^ will save time.
                        observer.error(new Error('The api encountered an unexpected error'));
                        // The benefit of re-wrapping the HttpErrorResponse is that the consuming effect can
                        // use the catchError operator and simply call error.toString() on the error to get the string
                        // to forward onto the appropriate api error action.
                    }
                    observer.error(err); // Forward non-HttpErrorResponse errors to observer
                },
                complete: function () { observer.complete(); }
            });
        });
        return observable;
    };
};
