import { OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { customDateValidator } from 'app/grouptools-theme/validators/customDate.validator';
import { minDateValidator } from 'app/grouptools-theme/validators/minDate.validator';
import { maxDateValidator } from 'app/grouptools-theme/validators/maxDate.validator';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { NbgDatePickerUtility } from 'app/grouptools-theme/services/ngDatePickerHelpers/ngDatePickerUtility.service';
import { minimumValueValidator } from 'app/grouptools-theme/validators/minimumValue.validator';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as modalActions from 'app/store/actions/modal.action';
import { takeUntil } from 'rxjs/operators';
import { ModalSize } from 'app/store/models/modal';
var GledAddActivityLodgingComponent = /** @class */ (function () {
    function GledAddActivityLodgingComponent(fb, datePickerUtility, store) {
        this.fb = fb;
        this.datePickerUtility = datePickerUtility;
        this.store = store;
        this.steps = {
            stepOne: {
                clickable: false,
                expand: true,
                check: false
            },
            stepTwo: {
                clickable: false,
                expand: true,
                check: false
            },
            stepThree: {
                clickable: false,
                expand: true,
                check: false
            }
        };
        this.unsubscribe$ = new Subject();
        // -------------------------------------------------------------------------------------------
        // Previously <feature-add-on>
        // -------------------------------------------------------------------------------------------
        this.formSubmitted = false;
        // saveConfirm: boolean = false;
        this.isEdit = false;
        this.isFirstFeature = false;
        // @Input() disableSave: boolean = false;
        this.invitesSent = false;
        this.isRequiredDisabled = true;
        // @Input() attendeeCount: number = 0;
        this.multiOptions = false;
        this.initAddLodgingForm = {
            // --------------- <feature-add-on> -----------------------
            isRequired: ['Yes'],
            ComponentName: [null, Validators.required],
            // tslint:disable-next-line:max-line-length
            ComponentOccurDate: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            ComponentEndDate: [null],
            ComponentOccurTime: [''],
            ComponentEndTime: [''],
            ComponentDesc: [null, Validators.required],
            RSVPDueDate: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            // VenueName: [null, Validators.required],
            ComponentVenueLocation: [null, Validators.required],
            // --------------- <feature-tickets> ----------------------
            // GetTickets: [''],
            SeatLocation: [''],
            LodgingSeatLocation: [''],
            // TranSeatLocation: [''],
            // SupplierCapacity: ['', minimumValueValidator(1)],
            // SupplyPayDate: [null, [customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            // ComponentCapacity: ['', [minimumValueValidator(1)]],
            // ComponentCost: ['', minimumValueValidator(0)],
            // TotalCost: [{
            //   value: 0,
            //   disabled: true
            // }],
            ComponentPrice: ['', [minimumValueValidator(0)]],
            DepositAmount: ['', [minimumValueValidator(0)]],
            DepositDueDate: [null, [customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            // FinalPaymentDue: [null, [Validators.required, customDateValidator(''), minDateValidator('today'), maxDateValidator('2099-12-31')]],
            FinalPaymentDue: [null, []],
        };
        // -------------------------------------------------------------------------------------------
        // Form Validation Config
        // -------------------------------------------------------------------------------------------
        // tslint:disable-next-line:member-ordering
        this.formErrors = {
            'isRequired': '',
            'ComponentName': '',
            'ComponentOccurDate': '',
            'ComponentEndDate': '',
            'RSVPDueDate': '',
            'ComponentDesc': '',
            // 'VenueName': '',
            'ComponentVenueLocation': '',
            // 'TicketOccurDate': '',
            // 'SupplyPayDate': '',
            'DepositDueDate': '',
            'FinalPaymentDue': '',
            // 'ComponentCapacity': '',
            'ComponentPrice': '',
            'LodgingSeatLocation': '',
            // 'TranSeatLocation': '',
            // 'SupplierCapacity': '',
            'DepositAmount': ''
        };
        // tslint:disable-next-line:member-ordering
        // public ComponentCapacityMessages = {
        //   'tooSmall': 'Quantity must be 1 or greater',
        //   'required': 'If you enter a price, you must enter a quantity',
        //   'fullRequired': 'Quantity is required.'
        // };
        // tslint:disable-next-line:member-ordering
        // public ComponentCapacitySeatsMessages = {
        //   'tooSmall': 'Seats must be 1 or greater',
        //   'required': 'If you enter a price, you must enter a seat quantity',
        //   'fullRequired': 'Seats is required.'
        // };
        // tslint:disable-next-line:member-ordering
        this.ComponentPriceMessages = {
            'tooSmall': 'Price must be 0 or greater',
            'required': 'If you enter a quantity, you must enter a price',
            'fullRequired': 'Price is required.'
        };
        // tslint:disable-next-line:member-ordering
        // public ComponentPriceSeatsMessages = {
        //   'tooSmall': 'Price must be 0 or greater',
        //   'required': 'If you enter a seat quantity, you must enter a price',
        //   'fullRequired': 'Price is required.'
        // };
        // tslint:disable-next-line:member-ordering
        this.validationMessages = {
            'isRequired': {
                'required': 'Please select one.'
            },
            'ComponentName': {
                'required': 'Name is required.'
            },
            // 'VenueName': {
            //   'required': 'Location is required.'
            // },
            'ComponentVenueLocation': {
                'required': 'Address is required.'
            },
            'ComponentOccurDate': {
                'required': 'Date is required.',
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'ComponentEndDate': {
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'RSVPDueDate': {
                'required': 'RSVP Due Date is required.',
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'ComponentDesc': {
                'required': 'Description is required.'
            },
            // 'TicketOccurDate': {
            //   'beforeToday': 'Date must be in the future, greater than today',
            //   'dateTooLate': 'Date cannot be that far in the future',
            //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            // },
            // 'SupplyPayDate': {
            //   'beforeToday': 'Date must be in the future, greater than today',
            //   'dateTooLate': 'Date cannot be that far in the future',
            //   'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            // },
            'DepositDueDate': {
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            'FinalPaymentDue': {
                'required': 'Final Payment Due Date is required',
                'beforeToday': 'Date must be in the future, greater than today',
                'dateTooLate': 'Date cannot be that far in the future',
                'pattern': 'Date must be a valid date in the following format: mm/dd/yyyy'
            },
            // 'ComponentCapacity': this.ComponentCapacityMessages,
            'ComponentPrice': this.ComponentPriceMessages,
            'LodgingSeatLocation': {
                'required': 'Room Type is required'
            },
            // 'TranSeatLocation': {
            //   'required': 'Pickup / Drop Off Location is required'
            // },
            // 'SupplierCapacity': {
            //   'tooSmall': 'This field accepts positive numbers only'
            // },
            // 'ComponentCost': {
            //   'tooSmall': 'Cost must be 0 or greater'
            // },
            'DepositAmount': {
                'tooSmall': 'Deposit must be 0 or greater'
            }
        };
        // @Input() isFirstFeature: boolean = true;
        this.changePhoto = new EventEmitter();
        this.changeEventPhoto = new EventEmitter();
        this.photoType = 'an Activity Photo';
    }
    // -------------------------------------------------------------------------------------------
    // Angular Life Cycle Methods
    // -------------------------------------------------------------------------------------------
    GledAddActivityLodgingComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group(this.initAddLodgingForm);
        this.subscribeToFormsOnValueChanged();
    };
    GledAddActivityLodgingComponent.prototype.ngOnChanges = function (changes) {
        // --------------- <feature-add-on> -----------------------
        if (Boolean(changes.isEdit)) {
            if (changes.isEdit.currentValue === false) {
                if (Boolean(changes.invitesSent)) {
                    if (changes.invitesSent.currentValue === true) {
                        this.form.controls.isRequired.disable();
                        if (!this.form.controls.isRequired.value) {
                            this.form.controls.isRequired.setValue('No');
                        }
                    }
                    else {
                        this.form.controls.isRequired.enable();
                    }
                }
            }
            if (changes.isEdit.currentValue === true) {
                if (this.isRequiredDisabled) {
                    if (changes.isRequiredDisabled.currentValue === true) {
                        this.form.controls.isRequired.disable();
                    }
                }
            }
        }
        // --------------- <feature-tickets> ----------------------
        if (Boolean(changes.featureTicketsForm)) {
            // this.subscribeToFormsOnValueChanged(); // TODO: check if this lost features because it used to subscribe/unsubscribe each time ngOnChanges was called
            this.onValueChanged();
        }
    };
    GledAddActivityLodgingComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    // -------------------------------------------------------------------------------------------
    // Form Validation and Management Methods
    // -------------------------------------------------------------------------------------------
    GledAddActivityLodgingComponent.prototype.onValueChanged = function (data) {
        if (Boolean(this.form) !== true) {
            return;
        }
        if (this.formSubmitted === true) {
            this.clearAndSetFormErrors(this.formErrors, this.form, this.validationMessages, false, false);
        }
        else {
            this.clearAndSetFormErrors(this.formErrors, this.form, this.validationMessages, true, false);
        }
    };
    GledAddActivityLodgingComponent.prototype.onSubmit = function () {
        this.formSubmitted = true;
        this.clearAndSetFormErrors(this.formErrors, this.form, this.validationMessages, false, false);
        if (this.form.valid !== true) {
            return;
        }
        // TODO: send form to be saved (or could use saveConfirm to show confirmation modal)
    };
    // -------------------------------------------------------------------------------------------
    // Miscellaneous Methods
    // -------------------------------------------------------------------------------------------
    GledAddActivityLodgingComponent.prototype.subscribeToFormsOnValueChanged = function () {
        var _this = this;
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(function (data) {
            // if (Boolean(this.formSubmitted)) { this.onValueChanged(data); } // TODO: this was an alternate version used in feature-add-on form
            return _this.onValueChanged(data); // TODO: this was used in feature-tickets form
        });
        this.onValueChanged();
    };
    GledAddActivityLodgingComponent.prototype.cancelModal = function () {
        this.store.dispatch(new modalActions.CloseModal());
    };
    GledAddActivityLodgingComponent.prototype.handleVenueAddressChange = function (address) {
        // --------------- <feature-add-on> -----------------------
        var addressList = address.formatted_address.split(',');
        if (addressList[0] === address.name) {
            this.form.controls.ComponentVenueLocation.setValue(address.formatted_address);
        }
        else {
            this.form.controls.ComponentVenueLocation.setValue(address.name + ', ' + address.formatted_address);
        }
    };
    // TODO: Put clearAndSetFormErrors somewhere common to all activities
    GledAddActivityLodgingComponent.prototype.clearAndSetFormErrors = function (formErrors, form, validationMessages, checkIfControlIsDirty, skipFormLevelErrors) {
        // checkIfControlIsDirty is used in onValueChanges but not when formSubmitted is true
        // checkFormLevelErrors is hopefully safe to be used always
        for (var field in formErrors) {
            if (formErrors.hasOwnProperty(field) !== true) {
                continue;
            }
            formErrors[field] = '';
        }
        // check for form-level errors
        if (!form.valid && skipFormLevelErrors !== true) {
            for (var key in form.errors) {
                if (form.errors.hasOwnProperty(key) !== true) {
                    continue;
                }
                var messages = validationMessages[key];
                if (typeof form.errors[key] === 'object') {
                    for (var subKey in form.errors[key]) {
                        if (form.errors[key].hasOwnProperty(subKey) !== true) {
                            continue;
                        }
                        formErrors[key] += messages[subKey] + ' ';
                    }
                }
                else {
                    formErrors[key] += messages[form.errors[key]] + ' ';
                }
            }
        }
        for (var field in formErrors) {
            if (formErrors.hasOwnProperty(field) !== true) {
                continue;
            }
            // this.formErrors[field] = '';
            var control = form.get(field);
            if (control && !control.valid && (checkIfControlIsDirty === false || (control.dirty))) {
                var messages = validationMessages[field];
                for (var key in control.errors) {
                    if (control.errors.hasOwnProperty(key) !== true) {
                        continue;
                    }
                    formErrors[field] += messages[key] + ' ';
                    // break; // this was here in the feature-add-on classes onValueChanges method.  I think it is to only show one error message per control at a time.
                    // TODO: add parameter setMultipleErrorsPerControl: boolean and use this to toggle break statement.
                }
            }
        }
    };
    return GledAddActivityLodgingComponent;
}());
export { GledAddActivityLodgingComponent };
export var AddActivityLodgingModalConfig = {
    title: 'Add Lodging',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: GledAddActivityLodgingComponent
    }
};
