import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

class HttpRequestRegexSearch {
  httpMethod: string;
  rawRegexPattern: string;

  constructor(
    httpMethod: string,
    urlFragment: string,
    startsAtStartOfUrl: boolean,
    endsAtEndOfUrl: boolean
  ) {
    this.httpMethod = httpMethod;
    const regexEncodedUrlFragment = urlFragment.split("/").join("\\/");
    this.rawRegexPattern = "";
    if (startsAtStartOfUrl) this.rawRegexPattern = "^";
    this.rawRegexPattern += regexEncodedUrlFragment;
    if (endsAtEndOfUrl) this.rawRegexPattern += "$";
  }
}

@Injectable({
  providedIn: 'root'
})
export class GtLoaderConfigService {
  
  regexHttpMethodMap: Map<string, RegExp>;

  constructor() { 
    // To add more GT Loader Exceptions, simply create a new HttpRequestRegexSearch object in the array below.
    // CAUTION: Only use forward slashes here, NO BACKSLASHES!!
    const gtLoaderExceptions: HttpRequestRegexSearch[] = [
      new HttpRequestRegexSearch('PATCH', '/EventItems/UpdateAssignments', false, true),
      new HttpRequestRegexSearch('GET', '/pages/create-event', false, true),
      new HttpRequestRegexSearch('GET', '/login', false, true)
    ];

    const tempMap = new Map<string, string>();
    for (const gtLdrExc of gtLoaderExceptions) {
      if (tempMap.has(gtLdrExc.httpMethod))
        tempMap.set(gtLdrExc.httpMethod, tempMap.get(gtLdrExc.httpMethod) + '|'+ gtLdrExc.rawRegexPattern);
      else 
        tempMap.set(gtLdrExc.httpMethod, gtLdrExc.rawRegexPattern);
    }

    // It seems like the performance of repeatedly adding to a regex would be poor.
    // I think it would be better to concat the strings first, then form the regex once.
    this.regexHttpMethodMap = new Map<string, RegExp>();
    for (const [key, value] of tempMap) {
      this.regexHttpMethodMap.set(key, new RegExp(value, 'i')); // 'i' means ignore case
    }
  }

  shouldShowGTLoaderForRequest(req: HttpRequest<any>): boolean {
    // Reasoning Behind Regular Expression (RegExp) Implementation:
    // This method could be a performance bottleneck, having to perform a substring search for every exclusion on every request.
    // The regexp are cached so the DFA or NFA they use internally only has to be calculated once,
    // then the regexp *should* run in time O(c) where c is the number of characters in the req.url 
    // (I say *should* b/c I haven't empirically tested the performance)
    // Since all url fragments for each httpMethod are grouped into one regexp, the time complexity should remain the same
    // as more gtLoaderExceptions are added, making this a really great long term solution.
    
    // The reason for the httpMethod map is because two endpoints can have the same name, but with different httpMethods.
    // so the only way to tell the difference is using the httpMethod.
    
    if (this.regexHttpMethodMap.has(req.method)
        && this.regexHttpMethodMap.get(req.method).test(req.url)) 
        return false;
    return true;
  }
}
