import { EventItemAssignment, EventItemState } from "app/store/models/eventItemState";
import * as EimActions from "app/store/actions/eventItem.action";
import { ApiState, DataState } from "app/store/models/apiData";
import { attendeeUpsertAdapter, eventItemAdapter, eventItemEntitySelectors, mixedAttendeeAdapter, showInfoAdapter } from "app/store/entity-adapters/eventItem.adapter";
import { EventItem } from "app/store/models/eventItem";
import { AttendeeUpsertRequest } from "../models/requests/attendee-upsert-request";
import { Update } from "@ngrx/entity";
import { GTResponseCodes } from "../models/gtResponse";


export type State = EventItemState;

export const initialState: State = {
    showList: showInfoAdapter.getInitialState({
        apiState: ApiState.Initial,
        errorMessage: null,
    }),
    eventItems: eventItemAdapter.getInitialState({
        apiState: ApiState.Initial,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    // initiallyLoadedAttendees will be used to prevent autosaving from triggering the @Input of the attendees tab changing,
    // which then causes parts of the page to reload.  We want to load them initially, then allow the page to be the source of truth after that.
    // only the attendee tab will use it, the rest of the app will use the 'attendees' property, which will represent the currently saved state.
    initiallyLoadedAttendees: attendeeUpsertAdapter.getInitialState({
        apiState: ApiState.Initial,
        dataState: DataState.Initial,
        errorMessage: null,
    }),
    attendees: attendeeUpsertAdapter.getInitialState({
        apiState: ApiState.Initial,
        dataState: DataState.Initial,
        errorMessage: null,
    }),

    // importedAttendees: [],

    waitingAttendees: mixedAttendeeAdapter.getInitialState(),
    invalidAttendees: mixedAttendeeAdapter.getInitialState(),
    inFlightAttendees: mixedAttendeeAdapter.getInitialState(),
    savedInputAttendees: attendeeUpsertAdapter.getInitialState(),
    savedInputNewIdMap: new Map<number, number>(),
    savedInputNewIdMapReverse: new Map<number, number>(),

    deletedEventAttendeeIds: [],
    deletedNewEventAttendeeIds: [],
    failedToDeleteEventAttendeeIds: [],
    failedToDeleteNewEventAttendeeIds: [],

    failedToAutosaveEventAttendeeIds: [],
    failedToAutosaveNewEventAttendeeIds: [],

    deletedNewEventAttendeeIdsSet: new Set<number>(),

    assignmentsState: {
        apiState: ApiState.Initial,
        errorMessage: null,
    },
    selectedTab: 'attendees',
    deliveryPageSelectionState: new Map<number, boolean>(),
    downloadState: {
        apiState: ApiState.Initial,
        errorMessage: null,
        responseCode: GTResponseCodes.Initial,
    },
    notifyState: {
        apiState: ApiState.Initial,
        errorMessage: null,
        responseCode: GTResponseCodes.Initial,
    },
    downloadManifestState: {
        apiState: ApiState.Initial,
        errorMessage: null,
        responseCode: GTResponseCodes.Initial,
    },
}


export function reducer(state: State = initialState, action: EimActions.Actions): State {
    switch (action.type) {
        // ===============================================================================
        //                            Delivery Page Selection State
        // ===============================================================================
        case EimActions.ActionTypes.EimPageSetDeliverySelectionState:
        {
            // here need to take the input (partial selection map) and assign only those partial values to the whole
            // but remember immutability!!
            const newMap = new Map<number, boolean>(state.deliveryPageSelectionState);
            for (const entry of action.payload.selectionMap.entries()) {
                const eventItemId: number = entry[0];
                const isSelected: boolean = entry[1];
                newMap.set(eventItemId, isSelected);
            }
            return {
                ...state,
                deliveryPageSelectionState: newMap,
            };
        }
        case EimActions.ActionTypes.EimPageSetDeliverySelectionStateAll:
        {
            // need to look at all the event items, and just create a new map with all set to true (or all set to false)
            const newMap = new Map<number, boolean>();
            const eventItems = eventItemEntitySelectors.selectAll(state.eventItems);
            for (const eventItem of eventItems) {
                newMap.set(eventItem.id, action.payload.isAllSelected);
            }
            return {
                ...state,
                deliveryPageSelectionState: newMap,
            };
        }
        // ===============================================================================
        //                            Notifying the Attendee
        // ===============================================================================

        case EimActions.ActionTypes.EimPageNotifyAttendeesTicketsReady:
        case EimActions.ActionTypes.EimPageNotifySelectedAttendeesTicketsReady:
        {
            return {
                ...state,
                notifyState: {
                    ...state.notifyState,
                    apiState: ApiState.InFlight,
                }
            }
        }

        case EimActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyResponse:
        {
            // Here get GTCollectionPayload<TicketReadyNotificationResponse>, some notifications could be successful, some failures
            // The endpoint should make all the eventAttendeeIds valid for the successes (it is of type "number | null")
            // But the failures might be null or valid ids, so this might need to be checked, heck could check the successes too, no big deal

            // Usefulness: need to show user result of notifications, might need to itemize who was/wasn't notified, maybe even visually indicate the failures in the list
            //     need to see the state of the api request, to show visual feedback of progress
            //     need to update the status of each eventItem and/or attendee that was notified (start with eventItem and go from there)
            //     (but if only updating eventItem, then do we need rules in place to block the eventItem from being re-assigned?)
            //     Once the attendee is notified, they could download at any time, and the app's store might be out of date,
            //     So assignments could fail individually, what to do in this case? - could lock eventItem from assigning once notified status
            //     Could allow user to 'Revoke Notification' with warning that this will only work if the attendee has not downloaded the ticket yet 
            //        - Do we send revoked notification when revoked? or just show revoked info when the attendee tries to download from revoked link

            return {
                ...state,
                notifyState: {
                    apiState: ApiState.Success,
                    errorMessage: null,
                    responseCode: GTResponseCodes.Success,
                }
            };
        }
        case EimActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyTooSoonError:
        {
            return {
                ...state,
                notifyState: {
                    apiState: ApiState.Error,
                    errorMessage: null,
                    responseCode: GTResponseCodes.TooEarlyToGenerateTickets,
                }
            }
        }
        case EimActions.ActionTypes.EimApiNotifyAttendeesTicketsReadyFailure:
        {
            // This means the entire request failed from an unexpected error
            return {
                ...state,
                notifyState: {
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                    responseCode: action.payload.responseCode,
                }
            };
        }

        // ===============================================================================
        //                            Downloading Tickets
        // ===============================================================================
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadTickets:
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadSelectedTickets:
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadAllTickets:
        {
            return {
                ...state,
                downloadState: {
                    ...state.downloadState,
                    apiState: ApiState.InFlight,
                }
            };
        }
        case EimActions.ActionTypes.EimApiGroupLeaderDownloadTicketsTooSoonError:
        {
            return {
                ...state,
                downloadState: {
                    apiState: ApiState.Success,
                    errorMessage: null,
                    responseCode: GTResponseCodes.TooEarlyToGenerateTickets,
                }
            }
        }
        case EimActions.ActionTypes.EimPageGroupLeaderDownloadTooManyTicketsError:
        case EimActions.ActionTypes.EimEffectsDownloadFile:
        case EimActions.ActionTypes.EimApiGroupLeaderDownloadTicketsResponse:
        {
            // Here we get GTCollectionPayload<FetchedTicketResult>, some tickets (event items) could be successful, some failures
            // In fact, the errors mean the ticket failed to download for some unexpected reason,
            // but a success could actually be any of the FetchedTicketStatus types, so even they could be purposeful failures

            // Usefulness: Need to show user the result of the downloads, which tickets are included in the download, which failed, were denied, etc
            //             Also need to kick of the actual download (this might be done in an effect or triggered from a selector)
            
            return {
                ...state,
                downloadState: {
                    apiState: ApiState.Success,
                    errorMessage: null,
                    responseCode: GTResponseCodes.Success,
                }
            };
        }
        case EimActions.ActionTypes.EimApiGroupLeaderDownloadTicketsFailure:
        {
            // This means the entire request failed from an unexpected error
            return {
                ...state,
                downloadState: {
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                    responseCode: action.payload.responseCode,
                }
            };
        }

        // ===============================================================================
        //                            Downloading Manifest
        // ===============================================================================
        case EimActions.ActionTypes.EimPageDownloadManifest:
        {
            return {
                ...state,
                downloadManifestState: {
                    apiState: ApiState.InFlight,
                    errorMessage: null,
                    responseCode: null,
                }
            };
        }
        case EimActions.ActionTypes.EimApiDownloadManifestResponse:
        {
            return {
                ...state,
                downloadManifestState: {
                    apiState: ApiState.Success,
                    errorMessage: null,
                    responseCode: GTResponseCodes.Success,
                }
            };
        }
        case EimActions.ActionTypes.EimApiDownloadManifestFailure:
        {
            return {
                ...state,
                downloadManifestState: {
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                    responseCode: action.payload.responseCode,
                }
            };
        }

        // ===============================================================================
        //                            Retrieving the Show List
        // ===============================================================================
        case EimActions.ActionTypes.EimGetShowList:
        {
            return {
                ...state,
                showList: {
                    ...state.showList,
                    apiState: ApiState.InFlight,
                }
            };
        }
        case EimActions.ActionTypes.EimGetShowListSuccess:
        {
            return {
                ...state,
                showList: {
                    ...showInfoAdapter.addAll(action.payload.showList, state.showList),
                    apiState: ApiState.Success,
                    errorMessage: null,
                }
            };
        }
        case EimActions.ActionTypes.EimGetShowListFailure:
        {
            return {
                ...state,
                showList: {
                    ...state.showList,
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                }
            };
        }
        // ===============================================================================
        //                            Assigning Attendees
        // ===============================================================================
        case EimActions.ActionTypes.EimPageAssignAttendeeToEventItem:
        {
            const eventItemUpdate: Update<EventItem> = {
                id: action.payload.eventItem.id,
                changes: { 
                    eventAttendeeId: action.payload.attendee.eventAttendeeId 
                }
            };

            const assignment: EventItemAssignment = {eventItemId: action.payload.eventItem.id, eventAttendeeId: action.payload.attendee.eventAttendeeId};
            return {
                ...state,
                assignmentsState: {
                    ...state.assignmentsState,
                    apiState: ApiState.InFlight,
                },
                eventItems: eventItemAdapter.updateOne(eventItemUpdate, state.eventItems),
            }
        }
        case EimActions.ActionTypes.EimPageUnassignAttendeeFromEventItem:
        {
            const eventItemUpdate: Update<EventItem> = {
                id: action.payload.eventItem.id,
                changes: { eventAttendeeId: null }
            };
            const assignment: EventItemAssignment = {eventItemId: action.payload.eventItem.id, eventAttendeeId: null};
            return {
                ...state,
                assignmentsState: {
                    ...state.assignmentsState,
                    apiState: ApiState.InFlight,
                },
                eventItems: eventItemAdapter.updateOne(eventItemUpdate, state.eventItems),
            }
        }
        case EimActions.ActionTypes.EimApiAutosaveAssignmentsSuccess:
        {            
            return {
                ...state,
                // Updating the eventItems here will cause an assignment dropped during a pending api request to be overwritten by the api response, causing weird bugs
                // eventItems: eventItemAdapter.upsertMany(action.payload.eventItems, state.eventItems),
                assignmentsState: {
                    apiState: ApiState.Success,
                    errorMessage: null,
                },
            }
        }
        case EimActions.ActionTypes.EimApiAutosaveAssignmentsFailure:
        {
            return {
                ...state,
                assignmentsState: {
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                },
            }
        }
        // ===============================================================================
        //                            Eim Page Actions
        // ===============================================================================
        case EimActions.ActionTypes.EimPageAttendeesHaveBeenEdited:
        {
            const validAttendees = action.payload.allAttendees.filter(attendee => attendee.isValid);
            const invalidAttendees = action.payload.allAttendees.filter(attendee => !attendee.isValid);
            return {
                ...state,
                waitingAttendees: mixedAttendeeAdapter.addAll(validAttendees, state.waitingAttendees),
                invalidAttendees: mixedAttendeeAdapter.addAll(invalidAttendees, state.invalidAttendees),
            };
        }
        // ===============================================================================
        //                            Loading Attendees
        // ===============================================================================
        case EimActions.ActionTypes.EimPageLoadAttendees:
        {
            return {
                ...state,
                initiallyLoadedAttendees: {
                    ...state.initiallyLoadedAttendees,
                    apiState: ApiState.InFlight,
                },
                attendees: {
                    ...state.attendees,
                    apiState: ApiState.InFlight,
                },
                // waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees),
                // invalidAttendees: mixedAttendeeAdapter.removeAll(state.invalidAttendees),
                // inFlightAttendees: mixedAttendeeAdapter.removeAll(state.inFlightAttendees),
                // savedInputAttendees: {}
                // TODO: maybe reset waiting, savedInput, inflight, etc here... or maybe resetting in success action is better...
            };
        }
        // case EimActions.EIM_PAGE_ADD_IMPORTED_ATTENDEES:
        // {
        //     return {
        //         ...state,
        //         // initiallyLoadedAttendees: {
        //         //     ...state.initiallyLoadedAttendees,
        //         //     ...attendeeUpsertAdapter.addMany(action.payload.importedAttendees, state.initiallyLoadedAttendees),
        //         // },
        //         // attendees: {
        //         //     ...state.attendees,
        //         //     ...attendeeUpsertAdapter.addMany(action.payload.importedAttendees, state.initiallyLoadedAttendees),
        //         // }
        //         importedAttendees: action.payload.importedAttendees,
        //     };
        // }
        case EimActions.ActionTypes.EimApiLoadAttendeesSuccess:
        {
            return {
                ...state,
                initiallyLoadedAttendees: {
                    ...attendeeUpsertAdapter.addAll(action.payload.attendees, state.initiallyLoadedAttendees),
                    apiState: ApiState.Success,
                    dataState: DataState.Success,
                    errorMessage: null,
                },
                attendees: {
                    ...attendeeUpsertAdapter.addAll(action.payload.attendees, state.attendees),
                    apiState: ApiState.Success,
                    dataState: DataState.Success,
                    errorMessage: null,
                },
                savedInputAttendees: {
                    ...attendeeUpsertAdapter.addAll(action.payload.attendees, state.savedInputAttendees),
                },
                savedInputNewIdMap: new Map<number, number>(), // after loading, all attendees have valid eventAttendeeIds
                savedInputNewIdMapReverse: new Map<number, number>(),
                waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees),
                invalidAttendees: mixedAttendeeAdapter.removeAll(state.invalidAttendees),
                // inFlightAttendees: mixedAttendeeAdapter.removeAll(state.inFlightAttendees), // not so sure I should empty out the in flights here...
            }
        }
        case EimActions.ActionTypes.EimApiLoadAttendeesFailure:
        {
            return {
                ...state,
                initiallyLoadedAttendees: {
                    ...state.initiallyLoadedAttendees,
                    apiState: ApiState.Error,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                },
                attendees: {
                    ...state.attendees,
                    apiState: ApiState.Error,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                }
            }
        }
        // ===============================================================================
        //                            Loading Event Items
        // ===============================================================================
        case EimActions.ActionTypes.EimPageLoadEventItems:
        {
            return {
                ...state,
                eventItems: {
                    ...state.eventItems,
                    apiState: ApiState.InFlight,
                }
            }
        }
        case EimActions.ActionTypes.EimApiLoadEventItemsSuccess:
        {
            return {
                ...state,
                eventItems: {
                    ...eventItemAdapter.addAll(action.payload.eventItems, state.eventItems),
                    apiState: ApiState.Success,
                    dataState: DataState.Success,
                    errorMessage: null,
                },
                assignmentsState: {
                    apiState: ApiState.Success,
                    errorMessage: null,
                },
            }
        }
        case EimActions.ActionTypes.EimApiLoadEventItemsFailure:
        {
            return {
                ...state,
                eventItems: {
                    ...state.eventItems,
                    apiState: ApiState.Error,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                },
                assignmentsState: {
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                },
            }
        }
        // ===============================================================================
        //                            Deleting Attendees
        // ===============================================================================
        // case EimActions.ActionTypes.EimPageDeleteAttendee:
        // {
        //     return {
        //         ...state,
        //         attendees: {
        //             ...state.attendees,
        //             apiState: ApiState.InFlight,
        //         }
        //     };
        // }
        case EimActions.ActionTypes.EiEffectsDeleteExistingAttendee:
        {
            return {
                ...state,
                initiallyLoadedAttendees: {
                    ...state.initiallyLoadedAttendees,
                    apiState: ApiState.InFlight, // This was causing problems with the loading spinner on the seats tab
                },
                attendees: {
                    ...state.attendees,
                    apiState: ApiState.InFlight, // This was causing problems with the loading spinner on the seats tab
                }
            }
        }
        case EimActions.ActionTypes.EimApiDeleteAttendeeSuccess:
        {
            return {
                ...state,
                initiallyLoadedAttendees: {
                    ...attendeeUpsertAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.initiallyLoadedAttendees),
                    apiState: ApiState.Success, // state.initiallyLoadedAttendees.apiState,
                    dataState: DataState.Success, // state.initiallyLoadedAttendees.dataState,
                    errorMessage: state.initiallyLoadedAttendees.errorMessage,
                },
                attendees: {
                    ...attendeeUpsertAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.attendees),
                    apiState: ApiState.Success, // This could potentially conflict with the upsert attendees state that uses this same variable, but the chances are very low, and the state will be fixed on the next autosave request
                    // state.attendees.apiState, // This will be set when autosaving kicks in
                    dataState: DataState.Success, // This could potentially conflict with the upsert attendees state that uses this same variable, but the chances are very low, and the state will be fixed on the next autosave request
                    // state.attendees.dataState, // This will be set when autosaving kicks in 
                    errorMessage: state.attendees.errorMessage,
                },
                waitingAttendees: mixedAttendeeAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.waitingAttendees),
                invalidAttendees: mixedAttendeeAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.invalidAttendees),
                savedInputAttendees: attendeeUpsertAdapter.removeMany(action.payload.deletedEventAttendeeIds, state.savedInputAttendees),
                deletedEventAttendeeIds: state.deletedEventAttendeeIds.concat(action.payload.deletedEventAttendeeIds),
            }
        }
        case EimActions.ActionTypes.EimApiDeleteAttendeeFailure:
        {
            return {
                ...state,
                initiallyLoadedAttendees: {
                    ...state.initiallyLoadedAttendees,
                    apiState: ApiState.Error,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                },
                attendees: {
                    ...state.attendees,
                    apiState: ApiState.Error,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                },
                failedToDeleteEventAttendeeIds: state.failedToDeleteEventAttendeeIds.concat([action.payload.failedEventAttendeeId]),
            }
        }
        case EimActions.ActionTypes.EiEffectsDeleteNewAttendee:
        {
            const newIdSet = new Set<number>(state.deletedEventAttendeeIds);
            newIdSet.add(action.payload.newEventAttendeeId);
            return {
                ...state,
                // Below the action.payload.newEventAttendeeId is negative b/c it waitingAttendees is indexed by the combinedEventAttendeeId
                waitingAttendees: mixedAttendeeAdapter.removeOne(-action.payload.newEventAttendeeId, state.waitingAttendees),
                invalidAttendees: mixedAttendeeAdapter.removeOne(-action.payload.newEventAttendeeId, state.invalidAttendees),
                deletedNewEventAttendeeIds: state.deletedNewEventAttendeeIds.concat(action.payload.newEventAttendeeId),
                deletedNewEventAttendeeIdsSet: newIdSet,
            }
        }
        case EimActions.ActionTypes.EimPageDeletedIdsProcessed:
        {
            const deletedEventAttendeeIdsSet = new Set<number>(action.payload.deletedEventAttendeeIds);
            const deletedNewEventAttendeeIdsSet = new Set<number>(action.payload.deletedNewEventAttendeeIds);
            const failedToDeleteEventAttendeeIdsSet = new Set<number>(action.payload.failedToDeleteEventAttendeeIds);
            const failedToDeleteNewEventAttendeeIdsSet = new Set<number>(action.payload.failedToDeleteNewEventAttendeeIds);

            return {
                ...state,
                deletedEventAttendeeIds: state.deletedEventAttendeeIds.filter(id => !deletedEventAttendeeIdsSet.has(id)),
                deletedNewEventAttendeeIds: state.deletedNewEventAttendeeIds.filter(newId => !deletedNewEventAttendeeIdsSet.has(newId)),
                failedToDeleteEventAttendeeIds: state.failedToDeleteEventAttendeeIds.filter(id => !failedToDeleteEventAttendeeIdsSet.has(id)),
                failedToDeleteNewEventAttendeeIds: state.failedToDeleteNewEventAttendeeIds.filter(newId => !failedToDeleteNewEventAttendeeIdsSet.has(newId)),
            }
        }
        // ===============================================================================
        //                            Saving Attendees
        // ===============================================================================
        case EimActions.ActionTypes.EiEffectsNoAttendeesToSave:
        {
            return {
                ...state,
                waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees),
            }
        }
        case EimActions.ActionTypes.EimPageSaveAttendees:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    apiState: ApiState.InFlight,
                },
                waitingAttendees: mixedAttendeeAdapter.removeAll(state.waitingAttendees),
                inFlightAttendees: mixedAttendeeAdapter.addAll(action.payload.attendees.attendees, state.inFlightAttendees),
            };
        }
        case EimActions.ActionTypes.EimApiSaveAttendeesSuccess:
        {
            // inFlightAttendees has the requested state of saved attendees, but some could be success, others failure, also some could be new
            // need to find the matching requested attendee for each successfully saved attendee.

            const inFlightAttendeesDict = state.inFlightAttendees.entities;
            const savedInputAttendeesToUpsert: AttendeeUpsertRequest[] = [];
            const newMap = new Map<number, number>(state.savedInputNewIdMap);
            const newMapReverse = new Map<number, number>(state.savedInputNewIdMapReverse);

            for (const successfulResponseAttendee of action.payload.attendeeCollectionPayload.Succeeded) {
                let requestedAttendee: AttendeeUpsertRequest = inFlightAttendeesDict[successfulResponseAttendee.eventAttendeeId];
                if (!requestedAttendee && successfulResponseAttendee.newEventAttendeeId > 0) {
                    // The below index is negative because inFlightAttendees should use the combined event attendee id, which puts the new ids negative
                    requestedAttendee = inFlightAttendeesDict[-successfulResponseAttendee.newEventAttendeeId];
                }

                if (!requestedAttendee) {
                    // Then there is no matching requested attendee.  This is unexpected (and likely an error)
                    console.warn("WARNING: Could not find requested in-flight attendee!");
                    console.log(successfulResponseAttendee);
                    console.log(state.inFlightAttendees);
                    continue; // skip this attendee
                }
                requestedAttendee = AttendeeUpsertRequest.copy(requestedAttendee);
                // Now we have the original request object, and the successfully saved response object.

                // // Check if this successfully saved attendee was deleted locally while it was initially saving
                // if (requestedAttendee.newEventAttendeeId > 0) {
                //     const shouldDeleteThisAttendee = state.deletedNewEventAttendeeIdsSet.has(requestedAttendee.newEventAttendeeId);
                //     if (shouldDeleteThisAttendee) {
                //         // TODO: what to do here?
                //     }
                // }

                // Update the id fields that could have been assigned in the response
                requestedAttendee.eventAttendeeId = successfulResponseAttendee.eventAttendeeId;
                requestedAttendee.parentUserId = successfulResponseAttendee.parentUserId;

                // requestedAttendee will be used to update the savedInputAttendees, which represent the client's input of what has been saved,
                // it represents the client input, and not what was returned from the response so that it can be accurately compared to future client changes
                // to know if there are any differences that need to be saved.
                savedInputAttendeesToUpsert.push(requestedAttendee);
                if (requestedAttendee.newEventAttendeeId > 0) {
                    newMap.set(requestedAttendee.newEventAttendeeId, requestedAttendee.eventAttendeeId);
                    newMapReverse.set(requestedAttendee.eventAttendeeId, requestedAttendee.newEventAttendeeId);
                }
                // Then the response attendee should be used to update the attendees property, which will be used everywhere else in the application
                // to represent the currently saved state of attendees.
            }

            // Trying to just clean out the old values, and assign fresh from the latest response, 
            // we'll start simple, and see how this approach fairs in the wild!
            // Turns out this approach might actually work, but why isn't straightforward
            // Since the attendee autosaving saves only the changed attendees, this leaves the door open for the problem of a failed attendee, 
            // then a different successful attendee on next request overwriting the previous failed id 
            // BUT, luckily the failed attendees seem to stay in the queue, and keep getting sent in every request until they are saved, which prevents this problem
            // and so this approach works...
            const failedToAutosaveEventAttendeeIds: number[] = [];
            const failedToAutosaveNewEventAttendeeIds: number[] = [];
            for (const failedResponseAttendee of action.payload.attendeeCollectionPayload.Failed) {
                if (failedResponseAttendee.newEventAttendeeId > 0) failedToAutosaveNewEventAttendeeIds.push(failedResponseAttendee.newEventAttendeeId);
                else failedToAutosaveEventAttendeeIds.push(failedResponseAttendee.eventAttendeeId);
            }

            // TODO: Handle the situation where action.payload.attendeeCollectionPayload.Failed is not empty - what to do?

            return {
                ...state,
                attendees: {
                    ...attendeeUpsertAdapter.upsertMany(action.payload.attendeeCollectionPayload.Succeeded, state.attendees),
                    apiState: ApiState.Success,
                    dataState: DataState.Success,
                    errorMessage: null,
                },
                savedInputAttendees: attendeeUpsertAdapter.upsertMany(savedInputAttendeesToUpsert, state.savedInputAttendees),
                savedInputNewIdMap: newMap,
                savedInputNewIdMapReverse: newMapReverse,
                inFlightAttendees: mixedAttendeeAdapter.removeAll(state.inFlightAttendees), // clear out inflight after response

                failedToAutosaveEventAttendeeIds: failedToAutosaveEventAttendeeIds,
                failedToAutosaveNewEventAttendeeIds: failedToAutosaveNewEventAttendeeIds,
            }
        }
        case EimActions.ActionTypes.EimApiSaveAttendeesFailure:
        {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    apiState: ApiState.Error,
                    dataState: DataState.Error,
                    errorMessage: action.payload.errorMessage,
                },
                inFlightAttendees: mixedAttendeeAdapter.removeAll(state.inFlightAttendees), // clear out inflight after response
            }
        }
        // ===============================================================================
        //                            Miscellaneous Actions
        // ===============================================================================
        case EimActions.ActionTypes.EimPageSelectTab:
        {
            return {
                ...state,
                selectedTab: action.payload.selectedTab,
            }
        }
        // case AuthActions.AuthActionTypes.AuthEffectsClearAllData:
        // {
        //     return {
        //         ...initialState,
        //     };
        // }

        //-------------------------------------------------------------------------------------
        default:
        {
            return state;
        }
    }
}



// function updateAssignmentsEntity(eventItem: EventItem, eventAttendeeId: number | null, state: EventItemState): EventItemAssignmentEntity {
    
//     let newUnsavedAssignments: EventItemAssignmentEntity;
    
//     const existingEventItem = state.eventItems.entities[eventItem.id];
//     if (!!existingEventItem && existingEventItem.eventAttendeeId === eventAttendeeId) {
//         // Then this update is setting the eventAttendeeId back to its' original value,
//         // so no need to save, just remove it from the update assignments property if its been added
//         newUnsavedAssignments = unsavedAssignmentsAdapter.removeOne(eventItem.id, state.unsavedAssignments);
        
//     } else {
//         const assignmentToUpsert: EventItemAssignment = {eventItemId: eventItem.id, eventAttendeeId: eventAttendeeId};
//         newUnsavedAssignments = unsavedAssignmentsAdapter.upsertOne(assignmentToUpsert, state.unsavedAssignments);
//     }

//     if (unsavedEntitySelectors.selectTotal(newUnsavedAssignments) > 0) {
//         newUnsavedAssignments.apiState = ApiState.Initial;
//         // Don't change errorMessage (could still exist from previous failed request)
//     } else {
//         newUnsavedAssignments.apiState = ApiState.Success;
//         newUnsavedAssignments.errorMessage = null;
//     }

//     return newUnsavedAssignments;
// }