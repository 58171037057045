import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { UserMessageDTO } from "../models/packages/UserMessageDTO";


export interface UserMessageEntity extends EntityState<UserMessageDTO> {
    // Put any extra properties here, like apiState, dataState, etc...
}

export const userMessageEntityAdapter: EntityAdapter<UserMessageDTO> = createEntityAdapter<UserMessageDTO>({
    selectId: userMessage => userMessage.IdUserMessage,
    sortComparer: (a, b) => a.IdUserMessage - b.IdUserMessage,
});

export const userMessageEntitySelectors = userMessageEntityAdapter.getSelectors();
