/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./grouptools-new-modal.component";
import * as i3 from "@ngrx/store";
var styles_GroupToolsNewModalComponent = [];
var RenderType_GroupToolsNewModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GroupToolsNewModalComponent, data: {} });
export { RenderType_GroupToolsNewModalComponent as RenderType_GroupToolsNewModalComponent };
function View_GroupToolsNewModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, null); }
function View_GroupToolsNewModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GroupToolsNewModalComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["class", "modal-title text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isStatic; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 6, 0, currVal_1); }); }
function View_GroupToolsNewModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "gt-modal-hr"]], null, null, null, null, null))], null, null); }
function View_GroupToolsNewModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_GroupToolsNewModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GroupToolsNewModalComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GroupToolsNewModalComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(7, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, [[1, 3], ["container", 2]], null, 0, null, View_GroupToolsNewModalComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayHeader; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayHeader; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GroupToolsNewModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "grouptools-new-modal", [], null, null, null, View_GroupToolsNewModalComponent_0, RenderType_GroupToolsNewModalComponent)), i0.ɵdid(1, 245760, null, 0, i2.GroupToolsNewModalComponent, [i0.ComponentFactoryResolver, i3.Store, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GroupToolsNewModalComponentNgFactory = i0.ɵccf("grouptools-new-modal", i2.GroupToolsNewModalComponent, View_GroupToolsNewModalComponent_Host_0, {}, {}, []);
export { GroupToolsNewModalComponentNgFactory as GroupToolsNewModalComponentNgFactory };
