import { ModuleWithProviders } from '@angular/core';
import { BaContentTop } from './components/baContentTop/baContentTop.component';
import { BaMenu } from './components/baMenu/baMenu.component';
import { BaMenuItem } from './components/baMenu/components/baMenuItem/baMenuItem.component';
import { BaMsgCenter } from './components/baMsgCenter/baMsgCenter.component';
import { BaLoader } from './components/baLoader/baLoader.component';
import { BaSidebar } from './components/baSidebar/baSidebar.component';
import { BaCard } from './components/baCard/baCard.component';
import { BaPictureUploader } from './components/baPictureUploader/baPictureUploader.component';
import { GroupToolsLoaderComponent } from './components/grouptoolsLoader/grouptoolsLoader.component';
import { BaMenuService } from './services/baMenu/baMenu.service';
import { EqualPasswordsValidator } from './validators/equalsPasswords.validator';
import { OnlyUninvitedPipe } from './pipes/onlyUnivited.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { BaProfilePicturePipe } from 'app/grouptools-theme/pipes/baProfilePicture/baProfilePicture.pipe';
import { UtcDatePipe } from 'app/grouptools-theme/pipes/utcDate.pipe';
import { ShortTimeStringPipe } from 'app/grouptools-theme/pipes/shortTimeString.pipe';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { GroupToolsLoaderService } from './services/grouptoolsLoader/grouptoolsLoader.service';
import { BlacklistStringsValidator } from './validators/blacklist-strings.validator';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
var NGA_COMPONENTS = [
    BaContentTop,
    BaMenuItem,
    BaMenu,
    BaMsgCenter,
    BaSidebar,
    BaCard,
    BaLoader,
    BaPictureUploader,
    GroupToolsLoaderComponent,
    LoadingSpinnerComponent,
];
var NGA_PIPES = [
    OnlyUninvitedPipe,
    BaProfilePicturePipe,
    PhonePipe,
    UtcDatePipe,
    ShortTimeStringPipe
];
var NGA_SERVICES = [
    BaMenuService,
    GroupToolsLoaderService
];
var GTNgaModule = /** @class */ (function () {
    function GTNgaModule() {
    }
    GTNgaModule.forRoot = function () {
        return {
            ngModule: GTNgaModule,
            providers: [
                BaMenuService,
                GroupToolsLoaderService,
                EqualPasswordsValidator,
                BlacklistStringsValidator
            ],
        };
    };
    return GTNgaModule;
}());
export { GTNgaModule };
