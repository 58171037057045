import { PackageDetailsState } from "../models/packageDetailsState";
import * as PDActions from "app/store/actions/packageDetails.actions";
import * as eventActions from '../actions/event.action';
import { ApiState } from "../models/apiData";

export type State = PackageDetailsState;

export const initialState: State = {
    packageDetails: {},
    tagInfo: null,
    components: [],
    packageRecord: {
        Components: [],
        Package: {
            IdPackage: 0,
            PackageName: '',
            PackageShortDesc: '',
            PackageDesc: '',
            PackageDate: null,
            PackageTime: null,
            ComponentCount: 0,
            InviteCount: 0,
            AttendeeCount: 0,
            StartingPrice: 0,
            EventStatus: '',
            CreatedAt: '',
            UpdatedAt: null,
            CreatedBy: '',
            JsonDoc: '{}',
            ShortPublicUrl: '',
            RSVPDueDate: null,
            PhotoUrl: '',
        }
    },
    paymentsReceivedSummary: {Received: 0, AmountDue: 0, Balance: 0},
    paymentsSentSummary: null,
    allSuppliers: [],
    packageLogs: [],
    separatedPayments: {
        AttendeePayments: [],
        SupplierPayments: [],
    },
    packageSuppliersByActivity: [],
    bibOrderNumberCreateEventApiState: {
        apiState: ApiState.Initial,
        errorMessage: null,
    },
    supplierOrderNumberCreateEventApiState: {
        apiState: ApiState.Initial,
        errorMessage: null,
    },
    tickets: [],
};

export function reducer(state: State = initialState, action: PDActions.Actions): State {
    switch (action.type) {
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDEffectsLoadPackageDetails: {
            return {
                ...state,
                packageDetails: {
                    ...state.packageDetails,
                    IdPackage: action.payload.eventId,
                },
            };
        }
        case PDActions.ActionTypes.PDApiLoadPackageDetailsSuccess: {
            return {
                ...state,
                packageDetails: action.payload.packageDetails,
            };
        }
        case PDActions.ActionTypes.PDApiLoadPackageDetailsFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadTagInfoSuccess: {
            return {
                ...state,
                tagInfo: action.payload.tagInfo,
            };
        }
        case PDActions.ActionTypes.PDApiLoadTagInfoFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadPackageComponentsSuccess: {
            return {
                ...state,
                components: action.payload.components,
            };
        }
        case PDActions.ActionTypes.PDApiLoadPackageComponentsFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadPackageRecordSuccess: {
            return {
                ...state,
                packageRecord: action.payload.packageRecord,
            };
        }
        case PDActions.ActionTypes.PDApiLoadPackageRecordFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadSuppliersSuccess: {
            return {
                ...state,
                allSuppliers: action.payload.suppliers,
            };
        }
        case PDActions.ActionTypes.PDApiLoadSuppliersFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadPackageLogsSuccess: {
            return {
                ...state,
                packageLogs: action.payload.packageLogs,
            };
        }
        case PDActions.ActionTypes.PDApiLoadPackageLogsFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PDApiLoadSuppliersForPackageSuccess: {
            return {
                ...state,
                packageSuppliersByActivity: action.payload.packageSuppliersByActivity,
            };
        }
        case PDActions.ActionTypes.PDApiLoadSuppliersForPackageFailure: {
            return {
                ...state,
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        } //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.CreateEventFromBibOrderDialog: {
            return {
                ...state,
                bibOrderNumberCreateEventApiState: {
                    ...state.bibOrderNumberCreateEventApiState,
                    apiState: ApiState.InFlight,
                }
            };
        }
        case PDActions.ActionTypes.PDApiCreateEventFromBibOrderSuccess: {
            return {
                ...state,
                bibOrderNumberCreateEventApiState: {
                    ...state.bibOrderNumberCreateEventApiState,
                    apiState: ApiState.Success,
                },
            };
        }
        case PDActions.ActionTypes.PDApiCreateEventFromBibOrderFailure: {
            return {
                ...state,
                bibOrderNumberCreateEventApiState: {
                    ...state.bibOrderNumberCreateEventApiState,
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                }
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.CreateEventFromSupplierOrderDialog: {
            return {
                ...state,
                supplierOrderNumberCreateEventApiState: {
                    ...state.supplierOrderNumberCreateEventApiState,
                    apiState: ApiState.InFlight,
                }
            };
        }
        case PDActions.ActionTypes.PDApiCreateEventFromSupplierOrderSuccess: {
            return {
                ...state,
                supplierOrderNumberCreateEventApiState: {
                    ...state.supplierOrderNumberCreateEventApiState,
                    apiState: ApiState.Success,
                },
            };
        }
        case PDActions.ActionTypes.PDApiCreateEventFromSupplierOrderFailure: {
            return {
                ...state,
                supplierOrderNumberCreateEventApiState: {
                    ...state.supplierOrderNumberCreateEventApiState,
                    apiState: ApiState.Error,
                    errorMessage: action.payload.errorMessage,
                }
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.UpdateEventFromSupplierOrder: {
            return {
                ...state    // TODO: May want this to update state in some way
            };
        }
        case PDActions.ActionTypes.PDApiUpdateEventFromSupplierOrderSuccess: {
            return {
                ...state    // TODO: May want this to update state in some way
            };
        }
        case PDActions.ActionTypes.PDApiUpdateEventFromSupplierOrderFailure: {
            return {
                ...state
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.LoadTickets: {
            return {
                ...state    // TODO: May want this to update state in some way
            };
        }
        case PDActions.ActionTypes.PDApiLoadTicketsSuccess: {
            return {
                ...state,
                tickets: action.payload.loadTicketsResponse.tickets,
            };
        }
        case PDActions.ActionTypes.PDApiLoadTicketsFailure: {
            return {
                ...state
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        }
        //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.PurchaseTickets: {
            return {
                ...state    // TODO: May want this to update state in some way
            };
        }
        case PDActions.ActionTypes.PDApiPurchaseTicketsSuccess: {
            return {
                ...state,
            };
        }
        case PDActions.ActionTypes.PDApiPurchaseTicketsFailure: {
            return {
                ...state
                // TODO: Add some error state and record here using action.payload.errorMessage
            };
        }
         //-------------------------------------------------------------------------------------
        case PDActions.ActionTypes.AuthenticationServiceLogout:
        {
            return {
                ...initialState,
            };
        }
        //--------------------------------------------------------------------------------------
        case PDActions.ActionTypes.UpdateEventOk: {
            // CD 09/01/2023 - Right now we have selectedEvent and packageDetails slices of state. UpdateEventOk previously only updated selectedEvent, but we need to update both
            //                 since both are actively used.
            return {
                ...state,
                packageDetails: {
                    ...state.packageDetails,
                    JsonDoc: action.payload.jsonDoc,
                },
            };
        }
        default: {
            return state;
        }
    }
}
