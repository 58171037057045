import * as attendeePackages from '../actions/attendeePackages';

export interface State {
  selectedPackageId: number;
};

export const initialState: State = {
  selectedPackageId: 0
};

export function reducer(state = initialState, action: attendeePackages.Actions): State {
  switch (action.type) {
    case attendeePackages.SET_SELECTED_ID:
      {
        return Object.assign({}, state, {
          selectedPackageId: action.payload
        });
      }
    case attendeePackages.LOGOUT:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
}

export const getSelectedPackageId = (state: State) => state.selectedPackageId;

