import { ContactsState } from "../models/contacts.state";
import * as ContactsActions from "app/store/actions/contacts.action";

export type State = ContactsState;

export const initialState: State = {
    contacts: [],
};

export function reducer(state: State = initialState, action: ContactsActions.Actions): State {
    switch (action.type) {
        case ContactsActions.ActionTypes.ContactsEffectLoadContactsSuccess:
        {
            return {
                ...state,
                contacts: action.payload.contacts,
            };
        }
        case ContactsActions.ActionTypes.ContactsEffectLoadContactsFailure:
        {
            return {
                ...state, // TODO: Implement somewhere to store error
            };
        }
        case ContactsActions.ActionTypes.AuthenticationServiceLogout:
        {
            return initialState;
        }
        case ContactsActions.ActionTypes.AuthenticationServiceLogout:
        {
            return {
                ...initialState,
            };
        }
        default:
        {
            return state;
        }
    }
}
