import * as eventActions from '../actions/event.action';
import { Profile } from '../models/profile';

export type State = Profile[];

export function reducer(state: State = [], action: eventActions.Actions): State {
    switch (action.type) {
        case eventActions.LOAD_ALTERNATE_LEADERS_OK:
        {
            // cast as Attendee[] because we know that's what it will be
            return <Profile[]> (<eventActions.LoadAlternateLeadersOk>action).payload;
        }
        case eventActions.CLEAR_SELECTED_EVENT_DATA:
        {
            return [];
        }
        default:
            return state;
    }

}

