import { Router } from '@angular/router';
// import { DeviceDetectorService } from 'ngx-device-detector';
var NotIEGuard = /** @class */ (function () {
    function NotIEGuard(router) {
        this.router = router;
        this.deviceInfo = null;
    }
    NotIEGuard.prototype.canActivate = function (route, state) {
        if (this.deviceInfo === null) {
            // this.deviceInfo = this.deviceService.getDeviceInfo();
        }
        // if(this.deviceInfo.browser !== 'ie')
        return true;
        this.router.navigate(['/not-supported']);
    };
    return NotIEGuard;
}());
export { NotIEGuard };
