<!-- OR LINE -->
<div class="form-group row or-group">
    <h4 class="or"><span class="line-center">OR Log In and Register with</span></h4>
</div>

<!-- LOGIN WITH SOCIAL -->
<div class="form-group row social-row">
    <div class="col-sm-12">
        <google-login (loginComplete)="google($event)"></google-login>
    </div>
</div>
<div class="form-group row social-row">
    <div class="col-sm-12 btn-row">
        <facebook-login (loginComplete)="facebook($event)"></facebook-login>
    </div>
</div>
<div class="form-group row social-row">
    <div class="col-sm-12 btn-row">
        <amazon-login (loginComplete)="amazon($event)"></amazon-login>
    </div>
</div>