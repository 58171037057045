import { timer as observableTimer } from 'rxjs';
import { map, takeUntil, finalize } from 'rxjs/operators';
import { EventEmitter, SimpleChanges } from '@angular/core';
import { RegisterService } from '../register.service';
import { AuthService } from 'app/authentication/authentication.service';
import 'style-loader!./account-verification.component.scss';
import * as user from 'app/store/actions/user';
import { Store } from '@ngrx/store';
var AccountVerificationComponent = /** @class */ (function () {
    function AccountVerificationComponent(store, _registerService, _authServie) {
        this.store = store;
        this._registerService = _registerService;
        this._authServie = _authServie;
        this.registerInModal = true;
        this.isEventDetail = false;
        this.isInvitation = false;
        this.isAttendee = false;
        this.isPackageInvite = false;
        this.isEnterCode = false;
        this.skipVerification = new EventEmitter();
        this.onlyLogin = new EventEmitter();
        this.showSuccessModal = new EventEmitter();
        this.changeModalTitle = new EventEmitter();
        this.switchCase = 'createAccountSuccess';
        this.count = 24 * 60 * 60;
        this.verifyAccountClicked = false;
        this.verifyingAccount = false;
        this.enterTimes = 0;
        this.enterOver = false;
        this.timeOver = false;
        this.isVerified = false;
    }
    AccountVerificationComponent.prototype.ngOnChanges = function (change) {
        var _this = this;
        if (change.isEventDetail) {
            if (change.isEventDetail) {
                this.switchCase = "groupleaderVerification";
            }
        }
        if (change.isInvitation) {
            if (change.isInvitation) {
                this.switchCase = "groupleaderVerification";
            }
        }
        if (change.isAttendee) {
            if (change.isAttendee) {
                this.switchCase = "attendeeVerification";
            }
        }
        if (change.isEnterCode) {
            if (change.isEnterCode) {
                this.switchCase = "verifyAccount";
                this._registerService.GetVerificationCodeExpiration().subscribe(function (res) {
                    if (res.ResponseCode === 200) {
                        var current = new Date();
                        var expiration = new Date(res.Obj);
                        var compare = (expiration.getTime() - current.getTime()) / 1000;
                        if (compare < 0) {
                            _this.countDown = 0;
                            _this.enterOver = true;
                            _this.verifyingAccount = false;
                        }
                        else
                            _this.count = compare;
                    }
                    else {
                        _this.countDown = 0;
                        _this.enterOver = true;
                        _this.verifyingAccount = false;
                    }
                });
                this.verifyCodeCountDown();
            }
        }
    };
    AccountVerificationComponent.prototype.sendVerificationCode = function () {
        var _this = this;
        this._registerService.sendVerifyCode().subscribe(function (res) {
            if (res.ResponseCode == 200) {
                _this.changeModalTitle.emit();
                _this.switchCase = 'verifyAccount';
                _this.verifyCodeCountDown();
            }
        });
    };
    AccountVerificationComponent.prototype.Login = function () {
        if (this.registerInModal)
            this.sendVerificationCode();
        else
            this.onlyLogin.emit();
    };
    AccountVerificationComponent.prototype.verifyAccount = function () {
        var _this = this;
        this.verifyingAccount = true;
        this.verifyAccountClicked = true;
        this.enterTimes++;
        this._registerService.ValidateVerificationCode({ code: this.verifyCode }).subscribe(function (res) {
            if (res.ResponseCode == 200) {
                _this.isVerified = true;
                if (_this.isPackageInvite)
                    _this.accountVerificationSuccess();
                else if (!_this.isEventDetail && !_this.isInvitation && !_this.isAttendee && !_this.isEnterCode)
                    _this.skipVerification.emit();
                else
                    _this.showSuccessModal.emit();
            }
            if (res.ResponseCode == 401) {
                _this.isVerified = false;
                if (_this.enterTimes == 3) {
                    _this.enterOver = true;
                    _this.verifyAccountClicked = false;
                    _this.countDown = 0;
                    _this.timer$.unsubscribe();
                }
            }
            _this.verifyingAccount = false;
        });
    };
    AccountVerificationComponent.prototype.sendVerifyCode = function () {
        this.enterOver = false;
        this.enterTimes = 0;
        this.timeOver = false;
        this.verifyCode = '';
        this.verifyAccountClicked = false;
        this.sendVerificationCode();
    };
    AccountVerificationComponent.prototype.verifyCodeCountDown = function () {
        var _this = this;
        var interval = 1000;
        var duration = this.count * 1000;
        var stream$ = observableTimer(0, interval).pipe(finalize(function () {
            //console.log("All done!");
        }), takeUntil(observableTimer(duration + interval)), map(function (value) { return duration - value * interval; }));
        this.timer$ = stream$.subscribe(function (value) { return _this.countDown = value / 1000; });
    };
    // getButtonText(){
    //     if(this.isEventDetail || this.isAttendee)
    //         return 'Return to Event Details';
    //     if(this.isInvitation)
    //         return 'Return to Invitation';
    //     return 'Cancel'
    // }
    AccountVerificationComponent.prototype.accountVerificationSuccess = function () {
        var _this = this;
        this._authServie.refresh().subscribe(function (suc) {
            _this.store.dispatch(new user.SetVerifiedStatus(true));
            _this.skipVerification.emit();
        });
    };
    return AccountVerificationComponent;
}());
export { AccountVerificationComponent };
