import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GoogleLoginComponent } from './google-login.component';
import { GoogleRegisterComponent } from './google-register.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        GoogleLoginComponent,
        GoogleRegisterComponent
    ],
    exports: [
        GoogleLoginComponent,
        GoogleRegisterComponent
    ]
})
export class GoogleLoginModule { }