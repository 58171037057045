import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalSize } from 'app/store/models/modal';
import * as modalActions from 'app/store/actions/modal.action';
import { AddActivityLodgingModalConfig } from './gled-add-activity-lodging/gled-add-activity-lodging.component';
import { AddActivityOtherModalConfig } from './gled-add-activity-other/gled-add-activity-other.component';
import { AddActivityRestaurantModalConfig } from './gled-add-activity-restaurant/gled-add-activity-restaurant.component';
import { AddActivityShowModalConfig } from './gled-add-activity-show/gled-add-activity-show.component';
import { AddActivityTransportationModalConfig } from './gled-add-activity-transportation/gled-add-activity-transportation.component';
var GledAddActivityState;
(function (GledAddActivityState) {
    GledAddActivityState["ChooseActivityType"] = "ChooseActivityType";
    GledAddActivityState["Show"] = "Show";
    GledAddActivityState["Restaurant"] = "Restaurant";
    GledAddActivityState["Lodging"] = "Lodging";
    GledAddActivityState["Transportation"] = "Transportation";
    GledAddActivityState["Other"] = "Other";
})(GledAddActivityState || (GledAddActivityState = {}));
var GledAddActivityComponent = /** @class */ (function () {
    function GledAddActivityComponent(store) {
        this.store = store;
        this.enumGledAddActivityState = GledAddActivityState; // This is so we can use the enum type in the template
        this.state = GledAddActivityState.ChooseActivityType;
    }
    GledAddActivityComponent.prototype.ngOnInit = function () {
    };
    GledAddActivityComponent.prototype.openAddActivityModal = function (modalType) {
        switch (modalType) {
            case GledAddActivityState.Lodging:
                this.store.dispatch(new modalActions.OpenModal(tslib_1.__assign({}, AddActivityLodgingModalConfig)));
                break;
            case GledAddActivityState.Other:
                this.store.dispatch(new modalActions.OpenModal(tslib_1.__assign({}, AddActivityOtherModalConfig)));
                break;
            case GledAddActivityState.Restaurant:
                this.store.dispatch(new modalActions.OpenModal(tslib_1.__assign({}, AddActivityRestaurantModalConfig)));
                break;
            case GledAddActivityState.Show:
                this.store.dispatch(new modalActions.OpenModal(tslib_1.__assign({}, AddActivityShowModalConfig)));
                break;
            case GledAddActivityState.Transportation:
                this.store.dispatch(new modalActions.OpenModal(tslib_1.__assign({}, AddActivityTransportationModalConfig)));
                break;
            default:
                alert('test test');
                // TODO: maybe notify user it was an invalid choice?  Or there was an error?  Maybe an apologetic error modal?
                break;
        }
    };
    return GledAddActivityComponent;
}());
export { GledAddActivityComponent };
export var AddActivityModalConfig = {
    title: 'Choose Activity Type',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: GledAddActivityComponent
    }
};
