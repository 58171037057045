<div class="gled-add-activity">

  <!-- <p>Which type of activity would you like to add?</p> OLD
<button (click)="state = enumGledAddActivityState.Show">Add Show</button>
<button (click)="state = enumGledAddActivityState.Restaurant">Add Restaurant</button>
<button (click)="state = enumGledAddActivityState.Lodging">Add Lodging</button>
<button (click)="state = enumGledAddActivityState.Transportation">Add Transportation</button>
<button (click)="state = enumGledAddActivityState.Other">Add Custom Activity</button> -->

  <p>Which type of activity would you like to add?</p>
  <!-- <button (click)="state = enumGledAddActivityState.Show">Add Show</button> -->
  
  <div><button (click)="openAddActivityModal(enumGledAddActivityState.Show)">Add Show</button></div>
  <div><button (click)="openAddActivityModal(enumGledAddActivityState.Restaurant)">Add Restaurant</button></div>
  <div><button (click)="openAddActivityModal(enumGledAddActivityState.Lodging)">Add Lodging</button></div>
  <div><button (click)="openAddActivityModal(enumGledAddActivityState.Transportation)">Add Transportation</button></div>
  <div><button (click)="openAddActivityModal(enumGledAddActivityState.Other)">Add Custom Activity</button></div>

  <!-- <div [ngSwitch]="state"> OLD
  <p *ngSwitchCase="enumGledAddActivityState.Show">Show (Component Here)</p>
  <gled-add-activity-show *ngSwitchCase="enumGledAddActivityState.Show"></gled-add-activity-show>
  <p *ngSwitchCase="enumGledAddActivityState.Restaurant">Restaurant (Component Here)</p>
  <p *ngSwitchCase="enumGledAddActivityState.Lodging">Lodging (Component Here)</p>
  <p *ngSwitchCase="enumGledAddActivityState.Transportation">Transportation (Component Here)</p>
  <p *ngSwitchCase="enumGledAddActivityState.Other">Other (Component Here)</p>
</div> -->

  <!-- <div [ngSwitch]="state">
  <gled-add-activity-show *ngSwitchCase="enumGledAddActivityState.Show"></gled-add-activity-show>
  <gled-add-activity-restaurant *ngSwitchCase="enumGledAddActivityState.Restaurant"></gled-add-activity-restaurant>
  <gled-add-activity-lodging *ngSwitchCase="enumGledAddActivityState.Lodging"></gled-add-activity-lodging>
  <gled-add-activity-transportation *ngSwitchCase="enumGledAddActivityState.Transportation"></gled-add-activity-transportation>
  <gled-add-activity-other *ngSwitchCase="enumGledAddActivityState.Other"></gled-add-activity-other>
</div>

<button *ngIf="state !== enumGledAddActivityState.ChooseActivityType" (click)="state = enumGledAddActivityState.ChooseActivityType">Cancel</button> -->

</div>