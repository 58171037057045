import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
// NOTE: The DragDropMessagingService class is only used for visual updates between components
//       It is not used for persistant state, that is handled by ngrx.
var DragDropMessagingService = /** @class */ (function () {
    //----------------------------------------------------------------------------------------
    function DragDropMessagingService() {
        //----------------------------------------------------------------------------------------
        this.subject = new Subject();
        //----------------------------------------------------------------------------------------
        // This is just temporary state to be used in a seat assignment component (there are 3)
        this.eventItemIdSet = new Set();
    }
    DragDropMessagingService.prototype.send = function (messageType, attendee, eventItem) {
        this.subject.next({ messageType: messageType, attendee: attendee, eventItem: eventItem });
    };
    DragDropMessagingService.prototype.on = function () {
        return this.subject.asObservable();
    };
    DragDropMessagingService.prototype.registerEventItemAsLinkInvalidated = function (eventItem) {
        if (!eventItem)
            return;
        this.eventItemIdSet.add(eventItem.id);
    };
    DragDropMessagingService.prototype.isEventItemLinkInvalidated = function (eventItem) {
        return this.eventItemIdSet.has(eventItem.id);
    };
    DragDropMessagingService.prototype.clearEventItemLinkInvalidatedState = function () {
        this.eventItemIdSet.clear();
    };
    DragDropMessagingService.ngInjectableDef = i0.defineInjectable({ factory: function DragDropMessagingService_Factory() { return new DragDropMessagingService(); }, token: DragDropMessagingService, providedIn: "root" });
    return DragDropMessagingService;
}());
export { DragDropMessagingService };
export var DragDropMessageTypes;
(function (DragDropMessageTypes) {
    DragDropMessageTypes["AttendeeHasBeenUnassigned"] = "AttendeeHasBeenUnassigned";
    DragDropMessageTypes["AttendeeHasBeenKickedOutOfSeat"] = "AttendeeHasBeenKickedOutOfSeat";
    DragDropMessageTypes["AttendeeAssignedToSeat"] = "AttendeeAssignedToSeat";
    DragDropMessageTypes["AttendeeMovedFromSeat"] = "AttendeeMovedFromSeat";
})(DragDropMessageTypes || (DragDropMessageTypes = {}));
