/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grouptools-label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/popover";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./grouptools-label.component";
var styles_GrouptoolsLabelComponent = [i0.styles];
var RenderType_GrouptoolsLabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GrouptoolsLabelComponent, data: {} });
export { RenderType_GrouptoolsLabelComponent as RenderType_GrouptoolsLabelComponent };
function View_GrouptoolsLabelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "gt-label-span"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "pull-right": 0 }), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-info-circle"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v.parent, 3).hide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v.parent, 3).toggle() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "gt-label-span"; var currVal_1 = _ck(_v, 2, 0, _co.pullRight); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_GrouptoolsLabelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "gt-label-span"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v.parent, 3).hide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v.parent, 3).toggle() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-question-circle question-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_GrouptoolsLabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 12, "label", [["class", "gt-label"], ["outsideClick", "true"]], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "full-width": 0 }), i1.ɵdid(3, 212992, [["pop", 4]], 0, i3.PopoverDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory], { popover: [0, "popover"], outsideClick: [1, "outsideClick"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GrouptoolsLabelComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GrouptoolsLabelComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "gt-label"; var currVal_2 = _ck(_v, 2, 0, _co.pullRight); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.helperText; var currVal_4 = "true"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = (_co.helperText && !_co.isQuestion); _ck(_v, 8, 0, currVal_5); var currVal_6 = (_co.helperText && _co.isQuestion); _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.for; _ck(_v, 0, 0, currVal_0); }); }
export function View_GrouptoolsLabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "grouptools-label", [], null, null, null, View_GrouptoolsLabelComponent_0, RenderType_GrouptoolsLabelComponent)), i1.ɵdid(1, 49152, null, 0, i5.GrouptoolsLabelComponent, [], null, null)], null, null); }
var GrouptoolsLabelComponentNgFactory = i1.ɵccf("grouptools-label", i5.GrouptoolsLabelComponent, View_GrouptoolsLabelComponent_Host_0, { for: "for", helperText: "helperText", isQuestion: "isQuestion", pullRight: "pullRight" }, {}, ["*"]);
export { GrouptoolsLabelComponentNgFactory as GrouptoolsLabelComponentNgFactory };
