/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./google-login.component";
import * as i2 from "ngx-configure";
var styles_GoogleLoginComponent = [];
var RenderType_GoogleLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoogleLoginComponent, data: {} });
export { RenderType_GoogleLoginComponent as RenderType_GoogleLoginComponent };
export function View_GoogleLoginComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { button: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["button", 1]], null, 0, "img", [["class", "clickable social-image img-fluid"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.initListData.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.googleButtonImage; _ck(_v, 1, 0, currVal_0); }); }
export function View_GoogleLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "google-login", [], null, null, null, View_GoogleLoginComponent_0, RenderType_GoogleLoginComponent)), i0.ɵdid(1, 4243456, null, 0, i1.GoogleLoginComponent, [i0.Renderer2, i2.NgxConfigureService], null, null)], null, null); }
var GoogleLoginComponentNgFactory = i0.ɵccf("google-login", i1.GoogleLoginComponent, View_GoogleLoginComponent_Host_0, {}, { loginComplete: "loginComplete", initListData: "initListData" }, []);
export { GoogleLoginComponentNgFactory as GoogleLoginComponentNgFactory };
