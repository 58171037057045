var PREFIX = '[User]';
export var LOAD_USER_STATE = PREFIX + " Load User State";
export var LOAD_USER_STATE_OK = PREFIX + " Load User State Ok";
export var SET_LOGGED_IN = PREFIX + " Set Logged In";
export var SET_USER_ID = PREFIX + " Set User ID";
export var SET_LOGIN_STATUS = PREFIX + " Set Login Status";
export var SET_DEFAULT = PREFIX + " Set Default";
export var SET_REGISTERED_STATUS = PREFIX + " Set Registered Status";
export var SET_VERIFIED_STATUS = PREFIX + " Set Verified Status";
export var SET_EMAIL = PREFIX + " Set Email";
export var SET_FIRST_NAME = PREFIX + " Set First Name";
export var SET_LAST_NAME = PREFIX + " Set Last Name";
export var SET_PHONE_NUMBER = PREFIX + " Set Phone Number";
export var SET_ADDRESS_STATE = PREFIX + " Set Address State";
export var SET_PROFILE_PICTURE = PREFIX + " Set Profile Picture";
export var SET_DEFAULT_PROFILE_PICTURE = PREFIX + " Set Default Profile Picture";
export var SET_LEADER_STATUS = PREFIX + " Set Leader Status";
export var SET_PAYMENT_METHOD = PREFIX + " Set Payment Method";
export var SET_ROLES = PREFIX + " Set Roles";
export var SET_ALTERNATE_PAYMENT_METHOD = PREFIX + " Set Alternate Payment Method";
// refactor actions
export var LOAD_GROUP_LEADER_PROFILE = PREFIX + " Load Group Leader Profile";
export var LOAD_GROUP_LEADER_PROFILE_OK = PREFIX + " Load Group Leader Profile Ok";
export var SET_UNREAD_EMAIL_PREFERENCE = PREFIX + " Set Unread Email Preferences";
export var SET_UNREAD_EMAIL_PREFERENCE_OK = PREFIX + " Set Unread Email Preferences Ok";
export var SET_ABOUT = PREFIX + " Set About";
export var SAVE_ABOUT = PREFIX + " Save About";
export var SAVE_ABOUT_OK = PREFIX + " Save About Ok";
// new actions
export var SET_USER_REGISTERED_DATA = PREFIX + " Set user registered data";
export var LOGOUT = PREFIX + " Logout";
var LoadUserState = /** @class */ (function () {
    function LoadUserState(payload) {
        this.payload = payload;
        this.type = LOAD_USER_STATE;
    }
    return LoadUserState;
}());
export { LoadUserState };
var LoadUserStateOk = /** @class */ (function () {
    function LoadUserStateOk(payload) {
        this.payload = payload;
        this.type = LOAD_USER_STATE_OK;
    }
    return LoadUserStateOk;
}());
export { LoadUserStateOk };
var SetUnreadEmailPreference = /** @class */ (function () {
    function SetUnreadEmailPreference(payload) {
        this.payload = payload;
        this.type = SET_UNREAD_EMAIL_PREFERENCE;
    }
    return SetUnreadEmailPreference;
}());
export { SetUnreadEmailPreference };
var SetUnreadEmailPreferenceOk = /** @class */ (function () {
    function SetUnreadEmailPreferenceOk(payload) {
        this.payload = payload;
        this.type = SET_UNREAD_EMAIL_PREFERENCE_OK;
    }
    return SetUnreadEmailPreferenceOk;
}());
export { SetUnreadEmailPreferenceOk };
var SetUserRegisteredData = /** @class */ (function () {
    function SetUserRegisteredData(payload) {
        this.payload = payload;
        this.type = SET_USER_REGISTERED_DATA;
    }
    return SetUserRegisteredData;
}());
export { SetUserRegisteredData };
var SetAbout = /** @class */ (function () {
    function SetAbout(payload) {
        this.payload = payload;
        this.type = SET_ABOUT;
    }
    return SetAbout;
}());
export { SetAbout };
var SaveAbout = /** @class */ (function () {
    function SaveAbout(payload) {
        this.payload = payload;
        this.type = SAVE_ABOUT;
    }
    return SaveAbout;
}());
export { SaveAbout };
var SaveAboutOk = /** @class */ (function () {
    function SaveAboutOk(payload) {
        this.payload = payload;
        this.type = SAVE_ABOUT_OK;
    }
    return SaveAboutOk;
}());
export { SaveAboutOk };
var SetLoggedIn = /** @class */ (function () {
    function SetLoggedIn(payload) {
        this.payload = payload;
        this.type = SET_LOGGED_IN;
    }
    return SetLoggedIn;
}());
export { SetLoggedIn };
var SetUserID = /** @class */ (function () {
    function SetUserID(payload) {
        this.payload = payload;
        this.type = SET_USER_ID;
    }
    return SetUserID;
}());
export { SetUserID };
var SetLoginStatus = /** @class */ (function () {
    function SetLoginStatus(payload) {
        this.payload = payload;
        this.type = SET_LOGIN_STATUS;
    }
    return SetLoginStatus;
}());
export { SetLoginStatus };
var SetDefault = /** @class */ (function () {
    function SetDefault(payload) {
        this.payload = payload;
        this.type = SET_DEFAULT;
    }
    return SetDefault;
}());
export { SetDefault };
var SetEmail = /** @class */ (function () {
    function SetEmail(payload) {
        this.payload = payload;
        this.type = SET_EMAIL;
    }
    return SetEmail;
}());
export { SetEmail };
var SetRegisteredStatus = /** @class */ (function () {
    function SetRegisteredStatus(payload) {
        this.payload = payload;
        this.type = SET_REGISTERED_STATUS;
    }
    return SetRegisteredStatus;
}());
export { SetRegisteredStatus };
var SetVerifiedStatus = /** @class */ (function () {
    function SetVerifiedStatus(payload) {
        this.payload = payload;
        this.type = SET_VERIFIED_STATUS;
    }
    return SetVerifiedStatus;
}());
export { SetVerifiedStatus };
var SetFirstName = /** @class */ (function () {
    function SetFirstName(payload) {
        this.payload = payload;
        this.type = SET_FIRST_NAME;
    }
    return SetFirstName;
}());
export { SetFirstName };
var SetLastName = /** @class */ (function () {
    function SetLastName(payload) {
        this.payload = payload;
        this.type = SET_LAST_NAME;
    }
    return SetLastName;
}());
export { SetLastName };
var SetPhoneNumber = /** @class */ (function () {
    function SetPhoneNumber(payload) {
        this.payload = payload;
        this.type = SET_PHONE_NUMBER;
    }
    return SetPhoneNumber;
}());
export { SetPhoneNumber };
var SetAddressState = /** @class */ (function () {
    function SetAddressState(payload) {
        this.payload = payload;
        this.type = SET_ADDRESS_STATE;
    }
    return SetAddressState;
}());
export { SetAddressState };
var SetProfilePicture = /** @class */ (function () {
    function SetProfilePicture(payload) {
        this.payload = payload;
        this.type = SET_PROFILE_PICTURE;
    }
    return SetProfilePicture;
}());
export { SetProfilePicture };
var SetDefaultProfilePicture = /** @class */ (function () {
    function SetDefaultProfilePicture(payload) {
        this.payload = payload;
        this.type = SET_DEFAULT_PROFILE_PICTURE;
    }
    return SetDefaultProfilePicture;
}());
export { SetDefaultProfilePicture };
var SetLeaderStatus = /** @class */ (function () {
    function SetLeaderStatus(payload) {
        this.payload = payload;
        this.type = SET_LEADER_STATUS;
    }
    return SetLeaderStatus;
}());
export { SetLeaderStatus };
var SetPaymentMethod = /** @class */ (function () {
    function SetPaymentMethod(payload) {
        this.payload = payload;
        this.type = SET_PAYMENT_METHOD;
    }
    return SetPaymentMethod;
}());
export { SetPaymentMethod };
var SetRoles = /** @class */ (function () {
    function SetRoles(payload) {
        this.payload = payload;
        this.type = SET_ROLES;
    }
    return SetRoles;
}());
export { SetRoles };
var SetAlternatePaymentMethod = /** @class */ (function () {
    function SetAlternatePaymentMethod(payload) {
        this.payload = payload;
        this.type = SET_ALTERNATE_PAYMENT_METHOD;
    }
    return SetAlternatePaymentMethod;
}());
export { SetAlternatePaymentMethod };
var LoadGroupLeaderProfile = /** @class */ (function () {
    function LoadGroupLeaderProfile(payload) {
        this.payload = payload;
        this.type = LOAD_GROUP_LEADER_PROFILE;
    }
    return LoadGroupLeaderProfile;
}());
export { LoadGroupLeaderProfile };
var LoadGroupLeaderProfileOk = /** @class */ (function () {
    function LoadGroupLeaderProfileOk(payload) {
        this.payload = payload;
        this.type = LOAD_GROUP_LEADER_PROFILE_OK;
    }
    return LoadGroupLeaderProfileOk;
}());
export { LoadGroupLeaderProfileOk };
var Logout = /** @class */ (function () {
    function Logout(payload) {
        this.payload = payload;
        this.type = LOGOUT;
    }
    return Logout;
}());
export { Logout };
