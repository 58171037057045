import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLConfig } from '../pages/helpers/config.service';
import { GTResponse, handleGTResponse } from 'app/store/models/gtResponse';
var ActionsService = /** @class */ (function () {
    function ActionsService(http, URLs) {
        this.http = http;
        this.URLs = URLs;
        this.actionsOptions = {
            multiSelectMode: false,
            hideHeader: true,
            hideSubHeader: true,
            hideDate: true,
            hideMenu: false,
            hideFilter: true,
            hideHead: true,
            columns: [
                { fieldName: 'icon' },
                { fieldName: 'type' },
                { fieldName: 'content' },
            ],
            bulkActions: [],
            singleActions: ['Dismiss'],
            headerActions: []
        };
    }
    ActionsService.prototype.GetActions = function (options) {
        return this.http.post(this.URLs._actions(), options).pipe(handleGTResponse(), map(function (userActions) {
            userActions.map(function (alert) {
                alert.JsonActionMessage = JSON.parse(alert.JsonActionMessage);
                alert.JsonActions = JSON.parse(alert.JsonActions);
                // add special fields with html for list display
                if (alert.Type === 'System Alert') {
                    alert.icon = '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>';
                }
                else {
                    alert.icon = '<i class="fa fa-info-circle" aria-hidden="true"></i>';
                }
                alert.type = '<h4>' + alert.Type + '</h4><p>'
                    + alert.RuleType + '</p>';
                // 2018-12-14 - ska - wrap content in a p tag for consistent styling
                alert.content = "<p>" + alert.JsonActionMessage.message + "</p>";
                // add special field for custom actions
                alert.customActions = [];
                alert.JsonActions.forEach(function (action) {
                    alert.customActions.push(action.Text);
                });
            });
            return userActions;
        }));
    };
    ActionsService.prototype.MarkAsRead = function (actionsObj) {
        var token = '';
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        var transferObj = Object.assign({}, actionsObj);
        if (transferObj.JsonActions) {
            transferObj.JsonActions = JSON.stringify(transferObj.JsonActions);
        }
        if (transferObj.JsonActionMessage) {
            transferObj.JsonActionMessage = JSON.stringify(transferObj.JsonActionMessage);
        }
        return this.http.post(this.URLs._markActionAsRead(), transferObj).pipe(handleGTResponse());
    };
    ActionsService.prototype.DismissSurveyActions = function (surveyId) {
        return this.http.post(this.URLs._dismissAllSurveyActions(surveyId), null).pipe(handleGTResponse());
    };
    return ActionsService;
}());
export { ActionsService };
