import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { MessagesService } from "app/pages/messages/messages.service";
import * as fromMessage from '../actions/message.action';
import * as UnhandledErrorActions from '../actions/unhandledError.action';
import { exhaustMap, map, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import * as MessageActions from "app/store/actions/message.action";
import { MessageRequestDTO } from "../models/requests/MessageRequestDTO";

@Injectable()
export class MessageEffects {

    @Effect()
    loadEventMessages = this.actions$.pipe(
        ofType<MessageActions.PDEffectsLoadEventMessages>(MessageActions.ActionTypes.PDEffectsLoadEventMessages),
        concatMap(action => {
            const options: MessageRequestDTO = {IdPackage: action.payload.eventId};
            return this.messageService.GetMessages(options).pipe(
                map(eventMessages => new MessageActions.MessageEffectsLoadEventMessagesSuccess({eventMessages})),
                catchError(error => of(new MessageActions.MessageEffectsLoadEventMessagesFailure({errorMessage: error.toString()}))),
            );
        }),
    );

    @Effect()
    loadEventSentMessages = this.actions$.pipe(
        ofType<MessageActions.PDEffectsLoadEventSentMessages>(MessageActions.ActionTypes.PDEffectsLoadEventSentMessages),
        concatMap(action => {
            const options: MessageRequestDTO = {IdPackage: action.payload.eventId};
            return this.messageService.GetSentMessages(options).pipe(
                map(eventSentMessages => new MessageActions.MessageEffectsLoadEventSentMessagesSuccess({eventSentMessages})),
                catchError(error => of(new MessageActions.MessageEffectsLoadEventSentMessagesFailure({errorMessage: error.toString()}))),
            );
        }),
    );

    @Effect()
    loadEventMessagesNew = this.actions$.pipe(
        ofType<fromMessage.LoadEventMessagesNew>(fromMessage.ActionTypes.LoadEventMessagesNew),
        exhaustMap(action => this.messageService.getMessagesNew(action.payload.eventId)
                    .pipe(
                        map(eventMessagesNew => new fromMessage.LoadEventMessagesNewSuccess({eventMessagesNew})),
                        catchError(error => of(new fromMessage.LoadEventMessagesNewFailure({errorMessage: error.toString()})))
                    )
        )
    );

    @Effect()
    deleteMessage = this.actions$.pipe(
        ofType<MessageActions.MessagesListComponentDeleteMessage>(MessageActions.ActionTypes.MessagesListComponentDeleteMessage),
        concatMap(action => {
            return this.messageService.DeleteMessages(action.payload.message).pipe(
                map(idUserMessage => new MessageActions.MessageEffectsDeleteMessageSuccess({idUserMessage})),
                catchError(error => of(new MessageActions.MessageEffectsDeleteMessageFailure({errorMessage: error.toString()}))),
            );
        }),
    );

    @Effect()
    markMessageAsRead = this.actions$.pipe(
        ofType<MessageActions.MessagesListComponentMarkMessageAsRead>(MessageActions.ActionTypes.MessagesListComponentMarkMessageAsRead),
        concatMap(action => {
            return this.messageService.MarkAsRead(action.payload.message).pipe(
                map(idUserMessage => new MessageActions.MessageEffectsMarkMessageAsReadSuccess({message: action.payload.message})),
                catchError(error => of(new MessageActions.MessageEffectsMarkMessageAsReadFailure({errorMessage: error.toString()}))),
            );
        }),
    );

    constructor(
        private actions$: Actions,
        private messageService: MessagesService,
    ) {}
}
