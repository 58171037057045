import { Observable, BehaviorSubject } from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var GroupToolsSize = /** @class */ (function () {
    function GroupToolsSize(eventManager) {
        this.eventManager = eventManager;
        // screen width constants
        this.SMALL_WIDTH = 576;
        this.MEDIUM_WIDTH = 768;
        this.LARGE_WIDTH = 1024;
        this.X_LARGE_WIDTH = 1400;
        this.heightSubject = new BehaviorSubject(window.innerHeight);
        this.widthSubject = new BehaviorSubject(window.innerWidth);
        this.eventManager.addGlobalEventListener('window', 'resize', this.resizeHandler.bind(this));
    }
    Object.defineProperty(GroupToolsSize.prototype, "windowWidth$", {
        get: function () {
            return this.widthSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupToolsSize.prototype, "windowHeight$", {
        get: function () {
            return this.heightSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    GroupToolsSize.prototype.resizeHandler = function (event) {
        var target = event.target;
        this.heightSubject.next(target.innerHeight);
        this.widthSubject.next(target.innerWidth);
    };
    GroupToolsSize.ngInjectableDef = i0.defineInjectable({ factory: function GroupToolsSize_Factory() { return new GroupToolsSize(i0.inject(i1.EventManager)); }, token: GroupToolsSize, providedIn: "root" });
    return GroupToolsSize;
}());
export { GroupToolsSize };
