import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as AttendeesActions from 'app/store/actions/attendees.action';
import * as AttendeesSelectors from 'app/store/selectors/attendees.selector';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ModalSize } from 'app/store/models/modal';
import { Actions, ofType } from '@ngrx/effects';
import { CloseModal } from 'app/store/actions/modal.action';
var ChangeAttendeeEmailsComponent = /** @class */ (function () {
    function ChangeAttendeeEmailsComponent(store$, actions$) {
        this.store$ = store$;
        this.actions$ = actions$;
        this.unsubscribe$ = new Subject();
        this.saveInProgress = false;
        this.savedEmails = 0;
        this.savedMessage = '';
    }
    ChangeAttendeeEmailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.attendees$ = this.store$.pipe(select(AttendeesSelectors.getAttendees), takeUntil(this.unsubscribe$), map(function (attendeeList) {
            _this.attendees = attendeeList.filter(function (a) { return a.status.toLowerCase() === 'bounced'; });
            _this.saveInProgress = false;
            return _this.attendees;
        }));
        // TODO: Catching an action directly in a component is not a great pattern,
        this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(AttendeesActions.ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess)).subscribe(function (action) {
            _this.savedEmails = action.payload.attendees.length;
            if (_this.savedEmails === 1) {
                _this.savedMessage = 'Email has been updated';
            }
            else if (_this.savedEmails > 1) {
                _this.savedMessage = _this.savedEmails + " email addresses have been updated";
            }
        });
    };
    ChangeAttendeeEmailsComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    ChangeAttendeeEmailsComponent.prototype.close = function () {
        this.store$.dispatch(new CloseModal());
    };
    ChangeAttendeeEmailsComponent.prototype.updateEmails = function () {
        var e_1, _a;
        this.saveInProgress = true;
        var updatedAttendees = [];
        try {
            for (var _b = tslib_1.__values(this.attendees), _c = _b.next(); !_c.done; _c = _b.next()) {
                var a = _c.value;
                if (a['updatedEmail']) {
                    updatedAttendees.push(a);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.store$.dispatch(new AttendeesActions.ChangeAttendeeEmailsComponentChangeAttendeeEmails({ attendees: updatedAttendees }));
    };
    return ChangeAttendeeEmailsComponent;
}());
export { ChangeAttendeeEmailsComponent };
export var ChangeAttendeeEmailsConfig = {
    title: 'Change Attendee Email',
    size: ModalSize.Medium,
    isFullScreenMobile: true,
    componentWithOptions: {
        component: ChangeAttendeeEmailsComponent
    }
};
