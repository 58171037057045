import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AttendeesActions from '../actions/attendees.action';
import { Injectable } from '@angular/core';
import { EventDetailsService } from 'app/pages/package-details/event-details.service';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { AttendeePackageDetailService } from 'app/pages/attendee-package-details/attendee-package-details.service';
import { of } from 'rxjs';
import { UpdateResponseDTO } from '../models/packages/UpdateResponseDTO';
import { GroupTixService } from '../services/group-tix.service';

@Injectable()
export class AttendeesEffects {

    @Effect()
    loadAttendees = this.actions$.pipe(
        ofType<AttendeesActions.GeneralLoadAttendees | AttendeesActions.GroupMembersTabLoadAttendees>
                (AttendeesActions.ActionTypes.GeneralLoadAttendees, AttendeesActions.ActionTypes.GroupMembersTabLoadAttendees),
        concatMap(action => this.eventService.getAttendees(action.payload.eventId).pipe(
            map(attendees => new AttendeesActions.AttendeeEffectLoadAttendeesSuccess({attendees})),
            catchError(error => of(new AttendeesActions.AttendeeEffectLoadAttendeesFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    saveAttendees = this.actions$.pipe(
        ofType<AttendeesActions.GroupMemberTabSaveAllAttendees 
            | AttendeesActions.GroupMemberTabAddNewAttendees
            | AttendeesActions.ImportContactsToEventSaveAttendees
            | AttendeesActions.PreviousAttendeesSaveAttendees>
            (AttendeesActions.ActionTypes.GroupMemberTabSaveAllAttendees,
            AttendeesActions.ActionTypes.GroupMemberTabAddNewAttendees,
            AttendeesActions.ActionTypes.ImportContactsToEventSaveAttendees,
            AttendeesActions.ActionTypes.PreviousAttendeesSaveAttendees),
        concatMap(action => this.groupTixService.upsertAttendees(action.payload.attendees, action.payload.eventId).pipe(
            map(attendeeCollectionPayload => new AttendeesActions.AttendeeEffectSaveAttendeesSuccess({attendeeCollectionPayload})),
            catchError(error => of(new AttendeesActions.AttendeeEffectSaveAttendeesFailure({errorMessage: error.toString(), failedAttendees: action.payload.attendees.attendees}))),
        )),
    );

    @Effect()
    addAttendees = this.actions$.pipe(
        ofType<AttendeesActions.PublicEventAddNewAttendee>
            (AttendeesActions.ActionTypes.PublicEventAddNewAttendee),
        concatMap(action => this.groupTixService.publicEventAddAttendees(action.payload.attendee, action.payload.eventId).pipe(
            map(response => new AttendeesActions.PublicEventAddNewAttendeeSuccess({response})),
            catchError(error => of(new AttendeesActions.PublicEventAddNewAttendeeFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    updateAttendeeStatus = this.actions$.pipe(
        ofType<AttendeesActions.UpdateAttendeeStatusComponentUpdateAttendeeStatus>
                (AttendeesActions.ActionTypes.UpdateAttendeeStatusComponentUpdateAttendeeStatus),
        concatMap(action => {           
            const formatted: UpdateResponseDTO = {
                IdPackage: action.payload.attendee.eventId,
                status: action.payload.attendee.status,
                IdUser: action.payload.attendee.id
            };
            return this.apdService.SetStatus(formatted).pipe(
                map(updateInvitationResponse => new AttendeesActions.AttendeeEffectUpdateAttendeeStatusSuccess({updateInvitationResponse})),
                catchError(error => of(new AttendeesActions.AttendeeEffectUpdateAttendeeStatusFailure({errorMessage: error.toString()}))),
            );
        }),
    );

    @Effect()
    changeAttendeeEmails = this.actions$.pipe(
        ofType<AttendeesActions.ChangeAttendeeEmailsComponentChangeAttendeeEmails>
                (AttendeesActions.ActionTypes.ChangeAttendeeEmailsComponentChangeAttendeeEmails),
        concatMap(action => this.eventService.changeAttendeeEmails(action.payload.attendees).pipe(
            map(attendees => new AttendeesActions.AttendeeEffectChangeAttendeeEmailsSuccess({attendees})),
            catchError(error => of(new AttendeesActions.AttendeeEffectChangeAttendeeEmailsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    inviteEventAttendees = this.actions$.pipe(
        ofType<AttendeesActions.GroupMemberTab_InviteEventAttendees>(AttendeesActions.ActionTypes.GroupMemberTab_InviteEventAttendees),
        concatMap(action => this.groupTixService.inviteEventAttendees(action.payload.inviteEventAttendeesRequest).pipe(
            map(inviteEventAttendeesResponse => new AttendeesActions.AttendeeEffect_InviteAttendeesSuccess({attendeeIds: inviteEventAttendeesResponse})),
            catchError(error => of(new AttendeesActions.AttendeeEffect_InviteAttendeesFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadLeaderSummary = this.actions$.pipe(
        ofType<AttendeesActions.PackageDetailsEffectLoadLeaderSummary>(AttendeesActions.ActionTypes.PackageDetailsEffectLoadLeaderSummary),
        concatMap(action => this.eventService.getLeaderSummary(action.payload.eventId).pipe(
            map(leaderSummary => new AttendeesActions.AttendeeEffectLoadLeaderSummarySuccess({leaderSummary})),
            catchError(error => of(new AttendeesActions.AttendeeEffectLoadLeaderSummaryFailure({errorMessage: error.toString()}))),
        )),
    );

    constructor(
        private actions$: Actions,
        private eventService: EventDetailsService,
        private apdService: AttendeePackageDetailService,
        private groupTixService: GroupTixService,
    ) {}
}
