import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as PackageDetailsSelectors from 'app/store/selectors/packageDetails.selector';
import * as PackageDetailsActions from "app/store/actions/packageDetails.actions";
var CreateFromBibOrderDialogComponent = /** @class */ (function () {
    function CreateFromBibOrderDialogComponent(dialogRef, data, store$) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.store$ = store$;
    }
    CreateFromBibOrderDialogComponent.prototype.ngOnInit = function () {
        this.bibOrderNumberCreateEventApiState$ = this.store$.select(PackageDetailsSelectors.getBibOrderNumberCreateEventApiState);
    };
    CreateFromBibOrderDialogComponent.prototype.showSelected = function () { };
    CreateFromBibOrderDialogComponent.prototype.dateSelected = function () { };
    CreateFromBibOrderDialogComponent.prototype.getStartedClicked = function (createEventFromBibOrderRequest) {
        this.store$.dispatch(new PackageDetailsActions.CreateEventFromBibOrderDialog({ createEventFromBibOrderRequest: createEventFromBibOrderRequest }));
    };
    return CreateFromBibOrderDialogComponent;
}());
export { CreateFromBibOrderDialogComponent };
