import { createSelector } from "@ngrx/store";
import { AppState } from "app/store/models/app-state";
import { cloneDeep as _cloneDeep } from "lodash";

const getContactsState = (state: AppState) => state.contactsState;

// =================================================================================
//                                      Selectors
// =================================================================================

export const getContacts = createSelector(
    getContactsState,
    contactsState => contactsState.contacts
);

export const getContactsCopy = createSelector(
    getContacts,
    contacts => _cloneDeep(contacts) // cloning here is a hack to allow older code to change the data (it is readonly when in ngrx store)
);
