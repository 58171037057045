import * as tslib_1 from "tslib";
var AttendeeUpsertRequest = /** @class */ (function () {
    function AttendeeUpsertRequest(userId, eventAttendeeId, newEventAttendeeId, isParentResponsible, parentUserId, parentAttendeeId, newEventAttendeeIdParent, fullName, emailPhone, email, phone, guestCount, eventId, status) {
        this.userId = userId;
        this.eventAttendeeId = eventAttendeeId;
        this.newEventAttendeeId = newEventAttendeeId;
        this.isParentResponsible = isParentResponsible;
        this.parentUserId = parentUserId;
        this.parentAttendeeId = parentAttendeeId;
        this.newEventAttendeeIdParent = newEventAttendeeIdParent;
        this.fullName = fullName;
        this.emailPhone = emailPhone;
        this.email = email;
        this.phone = phone;
        this.guestCount = guestCount;
        this.eventId = eventId;
        this.parentFullName = "";
        this.status = status;
    }
    Object.defineProperty(AttendeeUpsertRequest.prototype, "combinedEventAttendeeId", {
        get: function () {
            if (this.eventAttendeeId > 0)
                return this.eventAttendeeId;
            if (this.newEventAttendeeId > 0)
                return -this.newEventAttendeeId;
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    AttendeeUpsertRequest.prototype.isEqualTo = function (a) {
        // NOTE: null === null and 0 === 0, but for ids we need null === 0 and 0 === null,
        //       this is because sometimes the api returns 0 when send null or vice-versa
        return ((this.userId === a.userId || (this.userId === 0 && a.userId === null) || (this.userId === null && a.userId === 0))
            && (this.eventAttendeeId === a.eventAttendeeId || (this.eventAttendeeId === 0 && a.eventAttendeeId === null) || (this.eventAttendeeId === null && a.eventAttendeeId === 0))
            && (this.newEventAttendeeId === a.newEventAttendeeId || (this.newEventAttendeeId === 0 && a.newEventAttendeeId === null) || (this.newEventAttendeeId === null && a.newEventAttendeeId === 0))
            && this.isParentResponsible === a.isParentResponsible
            && (this.parentUserId === a.parentUserId || (this.parentUserId === 0 && a.parentUserId === null) || (this.parentUserId === null && a.parentUserId === 0))
            && (this.parentAttendeeId === a.parentAttendeeId || (this.parentAttendeeId === 0 && a.parentAttendeeId === null) || (this.parentAttendeeId === null && a.parentAttendeeId === 0))
            && (this.newEventAttendeeIdParent === a.newEventAttendeeIdParent || (this.newEventAttendeeIdParent === 0 && a.newEventAttendeeIdParent === null) || (this.newEventAttendeeIdParent === null && a.newEventAttendeeIdParent === 0))
            && this.fullName === a.fullName
            // TODO: Rethink the messy matching '==' in emailPhone, email, and phone, this could cause problems
            && this.emailPhone == a.emailPhone
            && this.email == a.email
            && this.phone == a.phone
            && this.guestCount === a.guestCount
            && (this.eventId === a.eventId || (this.eventId === 0 && a.eventId === null) || (this.eventId === null && a.eventId === 0))
        // NOTE: eventId should always exist, but adding a 0 === null and null === 0 check doesn't hurt
        );
    };
    AttendeeUpsertRequest.prototype.isSameAttendeeAs = function (a) {
        // both could be new
        if (this.isNewAttendee && a.isNewAttendee) {
            // Then both 'this' and 'a' are new attendees (meaning both have a valid newEventAttendeeId)
            return this.newEventAttendeeId === a.newEventAttendeeId;
        }
        else if (this.isNewAttendee) {
            // only 'this' is new attendee (meaning this should have a valid newEventAttendeeId, but a might not)
            if (typeof a.newEventAttendeeId !== 'number' || a.newEventAttendeeId < 1)
                return false;
            return this.newEventAttendeeId === a.newEventAttendeeId;
        }
        else if (a.isNewAttendee) {
            // only 'a' is new attendee (meaning a should have a valid newEventAttendeeId, but this might not)
            if (typeof this.newEventAttendeeId !== 'number' || this.newEventAttendeeId < 1)
                return false;
            return this.newEventAttendeeId === a.newEventAttendeeId;
        }
        else {
            // neither are new attendees (meaning both should have a valid eventAttendeeId)
            // This is the only case where eventAttendeeId can be used to compare
            return this.eventAttendeeId === a.eventAttendeeId;
        }
    };
    Object.defineProperty(AttendeeUpsertRequest.prototype, "isParent", {
        get: function () { return !this.isParentResponsible; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeUpsertRequest.prototype, "isChild", {
        get: function () { return this.isParentResponsible; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeUpsertRequest.prototype, "isChildOfNewParent", {
        // public get isChildOfNewParent() { return this.isChild && (typeof this.newEventAttendeeIdParent === 'number') && this.newEventAttendeeIdParent > 0; }
        get: function () { return this.isChild && (typeof this.parentUserId !== 'number' || this.parentUserId < 1); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeUpsertRequest.prototype, "isChildOfExistingParent", {
        get: function () { return this.isChild && !this.isChildOfNewParent; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeUpsertRequest.prototype, "isNewAttendee", {
        get: function () { return typeof this.eventAttendeeId !== 'number' || this.eventAttendeeId < 1; },
        enumerable: true,
        configurable: true
    });
    AttendeeUpsertRequest.createNewAttendee = function (newEventAttendeeId, fullName, emailPhone, email, phone, eventId, guestCount, status) {
        if (guestCount === void 0) { guestCount = 0; }
        return new AttendeeUpsertRequest(null, null, newEventAttendeeId, false, null, null, null, fullName, emailPhone, email, phone, guestCount, eventId, status);
    };
    AttendeeUpsertRequest.createFromAttendee = function (a) {
        return new AttendeeUpsertRequest(a.id, a.eventAttendeeId, null, a.isParentResponsible, a.parentUserId, a.parentAttendeeId, null, a.fullName, '', a.email, a.phone, a.guestCount, a.eventId, a.status);
    };
    AttendeeUpsertRequest.createNewChildAttendee = function (newEventAttendeeId, fullName, parent, eventId) {
        var returnVal;
        if (parent.isNewAttendee) {
            returnVal = AttendeeUpsertRequest.createNewPlusOneWithNewParent(newEventAttendeeId, fullName, '', '', '', parent.newEventAttendeeId, eventId, '');
        }
        else {
            returnVal = AttendeeUpsertRequest.createNewPlusOneWithExistingParent(newEventAttendeeId, fullName, '', '', '', parent.eventAttendeeId, parent.eventAttendeeId, eventId, '');
        }
        return returnVal;
    };
    AttendeeUpsertRequest.createNewPlusOneWithExistingParent = function (newEventAttendeeId, fullName, emailPhone, email, phone, parentUserId, parentAttendeeId, eventId, status) {
        return new AttendeeUpsertRequest(null, null, newEventAttendeeId, true, parentUserId, parentAttendeeId, null, fullName, emailPhone, email, phone, 0, eventId, status);
    };
    AttendeeUpsertRequest.createNewPlusOneWithNewParent = function (newEventAttendeeId, fullName, emailPhone, email, phone, newEventAttendeeIdParent, eventId, status) {
        return new AttendeeUpsertRequest(null, null, newEventAttendeeId, true, null, null, newEventAttendeeIdParent, fullName, emailPhone, email, phone, 0, eventId, status);
    };
    AttendeeUpsertRequest.copy = function (a) {
        return new AttendeeUpsertRequest(a.userId, a.eventAttendeeId, a.newEventAttendeeId, a.isParentResponsible, a.parentUserId, a.parentAttendeeId, a.newEventAttendeeIdParent, a.fullName, a.emailPhone, a.email, a.phone, a.guestCount, a.eventId, a.status);
    };
    AttendeeUpsertRequest.createAttendeeFromAttendee = function (a) {
        return AttendeeUpsertRequest.copy(a);
    };
    // ===============================================================================
    //                                Sorting Logic
    // ===============================================================================
    AttendeeUpsertRequest.getSortedAttendees = function (attendees, sortDirection) {
        // get array of parents
        // sort array of parents
        // iterate through array of parents, and get children (best way is Map<parentId, Array<child>>), but need 2 parent->child maps for new and existing
        var tupleResult = this.createParentListAndChildrenMapsFromAttendees(attendees);
        var parents = tupleResult[0];
        var existingParentToChildMap = tupleResult[1];
        var newParentToChildMap = tupleResult[2];
        this.sortParentAttendeesInPlace(parents, sortDirection);
        // TODO: Sort children amoungst each other?  Could iterate each array in the maps, and sort in place
        // KC 2021-04-12: This behavior has not been defined by the business yet, so I'll just leave it alone for now.
        // Generate final sorted attendee list using parents and children maps
        var sortedAttendees = this.mergeParentsAndChildrenMapsIntoAttendeeList(parents, existingParentToChildMap, newParentToChildMap);
        return sortedAttendees;
    };
    AttendeeUpsertRequest.compare = function (a, b, sortDirection) {
        switch (sortDirection) {
            default:
            case 'databaseOrder':
                {
                    if (a.isNewAttendee && b.isNewAttendee) {
                        // If both new, sort by newEventAttendeeId DESCENDING (newest should appear higher)
                        if (a.newEventAttendeeId > b.newEventAttendeeId)
                            return -1;
                        if (a.newEventAttendeeId < b.newEventAttendeeId)
                            return 1;
                        return 0;
                    }
                    else if (!a.isNewAttendee && !b.isNewAttendee) {
                        // both existing, could be any of these cases:
                        // both plain existing (sort by eventAttendeeId)
                        // one newly saved existing, one plain existing (sort newly saved to top)
                        // both newly saved existing (sort by inverse newEventAttendeeId)
                        if ((a.newEventAttendeeId === null || a.newEventAttendeeId <= 0)
                            && (b.newEventAttendeeId === null || b.newEventAttendeeId <= 0)) {
                            // Then both existing attendees were existing before loading the page
                            // sort by eventAttendeeId ASCENDING (newest should appear last)
                            if (a.eventAttendeeId < b.eventAttendeeId)
                                return -1;
                            if (a.eventAttendeeId > b.eventAttendeeId)
                                return 1;
                            return 0;
                        }
                        else if (a.newEventAttendeeId > 0 && b.newEventAttendeeId > 0) {
                            // Then both existing attendees are newly saved (did not exist before loading the page)
                            // sort by newEventAttendeeId DESCENDING (newest should appear higher)
                            if (a.newEventAttendeeId > b.newEventAttendeeId)
                                return -1;
                            if (a.newEventAttendeeId < b.newEventAttendeeId)
                                return 1;
                            return 0;
                        }
                        else {
                            // Then one attendee is newly saved, another is not
                            // sort newly saved existing before plain existing
                            if (a.newEventAttendeeId > 0)
                                return -1;
                            return 1;
                        }
                    }
                    else {
                        // One is new and one is existing, sort new before existing
                        if (a.isNewAttendee)
                            return -1;
                        return 1;
                    }
                }
            case 'nameAsc':
                {
                    return a.fullName.localeCompare(b.fullName);
                }
            case 'nameDesc':
                {
                    return b.fullName.localeCompare(a.fullName);
                }
            case 'emailPhoneAsc':
                {
                    return a.emailPhone.localeCompare(b.emailPhone);
                }
            case 'emailPhoneDesc':
                {
                    return b.emailPhone.localeCompare(a.emailPhone);
                }
        }
    };
    AttendeeUpsertRequest.mergeParentsAndChildrenMapsIntoAttendeeList = function (parents, existingParentToChildMap, newParentToChildMap) {
        var e_1, _a, e_2, _b, e_3, _c;
        // Generate final sorted attendee list using parents and children maps
        var sortedAttendees = [];
        try {
            for (var parents_1 = tslib_1.__values(parents), parents_1_1 = parents_1.next(); !parents_1_1.done; parents_1_1 = parents_1.next()) {
                var parent_1 = parents_1_1.value;
                sortedAttendees.push(parent_1);
                if (parent_1.newEventAttendeeId > 0) {
                    var childArray = newParentToChildMap.get(parent_1.newEventAttendeeId);
                    if (childArray) {
                        try {
                            for (var childArray_1 = tslib_1.__values(childArray), childArray_1_1 = childArray_1.next(); !childArray_1_1.done; childArray_1_1 = childArray_1.next()) {
                                var child = childArray_1_1.value;
                                sortedAttendees.push(child);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (childArray_1_1 && !childArray_1_1.done && (_b = childArray_1.return)) _b.call(childArray_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
                if (parent_1.eventAttendeeId > 0) {
                    var childArray = existingParentToChildMap.get(parent_1.eventAttendeeId);
                    if (childArray) {
                        try {
                            for (var childArray_2 = tslib_1.__values(childArray), childArray_2_1 = childArray_2.next(); !childArray_2_1.done; childArray_2_1 = childArray_2.next()) {
                                var child = childArray_2_1.value;
                                sortedAttendees.push(child);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (childArray_2_1 && !childArray_2_1.done && (_c = childArray_2.return)) _c.call(childArray_2);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (parents_1_1 && !parents_1_1.done && (_a = parents_1.return)) _a.call(parents_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return sortedAttendees;
    };
    AttendeeUpsertRequest.createParentListAndChildrenMapsFromAttendees = function (attendees) {
        var e_4, _a;
        var parents = [];
        var existingParentToChildMap = new Map();
        var newParentToChildMap = new Map();
        try {
            for (var attendees_1 = tslib_1.__values(attendees), attendees_1_1 = attendees_1.next(); !attendees_1_1.done; attendees_1_1 = attendees_1.next()) {
                var attendee = attendees_1_1.value;
                if (attendee.isParentResponsible) {
                    // add child to appropriate parent->child map
                    if (attendee.parentUserId === null || attendee.parentUserId < 1) {
                        // then this is a child of a new parent
                        var childArray = newParentToChildMap.get(attendee.newEventAttendeeIdParent);
                        if (!childArray)
                            childArray = []; // Initialize array if this is the parent's first child
                        childArray.push(attendee);
                        newParentToChildMap.set(attendee.newEventAttendeeIdParent, childArray);
                    }
                    else {
                        // then this is a child of an existing parent
                        var childArray = existingParentToChildMap.get(attendee.parentUserId);
                        if (!childArray)
                            childArray = []; // Initialize array if this is the parent's first child
                        childArray.push(attendee);
                        existingParentToChildMap.set(attendee.parentUserId, childArray);
                    }
                }
                else {
                    parents.push(attendee);
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (attendees_1_1 && !attendees_1_1.done && (_a = attendees_1.return)) _a.call(attendees_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return [parents, existingParentToChildMap, newParentToChildMap];
    };
    AttendeeUpsertRequest.sortParentAttendeesInPlace = function (parentAttendees, sortDirection) {
        parentAttendees.sort(function (a, b) { return AttendeeUpsertRequest.compare(a, b, sortDirection); });
    };
    return AttendeeUpsertRequest;
}());
export { AttendeeUpsertRequest };
