import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { PackageDetailsService } from "app/pages/package-details/package-details.service";
import * as PDActions from "app/store/actions/packageDetails.actions";
import * as AttendeesActions from "app/store/actions/attendees.action";
import * as SurveysActions from "app/store/actions/surveys.action";
import * as MessageActions from "app/store/actions/message.action";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import * as eventActions from 'app/store/actions/event.action';
import * as userActions from 'app/store/actions/user';
import * as activityActions from 'app/store/actions/activity.action';
import * as ContactsActions from 'app/store/actions/contacts.action';
import * as PaymentActions from 'app/store/actions/payment.action';
import * as formActions from 'app/store/actions/forms';
import { of } from "rxjs";
import { PaymentsService } from "app/pages/payments/payments.service";
import { Router } from "@angular/router";
import { DialogService } from "app/dialogs/dialog.service";

@Injectable()
export class PackageDetailsEffects {

    @Effect()
    packageDetailsPageLoad = this.actions$.pipe(
        ofType<PDActions.PackageDetailsPageLoad>(PDActions.ActionTypes.PackageDetailsPageLoad),
        concatMap(action => {
            const eventId = action.payload.eventId;
            return [
                // new PackageDetailsActions.PDEffectsLoadSelectedEvent({eventId}),
                // new PackageDetailsActions.PDEffectsLoadLeaderSummary({eventId}),
                // new PackageDetailsActions.PDEffectsLoadAlternateLeaders({eventId}),
                // new PackageDetailsActions.PDEffectsLoadGroupLeaderProfile({eventId}),
                // new PackageDetailsActions.PDEffectsLoadSelectedActivities({eventId}),
                // new PackageDetailsActions.PDEffectsLoadAttendees({eventId}),
                // new PackageDetailsActions.PDEffectsLoadEventContacts({eventId}),

                new PDActions.PDEffectsLoadPackageDetails({eventId}),
                new PDActions.PDEffectsLoadTagInfo({eventId}),
                new PDActions.PDEffectsLoadPackageComponents({eventId}),
                new PDActions.PDEffectsLoadPackageRecord({eventId}),

                new PaymentActions.PackageDetailsEffectsLoadPaymentsReceivedSummary({eventId}),
                new PaymentActions.PackageDetailsEffectsLoadPaymentsSentSummary({eventId}),

                new AttendeesActions.GeneralLoadAttendees({eventId}),
                new PDActions.PDEffectsLoadSuppliers(),
                new PDActions.PDEffectsLoadPackageLogs({eventId}),
                new PaymentActions.PackageDetailsEffectsLoadEventPayments({eventId}),
                new PDActions.PDEffectsLoadSuppliersForPackage({eventId}),

                new eventActions.LoadSelectedEvent(eventId),
                new AttendeesActions.PackageDetailsEffectLoadLeaderSummary({eventId}),
                new eventActions.LoadAlternateLeaders(eventId),
                new userActions.LoadGroupLeaderProfile(eventId),
                new activityActions.LoadSelectedActivities(eventId),
                new ContactsActions.PackageDetailsEffectLoadContacts(),
                new formActions.LoadEventForms(eventId),
                new SurveysActions.PackageDetailsEffectSetSurveysEventId({eventId}), // TODO: make sure SetSurveysPackageId above functionality still works correctly
                new SurveysActions.PackageDetailsEffectLoadEventSurveys({eventId}),

                new MessageActions.PDEffectsLoadEventMessages({eventId}),
                new MessageActions.PDEffectsLoadEventSentMessages({eventId}),
            ];
        }),
    );

    @Effect()
    packageDetailsPageGetPackageDetails = this.actions$.pipe(
        ofType<PDActions.PackageDetailsPageGetPackageDetails>(PDActions.ActionTypes.PackageDetailsPageGetPackageDetails),
        concatMap(action => {
            const eventId = action.payload.eventId;
            return [
                // new PackageDetailsActions.PDEffectsLoadSelectedEvent({eventId}),
                // new PackageDetailsActions.PDEffectsLoadLeaderSummary({eventId}),
                // new PackageDetailsActions.PDEffectsLoadGroupLeaderProfile({eventId}),
                // new PackageDetailsActions.PDEffectsLoadAttendees({eventId}),

                new PDActions.PDEffectsLoadPackageDetails({eventId}),
                new PDActions.PDEffectsLoadPackageComponents({eventId}),
                new AttendeesActions.GeneralLoadAttendees({eventId}),
                new PaymentActions.PackageDetailsEffectsLoadEventPayments({eventId}),
                new PaymentActions.PackageDetailsEffectsLoadPaymentsReceivedSummary({eventId}),

                new eventActions.LoadSelectedEvent(eventId),
                new AttendeesActions.PackageDetailsEffectLoadLeaderSummary({eventId}),
                new userActions.LoadGroupLeaderProfile(eventId),
            ];
        }),
    );

    @Effect()
    attendeePackageDetailsPageLoad = this.actions$.pipe(
        ofType<PDActions.PackageDetailsPageGetPackageDetails>(PDActions.ActionTypes.PackageDetailsPageGetPackageDetails),
        concatMap(action => {
            const eventId = action.payload.eventId;
            return [
                new MessageActions.PDEffectsLoadEventMessages({eventId}),
                new MessageActions.PDEffectsLoadEventSentMessages({eventId}),
            ];
        }),
    );

    @Effect()
    inboxPageLoad = this.actions$.pipe(
        ofType<PDActions.InboxPageLoad>(PDActions.ActionTypes.InboxPageLoad),
        concatMap(action => {
            const eventId = null; // null means load messages for all events
            return [
                new MessageActions.PDEffectsLoadEventMessages({eventId}),
                new MessageActions.PDEffectsLoadEventSentMessages({eventId}),
            ];
        }),
    );

    @Effect()
    reloadMessages = this.actions$.pipe(
        ofType<PDActions.EventRelatedMessagesComponentLoadMessages | 
                PDActions.MessagesComponentUpdateSentMessages>
                (PDActions.ActionTypes.EventRelatedMessagesComponentLoadMessages, 
                PDActions.ActionTypes.MessagesComponentUpdateSentMessages),
        concatMap(action => {
            const eventId = action.payload.eventId;
            return [
                new MessageActions.PDEffectsLoadEventMessages({eventId}),
                new MessageActions.PDEffectsLoadEventSentMessages({eventId}),
            ];
        }),
    );

    @Effect()
    forwardActionsTo_PDEffectLoadPackageRecord = this.actions$.pipe(
        ofType<PDActions.EventReportComponentPageLoad | PDActions.EventFeaturesViewComponentReloadPackageRecord>
        (PDActions.ActionTypes.EventReportComponentPageLoad, PDActions.ActionTypes.EventFeaturesViewComponentReloadPackageRecord),
        map(action => new PDActions.PDEffectsLoadPackageRecord({eventId: action.payload.eventId})),
    );

    @Effect()
    loadPackageDetails = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadPackageDetails>(PDActions.ActionTypes.PDEffectsLoadPackageDetails),
        concatMap(action => this.packageDetailsService.GetPackage(action.payload.eventId).pipe(
            map(packageDetails => new PDActions.PDApiLoadPackageDetailsSuccess({packageDetails})),
            catchError(error => of(new PDActions.PDApiLoadPackageDetailsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadTagInfo = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadTagInfo>(PDActions.ActionTypes.PDEffectsLoadTagInfo),
        concatMap(action => this.packageDetailsService.GetTagsInfo(action.payload.eventId, 'Package').pipe(
            map(tagInfo => new PDActions.PDApiLoadTagInfoSuccess({tagInfo})),
            catchError(error => of(new PDActions.PDApiLoadTagInfoFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadPackageComponents = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadPackageComponents>(PDActions.ActionTypes.PDEffectsLoadPackageComponents),
        concatMap(action => this.packageDetailsService.packageComponentsGetByID(action.payload.eventId).pipe(
            map(components => new PDActions.PDApiLoadPackageComponentsSuccess({components})),
            catchError(error => of(new PDActions.PDApiLoadPackageComponentsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadPackageRecord = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadPackageRecord>(PDActions.ActionTypes.PDEffectsLoadPackageRecord),
        concatMap(action => this.packageDetailsService.GetPackageRecord(action.payload.eventId).pipe(
            map(packageRecord => new PDActions.PDApiLoadPackageRecordSuccess({packageRecord})),
            catchError(error => of(new PDActions.PDApiLoadPackageRecordFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadSuppliers = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadSuppliers>(PDActions.ActionTypes.PDEffectsLoadSuppliers),
        concatMap(action => this.packageDetailsService.GetActiveSuppliers().pipe(
            map(suppliers => new PDActions.PDApiLoadSuppliersSuccess({suppliers})),
            catchError(error => of(new PDActions.PDApiLoadSuppliersFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadPackageLogs = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadPackageLogs>(PDActions.ActionTypes.PDEffectsLoadPackageLogs),
        concatMap(action => this.packageDetailsService.getPackageLogs(action.payload.eventId).pipe(
            map(packageLogs => new PDActions.PDApiLoadPackageLogsSuccess({packageLogs})),
            catchError(error => of(new PDActions.PDApiLoadPackageLogsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadSuppliersForPackage = this.actions$.pipe(
        ofType<PDActions.PDEffectsLoadSuppliersForPackage>(PDActions.ActionTypes.PDEffectsLoadSuppliersForPackage),
        concatMap(action => this.paymentService.getSuppliersForPackage(action.payload.eventId).pipe(
            map(packageSuppliersByActivity => new PDActions.PDApiLoadSuppliersForPackageSuccess({packageSuppliersByActivity})),
            catchError(error => of(new PDActions.PDApiLoadSuppliersForPackageFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    createEventFromBibOrder = this.actions$.pipe(
        ofType<PDActions.CreateEventFromBibOrderDialog>(PDActions.ActionTypes.CreateEventFromBibOrderDialog),
        concatMap(action => this.packageDetailsService.createEventFromBibOrder(action.payload.createEventFromBibOrderRequest).pipe(
            map(createEventFromBibOrderResponse => new PDActions.PDApiCreateEventFromBibOrderSuccess({createEventFromBibOrderResponse})),
            catchError(error => of(new PDActions.PDApiCreateEventFromBibOrderFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect({dispatch: false})
    navigateToPackageDetailsPageFromBibOrderEventCreation = this.actions$.pipe(
        ofType<PDActions.PDApiCreateEventFromBibOrderSuccess>(PDActions.ActionTypes.PDApiCreateEventFromBibOrderSuccess),
        tap(action => {
            this.dialogService.closeBibOrderNumberLoginDialog();
            this.router.navigateByUrl('/pages/package-details/' + action.payload.createEventFromBibOrderResponse.eventId);
        }),
    );

    @Effect()
    createEventFromSupplierOrder = this.actions$.pipe(
        ofType<PDActions.CreateEventFromSupplierOrderDialog>(PDActions.ActionTypes.CreateEventFromSupplierOrderDialog),
        concatMap(action => this.packageDetailsService.createEventFromSupplierOrder(action.payload.createEventFromSupplierOrderRequest).pipe(
            map(createEventFromSupplierOrderResponse => new PDActions.PDApiCreateEventFromSupplierOrderSuccess({createEventFromSupplierOrderResponse: createEventFromSupplierOrderResponse})),
            catchError(error => of(new PDActions.PDApiCreateEventFromSupplierOrderFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    updateEventFromSupplierOrder = this.actions$.pipe(
        ofType<PDActions.UpdateEventFromSupplierOrder>(PDActions.ActionTypes.UpdateEventFromSupplierOrder),
        concatMap(action => this.packageDetailsService.updateEventFromSupplierOrder(action.payload.updateEventFromSupplierOrderRequest).pipe(
            map(updateEventFromSupplierOrderResponse => new PDActions.PDApiUpdateEventFromSupplierOrderSuccess({updateEventFromSupplierOrderResponse: updateEventFromSupplierOrderResponse})),
            catchError(error => of(new PDActions.PDApiUpdateEventFromSupplierOrderFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    loadTickets = this.actions$.pipe(
        ofType<PDActions.LoadTickets>(PDActions.ActionTypes.LoadTickets),
        concatMap(action => this.packageDetailsService.loadTickets(action.payload.loadTicketsRequest).pipe(
            map(loadTicketsResponse => new PDActions.PDApiLoadTicketsSuccess({loadTicketsResponse})),
            catchError(error => of(new PDActions.PDApiLoadTicketsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect()
    purchaseTickets = this.actions$.pipe(
        ofType<PDActions.PurchaseTickets>(PDActions.ActionTypes.PurchaseTickets),
        concatMap(action => this.packageDetailsService.purchaseTickets(action.payload.purchaseTicketsRequest).pipe(
            map(purchaseTicketsResponse => new PDActions.PDApiPurchaseTicketsSuccess({purchaseTicketsResponse})),
            catchError(error => of(new PDActions.PDApiPurchaseTicketsFailure({errorMessage: error.toString()}))),
        )),
    );

    @Effect({dispatch: false})
    navigateToPackageDetailsPageFromAVOrderEventCreation = this.actions$.pipe(
        ofType<PDActions.PDApiCreateEventFromSupplierOrderSuccess>(PDActions.ActionTypes.PDApiCreateEventFromSupplierOrderSuccess),
        tap(action => {
            this.dialogService.closeSupplierOrderNumberLoginDialog();
            this.router.navigateByUrl('/pages/package-details/' + action.payload.createEventFromSupplierOrderResponse.eventId);
        }),
    );

    constructor(
        private actions$: Actions,
        private packageDetailsService: PackageDetailsService,
        private paymentService: PaymentsService,
        private router: Router,
        private dialogService: DialogService,
    ) {}
}