export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["GeneralLoadAttendees"] = "[General] Load Attendees";
    ActionTypes["GroupMembersTabLoadAttendees"] = "[Group Members Tab] Load Attendees";
    ActionTypes["AttendeeEffectLoadAttendeesSuccess"] = "[Attendee Effect] Load Attendees Success";
    ActionTypes["AttendeeEffectLoadAttendeesFailure"] = "[Attendee Effect] Load Attendees Failure";
    // These are synchronous actions (not part of autosaving, need to block ui until completed)
    ActionTypes["GroupMemberTabSaveAllAttendees"] = "[Group Member Tab] Save All Attendees";
    ActionTypes["GroupMemberTabAddNewAttendees"] = "[Group Member Tab] Add New Attendees";
    ActionTypes["ImportContactsToEventSaveAttendees"] = "[Import Contacts to Event] Save Attendees";
    ActionTypes["PreviousAttendeesSaveAttendees"] = "[Previous Attendees] Save Attendees";
    ActionTypes["AttendeeEffectSaveAttendeesSuccess"] = "[Attendee Effect] Save Attendees Success";
    ActionTypes["AttendeeEffectSaveAttendeesFailure"] = "[Attendee Effect] Save Attendees Failure";
    ActionTypes["PublicEventAddNewAttendee"] = "[Public Event] Add New Attendee";
    ActionTypes["PublicEventAddNewAttendeeSuccess"] = "[Public Event] Add New Attendee Success";
    ActionTypes["PublicEventAddNewAttendeeFailure"] = "[Public Event] Add New Attendee Failure";
    ActionTypes["UpdateAttendeeStatusComponentUpdateAttendeeStatus"] = "[Update Attendee Status Component] Update Attendee Status";
    ActionTypes["AttendeeEffectUpdateAttendeeStatusSuccess"] = "[Attendee Effect] Update Attendee Status Success";
    ActionTypes["AttendeeEffectUpdateAttendeeStatusFailure"] = "[Attendee Effect] Update Attendee Status Failure";
    ActionTypes["ChangeAttendeeEmailsComponentChangeAttendeeEmails"] = "[Change Attendee Emails Component] Change Attendee Emails";
    ActionTypes["AttendeeEffectChangeAttendeeEmailsSuccess"] = "[Attendee Effect] Change Attendee Emails Success";
    ActionTypes["AttendeeEffectChangeAttendeeEmailsFailure"] = "[Attendee Effect] Change Attendee Emails Failure";
    ActionTypes["GroupMemberTab_InviteEventAttendees"] = "[Group Member Tab] Invite Attendees";
    ActionTypes["AttendeeEffect_InviteAttendeesSuccess"] = "[Attendee Effect] Invite Attendees Success";
    ActionTypes["AttendeeEffect_InviteAttendeesFailure"] = "[Attendee Effect] Invite Attendees Failure";
    ActionTypes["PackageDetailsEffectLoadLeaderSummary"] = "[Package Details Effect] Load Leader Summary";
    ActionTypes["AttendeeEffectLoadLeaderSummarySuccess"] = "[Attendee Effect] Load Leader Summary Success";
    ActionTypes["AttendeeEffectLoadLeaderSummaryFailure"] = "[Attendee Effect] Load Leader Summary Failure";
    ActionTypes["EventEffectClearAttendees"] = "[Event Effect] Clear Attendees";
})(ActionTypes || (ActionTypes = {}));
//----------------------------------------------------------------
var GeneralLoadAttendees = /** @class */ (function () {
    function GeneralLoadAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.GeneralLoadAttendees;
    }
    return GeneralLoadAttendees;
}());
export { GeneralLoadAttendees };
var GroupMembersTabLoadAttendees = /** @class */ (function () {
    function GroupMembersTabLoadAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.GroupMembersTabLoadAttendees;
    }
    return GroupMembersTabLoadAttendees;
}());
export { GroupMembersTabLoadAttendees };
var AttendeeEffectLoadAttendeesSuccess = /** @class */ (function () {
    function AttendeeEffectLoadAttendeesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectLoadAttendeesSuccess;
    }
    return AttendeeEffectLoadAttendeesSuccess;
}());
export { AttendeeEffectLoadAttendeesSuccess };
var AttendeeEffectLoadAttendeesFailure = /** @class */ (function () {
    function AttendeeEffectLoadAttendeesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectLoadAttendeesFailure;
    }
    return AttendeeEffectLoadAttendeesFailure;
}());
export { AttendeeEffectLoadAttendeesFailure };
//----------------------------------------------------------------
var GroupMemberTabSaveAllAttendees = /** @class */ (function () {
    function GroupMemberTabSaveAllAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.GroupMemberTabSaveAllAttendees;
    }
    return GroupMemberTabSaveAllAttendees;
}());
export { GroupMemberTabSaveAllAttendees };
var GroupMemberTabAddNewAttendees = /** @class */ (function () {
    function GroupMemberTabAddNewAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.GroupMemberTabAddNewAttendees;
    }
    return GroupMemberTabAddNewAttendees;
}());
export { GroupMemberTabAddNewAttendees };
var ImportContactsToEventSaveAttendees = /** @class */ (function () {
    function ImportContactsToEventSaveAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.ImportContactsToEventSaveAttendees;
    }
    return ImportContactsToEventSaveAttendees;
}());
export { ImportContactsToEventSaveAttendees };
var PreviousAttendeesSaveAttendees = /** @class */ (function () {
    function PreviousAttendeesSaveAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.PreviousAttendeesSaveAttendees;
    }
    return PreviousAttendeesSaveAttendees;
}());
export { PreviousAttendeesSaveAttendees };
var AttendeeEffectSaveAttendeesSuccess = /** @class */ (function () {
    function AttendeeEffectSaveAttendeesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectSaveAttendeesSuccess;
    }
    return AttendeeEffectSaveAttendeesSuccess;
}());
export { AttendeeEffectSaveAttendeesSuccess };
var AttendeeEffectSaveAttendeesFailure = /** @class */ (function () {
    function AttendeeEffectSaveAttendeesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectSaveAttendeesFailure;
    }
    return AttendeeEffectSaveAttendeesFailure;
}());
export { AttendeeEffectSaveAttendeesFailure };
//----------------------------------------------------------------
var PublicEventAddNewAttendee = /** @class */ (function () {
    function PublicEventAddNewAttendee(payload) {
        this.payload = payload;
        this.type = ActionTypes.PublicEventAddNewAttendee;
    }
    return PublicEventAddNewAttendee;
}());
export { PublicEventAddNewAttendee };
var PublicEventAddNewAttendeeSuccess = /** @class */ (function () {
    function PublicEventAddNewAttendeeSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.PublicEventAddNewAttendeeSuccess;
    }
    return PublicEventAddNewAttendeeSuccess;
}());
export { PublicEventAddNewAttendeeSuccess };
var PublicEventAddNewAttendeeFailure = /** @class */ (function () {
    function PublicEventAddNewAttendeeFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.PublicEventAddNewAttendeeFailure;
    }
    return PublicEventAddNewAttendeeFailure;
}());
export { PublicEventAddNewAttendeeFailure };
//----------------------------------------------------------------
var UpdateAttendeeStatusComponentUpdateAttendeeStatus = /** @class */ (function () {
    function UpdateAttendeeStatusComponentUpdateAttendeeStatus(payload) {
        this.payload = payload;
        this.type = ActionTypes.UpdateAttendeeStatusComponentUpdateAttendeeStatus;
    }
    return UpdateAttendeeStatusComponentUpdateAttendeeStatus;
}());
export { UpdateAttendeeStatusComponentUpdateAttendeeStatus };
var AttendeeEffectUpdateAttendeeStatusSuccess = /** @class */ (function () {
    function AttendeeEffectUpdateAttendeeStatusSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectUpdateAttendeeStatusSuccess;
    }
    return AttendeeEffectUpdateAttendeeStatusSuccess;
}());
export { AttendeeEffectUpdateAttendeeStatusSuccess };
var AttendeeEffectUpdateAttendeeStatusFailure = /** @class */ (function () {
    function AttendeeEffectUpdateAttendeeStatusFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectUpdateAttendeeStatusFailure;
    }
    return AttendeeEffectUpdateAttendeeStatusFailure;
}());
export { AttendeeEffectUpdateAttendeeStatusFailure };
//----------------------------------------------------------------
var ChangeAttendeeEmailsComponentChangeAttendeeEmails = /** @class */ (function () {
    function ChangeAttendeeEmailsComponentChangeAttendeeEmails(payload) {
        this.payload = payload;
        this.type = ActionTypes.ChangeAttendeeEmailsComponentChangeAttendeeEmails;
    }
    return ChangeAttendeeEmailsComponentChangeAttendeeEmails;
}());
export { ChangeAttendeeEmailsComponentChangeAttendeeEmails };
var AttendeeEffectChangeAttendeeEmailsSuccess = /** @class */ (function () {
    function AttendeeEffectChangeAttendeeEmailsSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectChangeAttendeeEmailsSuccess;
    }
    return AttendeeEffectChangeAttendeeEmailsSuccess;
}());
export { AttendeeEffectChangeAttendeeEmailsSuccess };
var AttendeeEffectChangeAttendeeEmailsFailure = /** @class */ (function () {
    function AttendeeEffectChangeAttendeeEmailsFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectChangeAttendeeEmailsFailure;
    }
    return AttendeeEffectChangeAttendeeEmailsFailure;
}());
export { AttendeeEffectChangeAttendeeEmailsFailure };
//----------------------------------------------------------------
var GroupMemberTab_InviteEventAttendees = /** @class */ (function () {
    function GroupMemberTab_InviteEventAttendees(payload) {
        this.payload = payload;
        this.type = ActionTypes.GroupMemberTab_InviteEventAttendees;
    }
    return GroupMemberTab_InviteEventAttendees;
}());
export { GroupMemberTab_InviteEventAttendees };
var AttendeeEffect_InviteAttendeesSuccess = /** @class */ (function () {
    function AttendeeEffect_InviteAttendeesSuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffect_InviteAttendeesSuccess;
    }
    return AttendeeEffect_InviteAttendeesSuccess;
}());
export { AttendeeEffect_InviteAttendeesSuccess };
var AttendeeEffect_InviteAttendeesFailure = /** @class */ (function () {
    function AttendeeEffect_InviteAttendeesFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffect_InviteAttendeesFailure;
    }
    return AttendeeEffect_InviteAttendeesFailure;
}());
export { AttendeeEffect_InviteAttendeesFailure };
//----------------------------------------------------------------
var PackageDetailsEffectLoadLeaderSummary = /** @class */ (function () {
    function PackageDetailsEffectLoadLeaderSummary(payload) {
        this.payload = payload;
        this.type = ActionTypes.PackageDetailsEffectLoadLeaderSummary;
    }
    return PackageDetailsEffectLoadLeaderSummary;
}());
export { PackageDetailsEffectLoadLeaderSummary };
var AttendeeEffectLoadLeaderSummarySuccess = /** @class */ (function () {
    function AttendeeEffectLoadLeaderSummarySuccess(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectLoadLeaderSummarySuccess;
    }
    return AttendeeEffectLoadLeaderSummarySuccess;
}());
export { AttendeeEffectLoadLeaderSummarySuccess };
var AttendeeEffectLoadLeaderSummaryFailure = /** @class */ (function () {
    function AttendeeEffectLoadLeaderSummaryFailure(payload) {
        this.payload = payload;
        this.type = ActionTypes.AttendeeEffectLoadLeaderSummaryFailure;
    }
    return AttendeeEffectLoadLeaderSummaryFailure;
}());
export { AttendeeEffectLoadLeaderSummaryFailure };
//----------------------------------------------------------------
var EventEffectClearAttendees = /** @class */ (function () {
    function EventEffectClearAttendees() {
        this.type = ActionTypes.EventEffectClearAttendees;
    }
    return EventEffectClearAttendees;
}());
export { EventEffectClearAttendees };
