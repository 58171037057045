<div class="gled-add-activity-show">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <p class="title" id="stepOne" [ngClass]="{'clickable':steps.stepOne.clickable,'title-margin':isFirstFeature}">
            {{ !!isFirstFeature ? '1. Tell us about your event' : '1. Tell us more about this activity'}}
            <i class="fa fa-check step-check" *ngIf="steps.stepOne.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-add-on> begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="feature-add-on">
            <!-- <form [formGroup]="featureAddOnForm" (ngSubmit)="onSubmit_FeatureAddOnForm()"> -->
            <div class="form-group" *ngIf="!isFirstFeature">
                <grouptools-label for="isRequired" class="feature-required-popover"
                    [helperText]="'At least one activity is required. A required activity means that all attendees must opt-in. An attendee can opt in to an optional activity at any time. This cannot be changed once invites have been sent.'"
                    [isQuestion]="true">
                    <label>Is this activity required?</label>
                </grouptools-label>
                <input type="radio" formControlName="isRequired" value="Yes"><span class="required-opt">Yes</span>
                <input type="radio" formControlName="isRequired" value="No"> <span class="required-opt">No</span>
                <label for="isRequired" *ngIf="addShowFormErrors.isRequired"
                    class="error-text">{{addShowFormErrors.isRequired}}</label>
            </div>

            <div class="form-group" [class.has-error]="addShowFormErrors.ComponentName">
                <grouptools-label for="addShowName" [helperText]="'Name of the activity.'"><label>Name:</label>
                </grouptools-label>
                <input type="text" class="form-control" id="addShowName" placeholder="Add an enticing title..."
                    formControlName="ComponentName">
                <label for="addShowName" *ngIf="addShowFormErrors.ComponentName"
                    class="error-text">{{addShowFormErrors.ComponentName}}</label>
            </div>

            <div class="form-group less-form-bottom">
                <div class="row">
                    <div class="col-sm-6 less-right" [class.has-error]="addShowFormErrors.ComponentOccurDate">
                        <label for="ComponentOccurDate">Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                formControlName="ComponentOccurDate" ngbDatepicker #ComponentOccurDate="ngbDatepicker"
                                container="body">
                            <div class="input-group-addon"
                                (click)="ComponentOccurDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, ComponentOccurDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="ComponentOccurDate" *ngIf="addShowFormErrors.ComponentOccurDate"
                            class="error-text">{{addShowFormErrors.ComponentOccurDate}}</label>
                    </div>
                    <div class="col-sm-6 less-left">
                        <label for="addShowOccurTime">Time:</label>
                        <div class="input-group">
                            <input type="time" class="form-control" id="addShowOccurTime"
                                formControlName="ComponentOccurTime" style="height:46px;">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" [class.has-error]="addShowFormErrors.ComponentDesc">
                <grouptools-label for="addShowDesc"
                    [helperText]="'Write something enticing about the activity. This will help invitees understand what the activity is all about and what it may include.'">
                    <label>{{ !!this.isFirstFeature ? 'Add an enticing description:' : 'Give your activity a description:' }}</label>
                </grouptools-label>
                <textarea class="form-control" id="addShowDesc"
                    placeholder="{{ !!this.isFirstFeature ? 'Be descriptive, this is what your users will see when you send an invitation. ' : 'Describe what this activity is all about...' }}"
                    formControlName="ComponentDesc"></textarea>
                <label for="addShowDesc" *ngIf="addShowFormErrors.ComponentDesc"
                    class="error-text">{{addShowFormErrors.ComponentDesc}}</label>
            </div>

            <div class="form-group" [class.has-error]="addShowFormErrors.VenueName">
                <label for="addShowVenueName">Venue / Location:</label>
                <!-- <input ngx-google-places-autocomplete [options]='options' class="form-control" id="addShowVenueName"
                    placeholder="Where is this located?" formControlName="VenueName" #placesRef="ngx-places"
                    (onAddressChange)="handleVenueNameChange($event)" /> -->
                <!--<input type="text" class="form-control" id="addShowVenueName" placeholder="Where is this located?" formControlName="VenueName">-->
                <label for="addShowVenueName" *ngIf="addShowFormErrors.VenueName"
                    class="error-text">{{addShowFormErrors.VenueName}}</label>
            </div>

            <div class="form-group" [class.has-error]="addShowFormErrors.ComponentVenueLocation">
                <label for="addShowVenueLocation">Address:</label>
                <!-- <input ngx-google-places-autocomplete [options]='options' class="form-control" id="addShowVenueLocation"
                    placeholder="What's the address?" formControlName="ComponentVenueLocation" #placesRef="ngx-places"
                    (onAddressChange)="handleVenueAddressChange($event)" /> -->
                <!--<input type="text" class="form-control" id="addShowVenueLocation" placeholder="What's the address?" formControlName="ComponentVenueLocation">-->
                <label for="addShowVenueLocation" *ngIf="addShowFormErrors.ComponentVenueLocation"
                    class="error-text">{{addShowFormErrors.ComponentVenueLocation}}</label>
            </div>

            <div class="form-group less-form-bottom" *ngIf="isFirstFeature">
                <div class="row">
                    <div class="col-sm-12 less-right" [class.has-error]="addShowFormErrors.RSVPDueDate">
                        <label for="RSVPDueDate">RSVP Due Date:</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" name="dp" formControlName="RSVPDueDate"
                                ngbDatepicker #RSVPDueDate="ngbDatepicker" container="body">
                            <div class="input-group-addon" (click)="RSVPDueDate.toggle();$event.stopPropagation();"
                                (document:click)="datePickerUtility.tryClose($event, RSVPDueDate)">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                        <label for="RSVPDueDate" *ngIf="addShowFormErrors.RSVPDueDate"
                            class="error-text">{{addShowFormErrors.RSVPDueDate}}</label>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="isEdit">
                <div class="form-group row">
                    <div class="col-xs-12">
                        <button [disabled]="disableSave_FeatureAddOn ? true : null" type="submit"
                            class="btn btn-secondary form-control add-feature-continue-btn-modal">
                            <span>'Save & Continue'</span>
                        </button>
                    </div>
                </div>
            </div> -->
            <!-- </form> -->
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-add-on> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <p class="title" id="stepTwo" 
            [ngClass]="{'clickable':false}">
            2. {{ !!isFirstFeature ? 'Dates, pricing and availability' : 'Tell us about your tickets'}}
            <i class="fa fa-check step-check" *ngIf="steps.stepTwo.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-tickets> Begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="feature-tickets">
            <grouptools-label
                [helperText]="'Switching to advanced mode allows you to enter ticket supplier information such as seat locations, supplier minimums, deposit information and payment due dates.'"
                [isQuestion]="true" class="advanced-mode quantity-popover">
                <label class="text">Advanced Mode</label>
                <label class="switch">
                    <input type="checkbox" [checked]="AdvancedMode" (change)="AdvancedMode = !AdvancedMode"><i
                        class="fa fa-check-circle"></i>
                    <span class="slider round"></span>

                </label>
            </grouptools-label>
            <!-- <form [formGroup]="featureTicketsForm" (ngSubmit)="onSubmit_FeatureTickets()" class="feature-tickets-form"> -->
            <div class="feature-tickets-form">
                <div class="form-group" *ngIf="AdvancedMode && !multiOptions">
                    <label for="SeatLocation">Seat Location / Area: (optional)</label>
                    <input type="text" class="form-control" id="SeatLocation" formControlName="SeatLocation">
                </div>

                <div class="form-group" *ngIf="AdvancedMode">
                    <div class="row">
                        <div class="col-sm-6 less-right" [class.has-error]="addShowFormErrors.SupplierCapacity">
                            <label for="SupplierCapacity">Supplier Min Quantity(opt):</label>
                            <div class="input-group">
                                <input type="number" class="form-control" id="SupplierCapacity"
                                    formControlName="SupplierCapacity">
                            </div>
                            <label for="SupplierCapacity" *ngIf="addShowFormErrors.SupplierCapacity"
                                class="error-text">{{addShowFormErrors.SupplierCapacity}}</label>
                        </div>
                        <div class="col-sm-6 less-left" [class.has-error]="addShowFormErrors.SupplyPayDate">
                            <label for="SupplyPayDate">Supplier Pay Date(opt):</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                    formControlName="SupplyPayDate" ngbDatepicker #SupplyPayDate="ngbDatepicker"
                                    container="body">
                                <div class="input-group-addon"
                                    (click)="SupplyPayDate.toggle();$event.stopPropagation();"
                                    (document:click)="datePickerUtility.tryClose($event, SupplyPayDate)">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                            <label for="SupplyPayDate" *ngIf="addShowFormErrors.SupplyPayDate"
                                class="error-text">{{addShowFormErrors.SupplyPayDate}}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="!multiOptions">
                    <div class="row">
                        <div class="less-right col-sm-3" [class.has-error]="addShowFormErrors.ComponentCapacity">
                            <grouptools-label for="ComponentCapacity" class="quantity-popover"
                                [helperText]="'Enter 999 for unlimited quantity. The number you enter here will the determine the maximum availability for this activity. Attendees will NOT be able to opt in to this activity once this number has been depleted. You can increase this number at any time.'">
                                <label class="label-less-margin">Quantity:</label>
                            </grouptools-label>
                            <input type="number" class="form-control" min="1" id="ComponentCapacity"
                                formControlName="ComponentCapacity" (input)="calculateTotalCost($event)"
                                (change)="calculateTotalCost($event)" (keyup)="calculateTotalCost($event)">
                            <label for="ComponentCapacity" *ngIf="addShowFormErrors.ComponentCapacity"
                                class="error-text">{{addShowFormErrors.ComponentCapacity}}</label>
                        </div>
                        <div class="col-sm-3 less-left less-right" [class.has-error]="addShowFormErrors.ComponentCost"
                            *ngIf="AdvancedMode">
                            <label for="ComponentCost">Cost:</label>
                            <input type="number" min="0" class="form-control" id="ComponentCost"
                                formControlName="ComponentCost" (input)="calculateTotalCost($event)"
                                (change)="calculateTotalCost($event)" (keyup)="calculateTotalCost($event)">
                            <label for="ComponentCost" *ngIf="addShowFormErrors.ComponentCost"
                                class="error-text">{{addShowFormErrors.ComponentCost}}</label>
                        </div>
                        <div class="col-sm-3 less-left less-right" *ngIf="AdvancedMode">
                            <label for="TotalCost">Total Cost:</label>
                            <input type="number" class="form-control" id="TotalCost" formControlName="TotalCost">
                        </div>
                        <div class="col-sm-3 less-left" [class.has-error]="addShowFormErrors.ComponentPrice">
                            <grouptools-label for="ComponentPrice"
                                [helperText]="'Enter 0 if the event/activity is free. The amount you will charge your attendees opting into this activity.'">
                                <label class="label-less-margin">Price:</label>
                            </grouptools-label>
                            <input [readonly]="isEdit && multiOptions" min="0" type="number" class="form-control"
                                id="ComponentPrice" formControlName="ComponentPrice">
                            <label for="ComponentPrice" *ngIf="addShowFormErrors.ComponentPrice"
                                class="error-text">{{addShowFormErrors.ComponentPrice}}</label>
                        </div>
                    </div>
                </div>

                <div *ngIf="AdvancedMode">
                    <p>Tell us about your attendee requirements</p>
                    <hr>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-6 less-right">
                            <label for="DepositAmount">Deposit Amount(If any):</label>
                            <div class="input-group">
                                <input type="number" class="form-control" id="DepositAmount"
                                    formControlName="DepositAmount">
                            </div>
                            <label for="DepositAmount" *ngIf="addShowFormErrors.DepositAmount"
                                class="error-text">{{addShowFormErrors.DepositAmount}}</label>
                        </div>
                        <div class="col-sm-6 less-left" [class.has-error]="addShowFormErrors.DepositDueDate">
                            <label for="DepositDueDate">Deposit Due Date(If any):</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="mm/dd/yyyy" name="dp"
                                    formControlName="DepositDueDate" ngbDatepicker #DepositDueDate="ngbDatepicker"
                                    container="body">
                                <div class="input-group-addon"
                                    (click)="DepositDueDate.toggle();$event.stopPropagation();"
                                    (document:click)="datePickerUtility.tryClose($event, DepositDueDate)">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                            <label for="DepositDueDate" *ngIf="addShowFormErrors.DepositDueDate"
                                class="error-text">{{addShowFormErrors.DepositDueDate}}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group" [class.has-error]="addShowFormErrors.FinalPaymentDue">
                    <grouptools-label for="FinalPaymentDue"
                        [helperText]="'The last day you are willing to accept payments for your event. This date will affect attendee opt in dates.'">
                        <label class="label-less-margin">Final Payment Due:</label>
                    </grouptools-label>
                    <div class="input-group">
                        <input class="form-control" placeholder="mm/dd/yyyy" name="dp" formControlName="FinalPaymentDue"
                            ngbDatepicker #FinalPaymentDue="ngbDatepicker" container="body">
                        <div class="input-group-addon" (click)="FinalPaymentDue.toggle();$event.stopPropagation();"
                            (document:click)="datePickerUtility.tryClose($event, FinalPaymentDue)">
                            <i class="fa fa-calendar"></i>
                        </div>
                    </div>
                    <label for="FinalPaymentDue" *ngIf="addShowFormErrors.FinalPaymentDue"
                        class="error-text">{{addShowFormErrors.FinalPaymentDue}}</label>
                </div>

                <!-- <div class="form-group">
          <label for="MenuPDF">Description Of Menu:</label>
          <textarea class="form-control" id="MenuPDF" formControlName="MenuPDF"></textarea>
      </div> -->

                <!-- Add ng-content so that we can show the price increase message in edit modal -->
                <ng-content></ng-content>

                <!-- <div *ngIf="isEdit">
                    <div class="form-group row">
                        <div class="col-xs-12">
                            <button type="submit" [disabled]="disableSave_FeatureTickets ? true : null"
                                class="btn btn-secondary form-control add-feature-continue-btn-modal">
                                <span>'Save & Continue'</span>
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
            <!-- </form> -->
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <feature-tickets> End -->
        <!--------------------------------------------------------------------------------------------- -->

        <p class="title" id="stepThree">
            3. Additional Information
            <i class="fa fa-check step-check" *ngIf="steps.stepThree.check"></i>
        </p>
        <div class="title-hr-modal"></div>

        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <additional-info> Begin -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="additional-info">
            <div *ngIf="!isFirstFeature">
                <grouptools-label for="featurePhoto"
                    [helperText]="'The photo is optional but useful to give your attendees a visual of the activity. This could be a photo, a logo or whatever you think makes the activity interesting.'">
                </grouptools-label>
                <div class="feature-photo" (click)="changePhoto.emit()"
                    [ngClass]="{'exist-image-height':image,'none-image-height':!image}">
                    <p *ngIf="!image">Add {{photoType}}</p>
                    <img *ngIf="image" [src]="image">
                </div>
            </div>
            <div *ngIf="isFirstFeature">
                <label class="photo-title">Photos</label>
                <div class="feature-photo" (click)="changeEventPhoto.emit()"
                    [ngClass]="{'exist-image-height':eventImage,'none-image-height':!eventImage}">
                    <p *ngIf="!eventImage">Add an event photo. This is the primary photo of your event.</p>
                    <img *ngIf="eventImage" [src]="eventImage">
                </div>
                <div class="feature-photo" (click)="changePhoto.emit()"
                    [ngClass]="{'exist-image-height':image,'none-image-height':!image}">
                    <p *ngIf="!image">Add {{photoType}}. This is the secondary photo of your event.</p>
                    <img *ngIf="image" [src]="image">
                </div>
            </div>
        </div>
        <!--------------------------------------------------------------------------------------------- -->
        <!-- Previously <additional-info> End -->
        <!--------------------------------------------------------------------------------------------- -->
        <div class="custom-feature-btns">
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-primary form-control save-btn" type="submit">
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-secondary form-control cancel-btn" type="button" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>
